/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useRef, useState } from 'react';

import { IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import {
  Container,
  Header,
  HeaderButton,
  IonSegmentContainer,
  StyledIonDatetime,
  TimeIonLabel,
} from './AvailabilityCustomTime.styles';
import { AnimatePresence, motion } from 'framer-motion';
import { IAvailabilityTime } from '../../types';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import dayjs from 'dayjs';

type AvailabilityCustomTimeProps = {
  openCustomTimer: boolean;
  slotId: string;
  setOpenCustomTimer: (arg: boolean) => void;
  handleCustomTime: (
    arg: IAvailabilityTime,
    times: string,
    slotId: string
  ) => void;
};

const AvailabilityCustomTime: React.FC<AvailabilityCustomTimeProps> = ({
  openCustomTimer,
  setOpenCustomTimer,
  handleCustomTime,
  slotId,
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [startTime, setStartTime] = useState<string>(''); // Format YYYY-MM-DDTHH:mm:ss
  const [endTime, setEndTime] = useState<string>(''); // Format YYYY-MM-DDTHH:mm:ss
  const [segmentTab, setSegmentTab] = useState<string>('start-time');
  const closeModal = (): void => {
    setOpenCustomTimer(false);
  };
  const clearTimes = (): void => {
    setStartTime('');
    setEndTime('');
    setSegmentTab('start-time');
  };

  const handleConfirm = (): void => {
    if (startTime.length === 0 || endTime.length === 0) {
      alert('Please select a start and end time');
      return;
    }
    const time = `${dayjs(parseISOLocal(startTime)).format('hh:mmA')} - ${dayjs(
      parseISOLocal(endTime)
    ).format('hh:mmA')}`;

    const formattedTime = {
      id: slotId,
      from: `${dayjs(parseISOLocal(startTime)).format('HH:mm')}`,
      until: `${dayjs(parseISOLocal(endTime)).format('HH:mm')}`,
    };

    setOpenCustomTimer(false);
    handleCustomTime(formattedTime as IAvailabilityTime, time, slotId);
    clearTimes();
  };

  const handleTimeSelection = (value: string) => {
    if (segmentTab === 'start-time') {
      setStartTime(value);
    } else if (segmentTab === 'end-time') {
      setEndTime(value);
    }
  };
  return (
    <IonModal
      isOpen={openCustomTimer}
      ref={modal}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      keepContentsMounted={true}
      className="custom-time-modal"
    >
      <Container>
        <Header>
          <HeaderButton fill="clear" onClick={closeModal}>
            Cancel
          </HeaderButton>

          <HeaderButton fill="clear" onClick={handleConfirm}>
            Confirm
          </HeaderButton>
        </Header>
        <IonSegmentContainer>
          <IonSegment
            value="start-time"
            onIonChange={({ detail }) => {
              setSegmentTab(detail.value as string);
            }}
          >
            <IonSegmentButton value="start-time">
              <TimeIonLabel>
                Start time <br />
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {startTime &&
                      dayjs(parseISOLocal(startTime)).format('hh:mm A')}
                  </motion.div>
                </AnimatePresence>
              </TimeIonLabel>
            </IonSegmentButton>
            <IonSegmentButton
              value="end-time"
              disabled={startTime.length > 0 ? false : true}
            >
              <TimeIonLabel>
                End time
                <br />
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {endTime && dayjs(parseISOLocal(endTime)).format('hh:mm A')}
                  </motion.div>
                </AnimatePresence>
              </TimeIonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonSegmentContainer>
        <StyledIonDatetime
          min={
            segmentTab === 'end-time'
              ? startTime
              : dayjs('1970-01-01').toISOString()
          }
          onIonChange={({ detail }) => {
            handleTimeSelection(detail.value as string);
          }}
          minuteValues="0,15,30,45"
          presentation="time"
          locale="en-US"
          hourCycle="h12"
          preferWheel
        ></StyledIonDatetime>
      </Container>
    </IonModal>
  );
};

export default AvailabilityCustomTime;
