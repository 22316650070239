import * as React from 'react';

export const DollarIconNoCircle = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.57 7.802v.001c-.144.765-.053 1.354.166 1.822.222.474.594.864 1.078 1.195.986.675 2.333 1.037 3.496 1.347h0l.558.149h.001c1.293.35 2.132.671 2.617 1.072.261.216.438.468.52.768.077.288.054.573.006.831h0c-.168.9-.94 1.371-1.743 1.585-.817.218-1.814.22-2.749.085-.537-.078-1.193-.246-1.995-.502-.39-.124-.797.087-.917.44a.68.68 0 00.458.863h0c.914.293 1.619.468 2.224.557h.015l.044.005.431.06V19.813c0 .367.31.687.721.687.413 0 .722-.321.722-.688v-1.689l.444-.05c2.14-.244 3.48-1.318 3.764-2.842h0c.143-.763.051-1.356-.165-1.827-.218-.477-.579-.866-1.03-1.19-.915-.659-2.116-.99-2.967-1.222h-.002l-.57-.152c-1.441-.382-2.412-.698-2.99-1.088-.305-.206-.539-.456-.662-.78L6.57 7.802zm0 0c.286-1.555 1.634-2.626 3.767-2.87l.443-.052V3.187c0-.367.31-.687.72-.687.413 0 .723.321.723.688V4.918l.43.06a.655.655 0 00.066.007c.94.134 1.94.465 2.87.776h0l.421.14h.002a.68.68 0 01.45.865.733.733 0 01-.92.435s0 0 0 0l-.43-.143h0l-.038-.012c-.803-.268-1.765-.589-2.585-.703-.939-.135-1.937-.133-2.754.085-.803.213-1.575.683-1.743 1.583l-1.422-.21zm6.13-2.819h.003-.003zm-2.396 13.034h0z"
      fill={props.fill}
      stroke="#23364B"
    />
  </svg>
);
