import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { personOutline } from 'ionicons/icons';
import { ReactNode } from 'react';
import { SystemChatMessageCard } from './SystemChatMessage.styles';

type SystemChatMessageProps = {
  message: ReactNode;
};
export const SystemChatMessage: React.FC<SystemChatMessageProps> = ({
  message,
}) => (
  <IonGrid>
    <IonRow className="ion-align-items-center">
      <IonCol size="auto" className="ion-margin-end">
        <IonIcon
          src={personOutline}
          style={{ fontSize: '23px', minWidth: '23px' }}
        />
      </IonCol>
      <IonCol>
        <SystemChatMessageCard>{message}</SystemChatMessageCard>
      </IonCol>
    </IonRow>
  </IonGrid>
);
