import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  useIonAlert,
  IonLabel,
  IonIcon,
  IonList,
  IonItem,
  // IonButton
} from '@ionic/react';
import { BryaLogo } from '../../assets/images/BryaLogo';
import {
  Container,
  Footer,
  HeaderContent,
  NotificationContainer,
  NotificationCounter,
  UserEmail,
  UserName,
  Version,
  Wrapper,
  WrapperLink,
} from './ProHeader.styles';
import { NotificationBell } from '../../assets/images/NotificaitionBell';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuthContext } from '../../providers/authProvider';
import { IUserData } from '../../types';
import { useHistory } from 'react-router';
import { getEnvLabel } from '../../utils/env';
import { useAppContext } from '../../providers/appContextProvider';
import { useNotifications } from '../../providers/notificationsProvider';
import { cogOutline, helpCircleOutline, logOutOutline } from 'ionicons/icons';
import { ROUTES } from '../../constants/routes';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { useTalkJsChat } from '../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';

// import CustomAlert from '../CustomAlert/CustomAlert';
// import { useDeleteAccountMutation } from '../../graphql/generated';

const ProHeader: React.FC = () => {
  const [presentAlert] = useIonAlert();
  const i18n = useTranslation();
  const { user, logout } = useAuthContext();
  // const { globalAlert } = useAppContext();
  const { env } = useAppContext();
  const history = useHistory();
  const menuRef = useRef<HTMLIonMenuElement>(null);
  const { unReadNotificationsCount } = useNotifications();
  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();

  {
    /* TODO: add again when backend has support to this */
  }
  // const [showRemoveAccountConfirm, setShowRemoveAccountConfirm] =
  //   useState(false);
  // const [deleteAccount, { loading: isDeletingAccount }] =
  //   useDeleteAccountMutation();

  useEffect(() => {
    return () => {
      void menuRef?.current?.close();
    };
  }, [history?.location.pathname]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      logger.error({
        tag: '[LOGOUT]',
        message: `Something went wrong when logging out: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
    } finally {
      history.replace(ROUTES.LOGIN);
    }
  };
  {
    /* TODO: add again when backend has support to this */
  }
  // const handleRemoveAccount = async () => {
  //   try {
  //     await deleteAccount();
  //     await logout();
  //     history.push(ROUTES.ACCOUNT_DELETED);
  //   } catch (err) {
  //     logger.error({
  //       tag: '[USER_DELETE_ACCOUNT]',
  //       message: `Error on deleteAccount mutation ${getApolloErrorMessage(
  //         err
  //       )}`,
  //       error: err as Error,
  //     });
  //     globalAlert({
  //       title: <Trans i18nKey="genericError.title" />,
  //       subtitle: getApolloErrorMessage(err) || (
  //         <Trans i18nKey="genericError.subtitle" />
  //       ),
  //       firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
  //     });
  //   } finally {
  //     setShowRemoveAccountConfirm(false);
  //   }
  // };

  return (
    <Container>
      {getBryaTeamChatQueryLoading && <FullScreenLoader />}
      <IonMenu ref={menuRef} contentId="trigger-menu-id">
        <IonHeader className="ion-no-border" translucent={true}>
          <IonToolbar color="#ffffff">
            <HeaderContent>
              <UserAvatar size="40px" user={user as IUserData}></UserAvatar>
              <Wrapper>
                <UserName>
                  {user?.firstName} {user?.lastName}
                </UserName>
                <UserEmail>{user?.email}</UserEmail>
              </Wrapper>
            </HeaderContent>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem
              key={'notificationsettings'}
              onClick={() => history.push(ROUTES.ACCOUNT.PREFERENCES)}
            >
              <WrapperLink>
                <IonIcon size="large" src={cogOutline} />
                <IonLabel>{i18n.t('accountPage.preferences')}</IonLabel>
              </WrapperLink>
            </IonItem>
            <IonItem
              key={'notificationsettings'}
              onClick={handleGetBryaTeamChat}
            >
              <WrapperLink>
                <IonIcon size="large" src={helpCircleOutline} />
                <IonLabel>{i18n.t('accountPage.help')}</IonLabel>
              </WrapperLink>
            </IonItem>
          </IonList>
        </IonContent>

        <Footer>
          <IonList>
            <IonItem lines="none">
              <WrapperLink>
                <IonIcon size="large" src={logOutOutline} />
                <IonLabel
                  data-testid="header-logout"
                  onClick={() => {
                    void presentAlert({
                      header: i18n.t('logoutText'),
                      buttons: [
                        {
                          text: i18n.t('cancel'),
                          role: 'cancel',
                        },
                        {
                          text: i18n.t('logOut'),
                          role: 'confirm',
                          handler: handleLogout,
                        },
                      ],
                    });
                  }}
                >
                  {i18n.t('logOut')}
                </IonLabel>
              </WrapperLink>
            </IonItem>
            <IonItem lines="none">
              <Wrapper>
                {/* TODO: add again when backend has support to this */}
                {/* <IonButton
                  strong
                  fill="clear"
                  color="danger"
                  onClick={() => setShowRemoveAccountConfirm(true)}
                >
                  {i18n.t('myAccount.removeAccount')}
                </IonButton> */}
                <Version>
                  {i18n.t('version')}{' '}
                  {process.env.REACT_APP_BUILD_VERSION || '0.0.0'}{' '}
                  {`(${getEnvLabel(env)})`}
                </Version>
              </Wrapper>
            </IonItem>
          </IonList>
        </Footer>
      </IonMenu>

      <IonHeader
        id="trigger-menu-id"
        className="ion-no-border"
        translucent={true}
      >
        <IonToolbar color="#ffffff" className="pro-toolbar">
          <IonButtons slot="start">
            <IonMenuButton
              id="click"
              style={{ color: '#666666' }}
            ></IonMenuButton>
          </IonButtons>
          <IonTitle>
            <BryaLogo width={84} height={32} />
          </IonTitle>
          <IonButtons slot="end">
            <NotificationContainer
              hasUnreadNotification={
                unReadNotificationsCount > 0 ? true : false
              }
              onClick={() => history.push('/notification-center')}
            >
              {unReadNotificationsCount > 0 && (
                <NotificationCounter>
                  {unReadNotificationsCount}{' '}
                  <Trans i18nKey="Notifications.new" />
                </NotificationCounter>
              )}
              <NotificationBell />
            </NotificationContainer>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/* <CustomAlert
        isOpen={showRemoveAccountConfirm}
        onDidDismiss={() => setShowRemoveAccountConfirm(false)}
        title={i18n.t('myAccount.removeAccountConfirmTitle')}

        subtitle={i18n.t('myAccount.removeAccountConfirmSubtitle')}
        firstButtonLabel={i18n.t(
          'myAccount.removeAccountConfirmFirstButtonLabel'
        )}
        secondButtonLabel={i18n.t(
          'myAccount.removeAccountConfirmSecondButtonLabel'
        )}
        firstButtonAction={handleRemoveAccount}
        secondButtonAction={() => setShowRemoveAccountConfirm(false)}
        isDisabled={isDeletingAccount}
      /> */}
    </Container>
  );
};

export default ProHeader;
