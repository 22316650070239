import { IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { EmptyOpenActivity } from '../../../../assets/images/EmptyOpenActivity';
import { SecondaryTitle } from '../../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../../components/Typography/Body/Body';

export const AgentConfirmedActivitiesEmptyState: React.FC = () => {
  return (
    <div className="ion-text-center">
      <IonIcon
        src={checkmarkOutline}
        style={{
          fontSize: '47px',
          minWidth: '47px',
          color: '#666666',
        }}
      />
      <SecondaryTitle>
        <Trans i18nKey="AgentConfirmedActivitiesEmptyState.TitleText" />
      </SecondaryTitle>
    </div>
  );
};

export const AgentRequestedActivitiesEmptyState: React.FC = () => {
  return (
    <div className="ion-text-center">
      <EmptyOpenActivity />
      <SecondaryTitle>
        <Trans i18nKey="AgentRequestedActivitiesEmptyState.TitleText" />
      </SecondaryTitle>
      <div className="ion-margin-top">
        <BodyText>
          <Trans i18nKey="AgentRequestedActivitiesEmptyState.DescriptionText" />
        </BodyText>
      </div>
    </div>
  );
};
