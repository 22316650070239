import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToggle,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { pixelToRem } from '../../utils/helper';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { Space } from '../Space/Space';
import {
  AvatarContainer,
  BackButton,
  Card,
  HeaderTitle,
  RateInstructions,
  RateQuestion,
  RateTitle,
  RateValue,
  Toolbar,
} from './RateProfessionalModalSheet.styles';
import { Button } from '../Button/Button';
import { Rating } from 'react-simple-star-rating';
import RatingEmpty from '../../assets/images/RatingEmpty';
import RatingCompleted from '../../assets/images/RatingCompleted';
import { Trans, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import {
  PositiveTags,
  useGetServiceRequestQuery,
  useReviewServiceRequestMutation,
} from '../../graphql/generated';
import { RateProfessionalSkeleton } from './RateProfessionalSkeleton';
import { heart, heartOutline } from 'ionicons/icons';
import { Chip } from '../Chip/Chip';
import { TextArea } from '../TextArea/TextArea';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { RequestCategoryName } from '../RequestCategoryName/RequestCategoryName';

interface WithSwiperProps {
  children: (swiper: SwiperClass) => JSX.Element | null;
}
const WithSwiper: React.FC<WithSwiperProps> = ({ children }) => {
  const swiper = useSwiper();
  return children(swiper);
};

interface PositiveRatingOptionsProps {
  isMarkedAsFavorite: boolean;
  onMarkedAsFavoriteToggle: () => void;
  selectedPositiveTags: PositiveTags[];
  onPositiveTagToggle: (tag: PositiveTags) => void;
}
const PositiveRatingOptions: React.FC<PositiveRatingOptionsProps> = ({
  isMarkedAsFavorite,
  onMarkedAsFavoriteToggle,
  selectedPositiveTags,
  onPositiveTagToggle,
}) => {
  return (
    <Space direction="column" size="medium">
      <RateQuestion>
        <Trans i18nKey={`rateProfessional.goodRateQuestion`} />
      </RateQuestion>
      <Space direction="row" size="4px" wrap="wrap">
        {Object.values(PositiveTags).map((tag) => (
          <Chip
            selected={selectedPositiveTags.includes(tag)}
            key={tag}
            onClick={() => {
              onPositiveTagToggle(tag);
            }}
          >
            <Trans i18nKey={`enums.reviewPositiveTags.${tag}`} />
          </Chip>
        ))}
      </Space>
      <Card>
        <Space direction="row" align="center" justify="space-between">
          <Space direction="row" align="center" size="small">
            <IonIcon
              icon={isMarkedAsFavorite ? heart : heartOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: isMarkedAsFavorite ? 'red' : 'inherit',
              }}
            />
            <Trans i18nKey="rateProfessional.markAsFavorite" />
          </Space>

          <IonToggle
            checked={isMarkedAsFavorite}
            onIonChange={() => {
              onMarkedAsFavoriteToggle();
            }}
          />
        </Space>
      </Card>
    </Space>
  );
};

interface NegativeRatingOptionsProps {
  // NOTE: For now PositiveTags and NegativeTags are the same
  // and because of that the enum is not generated
  selectedNegativeTags: PositiveTags[];
  onNegativeTagToggle: (tag: PositiveTags) => void;
}
const NegativeRatingOptions: React.FC<NegativeRatingOptionsProps> = ({
  selectedNegativeTags,
  onNegativeTagToggle,
}) => {
  return (
    <Space direction="column" size="medium">
      <RateQuestion>
        <Trans
          i18nKey={`rateProfessional.badRateQuestion`}
          components={{ b: <b /> }}
        />
      </RateQuestion>
      <Space direction="row" size="4px" wrap="wrap">
        {Object.values(PositiveTags).map((tag) => (
          <Chip
            selected={selectedNegativeTags.includes(tag)}
            key={tag}
            onClick={() => {
              onNegativeTagToggle(tag);
            }}
          >
            <Trans i18nKey={`enums.reviewPositiveTags.${tag}`} />
          </Chip>
        ))}
      </Space>
    </Space>
  );
};

const POSITIVE_RATING_THRESHOLD = 4;
interface RateProfessionalModalSheetProps {
  isOpen: boolean;
  onClose: () => void;
  serviceRequestId: number;
}
export const RateProfessionalModalSheet: React.FC<
  RateProfessionalModalSheetProps
> = ({ isOpen, onClose, serviceRequestId }) => {
  const i18n = useTranslation();
  const [flowCompleted, setFlowCompleted] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const [showNoRatingError, setShowNoRatingError] = useState(false);
  const [isMarkedAsFavorite, setIsMarkedAsFavorite] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const [selectedPositiveTags, setSelectedPositiveTags] = useState<
    PositiveTags[]
  >([]);
  const [selectedNegativeTags, setSelectedNegativeTags] = useState<
    PositiveTags[]
  >([]);
  const { data, error, loading } = useGetServiceRequestQuery({
    variables: {
      getServiceRequestId: serviceRequestId,
    },
  });

  const [reviewComment, setReviewComment] = useState<string>('');
  const [reviewServiceRequest, { loading: isSubmitting }] =
    useReviewServiceRequestMutation();
  const serviceRequest = data?.getServiceRequest;

  const finishFlow = useCallback(() => {
    setFlowCompleted(true);
  }, []);

  useEffect(() => {
    if (error) {
      logger.error({
        tag: '[RateProfessionalModalSheet][useGetServiceRequestQuery]',
        message: `Fail while getting service request: ${getApolloErrorMessage(
          error
        )}`,
        error: error as Error,
      });
      finishFlow();
    }
  }, [error]);

  useEffect(() => {
    if (flowCompleted) {
      onClose();
    }
  }, [flowCompleted]);

  const handleRatingChange = useCallback((newRating: number) => {
    setRating(newRating);
  }, []);

  const resetForm = useCallback(() => {
    setRating(0);
    setSwiperIndex(0);
    setShowNoRatingError(false);
    setIsMarkedAsFavorite(false);
    setSelectedPositiveTags([]);
    setSelectedNegativeTags([]);
    setFlowCompleted(false);
  }, []);

  const handlePositiveTagToggle = useCallback(
    (tag: PositiveTags) => {
      if (selectedPositiveTags.includes(tag)) {
        setSelectedPositiveTags((prev) => prev.filter((t) => t !== tag));
      } else {
        setSelectedPositiveTags((prev) => [...prev, tag]);
      }
    },
    [selectedPositiveTags]
  );

  const handleNegativeTagToggle = useCallback(
    (tag: PositiveTags) => {
      if (selectedNegativeTags.includes(tag)) {
        setSelectedNegativeTags((prev) => prev.filter((t) => t !== tag));
      } else {
        setSelectedNegativeTags((prev) => [...prev, tag]);
      }
    },
    [selectedNegativeTags]
  );

  const handleSubmit = async () => {
    try {
      await reviewServiceRequest({
        variables: {
          data: {
            rating,
            serviceRequestId,
            comment: reviewComment,
            positiveTags: selectedPositiveTags,
            negativeTags: selectedNegativeTags,
            markAsFavorite: isMarkedAsFavorite,
          },
        },
      });
      finishFlow();
    } catch (err) {
      logger.error({
        tag: '[RateProfessionalModalSheet][SubmitReview]',
        message: `Fail when submitting review: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      // TODO: display error or fail silently?
      // NOTE: We finish the flow even if there was an error In order to avoid
      // keeping the user for eternal in RateProfessional flow due an internal bug
      finishFlow();
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={flowCompleted}
      breakpoints={[1]}
      initialBreakpoint={1}
      onDidDismiss={() => {
        resetForm();
      }}
    >
      {loading || !serviceRequest ? (
        <RateProfessionalSkeleton />
      ) : (
        <IonContent className="ion-padding">
          <Swiper
            slidesPerView={1}
            allowTouchMove={false}
            onActiveIndexChange={(swiperCore) => {
              setSwiperIndex(swiperCore.activeIndex);
            }}
          >
            <div slot="container-start">
              <IonHeader>
                <Toolbar>
                  {
                    <WithSwiper>
                      {(swiper) =>
                        swiperIndex !== 0 ? (
                          <IonButtons slot="start">
                            <BackButton
                              disabled={isSubmitting}
                              onClick={() => {
                                swiper.slidePrev();
                              }}
                            >
                              <Trans i18nKey="rateProfessional.cta.back" />
                            </BackButton>
                          </IonButtons>
                        ) : null
                      }
                    </WithSwiper>
                  }
                  <IonTitle>
                    <HeaderTitle>
                      <RequestCategoryName
                        category={serviceRequest.RequestCategory?.name}
                      />
                    </HeaderTitle>
                  </IonTitle>
                </Toolbar>
              </IonHeader>

              <AvatarContainer>
                <UserAvatar
                  size="88px"
                  fontSize={pixelToRem(32)}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  user={serviceRequest.Agent!.User}
                />
              </AvatarContainer>
            </div>

            <SwiperSlide>
              <Space direction="column" size="large" align="center">
                <RateTitle>
                  <Trans
                    i18nKey="rateProfessional.title"
                    values={{
                      fullName: serviceRequest.Agent?.User?.fullName,
                    }}
                  />
                </RateTitle>
                <Space direction="column" size="small" align="center">
                  <Rating
                    onClick={handleRatingChange}
                    emptyIcon={
                      <span style={{ margin: '0 5px' }}>{RatingEmpty()}</span>
                    }
                    fillIcon={
                      <span style={{ margin: '0 5px' }}>
                        {RatingCompleted()}
                      </span>
                    }
                    initialValue={rating}
                    disableFillHover={true}
                    allowHover={false}
                  />
                  {rating ? (
                    <RateValue>
                      <Trans i18nKey={`rateProfessional.rates.${rating}`} />
                    </RateValue>
                  ) : (
                    showNoRatingError && (
                      <RateValue style={{ color: 'var(--interaction-error)' }}>
                        <Trans i18nKey="rateProfessional.errors.missingRating" />
                      </RateValue>
                    )
                  )}
                </Space>
                <Space direction="column" size="small">
                  <RateInstructions>
                    <Trans i18nKey="rateProfessional.instructions" />
                  </RateInstructions>
                  <TextArea
                    placeholder={String(
                      i18n.t('rateProfessional.inputPlaceholder')
                    )}
                    onIonInput={(e) => {
                      setReviewComment(e.detail.value || '');
                    }}
                  />
                </Space>
                <WithSwiper>
                  {(swiper) => (
                    <Button
                      color="primary-orange"
                      onClick={() => {
                        if (!rating) {
                          setShowNoRatingError(true);
                          return;
                        }
                        swiper.slideNext();
                      }}
                    >
                      <Trans i18nKey="rateProfessional.cta.continue" />
                    </Button>
                  )}
                </WithSwiper>
              </Space>
            </SwiperSlide>

            <SwiperSlide>
              <Space direction="column" size="large" align="center">
                {rating >= POSITIVE_RATING_THRESHOLD ? (
                  <PositiveRatingOptions
                    isMarkedAsFavorite={isMarkedAsFavorite}
                    onMarkedAsFavoriteToggle={() =>
                      setIsMarkedAsFavorite((prev) => !prev)
                    }
                    selectedPositiveTags={selectedPositiveTags}
                    onPositiveTagToggle={handlePositiveTagToggle}
                  />
                ) : (
                  <NegativeRatingOptions
                    selectedNegativeTags={selectedNegativeTags}
                    onNegativeTagToggle={handleNegativeTagToggle}
                  />
                )}
                <Button
                  loading={isSubmitting}
                  color="primary-orange"
                  onClick={() => {
                    void handleSubmit();
                  }}
                >
                  <Trans i18nKey="rateProfessional.cta.done" />
                </Button>
              </Space>
            </SwiperSlide>
          </Swiper>
        </IonContent>
      )}
    </IonModal>
  );
};
