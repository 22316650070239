import { IonSkeletonText } from '@ionic/react';
import { Card } from './SelectPaymentCard.style';

export const SkeletonPage: React.FC = () => {
  return (
    <Card style={{ padding: 12 }}>
      <IonSkeletonText
        animated
        style={{ width: '100%', height: '45px', marginBottom: '8px' }}
      />
      <IonSkeletonText
        animated
        style={{ width: '100%', height: '45px', marginBottom: '8px' }}
      />
      <IonSkeletonText animated style={{ width: '100%', height: '45px' }} />
    </Card>
  );
};
