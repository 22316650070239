/* eslint-disable */
import * as React from 'react';

export const CircleNotSelected = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8233_88898)">
      <mask
        id="mask0_8233_88898"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="23"
        height="23"
      >
        <path d="M22.166 23H0.166016V0H22.166V23Z" fill="white" />
      </mask>
      <g mask="url(#mask0_8233_88898)">
        <path
          d="M16.1252 6.92572C15.1493 5.81577 13.897 5.01204 12.5087 4.60462C12.2746 4.95279 11.9637 5.23688 11.6025 5.4331C11.2412 5.62923 10.8402 5.73162 10.4331 5.73162C10.026 5.73162 9.62492 5.62923 9.26367 5.4331C8.90241 5.23688 8.5916 4.95279 8.35749 4.60462C6.96916 5.01156 5.7169 5.81513 4.74141 6.92516C4.63715 7.04413 4.51148 7.14049 4.37159 7.20878C4.2317 7.27698 4.08034 7.31577 3.92612 7.32282C3.7719 7.32988 3.61787 7.30506 3.47281 7.24994C3.32775 7.19474 3.1945 7.11027 3.08068 7.00122C2.96686 6.89225 2.87468 6.76083 2.80943 6.61458C2.74417 6.46833 2.7071 6.31011 2.70034 6.14887C2.69359 5.98763 2.71727 5.82663 2.77005 5.67499C2.82282 5.52335 2.90364 5.384 3.00791 5.26504C4.3566 3.73062 6.10899 2.64716 8.04487 2.15076C8.22147 1.63659 8.54563 1.19197 8.97304 0.877668C9.40044 0.563361 9.91024 0.394668 10.4329 0.394668C10.9554 0.394668 11.4652 0.563361 11.8926 0.877668C12.32 1.19197 12.6442 1.63659 12.8208 2.15076C14.7571 2.64692 16.5098 3.73038 17.8588 5.26504C17.9649 5.38361 18.0475 5.52311 18.1018 5.67531C18.1561 5.82759 18.1809 5.98954 18.1749 6.15189C18.1689 6.31423 18.1321 6.47373 18.0667 6.62109C18.0013 6.76844 17.9086 6.90081 17.794 7.0105C17.6793 7.12011 17.545 7.20497 17.3987 7.25993C17.2525 7.31497 17.0973 7.33908 16.9421 7.33099C16.7869 7.32282 16.6348 7.28261 16.4946 7.21258C16.3543 7.14255 16.2289 7.04421 16.1252 6.9231V6.92572Z"
          fill="#23364B"
        />
        <path
          d="M20.0144 15.5588C20.2663 15.9606 20.4095 16.4262 20.4287 16.9062C20.448 17.3862 20.3425 17.8627 20.1237 18.2851C19.9048 18.7075 19.5807 19.0601 19.1856 19.3054C18.7905 19.5508 18.3393 19.6797 17.8798 19.6786C17.8102 19.6786 17.7447 19.6629 17.6761 19.6572C16.2998 21.1638 14.5248 22.2068 12.5782 22.6527C12.4267 22.6892 12.2698 22.6939 12.1166 22.6665C11.9634 22.6391 11.8168 22.5802 11.6855 22.4932C11.5542 22.4062 11.4407 22.2928 11.3517 22.1596C11.2626 22.0264 11.1996 21.8761 11.1666 21.7173C11.1335 21.5585 11.1308 21.3944 11.1589 21.2346C11.1869 21.0747 11.2449 20.9222 11.3298 20.786C11.4145 20.6498 11.5243 20.5325 11.6528 20.441C11.7812 20.3494 11.9258 20.2854 12.0781 20.2527C13.4166 19.9454 14.6517 19.2683 15.6546 18.2919C15.4429 17.9012 15.3303 17.4604 15.3275 17.0114C15.3285 16.3045 15.5976 15.6268 16.0758 15.127C16.5538 14.6271 17.2021 14.3458 17.8782 14.3447C17.8897 14.3447 17.9002 14.3484 17.9112 14.3484C18.0713 13.7014 18.1528 13.0359 18.1538 12.3677C18.154 11.5336 18.0303 10.7046 17.7872 9.91015C17.7397 9.75629 17.7217 9.59418 17.7343 9.43302C17.7468 9.27186 17.7897 9.11491 17.8604 8.97112C17.9311 8.82733 18.0283 8.69956 18.1463 8.59518C18.2644 8.49073 18.401 8.41166 18.5483 8.36257C18.6957 8.31347 18.8509 8.29523 19.0049 8.30895C19.159 8.32267 19.309 8.36812 19.4463 8.44259C19.5835 8.51698 19.7054 8.61906 19.8049 8.74286C19.9044 8.86666 19.9794 9.00982 20.0258 9.164C20.3418 10.1991 20.5025 11.2791 20.5025 12.3656C20.5003 13.4502 20.3356 14.5276 20.0144 15.5588Z"
          fill="#23364B"
        />
        <path
          d="M3.07536 9.91173C2.83213 10.7061 2.70828 11.5351 2.70826 12.3692C2.70897 13.0449 2.79199 13.7177 2.95533 14.3714C3.36007 14.4075 3.75051 14.545 4.09358 14.7725C4.43666 14.9999 4.72225 15.3104 4.92615 15.6777C5.13006 16.0449 5.24627 16.4582 5.26495 16.8824C5.28362 17.3067 5.20421 17.7293 5.03343 18.1147C6.06461 19.1848 7.36555 19.9263 8.78455 20.2527C8.93681 20.2854 9.08133 20.3494 9.20984 20.441C9.33827 20.5325 9.44804 20.6498 9.53286 20.786C9.61759 20.9222 9.6757 21.0747 9.7037 21.2346C9.73169 21.3944 9.72911 21.5585 9.69596 21.7173C9.66288 21.8761 9.59999 22.0264 9.51093 22.1596C9.42179 22.2928 9.30838 22.4062 9.17706 22.4932C9.04575 22.5802 8.89926 22.6391 8.74602 22.6665C8.59277 22.6939 8.43589 22.6892 8.28439 22.6527C6.3266 22.2039 4.54258 21.1515 3.16289 19.6315C3.01581 19.6611 2.86651 19.6768 2.71676 19.6786C2.04051 19.6778 1.39217 19.3966 0.913988 18.8966C0.435803 18.3967 0.16681 17.7189 0.166016 17.0119C0.168427 16.3854 0.383612 15.7802 0.773193 15.3042C0.501214 14.3508 0.361913 13.3615 0.359572 12.3666C0.359637 11.2802 0.520763 10.2004 0.837211 9.16558C0.912933 8.91734 1.06217 8.70067 1.26337 8.54696C1.46457 8.39318 1.70731 8.31022 1.95653 8.31014C2.1413 8.31046 2.3234 8.35622 2.48807 8.44386C2.65274 8.5315 2.79533 8.65847 2.9043 8.8144C3.01328 8.9704 3.08555 9.15107 3.11527 9.34173C3.14499 9.53239 3.13132 9.72765 3.07536 9.91173Z"
          fill="#23364B"
        />
        <path
          d="M3.27025 13.8475C4.97712 13.8475 6.36081 15.2941 6.36081 17.0785C6.36081 18.863 4.97712 20.3096 3.27025 20.3096C1.56338 20.3096 0.179688 18.863 0.179688 17.0785C0.179688 15.2941 1.56338 13.8475 3.27025 13.8475Z"
          fill="#23364B"
        />
        <path
          d="M18.2829 13.8475C19.9898 13.8475 21.3735 15.2941 21.3735 17.0785C21.3735 18.863 19.9898 20.3096 18.2829 20.3096C16.5761 20.3096 15.1924 18.863 15.1924 17.0785C15.1924 15.2941 16.5761 13.8475 18.2829 13.8475Z"
          fill="#23364B"
        />
        <path
          d="M10.3357 7.9155e-05C12.0425 7.9155e-05 13.4262 1.44666 13.4262 3.23112C13.4262 5.01557 12.0425 6.46216 10.3357 6.46216C8.62881 6.46216 7.24512 5.01557 7.24512 3.23112C7.24512 1.44666 8.62881 7.9155e-05 10.3357 7.9155e-05Z"
          fill="#23364B"
        />
        <path
          d="M3.27102 14.7705C4.49021 14.7705 5.47856 15.8037 5.47856 17.0783C5.47856 18.353 4.49021 19.3862 3.27102 19.3862C2.05183 19.3862 1.06348 18.353 1.06348 17.0783C1.06348 15.8037 2.05183 14.7705 3.27102 14.7705Z"
          fill="white"
        />
        <path
          d="M18.2827 14.7705C19.5019 14.7705 20.4903 15.8037 20.4903 17.0783C20.4903 18.353 19.5019 19.3862 18.2827 19.3862C17.0635 19.3862 16.0752 18.353 16.0752 17.0783C16.0752 15.8037 17.0635 14.7705 18.2827 14.7705Z"
          fill="white"
        />
        <path
          d="M10.3345 0.923295C11.5537 0.923295 12.542 1.95657 12.542 3.23118C12.542 4.50579 11.5537 5.53906 10.3345 5.53906C9.1153 5.53906 8.12695 4.50579 8.12695 3.23118C8.12695 1.95657 9.1153 0.923295 10.3345 0.923295Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8233_88898">
        <rect
          width="22"
          height="23"
          fill="white"
          transform="matrix(1 0 0 -1 0.166016 23)"
        />
      </clipPath>
    </defs>
  </svg>
);
