import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import {
  SecondaryTitle,
  TertiaryTitle,
} from '../../../components/Typography/Headings/Headings';
import { Trans } from 'react-i18next';
import {
  AboutYouForm,
  AboutYouFormFields,
} from '../../../components/Forms/AboutYouForm';
import { Variants, motion } from 'framer-motion';
import {
  AppUser,
  useCreateCustomerWithAdditionalInformationMutation,
  useGetUserRelatedCustomersQuery,
} from '../../../graphql/generated';
import { useAuthContext } from '../../../providers/authProvider';
import { IUserData } from '../../../types';
import { useAppContext } from '../../../providers/appContextProvider';
import { logger } from '../../../logger';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames } from '../../../constants/enums';

const animation: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const MembershipAboutYouPage: React.FC = () => {
  const [createCustomerWithAdditionalInformation] =
    useCreateCustomerWithAdditionalInformationMutation();
  const { user, refreshToken } = useAuthContext() as {
    user: IUserData;
    refreshToken: () => Promise<IUserData | undefined>;
  };

  const { data: userRelatedCustomers } = useGetUserRelatedCustomersQuery({
    variables: {
      roles: [AppUser.CircleMember, AppUser.FamilyPartner],
      userId: user?.id,
    },
  });

  const hasConnection =
    userRelatedCustomers?.getUserRelatedCustomers &&
    userRelatedCustomers?.getUserRelatedCustomers?.length > 0;

  const { globalAlert } = useAppContext();
  const history = useHistory();
  const { isFeatureEnabled } = useFeatureFlags();

  const navigateToNextStep = (u: IUserData) => {
    if (!isFeatureEnabled(FeatureNames.MembershipFlow)) {
      history.push(ROUTES.TABS.ASK_BRYA);
      return;
    }

    if (u.appFeaturesSelected?.seniorMember) {
      history.push(ROUTES.MEMBERSHIP.OUTRO);
      return;
    }

    if (hasConnection) {
      history.push(ROUTES.MEMBERSHIP.OUTRO);
      return;
    }

    // TODO: how to handle multiple combinations? maybe this logic can be inside getUserLandingRoute?
    if (u.appFeaturesSelected?.familyPartner) {
      history.push(ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE);
      return;
    }

    if (u.appFeaturesSelected?.circleMember) {
      history.push(ROUTES.MEMBERSHIP.ABOUT_FRIEND);
      return;
    }
  };

  const onSubmit = async (data: AboutYouFormFields) => {
    try {
      // NOTE: avoid creating multiple customers
      if (user?.customerId) {
        navigateToNextStep(user);
        return;
      }

      const createCustomerResponse =
        await createCustomerWithAdditionalInformation({
          variables: {
            role: AppUser.SeniorMember,
            customer: {
              ...data,
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
            },
          },
        });
      const newCustomerId =
        createCustomerResponse.data?.createCustomerWithAdditionalInformation.id;

      if (!newCustomerId) {
        throw new Error('missing customerId in create customer response');
      }

      const updatedUser = await refreshToken();
      if (!updatedUser) throw new Error('fail refreshing token');
      navigateToNextStep(updatedUser);
    } catch (err) {
      logger.error({
        tag: '[ABOUT_YOU_FORM_SUBMIT]',
        message: `Fail trying submitting about yourself form: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <motion.div initial="initial" animate="animate" variants={animation}>
          <IonGrid>
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <SecondaryTitle style={{ textAlign: 'center' }}>
                  <Trans i18nKey="membership.aboutYou.title" />
                </SecondaryTitle>
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <TertiaryTitle style={{ textAlign: 'center' }}>
                  <Trans i18nKey="membership.aboutYou.description" />
                </TertiaryTitle>
              </IonCol>
            </IonRow>
            <AboutYouForm key={user.id} onSubmit={onSubmit} />
          </IonGrid>
        </motion.div>
      </IonContent>
    </IonPage>
  );
};

export default MembershipAboutYouPage;
