import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { GetCoachVisitQuery } from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { useHistory } from 'react-router';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { useAppContext } from '../../providers/appContextProvider';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { ActivityCardItemCoach } from '../ActivityCardItemCoach/ActivityCardItemCoach';
import { ActivityCardItemPhoneCall } from '../ActivityCardItemPhoneCall/ActivityCardItemPhoneCall';

type CoachVisitQueryResult = GetCoachVisitQuery['getCoachVisit'];

type CancelledCustomerActivityCoachVisitProps = {
  coachVisit: CoachVisitQueryResult;
};

export const CancelledCustomerActivityCoachVisit: React.FC<
  CancelledCustomerActivityCoachVisitProps
> = ({ coachVisit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { globalAlert } = useAppContext();
  const connectWithCoachErrorHandler = useDefaultMutationErrorHandler({
    tag: '[CancelledCustomerActivityCoachVisit][ConnectWithCoach]',
  });
  const { directChatMessage, loading: isCreatingChat } = useDirectChatMessage({
    onError: connectWithCoachErrorHandler,
  });

  const handleRequestNewMeeting = () => {
    globalAlert({
      title: t(
        'CancelledCustomerActivityCoachVisit.RequestNewMeetingAlertTitle'
      ),
      subtitle: t(
        'CancelledCustomerActivityCoachVisit.RequestNewMeetingAlertSubtitle'
      ),
      firstButtonLabel: t(
        'CancelledCustomerActivityCoachVisit.RequestNewMeetingAlertFirstButtonLabel'
      ),
      firstButtonAction: () => {
        return directChatMessage(coachVisit.Coach.id);
      },
      secondButtonLabel: t(
        'CancelledCustomerActivityCoachVisit.RequestNewMeetingAlertSecondButtonLabel'
      ),
    });
  };

  const loading = isCreatingChat;

  return (
    <>
      <PageWithBottomSheetLayout
        title={t('CancelledCustomerActivityCoachVisit.Title')}
        background="var(--colors-gradient-red)"
        bottomSheetBackground="var(--colors-gradient-red-sheet)"
        bottomSheetTitle={
          <>
            <IconActivityCancelled />
            {t('CancelledCustomerActivityCoachVisit.SheetTitle')}
          </>
        }
        startSlotHeaderAction={
          <HeaderBackButton onClick={() => history.goBack()} />
        }
      >
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <BodyText>
              {t('CancelledCustomerActivityCoachVisit.SheetBody')}
            </BodyText>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <CardList>
              <ActivityCardItemCoach coach={coachVisit.Coach} />
              {coachVisit.Address ? (
                <ActivityCardItemAddress address={coachVisit.Address} />
              ) : (
                <ActivityCardItemPhoneCall />
              )}
              <ActivityCardItemRequestScheduledAt
                scheduledAt={coachVisit.scheduledAt}
              />
              {/* TODO: Show Timeline when backend returns that */}
            </CardList>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <Button
              color="secondary-red"
              onClick={handleRequestNewMeeting}
              loading={loading}
            >
              {t('CancelledCustomerActivityCoachVisit.SecondaryCta')}
            </Button>
          </IonRow>
        </IonGrid>
      </PageWithBottomSheetLayout>
    </>
  );
};
