import * as React from 'react';

export const CompletedMark = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="20" height="20" rx="10" fill="#3F51B5" />
    <path
      d="M13.75 7.00021L8.49997 13.0002L6.24997 10.7502"
      stroke="white"
      strokeWidth="2.0625"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
