import React, { useEffect, useRef, useState } from 'react';

import { IonModal, IonToast } from '@ionic/react';
import {
  Container,
  Content,
  Header,
  HeaderButton,
} from './AvailabilityModal.styles';
import AvailabilityDates from '../AvailabilityDates/AvailabilityDates';
import { checkmarkCircle } from 'ionicons/icons';
import { IAvailabilityTime } from '../../types';
import { ServiceRequestTentativeSchedule } from '../../graphql/generated';

type AvailabilityModalProps = {
  AvailabilityModalIsOpen: boolean;
  setAvailabilityModalIsOpen: (value: boolean) => void;
  setAvailabilitySaved: (value: boolean) => void;
  setValue: (key: string, value: {}[]) => void;
  tentativeSchedule: ServiceRequestTentativeSchedule;
};

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  AvailabilityModalIsOpen,
  setAvailabilityModalIsOpen,
  setAvailabilitySaved,
  setValue,
  tentativeSchedule,
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [tentativeScheduleValue, setTentativeScheduleValue] =
    useState<ServiceRequestTentativeSchedule>(tentativeSchedule);
  const [selectedAvailability, setSelectedAvailability] = useState<
    IAvailabilityTime[]
  >([]);
  const closeModal = (): void => {
    setAvailabilityModalIsOpen(false);
  };
  const handleSave = (): void => {
    const availabilityWithoutIndexKey = selectedAvailability.map((value) => {
      delete value.id;
      return value;
    });
    setValue('availabilities', availabilityWithoutIndexKey);
    setOpenToast(true);
    closeModal();
    setAvailabilitySaved(true);
    setTimeout(() => {
      setOpenToast(false);
    }, 2900);
  };

  useEffect(() => {
    if (
      tentativeSchedule === ServiceRequestTentativeSchedule.WithinDays ||
      (tentativeSchedule === ServiceRequestTentativeSchedule.WithinWeeks &&
        tentativeScheduleValue !== ServiceRequestTentativeSchedule.WithinDays)
    ) {
      setSelectedAvailability([]);
      setValue('availabilities', []);
    }
    setTentativeScheduleValue(tentativeSchedule);
  }, [tentativeSchedule]);

  return (
    <>
      <IonModal
        isOpen={AvailabilityModalIsOpen}
        ref={modal}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        keepContentsMounted={true}
      >
        <Container>
          <Header>
            <HeaderButton onClick={closeModal} fill="clear">
              Back
            </HeaderButton>
            <h2>Availability</h2>
            <HeaderButton onClick={handleSave} fill="clear">
              Save
            </HeaderButton>
          </Header>
          <Content>
            <AvailabilityDates
              setSelectedAvailability={setSelectedAvailability}
              selectedAvailability={selectedAvailability}
              tentativeSchedule={tentativeSchedule}
            />
          </Content>
        </Container>
      </IonModal>
      <IonToast
        isOpen={openToast}
        message="Availability saved successfully!"
        duration={3000}
        icon={checkmarkCircle}
      ></IonToast>
    </>
  );
};

export default AvailabilityModal;
