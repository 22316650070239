import styled from 'styled-components';

export type LabelProps = {
  width: number;
};

export const Title = styled.p`
  color: #23364b;
  font-size: 30px;
  line-height: 30px;
  margin-top: 60px;
  margin-bottom: 8px;
`;

export const Subtitle = styled.p<LabelProps>`
  color: #616161;
  font-size: 16px;
  line-height: 20px;
  width: ${(props) => props.width / 1.25}px;
`;

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
`;

export const DoneButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  background-color: transparent;
`;

export const BackLabel = styled.span`
  font-size: 16px;
  color: #ea8231;
  text-transform: capitalize;
`;

export const DoneLabel = styled.span`
  font-size: 16px;
  color: #ea8231;
`;

export const SearchButtonLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const LocationsHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
