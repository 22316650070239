import { IonHeader, isPlatform } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonHeader = styled(IonHeader)`
  ion-toolbar {
    --background: #ffffff;
    --border-style: none;
    ${isPlatform('android') &&
    `
        --padding-top: 1.5rem;
    `}
  }
  h1 {
    color: var(--body-text-900, #212121);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 110%;
    margin-left: 1.5rem;
  }
`;
