import { IonContent, IonHeader } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import HalfCircle from '../../assets/half-circle.svg';

export const StyledIonHeader = styled(IonHeader)`
  min-height: 185px;
  .border {
    width: 100%;
    height: 10px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 20px;
    z-index: 99;
  }

  ion-toolbar {
    width: 100%;
    height: 165px;
    color: #fff;
    --background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 86.67%),
      linear-gradient(211deg, #848b8b 0%, #676c6c 57.29%, #404444 100%);

    &:before {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 50px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      bottom: -15px;
      opacity: 0.2;
      transform: rotate(-100deg);
    }
    &:after {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 50px;
      height: 150px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      right: 2px;
      top: 20px;
      opacity: 0.3;
    }
  }
  ion-button {
    --color: #fff;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.4px;
  }
`;
export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 120px;
  z-index: 999;
  padding: 0 16px;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BryaUsersAvatar = styled.div<{ size?: number; fontSize?: number }>`
  display: flex;
  width: ${({ size }) => (size ? `${size}px` : '17px')};
  height: ${({ size }) => (size ? `${size}px` : '17px')};
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  border: 1px solid var(--primary-orange, #ea8231);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  color: var(--primary-orange, #ea8231);
  font-size: ${({ fontSize }) =>
    fontSize ? pixelToRem(fontSize) : pixelToRem(8)};
  font-weight: 400;
`;

export const ProfileInfo = styled.div`
  width: 100%;
  min-height: 100px;
  h1,
  span {
    color: var(--neutral-1, #fff);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  span {
    font-weight: 500;
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      transform: translateY(-2px);
      margin-left: 4px;
    }
  }
`;
export const ProfileDescription = styled.div`
  margin-top: 16px;
  p {
    margin: 4px 0 0;
    color: var(--body-text-900, #212121);
    font-size: 10px;
    font-weight: 600;
    line-height: 120%;
    span {
      font-size: inherit;
      color: var(--primary-blue, #23364b);
    }
    small {
      font-size: inherit;
      color: var(--body-text-900, #212121);
    }
  }
`;

export const ProfileImageContainer = styled.div`
  margin-right: 16px;
`;

export const TabContainer = styled(IonContent)`
  --padding-top: 40px;
  .react-tabs {
    font-weight: 500;
    font-size: ${pixelToRem(12)};
    color: #666666;
    &__tab {
      min-height: 40px;
      position: unset;
      max-width: 179px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__tab-list {
      display: flex;
      justify-content: space-around;
      border: none;
    }
    &__tab--selected {
      color: #ea8231;
      border: none;
      border-bottom: 2px solid #ea8231;
    }
  }
`;

export const UserFavoriteContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const UserFavoriteLabel = styled.h2`
  color: var(--primary-blue, #23364b);
  font-size: ${pixelToRem(18)};
  font-weight: 400;
  line-height: 140%;
  margin-left: 16px;
`;

export const StyledIonContent = styled(IonContent)`
  --padding-end: 32px;
  --padding-start: 32px;
  --padding-top: 32px;
`;
