import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BodyText } from '../Typography/Body/Body';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import { Title } from '../ModalPostVisitInfo/ModalPostVisitInfo.style';
import { Button } from '../Button/Button';
import {
  GetServiceRequestQuery,
  GetServiceRequestReviewQuery,
  GraphqlServiceRequestPayment,
  ServicePaymentType,
} from '../../graphql/generated';
import {
  IconContainer,
  IonAccordContainer,
  PaymentCompleteContainer,
} from './ServiceSummary.styles';
import { Steps } from './ServiceSummary';
import IconPaymentCompleted from '../../assets/images/IconPaymentCompleted';
import { IonAccordion, IonItem, IonLabel } from '@ionic/react';
import { DollarIconNoCircle } from '../../assets/images/DollarIconNoCircle';
import CardIcon from '../../assets/images/CardIcon';
import CostLine from '../CostTableCard/CostLine';
import { TertiaryTitle } from '../Typography/Headings/Headings';
import PaymentMethod from '../CostTableCard/PaymentMethod';
import styled from 'styled-components';
import { calculateTotalValueWithLabel, pixelToRem } from '../../utils/helper';
import { formatNumber } from '../../utils/formatNumber';

type PaymentCompleteProps = {
  serviceSummaryData?: GetServiceRequestQuery;
  serviceReqReviewData?: GetServiceRequestReviewQuery;
  setNewStep?: (Steps: Steps) => void;
  onClose: () => void;
};

export const ServicePaymentTypeLabel = {
  [ServicePaymentType.Fixed]: 'Fixed',
  [ServicePaymentType.Hour]: 'Hourly Rate',
  [ServicePaymentType.Visit]: 'Visit',
};

export const StyledP = styled.p`
  .&& {
    font-size: ${() => pixelToRem(24)};
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: '#000000 !important';
  }
`;

export const PaymentComplete: FC<PaymentCompleteProps> = ({
  serviceSummaryData,
  serviceReqReviewData,
  setNewStep,
  onClose,
}) => {
  const { t } = useTranslation();

  const handleRateAndReviewProfessional = () =>
    setNewStep ? setNewStep(Steps.RateProfessionalStep) : null;

  const serviceRequestPayments =
    serviceSummaryData?.getServiceRequest.ServiceRequestPayments?.sort(
      (a, b) => a.createdAt.getTime() - b.createdAt.getTime()
    );
  const lastRequestedPayment = Number(serviceRequestPayments?.length) - 1 ?? 0;
  const initialServiceCost = serviceRequestPayments?.[0];
  const lastServiceCost = serviceRequestPayments?.[lastRequestedPayment];

  const totalCost = serviceRequestPayments?.reduce((acc, payment) => {
    const totalAmount = payment.amount + payment.fee + payment.tax;
    acc = acc + totalAmount;
    return acc;
  }, 0);

  const totalCostLabel =
    totalCost && !isNaN(totalCost) ? `$${totalCost.toFixed(2)}` : '-';

  const hasReview = Boolean(
    serviceReqReviewData?.getServiceRequestReview?.rating
  );

  return (
    <>
      <IconContainer>
        <IconPaymentCompleted />
      </IconContainer>

      <Title>{t('serviceSummary.paymentComplete')}</Title>

      <BodyText className="ion-text-center">
        {t('serviceSummary.successfullyProcessed')}
      </BodyText>

      <IonAccordContainer>
        <IonAccordion toggleIcon="" value="first" readonly>
          <IonItem slot="header">
            <CardIcon width={18} style={{ marginRight: '10px' }} />
            <IonLabel>
              <Trans i18nKey={'serviceSummary.transactionId'}></Trans>
              <br />#
              {
                serviceSummaryData?.getServiceRequest
                  .ServiceRequestPayments?.[0].stripeTransactionId
              }
            </IonLabel>
          </IonItem>
        </IonAccordion>
        <IonAccordion style={{ padding: '0' }} value="second">
          <IonItem slot="header">
            <DollarIconNoCircle style={{ marginRight: '10px' }} />
            <IonLabel>
              {t('serviceSummary.totalCustomerCost')} {totalCostLabel}
            </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <PaymentCompleteContainer>
              <AnimateMotionDiv>
                <CostLine
                  label={t('serviceSummary.paymentType')}
                  value={
                    initialServiceCost?.type
                      ? ServicePaymentTypeLabel[initialServiceCost?.type]
                      : '-'
                  }
                />
                <CostLine
                  label={t('serviceSummary.rate')}
                  value={
                    initialServiceCost?.amount
                      ? `$${initialServiceCost?.amount}`
                      : '-'
                  }
                />{' '}
                {initialServiceCost?.type &&
                  initialServiceCost.type === ServicePaymentType.Hour && (
                    <CostLine
                      label={t('serviceSummary.hours')}
                      value={initialServiceCost?.estimateHours ?? '-'}
                    />
                  )}
                <CostLine
                  label={t('serviceSummary.initialCost')}
                  value=""
                  isTotal
                />
              </AnimateMotionDiv>
              <AnimateMotionDiv>
                <CostLine
                  label={t('serviceSummary.serviceCharge')}
                  value={
                    initialServiceCost?.amount
                      ? `$${initialServiceCost?.amount}`
                      : '-'
                  }
                />
                <CostLine
                  label={t('serviceSummary.safetyFee')}
                  value={
                    initialServiceCost?.fee
                      ? `$${initialServiceCost?.fee}`
                      : '-'
                  }
                />
                {initialServiceCost?.tax ? (
                  <CostLine
                    label={t('serviceSummary.taxFee')}
                    value={`$${formatNumber(initialServiceCost?.tax)}`}
                  />
                ) : null}
                <CostLine
                  label={t('serviceSummary.customerCost')}
                  value={calculateTotalValueWithLabel(
                    initialServiceCost as GraphqlServiceRequestPayment
                  )}
                  isTotal
                  finalBorder
                />

                <TertiaryTitle style={{ margin: '1rem 0 1rem 0' }}>
                  {t('serviceSummary.paymentMethod')}
                </TertiaryTitle>
                {initialServiceCost?.stripePaymentMethodId && (
                  <PaymentMethod
                    stripePaymentMethodId={
                      initialServiceCost?.stripePaymentMethodId
                    }
                  />
                )}
                <TertiaryTitle
                  style={{ margin: '1rem 0 1rem 0', fontWeight: 'bold' }}
                >
                  {t('serviceSummary.postVisitServiceCost')}
                </TertiaryTitle>
                {initialServiceCost?.type &&
                  initialServiceCost.type === ServicePaymentType.Hour && (
                    <CostLine
                      label={t('serviceSummary.hours')}
                      value={
                        lastServiceCost?.amount
                          ? lastServiceCost.amount / initialServiceCost.amount
                          : '-'
                      }
                    />
                  )}
                <CostLine
                  label={t('serviceSummary.serviceCharge')}
                  value={
                    lastServiceCost?.amount
                      ? `$${lastServiceCost?.amount}`
                      : '-'
                  }
                />
                <CostLine
                  label={t('serviceSummary.safetyFee')}
                  value={
                    lastServiceCost?.fee ? `$${lastServiceCost?.fee}` : '-'
                  }
                />
                {lastServiceCost?.tax ? (
                  <CostLine
                    label={t('serviceSummary.taxFee')}
                    value={`$${formatNumber(lastServiceCost?.tax)}`}
                  />
                ) : null}
                <CostLine
                  label={t('serviceSummary.customerCost')}
                  value={calculateTotalValueWithLabel(
                    lastServiceCost as GraphqlServiceRequestPayment
                  )}
                  isTotal
                  finalBorder
                />
                <TertiaryTitle style={{ margin: '1rem 0 1rem 0' }}>
                  {t('serviceSummary.paymentMethod')}
                </TertiaryTitle>
                {lastServiceCost?.stripePaymentMethodId && (
                  <PaymentMethod
                    stripePaymentMethodId={
                      lastServiceCost?.stripePaymentMethodId
                    }
                  />
                )}
              </AnimateMotionDiv>
            </PaymentCompleteContainer>
          </div>
        </IonAccordion>
      </IonAccordContainer>

      <Button
        color="primary-orange"
        style={{ marginTop: '16px' }}
        onClick={hasReview ? onClose : handleRateAndReviewProfessional}
      >
        {hasReview ? 'OK' : t('serviceSummary.rateAndReview')}
      </Button>
    </>
  );
};
