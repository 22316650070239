import styled from 'styled-components';

import { pixelToRem } from '../../utils/helper';

export type FontProps = {
  fontSize?: string;
  color?: string;
};

export const CostBreakDownDiv = styled.p<FontProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(12)};
  color: #212121;
  margin-right: 2px;
  margin-top: 10px;
`;
