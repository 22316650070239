import { IonContent } from '@ionic/react';
import { Container } from '../../../../components/ActivitiesTab/ActivitiesTab.style';
import { Space } from '../../../../components/Space/Space';
import { DateSelector } from '../../../../components/Date/DateSelector';
import {
  AppointmentActivitiesFilter,
  AppointmentRequestActivitiesStatus,
  GraphqlAppointmentRequest,
  useGetAgentActivitiesQuery,
} from '../../../../graphql/generated';
import { useMemo, useState } from 'react';
import { ActivitiesStatusFilters } from '../components/ActivitiesStatusFilters';
import {
  AppointmentRequestsList,
  AppointmentRequestsListSkeleton,
} from '../components/AppointmentRequestsList';
import dayjs from 'dayjs';
import {
  AgentConfirmedActivitiesEmptyState,
  AgentRequestedActivitiesEmptyState,
} from '../components/AgentActivitiesEmptyStates';
import { useDateWithTimezone } from '../../../../utils/hooks/useDateWithTimezone';

export const RequestedActivities: React.FC = () => {
  // TODO: handle error when defined
  const { data, loading } = useGetAgentActivitiesQuery({
    variables: {
      filter: {
        status: AppointmentRequestActivitiesStatus.Requested,
      },
      // TODO: implement pagination if necessary
      page: 1,
      pageSize: 1000,
    },
  });

  const activities = data?.getAgentActivities
    ?.activities as GraphqlAppointmentRequest[];

  if (loading) return <AppointmentRequestsListSkeleton />;

  if (!activities || activities.length === 0)
    return <AgentRequestedActivitiesEmptyState />;

  return (
    <AppointmentRequestsList
      withDateDayDivider={false}
      appointmentRequests={activities}
    />
  );
};

export const ConfirmedActivities: React.FC = () => {
  const { transformDateToTimezone } = useDateWithTimezone();

  const initialIntervalFilter = useMemo(() => {
    return {
      fromDate: transformDateToTimezone(dayjs().startOf('day').toDate()),
      untilDate: transformDateToTimezone(
        dayjs().add(6, 'day').endOf('day').toDate()
      ),
    };
  }, [transformDateToTimezone]);
  const [filters, setFilters] = useState<AppointmentActivitiesFilter>({
    ...initialIntervalFilter,
    status: AppointmentRequestActivitiesStatus.Confirmed,
  });

  // TODO: handle error when defined
  const { data, loading } = useGetAgentActivitiesQuery({
    variables: {
      filter: filters,
      // TODO: implement pagination if necessary
      page: 1,
      pageSize: 1000,
    },
  });

  const activities = data?.getAgentActivities
    ?.activities as GraphqlAppointmentRequest[];

  return (
    <>
      <DateSelector
        range="week"
        onSelected={(fromDate, untilDate) =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            fromDate: transformDateToTimezone(fromDate),
            untilDate: transformDateToTimezone(untilDate),
          }))
        }
      />
      {loading ? <AppointmentRequestsListSkeleton /> : null}
      {!loading && activities.length === 0 ? (
        <AgentConfirmedActivitiesEmptyState />
      ) : null}
      {!loading && activities.length > 0 ? (
        <AppointmentRequestsList
          withDateDayDivider={true}
          appointmentRequests={activities}
        />
      ) : null}
    </>
  );
};

const AgentActivities: React.FC = () => {
  const [filter, setFilter] = useState<AppointmentRequestActivitiesStatus>(
    AppointmentRequestActivitiesStatus.Requested
  );

  return (
    <>
      <IonContent>
        <Container style={{ padding: '32px 24px' }}>
          <Space direction="column" size="large">
            <ActivitiesStatusFilters
              selected={filter}
              onFilterChange={(f) => {
                setFilter(f);
              }}
            />
            {/* TODO: Use router outlet when routing is refactored */}
            {filter === AppointmentRequestActivitiesStatus.Requested ? (
              <RequestedActivities />
            ) : (
              <ConfirmedActivities />
            )}
          </Space>
        </Container>
      </IonContent>
    </>
  );
};

export default AgentActivities;
