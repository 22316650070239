/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import {
  Container,
  DayContainer,
  MouthContainer,
  WeekDayContainer,
} from './ActivityTimeLine.styles';

type ActivityTimeLineProps = {
  data: {
    day: string;
    month: string;
    weekDay: string;
  };
};
const ActivityTimeLine: React.FC<ActivityTimeLineProps> = ({ data }) => {
  return (
    <Container>
      <MouthContainer>{data?.month}</MouthContainer>
      <DayContainer>{data?.day}</DayContainer>
      <WeekDayContainer>{data?.weekDay}</WeekDayContainer>
    </Container>
  );
};

export default ActivityTimeLine;
