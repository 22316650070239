import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import {
  MainTitle,
  SecondaryTitle,
} from '../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../components/Typography/Body/Body';
import { Button } from '../../../components/Button/Button';
import { ActionableCard } from '../components/ActionableCard';
import { useHistory, useLocation } from 'react-router';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames } from '../../../constants/enums';
import { mailOpenOutline } from 'ionicons/icons';
import {
  RequestToNotExistingUserInput,
  UserInvitesType,
  useSendRequestToNotExistingUserMutation,
} from '../../../graphql/generated';
import { useAppContext } from '../../../providers/appContextProvider';
import { GreenCheckMark } from '../../../assets/images/GreenCheckMark';
import { getUserLandingRoute } from '../../../utils/routes/getUserLandingRoute';
import { useAuthContext } from '../../../providers/authProvider';
import { logger } from '../../../logger';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import { QUERY_PARAMS, ROUTES } from '../../../constants/routes';

type FriendLocationState = Pick<
  RequestToNotExistingUserInput,
  'firstName' | 'lastName' | 'email' | 'phoneNumber'
>;

const InviteSentModalTitle = () => (
  <IonGrid>
    <IonRow className="ion-justify-content-center">
      <IonCol size="auto">
        <GreenCheckMark />
      </IonCol>
    </IonRow>
    <IonRow className="ion-padding-top ion-justify-content-center">
      <IonCol size="auto">
        <SecondaryTitle>
          <Trans i18nKey="membership.notMatchFriend.inviteSentModal.title" />
        </SecondaryTitle>
      </IonCol>
    </IonRow>
  </IonGrid>
);

const MembershipNotMatchFriendPage: React.FC = () => {
  const history = useHistory();
  const { state: friendInfo } = useLocation<FriendLocationState>();
  const { isFeatureEnabled } = useFeatureFlags();
  const isInviteButtonEnable = isFeatureEnabled(
    FeatureNames.InviteOthersMembershipFlow
  );
  const { globalAlert } = useAppContext();
  const { user } = useAuthContext();
  const [sendRequestToNotExistingUser] =
    useSendRequestToNotExistingUserMutation();

  const handleInviteFriend = async () => {
    try {
      await sendRequestToNotExistingUser({
        variables: {
          requestToNotExistingUserInput: {
            ...friendInfo,
            requestType: UserInvitesType.CircleMember,
          },
        },
      });
      globalAlert({
        title: <InviteSentModalTitle />,
        subtitle: (
          <Trans i18nKey="membership.notMatchFriend.inviteSentModal.subtitle" />
        ),
        firstButtonLabel: (
          <Trans i18nKey="membership.notMatchFriend.inviteSentModal.firstButtonLabel" />
        ),
        firstButtonAction: () => {
          history.push(
            `${ROUTES.MEMBERSHIP.ABOUT_FRIEND}?${QUERY_PARAMS.FORM_ID}=${Number(
              new Date()
            )}`
          );
        },
        secondButtonLabel: (
          <Trans i18nKey="membership.notMatchFriend.inviteSentModal.secondButtonLabel" />
        ),
        secondButtonAction: () => {
          history.push(getUserLandingRoute(user));
        },
      });
    } catch (err) {
      logger.error({
        tag: '[NOT_MATCH_FRIEND]',
        message: `Fail trying inviting friend: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  const handleEditFriendInfo = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical">
            <IonCol>
              <MainTitle>
                {friendInfo ? (
                  <Trans
                    i18nKey="membership.notMatchFriend.title"
                    values={friendInfo}
                  />
                ) : (
                  <Trans i18nKey="membership.notMatchFriend.fallbackTitle" />
                )}
              </MainTitle>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText style={{ whiteSpace: 'pre-line' }}>
                {friendInfo ? (
                  <Trans
                    i18nKey="membership.notMatchFriend.body"
                    values={friendInfo}
                  />
                ) : (
                  <Trans i18nKey="membership.notMatchFriend.fallbackBody" />
                )}
              </BodyText>
            </IonCol>
          </IonRow>

          {isInviteButtonEnable && friendInfo && (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  iconSrc={mailOpenOutline}
                  actionText={
                    <Trans i18nKey="membership.notMatchFriend.cardActions.inviteFriend" />
                  }
                  onAction={handleInviteFriend}
                />
              </IonCol>
            </IonRow>
          )}

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button color="secondary-orange" onClick={handleEditFriendInfo}>
                <Trans i18nKey="membership.notMatchFriend.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembershipNotMatchFriendPage;
