import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const BackButton = styled.button`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  background: unset;
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
`;
