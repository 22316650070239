import { yupResolver } from '@hookform/resolvers/yup';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormInput from '../FormInput/FormInput';
import { IonCol, IonRow } from '@ionic/react';
import { Button } from '../Button/Button';
import { UserInviteInput } from '../../graphql/generated';
import FormSelect from '../FormSelect/FormSelect';
import { getPhoneNumberInputYupValidator } from '../../utils/form/getPhoneNumberInputYupValidator';
import { getAtLeastOneIsRequiredYupValidator } from '../../utils/form/getAtLeastOneIsRequiredYupValidator';

// TODO: why is not in generated file?
enum CustomerRelationships {
  SIBLING = 'sibling',
  CHILD = 'child',
  PARTNER = 'partner',
  OTHER = 'other',
}

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

export type InviteFamilyMemberFormFields = Pick<
  UserInviteInput,
  'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'relationship'
>;

interface InviteFamilyMemberFormProps {
  onSubmit: SubmitHandler<InviteFamilyMemberFormFields>;
}

export const InviteFamilyMemberForm: React.FC<InviteFamilyMemberFormProps> = ({
  onSubmit,
}) => {
  const i18n = useTranslation();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(i18n.t('forms.inviteFamilyMember.firstName.errorMessage')),
        lastName: yup
          .string()
          .required(i18n.t('forms.inviteFamilyMember.lastName.errorMessage')),
        email: yup
          .string()
          .test(
            ...getAtLeastOneIsRequiredYupValidator({
              errorMessage: i18n.t('forms.common.email.errors.atLeastOne'),
              otherFieldsNames: ['phoneNumber'],
            })
          )
          .email(i18n.t('forms.inviteFamilyMember.email.errors.invalid')),
        phoneNumber: yup
          .string()
          .test(
            ...getAtLeastOneIsRequiredYupValidator({
              errorMessage: i18n.t(
                'forms.common.phoneNumber.errors.atLeastOne'
              ),
              otherFieldsNames: ['email'],
            })
          )
          .test(
            ...getPhoneNumberInputYupValidator(
              i18n.t('forms.common.phoneNumber.errors.invalid')
            )
          ),
        relationship: yup
          .string()
          .required(
            i18n.t('forms.inviteFamilyMember.relationship.errorMessage')
          ),
      }),
    []
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<InviteFamilyMemberFormFields>({
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.inviteFamilyMember.firstName.label')}
              name={i18n.t('forms.inviteFamilyMember.firstName.name')}
              register={register('firstName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.inviteFamilyMember.lastName.label')}
              name={i18n.t('forms.inviteFamilyMember.lastName.name')}
              register={register('lastName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.inviteFamilyMember.email.label')}
              name={i18n.t('forms.inviteFamilyMember.email.name')}
              register={register('email')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              type="tel"
              label={i18n.t('forms.inviteFamilyMember.phoneNumber.label')}
              name={i18n.t('forms.inviteFamilyMember.phoneNumber.name')}
              register={register('phoneNumber')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormSelect
              placeholder={i18n.t(
                'forms.inviteFamilyMember.relationship.placeholder'
              )}
              label={i18n.t('forms.inviteFamilyMember.relationship.label')}
              {...register('relationship')}
              options={Object.values(CustomerRelationships).map((value) => ({
                label: i18n.t(`enums.customerRelationships.${value}`),
                value,
              }))}
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <Button color="primary-orange" type="submit" loading={isSubmitting}>
              <Trans i18nKey="forms.inviteFamilyMember.submit" />
            </Button>
          </motion.div>
        </IonCol>
      </IonRow>
    </form>
  );
};
