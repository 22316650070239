import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import {
  IonCard,
  IonFooter,
  IonLabel,
  IonListHeader,
  IonToggle,
  IonToolbar,
} from '@ionic/react';

export const Toolbar = styled(IonToolbar)`
  --background: white;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

export const Title = styled.p`
  color: var(--colors-primary-blue);
  font-size: ${pixelToRem(24)};
  line-height: 30px;
  margin-top: -8px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  color: var(--body-text-900, #212121);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 140%;
  margin: 0;
`;

export const Info = styled.p`
  color: var(--body-text-900);
  font-size: ${pixelToRem(16)};
  line-height: 20px;
`;

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
`;

export const SearchButtonLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
`;

export const PushAreOffContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--body-text-900);
  border-radius: 8px;
  padding: 1rem 1rem 1rem 0.5rem;
  gap: 0.5rem;
`;

export const LeftItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  ion-toggle::part(track) {
    height: 18px;
    width: 44px;
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }
`;

export const IonListHeaderStyled = styled(IonListHeader)`
  color: var(--colors-primary-blue);
  font-size: ${pixelToRem(18)};
  line-height: 20px;
  font-weight: 600;
`;

export const IonLabelStyled = styled(IonLabel)`
  font-size: ${pixelToRem(16)};
  line-height: 16px;
  font-weight: 500;
  color: '#000';
`;

export const CustomIonToggle = styled(IonToggle)`
  padding: 8px;
  --track-background: #ccc;
  --track-background-checked: rgba(234, 130, 49, 0.38);
  --handle-background: #f4f5f8;
  --handle-background-checked: var(--ion-color-primary);
  --handle-border-radius: 14px;
  --handle-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14),
    0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  --handle-width: 25px;
  --handle-height: 27px;
  --handle-max-height: auto;
  --handle-spacing: 0px;
`;

export const StyledFooter = styled(IonFooter)`
  ion-toolbar {
    --background: var(--White);
    --border-style: none;
    --padding-end: 1.5rem;
    --padding-start: 1.5rem;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 1rem 1rem 1rem 0.5rem;
`;

export const Wrapper = styled(IonCard)`
  margin-bottom: 32px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
`;

export const StyledIonCard = styled(IonCard)`
  padding: 14px;
  margin-inline: 2px;
`;
