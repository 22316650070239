import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { BuildEnv, buildEnv, getEnvLabel } from '../../utils/env';
import { AppContext } from '../../providers/appContextProvider';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ModalDebugSettings: React.FC<Props> = ({ isOpen, onClose }) => {
  const { env, setEnv } = useContext(AppContext);
  const [allowSwitchEnv, setAllowSwitchEnv] = useState(env !== buildEnv);
  const [versionTapCounter, setVersionTapCounter] = useState(0);

  const handleVersionItemClick = () => {
    if (versionTapCounter >= 7) {
      setAllowSwitchEnv(true);
      setVersionTapCounter(0);
      return;
    }

    setVersionTapCounter(versionTapCounter + 1);
  };

  const enableEnvOption = (opt: BuildEnv) => {
    if (buildEnv !== BuildEnv.Production) return true;
    // NOTE: we don't allow selecting local or dev from a prod build
    // because we don't want apple testers to go there
    return opt === BuildEnv.Production || opt === BuildEnv.Staging;
  };

  const handleDidDismiss = () => {
    setAllowSwitchEnv(env !== buildEnv);
    setVersionTapCounter(0);
    onClose();
  };

  return (
    <IonModal isOpen={isOpen} onIonModalDidDismiss={handleDidDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>About</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>Done</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem onClick={handleVersionItemClick}>
            <IonLabel>
              <h3>Version</h3>
              <p>{process.env.REACT_APP_BUILD_VERSION || '0.0.0'}</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h3>Build Environment</h3>
              <p>{getEnvLabel(buildEnv)}</p>
            </IonLabel>
          </IonItem>
          {allowSwitchEnv && (
            <IonItem>
              <IonSelect
                value={env}
                label="Current Environment"
                onIonChange={(event) => setEnv(event.detail?.value as BuildEnv)}
              >
                {Object.entries(BuildEnv).map(([label, value]) => (
                  <IonSelectOption
                    key={value}
                    value={value}
                    disabled={!enableEnvOption(value)}
                  >
                    {label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default ModalDebugSettings;
