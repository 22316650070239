import { IonButton, IonPage } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

export const ChatInboxPage = styled(IonPage)`
  ion-card {
    border-radius: 10px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  }
  opacity: 1 !important;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const ChatButton = styled(IonButton)`
  background: none;
  position: absolute;
  bottom: 180px;
  right: 16px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardMiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const CardText = styled.div`
  color: #23364b;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
`;
