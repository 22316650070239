import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { useTranslation } from 'react-i18next';
import { informationOutline } from 'ionicons/icons';
import { GetCircleRequestQuery } from '../../graphql/generated';
import { Accordion } from '../Accordion/Accordion';
import { DescriptionBox } from './ActivityCardItemRequestDescription.styles';

type Description = GetCircleRequestQuery['getCircleRequest']['description'];

type ActivityCardItemRequestDescriptionProps = {
  description: Description;
};
export const ActivityCardItemRequestDescription: React.FC<
  ActivityCardItemRequestDescriptionProps
> = ({ description }) => {
  const { t } = useTranslation();
  return (
    <Accordion
      header={
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="ion-align-self-center">
              <IonIcon
                icon={informationOutline}
                color="var(--colors-primary-blue)"
                style={{
                  width: '24px',
                  height: '24px',
                  marginInlineEnd: '8px',
                }}
              />
            </IonCol>
            <IonCol className="ion-align-self-center">
              <BodyText color="var(--colors-primary-blue)">
                {t('ActivityCardItemRequestDescription.Label')}
              </BodyText>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      content={
        <DescriptionBox className="ion-margin-top ion-padding" slot="content">
          <BodyText>{description}</BodyText>
        </DescriptionBox>
      }
    />
  );
};
