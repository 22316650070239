import * as React from 'react';

export const WelcomeFlowImage = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={289}
    height={288}
    viewBox="0 0 289 288"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M194.092 93.25C193.658 41.696 151.742.029 100.082 0 47.975-.028 5.942 42.709 5.942 94.784v170.762a16.624 16.624 0 0016.625 16.623h171.525c51.957 0 94.076-42.924 94.076-94.873 0-51.948-42.119-94.045-94.076-94.045z"
      fill="#EA8231"
    />
    <mask
      id="a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={5}
      width={284}
      height={283}
    >
      <path
        d="M188.985 98.358c-.434-51.555-42.35-93.222-94.01-93.25C42.867 5.078.833 47.815.833 99.89v170.762a16.624 16.624 0 0016.625 16.623h171.526c51.956 0 94.075-42.923 94.075-94.872s-42.119-94.046-94.075-94.046z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        d="M188.985 98.358c-.434-51.555-42.35-93.222-94.01-93.25C42.867 5.078.833 47.815.833 99.89v170.762a16.624 16.624 0 0016.625 16.623h171.526c51.956 0 94.075-42.923 94.075-94.872s-42.119-94.046-94.075-94.046z"
        fill="#fff"
      />
      <path
        opacity={0.3}
        d="M188.985 98.358c-.434-51.555-42.35-93.222-94.01-93.25C42.867 5.078.833 47.815.833 99.89v170.762a16.624 16.624 0 0016.625 16.623h171.526c51.956 0 94.075-42.923 94.075-94.872s-42.119-94.046-94.075-94.046z"
        fill="url(#paint0_radial_5650_228704)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.502 223.582l46.897-11.257c8.983-6.218 17.375-10.661 25.176-13.33 2.241-.293 5.899.157 1.015 5.108-4.883 4.951-9.798 10.378-8.612 12.636 1.186 2.258 6.052.88 7.237 4.418.79 2.359-7.211 3.565-24.004 3.62l-37.051 19.099-10.658-20.294z"
        fill="#FFC069"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1 213.267c14.083 4.599 33.797 7.964 58.054 2.033l1.919 20.155c-18.4 9.022-41.567 13.711-58.337 7.506-13.216-4.889-14.57-23.352-1.635-29.694z"
        fill="#2026A2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.512 188.228c-6.918 5.406-13.991 8.476-17.545 8.013-8.911-1.159-13.574-32.569-8.415-45.811 5.159-13.242 38.857-22.876 43.61 1.522 1.65 8.468-1.011 16.533-5.562 23.406l14.968 43.116-27.684 3.399.628-33.645z"
        fill="#FFC069"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.826 135.558c3.642-1.615 9.085-2.679 11.428-2.699-1.7-2.977-2.651-6.411-2.644-9.868.007-3.359 1.144-6.358 2.153-9.479.465-1.438 1.016-2.879 1.956-4.051.958-1.194 2.249-2.063 3.653-2.574 1.436-.524 2.985-.685 4.503-.557.672.057 1.296.284 1.958.371.679.089.948-.361 1.374-.832 1.029-1.135 2.655-1.832 4.089-2.187 1.609-.397 3.333-.36 4.956-.016 1.58.335 3.14 1.002 4.387 2.1.556.489 1.075 1.059 1.448 1.713.152.269.193.434.488.503.274.064.581.029.862.087 2.722.562 5.204 2.3 6.738 4.736.895 1.421 1.558 4.035 1.831 7.022.273 2.988-1.469 11.084-3.642 14.226-1.94 2.805-5.042 4.879-8.145 6.001 2.415 2.155 4.196 4.999 5.616 7.939a41.852 41.852 0 013.361 10.068c.657 3.323 1.115 6.866.73 10.244-.361 3.159-1.629 5.87-4.066 7.79-1.116.879-2.346 1.588-3.59 2.251-1.225.653-2.456 1.292-3.769 1.735-2.486.839-5.435 1.109-7.648-.673-.797-.642-1.688-1.464-2.314-2.291-.225-.298-.363-.494-.176-.835.225-.408.626-.749.938-1.085 1.774-1.917 2.725-4.301 2.708-6.994-.017-2.732-1.169-6.12-3.941-6.973-3.42-1.053-6.787 1.051-9.478 2.958-1.754-4.16-4.746-7.732-8.931-9.225-4.631-1.651-9.668-1.293-14.382-2.619-1.228-.345-2.486-.754-3.468-1.645-.411-.372-.899-.861-1.11-1.394-.277-.7.116-1.364.409-1.973 1.549-3.226 8.077-10.16 11.718-11.774z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.486 281.162l44.043 13.169-11.253 18.684-34.24-20.084c-15.987-.944-23.54-2.513-22.66-4.708.743-1.852 2.509-2.168 4.135-2.459 1.267-.226 2.449-.438 2.997-1.348 1.252-2.079-3.132-7.488-7.513-12.444-4.38-4.956-.874-5.188 1.245-4.791 7.283 2.946 15.032 7.606 23.246 13.981zm190.574 5.039l23.063-7.578c21.202 40.156 33.593 62.067 37.173 65.731 2.626 2.688 17.508 8.613 27.448 11.532 3.989 1.172 3.568 4.464 2.582 5.621-6.218 7.302-13.011 5.845-20.406 4.258-2.141-.459-4.332-.93-6.574-1.201-5.804-.703-7.163 2.395-8.448 5.323-1.137 2.592-2.216 5.051-6.269 4.62.084-.793.198-1.684.322-2.645.796-6.215 1.97-15.381-2.025-20.132-2.05-2.438-17.672-24.281-46.866-65.529z"
        fill="#FFC069"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.417 201.245l-10.184-.481c-19.145 23.679-21.812 75.245-35.47 83.704-12.913 7.998-33.068 4.268-57.146-1.154l-5.132 16.601c23.733 20.032 62.278 69.234 88.697 53.75 26.793-20.983 24.613-117.287 19.235-152.42z"
        fill="#DB2721"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.12 345.437l-88.254 10.836c-4.033.495-2.865-29.967-2.641-32.968 2.576-34.637 24.365-74.308 18.455-122.436l24.968-7.106c25.202 31.177 36.466 96.414 47.472 151.674z"
        fill="#DDE3E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173 193.598l.29-.036c61.406 28.07 102.591 78.213 123.553 150.428l-18.055 5.884c-11.938-10.853-26.771-28.037-44.498-51.552 5.582 13.795 10.049 27.838 12.683 41.641l-83.6 12.409c-20.137-36.861-.162-157.572-.162-157.572l5.485-.673 4.304-.529z"
        fill="#FF4133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.502 88.914c-11.536-2.811-23.167 4.263-25.978 15.799l-9.029 37.059c-2.81 11.536 4.263 23.167 15.8 25.978l2.634.642a.962.962 0 00-.005.028l35.687 8.694c6.825-4.047 9.884-39.006 9.62-56.165l.669-2.744c2.81-11.537-4.263-23.168-15.8-25.978l-13.598-3.313z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.357 150.13c6.797 6.75 14.06 10.98 17.967 10.927 9.796-.132 18.866-33.675 14.976-48.717-3.889-15.043-39.135-29.773-47.406-3.862-2.87 8.993-1.021 18.093 3.026 26.137l-21.725 44.96 29.533 7.196 3.629-36.641z"
        fill="#FFC069"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.949 107.679c1.064-.64 2.094-1.259 3.096-1.793l.194-.968s13.897-16.737 28.147-13.265c14.238 3.469 17.126 11.192 19.581 17.756l.007.018c-3.678 6.388-10.389 7.7-17.98 9.184-3.267.639-6.697 1.309-10.118 2.43a6.934 6.934 0 00-3.896-2.553 6.97 6.97 0 00-8.419 5.126 6.97 6.97 0 005.427 8.492c-2.204 14.963-6.82 31.539-11.842 34.517l-35.686-8.694c3.316-18.067 9.159-22.028 15.13-26.075 5.617-3.808 11.347-7.693 15.193-23.477.394-.234.782-.467 1.166-.698z"
        fill="#191847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.415 246.154l20.138-8.95s5.357-15.083 10.997-14.702c2.999-1.1-3.126 11.469 2.438 10.806 5.564-.664 19.752-9.469 21.881-7.257 3.149 3.273-.661 12.181-4.622 16.437-7.402 7.954-13.18 10.399-27.174 13.057-10.975 2.084-24.201 10.031-24.201 10.031l.543-19.422z"
        fill="#FFC069"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.932 323.704c-13.255-6.502-25.434-12.476-33.488-13.542 1.017-36.527 13.815-52.253 27.091-68.568 4.438-5.453 8.93-10.972 13.052-17.355 14.333-26.012 54.52-70.243 58.171-69.008l33.29 11.263c-4.646 18.295-5.537 39.391-6.173 60.798 2.358 8.074 6.109 14.429 11.923 16.996 23.095 10.193 85.935-6.365 85.935-6.365s11.762 30.751 9.62 43.241c-48.857 20.498-86.569 22.521-112.954 12.95-3.552 16.602-9.713 31.446-20.742 42.923-12.346 12.849-40.919-1.166-65.725-13.333z"
        fill="#1F28CF"
      />
      <path
        d="M239.072 163.805a6.806 6.806 0 00-9.58.039c-2.667 2.664-2.631 7.001.033 9.665l8.736 8.736a1.203 1.203 0 001.701 0l8.775-8.775c2.658-2.658 2.617-7.009-.041-9.666a6.804 6.804 0 00-9.624.001zM133.632 48.9c-5.068-4.983-13.216-4.96-18.254.074-5.082 5.076-5.014 13.34.062 18.416l16.646 16.645a2.293 2.293 0 003.241 0l16.72-16.72c5.064-5.064 4.986-13.354-.078-18.418s-13.273-5.062-18.337.003z"
        fill="#DB2721"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_5650_228704"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(115.08484 18.32083 -18.3246 115.10854 162.84 157.764)"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#EDB8A7" />
      </radialGradient>
    </defs>
  </svg>
);
