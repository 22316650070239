import { yupResolver } from '@hookform/resolvers/yup';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormInput from '../FormInput/FormInput';
import {
  IonCol,
  IonContent,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Button } from '../Button/Button';
import {
  CreateAddressInput,
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from '../../graphql/generated';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import GooglePlacesSearchAddressOutput from '../GooglePlacesSearchAddressOutput/GooglePlacesSearchAddressOutput';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import styled from 'styled-components';

export const Toolbar = styled(IonToolbar)`
  --background: white;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

export type AddressFormFields = Pick<
  CreateAddressInput,
  'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zipCode'
>;

export type AddressFormInitialValues = AddressFormFields & { id: number };

interface ModalAddAddressProps {
  customerId: number;
  googlePlacesApiKey?: string | null;
  isOpen: boolean;
  initialValues?: AddressFormInitialValues | null;
  onSaveAddress: (addressId: number) => unknown;
  onDidDismiss: (event: CustomEventInit<OverlayEventDetail>) => unknown;
}

export const ModalAddressForm: React.FC<ModalAddAddressProps> = ({
  customerId,
  isOpen,
  googlePlacesApiKey,
  initialValues,
  onDidDismiss,
  onSaveAddress,
}) => {
  const i18n = useTranslation();
  const [createAddressMutation] = useCreateAddressMutation();
  const [updateAddressMutation] = useUpdateAddressMutation();
  const { globalAlert } = useAppContext();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        addressLine1: yup
          .string()
          .required(i18n.t('forms.aboutYou.addressLine1.errorMessage')),
        addressLine2: yup.string(),
        city: yup.string().required(i18n.t('forms.aboutYou.city.errorMessage')),
        state: yup
          .string()
          .required(i18n.t('forms.aboutYou.state.errorMessage')),
        zipCode: yup
          .string()
          .required(i18n.t('forms.aboutYou.zipCode.errorMessage')),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddressFormFields>({
    resolver: yupResolver(formSchema),
    values: initialValues || undefined,
  });

  const createAddress = async (data: AddressFormFields) => {
    try {
      const createAddressResponse = await createAddressMutation({
        variables: {
          createAddressInput: {
            ...data,
            country: 'US', // TODO: consider using the country from the data object
            customerId,
          },
        },
      });
      if (createAddressResponse.data?.createAddress.id) {
        onSaveAddress(createAddressResponse.data?.createAddress.id);
      }
    } catch (err) {
      logger.error({
        tag: '[MODAL_ADDRESS_FORM_SUBMIT]',
        message: `Error creating address form: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  const updateAddress = async (data: AddressFormFields) => {
    if (!initialValues?.id) return;
    try {
      const updateAddressResponse = await updateAddressMutation({
        variables: {
          updateAddressId: initialValues.id,
          updateAddressInput: {
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode,
          },
        },
      });
      if (updateAddressResponse.data?.updateAddress.id) {
        onSaveAddress(updateAddressResponse.data?.updateAddress.id);
      }
    } catch (err) {
      logger.error({
        tag: '[MODAL_ADDRESS_FORM_SUBMIT]',
        message: `Error updating address: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  const onSubmit = async (data: AddressFormFields) => {
    if (initialValues?.id) {
      await updateAddress(data);
    } else {
      await createAddress(data);
    }
  };

  const onGooglePlacesSearchAddress = (
    address: Partial<CreateAddressInput>
  ) => {
    reset(address);
  };

  return (
    <IonModal
      initialBreakpoint={1}
      breakpoints={[0, 0.25, 0.5, 0.8, 1]}
      isOpen={isOpen}
      onIonModalDidDismiss={onDidDismiss}
    >
      <IonHeader>
        <Toolbar>
          <IonTitle>
            <Trans
              i18nKey={initialValues?.id ? 'editAddress' : 'addAddress'}
            ></Trans>
          </IonTitle>
        </Toolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {googlePlacesApiKey && (
          <GooglePlacesSearchAddressOutput
            googlePlaceApiKey={googlePlacesApiKey}
            onAddress={onGooglePlacesSearchAddress}
          />
        )}
        <form className="ion-margin-top" onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol>
              <motion.div variants={animation}>
                <FormInput
                  label={i18n.t('forms.aboutYou.addressLine1.label')}
                  name={i18n.t('forms.aboutYou.addressLine1.name')}
                  register={register('addressLine1')}
                  closeLabels
                  errors={errors}
                />
              </motion.div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <motion.div variants={animation}>
                <FormInput
                  label={i18n.t('forms.aboutYou.addressLine2.label')}
                  name={i18n.t('forms.aboutYou.addressLine2.name')}
                  register={register('addressLine2')}
                  closeLabels
                  errors={errors}
                />
              </motion.div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <motion.div variants={animation}>
                <FormInput
                  label={i18n.t('forms.aboutYou.city.label')}
                  name={i18n.t('forms.aboutYou.city.name')}
                  register={register('city')}
                  closeLabels
                  errors={errors}
                />
              </motion.div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-bottom">
            <IonCol className="ion-padding-end">
              <motion.div variants={animation}>
                <FormInput
                  label={i18n.t('forms.aboutYou.state.label')}
                  name={i18n.t('forms.aboutYou.state.name')}
                  register={register('state')}
                  closeLabels
                  errors={errors}
                />
              </motion.div>
            </IonCol>
            <IonCol>
              <motion.div variants={animation}>
                <FormInput
                  label={i18n.t('forms.aboutYou.zipCode.label')}
                  name={i18n.t('forms.aboutYou.zipCode.name')}
                  register={register('zipCode')}
                  closeLabels
                  errors={errors}
                />
              </motion.div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <motion.div variants={animation}>
                <Button
                  color="primary-orange"
                  type="submit"
                  loading={isSubmitting}
                >
                  <Trans i18nKey="save" />
                </Button>
              </motion.div>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default ModalAddressForm;
