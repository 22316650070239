import React, { useEffect, useState } from 'react';

import { Container } from './ActivityPeriodSelector.styles';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { ActivitiesOptions, ActivitiesStatus } from '../../graphql/generated';
type ActivityPeriodSelectorProps = {
  selectedTab: string;
  filterType: string | null;
  setFilterType: React.Dispatch<React.SetStateAction<string | null>>;
  hasPendingPayment: boolean;
};
const ActivityPeriodSelector: React.FC<ActivityPeriodSelectorProps> = ({
  selectedTab,
  setFilterType,
  filterType,
  hasPendingPayment,
}) => {
  const [selectedPlaceholder, setSelectedPlaceholder] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const activityOpen = [
    {
      label: 'All',
      value: ActivitiesOptions.All,
    },
    {
      label: 'Confirmation needed',
      value: ActivitiesOptions.ConfirmationNeeded,
    },
    {
      label: 'Pending',
      value: ActivitiesOptions.Pending,
    },
  ];
  const activityScheduled = [
    {
      label: 'Today',
      value: ActivitiesOptions.Today,
    },
    {
      label: 'This Week',
      value: ActivitiesOptions.ThisWeek,
    },
    {
      label: 'All Upcoming',
      value: ActivitiesOptions.All,
    },
  ];
  const activityHistory = [
    {
      label: 'All',
      value: ActivitiesOptions.All,
    },
    {
      label: 'Completed',
      value: ActivitiesOptions.Completed,
    },
    {
      label: 'Cancelled',
      value: ActivitiesOptions.Cancelled,
    },
  ];

  const handleFilterChange = (filter: string) => {
    setFilterType(filter);
  };

  useEffect(() => {
    if (hasPendingPayment) {
      if (selectedTab === ActivitiesStatus.Scheduled) {
        activityScheduled.push({
          label: 'Action required',
          value: 'actionRequired' as unknown as ActivitiesOptions, //TODO: fix this when api is ready,
        });
      } else if (selectedTab === ActivitiesStatus.History) {
        activityHistory.push({
          label: 'Action required',
          value: 'actionRequired' as unknown as ActivitiesOptions,
        });
      }
    }
  }, [hasPendingPayment, selectedTab]);
  const selectedValue: {
    [key: string]: string;
  } = {
    open: 'All',
    scheduled: 'All Upcoming',
    history: 'All',
  };

  useEffect(() => {
    handleFilterChange('all');
    setSelectedPlaceholder(selectedValue[selectedTab]);
    switch (selectedTab) {
      case ActivitiesStatus.Open:
        setSelectOptions(activityOpen);
        break;
      case ActivitiesStatus.Scheduled:
        setSelectOptions(activityScheduled);
        break;
      case ActivitiesStatus.History:
        setSelectOptions(activityHistory);
        break;
      default:
        setSelectOptions(activityOpen);
        break;
    }
  }, [selectedTab]);

  return (
    <Container>
      <IonSelect
        interface="popover"
        placeholder={selectedPlaceholder}
        value={filterType}
        onIonChange={({ detail }) => handleFilterChange(detail.value as string)}
      >
        {selectOptions.map(({ value, label }) => (
          <IonSelectOption
            key={value}
            aria-label={value || 'All'}
            className="activity-options"
            value={value}
          >
            {label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </Container>
  );
};

export default ActivityPeriodSelector;
