import { GraphqlStripeCard } from '../../graphql/generated';

export const formatPartialCardNumber = (card: GraphqlStripeCard) => {
  return (
    card.brand.charAt(0).toUpperCase() +
    card.brand.slice(1) +
    ' •••• ' +
    card.last4
  );
};

export const formatExpiration = (card: GraphqlStripeCard) => {
  return card.exp_month.toString() + '/' + card.exp_year.toString();
};
