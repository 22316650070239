import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
} from '@ionic/react';

interface ChatInboxSkeletonProps {
  numberOfSections: number;
}

export const ChatInboxSkeleton: React.FC<ChatInboxSkeletonProps> = ({
  numberOfSections = 0,
}) => {
  return (
    <>
      {Array.from({ length: numberOfSections }, (_, index) => (
        <IonList key={index} style={{ height: '70px ' }}>
          <IonListHeader>
            <IonSkeletonText
              animated={true}
              style={{ width: '20px' }}
            ></IonSkeletonText>
          </IonListHeader>
          <IonItem>
            <IonThumbnail slot="start">
              <IonSkeletonText animated={true}></IonSkeletonText>
            </IonThumbnail>
            <IonLabel>
              <h3>
                <IonSkeletonText
                  animated={true}
                  style={{ width: '80%' }}
                ></IonSkeletonText>
              </h3>
              <p>
                <IonSkeletonText
                  animated={true}
                  style={{ width: '60%' }}
                ></IonSkeletonText>
              </p>
            </IonLabel>
          </IonItem>
        </IonList>
      ))}
    </>
  );
};
