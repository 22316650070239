import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const DoneButton = styled.button`
  font-size: ${() => pixelToRem(16)};
  font-weight: 700;
  line-height: 22px;
  background-color: unset;
  color: ${({ color }) => color || 'white'};
`;
