import { IonButtons } from '@ionic/react';
import { ReactNode, CSSProperties } from 'react';
import {
  BottomSheetHeader,
  Header,
  LowerToolbar,
  StyledIonContent,
  BottomSheet,
  BottomSheetSubtitle,
  UpperToolbar,
  HeadersContainer,
} from './PageWithBottomSheetLayout.styles';
import { SecondaryTitle } from '../Typography/Headings/Headings';
import { BodyTextMedium } from '../Typography/Body/Body';

interface PageWithBottomSheetLayoutProps {
  title: ReactNode;
  subtitle?: ReactNode;
  bottomSheetTitle: ReactNode;
  bottomSheetSubtitle?: ReactNode;
  startSlotHeaderAction?: ReactNode;
  endSlotHeaderAction?: ReactNode;
  children: ReactNode;
  background: CSSProperties['background'];
  bottomSheetBackground: CSSProperties['background'];
}

export const PageWithBottomSheetLayout: React.FC<
  PageWithBottomSheetLayoutProps
> = ({
  title,
  subtitle,
  startSlotHeaderAction,
  endSlotHeaderAction,
  background,
  bottomSheetTitle,
  bottomSheetSubtitle,
  bottomSheetBackground,
  children,
}) => {
  return (
    <StyledIonContent background={background} fullscreen>
      <Header>
        <UpperToolbar>
          {startSlotHeaderAction ? (
            <IonButtons slot="start">{startSlotHeaderAction}</IonButtons>
          ) : null}
          {endSlotHeaderAction ? (
            <IonButtons slot="end">{endSlotHeaderAction}</IonButtons>
          ) : null}
        </UpperToolbar>

        <LowerToolbar>
          <HeadersContainer>
            <SecondaryTitle color="white" slot="start">
              {title}
            </SecondaryTitle>
            {subtitle ? (
              <BodyTextMedium color="white">{subtitle}</BodyTextMedium>
            ) : null}
          </HeadersContainer>
        </LowerToolbar>
      </Header>
      <BottomSheet>
        <BottomSheetHeader background={bottomSheetBackground}>
          {bottomSheetTitle}
          {bottomSheetSubtitle ? (
            <BottomSheetSubtitle>{bottomSheetSubtitle}</BottomSheetSubtitle>
          ) : null}
        </BottomSheetHeader>
        {children}
      </BottomSheet>
    </StyledIonContent>
  );
};
