import React from 'react';
import { Container, ContentBlock, Label, Wrapper } from './AccountLink.styles';
import { RightArrow } from '../../assets/images/RightArrow';

interface IAccountLinkProps {
  label: string | React.ReactNode;
  icon: React.ReactNode;
  action: () => void;
}
const AccountLink: React.FC<IAccountLinkProps> = ({ label, icon, action }) => {
  return (
    <Container onClick={action}>
      <ContentBlock>
        <Wrapper>
          <div>{icon}</div>
          <Label>{label}</Label>
        </Wrapper>
      </ContentBlock>
      <ContentBlock>
        <RightArrow color="#23364b" />
      </ContentBlock>
    </Container>
  );
};

export default AccountLink;
