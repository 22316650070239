import { IonIcon, IonPage } from '@ionic/react';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  AppointmentRequestStatus,
  GraphqlAppointmentRequest,
  GraphqlCircleRequest,
  ServiceRequestStatus,
  useGetCircleMemberActivityByAppointmentIdQuery,
  useUpdateAppointmentRequestMutation,
} from '../../graphql/generated';
import { Space } from '../../components/Space/Space';
import { CircleRequestIcon } from '../../assets/images/ServiceCategory/CircleRequestIcon';
import { Button } from '../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import { GET_CIRCLE_MEMBER_ACTIVITIES } from '../../graphql/activities';
import { CustomAlertV2 } from '../../components/CustomAlert/CustomAlert';
import {
  getApolloErrorCodes,
  getApolloErrorMessage,
} from '../../utils/apollo/errors';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import {
  CircleRequestDetailCard,
  PartialGraphqlCircleRequest,
} from '../../components/ActivitiesDetailCard/ActivitiesDetailCard';
import { CSSProperties } from 'styled-components';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { repeatOutline } from 'ionicons/icons';
import { SelectDateAndTimeModalSheet } from '../../components/SelectDateAndTimeModalSheet/SelectDateAndTimeModalSheet';
import { BodyText } from '../../components/Typography/Body/Body';
import { ActivityDetailSkeletonPage } from '../../components/ActivityDetailSkeletonPage/ActivityDetailSkeletonPage';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { HeaderDoneButton } from '../../components/HeaderDoneButton/HeaderDoneButton';
import { RequestCategoryName } from '../../components/RequestCategoryName/RequestCategoryName';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

const getCircleRequestIconColor = (
  status: AppointmentRequestStatus
): string => {
  switch (status) {
    case AppointmentRequestStatus.Open:
      return '#084282';
    case AppointmentRequestStatus.Accepted:
      return 'var(--interaction-success)';
    case AppointmentRequestStatus.Declined:
      return 'var(--interaction-error)';
    default:
      return '';
  }
};

interface PageStatusVariables {
  background: CSSProperties['background'];
  ticketBackground: CSSProperties['background'];
  primaryColor: string;
  secondaryColor: string;
}
const getPageVariables = (
  appointmentRequest: Pick<GraphqlAppointmentRequest, 'status'>,
  circleRequest: Pick<GraphqlCircleRequest, 'status'>
): PageStatusVariables => {
  if (appointmentRequest.status === AppointmentRequestStatus.Open) {
    return {
      background: `linear-gradient(
        267.19deg,
        #084282 -0.42%,
        #00356f 41.24%,
        #00244a 100.84%
      )`,
      ticketBackground: `linear-gradient(180deg, #dbe0fc 0%, #ffffff 98.45%);`,
      primaryColor: 'primary-orange',
      secondaryColor: 'secondary-orange',
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Declined) {
    return {
      background: 'var(--interaction-error)',
      ticketBackground: `linear-gradient(180deg, #ffe4e2 0%, #ffffff 98.45%);`,
      primaryColor: 'primary-red',
      secondaryColor: 'secondary-red',
    };
  }

  if (circleRequest.status === ServiceRequestStatus.Confirmed) {
    return {
      background: `linear-gradient(
        267.19deg,
        #13c4ca -0.42%,
        #08979c 57.59%,
        #017c81 100.84%
      );`,
      ticketBackground: `linear-gradient(180deg, #dbf9fc 0%, #ffffff 98.45%);`,
      primaryColor: 'primary-green',
      secondaryColor: 'secondary-green',
    };
  }

  return {
    background: '',
    ticketBackground: '',
    primaryColor: '',
    secondaryColor: '',
  };
};

interface AlertProps {
  title: string;
  subtitle?: string;
  firstButtonLabel: string;
  secondButtonLabel?: string;
  firstButtonAction: () => void | Promise<void>;
  secondButtonAction?: () => void | Promise<void>;
}

const CircleMemberActivityDetailPage: React.FC = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const i18n = useTranslation();
  const history = useHistory();
  const { transformDateToTimezone } = useDateWithTimezone();

  const { directChatMessage: goToChat, loading: creatingConversation } =
    useDirectChatMessage();

  // TODO: handle error
  const { data, loading, refetch } =
    useGetCircleMemberActivityByAppointmentIdQuery({
      variables: { appointmentRequestId: Number(appointmentId) },
    });
  const [updateAppointmentRequest, { loading: isUpdating }] =
    useUpdateAppointmentRequestMutation({
      refetchQueries: [GET_CIRCLE_MEMBER_ACTIVITIES],
    });
  const [isSelectingDateAndTime, setIsSelectingDateAndTime] =
    useState<boolean>(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedDateAndTime, setSelectedDateAndTime] = useState<string>(''); // YYYY-MM-DDTHH:mm
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const appointment = data?.getCircleMemberActivityByAppointmentId;

  const resetForm = useCallback(() => {
    setSelectedDateAndTime('');
    setIsEditing(false);
  }, []);

  const handleAccept = useCallback(async () => {
    if (!selectedDateAndTime) {
      setAlert({
        title: i18n.t('circleMemberActivityDetail.alerts.invalidForm.title'),
        subtitle: String(
          i18n.t('circleMemberActivityDetail.alerts.invalidForm.subtitle')
        ),
        firstButtonLabel: i18n.t(
          'circleMemberActivityDetail.alerts.invalidForm.primaryLabel'
        ),
        firstButtonAction() {
          setAlert(null);
        },
      });
      return;
    }
    try {
      await updateAppointmentRequest({
        variables: {
          updateAppointmentRequestInput: {
            id: Number(appointmentId),
            scheduledAt: transformDateToTimezone(
              parseISOLocal(selectedDateAndTime)
            ),
            status: AppointmentRequestStatus.Accepted,
          },
        },
      });
      resetForm();
      await refetch();
      setAppointmentUpdated(true);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle:
          getApolloErrorMessage(err) || String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          if (
            getApolloErrorCodes(err).includes(
              'appointment-request-already-accepted'
            )
          ) {
            history.goBack();
          } else {
            setAlert(null);
          }
        },
      });
    }
  }, [selectedDateAndTime]);

  const handleReject = useCallback(() => {
    setAlert({
      title: i18n.t('circleMemberActivityDetail.alerts.decline.title', {
        requestor: appointment?.CircleRequest?.RequestedBy.fullName,
      }),
      firstButtonLabel: i18n.t(
        'circleMemberActivityDetail.alerts.decline.primaryLabel'
      ),
      firstButtonAction: async () => {
        try {
          setAlert(null);
          await updateAppointmentRequest({
            variables: {
              updateAppointmentRequestInput: {
                id: Number(appointmentId),
                status: AppointmentRequestStatus.Declined,
              },
            },
          });
          resetForm();
          await refetch();
          setAppointmentUpdated(true);
        } catch (err) {
          setAlert({
            title: i18n.t('genericError.title'),
            subtitle:
              getApolloErrorMessage(err) ||
              String(i18n.t('genericError.subtitle')),
            firstButtonLabel: i18n.t('genericError.primaryLabel'),
            firstButtonAction() {
              setAlert(null);
            },
          });
        }
      },
      secondButtonLabel: String(
        i18n.t('circleMemberActivityDetail.alerts.decline.secondaryLabel')
      ),
      secondButtonAction() {
        setAlert(null);
      },
    });
  }, [appointment?.CircleRequest?.RequestedBy.fullName]);

  const handleUpdate = useCallback(async () => {
    try {
      await updateAppointmentRequest({
        variables: {
          updateAppointmentRequestInput: {
            id: Number(appointmentId),
            scheduledAt: transformDateToTimezone(
              parseISOLocal(selectedDateAndTime)
            ),
            status: AppointmentRequestStatus.Accepted,
          },
        },
      });
      resetForm();
      await refetch();
      setAppointmentUpdated(true);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle:
          getApolloErrorMessage(err) || String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          setAlert(null);
        },
      });
    }
  }, [selectedDateAndTime]);

  const handleConnect = useCallback(async (userIds: number[]) => {
    try {
      await goToChat(userIds);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle: String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          setAlert(null);
        },
      });
    }
  }, []);

  const handleGoBack = () => {
    if (isEditing) {
      setAlert({
        title: i18n.t('circleMemberActivityDetail.alerts.unsavedChanges.title'),
        subtitle: String(
          i18n.t('circleMemberActivityDetail.alerts.unsavedChanges.subtitle')
        ),
        firstButtonLabel: i18n.t(
          'circleMemberActivityDetail.alerts.unsavedChanges.primaryLabel'
        ),
        firstButtonAction() {
          setAlert(null);
          history.goBack();
        },
        secondButtonLabel: String(
          i18n.t(
            'circleMemberActivityDetail.alerts.unsavedChanges.secondaryLabel'
          )
        ),
        secondButtonAction() {
          setAlert(null);
        },
      });
    } else {
      history.goBack();
    }
  };

  const overrideCircleRequestFields = useCallback(
    (
      circleRequest: PartialGraphqlCircleRequest,
      appointmentRequestScheduleDate: Date | null | undefined
    ): PartialGraphqlCircleRequest => {
      if (selectedDateAndTime) {
        return {
          ...circleRequest,
          scheduledAt: transformDateToTimezone(
            parseISOLocal(selectedDateAndTime)
          ),
        };
      }

      if (appointmentRequestScheduleDate) {
        return {
          ...circleRequest,
          scheduledAt: appointmentRequestScheduleDate,
        };
      }

      return circleRequest;
    },
    [selectedDateAndTime]
  );

  const isAnyMutationLoading = isUpdating || creatingConversation;

  if (
    loading ||
    !appointment ||
    isAnyMutationLoading ||
    !appointment.CircleRequest
  )
    return (
      <IonPage>
        <ActivityDetailSkeletonPage />
      </IonPage>
    );

  const isFormEditable =
    appointment.status === AppointmentRequestStatus.Open || isEditing;

  const pageVariables = getPageVariables(
    appointment,
    appointment.CircleRequest
  );

  const getHiddenFields: () => (keyof PartialGraphqlCircleRequest)[] = () => {
    if (
      !isEditing &&
      [AppointmentRequestStatus.Declined].includes(appointment.status)
    ) {
      return ['scheduledAt'];
    } else {
      return [];
    }
  };

  return (
    <IonPage>
      <PageWithBottomSheetLayout
        title={
          <RequestCategoryName
            category={appointment.CircleRequest.RequestCategory?.name}
          />
        }
        subtitle={
          appointment.CircleRequest.recurring ? (
            <IonIcon icon={repeatOutline} style={{ width: 20, height: 20 }} />
          ) : undefined
        }
        bottomSheetTitle={
          <>
            <CircleRequestIcon
              color={getCircleRequestIconColor(appointment.status)}
              style={{ width: '60px', height: '60px' }}
            />
            <Trans
              i18nKey={`circleMemberActivityDetail.appointmentStatusTitles.${appointment.status}`}
            />
          </>
        }
        startSlotHeaderAction={
          appointmentUpdated ? null : (
            <HeaderBackButton
              onClick={handleGoBack}
              disabled={isAnyMutationLoading}
            />
          )
        }
        endSlotHeaderAction={
          appointmentUpdated ? (
            <HeaderDoneButton
              onClick={() => history.goBack()}
              disabled={isAnyMutationLoading}
            />
          ) : null
        }
        background={pageVariables.background}
        bottomSheetBackground={pageVariables.ticketBackground}
        bottomSheetSubtitle={
          appointment.CircleRequest.recurring
            ? i18n.t('serviceForm.recurringEvent')
            : undefined
        }
      >
        <Space size="large" direction="column">
          <BodyText color="var(--body-text-700)">
            {appointment.CircleRequest.description}
          </BodyText>
          <CircleRequestDetailCard
            activity={overrideCircleRequestFields(
              appointment.CircleRequest,
              appointment.scheduledAt
            )}
            onConnect={(userIds) => {
              void handleConnect(userIds);
            }}
            actionableFields={[
              {
                name: 'scheduledAt',
                onAction: () => {
                  if (isFormEditable && !isAnyMutationLoading) {
                    setIsSelectingDateAndTime(true);
                  }
                },
              },
            ]}
            actionable={isFormEditable}
            hiddenFields={getHiddenFields()}
          />
          <Space direction="column" size="small">
            {/* NOTE: Open request are editable by default */}
            {appointment.status !== AppointmentRequestStatus.Open &&
            !isEditing ? (
              <Button
                onClick={() => setIsEditing(true)}
                disabled={isAnyMutationLoading}
                color={pageVariables.primaryColor}
              >
                <Trans i18nKey="circleMemberActivityDetail.cta.viewOrEdit" />
              </Button>
            ) : null}

            {appointment.status === AppointmentRequestStatus.Open &&
            !isEditing ? (
              <Space size="small" direction="column">
                <Button
                  color={pageVariables.primaryColor}
                  onClick={() => {
                    void handleAccept();
                  }}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="circleMemberActivityDetail.cta.accept" />
                </Button>
                <Button
                  color={pageVariables.secondaryColor}
                  onClick={handleReject}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="circleMemberActivityDetail.cta.decline" />
                </Button>
              </Space>
            ) : null}

            {isEditing ? (
              <Space size="small" direction="column">
                <Button
                  color={pageVariables.primaryColor}
                  onClick={() => {
                    if (
                      appointment.status === AppointmentRequestStatus.Accepted
                    ) {
                      void handleUpdate();
                    } else {
                      void handleAccept();
                    }
                  }}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="circleMemberActivityDetail.cta.save" />
                </Button>
                {appointment.status !== AppointmentRequestStatus.Declined ? (
                  <Button
                    color={pageVariables.secondaryColor}
                    onClick={handleReject}
                    disabled={isAnyMutationLoading}
                  >
                    <Trans i18nKey="circleMemberActivityDetail.cta.decline" />
                  </Button>
                ) : null}
                <Button
                  color={pageVariables.secondaryColor}
                  onClick={resetForm}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="circleMemberActivityDetail.cta.cancel" />
                </Button>
              </Space>
            ) : null}
          </Space>
        </Space>
      </PageWithBottomSheetLayout>
      <SelectDateAndTimeModalSheet
        isOpen={isSelectingDateAndTime}
        createdAt={appointment.CircleRequest.createdAt}
        availabilities={appointment.CircleRequest.availabilities}
        tentativeSchedule={appointment.CircleRequest.tentativeSchedule}
        recurring={appointment.CircleRequest.recurring}
        frequency={appointment.CircleRequest.frequency ?? ''}
        onSubmit={(date, time) => {
          setSelectedDateAndTime(`${date}T${time}`);
          setIsSelectingDateAndTime(false);
        }}
        onClose={() => {
          setIsSelectingDateAndTime(false);
        }}
      />
      {alert ? <CustomAlertV2 isOpen {...alert} /> : null}
    </IonPage>
  );
};

export default CircleMemberActivityDetailPage;
