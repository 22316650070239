function RatingEmpty() {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none">
      <g clipPath="url(#clip0_3921_46801)">
        <path
          d="M12.5 7.13l.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.18.56 2.41-2.12-1.28-1.03-.64-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27zm0-5.13L9.69 8.63l-7.19.61 5.46 4.73L6.32 21l6.18-3.73L18.68 21l-1.64-7.03 5.46-4.73-7.19-.61L12.5 2z"
          fill="#A4A4B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_3921_46801">
          <path fill="#fff" transform="translate(.5)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RatingEmpty;
