import { IonContent, IonHeader, IonAlert } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import HalfCircle from '../../assets/half-circle.svg';
import ChevronDown from '../../assets/chevron-down-outline.svg';

export const StyledIonHeader = styled(IonHeader)`
  min-height: 185px;
  .border {
    width: 100%;
    height: 10px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 20px;
    z-index: 99;
  }
  h1 {
    position: absolute;
    z-index: 999;
    bottom: 42px;
    left: 16px;
    font-weight: 700;
    font-size: ${pixelToRem(24)};
    line-height: 120%;
    color: #ffffff;
  }
  ion-toolbar {
    width: 100%;
    height: 165px;
    color: #fff;
    --background: linear-gradient(
      267.19deg,
      #084282 -0.42%,
      #00356f 41.24%,
      #00244a 100.84%
    );
    &:before {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 50px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      bottom: -15px;
      opacity: 0.2;
      transform: rotate(-100deg);
    }
    &:after {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 30px;
      height: 150px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      right: 2px;
      top: 0;
      opacity: 0.3;
    }
  }
  ion-button {
    --color: #fff;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.4px;
  }
`;

export const StyledIonContent = styled(IonContent)`
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 0;
  --padding-bottom: 200px;

  form {
    ion-button {
      --color: #fff;
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 22px;
      letter-spacing: -0.4px;
      text-transform: uppercase;
    }

    ion-select::part(icon) {
      content: url(${ChevronDown});
    }
    ion-select::text {
      font-size: ${pixelToRem(14)};
    }
    ion-select-option {
      font-size: ${pixelToRem(14)};
    }

    ion-radio-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    ion-radio {
      --inner-border-radius: 14px;
      --color: #ddd;
    }

    ion-radio::part(container) {
      width: 30px;
      height: 30px;
      border: 1.5px solid #b0b2c4;
      border-radius: 100px;
      --inner-border-radius: 14px;
    }

    ion-radio::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }

    ion-radio.radio-checked::part(container) {
      background: #ea8231;
    }

    ion-radio.radio-checked::part(mark) {
      width: 6px;
      height: 10px;
      border-width: 0px 2px 2px 0px;
      border-style: solid;
      border-color: #fff;
      transform: rotate(45deg);
    }
    ion-toggle {
      padding: 8px;
      --track-background: #ccc;
      --track-background-checked: rgba(234, 130, 49, 0.38);
      --handle-background: #f4f5f8;
      --handle-background-checked: var(--ion-color-primary);
      --handle-border-radius: 14px;
      --handle-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14),
        0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      --handle-width: 25px;
      --handle-height: 27px;
      --handle-max-height: auto;
      --handle-spacing: 0px;
    }
    ion-toggle::part(track) {
      height: 18px;
      width: 44px;
      /* Required for iOS handle to overflow the height of the track */
      overflow: visible;
    }
  }
`;
export const Wrapper = styled.div<{ hasError?: boolean }>`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ion-textarea {
    background: #ffffff;
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
    border-radius: 14px;
    padding: 16px;
    min-height: 148px;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: #212121;
  }
  .input-container {
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
  }
  ion-select {
    --placeholder-color: #212121;
    width: 100%;
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
    border-radius: 14px;
    padding: 0 16px;
    min-height: 48px;
    display: flex;
    overflow: hidden;
    align-items: center;
    &::part(text) {
      max-width: 280px;
    }
  }

  .address-select {
    --padding-start: 24px;
  }

  div {
    width: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: ${pixelToRem(20)};
    line-height: 120%;
    color: #212121;
    margin-bottom: 16px;
    &.first-item {
      margin-bottom: 32px;
    }
  }
  .form-error {
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;
    color: #ea4255;
  }
  &.form-actions {
    ion-button {
      font-weight: 600;
      font-size: ${pixelToRem(16)};
      width: 100%;
      text-transform: capitalize;
      &:first-child {
        border-radius: 50px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
          0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
      &:last-child {
        color: #ea8231;
        margin-top: 16px;
      }
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #212121;
  border-radius: 14px;
  padding: 2px 16px;
  ion-input {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    --color: #212121;
    --placeholder-color: #212121;
    --placeholder-font-weight: 400;
  }
`;

export const CardActionContainer = styled.div<{ borderColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 100%;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'none'};
  div {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 8px;
    font-weight: 500;
    font-size: ${pixelToRem(16)};
    line-height: 19px;
    letter-spacing: 0.012em;
    color: #23364b;
  }
`;
export const StyledIonAlert = styled(IonAlert)`
  --padding: 32px;
  .alert-title {
    font-weight: 600;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
  }
  .alert-message {
    font-weight: 400;
    font-size: ${pixelToRem(12)};
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    white-space: break-spaces;
  }
  .alert-button-confirm {
    padding: 16px 20px;
    background: #ea8231;
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin: 0 32px 32px;
  }
`;
export const StyledIonAlertGoBack = styled(IonAlert)`
  --padding: 32px;
  .alert-title {
    font-weight: 600;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
  }
  .alert-message {
    font-weight: 400;
    font-size: ${pixelToRem(12)};
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    white-space: break-spaces;
  }

  .btn {
    font-weight: 600;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    margin: 0 16px 16px;
    padding: 16px 20px;
    border-radius: 50px;
  }

  .alert-button-confirm {
    background: #ea8231;
    color: #ffffff;
  }
  .alert-button-cancel {
    background: #ffffff;
    border: 1px solid #ea8231;
  }
`;
