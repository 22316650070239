import { yupResolver } from '@hookform/resolvers/yup';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormInput from '../FormInput/FormInput';
import { IonCol, IonRow } from '@ionic/react';
import { Button } from '../Button/Button';
import { CreateCustomerInput } from '../../graphql/generated';
import { getPhoneNumberInputYupValidator } from '../../utils/form/getPhoneNumberInputYupValidator';
import { getAtLeastOneIsRequiredYupValidator } from '../../utils/form/getAtLeastOneIsRequiredYupValidator';

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

type AboutFriendFormFields = Pick<
  CreateCustomerInput,
  'firstName' | 'lastName' | 'email' | 'phoneNumber'
>;

interface AboutFriendFormProps {
  onSubmit: SubmitHandler<AboutFriendFormFields>;
}

export const AboutFriendForm: React.FC<AboutFriendFormProps> = ({
  onSubmit,
}) => {
  const i18n = useTranslation();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(i18n.t('forms.aboutFriend.firstName.errorMessage')),
        lastName: yup
          .string()
          .required(i18n.t('forms.aboutFriend.lastName.errorMessage')),
        email: yup
          .string()
          .email(i18n.t('forms.aboutFriend.email.errorMessage'))
          .test(
            ...getAtLeastOneIsRequiredYupValidator({
              errorMessage: i18n.t('forms.common.email.errors.atLeastOne'),
              otherFieldsNames: ['phoneNumber'],
            })
          ),
        phoneNumber: yup
          .string()
          .test(
            ...getPhoneNumberInputYupValidator(
              i18n.t('forms.common.phoneNumber.errors.invalid')
            )
          )
          .test(
            ...getAtLeastOneIsRequiredYupValidator({
              errorMessage: i18n.t(
                'forms.common.phoneNumber.errors.atLeastOne'
              ),
              otherFieldsNames: ['email'],
            })
          ),
      }),
    []
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AboutFriendFormFields>({
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutFriend.firstName.label')}
              name={i18n.t('forms.aboutFriend.firstName.name')}
              register={register('firstName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutFriend.lastName.label')}
              name={i18n.t('forms.aboutFriend.lastName.name')}
              register={register('lastName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutFriend.email.label')}
              name={i18n.t('forms.aboutFriend.email.name')}
              register={register('email')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutFriend.phoneNumber.label')}
              name={i18n.t('forms.aboutFriend.phoneNumber.name')}
              register={register('phoneNumber')}
              closeLabels
              errors={errors}
              type="tel"
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <Button color="primary-orange" type="submit" loading={isSubmitting}>
              <Trans i18nKey="forms.aboutFriend.submit" />
            </Button>
          </motion.div>
        </IonCol>
      </IonRow>
    </form>
  );
};
