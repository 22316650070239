import styled from 'styled-components';
import { IonButton, IonLabel, IonDatetime } from '@ionic/react';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  height: 35vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e7eaeb;
  padding: 0 16px;
`;
export const HeaderButton = styled(IonButton)`
  line-height: 20px;
  --padding-end: 0;
  --padding-start: 0;
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 22px;
  letter-spacing: -0.4px;
  height: 46px;
  margin: 0;
`;
export const IonSegmentContainer = styled.div`
  ion-segment {
    --background: #f7f7f7;
  }
`;

export const TimeIonLabel = styled(IonLabel)`
  font-weight: 400;
  font-size: ${pixelToRem(12)};
  line-height: 13px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #424242;
  padding: 8px;
`;

export const StyledIonDatetime = styled(IonDatetime)`
  max-width: 100%;
  --background: #ffffff;
  font-weight: 400;
  font-size: ${pixelToRem(18)};
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #455154;
`;
