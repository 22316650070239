/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  IonIcon,
  IonPage,
  IonCard,
  IonCardHeader,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  CardText,
  AccountHeader,
  MainContainer,
  PageTitle,
  CardContent,
  PageSubTitle,
  ContinueButton,
} from './welcome.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack } from 'ionicons/icons';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../providers/authProvider';

const WelcomeStep1: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [dateType, setDateType] = useState<string>('yourself');

  const goBack = useCallback(() => {
    router.goBack();
  }, []);

  const onContinuePress = useCallback(() => {
    if (dateType === 'yourself') {
      router.push('/welcome-step2-yourself');
    } else {
      router.push('/welcome-step2-lovedone');
    }
  }, [dateType]);

  const parentVariant: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const childrenVariant: Variants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <IonPage>
      <motion.div initial="initial" animate="animate" variants={parentVariant}>
        <MainContainer className="ion-padding">
          <AccountHeader style={{ marginTop: 24 }}>
            <BackButton onClick={goBack}>
              <IonIcon
                icon={chevronBack}
                color="primary"
                style={{ width: 22, height: 26 }}
              />
              <BackLabel>{t('back')}</BackLabel>
            </BackButton>
            <BackButton onClick={onContinuePress}>
              <BackLabel>{t('Next')}</BackLabel>
            </BackButton>
          </AccountHeader>
          <motion.div variants={childrenVariant}>
            <PageTitle>
              <Trans
                i18nKey="welcome.title"
                values={{
                  userName: user?.firstName,
                }}
              />
            </PageTitle>
          </motion.div>
          <motion.div variants={childrenVariant} style={{ marginTop: 24 }}>
            <PageSubTitle>{t('welcome.subtitle')}</PageSubTitle>
          </motion.div>
          <div style={{ marginTop: 24 }}>
            <IonRadioGroup
              value={dateType}
              onIonChange={({ detail }) => {
                setDateType(detail.value as string);
              }}
            >
              <motion.div variants={childrenVariant}>
                <IonCard
                  style={{
                    border: '1px solid #ddd',
                    width: '100%',
                    marginLeft: '0px',
                  }}
                >
                  <IonCardHeader
                    style={{
                      padding: '16px',
                    }}
                  >
                    <CardContent>
                      <CardContent>
                        <CardText>{t('welcome.setupAccount')}</CardText>
                      </CardContent>
                      <IonRadio
                        labelPlacement="end"
                        value="yourself"
                        aria-label="Custom checkbox"
                      ></IonRadio>
                    </CardContent>
                  </IonCardHeader>
                </IonCard>
              </motion.div>
              <motion.div variants={childrenVariant} style={{ marginTop: 24 }}>
                <IonCard
                  style={{
                    border: '1px solid #ddd',
                    width: '100%',
                    marginLeft: '0px',
                  }}
                >
                  <IonCardHeader
                    style={{
                      padding: '16px',
                    }}
                  >
                    <CardContent>
                      <CardContent>
                        <CardText>{t('welcome.setupForLoved')}</CardText>
                      </CardContent>
                      <IonRadio
                        labelPlacement="end"
                        value="lovedone"
                        aria-label="Custom checkbox"
                      ></IonRadio>
                    </CardContent>
                  </IonCardHeader>
                </IonCard>
              </motion.div>
            </IonRadioGroup>
          </div>
        </MainContainer>
        <ContinueButton onClick={() => onContinuePress()}>
          <>{t('continue')}</>
        </ContinueButton>
      </motion.div>
    </IonPage>
  );
};

export default WelcomeStep1;
