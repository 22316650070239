import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../components/Typography/Body/Body';
import { Button } from '../../../components/Button/Button';
import { GreenCheckMark } from '../../../assets/images/GreenCheckMark';
import { ActionableCard } from '../components/ActionableCard';
import { IUserData } from '../../../types';
import { useAuthContext } from '../../../providers/authProvider';
import { useHistory } from 'react-router';
import { getUserLandingRoute } from '../../../utils/routes/getUserLandingRoute';
import { ROUTES } from '../../../constants/routes';

const MembershipMatchFriendPage: React.FC = () => {
  const { user } = useAuthContext() as { user: IUserData };
  const history = useHistory();

  const handleConnectWithAnotherFriend = () => {
    history.push(ROUTES.MEMBERSHIP.ABOUT_FRIEND);
  };

  const handleContinue = () => {
    history.push(getUserLandingRoute(user));
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonCol size="auto">
              <GreenCheckMark />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <SecondaryTitle>
                <Trans i18nKey="membership.matchFriend.title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText>
                <Trans i18nKey="membership.matchFriend.body" />
              </BodyText>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <ActionableCard
                onAction={handleConnectWithAnotherFriend}
                actionText={
                  <Trans i18nKey="membership.matchFriend.cardActions.supportWithAnother" />
                }
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button color="primary-orange" onClick={handleContinue}>
                <Trans i18nKey="membership.matchFriend.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembershipMatchFriendPage;
