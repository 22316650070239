import * as React from 'react';

function CardAmex(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={38}
      height={24}
      viewBox="0 0 38 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={38}
        height={24}
      >
        <path
          d="M.92 2.24c0-.927.762-1.687 1.693-1.687H35.33c.93 0 1.692.76 1.692 1.688v19.125c0 .928-.762 1.687-1.692 1.687H2.613c-.931 0-1.693-.76-1.693-1.687V2.24z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M.92 2.24c0-.927.762-1.687 1.693-1.687H35.33c.93 0 1.692.76 1.692 1.688v19.125c0 .928-.762 1.687-1.692 1.687H2.613c-.931 0-1.693-.76-1.693-1.687V2.24z"
          fill="#246EA9"
        />
      </g>
    </svg>
  );
}

export default CardAmex;
