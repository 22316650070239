import * as React from 'react';

export const IconMapDeviceLocation = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={104}
    height={104}
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={104} height={104} rx={52} fill="#EA8231" fillOpacity={0.25} />
    <rect
      x={14}
      y={14}
      width={76}
      height={76}
      rx={38}
      fill="#EA8231"
      fillOpacity={0.5}
    />
    <rect x={28} y={28} width={48} height={48} rx={24} fill="#EA8231" />
    <path
      d="M52.75 61.75a.751.751 0 01-.75-.77v-8.6a.375.375 0 00-.375-.375h-8.606a.764.764 0 01-.726-.499.75.75 0 01.394-.931l18-8.257a.75.75 0 01.995.993l-8.25 18a.75.75 0 01-.682.438z"
      fill="#fff"
    />
  </svg>
);
