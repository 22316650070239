import { GetCoachVisitQuery } from '../../graphql/generated';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { useTranslation } from 'react-i18next';
import { clipboardOutline } from 'ionicons/icons';
import { BryaPartnerVisitNotesModal } from '../BryaPartnerVisitNotesModal/BryaPartnerVisitNotesModal';
import { useState } from 'react';
import { ArrowRight } from '../../assets/images/ArrowRight';

type CoachVisitId = GetCoachVisitQuery['getCoachVisit']['id'];

type ActivityCardItemCoachNotesProps = {
  coachVisitId: CoachVisitId;
};
export const ActivityCardItemCoachNotes: React.FC<
  ActivityCardItemCoachNotesProps
> = ({ coachVisitId }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <IonGrid>
        <IonRow onClick={() => setIsModalOpen(true)}>
          <IonCol
            size="auto"
            className="ion-align-self-center"
            style={{ marginInlineEnd: '8px' }}
          >
            <IonIcon
              icon={clipboardOutline}
              color="var(--colors-primary-blue)"
              style={{ width: '24px', height: '24px' }}
            />
          </IonCol>
          <IonCol className="ion-align-self-center">
            <BodyText color="var(--colors-primary-blue)">
              {t('ActivityCardItemCoachNotes.Label')}
            </BodyText>
          </IonCol>
          <IonCol
            size="auto"
            className="ion-align-self-center"
            style={{ marginInlineStart: '8px' }}
          >
            <ArrowRight />
          </IonCol>
        </IonRow>
      </IonGrid>
      <BryaPartnerVisitNotesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        coachVisitId={coachVisitId}
      />
    </>
  );
};
