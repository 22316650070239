import { IonButton, IonContent, IonTextarea } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export type IonContentProps = {
  reviewsNo?: number;
};

export const Container = styled.div`
  ion-content::part(background) {
    background: #fff;
  }

  .react-tabs {
    font-weight: 500;
    font-size: ${pixelToRem(12)};
    line-height: 15px;
    color: #666666;
    background: #fff;
    &__tab {
      position: unset;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__tab-list {
      display: flex;
      justify-content: space-around;
      border: none;
    }
    &__tab--selected {
      color: #ea8231;
      border: none;
      border-bottom: 2px solid #ea8231;
    }
  }
`;

export const StyledIonContent = styled(IonContent)<IonContentProps>`
  height: calc(100vh - 580px);
  --padding-bottom: 250px;
`;

export const StyledIonContentPost = styled(IonContent)<IonContentProps>`
  height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  align-items: center;
`;

export const SaveButton = styled(IonButton)`
  width: 90%;
  height: 56px;
  margin-bottom: 12px;
  font-weight: 600;
  margin-left: 5%;
  text-transform: capitalize;
  font-size: ${pixelToRem(16)};
  --background-activated: #e59b62;
  --border-radius: 50px;
`;

export const NoFeedbackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NoFeedbackSpan = styled.span`
  color: #212121;
  font-weight: 600;
  font-size: ${pixelToRem(18)};
  line-height: 24px;
  margin-top: 24px;
  text-align: center;
`;

export const FeedbackTitle = styled.p`
  color: #212121;
  font-weight: 700;
  font-size: ${pixelToRem(20)};
  line-height: 24px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
`;

export const FeedbackSubtitle = styled.p`
  color: #212121;
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 22px;
  text-align: center;
  margin-top: 16px;
  width: 80%;
  margin-left: 10%;
`;

export const FeedbackLabel = styled.p`
  font-weight: 500;
  font-size: ${pixelToRem(14)};
  line-height: 20px;
  color: #212121;
  margin-left: 4px;
`;

export const FeedbackItem = styled.div`
  border: 1px solid #bdbdbd;
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
`;

export const FeedbackItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
`;

export const FeedbackItemText = styled.span`
  font-weight: 500;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  color: #392a27;
  margin-left: 8px;
`;

export const FeedbackItemBottomTexts = styled.span`
  font-weight: 400;
  font-size: ${pixelToRem(12)};
  line-height: 15px;
  color: var(--Base-2, #92847b);
`;

export const StyledIonTextArea = styled(IonTextarea)`
  border: solid 2px #e0e0e0;
  border-radius: 14px;
  height: 96px;
  margin-top: 8px;
  margin-bottom: 40px;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #616161;
  .textarea-bottom {
    border-top: none;
  }
  .textarea-bottom.sc-ion-textarea-md .counter.sc-ion-textarea-md {
    color: #000;
  }
  .has-focus.sc-ion-textarea-md textarea.sc-ion-textarea-md {
    caret-color: transparent;
  }
`;
