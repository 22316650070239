import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  CircleRequestCategoriesEnum,
  GetCircleRequestQuery,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestDescription } from '../ActivityCardItemRequestDescription/ActivityCardItemRequestDescription';
import { ActivityCardItemRequestTimeline } from '../ActivityCardItemRequestTimeline/ActivityCardItemRequestTimeline';
import { useHistory } from 'react-router';
import { ActivityCardItemRideInformation } from '../ActivityCardItemRideInformation/ActivityCardItemRideInformation';
import IconActivityCompleted from '../../assets/images/IconActivityCompleted';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { ActivityCardItemRequestConnectAcceptedBy } from '../ActivityCardItemRequestConnectAcceptedBy/ActivityCardItemRequestConnectAcceptedBy';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import { RequestCategoryName } from '../RequestCategoryName/RequestCategoryName';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type CircleRequestQueryResult = GetCircleRequestQuery['getCircleRequest'];

type CompletedCustomerActivityCircleRequestProps = {
  circleRequest: CircleRequestQueryResult;
};

export const CompletedCustomerActivityCircleRequest: React.FC<
  CompletedCustomerActivityCircleRequestProps
> = ({ circleRequest }) => {
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(circleRequest.recurring);
  const isRide =
    circleRequest.RequestCategory?.id === CircleRequestCategoriesEnum.Ride;
  const history = useHistory();
  const { directChatMessage } = useDirectChatMessage();

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle
            title={
              <RequestCategoryName
                category={circleRequest.RequestCategory?.name}
              />
            }
          />
        ) : (
          <RequestCategoryName category={circleRequest.RequestCategory?.name} />
        )
      }
      background="var(--colors-gradient-light-blue)"
      bottomSheetBackground="var(--colors-gradient-light-blue-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityCompleted />
          {t('CompletedCustomerActivityCircleRequest.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('CompletedCustomerActivityCircleRequest.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <CardList>
            <ActivityCardItemRequestConnectAcceptedBy
              acceptedBy={circleRequest.AcceptedBy}
              onConnect={() => {
                if (circleRequest.AcceptedBy?.id) {
                  return directChatMessage(circleRequest.AcceptedBy?.id);
                }

                return Promise.resolve();
              }}
            />
            {isRide ? (
              <ActivityCardItemRideInformation ride={circleRequest.ride} />
            ) : (
              <ActivityCardItemAddress address={circleRequest.Address} />
            )}
            <ActivityCardItemRequestScheduledAt
              scheduledAt={circleRequest.scheduledAt}
            />
            <ActivityCardItemRequestDescription
              description={circleRequest.description}
            />
            <ActivityCardItemRequestTimeline
              timeline={circleRequest.Timeline}
            />
          </CardList>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
