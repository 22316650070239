import { yupResolver } from '@hookform/resolvers/yup';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormInput from '../FormInput/FormInput';
import { IonCol, IonRow } from '@ionic/react';
import { Button } from '../Button/Button';
import {
  CreateAddressInput,
  CreateCustomerInput,
} from '../../graphql/generated';
import { TertiaryTitle } from '../Typography/Headings/Headings';
import { getPhoneNumberInputYupValidator } from '../../utils/form/getPhoneNumberInputYupValidator';

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

export type AboutLovedOneFormFields = Pick<
  CreateCustomerInput,
  'firstName' | 'lastName' | 'courtesyTitle' | 'email' | 'phoneNumber'
> &
  Pick<
    CreateAddressInput,
    'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zipCode'
  >;

interface AboutLovedOneFormProps {
  onSubmit: SubmitHandler<AboutLovedOneFormFields>;
}

export const AboutLovedOneForm: React.FC<AboutLovedOneFormProps> = ({
  onSubmit,
}) => {
  const i18n = useTranslation();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.firstName.errorMessage')),
        lastName: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.lastName.errorMessage')),
        courtesyTitle: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.courtesyTitle.errorMessage')),
        email: yup
          .string()
          .email(i18n.t('forms.aboutLovedOne.email.errorMessage'))
          .required(i18n.t('forms.aboutLovedOne.email.errorMessage')),
        phoneNumber: yup
          .string()
          .test(
            ...getPhoneNumberInputYupValidator(
              i18n.t('forms.common.phoneNumber.errors.invalid')
            )
          )
          .required(i18n.t('forms.aboutLovedOne.phoneNumber.errorMessage')),
        addressLine1: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.addressLine1.errorMessage')),
        addressLine2: yup.string(),
        city: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.city.errorMessage')),
        state: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.state.errorMessage')),
        zipCode: yup
          .string()
          .required(i18n.t('forms.aboutLovedOne.zipCode.errorMessage')),
      }),
    []
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AboutLovedOneFormFields>({
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.firstName.label')}
              name={i18n.t('forms.aboutLovedOne.firstName.name')}
              register={register('firstName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.lastName.label')}
              name={i18n.t('forms.aboutLovedOne.lastName.name')}
              register={register('lastName')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.courtesyTitle.label')}
              name={i18n.t('forms.aboutLovedOne.courtesyTitle.name')}
              register={register('courtesyTitle')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.email.label')}
              name={i18n.t('forms.aboutLovedOne.email.name')}
              register={register('email')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.phoneNumber.label')}
              name={i18n.t('forms.aboutLovedOne.phoneNumber.name')}
              register={register('phoneNumber')}
              closeLabels
              errors={errors}
              type="tel"
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <TertiaryTitle>
              <Trans i18nKey="forms.aboutLovedOne.homeAddressTitle" />
            </TertiaryTitle>
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.addressLine1.label')}
              name={i18n.t('forms.aboutLovedOne.addressLine1.name')}
              register={register('addressLine1')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.addressLine2.label')}
              name={i18n.t('forms.aboutLovedOne.addressLine2.name')}
              register={register('addressLine2')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.city.label')}
              name={i18n.t('forms.aboutLovedOne.city.name')}
              register={register('city')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow className="ion-padding-bottom">
        <IonCol className="ion-padding-end">
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.state.label')}
              name={i18n.t('forms.aboutLovedOne.state.name')}
              register={register('state')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>

        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutLovedOne.zipCode.label')}
              name={i18n.t('forms.aboutLovedOne.zipCode.name')}
              register={register('zipCode')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <Button color="primary-orange" type="submit" loading={isSubmitting}>
              <Trans i18nKey="forms.aboutLovedOne.submit" />
            </Button>
          </motion.div>
        </IonCol>
      </IonRow>
    </form>
  );
};
