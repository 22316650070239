/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { StyledIonModal, Title } from './ModalPaymentFailed.style';
import { useTranslation } from 'react-i18next';

import { IonContent } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { Button } from '../Button/Button';
import { BodyText } from '../Typography/Body/Body';
import { TertiaryTitle } from '../Typography/Headings/Headings';
import CostBox from '../CostTableCard/CostBox';
import PaymentMethod from '../CostTableCard/PaymentMethod';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';

interface ModalPaymentFailedProps {
  isOpenModal: boolean;
  onDidDismiss: (event?: CustomEvent<OverlayEventDetail<any>>) => void;
  onHandleContactSupport: () => Promise<void>;
  errorPaymentMethodId?: string;
}

const ModalPaymentFailed: React.FC<ModalPaymentFailedProps> = ({
  isOpenModal,
  onDidDismiss,
  onHandleContactSupport,
  errorPaymentMethodId,
}) => {
  const { t } = useTranslation();

  return (
    <StyledIonModal
      initialBreakpoint={0.7}
      breakpoints={[0, 0.7, 1]}
      isOpen={isOpenModal}
      onDidDismiss={onDidDismiss}
    >
      <IonContent className="ion-padding">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IconActivityCancelled />
        </div>

        <Title className="ion-text-center">
          {t('modalPaymentFailed.title')}
        </Title>

        <BodyText style={{ textAlign: 'center', marginBottom: '24px' }}>
          {t('StatusPage.paymentIssue.description')}
        </BodyText>

        {errorPaymentMethodId && (
          <>
            <TertiaryTitle>{t('serviceSummary.paymentMethod')}</TertiaryTitle>
            <CostBox>
              {<PaymentMethod stripePaymentMethodId={errorPaymentMethodId} />}
            </CostBox>
          </>
        )}

        <Button
          color="primary-orange"
          style={{ marginTop: '40px' }}
          onClick={() => {
            onDidDismiss();
          }}
        >
          {t('StatusPage.paymentIssue.actions.changePaymentMethod')}
        </Button>

        <Button
          color="secondary-orange"
          style={{ marginTop: '16px' }}
          onClick={onHandleContactSupport}
        >
          {t('serviceSummary.contactSupport')}
        </Button>
      </IonContent>
    </StyledIonModal>
  );
};

export default ModalPaymentFailed;
