import React, { useEffect, useMemo, useState } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import FormTextArea from '../../components/FormTextArea/FormTextArea';
import { useForm } from 'react-hook-form';
import FormSelect from '../../components/FormSelect/FormSelect';
import { Button } from '../../components/Button/Button';
import {
  CreateServiceRequestInput,
  RecurringFrequencyType,
  ServiceRequestCategoriesEnum,
  ServiceRequestTentativeSchedule,
  useCreateServiceRequestMutation,
  useGetAddressesLazyQuery,
  useGetAddressesQuery,
  useGetGooglePlacesApiKeyQuery,
  useListRequestCategoriesQuery,
} from '../../graphql/generated';
import { useLocation, useParams } from 'react-router';
import { ModalAddressForm } from '../../components/ModalAddressForm/ModalAddressForm';
import FormRadioGroup from '../../components/FormRadioGroup/FormRadioGroup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  homeOutline,
  arrowForwardOutline,
  calendarClearOutline,
  repeatOutline,
} from 'ionicons/icons';
import { BodyText, BodyTextSmall } from '../../components/Typography/Body/Body';
import CardButton from '../../components/CardButton/CardButton';
import FormCardToggle from '../../components/FormCardToggle/FormCardToggle';
import {
  FormContainer,
  FormLabel,
  IonContentStyled,
} from './ServiceRequestPage.styles';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { TertiaryTitle } from '../../components/Typography/Headings/Headings';
import { QUERY_PARAMS } from '../../constants/routes';
import { formatAddress } from '../../utils/address/formatAddress';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';

const ServiceRequestPage: React.FC = () => {
  const [addressFormIsOpen, setAddressFormIsOpen] = useState(false);
  const i18n = useTranslation();
  const location = useLocation();
  const params = useParams<{ customerId: string }>();
  const { globalAlert } = useAppContext();
  const { isFeatureEnabled } = useFeatureFlags();

  const urlSearch = new URLSearchParams(location.search);
  const customerId = Number(params.customerId);

  const [createServiceRequest] = useCreateServiceRequestMutation();

  const { data: getGooglePlacesApiKeyData } = useGetGooglePlacesApiKeyQuery();

  const {
    data: getAddressesData,
    refetch: refetchAddresses,
    loading: loadingGetAddresses,
  } = useGetAddressesQuery({
    skip: !customerId,
    variables: {
      customerId,
    },
  });

  const [getAddresses] = useGetAddressesLazyQuery();

  const formSchema = useMemo(
    () =>
      // NOTE: we haven't defined error messages for some fields
      // because they are always defined because they all
      // have default values that fit the form data shape.
      yup.object().shape({
        description: yup
          .string()
          .required(
            i18n.t('ServiceRequestPage.DescriptionFieldIsRequiredErrorMessage')
          ),
        category: yup.string().required(),
        addressId: yup
          .number()
          .required(
            i18n.t('ServiceRequestPage.AddressIdFieldIsRequiredErrorMessage')
          ),
        tentativeSchedule: yup.string().required(),
        isAvailabilityFlexible: yup.bool().required(),
        recurring: yup.bool().required(),
        frequency: yup
          .string()
          .test(
            'frequency-is-required-if-request-is-recurring',
            i18n.t('ServiceRequestPage.FrequencyFieldIsRequiredErrorMessage'),
            function (currentValue?: string) {
              return this.parent.recurring ? !!currentValue : true;
            }
          ),
      }),
    []
  );

  const {
    setValue,
    reset,
    register,
    unregister,
    watch,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<CreateServiceRequestInput>({
    defaultValues: async () => {
      const { data: getAddressesData } = await getAddresses({
        variables: { customerId },
      });
      const defaultAddress = getAddressesData?.getAddresses.find(
        (address) => address.isDefault
      );
      return {
        customerId,
        description:
          urlSearch.get(QUERY_PARAMS.SERVICE_REQUEST.DESCRIPTION) || '',
        category:
          (urlSearch.get(
            QUERY_PARAMS.SERVICE_REQUEST.CATEGORY
          ) as ServiceRequestCategoriesEnum) ||
          ServiceRequestCategoriesEnum.Other,
        tentativeSchedule: ServiceRequestTentativeSchedule.AsSoonAsPossible,
        addressId: defaultAddress?.id || Number.NaN,
        isAvailabilityFlexible: true,
        availabilities: [],
        recurring: false,
      };
    },
    resolver: yupResolver(formSchema),
  });

  const [isAvailabilityFlexible, recurring] = watch([
    'isAvailabilityFlexible',
    'recurring',
  ]);

  const {
    data: listRequestCategoriesData,
    loading: loadingListRequestCategories,
  } = useListRequestCategoriesQuery({
    variables: {
      serviceRequest: true,
    },
  });

  const categoryOptions = useMemo(
    () =>
      listRequestCategoriesData?.listRequestCategories.map(({ name, id }) => ({
        label: name,
        value: id,
      })) || [],
    [listRequestCategoriesData]
  );

  const addressOptions = useMemo(
    () =>
      getAddressesData?.getAddresses.map((address) => ({
        label: formatAddress(address),
        value: address.id,
      })) || [],
    [getAddressesData]
  );

  const tentativeScheduleOptions = useMemo(
    () => [
      {
        label: i18n.t(
          'ServiceRequestPage.TentativeScheduleAsSoonAsPossibleOptionText'
        ),
        value: ServiceRequestTentativeSchedule.AsSoonAsPossible,
      },
      {
        label: i18n.t(
          'ServiceRequestPage.TentativeScheduleWithinDaysOptionText'
        ),
        value: ServiceRequestTentativeSchedule.WithinDays,
      },
      {
        label: i18n.t(
          'ServiceRequestPage.TentativeScheduleWithinWeeksOptionText'
        ),
        value: ServiceRequestTentativeSchedule.WithinWeeks,
      },
      {
        label: i18n.t(
          'ServiceRequestPage.TentativeScheduleChooseTimeAndDateOptionText'
        ),
        value: ServiceRequestTentativeSchedule.ChooseTimeAndDate,
      },
    ],
    []
  );

  const isAvailabilityFlexibleOptions = useMemo(
    () => [
      {
        label: i18n.t(
          'ServiceRequestPage.IsAvailabilityFlexibleOptionTrueText'
        ),
        value: true,
      },
      {
        label: i18n.t(
          'ServiceRequestPage.IsAvailabilityFlexibleOptionFalseText'
        ),
        value: false,
      },
    ],
    []
  );

  const frequencyOptions = useMemo(
    () => [
      {
        label: i18n.t('ServiceRequestPage.FrequencyWeeklyOptionText'),
        value: RecurringFrequencyType.Weekly,
      },
      {
        label: i18n.t('ServiceRequestPage.FrequencyBiWeeklyOptionText'),
        value: RecurringFrequencyType.BiWeekly,
      },
      {
        label: i18n.t('ServiceRequestPage.FrequencyMonthlyOptionText'),
        value: RecurringFrequencyType.Monthly,
      },
    ],
    []
  );

  const handleOnSaveAddress = async (addressId: number) => {
    setAddressFormIsOpen(false);
    await refetchAddresses();
    setValue('addressId', addressId);
  };

  const onSubmit = async (data: CreateServiceRequestInput) => {
    try {
      await createServiceRequest({
        variables: {
          data,
        },
      });
    } catch (err) {
      logger.error({
        tag: '[SERVICE_REQUEST_PAGE]',
        message: `Failed to submit a service request: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: i18n.t('ServiceRequestPage.SubmitFormErrorAlertTitle'),
        subtitle:
          getApolloErrorMessage(err) ||
          i18n.t('ServiceRequestPage.SubmitFormErrorAlertSubtitle'),
        firstButtonLabel: i18n.t(
          'ServiceRequestPage.SubmitFormErrorAlertFirstButtonLabel'
        ),
      });
    }
  };

  const clearForm = () => {
    reset({
      description: '',
      category: ServiceRequestCategoriesEnum.Other,
      addressId: 0,
      isAvailabilityFlexible: true,
      tentativeSchedule: ServiceRequestTentativeSchedule.AsSoonAsPossible,
      recurring: false,
      customerId,
    });
  };

  useEffect(() => {
    if (!recurring) unregister('frequency');
  }, [recurring]);

  // TODO: implement and use a skeleton of the page layout component
  if (loadingListRequestCategories || loadingGetAddresses) return null;

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{i18n.t('ServiceRequestPage.PageTitle')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContentStyled fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonTitle size="large">
              {i18n.t('ServiceRequestPage.PageTitle')}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <FormContainer className="ion-padding ion-margin-top">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormTextArea
              containerClassName="ion-margin-bottom custom-ion-margin-32"
              {...register('description')}
              errors={errors}
              label={
                <FormLabel>
                  {i18n.t('ServiceRequestPage.DescriptionFieldLabel')}
                </FormLabel>
              }
            />
            <FormSelect
              containerClassName="ion-margin-bottom custom-ion-margin-32"
              {...register('category')}
              errors={errors}
              options={categoryOptions}
              label={
                <FormLabel>
                  {i18n.t('ServiceRequestPage.CategoryFieldLabel')}
                </FormLabel>
              }
            />
            <FormSelect
              {...register('addressId')}
              errors={errors}
              options={addressOptions}
              leftIcon={homeOutline}
              label={
                <FormLabel>
                  {i18n.t('ServiceRequestPage.AddressIdFieldLabel')}
                </FormLabel>
              }
            />
            <div
              className="ion-margin-bottom custom-ion-margin-32"
              role="button"
              onClick={() => setAddressFormIsOpen(true)}
            >
              <BodyText>
                {i18n.t('ServiceRequestPage.AddressFormTriggerTextPart1')}
                &nbsp;
                <IonText color="primary-orange">
                  {i18n.t('ServiceRequestPage.AddressFormTriggerTextPart2')}
                </IonText>
              </BodyText>
            </div>
            <FormSelect
              containerClassName="ion-margin-bottom custom-ion-margin-32"
              {...register('tentativeSchedule')}
              errors={errors}
              options={tentativeScheduleOptions}
              label={
                <FormLabel>
                  {i18n.t('ServiceRequestPage.TentativeScheduleFieldLabel')}
                </FormLabel>
              }
            />
            <div className="ion-margin-vertical">
              <FormRadioGroup
                value={isAvailabilityFlexible}
                {...register('isAvailabilityFlexible')}
                errors={errors}
                options={isAvailabilityFlexibleOptions}
              />
            </div>
            {!isAvailabilityFlexible && (
              <div className="ion-margin-vertical">
                <CardButton
                  leftIcon={calendarClearOutline}
                  rightIcon={arrowForwardOutline}
                  label={i18n.t('ServiceRequestPage.AddAvailabilityButtonText')}
                />
              </div>
            )}
            {isFeatureEnabled(FeatureNames.RequestRecurringInput) && (
              <FormCardToggle
                leftIcon={repeatOutline}
                label={i18n.t('ServiceRequestPage.IsRecurringToggleLabel')}
                {...register('recurring')}
              />
            )}
            {recurring && (
              <FormSelect
                {...register('frequency')}
                errors={errors}
                placeholder={i18n.t(
                  'ServiceRequestPage.FrequencySelectPlaceholder'
                )}
                options={frequencyOptions}
                label={
                  <>
                    <TertiaryTitle color="var(--body-text-900)">
                      {i18n.t('ServiceRequestPage.FrequencySelectLabelTitle')}
                    </TertiaryTitle>
                    <div className="ion-margin-vertical">
                      <BodyTextSmall color="var(--body-text-700)">
                        {i18n.t(
                          'ServiceRequestPage.FrequencySelectLabelDescription'
                        )}
                      </BodyTextSmall>
                    </div>
                  </>
                }
              />
            )}
            <Button color="primary-orange" type="submit" loading={isSubmitting}>
              {i18n.t('ServiceRequestPage.SubmitButtonText')}
            </Button>
            <Button color="primary-orange" fill="outline" onClick={clearForm}>
              {i18n.t('ServiceRequestPage.ClearFormButtonText')}
            </Button>
          </form>
        </FormContainer>
      </IonContentStyled>
      <ModalAddressForm
        key={`${addressFormIsOpen}`}
        customerId={customerId}
        googlePlacesApiKey={
          getGooglePlacesApiKeyData?.getGooglePlacesApiKey.apiKey
        }
        isOpen={addressFormIsOpen}
        onDidDismiss={() => setAddressFormIsOpen(false)}
        onSaveAddress={handleOnSaveAddress}
      />
    </IonPage>
  );
};

export default ServiceRequestPage;
