import { IonPage } from '@ionic/react';
import { useParams } from 'react-router';
import {
  SocialEventStatus,
  useGetCoachVisitQuery,
} from '../../graphql/generated';
import { ActivityDetailSkeletonPage } from '../../components/ActivityDetailSkeletonPage/ActivityDetailSkeletonPage';
import { logger } from '../../logger';
import { ConfirmedCustomerActivityCoachVisit } from '../../components/ConfirmedCustomerActivityCoachVisit/ConfirmedCustomerActivityCoachVisit';
import { CompletedCustomerActivityCoachVisit } from '../../components/CompletedCustomerActivityCoachVisit/CompletedCustomerActivityCoachVisit';
import { CancelledCustomerActivityCoachVisit } from '../../components/CancelledCustomerActivityCoachVisit/CancelledCustomerActivityCoachVisit';

type PageParams = {
  activityId: string;
};
export const CustomerActivityCoachVisitPage: React.FC = () => {
  const { activityId } = useParams<PageParams>();
  // TODO: handle error with generic error page?
  const { data, loading } = useGetCoachVisitQuery({
    variables: {
      coachVisitId: Number(activityId),
    },
  });

  const coachVisit = data?.getCoachVisit;

  const getComponent = () => {
    if (!coachVisit) return null;
    switch (coachVisit.status) {
      case SocialEventStatus.Confirmed:
        return <ConfirmedCustomerActivityCoachVisit coachVisit={coachVisit} />;
      case SocialEventStatus.Completed:
        return <CompletedCustomerActivityCoachVisit coachVisit={coachVisit} />;
      case SocialEventStatus.Cancelled:
        return <CancelledCustomerActivityCoachVisit coachVisit={coachVisit} />;
      default:
        logger.error({
          tag: '[CustomerActivityCoachVisitPage][UnhandledStatus]',
          message: `Unhandled activity status: ${
            coachVisit.status as string
          }, for coach visit event with ID: ${coachVisit.id}`,
        });
        // TODO: show error page
        return null;
    }
  };

  return (
    <IonPage>
      {loading || !coachVisit ? <ActivityDetailSkeletonPage /> : getComponent()}
    </IonPage>
  );
};
