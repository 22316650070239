import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonButton } from '@ionic/react';

export const Container = styled.div`
  margin-top: 32px;
  ion-segment {
    --background: transparent !important;
    max-width: 240px;
  }
  ion-segment-button {
    margin-right: 8px;
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    color: #212121;
    padding: 5px;
    border-radius: 16px;
    --background: #eeeeee;
    --background-checked: #ea8231;
    --color-checked: #ea8231;
  }
  ion-segment-button::part(indicator-background) {
    background: #ea8231;
  }

  ion-segment-button::part(native) {
    color: #212121;
  }

  .segment-button-checked::part(native) {
    color: #fff;
  }

  ion-segment-button::part(indicator-background) {
    border-radius: 16px;
  }
`;

export const Content = styled.div`
  p {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: #616161;
  }

  ion-input {
    label {
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 140%;
      color: #616161;
    }
  }
`;

export const AddStopButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddStopButton = styled(IonButton)`
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 140%;
  color: #ea8231;
  text-transform: capitalize !important;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  ion-icon {
    margin-right: 8px;
  }
`;
export const StopContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ion-icon {
    width: 24px;
    height: 24px;
    color: #ef8b8b;
    margin-left: 16px;
    margin-bottom: 12px;
  }
`;
