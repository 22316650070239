import { IonItem, IonLabel, SearchbarInputEventDetail } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import {
  SearchContainer,
  SearchList,
  StyledSearchbar,
} from './GooglePlacesSearch.styles';

type OnPlaceResultHandler = (
  placeResult: google.maps.places.PlaceResult | null,
  prediction: google.maps.places.AutocompletePrediction
) => unknown;

interface GooglePlacesSearchProps {
  googlePlaceApiKey: string;
  onPlaceResult?: OnPlaceResultHandler;
}

const GooglePlacesSearch: React.FC<GooglePlacesSearchProps> = ({
  googlePlaceApiKey,
  onPlaceResult,
}) => {
  const i18n = useTranslation();
  const [input, setInput] = useState('');
  const [fetchingPlaceDetails, setFetchingPlaceDetails] = useState(false);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isQueryPredictionsLoading,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googlePlaceApiKey,
  });

  useEffect(() => {
    getPlacePredictions({ input });
  }, [input]);

  const handleSearchbarInput = useCallback(
    (event: CustomEvent<SearchbarInputEventDetail>) => {
      setInput(event.detail?.value || '');
    },
    []
  );

  const handlePlacePredictionSelect = (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    setFetchingPlaceDetails(true);
    placesService?.getDetails(
      { placeId: prediction.place_id },
      (placeResult) => {
        setFetchingPlaceDetails(false);
        setInput('');
        if (onPlaceResult) onPlaceResult(placeResult, prediction);
      }
    );
  };

  const isLoading = isQueryPredictionsLoading || isPlacePredictionsLoading;

  return (
    <SearchContainer>
      <StyledSearchbar
        color="light"
        className="ion-no-marging ion-no-padding"
        value={input}
        debounce={1000}
        onIonInput={handleSearchbarInput}
        placeholder={i18n.t('searchAddress')}
      />
      <SearchList>
        {isLoading && input && (
          <IonItem color="light">
            <IonLabel>{i18n.t('loading')}</IonLabel>
          </IonItem>
        )}
        {placePredictions.map((placePrediction) => (
          <IonItem
            color="light"
            key={placePrediction.place_id}
            button
            disabled={fetchingPlaceDetails}
            onClick={() => handlePlacePredictionSelect(placePrediction)}
          >
            <IonLabel className="ion-text-wrap">
              {placePrediction.description}
            </IonLabel>
          </IonItem>
        ))}
        {!placePredictions.length && !isLoading && input && (
          <IonItem color="light">
            <IonLabel>{i18n.t('noResults')}</IonLabel>
          </IonItem>
        )}
      </SearchList>
    </SearchContainer>
  );
};

export default GooglePlacesSearch;
