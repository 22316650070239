/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';

import { Container } from './CircleUserActionSelector.style';
import { IonList, IonItem, IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import CustomAlert from '../CustomAlert/CustomAlert';
import { t } from 'i18next';

type CircleUserActionSelectorProps = {
  onResendUserInvite: () => void;
  onDeleteUserInvite: () => void;
};

const CircleUserActionSelector: React.FC<CircleUserActionSelectorProps> = ({
  onDeleteUserInvite,
  onResendUserInvite,
}) => {
  const i18n = useTranslation();
  const [isAlertOpen, setAlertIsOpen] = useState<boolean>(false);

  const goToResend = () => {
    if (onResendUserInvite) {
      onResendUserInvite();
    }
  };

  const confirmDelete = () => {
    setAlertIsOpen(true);
  };

  const decideAction = (clickedAction: string) => {
    if (clickedAction === 'resend') {
      goToResend();
    } else if (clickedAction === 'remove') {
      void confirmDelete();
    }
  };

  return (
    <Container>
      <IonList>
        <IonItem lines="none">
          <IonSelect
            interface="popover"
            value={''}
            onIonChange={(e) => decideAction(e.detail.value as string)}
          >
            <IonSelectOption value="resend">
              {i18n.t('myAccount.resend')}
            </IonSelectOption>
            <IonSelectOption value="remove">
              {i18n.t('myAccount.remove')}
            </IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonList>

      <CustomAlert
        isDisabled={false}
        isOpen={isAlertOpen}
        onDidDismiss={() => setAlertIsOpen(false)}
        title={t('myAccount.confirmMemberRemoval')}
        subtitle1={t('myAccount.confirmMemberRemovalDetail')}
        firstButtonLabel={t('myAccount.remove')}
        firstButtonAction={() => {
          void onDeleteUserInvite();
          setAlertIsOpen(false);
        }}
        secondButtonLabel={t('cancel')}
        secondButtonAction={() => {
          setAlertIsOpen(false);
        }}
      />
    </Container>
  );
};

export default CircleUserActionSelector;
