import { useCallback } from 'react';
import { logger } from '../../logger';
import { useReadNotificationsMutation } from '../../graphql/generated';
import { getApolloErrorMessage } from '../apollo/errors';

interface NotificationParams {
  notificationId: string;
  notificationChannel?: string | null;
}
export const useNotificationParamsHandler = () => {
  const [readNotifications] = useReadNotificationsMutation();
  return useCallback(
    async ({ notificationId, notificationChannel }: NotificationParams) => {
      // TODO: do analytics with notificationChannel?
      logger.info({
        tag: '[NotificationParamsHandler]',
        message: `Notification received with id: ${notificationId} and channel: ${notificationChannel}`,
      });

      try {
        await readNotifications({
          variables: {
            notificationsIds: [Number(notificationId)],
          },
        });
      } catch (err) {
        logger.warn({
          tag: '[NotificationParamsHandler][fail]',
          message: `Fail trying to read notification: ${notificationId}: ${getApolloErrorMessage(
            err
          )}`,
        });
      }
    },
    []
  );
};
