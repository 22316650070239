import * as React from 'react';

export const ShieldOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    {...props}
  >
    <path
      d="M18.3753 10.4707L12.315 17.7217L9.62402 14.9913"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3254 6.8652C20.4352 5.95668 18.4134 5.28379 13.9996 3.21924C9.58576 5.28379 7.56396 5.95668 2.6738 6.8652C1.78787 21.4082 13.1563 26.43 13.9996 26.7817C14.8429 26.43 26.2113 21.4082 25.3254 6.8652Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
