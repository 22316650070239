import { useGetServiceRequestQuery } from '../../graphql/generated';

// NOTE: we are creating this enum manually here because the generated one
// contains wrong values because of naming issues
// more info: https://github.com/MichalLytek/type-graphql/issues/741
export enum StripeTransactionStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export const useGetOutstandingBalanceStatus = (serviceRequestId?: number) => {
  const { data: serviceSummaryData } = useGetServiceRequestQuery({
    variables: { getServiceRequestId: Number(serviceRequestId) },
    skip: !serviceRequestId,
  });

  const serviceRequestPayments =
    serviceSummaryData?.getServiceRequest.ServiceRequestPayments;

  const pendingPaymentStatus = [
    StripeTransactionStatus.RequiresAction,
    StripeTransactionStatus.RequiresConfirmation,
    StripeTransactionStatus.RequiresPaymentMethod,
  ];

  const pendingPaymentsTransactions =
    serviceRequestPayments
      ?.filter((payment) =>
        pendingPaymentStatus.includes(
          payment.StripeTransaction?.status as StripeTransactionStatus
        )
      )
      .map((transaction) => transaction.stripeTransactionId) ?? [];

  const paymentPending = serviceRequestPayments?.find((payment) =>
    pendingPaymentsTransactions.includes(String(payment.stripeTransactionId))
  );
  const hasRefund = paymentPending?.amount && paymentPending?.amount < 0;

  const showOutstandingBalance = paymentPending && !hasRefund;

  return showOutstandingBalance;
};
