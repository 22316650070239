import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { Space } from '../../components/Space/Space';
import {
  peopleCircleOutline,
  peopleOutline,
  personOutline,
} from 'ionicons/icons';
import { Button } from '../../components/Button/Button';
import { MainTitle } from '../../components/Typography/Headings/Headings';
import { Card, CardTitle, Checkbox } from './FeatureSelectionPage.styles';
import { BodyText } from '../../components/Typography/Body/Body';
import { Trans } from 'react-i18next';
import {
  SubmitHandler,
  useForm,
  Controller,
  ControllerRenderProps,
  Resolver,
} from 'react-hook-form';
import { useContext, useEffect, useMemo } from 'react';
import { AuthContext, useAuthContext } from '../../providers/authProvider';
import { IUserData } from '../../types';
import {
  SelectAppFeaturesInput,
  useSetAppFeaturesSelectedMutation,
} from '../../graphql/generated';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { ROUTES } from '../../constants/routes';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { useHistory } from 'react-router';

type SeniorMemberField = {
  field: ControllerRenderProps<SelectAppFeaturesInput, 'seniorMember'>;
};
type FamilyPartnerField = {
  field: ControllerRenderProps<SelectAppFeaturesInput, 'familyPartner'>;
};
type CircleMemberField = {
  field: ControllerRenderProps<SelectAppFeaturesInput, 'circleMember'>;
};

const ERROR_TYPES = {
  NONE_OPTION: 'NONE_OPTION_SELECTED',
};

const formErrorValidator: Resolver<SelectAppFeaturesInput> = (values) => {
  if (!values.seniorMember && !values.familyPartner && !values.circleMember) {
    return {
      values: {},
      errors: {
        // NOTE: using root is not persisted in formState.errors,
        // so we need to use any of the fields
        seniorMember: {
          type: ERROR_TYPES.NONE_OPTION,
        },
      },
    };
  }

  return {
    values,
    errors: {},
  };
};

const FeatureSelectionPage: React.FC = () => {
  const { globalAlert } = useAppContext();
  const { logout, refreshToken } = useAuthContext();
  const history = useHistory();
  const [setAppFeaturesSelectedMutation, { loading }] =
    useSetAppFeaturesSelectedMutation();
  const { user } = useContext(AuthContext) as { user: IUserData };
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<SelectAppFeaturesInput>({
    resolver: formErrorValidator,
    defaultValues: {
      seniorMember: false,
      familyPartner: false,
      circleMember: false,
      userId: user?.id,
    },
  });

  const noneOptionError = useMemo(() => {
    return Boolean(
      Object.values(errors).some((error) =>
        Boolean(error?.type === ERROR_TYPES.NONE_OPTION)
      )
    );
  }, [errors]);

  useEffect(() => {
    if (noneOptionError) {
      globalAlert({
        title: (
          <Trans i18nKey="featureSelection.errors.noneOptionSelected.title" />
        ),
        subtitle: (
          <Trans i18nKey="featureSelection.errors.noneOptionSelected.subtitle" />
        ),
        firstButtonLabel: (
          <Trans i18nKey="featureSelection.errors.noneOptionSelected.firstButtonLabel" />
        ),
        firstButtonAction() {
          clearErrors();
        },
      });
    }
  }, [noneOptionError]);

  const onSubmit: SubmitHandler<SelectAppFeaturesInput> = async (data) => {
    try {
      await setAppFeaturesSelectedMutation({
        variables: { appFeaturesSelected: { ...data } },
      });
      await refreshToken();

      // TODO: How to handle multiple combinations of features selected?
      history.push(ROUTES.MEMBERSHIP.ABOUT_YOU);
      return;
    } catch (err) {
      logger.error({
        tag: '[SET_APP_FEATURES_SELECTED_MUTATION]',
        message: `Fail trying to update app features selected: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };
  return (
    <IonPage>
      <HeaderWithAction
        actionText={<Trans i18nKey="featureSelection.backCta" />}
        onAction={async () => {
          if (loading) return;
          try {
            await logout();
          } finally {
            history.replace('/login');
          }
        }}
      />
      <IonContent className="ion-padding">
        <Space direction="column" size="large">
          <MainTitle>
            <Trans
              i18nKey="featureSelection.title"
              values={{ name: user?.firstName }}
            />
          </MainTitle>
          <BodyText>
            <Trans i18nKey="featureSelection.body" />
          </BodyText>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction="column" size="medium">
              <Card>
                {/* NOTE: this could be improved with some sort of `useCustomForm` which
                  extends the `register` function to return the different props needed
                  for each ionic form element (like `control` and `setValue` for Checkbox) */}
                <Controller
                  name="seniorMember"
                  control={control}
                  render={({ field }: SeniorMemberField) => (
                    <Checkbox
                      labelPlacement="end"
                      value={field.value as boolean}
                      onIonChange={({ detail }) =>
                        setValue('seniorMember', detail.checked)
                      }
                    >
                      <Space direction="column" size="small">
                        <Space direction="row" size="4px" align="center">
                          <IonIcon
                            src={personOutline}
                            style={{
                              fontSize: '32px',
                              minWidth: '32px',
                              color: 'var(--colors-primary-blue)',
                            }}
                          />
                          <CardTitle>
                            <Trans i18nKey="featureSelection.cards.seniorMember.title" />
                          </CardTitle>
                        </Space>
                        <BodyText>
                          <Trans i18nKey="featureSelection.cards.seniorMember.body" />
                        </BodyText>
                      </Space>
                    </Checkbox>
                  )}
                />
              </Card>

              <Card>
                <Controller
                  name="familyPartner"
                  control={control}
                  render={({ field }: FamilyPartnerField) => (
                    <Checkbox
                      labelPlacement="end"
                      value={field.value as boolean}
                      onIonChange={({ detail }) =>
                        setValue('familyPartner', detail.checked)
                      }
                    >
                      <Space direction="column" size="small">
                        <Space direction="row" size="4px" align="center">
                          <IonIcon
                            src={peopleCircleOutline}
                            style={{
                              fontSize: '32px',
                              minWidth: '32px',
                              color: 'var(--colors-primary-blue)',
                            }}
                          />
                          <CardTitle>
                            <Trans i18nKey="featureSelection.cards.familyPartner.title" />
                          </CardTitle>
                        </Space>
                        <BodyText>
                          <Trans i18nKey="featureSelection.cards.familyPartner.body" />
                        </BodyText>
                      </Space>
                    </Checkbox>
                  )}
                />
              </Card>

              <Card>
                <Controller
                  name="circleMember"
                  control={control}
                  render={({ field }: CircleMemberField) => (
                    <Checkbox
                      labelPlacement="end"
                      value={field.value as boolean}
                      onIonChange={({ detail }) =>
                        setValue('circleMember', detail.checked)
                      }
                    >
                      <Space direction="column" size="small">
                        <Space direction="row" size="4px" align="center">
                          <IonIcon
                            src={peopleOutline}
                            style={{
                              fontSize: '32px',
                              minWidth: '32px',
                              color: 'var(--colors-primary-blue)',
                            }}
                          />
                          <CardTitle>
                            <Trans i18nKey="featureSelection.cards.circleMember.title" />
                          </CardTitle>
                        </Space>
                        <BodyText>
                          <Trans i18nKey="featureSelection.cards.circleMember.body" />
                        </BodyText>
                      </Space>
                    </Checkbox>
                  )}
                />
              </Card>
              <Button color="primary-orange" type="submit" loading={loading}>
                <Trans i18nKey="featureSelection.cta" />
              </Button>
            </Space>
          </form>
        </Space>
      </IonContent>
    </IonPage>
  );
};

export default FeatureSelectionPage;
