/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  GraphqlNotification,
  useGetNotificationsByUserIdQuery,
  useReadNotificationsMutation,
} from '../graphql/generated';
import { logger } from '../logger';
import { getApolloErrorMessage } from '../utils/apollo/errors';
import { NetworkStatus } from '@apollo/client';
import { AuthContext } from './authProvider';
import { IUserData } from '../types';
import { useNotifications } from './notificationsProvider';
import { ROUTES } from '../constants/routes';
import { useLocation } from 'react-router-dom';
import { ServiceSummary } from '../components/ServiceSummary/ServiceSummary';
import { usePersistentPolling } from '../utils/hooks/usePersistentPolling';

interface ServiceRequestFinishedNotificationHandlerProps {
  notificationId: number;
  serviceRequestId: number;
}
const ServiceRequestFinishedNotificationHandler: React.FC<
  ServiceRequestFinishedNotificationHandlerProps
> = ({ notificationId, serviceRequestId }) => {
  const [readNotifications] = useReadNotificationsMutation();
  const [isRatingProfessional, setIsRatingProfessional] = useState(false);
  const [handledNotificationsIds, setHandledNotificationsIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (notificationId && !handledNotificationsIds.includes(notificationId)) {
      setIsRatingProfessional(true);
    }
  }, [notificationId, handledNotificationsIds]);

  const handleFinish = async () => {
    try {
      await readNotifications({
        variables: {
          notificationsIds: [notificationId],
        },
      });
    } catch (err) {
      logger.error({
        tag: '[ServiceRequestFinishedNotificationHandler][readNotifications]',
        message: `Fail while reading notification: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
    } finally {
      setIsRatingProfessional(false);
      setHandledNotificationsIds((prev) => [...prev, notificationId]);
    }
  };
  if (isRatingProfessional) {
    return (
      <ServiceSummary
        onClose={() => {
          void handleFinish();
        }}
        serviceRequestId={serviceRequestId}
      />
    );
  }
  return null;
};

interface ServiceRequestFinishedPayload {
  serviceRequestId: number;
}
const getServiceRequestFinishedPayload = (
  notification: GraphqlNotification
) => {
  const casted = notification.payload as ServiceRequestFinishedPayload;
  if (casted.serviceRequestId) {
    return casted;
  } else {
    logger.error({
      tag: '[getServiceRequestFinishedPayload]',
      message: `Fail trying to cast notification payload to ServiceRequestFinishedPayload: ${JSON.stringify(
        notification.payload
      )}`,
    });
  }
};

interface NotificationsHandlerProps {
  notifications: GraphqlNotification[];
}
const NotificationsHandler: React.FC<NotificationsHandlerProps> = ({
  notifications,
}) => {
  // NOTE: here we can handle different type of notifications
  const serviceRequestFinishedNotification = notifications.find(
    (notification) =>
      // TODO: export enum from backend?
      notification.type === 'serviceRequestPostVisitSummaryCreated'
  );

  if (serviceRequestFinishedNotification) {
    const payload = getServiceRequestFinishedPayload(
      serviceRequestFinishedNotification
    );
    return payload ? (
      <ServiceRequestFinishedNotificationHandler
        notificationId={serviceRequestFinishedNotification.id}
        {...payload}
      />
    ) : null;
  }

  return null;
};

const ROUTES_WITH_NOTIFICATIONS_ENABLED = [
  ROUTES.TABS.ASK_BRYA,
  ROUTES.TABS.ACTIVITIES,
  ROUTES.TABS.ACCOUNT,
  ROUTES.TABS.CIRCLE,
  ROUTES.TABS.CONNECT,
  ROUTES.TABS.CHAT,
];

const ERROR_THRESHOLD_BACK_OFF = 3;
// TODO: configurable by env?
const POLL_INTERVAL = 45 * 1000;

const NotificationsProviderAuthed: React.FC = () => {
  const [errorCount, setErrorCount] = useState<number>(0);
  const {
    setUnReadNotificationsCount,
    setNewServiceNotification,
    setNewCircleNotification,
  } = useNotifications();
  const { pathname } = useLocation();

  const { data, error, loading, stopPolling, networkStatus, startPolling } =
    useGetNotificationsByUserIdQuery({
      pollInterval: 0,
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          unread: true,
        },
        // TODO: implement pagination if needed
        page: 1,
        pageSize: 100,
      },
    });

  const { startPersistentPolling, stopPersistentPolling } =
    usePersistentPolling(startPolling, stopPolling, POLL_INTERVAL);

  useEffect(() => {
    if (ROUTES_WITH_NOTIFICATIONS_ENABLED.includes(pathname)) {
      startPersistentPolling();
    } else {
      stopPersistentPolling();
    }
  }, [pathname, startPersistentPolling, stopPersistentPolling]);

  const notifications = data?.getNotificationsByUserId
    ?.notifications as GraphqlNotification[];

  useEffect(() => {
    if (error) {
      logger.info({
        tag: '[NotificationsProvider][useGetNotificationsByUserIdQuery]',
        message: `Fail while getting notifications: ${getApolloErrorMessage(
          error
        )}`,
        error: error as Error,
      });

      setErrorCount((prev) => prev + 1);

      if (errorCount >= ERROR_THRESHOLD_BACK_OFF) {
        logger.info({
          tag: '[NotificationsProvider][stopPolling]',
          message: `Reach polling error threshold: ${errorCount}, last error: ${getApolloErrorMessage(
            error
          )}`,
          error: error as Error,
        });
        stopPolling();
      }
    }
  }, [error]);

  const handleCircleNotifications = useCallback(() => {
    const circleRequestNotification = notifications.find(
      (notification) =>
        notification.type === 'circleRequest' && notification.readAt === null
    );
    if (circleRequestNotification) {
      setNewCircleNotification(true);
    } else {
      setNewCircleNotification(false);
    }
  }, [notifications, setNewCircleNotification]);

  const handleServiceNotifications = useCallback(() => {
    const serviceRequestNotification = notifications.find(
      (notification) =>
        notification.type === 'serviceRequest' && notification.readAt === null
    );

    if (serviceRequestNotification) {
      setNewServiceNotification(true);
    } else {
      setNewServiceNotification(false);
    }
  }, [notifications, setNewServiceNotification]);

  useEffect(() => {
    if (notifications) {
      handleCircleNotifications();
      handleServiceNotifications();
      // NOTE: if data is fetched we reset the error counter
      setUnReadNotificationsCount(notifications?.length);
      setErrorCount(0);
    }
  }, [notifications]);

  if (loading && networkStatus === NetworkStatus.loading) {
    // NOTE: network status is loading on the first fetch only
    return null;
  }

  return notifications ? (
    <NotificationsHandler notifications={notifications} />
  ) : null;
};

export const NotificationsPolling: React.FC = () => {
  const { user } = useContext(AuthContext) as {
    user: IUserData;
  };
  return user ? <NotificationsProviderAuthed /> : null;
};
