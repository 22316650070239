import React from 'react';
import {
  CellContainer,
  CellContent,
  HeaderContainer,
  IconContainer,
  TextContainer,
  TimeStampContainer,
} from './NotificationCell.styles';
import { IonSkeletonText } from '@ionic/react';

// import { Container } from './styles';

const NotificationCellSkeleton: React.FC = () => {
  return (
    <CellContainer>
      <CellContent>
        <IconContainer>
          <IonSkeletonText
            animated
            style={{
              width: '2.077rem',
              height: '2.125rem',
            }}
          />
        </IconContainer>
        <HeaderContainer>
          <TextContainer>
            <h2>
              <IonSkeletonText
                animated
                style={{
                  width: '100%',
                  height: '0.5rem',
                }}
              />
            </h2>
            <p>
              <IonSkeletonText
                animated
                style={{
                  width: '100%',
                  height: '3rem',
                }}
              />
            </p>
          </TextContainer>

          <TimeStampContainer>
            <span>
              <IonSkeletonText
                animated
                style={{
                  width: '50%',
                  height: '1rem',
                }}
              />
            </span>
          </TimeStampContainer>
        </HeaderContainer>
      </CellContent>
    </CellContainer>
  );
};

export default NotificationCellSkeleton;
