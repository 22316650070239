import { IonToast } from '@ionic/react';
import styled from 'styled-components';

export const StyledIonToast = styled(IonToast)<{ fontColor?: string }>`
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  color: ${({ fontColor }) => fontColor || '#212121'};
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.01563rem;
  line-height: 20px;
`;
