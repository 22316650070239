/* eslint-disable @typescript-eslint/no-explicit-any */
export function getApolloErrorMessage(error: any): string {
  if (error?.networkError?.result?.errors?.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return error.networkError.result.errors
      .map((e: any): string => String(e.message))
      .join('\n');
  }

  if (error?.message) {
    return error.message as string;
  }

  return '';
}

export function getApolloErrorCodes(error: any): string[] {
  if (error?.networkError?.result?.errors?.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return error.networkError.result.errors.map((e: any): string =>
      String(e?.extensions?.errorCode)
    );
  }

  return [];
}
