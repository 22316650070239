import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';
import { IonContent } from '@ionic/react';

export const Title = styled.h2`
  margin-top: 30px;
  color: #23364b;
  text-align: center;
  font-size: ${pixelToRem(30)};
  line-height: 130%;
  letter-spacing: 0.002em;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: ${pixelToRem(22)};
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.012em;
  color: #616161;
`;

export const PageTopContainer = styled.div`
  margin-bottom: 24px;
`;

export const PageContent = styled(IonContent)`
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 75%;
`;
