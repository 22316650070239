import React, { useState, useEffect, useRef, useContext } from 'react';
import { IonContent } from '@ionic/react';
import { ChatInboxPage } from './chat-inbox.styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChatInboxSkeleton } from '../../../components/ChatInboxSkeleton/ChatInboxSkeleton';
import { TalkJsContext } from '../../../providers/talkJsContext';
import HeaderWithTitle from '../../../components/HeaderWithTitle/HeaderWithTitle';
import { PATH_PARAMS, ROUTES } from '../../../constants/routes';
import { logger } from '../../../logger';

const ChatInbox: React.FC = () => {
  const inboxElemRef = useRef(null);
  const { t } = useTranslation();
  const history = useHistory();

  const [inboxMounted, setInboxMounted] = useState(false);
  const TalkJs = useContext(TalkJsContext);

  useEffect(() => {
    if (!TalkJs?.session) return;

    const inbox = TalkJs?.session.createInbox({ theme: 'mobile' });

    inbox.onSelectConversation((event) => {
      event.preventDefault();
      history.push(
        ROUTES.CHATBOX.replace(
          PATH_PARAMS.CONVERSATION_ID,
          event.conversation.id
        )
      );
    });

    inbox
      .mount(inboxElemRef.current)
      .then(() => {
        setInboxMounted(true);
      })
      .catch(() =>
        logger.error({
          tag: '[TalkJS inbox.mount]',
          message: 'Error mounting talkjs inbox',
        })
      );
  }, [TalkJs?.session]);

  return (
    <ChatInboxPage>
      {history.location.pathname === ROUTES.CHAT && (
        <HeaderWithTitle title={t('connectWithCommunity')} />
      )}
      <IonContent className="ion-padding">
        {!inboxMounted && <ChatInboxSkeleton numberOfSections={3} />}
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={inboxElemRef}
        />
      </IonContent>
    </ChatInboxPage>
  );
};

export default ChatInbox;
