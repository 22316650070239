import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonCard,
  IonCardHeader,
  IonButtons,
  IonPage,
  isPlatform,
} from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { chevronBack } from 'ionicons/icons';
import { BackLabel } from '../locations/locations.styles';
import { Community } from '../../assets/images/Community';
import { RightArrow } from '../../assets/images/RightArrow';
import { CardContent, CardMiddleContent, CardText } from './chatbox.styles';
import {
  TrustedUserTypeOptions,
  useGetUserParticipantsQuery,
  useGetConversationQuery,
} from '../../graphql/generated';
import { TalkJsContext } from '../../providers/talkJsContext';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';
import styled from 'styled-components';
import { logger } from '../../logger';
import { ModalConversationParticipants } from '../../components/ModalConversationParticipants/ModalConversationParticipants';
import { StorageKey, getStorage } from '../../utils/storage';
import { useAppContext } from '../../providers/appContextProvider';
import { environments } from '../../utils/env';

export const Toolbar = styled(IonToolbar)`
  --background: white;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

type PageParams = {
  conversationId: string;
};

const PARTICIPANTS_CARD_HEIGHT = '76px';
const CARD_MARGIN = '48px';

const Chatbox: React.FC = () => {
  const { env } = useAppContext();
  const apiUrl = environments[env].apiUrl;
  const [chatboxMounted, setChatboxMounted] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const chatboxEl = useRef(null);
  const i18n = useTranslation();
  const history = useHistory();
  const TalkJs = useContext(TalkJsContext);
  const { conversationId } = useParams<PageParams>();
  const { isFeatureEnabled } = useFeatureFlags();

  const { data: convInfo } = useGetConversationQuery({
    variables: {
      conversationId: conversationId ?? '',
    },
    skip: !conversationId || !TalkJs?.session,
  });

  const { data: participants, refetch } = useGetUserParticipantsQuery({
    variables: {
      conversationId,
      filter: { type: TrustedUserTypeOptions.All },
    },
    skip: !conversationId || !TalkJs?.session,
  });

  useEffect(() => {
    if (conversationId && TalkJs?.session) void refetch();
  }, [conversationId, TalkJs?.session]);

  useEffect(() => {
    if (!TalkJs?.session || !conversationId || !chatboxEl.current) return;
    const chatbox = TalkJs?.session.createChatbox({
      theme: 'mobile',
    });

    chatbox.onCustomMessageAction('toggle_favorite', ({ message }) => {
      void getStorage(StorageKey.Token).then(({ value: accessToken }) => {
        if (!accessToken) return;
        const isFavorite = message.custom && message.custom.saved === 'true';

        const newStatus = isFavorite ? 'false' : 'true';

        void fetch(`${apiUrl}/chat/update-favorite-message`, {
          method: 'POST',
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversationId: message.conversation.id,
            messageId: message.id,
            saved: newStatus,
          }),
        });
      });
    });

    chatbox.onCustomMessageAction('report_message', ({ message }) => {
      void getStorage(StorageKey.Token).then(({ value: accessToken }) => {
        if (!accessToken) return;
        const isReported = message.custom && message.custom.reported === 'true';

        const newStatus = isReported ? 'false' : 'true';

        void fetch(`${apiUrl}/chat/report-message`, {
          method: 'POST',
          headers: {
            Authorization: accessToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversationId: message.conversation.id,
            messageId: message.id,
            message: message.body,
            userId: message.senderId,
            reported: newStatus,
          }),
        });
      });
    });

    chatbox.onCustomConversationAction('favorite_messages', () => {
      chatbox.setMessageFilter({ custom: { saved: ['==', 'true'] } });
    });

    chatbox.onCustomConversationAction('all_messages', () => {
      chatbox.setMessageFilter({});
    });

    chatbox.select(conversationId);
    chatbox
      .mount(chatboxEl.current)
      .then(() => setChatboxMounted(true))
      .catch((err) => {
        logger.error({
          tag: '[ChatboxPage][TalkJS chatbox.mount]',
          message: `Error mounting talkjs chatbox: ${err}`,
          error: err,
        });
      });
  }, [TalkJs?.session, conversationId, chatboxEl.current]);

  const goBack = () => {
    history.goBack();
  };

  const onOpenModal = () => setOpenModal(true);

  const showParticipantsCard =
    isFeatureEnabled(FeatureNames.ConnectTabContactsButton) &&
    Number(participants?.getUserParticipants?.usersFromTalkJs?.length) > 0;

  return (
    <IonPage>
      <IonHeader>
        <Toolbar>
          <IonButtons slot="start">
            <IonButton onClick={goBack}>
              <IonIcon icon={chevronBack} color="primary" />
              <BackLabel>{i18n.t('back')}</BackLabel>
            </IonButton>
          </IonButtons>
        </Toolbar>
      </IonHeader>
      <IonContent>
        {showParticipantsCard && (
          <IonCard
            onClick={onOpenModal}
            style={{ height: PARTICIPANTS_CARD_HEIGHT }}
          >
            <IonCardHeader>
              <CardContent>
                <CardContent>
                  <Community color={'#23364B'} width={32} height={32} />
                  <CardMiddleContent>
                    <CardText>
                      {convInfo?.getConversation?.subject ? (
                        <Trans
                          i18nKey="members"
                          values={{
                            chatName: convInfo.getConversation.subject,
                          }}
                        />
                      ) : (
                        i18n.t('ChatboxPage.ChatParticipantsCardFallbackText')
                      )}
                    </CardText>
                  </CardMiddleContent>
                </CardContent>
                <RightArrow color="#23364B" width={24} />
              </CardContent>
            </IonCardHeader>
          </IonCard>
        )}
        <div
          ref={chatboxEl}
          style={{
            width: '100%',
            paddingBottom: isPlatform('ios') ? 15 : 0,
            height: showParticipantsCard
              ? `calc(100% - ${PARTICIPANTS_CARD_HEIGHT} - ${CARD_MARGIN})`
              : '100%',
          }}
        />

        <ModalConversationParticipants
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          conversationName={convInfo?.getConversation?.subject}
          conversationId={conversationId}
        />
        {!chatboxMounted && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: '80px' }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '80%' }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '60%' }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '30%' }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Chatbox;
