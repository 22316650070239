import {
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  ServiceRequestAvailability,
  ServiceRequestTentativeSchedule,
} from '../../graphql/generated';

import { Space } from '../../components/Space/Space';
import { Trans } from 'react-i18next';
import { useMountAnimated } from '../../utils/hooks/useMountAnimated';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import { pixelToRem } from '../../utils/helper';
import {
  TimeSlot,
  generateRange,
  parseFromAvailability,
  parseUntilAvailability,
  DayjsDateInput,
} from '../../utils/date';
import dayjs from 'dayjs';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

const UpperToolbar = styled(IonToolbar)`
  --background: auto;
  --padding-top: 16px;
  --padding-end: 8px;
  --padding-start: 8px;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

const LowerToolbar = styled(IonToolbar)`
  --background: auto;
  --padding-bottom: 16px;
  --padding-end: 8px;
  --padding-start: 8px;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

const MainTitle = styled.h1`
  font-family: Inter;
  font-size: ${pixelToRem(24)};
  font-weight: 700;
  line-height: 29px;
`;

const LeftHeaderButton = styled.button`
  font-family: Inter;
  font-size: ${pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  color: var(--colors-primary-orange);
  background: none;
`;

const RightHeaderButton = styled.button`
  font-family: Inter;
  font-size: ${pixelToRem(16)};
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  color: var(--colors-primary-orange);
  background: none;
`;

const DateSelected = styled.h2`
  font-family: Roboto;
  font-size: ${pixelToRem(16)};
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  width: 100%;
  text-align: center;
`;

interface DateTimeOptionProps {
  selected?: boolean;
}

const DateTimeOptionSelectedStyles = css`
  border: 1px solid var(--colors-primary-orange);
  background: linear-gradient(0deg, #fff7e6, #fff7e6);
`;
const DateTimeOption = styled.div<DateTimeOptionProps>`
  border: 1px solid var(--body-text-300);
  border-radius: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-family: Inter;
  font-size: ${pixelToRem(14)};
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.012em;
  color: var(--body-text-900);
  ${({ selected }) => (selected ? DateTimeOptionSelectedStyles : '')}
`;

const RecurringInfo = styled.p`
  font-family: Roboto;
  font-size: ${pixelToRem(14)};
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 24px;
  color: var(--body-text-900);
`;

const TIME_STEP_IN_MINUTES = 15;
const INITIAL_TIME_SLOT_VALUE = '07:00';

interface SelectDateAndTimeModalSheetProps {
  isOpen: boolean;
  onClose: () => void;
  createdAt: Date;
  availabilities: Array<ServiceRequestAvailability>;
  tentativeSchedule: ServiceRequestTentativeSchedule;
  recurring: boolean;
  frequency: string;

  // date in format YYYY-MM-DD and time in format HH:mm
  // representing in the specified timezone
  onSubmit: (date: string, time: string) => void;

  // TODO: support timezone prop
}

let curr = dayjs().startOf('day');
const ALL_DAY_TIME_SLOTS: Array<TimeSlot> = [];
while (curr.diff(dayjs().startOf('day'), 'day') === 0) {
  ALL_DAY_TIME_SLOTS.push({
    value: curr.format('HH:mm'),
    label: curr.format('h:mm A'),
  });
  curr = curr.add(TIME_STEP_IN_MINUTES, 'minute');
}

const generateTimeSlotsForAvailability = (
  availability: ServiceRequestAvailability,
  dateSelected: string, // format YYYY-MM-DD
  getTimeSlotInTimezoneFunction: (
    date: DayjsDateInput,
    timezone?: string
  ) => TimeSlot
): TimeSlot[] => {
  const fromDate = parseFromAvailability(availability);
  const untilDate = parseUntilAvailability(availability);

  const range = generateRange(
    fromDate,
    untilDate,
    TIME_STEP_IN_MINUTES,
    'minute',
    'minute',
    '[)'
  );

  // NOTE: due to timezone differences the fromDate and
  // untilDate could be in different calendar days
  return range
    .map((dateInRange) => getTimeSlotInTimezoneFunction(dateInRange))
    .filter((timeSlot) => {
      return timeSlot.fullDate?.includes(dateSelected);
    });
};

export const SelectDateAndTimeModalSheet: React.FC<
  SelectDateAndTimeModalSheetProps
> = ({
  isOpen,
  onClose,
  availabilities,
  tentativeSchedule,
  createdAt,
  onSubmit,
  recurring,
  frequency,
}) => {
  const {
    formatAvailabilityInTimezone,
    getAvailabilityDaysByTentativeScheduleInTimezone,
    getTimeSlotInTimezone,
  } = useDateWithTimezone();

  const modal = useRef<HTMLIonModalElement>(null);
  const initialTimeSlot = useRef<HTMLInputElement>(null);
  const isRequestorFlexible = useMemo(
    () => availabilities.length === 0,
    [availabilities]
  );

  const availableDays = useMemo((): Set<string> => {
    if (!isRequestorFlexible) {
      return new Set(
        availabilities
          .map((availability) => formatAvailabilityInTimezone(availability))
          .flat()
      );
    } else {
      return getAvailabilityDaysByTentativeScheduleInTimezone(
        tentativeSchedule,
        createdAt
      );
    }
  }, [
    createdAt,
    tentativeSchedule,
    availabilities,
    formatAvailabilityInTimezone,
    getAvailabilityDaysByTentativeScheduleInTimezone,
  ]);

  const [dateSelected, setDateSelected] = useState<string | undefined>();
  const [timeSelected, setTimeSelected] = useState<string | undefined>();

  const timeSelectorRef = useRef(null);
  useMountAnimated({ ref: timeSelectorRef, hide: !dateSelected });

  useEffect(() => {
    if (dateSelected && initialTimeSlot) {
      initialTimeSlot.current?.scrollIntoView();
    }
  }, [dateSelected]);

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      onDidDismiss={() => {
        // NOTE: reset state on dismiss
        setDateSelected(undefined);
        setTimeSelected(undefined);
        onClose();
      }}
    >
      <IonHeader>
        <UpperToolbar>
          <IonButtons slot="start">
            <LeftHeaderButton
              onClick={() => {
                if (dateSelected) {
                  setDateSelected(undefined);
                  setTimeSelected(undefined);
                } else {
                  onClose();
                }
              }}
            >
              <Trans i18nKey="circleMemberActivityDetail.dateTimeSelector.buttons.back" />
            </LeftHeaderButton>
          </IonButtons>
          {dateSelected ? (
            <IonTitle>
              <MainTitle>
                {dayjs(parseISOLocal(dateSelected)).format('dddd')}
              </MainTitle>
            </IonTitle>
          ) : (
            <IonTitle>
              <MainTitle>
                <Trans i18nKey="circleMemberActivityDetail.dateTimeSelector.title" />
              </MainTitle>
            </IonTitle>
          )}
          {dateSelected && timeSelected ? (
            <IonButtons slot="end">
              <RightHeaderButton
                onClick={() => {
                  if (dateSelected && timeSelected) {
                    onSubmit(dateSelected, timeSelected);
                  }
                }}
              >
                <Trans i18nKey="circleMemberActivityDetail.dateTimeSelector.buttons.save" />
              </RightHeaderButton>
            </IonButtons>
          ) : null}
        </UpperToolbar>
        {dateSelected ? (
          <LowerToolbar>
            <IonTitle>
              <DateSelected>
                {dayjs(parseISOLocal(dateSelected)).format('MMMM DD, YYYY')}
              </DateSelected>
            </IonTitle>
          </LowerToolbar>
        ) : null}
      </IonHeader>
      <IonContent className="ion-padding">
        <Space direction="column" size="large">
          {dateSelected ? null : (
            <>
              <Space direction="column" size="small">
                {Array.from(availableDays).map((dateFormatted: string) => {
                  const date = dayjs(parseISOLocal(dateFormatted));
                  const currentDate = dayjs();

                  if (
                    date.isAfter(currentDate) ||
                    date.isSame(currentDate, 'day')
                  ) {
                    return (
                      <DateTimeOption
                        key={date.format('dddd D')}
                        onClick={() => setDateSelected(dateFormatted)}
                        selected={
                          dateSelected ? dateFormatted === dateSelected : false
                        }
                      >
                        {date.format('dddd D')}
                      </DateTimeOption>
                    );
                  }
                  return null;
                })}
              </Space>
            </>
          )}

          <div ref={timeSelectorRef}>
            {dateSelected ? (
              <Space direction="column" size="small">
                {!isRequestorFlexible
                  ? availabilities.map((availability) => {
                      const times = generateTimeSlotsForAvailability(
                        availability,
                        dateSelected,
                        getTimeSlotInTimezone
                      );
                      return times.map((time) => (
                        <DateTimeOption
                          key={time.value}
                          onClick={() => setTimeSelected(time.value)}
                          selected={time.value === timeSelected}
                        >
                          {time.label}
                        </DateTimeOption>
                      ));
                    })
                  : ALL_DAY_TIME_SLOTS.map((time) => (
                      <DateTimeOption
                        ref={
                          time.value === INITIAL_TIME_SLOT_VALUE
                            ? initialTimeSlot
                            : undefined
                        }
                        key={time.value}
                        onClick={() => setTimeSelected(time.value)}
                        selected={time.value === timeSelected}
                      >
                        {time.label}
                      </DateTimeOption>
                    ))}
              </Space>
            ) : null}
          </div>
        </Space>
        {recurring && (
          <RecurringInfo>
            <Trans
              i18nKey="circleMemberActivityDetail.dateTimeSelector.recurringInfo"
              values={{
                frequency,
              }}
            />
          </RecurringInfo>
        )}
      </IonContent>
    </IonModal>
  );
};
