import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonTextarea } from '@ionic/react';

export const Container = styled.div`
  ion-label {
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    line-height: 20px;
    color: var(--body-text-900);
    padding-bottom: 4px;
  }
`;

export const StyledTextarea = styled(IonTextarea)<{ hasError?: boolean }>`
  background: var(--white);
  border: 1px solid
    ${({ hasError }) =>
      hasError ? 'var(--ion-color-primary-red)' : 'var(--body-text-900)'};
  border-radius: 14px;
  padding: 16px;
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 140%;
  color: var(--body-text-900);
`;
