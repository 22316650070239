import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { QUERY_PARAMS } from '../../constants/routes';

const randomId = () => Number(new Date()).toString();

/**
 * Returns the last formId query param value that is truthy
 * This is useful for passing the key prop to a form component that
 * you want to control when to reset the state of a React Component
 * Src: https://react.dev/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key
 */
export const useFormId = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const formIdFromQueryParams = queryParams.get(QUERY_PARAMS.FORM_ID);
  const [formId, setFormId] = useState(
    queryParams.get(QUERY_PARAMS.FORM_ID) || randomId()
  );

  useEffect(() => {
    if (formIdFromQueryParams && formIdFromQueryParams !== formId) {
      setFormId(formIdFromQueryParams);
    }
  }, [formIdFromQueryParams]);

  const regenerateFormId = useCallback(() => {
    setFormId(randomId());
  }, []);

  return { formId, regenerateFormId };
};
