import * as React from 'react';

export const Refresh = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 4.68723L14.375 6.56223L12.5 8.43722"
      stroke="#23364B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 6.56149H5.625C4.79696 6.56397 4.00354 6.894 3.41802 7.47952C2.83251 8.06503 2.50247 8.85845 2.5 9.68649V10.3115M7.5 15.3115L5.625 13.4365L7.5 11.5615"
      stroke="#23364B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 13.4365H14.375C15.203 13.434 15.9965 13.104 16.582 12.5185C17.1675 11.933 17.4975 11.1395 17.5 10.3115V9.68649"
      stroke="#23364B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
