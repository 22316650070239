import React, { useCallback, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonRow,
  IonSelectOption,
  IonSkeletonText,
  IonTitle,
  IonToast,
  IonToolbar,
  SearchbarInputEventDetail,
} from '@ionic/react';
import {
  FooterButtonText,
  FooterButton,
  Input,
  Searchbar,
  Select,
  Checkbox,
} from './ModalConversationParticipants.style';
import { useTranslation } from 'react-i18next';
import {
  chatboxOutline,
  heartCircleOutline,
  heartDislikeCircleOutline,
  heart,
  closeCircleOutline,
  lockOpenOutline,
} from 'ionicons/icons';
import {
  TrustUntrustActionType,
  TrustedUserTypeOptions,
  useBlockUserMutation,
  useGetBlockedUsersByBlockerUserIdQuery,
  useGetBlockerUsersByBlockedUserIdQuery,
  useGetUserParticipantsQuery,
  useTrustUntrustMembersMutation,
  useUnblockUserMutation,
} from '../../graphql/generated';
import {
  GET_BLOCKED_USERS,
  GET_BLOCKER_USERS,
  GET_USER_PARTICIPANTS,
} from '../../graphql/chat';
import { HeaderDoneButton } from '../HeaderDoneButton/HeaderDoneButton';
import { UnstyledToolbar } from '../UnstyledToolbar/UnstyledToolbar';
import { CustomAlertV2 } from '../CustomAlert/CustomAlert';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { BodyText, BodyTextMedium } from '../Typography/Body/Body';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';

const USER_AVATAR_SIZE = 56;
const CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE = 28;
const TRUSTED_ICON_SIZE = 22;
const FOOTER_ACTION_ICON_SIZE = 30;

const SkeletonConversationParticipantsList: React.FC<{ length?: number }> = ({
  length,
}) => {
  const LENGTH = length || 3;

  return (
    <IonList>
      {[...Array(LENGTH)].map((_, idx) => (
        <IonRow
          key={idx}
          className="ion-padding-vertical"
          style={{
            borderBottom: idx === LENGTH - 1 ? undefined : '1px solid #D9D9D9',
          }}
        >
          <IonCol size="auto" className="ion-align-self-center">
            <IonSkeletonText
              animated
              style={{
                width: USER_AVATAR_SIZE,
                height: USER_AVATAR_SIZE,
                borderRadius: '50%',
              }}
            />
          </IonCol>
          <IonCol
            className="ion-align-self-center"
            style={{ marginInlineStart: '8px' }}
          >
            <IonSkeletonText
              animated
              style={{ width: '60%', height: '23px', marginBottom: '4px' }}
            />
            <IonSkeletonText
              animated
              style={{ width: '30%', height: '19px' }}
            />
          </IonCol>
          <IonCol
            size="auto"
            className="ion-align-self-center"
            style={{ marginInlineStart: '8px' }}
          >
            <IonSkeletonText
              animated
              style={{
                width: CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                height: CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                marginRight: '8px',
              }}
            />
          </IonCol>
          <IonCol
            size="auto"
            className="ion-align-self-center"
            style={{ marginInlineStart: '8px' }}
          >
            <IonSkeletonText
              animated
              style={{
                width: CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                height: CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
              }}
            />
          </IonCol>
        </IonRow>
      ))}
    </IonList>
  );
};

type ModalConversationParticipantsProps = {
  isOpen: boolean;
  onClose: () => unknown;
  conversationId: string;
  conversationName?: string | null;
};

export const ModalConversationParticipants: React.FC<
  ModalConversationParticipantsProps
> = ({ isOpen, onClose, conversationId, conversationName }) => {
  const i18n = useTranslation();
  const [isDoingBulkAction, setIsDoingBulkAction] = useState<boolean>(false);
  const [isNewConversationAlertOpen, setIsNewConversationAlertOpen] =
    useState<boolean>(false);
  const [blockUserAlert, setBlockUserAlert] = useState<number>(0);
  const [newConversationName, setNewConversationName] = useState<string>('');
  const [filter, setFilter] = useState<TrustedUserTypeOptions>(
    TrustedUserTypeOptions.All
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedUsersIds, setSelectedUsersIds] = useState<Set<number>>(
    new Set()
  );
  const [isSuccessToastOpen, setIsSuccessToastOpen] = useState<boolean>(false);
  const startConversationErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ModalConversationParticipants][startConversation]',
  });
  const trustUntrustErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ModalConversationParticipants][trustUntrust]',
  });
  const blockUserErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ModalConversationParticipants][blockUser]',
  });
  const unblockUserErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ModalConversationParticipants][unblockUser]',
  });

  const resetModal = () => {
    setIsDoingBulkAction(false);
    setSelectedUsersIds(new Set());
    setFilter(TrustedUserTypeOptions.All);
    setSearchInput('');
  };

  const { directChatMessage, loading: isCreatingConversation } =
    useDirectChatMessage({
      onCompleted: () => {
        resetModal();
        onClose();
      },
      onError: startConversationErrorHandler,
    });
  const [trustUntrustMembers, { loading: isTogglingTrustUntrust }] =
    useTrustUntrustMembersMutation({
      onError: trustUntrustErrorHandler,
      onCompleted: () => {
        setIsSuccessToastOpen(true);
        setIsDoingBulkAction(false);
        setSelectedUsersIds(new Set());
      },
    });
  const [blockUser, { loading: isBlockingUser }] = useBlockUserMutation({
    onError: blockUserErrorHandler,
    onCompleted: () => {
      setIsSuccessToastOpen(true);
      setIsDoingBulkAction(false);
      setSelectedUsersIds(new Set());
    },
  });
  const [unblockUser, { loading: isUnblockingUser }] = useUnblockUserMutation({
    onError: unblockUserErrorHandler,
    onCompleted: () => {
      setIsSuccessToastOpen(true);
      setIsDoingBulkAction(false);
      setSelectedUsersIds(new Set());
    },
  });
  const { data: participants, loading: isLoadingParticipants } =
    useGetUserParticipantsQuery({
      variables: {
        conversationId,
        filter: { type: filter, fullName: searchInput },
      },
    });

  const { data: blockedUsers, loading: isLoadingBlockedUsers } =
    useGetBlockedUsersByBlockerUserIdQuery();
  const { data: blockerUsers, loading: isLoadingBlockerUsers } =
    useGetBlockerUsersByBlockedUserIdQuery();

  const conversationParticipants =
    participants?.getUserParticipants.usersFromTalkJs;

  const FILTER_OPTIONS = {
    [TrustedUserTypeOptions.All]: {
      label: i18n.t('ModalConversationParticipants.FilterAll'),
      value: TrustedUserTypeOptions.All,
    },
    [TrustedUserTypeOptions.Trusted]: {
      label: i18n.t('ModalConversationParticipants.FilterTrusted'),
      value: TrustedUserTypeOptions.Trusted,
    },
  };

  const handleSearchInput = (event: CustomEvent<SearchbarInputEventDetail>) => {
    setSearchInput(event.detail.value as string);
  };

  const handleTrustUntrust = (
    actionType: TrustUntrustActionType,
    trustedMembersIds: number[]
  ) => {
    void trustUntrustMembers({
      variables: {
        trustedMembersIds,
        action: actionType,
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_USER_PARTICIPANTS],
    });
  };

  const handleSelectToggle = useCallback(() => {
    setIsDoingBulkAction(!isDoingBulkAction);
    if (isDoingBulkAction) {
      setSelectedUsersIds(new Set());
    }
  }, [isDoingBulkAction]);

  const loading =
    isTogglingTrustUntrust ||
    isLoadingParticipants ||
    isCreatingConversation ||
    isLoadingBlockedUsers ||
    isLoadingBlockerUsers ||
    isBlockingUser ||
    isUnblockingUser;
  const isFooterActionDisabled = selectedUsersIds.size < 1 || loading;

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
    >
      <IonHeader>
        <UnstyledToolbar>
          <IonTitle>
            {conversationName
              ? i18n.t('ModalConversationParticipants.Title', {
                  chatName: conversationName,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              : i18n.t('ModalConversationParticipants.FallbackTitle')}
          </IonTitle>
          <IonButtons slot="end">
            <HeaderDoneButton
              color="var(--colors-primary-orange)"
              onClick={onClose}
            />
          </IonButtons>
        </UnstyledToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonRow className="ion-justify-content-between">
          <IonCol size="auto" className="ion-align-self-center">
            <IonButton
              color="primary-orange"
              fill="clear"
              className="ion-no-padding ion-no-margin"
              onClick={handleSelectToggle}
            >
              {isDoingBulkAction
                ? i18n.t('ModalConversationParticipants.SubheaderLabelDone')
                : i18n.t('ModalConversationParticipants.SubheaderLabelSelect')}
            </IonButton>
          </IonCol>
          <IonCol size="auto" className="ion-align-self-center">
            <Select
              interface="popover"
              placeholder={FILTER_OPTIONS[filter].label}
              value={filter}
              onIonChange={(e) =>
                setFilter(e.detail.value as TrustedUserTypeOptions)
              }
            >
              {Object.values(FILTER_OPTIONS).map(({ label, value }) => (
                <IonSelectOption key={value} value={value}>
                  {label}
                </IonSelectOption>
              ))}
            </Select>
          </IonCol>
        </IonRow>
        <Searchbar
          className="ion-margin-bottom ion-no-padding"
          value={searchInput}
          debounce={1500}
          onIonInput={handleSearchInput}
          placeholder={i18n.t(
            'ModalConversationParticipants.SearchInputPlaceholder'
          )}
        />
        {loading && (
          <SkeletonConversationParticipantsList
            length={conversationParticipants?.length}
          />
        )}
        {!loading &&
          (conversationParticipants?.length ? (
            <IonList>
              {conversationParticipants.map((participant, idx) => {
                const isLastElement =
                  idx === conversationParticipants.length - 1;

                const blockedUser =
                  blockedUsers?.getBlockedUsersByBlockerUserId.find(
                    (user) => user.blockedUserId === participant.id
                  );

                const blockedByUser =
                  blockerUsers?.getBlockerUsersByBlockedUserId.find(
                    (user) => user.blockerUserId === participant.id
                  );

                if (blockedByUser) return null;

                return (
                  <IonRow
                    key={participant.id}
                    className="ion-padding-vertical"
                    style={{
                      borderBottom: isLastElement
                        ? undefined
                        : '1px solid #D9D9D9',
                    }}
                  >
                    {isDoingBulkAction && (
                      <IonCol
                        size="auto"
                        className="ion-align-self-center"
                        style={{ marginInlineEnd: '8px' }}
                      >
                        <Checkbox
                          value={selectedUsersIds.has(participant.id)}
                          onIonChange={() => {
                            if (selectedUsersIds.has(participant.id)) {
                              selectedUsersIds.delete(participant.id);
                            } else {
                              selectedUsersIds.add(participant.id);
                            }
                            setSelectedUsersIds(new Set(selectedUsersIds));
                          }}
                        />
                      </IonCol>
                    )}
                    <IonCol
                      size="auto"
                      className="ion-align-self-center"
                      // style={{ position: 'relative' }}
                    >
                      <UserAvatar
                        size={USER_AVATAR_SIZE}
                        fontSize="22px"
                        user={participant}
                      />
                      {participant.trustedMember ? (
                        <IonIcon
                          icon={heart}
                          style={{
                            position: 'absolute',
                            right: 0 - TRUSTED_ICON_SIZE / 6,
                            bottom: 0 - TRUSTED_ICON_SIZE / 6,
                            width: TRUSTED_ICON_SIZE,
                            height: TRUSTED_ICON_SIZE,
                            color: 'var(--colors-primary-orange)',
                          }}
                        />
                      ) : null}
                    </IonCol>
                    <IonCol
                      className="ion-align-self-center"
                      style={{ marginInlineStart: '8px' }}
                    >
                      <BodyText color="var(--body-text-900)">
                        {participant.fullName}
                      </BodyText>
                      {participant.trustedMember ? (
                        <BodyTextMedium color="var(--neutral)">
                          {i18n.t('ModalConversationParticipants.TrustedLabel')}
                        </BodyTextMedium>
                      ) : null}
                      {blockedUser ? (
                        <BodyTextMedium color="var(--neutral)">
                          {i18n.t('ModalConversationParticipants.Blocked')}
                        </BodyTextMedium>
                      ) : null}
                    </IonCol>
                    {!isDoingBulkAction && (
                      <>
                        <IonCol
                          size="auto"
                          className="ion-align-self-center"
                          style={{ marginInlineStart: '8px' }}
                        >
                          {!blockedUser ? (
                            <IonIcon
                              onClick={() => {
                                setBlockUserAlert(participant.id);
                              }}
                              icon={closeCircleOutline}
                              style={{
                                color: 'var(--neutral)',
                                width:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                                height:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                              }}
                            />
                          ) : (
                            <IonIcon
                              onClick={() => {
                                void unblockUser({
                                  variables: { unblockUserId: blockedUser.id },
                                  refetchQueries: [
                                    GET_BLOCKED_USERS,
                                    GET_BLOCKER_USERS,
                                  ],
                                });
                              }}
                              icon={lockOpenOutline}
                              style={{
                                color: 'var(--neutral)',
                                width:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                                height:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                              }}
                            />
                          )}
                        </IonCol>

                        <IonCol
                          size="auto"
                          className="ion-align-self-center"
                          style={{ marginInlineStart: '8px' }}
                        >
                          {participant.trustedMember ? (
                            <IonIcon
                              onClick={() =>
                                void handleTrustUntrust(
                                  TrustUntrustActionType.Untrust,
                                  [participant.id]
                                )
                              }
                              icon={heartDislikeCircleOutline}
                              style={{
                                color: 'var(--neutral)',
                                width:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                                height:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                              }}
                            />
                          ) : (
                            <IonIcon
                              onClick={() =>
                                void handleTrustUntrust(
                                  TrustUntrustActionType.Trust,
                                  [participant.id]
                                )
                              }
                              icon={heartCircleOutline}
                              style={{
                                color: 'var(--neutral)',
                                width:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                                height:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                              }}
                            />
                          )}
                        </IonCol>

                        {!blockedUser && (
                          <IonCol
                            size="auto"
                            className="ion-align-self-center"
                            style={{ marginInlineStart: '8px' }}
                          >
                            <IonIcon
                              onClick={() => {
                                void directChatMessage(participant.id);
                              }}
                              icon={chatboxOutline}
                              style={{
                                color: 'var(--neutral)',
                                width:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                                height:
                                  CONVERSATION_PARTICIPANT_ACTION_ICON_SIZE,
                              }}
                            />
                          </IonCol>
                        )}
                      </>
                    )}
                  </IonRow>
                );
              })}
            </IonList>
          ) : (
            <BodyText className="ion-text-center">
              {i18n.t('ModalConversationParticipants.NoMatchFeedback')}
            </BodyText>
          ))}
        <IonToast
          style={{ '--width': 'fit-content' }}
          isOpen={isSuccessToastOpen}
          message={i18n.t('ModalConversationParticipants.SuccessfulToast')}
          onDidDismiss={() => {
            setIsSuccessToastOpen(false);
          }}
          position="bottom"
          color="success"
          duration={2000}
        ></IonToast>
      </IonContent>
      {isDoingBulkAction && selectedUsersIds.size > 0 && (
        <IonFooter>
          <IonToolbar
            className="ion-padding-vertical"
            style={{ '--background': 'white' }}
          >
            <IonRow className="ion-justify-content-evenly">
              <IonCol size="auto">
                <FooterButton
                  disabled={isFooterActionDisabled}
                  onClick={() =>
                    void handleTrustUntrust(
                      TrustUntrustActionType.Trust,
                      Array.from(selectedUsersIds)
                    )
                  }
                >
                  <IonIcon
                    icon={heartCircleOutline}
                    style={{
                      color: 'var(--colors-primary-blue)',
                      width: FOOTER_ACTION_ICON_SIZE,
                      height: FOOTER_ACTION_ICON_SIZE,
                    }}
                  />
                  <FooterButtonText>
                    {i18n.t('ModalConversationParticipants.FooterActionLike')}
                  </FooterButtonText>
                </FooterButton>
              </IonCol>
              <IonCol size="auto">
                <FooterButton
                  disabled={isFooterActionDisabled}
                  onClick={() =>
                    void handleTrustUntrust(
                      TrustUntrustActionType.Untrust,
                      Array.from(selectedUsersIds)
                    )
                  }
                >
                  <IonIcon
                    icon={heartDislikeCircleOutline}
                    style={{
                      color: 'var(--colors-primary-blue)',
                      width: FOOTER_ACTION_ICON_SIZE,
                      height: FOOTER_ACTION_ICON_SIZE,
                    }}
                  />
                  <FooterButtonText>
                    {i18n.t('ModalConversationParticipants.FooterActionUnlike')}
                  </FooterButtonText>
                </FooterButton>
              </IonCol>
              <IonCol size="auto">
                <FooterButton
                  disabled={isFooterActionDisabled}
                  onClick={() => {
                    if (selectedUsersIds.size === 1) {
                      void directChatMessage(Array.from(selectedUsersIds)[0]);
                      return;
                    }
                    setIsNewConversationAlertOpen(true);
                  }}
                >
                  <IonIcon
                    icon={chatboxOutline}
                    style={{
                      color: 'var(--colors-primary-blue)',
                      width: FOOTER_ACTION_ICON_SIZE,
                      height: FOOTER_ACTION_ICON_SIZE,
                    }}
                  />
                  <FooterButtonText>
                    {i18n.t(
                      'ModalConversationParticipants.FooterActionNewChat'
                    )}
                  </FooterButtonText>
                </FooterButton>
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonFooter>
      )}

      <CustomAlertV2
        isOpen={!!blockUserAlert}
        onDidDismiss={() => setBlockUserAlert(0)}
        title={i18n.t('BlockUserModal.Title')}
        subtitle={i18n.t('BlockUserModal.Subtitle')}
        firstButtonLabel={i18n.t('BlockUserModal.FirstButtonLabel')}
        secondButtonLabel={i18n.t('BlockUserModal.SecondButtonLabel')}
        firstButtonAction={async () => {
          setBlockUserAlert(0);
          await blockUser({
            variables: { blockedUserId: blockUserAlert },
            refetchQueries: [GET_BLOCKED_USERS, GET_BLOCKER_USERS],
          });
        }}
        secondButtonAction={() => setBlockUserAlert(0)}
      />

      <CustomAlertV2
        isOpen={isNewConversationAlertOpen}
        onDidDismiss={() => setIsNewConversationAlertOpen(false)}
        title={i18n.t(
          'ModalConversationParticipants.NewConversationAlertTitle'
        )}
        firstButtonLabel={i18n.t(
          'ModalConversationParticipants.NewConversationAlertFirstButtonLabel'
        )}
        secondButtonLabel={i18n.t(
          'ModalConversationParticipants.NewConversationAlertSecondButtonLabel'
        )}
        firstButtonAction={() => {
          setIsNewConversationAlertOpen(false);
          return directChatMessage(
            Array.from(selectedUsersIds),
            newConversationName
          );
        }}
        secondButtonAction={() => setIsNewConversationAlertOpen(false)}
      >
        <Input
          className="ion-padding"
          placeholder={i18n.t(
            'ModalConversationParticipants.NewConversationAlertInputPlaceholder'
          )}
          onIonInput={(e) => setNewConversationName(e.detail.value as string)}
        />
      </CustomAlertV2>
    </IonModal>
  );
};
