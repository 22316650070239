import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonRow,
  IonList,
  IonIcon,
  IonSpinner,
  useIonRouter,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardText,
  MainContainer,
  CardContent,
  StyledAvatar,
  StyledHeaderColumn,
  CustomIonItem,
  StyledAvatarCircleMember,
  ActiveTag,
  PendingTag,
  UserName,
} from './my-circle.style';
import React, { useCallback, useContext, useState } from 'react';
import { RightArrow } from '../../assets/images/RightArrow';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { AuthContext } from '../../providers/authProvider';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import { CircleNotSelected } from '../../assets/images/CircleNotSelected';
import {
  SentTypesOptions,
  UserInvitesType,
  useDeleteUserInviteMutation,
  useGetUserInvitesByCustomerQuery,
  useResendUserInviteMutation,
} from '../../graphql/generated';
import CircleUserActionSelector from '../../components/CircleUserActionSelector/CircleUserActionSelector';
import { trashOutline } from 'ionicons/icons';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { LoadingContainer } from '../MyProfile/MyProfile.style';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';

const detectStatus = (type: string) => {
  const capitalizedString = type.charAt(0).toUpperCase() + type.slice(1);
  switch (type) {
    case 'accepted':
      return <ActiveTag>{capitalizedString}</ActiveTag>;
    case 'declined':
      return <PendingTag>{capitalizedString}</PendingTag>;
  }
};

const MyCirclePage: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { user } = useContext(AuthContext);
  const [isAlertOpen, setAlertIsOpen] = useState<boolean>(false);
  const [tempSelectedId, setTempSelectedId] = useState<number | null>(null);

  const [deleteUserInviteMutation] = useDeleteUserInviteMutation();
  const [resendUserInviteMutation] = useResendUserInviteMutation();

  const onResendUserInvite = useCallback(async (userInviteId: number) => {
    try {
      await resendUserInviteMutation({
        variables: {
          userInviteData: {
            sentVia: [
              SentTypesOptions.Email,
              SentTypesOptions.Push,
              SentTypesOptions.Sms,
            ],
            userInviteId,
          },
        },
      });
    } catch (err) {
      logger.error({
        tag: '[RESEND_USER_INVITE]',
        message: `Fail resending user invite: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
    }
  }, []);

  const onDeleteUserInvite = useCallback(async (userInviteId: number) => {
    try {
      await deleteUserInviteMutation({
        variables: {
          userInviteId,
        },
      });
    } catch (err) {
      logger.error({
        tag: '[DELETE_USER_INVITE]',
        message: `Fail deleting user invite: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
    }
  }, []);

  const actionBeforeDelete = useCallback(
    (userInviteId: number) => {
      setTempSelectedId(userInviteId);
      setAlertIsOpen(true);
    },
    [tempSelectedId]
  );
  const { data, loading } = useGetUserInvitesByCustomerQuery({
    variables: {
      customerId: user?.customerId ?? 0,
    },
  });

  const filteredArray = data?.getUserInvitesByCustomer.filter(
    (item) => item.inviteType === UserInvitesType.CircleMember
  );

  return (
    <IonPage>
      <HeaderWithAction actionText={<Trans i18nKey="back" />} />
      <MainContainer className="ion-padding">
        <IonRow className="ion-padding-vertical">
          <StyledHeaderColumn>
            <StyledAvatar>
              {user?.firstName?.charAt(0) || 'A'}
              {user?.lastName?.charAt(0) || 'A'}
            </StyledAvatar>
            <SecondaryTitle style={{ marginTop: 8 }}>
              <Trans i18nKey="myAccount.myCircle" />
            </SecondaryTitle>
          </StyledHeaderColumn>
        </IonRow>
        {loading && (
          <LoadingContainer>
            <IonSpinner />
          </LoadingContainer>
        )}
        {!loading && (
          <IonList lines="none">
            {filteredArray?.map((item) => (
              <CustomIonItem key={item.id}>
                <StyledAvatarCircleMember>
                  {item?.name?.charAt(0) || 'A'}
                </StyledAvatarCircleMember>
                <UserName>{item.name}</UserName>
                {detectStatus(item.status)}
                <div slot="end">
                  {item?.status === 'accepted' && (
                    <IonIcon
                      src={trashOutline}
                      style={{ color: '#EA4255', width: 22, height: 22 }}
                      onClick={() => actionBeforeDelete(item.userId ?? 0)}
                    />
                  )}
                  {item?.status !== 'accepted' && (
                    <CircleUserActionSelector
                      onDeleteUserInvite={() =>
                        onDeleteUserInvite(item.userId ?? 0)
                      }
                      onResendUserInvite={() =>
                        onResendUserInvite(item.userId ?? 0)
                      }
                    />
                  )}
                </div>
              </CustomIonItem>
            ))}
          </IonList>
        )}
        <IonCard
          onClick={() =>
            router.push(
              ROUTES.INVITES.INVITE_CIRCLE_MEMBER.replace(
                PATH_PARAMS.CUSTOMER_ID,
                String(user?.customerId)
              )
            )
          }
        >
          <IonCardHeader
            style={{
              padding: '16px',
            }}
          >
            <CardContent>
              <CardContent>
                <CircleNotSelected />
                <CardText>{t('myAccount.addCircleMember')}</CardText>
              </CardContent>
              <RightArrow color="#23364b" />
            </CardContent>
          </IonCardHeader>
        </IonCard>
      </MainContainer>
      <CustomAlert
        isDisabled={false}
        isOpen={isAlertOpen}
        onDidDismiss={() => setAlertIsOpen(false)}
        title={t('myAccount.confirmMemberRemoval')}
        subtitle1={t('myAccount.confirmMemberRemovalDetail')}
        firstButtonLabel={t('myAccount.remove')}
        firstButtonAction={() => {
          void onDeleteUserInvite(tempSelectedId ?? 0);
          setAlertIsOpen(false);
        }}
        secondButtonLabel={t('cancel')}
        secondButtonAction={() => {
          setAlertIsOpen(false);
        }}
      />
    </IonPage>
  );
};

export default MyCirclePage;
