import { IonAccordionGroup } from '@ionic/react';
import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const CustomHr = styled.div`
  margin: 30px 0% 30px 0%;
  width: 100%;
  border: 0.5px solid rgb(158, 158, 158);
`;

export const IonAccordContainer = styled(IonAccordionGroup)`
  width: 100%;
  margin: 16px 0 0 0;
  background: white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;

  ion-item {
    background: white;
  }

  ion-accordion {
    padding: 10px;
    border-radius: 10px;
    background: white;
    display: inline-block;

    ion-item {
      display: inline-block;
    }

    ion-icon.ion-accordion-toggle-icon {
      transform: none !important;
    }
  }

  ion-accordion.accordion-expanding,
  ion-accordion.accordion-expanded {
    width: 100%;
    margin: 0;
  }
`;

export const PaymentCompleteContainer = styled.div`
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding: 16px;
`;
