import React from 'react';
import { ReviewCard, Wrapper } from './ProReviews.styles';
import { IonSkeletonText } from '@ionic/react';

const ProReviewsSkeleton: React.FC = () => {
  return (
    <ReviewCard>
      <h2>
        <IonSkeletonText animated style={{ width: '100px' }} />
      </h2>
      <Wrapper>
        <div>
          <IonSkeletonText animated style={{ width: '80px' }} />
        </div>
        <span>
          <IonSkeletonText animated style={{ width: '20px' }} />
        </span>
      </Wrapper>
      <p>
        {' '}
        <IonSkeletonText animated style={{ width: '200px' }} />
      </p>
      <Wrapper justifyContent="space-between">
        <small>
          <IonSkeletonText animated style={{ width: '50px' }} />
        </small>
        <small>
          <IonSkeletonText animated style={{ width: '50px' }} />
        </small>
      </Wrapper>
    </ReviewCard>
  );
};

export default ProReviewsSkeleton;
