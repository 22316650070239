/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect, useState } from 'react';
import {
  AddStopButton,
  AddStopButtonContainer,
  Container,
  Content,
  StopContainer,
} from './RideForm.styles';
import {
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import FormInput from '../FormInput/FormInput';
import { addCircleOutline, trashOutline } from 'ionicons/icons';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import AddressAutoComplete from '../AddressAutoComplete/AddressAutoComplete';
import {
  GetAddressesQuery,
  GraphqlAddresses,
  useGetGooglePlacesApiKeyQuery,
  useGetZipCodeMatchesBusinessRulesQuery,
} from '../../graphql/generated';
import {
  AddNewAddressErrorZipCode,
  AddNewAddressText,
  AddNewAddressTextOrange,
  Wrapper,
} from '../../pages/service-form/service-form.styles';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '../../assets/images/HomeIcon';
import { UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';

type RideFormProps = {
  register: UseFormRegister<any>;
  setValue: (key: string, value: string) => void;
  errors: unknown;
  clearErrors: (key: string) => void;
  userDefaultAddress: GraphqlAddresses | undefined;
  userAddresses: GetAddressesQuery | undefined;
  onAddressModal: (open: boolean) => void;
  getValues: UseFormGetValues<{
    [x: string]: string;
  }>;
  getAddressId: (id: number) => void;
};

const RideForm: React.FC<RideFormProps> = ({
  register,
  setValue,
  errors,
  clearErrors,
  userDefaultAddress,
  userAddresses,
  onAddressModal,
  getValues,
  getAddressId,
}) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [segmentTab, setSegmentTab] = useState<string>('oneWay');
  const [zipCode, setZipCode] = useState<string | null>(null);
  const i18n = useTranslation();
  const [stopLocations, setStopLocations] = useState([
    { label: '', placeholder: '' },
  ]);
  const { data: apiKeyData, loading: loadingApiKey } =
    useGetGooglePlacesApiKeyQuery();
  const googlePlaceApiKey =
    apiKeyData?.getGooglePlacesApiKey?.apiKey ||
    'AIzaSyBuBLG_5YjAoQqWlroik6tvWDMdXKIonNI';
  const addFormFields = useCallback(() => {
    setStopLocations([
      ...stopLocations,
      { label: 'Stop', placeholder: 'Please enter address' },
    ]);
  }, [stopLocations]);

  const { data: zipCodeCheck } = useGetZipCodeMatchesBusinessRulesQuery({
    variables: {
      zipCode: zipCode ? zipCode : userDefaultAddress?.zipCode ?? '',
    },
    skip: !userDefaultAddress,
  });

  const removeFormFields = (i: number) => {
    const newFormValues = [...stopLocations];
    newFormValues.splice(i, 1);
    setStopLocations(newFormValues);
  };

  useEffect(() => {
    setValue('ride.type', segmentTab);
  }, [segmentTab]);

  const handleHome = useCallback((value: string) => {
    const parts = value.split(',');
    const beforeCommaZipCode = parts[0].trim();
    const afterCommaAddressLine = parts[1].trim();
    const lastAddressId = parts[2].trim();
    if (getValues('category') === 'ride') {
      setValue('ride.pickupAddress.addressLine1', afterCommaAddressLine);
    }
    setValue('home', afterCommaAddressLine + ', ' + beforeCommaZipCode);
    setZipCode(beforeCommaZipCode);
    getAddressId(Number(lastAddressId));
  }, []);

  return (
    <Container>
      <h2>Confirm your locations</h2>
      <IonSegment
        value={segmentTab}
        onIonChange={({ detail }) => {
          setSegmentTab(detail.value as string);
        }}
      >
        <IonSegmentButton value="oneWay">
          <IonLabel>One way</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="roundTrip">
          <IonLabel>Round trip</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <Content>
        {segmentTab === 'oneWay' && (
          <AnimateMotionDiv>
            <p>
              Need a one-way ride? Simply provide us with your pick-up and
              drop-off locations.
            </p>
            {userAddresses?.getAddresses.length === 1 && (
              <Wrapper
                hasError={!zipCodeCheck?.getZipCodeMatchesBusinessRules.success}
              >
                <FormInput
                  icon="home"
                  placeholder="Home"
                  label="Pick-up Location"
                  value="home"
                  register={register('ride.pickupAddress.addressLine1')}
                />
                {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                  <AddNewAddressErrorZipCode>
                    {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                  </AddNewAddressErrorZipCode>
                )}
              </Wrapper>
            )}

            {userAddresses && userAddresses.getAddresses.length > 1 && (
              <AnimateMotionDiv>
                <Wrapper
                  hasError={
                    !zipCodeCheck?.getZipCodeMatchesBusinessRules.success
                  }
                >
                  <h2>Confirm your location</h2>
                  <div style={{ position: 'relative', top: 15, left: 16 }}>
                    <HomeIcon style={{ position: 'absolute' }} />
                  </div>
                  <IonSelect
                    labelPlacement="floating"
                    interface="popover"
                    shape="round"
                    placeholder={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    value={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    onIonBlur={() => clearErrors('home')}
                    className="address-select"
                    onIonChange={({ detail }) => {
                      handleHome(detail.value as string);
                    }}
                  >
                    {userAddresses?.getAddresses?.map((address) => (
                      <IonSelectOption
                        key={address.id}
                        value={
                          address.zipCode +
                          ',' +
                          address.addressLine1 +
                          ',' +
                          address.id.toString()
                        }
                      >
                        {address.addressLine1},{address.city}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                    <AddNewAddressErrorZipCode>
                      {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                    </AddNewAddressErrorZipCode>
                  )}
                  {isFeatureEnabled(
                    FeatureNames.AddAddressForRequestEntryPoint
                  ) ? (
                    <AddNewAddressText>
                      {i18n.t('serviceRequest.lookingFor')}
                      <AddNewAddressTextOrange
                        onClick={() => onAddressModal(true)}
                      >
                        {i18n.t('serviceRequest.addANew')}
                      </AddNewAddressTextOrange>
                    </AddNewAddressText>
                  ) : null}
                </Wrapper>
              </AnimateMotionDiv>
            )}
            {!loadingApiKey && (
              <AddressAutoComplete
                apiKey={String(googlePlaceApiKey)}
                setValue={setValue}
                placeholder="Please enter address"
                label="Drop-off Location"
                errors={errors}
                inputKey={'dropoffAddress'}
                onBlur={() => clearErrors('dropoffAddress')}
              />
            )}
          </AnimateMotionDiv>
        )}
        {segmentTab === 'roundTrip' && (
          <>
            <AnimateMotionDiv>
              <p>
                Approximately how long will your driver need to wait between
                stops while you are busy?
              </p>

              <FormInput
                placeholder="Enter time"
                label="Enter time"
                register={register('ride.waitTime')}
              />
              {userAddresses?.getAddresses.length === 1 && (
                <Wrapper
                  hasError={
                    !zipCodeCheck?.getZipCodeMatchesBusinessRules.success
                  }
                >
                  <FormInput
                    icon="home"
                    placeholder="Home"
                    label="Pick-up Location"
                    value="home"
                    register={register('ride.pickupAddress.addressLine1')}
                  />
                  {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                    <AddNewAddressErrorZipCode>
                      {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                    </AddNewAddressErrorZipCode>
                  )}
                </Wrapper>
              )}

              {userAddresses && userAddresses.getAddresses.length > 1 && (
                <AnimateMotionDiv>
                  <Wrapper
                    hasError={
                      !zipCodeCheck?.getZipCodeMatchesBusinessRules.success
                    }
                  >
                    <h2>Confirm your location</h2>
                    <div style={{ position: 'relative', top: 15, left: 16 }}>
                      <HomeIcon style={{ position: 'absolute' }} />
                    </div>
                    <IonSelect
                      labelPlacement="floating"
                      interface="popover"
                      shape="round"
                      placeholder={
                        getValues('home')
                          ? getValues('home')
                          : (userDefaultAddress?.addressLine1 ?? '') +
                            ', ' +
                            (userDefaultAddress?.city ?? '')
                      }
                      value={
                        getValues('home')
                          ? getValues('home')
                          : (userDefaultAddress?.addressLine1 ?? '') +
                            ', ' +
                            (userDefaultAddress?.city ?? '')
                      }
                      onIonBlur={() => clearErrors('home')}
                      className="address-select"
                      onIonChange={({ detail }) => {
                        handleHome(detail.value as string);
                      }}
                    >
                      {userAddresses?.getAddresses.map((address) => (
                        <IonSelectOption
                          key={address.id}
                          value={
                            address.zipCode +
                            ',' +
                            address.addressLine1 +
                            ',' +
                            address.id.toString()
                          }
                        >
                          {address.addressLine1},{address.city}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                    {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                      <AddNewAddressErrorZipCode>
                        {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                      </AddNewAddressErrorZipCode>
                    )}
                    {isFeatureEnabled(
                      FeatureNames.AddAddressForRequestEntryPoint
                    ) ? (
                      <AddNewAddressText>
                        {i18n.t('serviceRequest.lookingFor')}
                        <AddNewAddressTextOrange
                          onClick={() => onAddressModal(true)}
                        >
                          {i18n.t('serviceRequest.addANew')}
                        </AddNewAddressTextOrange>
                      </AddNewAddressText>
                    ) : null}
                  </Wrapper>
                </AnimateMotionDiv>
              )}

              {stopLocations.length &&
                stopLocations.map(
                  (element, index) =>
                    index > 0 && (
                      <StopContainer key={index}>
                        {!loadingApiKey && (
                          <AddressAutoComplete
                            apiKey={String(googlePlaceApiKey)}
                            setValue={setValue}
                            placeholder="Please enter address"
                            label={`${element?.label} ${index}`}
                            inputKey={'stops'}
                          />
                        )}
                        {index ? (
                          <IonIcon
                            icon={trashOutline}
                            onClick={() => removeFormFields(index)}
                          />
                        ) : null}
                      </StopContainer>
                    )
                )}
              {!loadingApiKey && (
                <AddressAutoComplete
                  apiKey={String(googlePlaceApiKey)}
                  setValue={setValue}
                  placeholder="Please enter address"
                  label="Drop-off Location"
                  errors={errors}
                  inputKey={'dropoffAddress'}
                  onBlur={() => clearErrors('dropoffAddress')}
                />
              )}
            </AnimateMotionDiv>
            {stopLocations.length <= 1 && (
              <AnimateMotionDiv>
                <AddStopButtonContainer>
                  <AddStopButton fill="clear" onClick={addFormFields}>
                    <IonIcon icon={addCircleOutline} />
                    Add stop
                  </AddStopButton>
                </AddStopButtonContainer>
              </AnimateMotionDiv>
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default RideForm;
