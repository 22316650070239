import React from 'react';
import {
  Container,
  Content,
  EmptyReviewsContainer,
  ReviewCard,
  ReviewsContainer,
  SelectContainer,
  Wrapper,
} from './ProReviews.styles';
import { GraphqlServiceRequestReview } from '../../graphql/generated';
import { StarIcon } from '../../assets/images/StarIcon';
import dayjs from 'dayjs';
import { IonSelect, IonSelectOption, IonSkeletonText } from '@ionic/react';
import ProReviewsSkeleton from './ProReview.skeleton';
import { EmptyReviews } from '../../assets/images/EmptyReviews';

interface IProReviews {
  data?: GraphqlServiceRequestReview[];
  AgentName: string;
  loading: boolean;
  serviceTypeList: { id: string; name: string }[];
  filterType?: (category: string) => Promise<void>;
}

const ProReviews: React.FC<IProReviews> = ({
  data,
  loading,
  serviceTypeList,
  filterType,
  AgentName,
}) => {
  return (
    <Container>
      {!loading && (
        <Wrapper
          justifyContent="space-between"
          style={{ marginBottom: '16px' }}
        >
          <h1>Reviews ({data?.length})</h1>
          {serviceTypeList?.length > 1 && (
            <SelectContainer>
              <IonSelect
                interface="popover"
                placeholder="All"
                onIonChange={({ detail }) =>
                  filterType && void filterType(detail.value as string)
                }
              >
                <IonSelectOption className="activity-options" value={'all'}>
                  All
                </IonSelectOption>
                {serviceTypeList?.map((serviceType, i) => (
                  <IonSelectOption
                    key={i}
                    className="activity-options"
                    value={serviceType.id}
                  >
                    {serviceType.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </SelectContainer>
          )}
        </Wrapper>
      )}
      {loading && (
        <h1 style={{ marginBottom: '16px' }}>
          <IonSkeletonText animated style={{ width: '120px' }} />
        </h1>
      )}
      <Content>
        <ReviewsContainer>
          {loading &&
            [...(Array(3) as [])].map(() => (
              <ProReviewsSkeleton key={Math.random()} />
            ))}
          {!loading &&
            data?.map((review, i) => (
              <ReviewCard key={i}>
                <h2>{review?.serviceRequestCategory}</h2>
                <Wrapper>
                  <div>
                    {[...(Array(review?.rating) as [])].map(() => (
                      <StarIcon key={Math.random()} />
                    ))}
                  </div>
                  <span>{review?.rating}</span>
                </Wrapper>
                <p>{review?.comment}</p>
                <Wrapper justifyContent="space-between">
                  <small>{review?.ReviewedBy.firstName}</small>
                  <small>
                    {dayjs(review?.ReviewedBy?.createdAt).format('DD of MMMM')}
                  </small>
                </Wrapper>
              </ReviewCard>
            ))}
          {!loading && data?.length === 0 && (
            <EmptyReviewsContainer>
              <EmptyReviews />
              <h2>No reviews</h2>
              <p>{AgentName} hasn&apos;t received any reviews yet.</p>
            </EmptyReviewsContainer>
          )}
        </ReviewsContainer>
      </Content>
    </Container>
  );
};

export default ProReviews;
