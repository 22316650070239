/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {
  Container,
  InputContainer,
  StyledInput,
  StyledLabel,
} from './AddressAutoComplete.styles';
import { LocationOutline } from '../../assets/images/LocationOutline';

interface IAddressAutoComplete {
  label?: string;
  placeholder: string;
  errors?: any;
  inputKey: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  setValue: (key: string, value: string) => void;
  apiKey: string;
}

const AddressAutoComplete: React.FC<IAddressAutoComplete> = ({
  label,
  setValue,
  placeholder,
  onBlur,
  errors,
  inputKey,
  apiKey,
}) => {
  const [inputValue, setInputValue] = useState('');

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
  });
  const [showPredictions, setShowPredictions] = useState(false);
  const handleZipCode = async (address: string, placeId: string) => {
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place?.address_components?.find((c: { types: string | string[] }) =>
        c.types.includes('postal_code')
      ) || {};
    setValue(`ride.${inputKey}.zipCode`, postalCode);
  };
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions?.length) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0]?.place_id,
        },
        (placeDetails) => {
          const city = placeDetails?.address_components?.find((item) =>
            item.types.includes('administrative_area_level_1')
          );
          const state = placeDetails?.address_components?.find((item) =>
            item.types.includes('locality')
          );
          const country = placeDetails?.address_components?.find((item) =>
            item.types.includes('country')
          );
          setValue(`ride.${inputKey}.city`, city?.short_name as string);
          setValue(`ride.${inputKey}.state`, state?.short_name as string);
          setValue(
            `ride.${inputKey}.addressLine1`,
            placeDetails?.name as string
          );
          setValue(
            `ride.${inputKey}.addressLine2`,
            placeDetails?.formatted_address as string
          );
          setValue(`ride.${inputKey}.country`, country?.short_name as string);
          void handleZipCode(
            placeDetails?.formatted_address as string,
            placeDetails?.place_id as string
          );
        }
      );
    }
  }, [placePredictions]);
  const handlePlacePredictionsClick = (place: string) => {
    setInputValue(place);
    getPlacePredictions({ input: place });
    setShowPredictions(false);
  };

  return (
    <Container>
      {label && <StyledLabel>{label}</StyledLabel>}
      <InputContainer>
        <LocationOutline style={{ marginRight: '8px' }} />
        <StyledInput
          style={
            inputKey &&
            errors &&
            errors[inputKey] && {
              borderColor: '#ea4255',
            }
          }
          value={inputValue}
          placeholder={placeholder}
          onBlur={onBlur && onBlur}
          onChange={(evt) => {
            getPlacePredictions({ input: evt.target.value });
            setInputValue(evt.target.value);
            setShowPredictions(true);
          }}
        />
        {isPlacePredictionsLoading && <IonSpinner color="primary" />}
      </InputContainer>
      {errors && inputKey && (
        <p className="form-error">{errors[inputKey]?.message as string}</p>
      )}
      {!isPlacePredictionsLoading && showPredictions && (
        <IonList inset={false}>
          {placePredictions.map((item) => (
            <IonItem
              key={item?.place_id}
              onClick={() => handlePlacePredictionsClick(item?.description)}
            >
              <IonLabel>{item?.description}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}
    </Container>
  );
};

export default AddressAutoComplete;
