import React, { ReactNode, useRef, useState } from 'react';
import {
  CellContainer,
  IconContainer,
  TextContainer,
  HeaderContainer,
  TimeStampContainer,
  CellContent,
  NewNotification,
  ActionsContainer,
} from './NotificationCell.styles';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import CircleRequestIcon from '../../assets/images/ServiceCategory/Circle_icon.svg';
import ServiceRequestIcon from '../../assets/images/ServiceCategory/Service_request.svg';
import SocialRequestIcon from '../../assets/images/ServiceCategory/social-request.svg';
import AlertCircleRed from '../../assets/images/AlertCircleRed.svg';
import PeopleCircleOutlineBlue from '../../assets/images/PeopleCircleOutlineBlue.svg';
import { formatCalendarNotificationDate } from '../../utils/date';
import { alertCircleOutline, chatboxOutline } from 'ionicons/icons';
import {
  GraphqlNotification,
  UserInvitesStatus,
  useAnswerRequestMutation,
  useJoinCircleMutation,
} from '../../graphql/generated';
import ToastHandler from '../ToastHandler/ToastHandler';
import { useAppContext } from '../../providers/appContextProvider';
import { GreenCheckMark } from '../../assets/images/GreenCheckMark';
import { SecondaryTitle } from '../Typography/Headings/Headings';
import { Trans } from 'react-i18next';
// import { useDeeplinkHandler } from '../../utils/hooks/useDeeplinkHandler';

const ActionsServices: string[] = [
  'familyPartnerRequest',
  'circleMemberRequest',
  'circleMemberInvite',
  'familyPartnerInvite',
];

const joinCircleRequested = ['familyPartnerRequest', 'circleMemberRequest'];
const joinCircleInvitation = ['familyPartnerInvite', 'circleMemberInvite'];

interface NotificationCellProps {
  data?: GraphqlNotification;
  deleteNotification: (id: number, displayToast: boolean) => void;
  readNotification: (id: number) => void;
  unreadNotification: (id: number) => void;
}

// TODO: export from backend NotificationTypes or NotificationCategories for this?
const type: {
  [key: string]: string;
} = {
  general: alertCircleOutline,
  circleMember: CircleRequestIcon,
  circleMemberInvite: PeopleCircleOutlineBlue,
  circleMemberInviteAccepted: CircleRequestIcon,
  circleMemberRequest: PeopleCircleOutlineBlue,
  familyPartnerInvite: PeopleCircleOutlineBlue,
  familyPartnerInviteAccepted: PeopleCircleOutlineBlue,
  familyPartnerRequest: PeopleCircleOutlineBlue,
  serviceRequest: ServiceRequestIcon,
  serviceRequestFinished: ServiceRequestIcon,
  serviceRequestCancelled: ServiceRequestIcon,
  serviceRequestCancelledPaymentFailed: ServiceRequestIcon,
  serviceRequestConfirmationNeeded: ServiceRequestIcon,
  serviceRequestAuthorizationFailed: AlertCircleRed,
  serviceRequestPaymentFailed: AlertCircleRed,
  serviceRequestExtraCharge: ServiceRequestIcon,
  serviceRequestPostVisitSummaryCreated: ServiceRequestIcon,
  serviceRequestNewPro: ServiceRequestIcon,
  serviceRequestConfirmedBySM: ServiceRequestIcon,
  serviceRequestNewTimeRequested: ServiceRequestIcon,
  serviceRequestConfirmationNeededReminder: ServiceRequestIcon,
  serviceRequestCancelledByPro: ServiceRequestIcon,
  coachVisit: SocialRequestIcon,
  coachVisitScheduled: SocialRequestIcon,
  coachVisitUpdated: SocialRequestIcon,
  coachVisitCancelled: SocialRequestIcon,
  coachVisitNoteCreated: SocialRequestIcon,
  socialEvent: SocialRequestIcon,
  socialEventScheduled: SocialRequestIcon,
  socialEventUpdated: SocialRequestIcon,
  socialEventCancelled: SocialRequestIcon,
  circleRequest: CircleRequestIcon,
  circleRequestAllMembersDeclined: CircleRequestIcon,
  circleRequestMemberUnavailable: CircleRequestIcon,
  circleRequestAccepted: CircleRequestIcon,
  circleRequestCancelled: CircleRequestIcon,
  circleRequestTimeChanged: CircleRequestIcon,
  circleRequestNew: CircleRequestIcon,
  circleRequestConfirmationNeededReminder: CircleRequestIcon,
  stripeConnectAccountRequirements: ServiceRequestIcon,
  talkjsNewMessage: chatboxOutline,
  postVisitSummaryProReminder: ServiceRequestIcon,
  appointmentServicesPrior30MinReminder: ServiceRequestIcon,
  appointmentCirclesPrior30MinReminder: CircleRequestIcon,
};

const JoinModalTitle = ({ title }: { title: ReactNode }) => (
  <IonGrid>
    <IonRow className="ion-justify-content-center">
      <IonCol size="auto">
        <GreenCheckMark />
      </IonCol>
    </IonRow>
    <IonRow className="ion-padding-top ion-justify-content-center">
      <IonCol size="auto">
        <SecondaryTitle>{title}</SecondaryTitle>
      </IonCol>
    </IonRow>
  </IonGrid>
);

const NotificationCell: React.FC<NotificationCellProps> = ({
  data,
  deleteNotification,
  readNotification,
  unreadNotification,
}) => {
  const { globalAlert } = useAppContext();
  // const handleDeeplink = useDeeplinkHandler();
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');

  const ref = useRef<HTMLIonItemSlidingElement | null>(null);
  const [joinCircleMutation, { loading }] = useJoinCircleMutation();
  const [requestedToJoinCircleMutation, { loading: loadingRequested }] =
    useAnswerRequestMutation();

  const showJoinModal = (
    inviteType: string,
    customerFirstName: string,
    customerLastName: string
  ) => {
    const customerFullName = `${customerFirstName} ${customerLastName}`;
    globalAlert({
      title: (
        <JoinModalTitle
          title={
            <Trans
              i18nKey={`notificationsCenter.joinModal.${inviteType}.title`}
              values={{ customerFullName }}
              components={{ i: <i /> }}
            />
          }
        />
      ),
      subtitle: (
        <Trans
          i18nKey={`notificationsCenter.joinModal.${inviteType}.subtitle`}
          values={{ customerFirstName }}
          components={{ i: <i /> }}
        />
      ),
      firstButtonLabel: (
        <Trans i18nKey={`notificationsCenter.joinModal.${inviteType}.cta`} />
      ),
      onDidDismiss() {
        deleteNotification(data?.id as number, false);
      },
    });
  };

  const readAndUnreadHandler = () => {
    if (data?.readAt) {
      unreadNotification(data?.id);
    } else {
      readNotification(data?.id as number);
    }
    void ref?.current?.close();
  };
  const deleteNotificationHandler = () => {
    deleteNotification(data?.id as number, true);
    void ref?.current?.close();
  };
  const delayedNotificationDelete = () => {
    setTimeout(() => {
      deleteNotification(data?.id as number, false);
    }, 2500);
  };
  const handleJoinCircle = async () => {
    try {
      if (joinCircleInvitation.includes(data?.type as string)) {
        // TODO: we are calling this mutation for familyPartnerInvite???
        await joinCircleMutation({
          variables: {
            joinCircleId: data?.payload?.userInviteId as number,
            status: UserInvitesStatus.Accepted,
          },
          onCompleted: ({ joinCircle }) => {
            const { firstName, lastName } = joinCircle.Customer;
            showJoinModal(
              data?.type as string,
              firstName as string,
              lastName as string
            );
          },
        });
      } else if (joinCircleRequested.includes(data?.type as string)) {
        await requestedToJoinCircleMutation({
          variables: {
            userRequestId: data?.payload?.userRequestId as number,
            status: UserInvitesStatus.Accepted,
          },
          onCompleted: () => {
            setToastText('You have accepted the join request');
            setShowToast(true);
            delayedNotificationDelete();
          },
        });
      }
    } catch (error) {
      alert('Error while joining circle');
    }
  };

  const handleDeclinedCircle = async () => {
    try {
      if (joinCircleInvitation.includes(data?.type as string)) {
        await joinCircleMutation({
          variables: {
            joinCircleId: data?.payload?.userInviteId as number,
            status: UserInvitesStatus.Declined,
          },
          onCompleted: () => {
            setToastText('You have declined the invitation');
            setShowToast(true);
            delayedNotificationDelete();
          },
        });
      } else if (joinCircleRequested.includes(data?.type as string)) {
        await requestedToJoinCircleMutation({
          variables: {
            userRequestId: data?.payload?.userRequestId as number,
            status: UserInvitesStatus.Declined,
          },
          onCompleted: () => {
            delayedNotificationDelete();
          },
        });
        if (!loadingRequested) {
          setToastText('You have declined the join request');
          setShowToast(true);
        }
      }
    } catch (error) {
      alert('Error while joining circle');
    }
  };
  // TODO: add again when deeplink is ready
  // const handleNotificationClick = () => {
  //   if (data?.deeplink) {
  //     handleDeeplink(data?.deeplink);
  //   }
  // };

  return (
    <>
      <CellContainer>
        <IonItemSliding
          style={{ position: 'relative' }}
          ref={(element) => {
            ref.current = element;
          }}
        >
          <IonItemOptions side="start" onIonSwipe={readAndUnreadHandler}>
            <IonItemOption
              onClick={readAndUnreadHandler}
              style={{
                background: '#F5F5F5',
                color: '#1A1F36',
              }}
              expandable
            >
              {data?.readAt ? 'Unread' : 'Read'}
            </IonItemOption>
          </IonItemOptions>

          <IonItem>
            <CellContent>
              {!data?.readAt && <NewNotification />}
              <IconContainer>
                <IonIcon
                  src={type[data?.type as string] ?? alertCircleOutline}
                  style={{
                    width: '1.5625rem',
                    height: '1.75rem',
                  }}
                />
              </IconContainer>
              <HeaderContainer>
                <TextContainer>
                  <h2>{data?.title}</h2>
                  <p>{data?.text}</p>
                </TextContainer>
                {ActionsServices.includes(data?.type as string) && (
                  <ActionsContainer>
                    <IonButton onClick={handleJoinCircle} shape="round">
                      {joinCircleInvitation.includes(data?.type as string) &&
                        'Join'}
                      {joinCircleRequested.includes(data?.type as string) &&
                        'Allow'}
                    </IonButton>
                    <IonButton
                      onClick={handleDeclinedCircle}
                      fill="outline"
                      shape="round"
                    >
                      {joinCircleInvitation.includes(data?.type as string) &&
                        'No, thanks'}
                      {joinCircleRequested.includes(data?.type as string) &&
                        'Not, right now'}
                    </IonButton>
                    {(loading || loadingRequested) && (
                      <IonSpinner
                        color="primary"
                        name="lines-small"
                        style={{
                          marginLeft: '0.5rem',
                        }}
                      />
                    )}
                  </ActionsContainer>
                )}
                <TimeStampContainer>
                  <span>
                    {formatCalendarNotificationDate(data?.createdAt as Date)}
                  </span>
                </TimeStampContainer>
              </HeaderContainer>
            </CellContent>
          </IonItem>

          {!ActionsServices.includes(data?.type as string) && (
            <IonItemOptions
              id="my-sliding"
              side="end"
              onIonSwipe={deleteNotificationHandler}
            >
              <IonItemOption
                onClick={deleteNotificationHandler}
                style={{
                  background: '#EA4255',
                  color: '#fff',
                }}
              >
                Delete
              </IonItemOption>
            </IonItemOptions>
          )}
        </IonItemSliding>
      </CellContainer>
      <ToastHandler
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastText}
        position={'top'}
      />
    </>
  );
};

export default NotificationCell;
