import {
  IonCard,
  IonCardContent,
  IonContent,
  IonModal,
  IonRow,
  IonCol,
  IonGrid,
} from '@ionic/react';
import { FC } from 'react';
import { RateProfessionalSkeleton } from '../RateProfessionalModalSheet/RateProfessionalSkeleton';

import { useGetVisitSummariesByCoachIdQuery } from '../../graphql/generated';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { SecondaryTitle } from '../Typography/Headings/Headings';
import dayjs from 'dayjs';
import {
  StyledCardSubtitle,
  StyledCardTitle,
  StyledCircleAvatar,
  StyledContainer,
} from './BryaPartnerVisitNotesModal.style';
import { BodyText } from '../Typography/Body/Body';

type BryaPartnerVisitNotesModalProps = {
  coachVisitId?: number;
  onClose?: () => void;
  isOpen?: boolean;
};

const getInitials = (firstName: string, lastName: string) => {
  const firstInitial = firstName && firstName.length > 0 ? firstName[0] : '';
  const lastInitial = lastName && lastName.length > 0 ? lastName[0] : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

export const BryaPartnerVisitNotesModal: FC<
  BryaPartnerVisitNotesModalProps
> = ({ coachVisitId, onClose = () => {}, isOpen }) => {
  const { t } = useTranslation();

  const { data, loading } = useGetVisitSummariesByCoachIdQuery({
    variables: { coachVisitId: Number(coachVisitId) },
  });

  const visitNotes = data?.getVisitSummariesByCoachId?.results;

  return (
    <IonModal
      onDidDismiss={onClose}
      isOpen={isOpen}
      initialBreakpoint={0.55}
      breakpoints={[0, 0.55, 1]}
    >
      {loading ? (
        <RateProfessionalSkeleton />
      ) : (
        <IonContent className="ion-padding">
          <SecondaryTitle
            className="ion-text-center"
            style={{ marginTop: '1rem', marginBottom: '2.5rem' }}
          >
            {t('BryaPartnerVisitNotesModal.PageTitle')} (
            {visitNotes?.length ?? 0})
          </SecondaryTitle>

          {visitNotes?.map((note, i) => (
            <IonCard key={i}>
              <StyledContainer>
                <IonGrid>
                  <IonRow dir="column">
                    <IonCol sizeXs="2">
                      <StyledCircleAvatar>
                        {getInitials(
                          String(note?.CoachVisit?.Coach.firstName),
                          String(note?.CoachVisit?.Coach.lastName)
                        )}
                      </StyledCircleAvatar>
                    </IonCol>
                    <IonCol sizeXs="10">
                      <StyledCardTitle>
                        {String(note?.CoachVisit?.Coach.fullName)}
                      </StyledCardTitle>
                      <StyledCardSubtitle>
                        {note?.createdAt
                          ? dayjs(note.createdAt).format('ddd, MMMM D - h.mmA')
                          : ''}
                      </StyledCardSubtitle>
                      <StyledCardSubtitle color="#212121">
                        {t('BryaPartnerVisitNotesModal.Card.ToYou')}
                      </StyledCardSubtitle>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </StyledContainer>
              <IonCardContent>
                <BodyText color="black">{note.body}</BodyText>
              </IonCardContent>
            </IonCard>
          ))}

          <Button
            color="primary-orange"
            className="ion-margin-top"
            onClick={onClose}
          >
            {t('BryaPartnerVisitNotesModal.ConfirmButton.Ok')}
          </Button>
        </IonContent>
      )}
    </IonModal>
  );
};
