import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

export type DivProps = {
  expanded?: boolean;
};

export const PaymentContainer = styled.div`
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding: 16px;
`;

export const PaymentInfoTitle = styled.h2`
  color: var(--colors-primary-blue);
  font-size: ${pixelToRem(16)};
  font-weight: 600;
  line-height: 140%;
`;

export const PaymentInfoText = styled.p`
  color: var(--body-text-900);
  font-size: ${pixelToRem(14)};
  font-weight: 400;
  line-height: 140%;
  margin: 0.5rem 0;
`;

export const PaymentInfoDescription = styled.p`
  color: var(--body-text-700);
  font-size: ${pixelToRem(14)};
  font-weight: 400;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const RadioInfo = styled.span`
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  color: var(--body-text-700);
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const CardInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
