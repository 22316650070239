import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { Trans } from 'react-i18next';
import { Variants, motion } from 'framer-motion';
import {
  AppUser,
  useCreateCustomerWithAddressMutation,
  useRequestToJoinFamilyGroupMutation,
} from '../../../graphql/generated';
import { useAppContext } from '../../../providers/appContextProvider';
import { logger } from '../../../logger';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import {
  AboutLovedOneForm,
  AboutLovedOneFormFields,
} from '../../../components/Forms/AboutLovedOneForm';
import { ROUTES } from '../../../constants/routes';
import { useHistory } from 'react-router';
import { useFormId } from '../../../utils/hooks/useFormId';

const animation: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const MembershipAboutLovedOnePage: React.FC = () => {
  const [createCustomerWithAddress] = useCreateCustomerWithAddressMutation();
  const [requestToJoinFamilyGroup] = useRequestToJoinFamilyGroupMutation();
  const { globalAlert } = useAppContext();
  const router = useHistory();
  const { formId, regenerateFormId } = useFormId();

  const handleExistingCustomer = (data: AboutLovedOneFormFields) => {
    regenerateFormId();
    router.push(ROUTES.MEMBERSHIP.MATCH_LOVED_ONE, data);
  };

  const handleNotExistingCustomer = async (data: AboutLovedOneFormFields) => {
    const {
      firstName,
      lastName,
      courtesyTitle,
      email,
      phoneNumber,
      ...address
    } = data;
    const createCustomerResponse = await createCustomerWithAddress({
      variables: {
        role: AppUser.FamilyPartner,
        customer: {
          firstName,
          lastName,
          courtesyTitle,
          email,
          phoneNumber,
        },
        address: {
          ...address,
          country: 'US',
        },
      },
    });

    const customerId =
      createCustomerResponse.data?.createCustomerWithAddress.id;
    if (!customerId) {
      throw new Error('missing customerId in create customer response');
    }

    router.push(ROUTES.MEMBERSHIP.NOT_MATCH_LOVED_ONE, {
      firstName,
      lastName,
      email,
      phoneNumber,
    });
  };

  const handleSubmit = async (data: AboutLovedOneFormFields) => {
    try {
      const joinFamilyGroupResponse = await requestToJoinFamilyGroup({
        variables: {
          requestToJoinFamilyGroupInput: {
            email: data.email,
            phoneNumber: data.phoneNumber,
          },
        },
      });

      if (joinFamilyGroupResponse.data?.requestToJoinFamilyGroup.match) {
        handleExistingCustomer(data);
      } else {
        await handleNotExistingCustomer(data);
      }
    } catch (err) {
      logger.error({
        tag: '[ABOUT_LOVED_ONE_FORM_SUBMIT]',
        message: `Fail trying submitting about loved one form: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <motion.div initial="initial" animate="animate" variants={animation}>
          <IonGrid>
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <SecondaryTitle>
                  <Trans i18nKey="membership.aboutLovedOne.title" />
                </SecondaryTitle>
              </IonCol>
            </IonRow>
            <AboutLovedOneForm key={formId} onSubmit={handleSubmit} />
          </IonGrid>
        </motion.div>
      </IonContent>
    </IonPage>
  );
};

export default MembershipAboutLovedOnePage;
