import { IonIcon, IonRow } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { CostBreakDownDiv } from './CostBreakDown.styles';
import { useState } from 'react';
import { CustomAlertV2 } from '../CustomAlert/CustomAlert';
import {
  ModalTitleSecondary,
  Subtitle,
} from '../CustomAlert/CustomAlert.style';

export const CostBreakDown = ({
  isCustomer = true,
}: {
  isCustomer?: boolean;
}) => {
  const [infoModal, setInfoModal] = useState(false);
  const i18n = useTranslation();

  const customerTitles = [
    i18n.t('postVisitModal.costBreakdownModalCustomerTitle1'),
    i18n.t('postVisitModal.costBreakdownModalCustomerTitle2'),
    i18n.t('postVisitModal.costBreakdownModalCustomerTitle3'),
    i18n.t('postVisitModal.costBreakdownModalCustomerTitle4'),
  ];

  const customerSubTitles = [
    i18n.t('postVisitModal.costBreakdownModalCustomerSubTitle1'),
    i18n.t('postVisitModal.costBreakdownModalCustomerSubTitle2'),
    i18n.t('postVisitModal.costBreakdownModalCustomerSubTitle3'),
    i18n.t('postVisitModal.costBreakdownModalCustomerSubTitle4'),
  ];

  const proTitles = [
    i18n.t('postVisitModal.costBreakdownModalProTitle1'),
    i18n.t('postVisitModal.costBreakdownModalProTitle2'),
    i18n.t('postVisitModal.costBreakdownModalProTitle3'),
  ];

  const proSubTitles = [
    i18n.t('postVisitModal.costBreakdownModalProSubTitle1'),
    i18n.t('postVisitModal.costBreakdownModalProSubTitle2'),
    i18n.t('postVisitModal.costBreakdownModalProSubTitle3'),
  ];

  return (
    <>
      <CustomAlertV2
        isOpen={infoModal}
        title={i18n.t('postVisitModal.costBreakdown')}
        firstButtonLabel={i18n.t('postVisitModal.ok')}
        firstButtonAction={() => {
          setInfoModal(false);
        }}
        onDidDismiss={() => setInfoModal(false)}
      >
        {isCustomer
          ? customerTitles.map((title, index) => {
              return (
                <IonRow
                  className="ion-padding-vertical"
                  key={`cost-break-down-${index}`}
                >
                  <ModalTitleSecondary>{title}</ModalTitleSecondary>
                  <Subtitle>{customerSubTitles[index]}</Subtitle>
                </IonRow>
              );
            })
          : proTitles.map((title, index) => {
              return (
                <IonRow
                  className="ion-padding-vertical"
                  key={`cost-break-down-${index}`}
                >
                  <ModalTitleSecondary>{title}</ModalTitleSecondary>
                  <Subtitle>{proSubTitles[index]}</Subtitle>
                </IonRow>
              );
            })}
      </CustomAlertV2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        onClick={() => setInfoModal(true)}
      >
        <CostBreakDownDiv>
          {i18n.t('postVisitModal.costBreakdown')}
        </CostBreakDownDiv>
        <IonIcon icon={informationCircleOutline} />
      </div>
    </>
  );
};
