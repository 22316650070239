import React from 'react';
import { CardContainer, TabContent } from '../ActivitiesTab.style';
import ActivityCard from '../../ActivityCard/ActivityCard';
import ActivityEmptyStates from '../../ActivityEmptyStates/ActivityEmptyStates';
import { IActivityCard } from '../../../types';

const OpenTab: React.FC<{ data: IActivityCard[] }> = ({ data }) => {
  return (
    <>
      {data?.map((activity: IActivityCard, index) => (
        <TabContent key={index}>
          <CardContainer>
            <ActivityCard data={activity} />
          </CardContainer>
        </TabContent>
      ))}
      {data?.length === 0 && <ActivityEmptyStates tab="open" />}
    </>
  );
};

export default OpenTab;
