import {
  IonCard,
  IonCardHeader,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import {
  CardContent,
  CardText,
  Container,
  RadioInfo,
  RadioItem,
  Card,
} from './SelectPaymentCard.style';
import { addCircleOutline, cardOutline } from 'ionicons/icons';
import { SkeletonPage } from './skeleton-page';
import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  useGetStripeCustomerCreditCardSecretQuery,
  useGetStripeCustomerPaymentMethodsQuery,
} from '../../graphql/generated';
import CardAmex from '../../assets/images/CardAmex';
import CardMastercard from '../../assets/images/CardMastercard';
import CardVisa from '../../assets/images/CardVisa';
import { Elements } from '@stripe/react-stripe-js';
import ModalAddPayment from '../ModalAddPayment/ModalAddPayment';
import { PaymentCardType } from '../../constants/enums';
import { Button } from '../Button/Button';
import {
  formatExpiration,
  formatPartialCardNumber,
} from '../../utils/formatters/payment';
import { useStripe } from '../../providers/stripeProvider';
import { PaymentIssueAlert } from '../../assets/images/PaymentIssueAlert';

interface SelectPaymentCardProps {
  buttonLabel: string;
  onCardSelect: (card: string) => void;
  cardDisabled?: boolean;
  errorPaymentId?: string;
}
export function SelectPaymentCard({
  buttonLabel,
  onCardSelect,
  cardDisabled,
  errorPaymentId,
}: SelectPaymentCardProps) {
  const [cardSelected, setCardSelected] = useState<boolean>(false);
  const [addCardModalOpen, setAddCardModal] = useState<boolean>(false);
  const [card, setCard] = useState<string>('');
  const stripePromise = useStripe();

  const {
    data: paymentMethods,
    loading: paymentMethodsLoading,
    refetch: refetchPaymentMethods,
  } = useGetStripeCustomerPaymentMethodsQuery();

  const { data: clientSecret } = useGetStripeCustomerCreditCardSecretQuery({
    skip: paymentMethodsLoading,
  });

  useEffect(() => {
    if (paymentMethods) {
      const defaultCard =
        paymentMethods?.getStripeCustomerPaymentMethods.find(
          (cardItem) => cardItem.default
        )?.id || '';
      setCard(defaultCard);

      if (defaultCard !== '') {
        setCardSelected(true);
      }
    }
  }, [paymentMethods]);

  const stripeOptions = {
    clientSecret: clientSecret?.getStripeCustomerCreditCardSecret,
  };

  const openPaymentModal = useCallback(() => {
    setAddCardModal(true);
  }, []);

  const handleRadioChange = useCallback(
    (event: { detail: { value: string } }) => {
      setCard(event.detail.value);
      setCardSelected(true);
    },
    [cardSelected]
  );

  const getCardIconType = useCallback((type: string) => {
    switch (type) {
      case PaymentCardType.Visa:
        return <CardVisa width={32} height={32} />;
      case PaymentCardType.MasterCard:
        return <CardMastercard width={32} height={32} />;
      case PaymentCardType.Amex:
        return <CardAmex width={32} height={32} />;
      default:
        return (
          <IonIcon
            icon={cardOutline}
            style={{
              color: 'var(--colors-primary-blue)',
              width: 24,
              height: 24,
            }}
          />
        );
    }
  }, []);

  const onConfirm = useCallback(() => {
    onCardSelect(card);
  }, [card]);

  const handleNewCardAdded = async () => {
    setAddCardModal(false);
    await refetchPaymentMethods();
  };

  const openAddCardModal = useCallback(() => {
    setAddCardModal(true);
  }, [clientSecret?.getStripeCustomerCreditCardSecret]);

  return (
    <Container>
      {!paymentMethodsLoading &&
        paymentMethods?.getStripeCustomerPaymentMethods.length === 0 && (
          <IonCard
            style={{
              margin: '0px',
              marginTop: '32px',
              height: '58px',
            }}
            onClick={openAddCardModal}
          >
            <IonCardHeader>
              <CardContent>
                <CardContent>
                  <IonIcon
                    icon={addCircleOutline}
                    color="#23364b"
                    style={{ width: 22, height: 22 }}
                  ></IonIcon>
                  <CardText>{t('payment.addnewCredit')}</CardText>
                </CardContent>
              </CardContent>
            </IonCardHeader>
          </IonCard>
        )}
      {paymentMethodsLoading && <SkeletonPage />}

      {!paymentMethodsLoading &&
        paymentMethods?.getStripeCustomerPaymentMethods.length !== 0 && (
          <Card>
            <IonRadioGroup onIonChange={handleRadioChange} value={card}>
              {paymentMethods?.getStripeCustomerPaymentMethods.map((item) => (
                <RadioItem key={item.id}>
                  <IonRadio labelPlacement="end" value={item.id}>
                    <IonRow>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: 8,
                        }}
                      >
                        {getCardIconType(item.card.brand)}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {formatPartialCardNumber(item.card)}
                        <RadioInfo>
                          {t('payment.expiration')}
                          {formatExpiration(item.card)}
                        </RadioInfo>
                      </div>
                      {errorPaymentId === item.id && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 24,
                          }}
                        >
                          <PaymentIssueAlert className="card-status payment-issue" />
                        </div>
                      )}
                    </IonRow>
                  </IonRadio>
                </RadioItem>
              ))}
            </IonRadioGroup>
            <IonCard
              onClick={() => openPaymentModal()}
              style={{
                marginLeft: -20,
                marginTop: 4,
                height: 42,
                boxShadow: 'none',
                width: '100%',
              }}
            >
              <IonCardHeader>
                <CardContent>
                  <CardContent>
                    <IonIcon
                      icon={addCircleOutline}
                      color="#23364b"
                      style={{ width: 24, height: 24 }}
                    ></IonIcon>
                    <CardText>{t('payment.useADifferentCard')}</CardText>
                  </CardContent>
                </CardContent>
              </IonCardHeader>
            </IonCard>
          </Card>
        )}
      <IonToolbar color={'#fff'}>
        <Button
          slot={'primary'}
          onClick={() => void onConfirm()}
          disabled={
            typeof cardDisabled === 'boolean' ? cardDisabled : !cardSelected
          }
          color="primary-orange"
        >
          <>{buttonLabel}</>
        </Button>
      </IonToolbar>
      {clientSecret?.getStripeCustomerCreditCardSecret && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <ModalAddPayment
            isOpenModal={addCardModalOpen}
            onOpenModal={setAddCardModal}
            onNewCardAdded={handleNewCardAdded}
          />
        </Elements>
      )}
    </Container>
  );
}
