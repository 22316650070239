import React from 'react';
import { StyledIonToast } from './ToastHandler.styles';
import { checkmarkCircleOutline } from 'ionicons/icons';

interface IToastHandler {
  message: string;
  fontColor?: string;
  duration?: number;
  position: 'top' | 'middle' | 'bottom';
  backgroundColor?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'medium'
    | 'dark'
    | undefined;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const ToastHandler: React.FC<IToastHandler> = ({
  isOpen,
  onDidDismiss,
  message,
  position,
  duration,
  backgroundColor,
  fontColor,
}) => {
  return (
    <StyledIonToast
      fontColor={fontColor}
      color={backgroundColor ?? 'success'}
      position={position}
      isOpen={isOpen}
      onIonToastDidDismiss={onDidDismiss}
      message={message}
      duration={duration ?? 2500}
      icon={checkmarkCircleOutline}
    />
  );
};

export default ToastHandler;
