/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { forwardRef } from 'react';
import { IonCardContent, IonToggle } from '@ionic/react';
import { ChangeHandler } from 'react-hook-form';
import {
  BryaCardToggleIcon,
  IonCardStyled,
  IonItemStyled,
} from './FormCardToggle.styles';

interface IFormCardToggleProps {
  leftIcon?: string;
  label: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
}

type FormCardToggleProps = React.ComponentProps<typeof IonToggle> &
  IFormCardToggleProps;

const FormCardToggle = forwardRef(
  (
    { leftIcon, label, onChange, onBlur, ...rest }: FormCardToggleProps,
    ref: React.Ref<HTMLIonToggleElement>
  ) => {
    return (
      <IonCardStyled button>
        <IonCardContent>
          <IonItemStyled lines="none" className="ion-no-padding">
            {leftIcon && <BryaCardToggleIcon slot="start" icon={leftIcon} />}
            <IonToggle
              // NOTE: react-hook-form needs the "type" attribute equals to "checkbox"
              // so it knows how to handle the change event properly
              // @ts-ignore
              type="checkbox"
              justify="space-between"
              mode="md"
              {...rest}
              ref={ref}
              onIonChange={onChange}
              onIonBlur={onBlur}
              color="primary-orange"
            >
              {label}
            </IonToggle>
          </IonItemStyled>
        </IonCardContent>
      </IonCardStyled>
    );
  }
);

FormCardToggle.displayName = 'FormCardToggle';

export default FormCardToggle;
