import * as React from 'react';

export const Service = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_951_32699)" opacity={0.8}>
      <path
        d="M10.172 16.814h6v-6l-7-7a12 12 0 0116 16l12 12a4.243 4.243 0 01-6 6l-12-12a12 12 0 01-16-16l7 7z"
        stroke={props.color}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_951_32699">
        <path fill="#fff" transform="translate(0 .643)" d="M0 0H40V40H0z" />
      </clipPath>
    </defs>
  </svg>
);
