import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../components/Typography/Body/Body';
import { Button } from '../../../components/Button/Button';
import { GreenCheckMark } from '../../../assets/images/GreenCheckMark';
import { ActionableCard } from '../components/ActionableCard';
import { useHistory } from 'react-router';
import { getUserLandingRoute } from '../../../utils/routes/getUserLandingRoute';
import { useAuthContext } from '../../../providers/authProvider';
import { IUserData } from '../../../types';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames } from '../../../constants/enums';
import { ROUTES } from '../../../constants/routes';

const MembershipOutroPage: React.FC = () => {
  const { user } = useAuthContext() as { user: IUserData };
  const history = useHistory();
  const { isFeatureEnabled } = useFeatureFlags();
  const isInviteButtonEnable = isFeatureEnabled(
    FeatureNames.InviteOthersMembershipFlow
  );

  const handleInviteOthers = () => {
    history.push(ROUTES.TABS.CONNECT);
  };

  const handleContinue = () => {
    history.push(getUserLandingRoute(user));
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonCol size="auto">
              <GreenCheckMark />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <SecondaryTitle>
                <Trans i18nKey="membership.outro.title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText style={{ whiteSpace: 'pre-wrap' }}>
                <Trans i18nKey="membership.outro.body" />
              </BodyText>
            </IonCol>
          </IonRow>

          {isInviteButtonEnable ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  onAction={handleInviteOthers}
                  actionText={
                    <Trans i18nKey="membership.outro.cardActions.inviteOthers" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button color="primary-orange" onClick={handleContinue}>
                <Trans i18nKey="membership.outro.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembershipOutroPage;
