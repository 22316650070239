import * as React from 'react';

export const ListOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    {...props}
  >
    <path
      d="M8.75146 7.87598H24.5015"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7511 14.001H24.5011"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7511 20.1274H24.5011"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.38421 8.76769C4.87181 8.76769 5.26708 8.37242 5.26708 7.88482C5.26708 7.39723 4.87181 7.00195 4.38421 7.00195C3.89662 7.00195 3.50134 7.39723 3.50134 7.88482C3.50134 8.37242 3.89662 8.76769 4.38421 8.76769Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.38421 14.8917C4.87181 14.8917 5.26708 14.4964 5.26708 14.0088C5.26708 13.5213 4.87181 13.126 4.38421 13.126C3.89662 13.126 3.50134 13.5213 3.50134 14.0088C3.50134 14.4964 3.89662 14.8917 4.38421 14.8917Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.38421 21.0177C4.87181 21.0177 5.26708 20.6224 5.26708 20.1348C5.26708 19.6472 4.87181 19.252 4.38421 19.252C3.89662 19.252 3.50134 19.6472 3.50134 20.1348C3.50134 20.6224 3.89662 21.0177 4.38421 21.0177Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
