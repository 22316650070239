import styled from 'styled-components';
import { IonContent, IonHeader, IonToolbar } from '@ionic/react';
import { pixelToRem } from '../../utils/helper';
import { CSSProperties } from 'react';

interface BackgroundProp {
  background: CSSProperties['background'];
}

export const StyledIonContent = styled(IonContent)<BackgroundProp>`
  --background: ${({ background }) => background};
`;

export const Header = styled(IonHeader)``;

export const UpperToolbar = styled(IonToolbar)`
  --background: unset;
  --color: white;
  --padding-bottom: 0;
  --padding-end: 24px;
  --padding-start: 24px;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;

  &:after {
    content: '';
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    width: 157px;
    height: 76px;
    left: -57px;
    top: -38px;
    background: white;
    opacity: 0.25;
  }
`;

export const LowerToolbar = styled(IonToolbar)`
  --background: unset;
  --color: white;
  --padding-top: 0;
  --padding-end: 24px;
  --padding-bottom: 24px;
  --padding-start: 24px;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;

  // NOTE: removing this ellipsis for now because it doesn't look good
  // because the overflow from the toolbar is not visible
  /* &:before {
    content: '';
    display: inline-block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: fixed;
    width: 142px;
    height: 90px;
    right: -70px;
    top: 36px;
    background: #ffffff;
    opacity: 0.3;
    transform: rotate(90deg);
    z-index: 2;
  } */
`;

export const HeadersContainer = styled.div`
  width: fit-content;
`;

export const BottomSheet = styled.article`
  padding: 32px 24px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  border-top: 1px solid #fff;
  background: linear-gradient(180deg, #fff 0%, #fff 26.72%, #f3f3f3 42.12%);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  min-height: -webkit-fill-available;
`;

export const BottomSheetHeader = styled.header<BackgroundProp>`
  background: ${({ background }) => background};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: ${() => pixelToRem(24)};
  font-weight: 700;
  line-height: 29px;
  gap: 4px;
  padding: 32px 24px 8px;
  margin: -32px -24px 0;
  border-radius: 16px 16px 0px 0px;
  text-align: center;
`;

export const BottomSheetSubtitle = styled.p`
  font-weight: 500;
  color: var(--ion-color-light-contrast);
  font-size: ${pixelToRem(12)};
  margin: 0;
  text-align: center;
  font-style: normal;
  line-height: normal;
`;
