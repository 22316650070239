import { gql } from '@apollo/client';

export const GET_ACTIVITIES = gql`
  query GetActivities($filter: ActivitiesFilter!, $pageSize: Int, $page: Int) {
    getActivities(filter: $filter, pageSize: $pageSize, page: $page) {
      requests {
        ... on GraphqlServiceRequest {
          id
          status
          requestType
          recurring
          category
          scheduledAt
          acceptedAt
          createdAt
          RequestedBy {
            id
            fullName
          }
          RequestCategory {
            id
            name
          }
          RequestedBy {
            id
            fullName
          }
          CreatedBy {
            id
            fullName
          }
          Customer {
            id
            fullName
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              state
              zipCode
              country
              isDefault
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              state
              isDefault
            }
            stops {
              addressLine1
              addressLine2
              city
              state
              isDefault
            }
          }
        }
        ... on GraphqlCircleRequest {
          id
          status
          requestType
          recurring
          circleCategory: category
          scheduledAt
          acceptedAt
          createdAt
          RequestedBy {
            id
            fullName
          }
          RequestCategory {
            id
            name
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
            stops {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
          }
        }
        ... on GraphqlCustomerSocialEvent {
          id
          eventName
          recurring
          status
          scheduledAt
          requestType
          createdAt
          updatedAt
          RequestedBy {
            id
            fullName
          }
          Address {
            addressLine1
          }
          Customer {
            id
            fullName
          }
        }
        ... on GraphqlCoachVisit {
          id
          recurring
          statusCoachVisit: status
          scheduledAt
          requestType
          createdAt
          RequestedBy {
            id
            fullName
          }
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
    }
  }
`;

export const GET_ACTIVITIES_GROUPED_BY_DATE = gql`
  query GetActivitiesGroupedByDate(
    $filter: ActivitiesGroupedByDateFilter!
    $lastValue: DateTime
  ) {
    getActivitiesGroupedByDate(filter: $filter, lastValue: $lastValue) {
      requests {
        id
        timeline {
          day
          month
          weekDay
        }
        activities {
          ... on GraphqlServiceRequest {
            id
            status
            requestType
            recurring
            category
            scheduledAt
            acceptedAt
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            RequestCategory {
              id
              name
            }
            ride {
              type
              waitTime
              pickupAddress {
                addressLine1
                addressLine2
                city
                state
                zipCode
                country
                isDefault
              }
              dropoffAddress {
                addressLine1
                addressLine2
                city
                state
                isDefault
              }
              stops {
                addressLine1
                addressLine2
                city
                state
                isDefault
              }
            }
          }
          ... on GraphqlCircleRequest {
            id
            status
            requestType
            recurring
            circleCategory: category
            scheduledAt
            acceptedAt
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            RequestCategory {
              id
              name
            }
            ride {
              type
              waitTime
              pickupAddress {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
              dropoffAddress {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
              stops {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
            }
          }
          ... on GraphqlCustomerSocialEvent {
            id
            eventName
            recurring
            status
            scheduledAt
            requestType
            createdAt
            updatedAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            Address {
              addressLine1
            }
            Customer {
              id
              fullName
            }
          }
          ... on GraphqlCoachVisit {
            id
            recurring
            statusCoachVisit: status
            scheduledAt
            requestType
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
          }
        }
      }
      meta {
        lastValue
        isLastPage
      }
    }
  }
`;

export const GET_CUSTOMER_ADDRESS = gql`
  query GetCustomerAddresses($customerId: Float!) {
    getAddresses(customerId: $customerId) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
      isDefault
    }
  }
`;

export const CREATE_SERVICE_REQUEST_MUTATION = gql`
  mutation CreateServiceRequest($data: CreateServiceRequestInput!) {
    createServiceRequest(data: $data) {
      id
    }
  }
`;

export const CREATE_CIRCLE_REQUEST_MUTATION = gql`
  mutation CreateCircleRequest($data: CreateCircleRequestInput!) {
    createCircleRequest(data: $data) {
      id
    }
  }
`;

export const GET_CIRCLE_MEMBER_ACTIVITIES = gql`
  query getCircleMemberActivities(
    $filter: AppointmentActivitiesFilter!
    $page: Int
    $pageSize: Int
  ) {
    getCircleMemberActivities(
      filter: $filter
      page: $page
      pageSize: $pageSize
    ) {
      activities {
        id
        userId
        serviceRequestId
        circleRequestId
        status
        CircleRequest {
          RequestCategory {
            id
            name
          }
          id
          customerId
          category
          otherCategory
          recurring
          frequency
          description
          addressId
          status
          requestedBy
          scheduledAt
          acceptedAt
          requestType
          tentativeSchedule
          isAvailabilityFlexible
          createdAt
          updatedAt
          acceptedBy
          askTo
        }
      }
    }
  }
`;

export const GET_AGENT_ACTIVITIES = gql`
  query GetAgentActivities(
    $filter: AppointmentActivitiesFilter!
    $page: Int
    $pageSize: Int
  ) {
    getAgentActivities(filter: $filter, page: $page, pageSize: $pageSize) {
      activities {
        id
        status
        AppointmentRequestPayments {
          id
        }
        ServiceRequest {
          id
          customerId
          category
          otherCategory
          recurring
          frequency
          description
          addressId
          status
          requestedBy
          scheduledAt
          acceptedAt
          requestType
          tentativeSchedule
          isAvailabilityFlexible
          createdAt
          updatedAt
          RequestCategory {
            id
            name
          }
          Address {
            id
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
            stops {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
          }
        }
      }
    }
  }
`;

export const GET_CIRCLE_MEMBER_ACTIVITY_BY_APPOINTMENT_ID = gql`
  query GetCircleMemberActivityByAppointmentId($appointmentRequestId: Int!) {
    getCircleMemberActivityByAppointmentId(
      appointmentRequestId: $appointmentRequestId
    ) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      SentTo {
        fullName
        profilePicture
        id
        talkJsId
      }
      CircleRequest {
        id
        customerId
        category
        otherCategory
        recurring
        frequency
        description
        addressId
        status
        lastStatusUpdate
        requestedBy
        scheduledAt
        acceptedAt
        requestType
        tentativeSchedule
        isAvailabilityFlexible
        createdAt
        updatedAt
        acceptedBy
        askTo
        availabilities {
          date
          from
          until
        }
        ride {
          type
          waitTime
          pickupAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          dropoffAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          stops {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
        }
        RequestedBy {
          id
          fullName
          firstName
          lastName
          profilePicture
        }
        RequestCategory {
          id
          name
        }
        AcceptedBy {
          id
          fullName
        }
        AskTo {
          id
          fullName
        }
        Address {
          id
          addressLine1
          addressLine2
          city
          country
          state
          zipCode
          place
        }
        Customer {
          firstName
          lastName
          fullName
          UserCustomerRoles {
            userId
            role
            Users {
              id
              fullName
              profilePicture
              firstName
              lastName
            }
          }
        }
        Timeline {
          id
          title
          description
          createdAt
          User {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const GET_AGENT_ACTIVITY_BY_APPOINTMENT_ID = gql`
  query GetAgentActivityByAppointmentId($appointmentRequestId: Int!) {
    getAgentActivityByAppointmentId(
      appointmentRequestId: $appointmentRequestId
    ) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      SentTo {
        fullName
        profilePicture
        id
        talkJsId
      }
      AppointmentRequestPayments {
        id
        amount
        appointmentRequestId
        createdAt
        estimateHours
        type
        updatedAt
      }
      ServiceRequest {
        id
        customerId
        category
        otherCategory
        recurring
        frequency
        description
        addressId
        status
        lastStatusUpdate
        requestedBy
        scheduledAt
        acceptedAt
        requestType
        tentativeSchedule
        isAvailabilityFlexible
        createdAt
        updatedAt
        serviceFee
        taxFee
        availabilities {
          date
          from
          until
        }
        ride {
          type
          waitTime
          pickupAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          dropoffAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          stops {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
        }
        RequestedBy {
          id
          fullName
          firstName
          lastName
          profilePicture
        }
        RequestCategory {
          id
          name
        }
        Customer {
          firstName
          lastName
          fullName
          UserCustomerRoles {
            userId
            role
            Users {
              id
              fullName
              profilePicture
              firstName
              lastName
            }
          }
        }
        Address {
          id
          addressLine1
          addressLine2
          city
          country
          state
          zipCode
          place
        }
        Timeline {
          id
          title
          description
          createdAt
          User {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const UPDATE_APPOINTMENT_REQUEST_MUTATION = gql`
  mutation UpdateAppointmentRequest(
    $updateAppointmentRequestInput: UpdateAppointmentRequestInput!
  ) {
    updateAppointmentRequest(
      updateAppointmentRequestInput: $updateAppointmentRequestInput
    ) {
      id
      CircleRequest {
        id
      }
      ServiceRequest {
        id
      }
    }
  }
`;

export const GET_ACTIVITIES_ALERTS = gql`
  query GetActivitiesAlerts {
    getActivitiesAlerts {
      status
      id
    }
  }
`;

export const CREATE_SUGGESTED_ACTIVITES = gql`
  mutation CreateSuggestedActivity(
    $createSuggestedActivityInput: CreateSuggestedActivityInput!
  ) {
    createSuggestedActivity(
      createSuggestedActivityInput: $createSuggestedActivityInput
    ) {
      id
    }
  }
`;
