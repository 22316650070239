/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback, useContext, useState } from 'react';

import { Container } from './AddressActionSelector.style';
import {
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonAlert,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import { AddressesFormProps } from '../../pages/address-form/address-form';
import { useDeleteAddressMutation } from '../../graphql/generated';
import { GET_ADDRESS } from '../../graphql/users';
import { AuthContext } from '../../providers/authProvider';
import { useAppContext } from '../../providers/appContextProvider';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { logger } from '../../logger';

type AddressProps = {
  address: AddressesFormProps;
  openDeleteToastVisible: (val: boolean) => void;
  onGoToConfirmScreen: (address: AddressesFormProps) => void;
};

const AddressActionSelector: React.FC<AddressProps> = ({
  address,
  onGoToConfirmScreen,
  openDeleteToastVisible,
}) => {
  const i18n = useTranslation();
  const [deleteAddressMutation] = useDeleteAddressMutation();
  const { user } = useContext(AuthContext);
  const [isAlertOpen, setAlertIsOpen] = useState<boolean>(false);
  const { globalAlert } = useAppContext();

  const goToEdit = useCallback(() => {
    if (address) {
      void onGoToConfirmScreen(address);
    }
  }, [address]);

  const confirmDelete = useCallback(() => {
    setAlertIsOpen(true);
  }, [isAlertOpen]);

  const goToDelete = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { customerId }: any = user;
    try {
      await deleteAddressMutation({
        variables: { deleteAddressId: address?.id ?? 0 },
        refetchQueries: [
          {
            query: GET_ADDRESS,
            variables: { customerId },
          },
        ],
      });
      openDeleteToastVisible(true);
    } catch (err) {
      logger.error({
        tag: '[DeleteAddressModal][deleteAddressMutation]',
        message: `Fail while calling mutation to delete address: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  }, [address]);

  const decideAction = useCallback(
    (clickedAction: string) => {
      if (clickedAction === 'edit') {
        goToEdit();
      } else if (clickedAction === 'delete') {
        void confirmDelete();
      }
    },
    [confirmDelete, goToEdit]
  );

  return (
    <Container>
      <IonList>
        <IonItem lines="none">
          <IonSelect
            interface="popover"
            value={''}
            onIonChange={(e) => decideAction(e.detail.value as string)}
          >
            <IonSelectOption value="edit">{i18n.t('edit')}</IonSelectOption>
            <IonSelectOption value="delete">{i18n.t('delete')}</IonSelectOption>
          </IonSelect>
        </IonItem>
      </IonList>
      <IonAlert
        isOpen={isAlertOpen}
        header={i18n.t('unsavedChanges') ?? ''}
        message={i18n.t('continueWithout') + i18n.t('editsMade') ?? ''}
        buttons={[
          {
            text: i18n.t('yesDelete'),
            role: 'confirm',
            handler: () => {
              void goToDelete();
            },
          },
          {
            text: i18n.t('cancel'),
            role: 'cancel',
            handler: () => {
              setAlertIsOpen(false);
            },
          },
        ]}
        onDidDismiss={() => setAlertIsOpen(false)}
      ></IonAlert>
    </Container>
  );
};

export default AddressActionSelector;
