import { IonTextarea } from '@ionic/react';
import styled from 'styled-components';

export const TextArea = styled(IonTextarea)`
  border: 1px solid var(--body-text-300);
  border-radius: 14px;
  height: 96px;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: var(--body-text-900);
`;
