import { IonModal, IonButton, IonTextarea, IonInput } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

type IonModalProps = {
  width?: string;
};

export const CustomIonModal = styled(IonModal)<IonModalProps>`
  --width: ${(props) => props?.width || 'fit-content'};
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 25px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --max-height: 100%;
  --overflow: auto;
  padding: 24px;
`;

export const ModalTitle = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${pixelToRem(18)};
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 12px;
  font-weight: 600;

  &.cancelRequestBtnTitle {
    width: 280px;
    height: 48px;
    font-weight: 700;
    font-size: ${pixelToRem(20)};
    color: #262626;

    @media (max-width: 1080px) {
      font-size: ${pixelToRem(18)};
    }
  }
  svg {
    margin-bottom: 1rem;
  }
`;

export const Title = styled.div`
  font-size: ${pixelToRem(18)};
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  margin: 0;
`;

export const Subtitle = styled.div`
  font-size: ${pixelToRem(18)};
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  margin: 0;
`;

export const ModalTitleSecondary = styled.span`
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
`;

export const ModalSubTitle = styled.span`
  font-size: ${pixelToRem(18)};
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;

  &.cancelRequestBtnSubtitle {
    text-transform: none;
    font-weight: 500;
    font-size: ${pixelToRem(18)};
    line-height: 140%;
    color: #212121;

    @media (max-width: 1080px) {
      font-size: ${pixelToRem(15)};
    }
  }
`;

export const ModalSubTitleSecondary = styled.span`
  font-size: ${pixelToRem(18)};
  line-height: 19px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  margin-bottom: 16px;
`;

export const ModalInnerContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px;
`;

export const CancelButton = styled(IonButton)`
  position: relative;
  height: 56px;
  width: 100%;
  font-weight: 600;
  background: #ffffff;
  border-radius: 50px;
  margin-top: 16px;
  --border-color: #ea8231;
  --border-radius: 50px;
  --background-activated: rgba(234, 130, 49, 0.4);
  --color-activated: #fff;
`;

export const StyledIonTextArea = styled(IonTextarea)`
  margin-bottom: 10px;
  height: 105px;
  width: 105%;
  border: solid 1px #e0e0e0;
  border-radius: 10px;
`;

export const StyledIonTextInput = styled(IonInput)`
  margin-bottom: 10px;
  height: 48px;
  width: 105%;
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  padding-inline-start: 12px !important;
`;
