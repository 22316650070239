import dayjs from 'dayjs';
import { GraphqlTimeline, GraphqlUser } from '../../graphql/generated';
import ActivityTimeLine from '../ActivityTimeLine/ActivityTimeLine';
import {
  ActivityCard,
  ActivityLine,
  StyledIonContent,
  Subtitle,
  TimeLineContainer,
  TimeLineContent,
  Title,
} from './ActivitiesHistory.styles';
import React from 'react';
import { IUserData } from '../../types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

interface ActivitiesHistoryProps {
  timeline?: GraphqlTimeline[];
  user: IUserData | null;
}

const ActivitiesHistory: React.FC<ActivitiesHistoryProps> = ({
  timeline,
  user,
}) => {
  const i18n = useTranslation();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();

  const isYou = (userId?: number | null) => user?.id === userId;

  const description = (
    createdAt: Date,
    userData?: Maybe<GraphqlUser> | null,
    userId?: number | null
  ) => (
    <>
      {i18n.t('by')} {isYou(userId) ? i18n.t('you') : userData?.fullName} |{' '}
      {formatDateAndTimeInTimezone(createdAt, undefined, 'h:mmA z', false)}
    </>
  );

  const sortedTimeline = timeline
    ? [...timeline].sort((a, b) => {
        return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
      })
    : [];

  return (
    <StyledIonContent>
      {sortedTimeline?.map(
        ({
          id,
          createdAt,
          title,
          userId,
          User,
          isAppVisible,
        }: GraphqlTimeline) => (
          <React.Fragment key={id}>
            {isAppVisible && (
              <TimeLineContent key={id}>
                <TimeLineContainer>
                  <ActivityTimeLine
                    data={{
                      day: dayjs(createdAt).format('DD'),
                      month: dayjs(createdAt).format('MMM'),
                      weekDay: dayjs(createdAt).format('ddd'),
                    }}
                  />
                  <ActivityLine />
                </TimeLineContainer>
                <ActivityCard>
                  <Title>{title}</Title>
                  <Subtitle>{description(createdAt, User, userId)}</Subtitle>
                </ActivityCard>
              </TimeLineContent>
            )}
          </React.Fragment>
        )
      )}
    </StyledIonContent>
  );
};

export default ActivitiesHistory;
