import React from 'react';
import {
  Container,
  Description,
  Title,
  Wrapper,
} from './PaymentAlertActionRequired.styles';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { ServiceRequestStatus } from '../../graphql/generated';

const PaymentAlertActionRequired: React.FC<{
  serviceId: number;
  serviceStatus: ServiceRequestStatus;
}> = ({ serviceId, serviceStatus }) => {
  const router = useHistory();
  return (
    <Container
      onClick={() => {
        if (!serviceId) return;
        router.push('/activityStatus', {
          status: serviceStatus,
          serviceRequestId: serviceId,
        });
      }}
    >
      <Wrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M4.51096 21.2033H20.4888C20.7483 21.2032 21.0033 21.1358 21.229 21.0077C21.4547 20.8796 21.6433 20.6951 21.7763 20.4723C21.9094 20.2495 21.9824 19.996 21.9881 19.7366C21.9939 19.4772 21.9323 19.2206 21.8092 18.9922L13.8208 4.15622C13.2541 3.10434 11.7457 3.10434 11.1789 4.15622L3.19049 18.9922C3.06747 19.2206 3.00583 19.4772 3.0116 19.7366C3.01737 19.996 3.09035 20.2495 3.2234 20.4723C3.35646 20.6951 3.54504 20.8796 3.77072 21.0077C3.9964 21.1358 4.25146 21.2032 4.51096 21.2033V21.2033Z"
            stroke="#EA4255"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.2311 9.44701L12.5002 15.1658L12.7688 9.44935C12.7705 9.41281 12.7646 9.37631 12.7517 9.34209C12.7388 9.30787 12.719 9.27664 12.6936 9.25032C12.6682 9.22399 12.6377 9.20312 12.604 9.18899C12.5702 9.17485 12.534 9.16775 12.4974 9.1681V9.1681C12.4614 9.16846 12.4259 9.176 12.393 9.1903C12.36 9.2046 12.3302 9.22537 12.3054 9.25136C12.2806 9.27736 12.2612 9.30807 12.2485 9.34167C12.2357 9.37527 12.2298 9.41109 12.2311 9.44701V9.44701Z"
            stroke="#EA4255"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 18.9082C12.3146 18.9082 12.1333 18.8532 11.9792 18.7502C11.825 18.6472 11.7048 18.5008 11.6339 18.3295C11.5629 18.1582 11.5443 17.9697 11.5805 17.7878C11.6167 17.6059 11.706 17.4389 11.8371 17.3078C11.9682 17.1767 12.1352 17.0874 12.3171 17.0512C12.499 17.015 12.6875 17.0336 12.8588 17.1046C13.0301 17.1755 13.1765 17.2957 13.2795 17.4499C13.3825 17.604 13.4375 17.7853 13.4375 17.9707C13.4375 18.2193 13.3387 18.4578 13.1629 18.6336C12.9871 18.8094 12.7486 18.9082 12.5 18.9082Z"
            fill="#EA4255"
          />
        </svg>
      </Wrapper>
      <Wrapper>
        <Title>
          <Trans i18nKey={`payment.alert.title`} />
        </Title>
        <Description>
          <Trans i18nKey={`payment.alert.description`} />
        </Description>
      </Wrapper>
    </Container>
  );
};

export default PaymentAlertActionRequired;
