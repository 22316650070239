import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

type CardContentProps = {
  title?: string | null | undefined;
};

export const CardContent = styled.div<CardContentProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ title }) =>
    title === 'Send a Message' &&
    `
    &:after {
      content: '';
      display: inline-block;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      position: absolute;
      width: 60px;
      height: 60px;
      left: -28px;
      top: -42px;
      background: #ffffff;
      opacity: 0.2;
      transform: rotate(-15deg);
    }
  `};
  ${({ title }) =>
    title === 'Ask Your Circle' &&
    `
    &:after {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      left: -66px;
      top: 0px;
      background: #ffffff;
      opacity: 0.25;
      transform: rotate(-120deg);
    }
    &:before {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      right: -56px;
      top: -56px;
      background: #ffffff;
      opacity: 0.3;
      transform: rotate(15deg);
    }
  `};
  ${({ title }) =>
    title === 'Hire a Service' &&
    `
    &:after {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      left: 58px;
      top: -68px;
      background: #ffffff;
      opacity: 0.25;
      transform: rotate(-120deg);
    }
    &:before {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      right: -64px;
      bottom: -18px;
      background: #ffffff;
      opacity: 0.3;
      transform: rotate(120deg);
    }
  `};
  ${({ title }) =>
    title === 'Connect with Community' &&
    `
    &:after {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      left: -42px;
      bottom: -68px;
      background: #ffffff;
      opacity: 0.25;
      transform: rotate(165deg);
    }
    &:before {
      content: '';
      display: inline-block;
      -moz-border-radius: 40px;
      -webkit-border-radius: 40px;
      border-radius: 40px;
      position: absolute;
      width: 80px;
      height: 80px;
      right: 50px;
      top: -66px;
      background: #ffffff;
      opacity: 0.3;
      transform: rotate(-15deg);
    }
  `};
`;

export const CardMiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const CardText = styled.div`
  color: #fff;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
`;
