import * as React from 'react';

export const LocationOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99994 1.87306C6.89447 1.87306 4.37494 4.27111 4.37494 7.22463C4.37494 10.6231 8.12494 16.0086 9.50189 17.8688C9.55905 17.9473 9.63396 18.0112 9.72052 18.0552C9.80707 18.0993 9.90282 18.1223 9.99994 18.1223C10.0971 18.1223 10.1928 18.0993 10.2794 18.0552C10.3659 18.0112 10.4408 17.9473 10.498 17.8688C11.8749 16.0094 15.6249 10.6258 15.6249 7.22463C15.6249 4.27111 13.1054 1.87306 9.99994 1.87306Z"
      stroke="#23364B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99995 9.37532C11.0355 9.37532 11.875 8.53585 11.875 7.50032C11.875 6.46479 11.0355 5.62532 9.99995 5.62532C8.96442 5.62532 8.12495 6.46479 8.12495 7.50032C8.12495 8.53585 8.96442 9.37532 9.99995 9.37532Z"
      stroke="#23364B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
