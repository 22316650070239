import { IonButton, IonCard, IonPage } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const IonPageStyled = styled(IonPage)`
  --ion-toolbar-background: #fff;
  .header-custom {
    padding-top: 24px;
  }

  ion-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  ion-radio::part(container) {
    width: 20px;
    height: 20px;

    border-radius: 10px;
    border: 2px solid #808080;
  }

  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  ion-radio.radio-checked::part(container) {
    background: var(--colors-primary-orange);
    border-color: transparent;
  }

  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;

    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;

    transform: rotate(45deg);
  }

  ion-radio {
    color: #23364b;
    font-size: ${pixelToRem(16)};
    line-height: 20px;
    font-weight: 700;
  }
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  background-color: transparent;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const InfoText = styled.span`
  font-size: ${pixelToRem(16)};
  line-height: 22px;
  color: #212121;
`;

export const SaveCardButton = styled(IonButton)`
  height: 56px;
  width: 100%;
  font-weight: 600;
  justify-content: center;
  --border-color: #ea8231;
  --border-radius: 20px;
  --disabled-background: #b0b2c3;
`;

export const BackButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: #23364b;
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(24)};
  line-height: 30px;
  margin-bottom: 8px;
`;

export const ConnectButtonText = styled.span`
  color: #fff;
  font-weight: 600;
  font-size: ${pixelToRem(12)};
  line-height: 14px;
  text-transform: capitalize;
`;

export const Subtitle = styled.p`
  color: #616161;
  font-size: ${pixelToRem(18)};
  line-height: 20px;
  margin-top: 30px;
`;

export const InfoCard = styled.div`
  padding: 16px;
  border: 1px solid #000;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const PayTitle = styled.span`
  color: #23364b;
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(20)};
  line-height: 24px;
`;

export const Wrapper = styled(IonCard)`
  margin-bottom: 32px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
`;
