import { createContext, useContext, useState, ReactNode, FC } from 'react';

interface UnmountContextProps {
  uniqueKey: number;
  cleanHiddenComponents: () => void;
}

interface UnmountProviderProps {
  children: ReactNode;
}

const UnmountContext = createContext<UnmountContextProps | undefined>(
  undefined
);

export const useUnmountContext = (): UnmountContextProps => {
  const context = useContext(UnmountContext);
  if (!context) {
    throw new Error(
      'useUnmountContext should be used inside of UnmountProvider'
    );
  }
  return context;
};

export const UnmountProvider: FC<UnmountProviderProps> = ({ children }) => {
  const [uniqueKey, setUniqueKey] = useState<number>(0);

  const cleanHiddenComponents = (): void => {
    setUniqueKey((prevKey) => prevKey + 1);
  };

  return (
    <UnmountContext.Provider value={{ uniqueKey, cleanHiddenComponents }}>
      {children}
    </UnmountContext.Provider>
  );
};
