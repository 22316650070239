import styled from 'styled-components';

// NOTE: using button instead of <IonButton> because it has
// a min-height that we can't override easily and makes row bigger
export const ConnectButton = styled.button`
  color: var(--colors-primary-orange);
  background-color: unset;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
`;
