import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import Talk from 'talkjs';
import { GlobalRole, useGetSignatureQuery } from '../graphql/generated';
import { AuthContext } from './authProvider';
import { logger } from '../logger';
import { getApolloErrorMessage } from '../utils/apollo/errors';

export interface TalkJsContextType {
  session?: Talk.Session | null;
}

export const TalkJsContext = createContext<TalkJsContextType | undefined>(
  undefined
);

type TalkJsProviderType = {
  children: ReactNode;
};

export const TalkJsProvider: React.FC<TalkJsProviderType> = ({ children }) => {
  const [session, setSession] = useState<Talk.Session | null>(null);
  const { user } = useContext(AuthContext);
  const destroySession = () => {
    session?.destroy();
    setSession(null);
  };

  const isPro =
    user?.role === GlobalRole.Agent || user?.role === GlobalRole.AdminAgent;
  const userHasPassedSetupFlow = !!user?.customerId;

  const { data, error } = useGetSignatureQuery({
    skip: !(isPro || userHasPassedSetupFlow),
  });

  useEffect(() => {
    async function initTalkJs() {
      if (!data) return;

      await Talk.ready;
      const { talkJsId, talkJsAppId, signature } = data.getSignature;
      const userOptions = {
        id: talkJsId,
        name: user?.firstName,
        email: user?.email,
        role: user?.role,
      } as Talk.UserOptions;

      const currentUser = new Talk.User(userOptions);
      const newSession = new Talk.Session({
        appId: talkJsAppId,
        me: currentUser,
        signature,
      });

      setSession(newSession);
    }
    if (data && user) {
      // NOTE: init session if user is a pro or if user has passed the setup / membership flow
      if (isPro || userHasPassedSetupFlow) {
        void initTalkJs();
      }
    } else {
      destroySession();
    }
  }, [data, user]);

  useEffect(() => {
    if (error) {
      logger.error({
        tag: '[TalkJsProvider][initializeSession]',
        message: `Fail at initializing session in TalkJS ${getApolloErrorMessage(
          error
        )}`,
        error: error as Error,
      });
      destroySession();
    }
  }, [error]);

  return (
    <TalkJsContext.Provider value={{ session }}>
      {children}
    </TalkJsContext.Provider>
  );
};
