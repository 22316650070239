import { IonContent } from '@ionic/react';
import styled from 'styled-components';

export const CellContainer = styled.div`
  margin-bottom: 1rem;
`;
export const CellContent = styled.div`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
`;

export const IconContainer = styled.div`
  margin-right: 1.5rem;
  position: relative;
  ion-icon {
    transform: translateX(0.5rem) !important;
  }
`;
export const HeaderContainer = styled.div`
  width: 100%;
`;

export const NewNotification = styled.div`
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 0.5rem;
  height: 0.5rem;
  background: var(--primary-orange, #ea8231);
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  color: var(--knock-gray-900, #1a1f36);
  h2 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
  }
  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
  }
  margin-bottom: 1rem;
`;
export const ActionsContainer = styled.div`
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  ion-button {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    transition: width 0.5s ease-in-out;
  }
`;
export const TimeStampContainer = styled.div`
  span {
    color: var(--knock-gray-200, #a5acb8);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
`;
export const NotificationContent = styled(IonContent)`
  --padding-bottom: 21.5rem;
`;
