import styled, { CSSProperties } from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

interface WithColor {
  color?: CSSProperties['color'];
}
export const MainTitle = styled.h1<WithColor>`
  font-size: ${() => pixelToRem(32)};
  font-weight: 700;
  line-height: 35px;
  margin: 0;
  color: ${({ color }) => color || 'var(--body-text-900)'};
`;

export const SecondaryTitle = styled.h2<WithColor>`
  font-size: ${() => pixelToRem(24)};
  font-weight: 700;
  line-height: 29px;
  margin: 0;
  color: ${({ color }) => color || 'var(--body-text-900)'};
`;

export const TertiaryTitle = styled.h3<WithColor>`
  font-size: ${() => pixelToRem(16)};
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  color: ${({ color }) => color || 'var(--body-text-900)'};
`;

export const QuaternaryTitle = styled.h4<WithColor>`
  font-size: ${() => pixelToRem(14)};
  font-weight: 500;
  line-height: 18px;
  margin-left: 4px;
  color: ${({ color }) => color || 'var(--ion-color-primary)'};
`;
