import React from 'react';
import { IonContentContainer } from './ServiceRequestedSuccessfully.styles';
import { GreenCheckMark } from '../../assets/images/GreenCheckMark';
import { IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Success from '../../components/Success/Success';
import { useHistory, useParams } from 'react-router';
import { useUnmountContext } from '../../providers/unmountHiddenCompProvider';
import { StorageKey, removeStorage } from '../../utils/storage';

type PageTranslations = {
  title: string;
  body: string;
  cta: string;
};

const ServiceRequestedSuccessfully: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { requestType } = useParams<{ requestType: string }>();
  const { cleanHiddenComponents } = useUnmountContext();
  const { title, body, cta }: PageTranslations = t(
    `serviceSubmitSuccess.${requestType}`,
    {
      returnObjects: true,
    }
  );

  return (
    <IonPage>
      <IonContentContainer scrollY={false}>
        <Success
          icon={<GreenCheckMark />}
          title={title}
          message={body}
          buttonLabel={cta}
          buttonAction={() => {
            void removeStorage(StorageKey.WhoIsThisId);
            cleanHiddenComponents();
            router.replace('/tabs/activities');
          }}
        />
      </IonContentContainer>
    </IonPage>
  );
};

export default ServiceRequestedSuccessfully;
