/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Trans, useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  BodyContainer,
  CancelButton,
  ContainerButtons,
  IconContainer,
  RecurringText,
  Subtitle,
  Title,
} from '../ActivityStatus.styles';
import ActivityStatusPending from '../../../assets/images/ActivitiesStatusPending';
import IconActivityPending from '../../../assets/images/IconActivityPending';
import ActivitiesSections from '../../ActivitiesSections/ActivitiesSections';
import {
  homeOutline,
  chevronDown,
  calendarClearOutline,
  personOutline,
  informationOutline,
  repeatOutline,
} from 'ionicons/icons';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ActivityStatusEnum } from '../../../pages/activity-status/activity-statusEnum';
import CustomAlert from '../../CustomAlert/CustomAlert';
import { ActivityStatus } from '../StatusComponent.interface';
import {
  GetCircleRequestQueryHookResult,
  GetServiceRequestQueryHookResult,
  GraphqlTimeline,
  useGetCircleRequestQuery,
  useGetServiceRequestQuery,
  useUserCancelCircleRequestMutation,
  useUserCancelServiceRequestMutation,
} from '../../../graphql/generated';
import { buildRideStops } from '../../../utils/buildRideObject';
import CircleRequestIcon from '../../../assets/images/ServiceCategory/Circle_Activities_Icon.svg';
import ActivityStatusSkeleton from '../StatusContentSkeleton';
import { getSentToData } from '../../../utils/getSentToData';
import { formatDayOfWeekInTimezone } from '../../../utils/date';
import { getAddressInformationCircle } from '../../../utils/getAddressInformationCircle';
import { GenericEnum } from '../../../constants/enums';
import { formatAddress } from '../../../utils/address/formatAddress';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface PendingStatusProps {
  title?: string;
  serviceRequestId?: number;
  requestType?: string;
  typeChange?: {
    iconStartName: React.ReactElement;
    iconEndName: React.ReactElement;
    title: string;
    readOnly: boolean;
    ratingValue: number | string;
    customClass?: true;
    customIcon: false;
  };
}

interface PendingOption {
  iconStartName: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
  iconEndName: string;
  title?: string | JSX.Element;
  ratingValue: string | number;
  readOnly: boolean;
  customIcon: boolean;
  subTitle: string;
  customClass: boolean;
  historyTimeline?: GraphqlTimeline[];
}

const PendingStatus: React.FC<PendingStatusProps> = ({
  typeChange,
  serviceRequestId,
  requestType,
}) => {
  const { t } = useTranslation();
  const [isDataReady, setIsDataReady] = useState(false);
  const i18n = useTranslation();
  const { width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation<ActivityStatus>();
  let reason = '';
  const [userCancelServiceRequestMutation] =
    useUserCancelServiceRequestMutation();
  const [cancelCircleRequestMutation] = useUserCancelCircleRequestMutation();
  const { formatDateAndTimeInTimezone, formatTimeInTimezone } =
    useDateWithTimezone();

  const { data: getServiceRequestData }: GetServiceRequestQueryHookResult =
    useGetServiceRequestQuery({
      variables: {
        getServiceRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'service',
      onCompleted: () => setIsDataReady(true),
    });

  const { data: getCircleRequestData }: GetCircleRequestQueryHookResult =
    useGetCircleRequestQuery({
      variables: {
        getCircleRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'circle',
      onCompleted: () => setIsDataReady(true),
    });

  const addressInf = formatAddress(
    getServiceRequestData?.getServiceRequest.Address,
    Boolean(getServiceRequestData?.getServiceRequest.Address?.place)
  );

  const ratingInf = getServiceRequestData?.getServiceRequest.Agent?.rating as
    | number
    | '';
  const userFullName = getServiceRequestData?.getServiceRequest.Agent?.User
    .fullName as string;

  const scheduledAt: Date | null | undefined =
    getServiceRequestData?.getServiceRequest.scheduledAt;
  const dateScheduled = scheduledAt
    ? formatDateAndTimeInTimezone(scheduledAt)
    : '';

  const circleScheduledAt: Date | null | undefined =
    getCircleRequestData?.getCircleRequest.scheduledAt;
  const circleDateScheduled = circleScheduledAt
    ? formatDateAndTimeInTimezone(circleScheduledAt)
    : '';

  const rideStops = useMemo(() => {
    let data;

    if (requestType === 'service') {
      data = getServiceRequestData?.getServiceRequest;
    } else if (requestType === 'circle') {
      data = getCircleRequestData?.getCircleRequest;
    }

    return buildRideStops(data);
  }, [getServiceRequestData, getCircleRequestData, requestType]);

  const sentToCircle = useMemo(() => {
    if (getCircleRequestData?.getCircleRequest.AskTo) {
      return getSentToData(getCircleRequestData?.getCircleRequest.AskTo) || [];
    }
  }, [getSentToData, getCircleRequestData?.getCircleRequest.AskTo]);
  const pendingOptions: PendingOption[] = [];
  if (requestType == 'service') {
    if (userFullName) {
      pendingOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: ratingInf ? true : false,
        title: userFullName,
        ratingValue: ratingInf,
        readOnly: true,
        subTitle: '',
        customClass: false,
      });
    }
    if (addressInf) {
      pendingOptions.push({
        iconStartName: homeOutline,
        iconEndName: rideStops?.length ? chevronDown : '',
        title: addressInf,
        ratingValue: '',
        readOnly: rideStops?.length ? false : true,
        customIcon: false,
        subTitle: '',
        customClass: false,
      });
    }
    if (rideStops?.length) {
      const pickupAddress = `${getServiceRequestData?.getServiceRequest.ride?.pickupAddress.addressLine1}${getServiceRequestData?.getServiceRequest.ride?.pickupAddress.city}`;
      pendingOptions.push({
        iconStartName: homeOutline,
        iconEndName: chevronDown,
        title: pickupAddress,
        ratingValue: '',
        readOnly: false,
        customIcon: false,
        subTitle: '',
        customClass: false,
      });
    }
    if (dateScheduled && !typeChange) {
      pendingOptions.push({
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: dateScheduled,
        ratingValue: '',
        readOnly: true,
        customIcon: false,
        subTitle: '',
        customClass: false,
      });
    }
  } else if (
    requestType === 'circle' &&
    getCircleRequestData?.getCircleRequest.Address
  ) {
    pendingOptions.unshift({
      iconStartName: homeOutline,
      iconEndName: rideStops.length > 0 ? chevronDown : '',
      title: getAddressInformationCircle(getCircleRequestData),
      ratingValue: '',
      readOnly: rideStops.length > 0 ? false : true,
      customIcon: false,
      subTitle: '',
      customClass: false,
    });
  } else if (requestType === 'social') {
    pendingOptions.unshift({
      iconStartName: homeOutline,
      iconEndName: '',
      title: addressInf,
      ratingValue: '',
      readOnly: true,
      customIcon: false,
      subTitle: '',
      customClass: false,
    });
  }

  if (circleDateScheduled) {
    pendingOptions.splice(pendingOptions.length - 1, 0, {
      iconStartName: calendarClearOutline,
      iconEndName: '',
      title: dateScheduled,
      ratingValue: '',
      readOnly: true,
      customIcon: false,
      subTitle: '',
      customClass: false,
    });
  }

  if (sentToCircle?.length) {
    pendingOptions.unshift({
      iconStartName: CircleRequestIcon,
      iconEndName: '',
      customIcon: false,
      title: t('sentToCircleMembers', { circleMembers: sentToCircle }),
      ratingValue: '',
      readOnly: true,
      customClass: false,
      subTitle: '',
    });
  }

  if (typeChange) {
    if (
      getServiceRequestData?.getServiceRequest?.rejectedReason ===
        GenericEnum.NewAgentNeeded &&
      getServiceRequestData?.getServiceRequest?.Agent === null
    ) {
      pendingOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: false,
        title: GenericEnum.NewProfessionalRequested,
        ratingValue: '',
        readOnly: true,
        customClass: true,
        subTitle: '',
      });
      if (
        getServiceRequestData?.getServiceRequest.recurring ||
        getCircleRequestData?.getCircleRequest.recurring
      ) {
        pendingOptions.splice(1, 0, {
          iconStartName: repeatOutline,
          iconEndName: '',
          title: (
            <Trans
              i18nKey="serviceForm.frequencyInfo"
              values={{
                frequency:
                  requestType === 'circle'
                    ? getCircleRequestData?.getCircleRequest.frequency?.toLowerCase()
                    : getServiceRequestData?.getServiceRequest.frequency?.toLowerCase(),
                dayOfWeek:
                  requestType === 'circle'
                    ? formatDayOfWeekInTimezone(
                        getCircleRequestData?.getCircleRequest.scheduledAt ?? ''
                      )
                    : formatDayOfWeekInTimezone(
                        getServiceRequestData?.getServiceRequest.scheduledAt ??
                          ''
                      ),
                time:
                  requestType === 'social'
                    ? formatTimeInTimezone(circleDateScheduled)
                    : formatTimeInTimezone(scheduledAt),
              }}
            />
          ),
          ratingValue: '',
          readOnly: true,
          customIcon: false,
          subTitle: '',
          customClass: false,
        });
      }
    } else if (
      getServiceRequestData?.getServiceRequest.Agent &&
      getServiceRequestData?.getServiceRequest?.rejectedReason ===
        GenericEnum.NewAgentNeeded
    ) {
      const penultimateIndex = pendingOptions.length - 1;

      pendingOptions.splice(penultimateIndex, 0, {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: dateScheduled,
        ratingValue: '',
        readOnly: true,
        customIcon: false,
        subTitle: 'New date and time proposed',
        customClass: false,
      });
    }
  }

  if (
    getServiceRequestData?.getServiceRequest?.rejectedReason ===
    GenericEnum.NewTimeNeeded
  ) {
    const formattedTime = getServiceRequestData?.getServiceRequest
      ?.availabilities[0]
      ? `${getServiceRequestData.getServiceRequest.availabilities[0].date}T${(
          getServiceRequestData.getServiceRequest.availabilities[0]
            .from as unknown as string
        ).replace('Z', '')}`
      : undefined;

    const proposedTime = formattedTime
      ? formatDateAndTimeInTimezone(new Date(formattedTime))
      : undefined;

    pendingOptions.unshift({
      iconStartName: calendarClearOutline,
      iconEndName: '',
      title: proposedTime ? proposedTime : GenericEnum.NewProposedTime,
      ratingValue: '',
      readOnly: true,
      customIcon: false,
      subTitle: proposedTime ? GenericEnum.NewProposedTime : '',
      customClass: false,
    });
  }

  if (
    getServiceRequestData?.getServiceRequest?.rejectedReason ===
    GenericEnum.NewAgentNeeded
  ) {
    pendingOptions.unshift({
      iconStartName: personOutline,
      iconEndName: '',
      title: GenericEnum.NewProfessionalRequested,
      ratingValue: '',
      readOnly: true,
      customIcon: false,
      subTitle: '',
      customClass: true,
    });
  }

  pendingOptions.push({
    iconStartName: HistoryIcon,
    historyTimeline:
      requestType === 'circle'
        ? getCircleRequestData?.getCircleRequest.Timeline ?? []
        : getServiceRequestData?.getServiceRequest.Timeline ?? [],
    iconEndName: chevronDown,
    title: 'Request history',
    ratingValue: '',
    readOnly: false,
    customIcon: false,
    subTitle: '',
    customClass: false,
  });

  const handleTextAreaChange = (value: string) => {
    reason = value;
  };

  const goToCancelledStatus = () =>
    history.replace('/activityStatus', {
      title: location.state?.title,
      status: ActivityStatusEnum.Cancelled,
      requestType,
      serviceRequestId,
      typeChange: {
        iconStartName: informationOutline,
        iconEndName: chevronDown,
        title: 'Reason',
        ratingValue: '',
        readOnly: false,
        reason,
      },
    });
  return (
    <>
      <ActivityStatusPending width={width} />
      <BodyContainer width={width} className="pending">
        <IconContainer>
          <IconActivityPending />
        </IconContainer>
        <Title data-testid="pending-title">{i18n.t('pending')}</Title>
        {(getServiceRequestData?.getServiceRequest.recurring ||
          getCircleRequestData?.getCircleRequest.recurring) && (
          <RecurringText>{i18n.t('serviceForm.recurringEvent')}</RecurringText>
        )}
        <Subtitle data-testid="pending-subtitle" className="pendingSubtitle">
          {requestType === 'circle' &&
            getCircleRequestData?.getCircleRequest.description}
          {typeChange?.title && <span>{i18n.t('pendingSubtitle')}</span>}
          {requestType === 'service' && (
            <span>{i18n.t('pendingSubtitleComp')}</span>
          )}
        </Subtitle>
        {!isDataReady && <ActivityStatusSkeleton count={2} />}
        {isDataReady && (
          <ActivitiesSections
            itemLists={pendingOptions}
            rideOptions={rideStops}
            status={'pending'}
          />
        )}
        <ContainerButtons>
          <CancelButton
            className="pending"
            data-testid="pending-btn-cancel"
            fill={'outline'}
            onClick={() => setModalOpen(true)}
          >
            <>{i18n.t('cancelRequest')}</>
          </CancelButton>
        </ContainerButtons>
        <CustomAlert
          showTextArea={true}
          onDidDismiss={() => setModalOpen(false)}
          isOpen={modalOpen}
          title={
            requestType === 'social'
              ? getServiceRequestData?.getServiceRequest.recurring
                ? i18n.t('cancelEventBtnTitleRecurring')
                : i18n.t('cancelEventBtnTitle')
              : getServiceRequestData?.getServiceRequest.recurring
              ? i18n.t('cancelRequestBtnTitleRecurring')
              : i18n.t('cancelRequestBtnTitle')
          }
          subtitle={i18n.t('cancelRequestBtnSubtitle')}
          firstButtonLabel={i18n.t('yesCancelButton')}
          secondButtonLabel={i18n.t('noCancelButton')}
          firstButtonAction={() => {
            if (requestType === 'service') {
              userCancelServiceRequestMutation({
                variables: {
                  cancellationReason: reason,
                  serviceRequestId: serviceRequestId ? serviceRequestId : 0,
                },
              })
                .then(() => {
                  setModalOpen(false);
                  goToCancelledStatus();
                })
                .catch(() => {
                  throw new Error('Update Service Request Cancel Failed');
                });
            }

            if (requestType === 'circle') {
              cancelCircleRequestMutation({
                variables: {
                  circleRequestId: serviceRequestId ? serviceRequestId : 0,
                  cancellationReason: reason,
                },
              })
                .then(() => {
                  setModalOpen(false);
                  goToCancelledStatus();
                })
                .catch(() => {
                  throw new Error('Cancel Circle Request Failed');
                });
            }
          }}
          onTextAreaChange={handleTextAreaChange}
          secondButtonAction={() => setModalOpen(false)}
        />
      </BodyContainer>
    </>
  );
};

export default PendingStatus;
