import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonButton, IonModal, IonSelect, IonToast } from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const StyledIonModal = styled(IonModal)``;

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 24px;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const PageTitle = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(24)};
  text-align: left;
  color: #000000;
  font-weight: 700;
  line-height: 28px;
  margin-top: 24px;
  margin-left: 4px;
`;

export const SearchButtonLabel = styled.span`
  font-weight: 500;
  font-size: ${pixelToRem(16)};
  text-transform: capitalize;
`;

export const TagTitle = styled.span`
  font-size: ${pixelToRem(12)};
  line-height: 15px;
  color: rgba(89, 89, 89, 1);
  text-transform: uppercase;
`;

export const LocationsHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InputsContainer = styled.div<ButtonProps>`
  margin-top: 24px;
  width: ${(props) => props.screenWidth && props.screenWidth}px;
  margin-left: -16px;
  height: ${(props) => props.screenHeight && props.screenHeight * 0.65}px;
`;

export const RowFormFields = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

export const TagButton = styled(IonButton)<ButtonProps>`
  --background-activated: rgba(35, 54, 75, 1);
  --background: rgba(35, 54, 75, 1);
  --border-color: rgba(35, 54, 75, 1);
  --border-radius: 56px;
  height: 40px;
  margin-left: 16px;
`;

export const CustomIonSelect = styled(IonSelect)`
  height: 48px;
  background: #ffffff;
  border: 1px solid #212121;
  border-radius: 14px;
  margin-bottom: 16px;
  margin-top: 8px;
  --placeholder-color: rgba(158, 158, 158, 1);
  color: rgba(33, 33, 33, 1);
  padding-inline-start: 12px !important;
`;

export const SelectContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 16px;
`;

export const CustomToast = styled(IonToast)`
  --background: #3a868c;
`;
