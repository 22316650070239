import { IonButton, IonDatetime, IonIcon } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import styled, { CSSProperties } from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { useCallback, useEffect, useState } from 'react';
import { Space } from '../Space/Space';
import { AnimatePresence, motion } from 'framer-motion';
import { Trans } from 'react-i18next';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import dayjs from 'dayjs';

const WeekSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
  border-radius: 14px;
  border: 1px solid var(--body-text-300);
  background: #f4f6f6;

  color: var(--character-title-85);
  text-align: center;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Week = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const ICON_STYLES: CSSProperties = {
  width: 16,
  height: 16,
};

const CalendarVisibilityToggleContainer = styled.div`
  text-align: center;
`;

const CalendarVisibilityToggleText = styled.span`
  text-transform: capitalize;
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 22px;
  letter-spacing: -0.4px;
  color: var(--colors-primary-blue);
  margin: 0;
`;

const CalendarCard = styled.div`
  width: fit-content;
  max-width: 86vw;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;

const WEEK_SELECTOR_FORMAT = 'ddd MMM D';

type Range = 'week';

interface DateSelectorProps {
  range?: Range;
  onSelected: (start: Date, end?: Date) => void;
  disablePastDates?: boolean;
  defaultCalendarOpen?: boolean;

  // Date in format YYYY-MM-DD
  isCalendarDateEnabled?: (date: string) => boolean;
}

// NOTE: Update AvailabilityDates.tsx to use this component
export function DateSelector({
  range,
  onSelected,
  disablePastDates,
  defaultCalendarOpen,
  isCalendarDateEnabled,
}: DateSelectorProps) {
  const [showCalendar, setShowCalendar] = useState(
    Boolean(defaultCalendarOpen)
  );
  const [dateSelected, setDateSelected] = useState<Date>(
    dayjs().startOf('day').toDate()
  );

  useEffect(() => {
    switch (range) {
      case 'week':
        // NOTE: dateSelected is at startOfDay in current timezone
        onSelected(dateSelected, dayjs(dateSelected).add(7, 'day').toDate());
        break;
      default:
        onSelected(dateSelected);
        break;
    }
  }, [dateSelected]);

  const isDateEnabled = useCallback((dateStr: string) => {
    const date = new Date(dateStr);
    let enabled = true;
    if (disablePastDates) {
      enabled =
        enabled &&
        dayjs(date).startOf('day').toDate() >= dayjs().startOf('day').toDate();
    }
    if (isCalendarDateEnabled) {
      enabled = enabled && isCalendarDateEnabled(dateStr);
    }
    return enabled;
  }, []);
  return (
    <Space size="small" direction="column">
      <WeekSelectorContainer>
        <IonIcon
          src={chevronBackOutline}
          style={{
            ...ICON_STYLES,
            opacity:
              disablePastDates &&
              dayjs(dateSelected).diff(dayjs().startOf('day'), 'days') === 0
                ? 0
                : 1,
          }}
          onClick={() =>
            disablePastDates
              ? setDateSelected(
                  dayjs(
                    dayjs.max([
                      dayjs(dateSelected).subtract(7, 'day'),
                      dayjs().startOf('day'),
                    ])
                  ).toDate()
                )
              : setDateSelected(dayjs(dateSelected).subtract(7, 'day').toDate())
          }
        />

        <Week>
          {`${dayjs(dateSelected).format(WEEK_SELECTOR_FORMAT)} - ${dayjs(
            dateSelected
          )
            .add(6, 'day')
            .format(WEEK_SELECTOR_FORMAT)}`}
        </Week>

        <IonIcon
          src={chevronForwardOutline}
          style={ICON_STYLES}
          onClick={() =>
            setDateSelected(dayjs(dateSelected).add(7, 'day').toDate())
          }
        />
      </WeekSelectorContainer>
      <CalendarVisibilityToggleContainer>
        <IonButton fill="clear" onClick={() => setShowCalendar(!showCalendar)}>
          <CalendarVisibilityToggleText>
            {showCalendar ? (
              <Trans i18nKey="dateSelector.hideCalendar" />
            ) : (
              <Trans i18nKey="dateSelector.showCalendar" />
            )}
          </CalendarVisibilityToggleText>
        </IonButton>
      </CalendarVisibilityToggleContainer>
      {showCalendar && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CalendarCard>
              <IonDatetime
                firstDayOfWeek={1}
                presentation="date"
                locale="en-US"
                value={dayjs(dateSelected).format('YYYY-MM-DD')}
                onIonChange={(event) => {
                  setDateSelected(parseISOLocal(event.detail.value as string));
                }}
                isDateEnabled={
                  disablePastDates || isCalendarDateEnabled
                    ? isDateEnabled
                    : undefined
                }
              />
            </CalendarCard>
          </motion.div>
        </AnimatePresence>
      )}
    </Space>
  );
}
