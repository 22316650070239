import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonCheckbox, IonInput, IonSearchbar, IonSelect } from '@ionic/react';
import SelectToggleIcon from '../../assets/horizontal-ellipsis.svg';

export const FooterButtonText = styled.span`
  font-size: ${pixelToRem(14)};
  font-weight: 600;
  line-height: 140%;
  color: var(--colors-primary-blue);
  text-align: center;
  margin-top: 8px;
`;

export const FooterButton = styled.button`
  width: 70px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Input = styled(IonInput)`
  border: solid 1px #e0e0e0;
  border-radius: 14px;
`;

export const Searchbar = styled(IonSearchbar)`
  --background: white;
  --color: var(--colors-primary-blue);
  --icon-color: var(--colors-primary-blue);
  --placeholder-color: var(--colors-primary-blue);
  --placeholder-font-style: Inter;
  --placeholder-font-weight: 500;
  --placeholder-opacity: 1;
  border-radius: 10px;
  .searchbar-input-container {
    height: 52px;
  }
`;

export const Select = styled(IonSelect)`
  ::part(icon) {
    width: 30px;
    height: 30px;
    content: url(${SelectToggleIcon});
  }

  ion-item {
    --inner-border-width: unset;
  }
`;

export const Checkbox = styled(IonCheckbox)`
  --size: 18px;
  --checkbox-background-checked: #1890ff;
  --checkmark-width: 3px;
  ::part(container) {
    border-radius: 2px;
    border: 2px solid #1890ff;
  }
`;
