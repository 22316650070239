import React, { memo } from 'react';
import { StyledIonButton } from './BryanButton.styles';
type BryaButtonProps = {
  children: React.ReactNode;
  [x: string]: unknown;
};
const BryaButton: React.FC<BryaButtonProps> = (props) => {
  return (
    <StyledIonButton data-testid="bryan-button" {...props}>
      {props.children}
    </StyledIonButton>
  );
};

export default memo(BryaButton);
