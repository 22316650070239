import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export type TitleProps = {
  fontSize?: string;
  color?: string;
};

export const Container = styled.div`
  .swiper-wrapper {
    padding-bottom: 2rem;
  }
`;

export const SlideOneTitle = styled.span<TitleProps>`
  color: ${({ color }) => (color ? color : '#000000')};
  text-decoration: none;
  font-size: ${pixelToRem(32)};
  margin-left: 4px;
  font-weight: 700;
  line-height: 110%;
`;

export const SlideOneSubtitle = styled.p<TitleProps>`
  color: #616161;
  text-align: center;
  font-size: ${(props) => props.fontSize}px;
`;

export const TitleContainer = styled.div<TitleProps>`
  text-align: center;
  margin-top: 24px;
`;

export const DescriptionContainer = styled.div<TitleProps>`
  display: flex;
`;
export const SliderContainer = styled.div<TitleProps>`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    height: 50vh;
  }
  @media (max-width: 480px) {
    height: 615px;
    svg {
      height: 52vh;
    }
  }
  @media (max-width: 430px) and (min-height: 931px) {
    height: 640px;
    svg {
      height: 50vh;
    }
  }
  @media (max-width: 428px) and (min-height: 842px) {
    height: 585px;
    svg {
      height: 48vh;
    }
  }
  @media (max-width: 428px) and (max-height: 843px) {
    height: 615px;
    svg {
      height: 50vh;
    }
  }
  @media (max-width: 414px) and (max-height: 915px) {
    height: 680px;
    svg {
      height: 50vh;
    }
  }
  @media (max-width: 414px) and (max-height: 896px) {
    height: 650px;
    svg {
      height: 50vh;
    }
  }
  @media (max-width: 414px) and (max-height: 844px) {
    height: 620px;
    svg {
      height: 50vh;
    }
  }
  @media (max-width: 414px) and (max-height: 736px) {
    height: 500px;
    svg {
      height: 42vh;
    }
  }
  @media (max-width: 393px) and (max-height: 852px) {
    height: 620px;
    svg {
      height: 46vh;
    }
  }
  @media (max-width: 393px) and (max-height: 641px) {
    height: 420px;
    svg {
      height: 36vh;
    }
  }
  @media (max-width: 375px) {
    height: 440px;
    svg {
      height: 38vh;
    }
  }
  @media (max-width: 375px) {
    height: 440px;
    svg {
      height: 38vh;
    }
  }
  @media (max-width: 375px) and (max-height: 812px) {
    height: 590px;
    svg {
      height: 49vh;
    }
  }
  @media (max-width: 375px) and (max-height: 667px) {
    height: 440px;
    svg {
      height: 36vh;
    }
  }
  @media (max-width: 360px) and (max-height: 740px) {
    height: 520px;
    svg {
      height: 45vh;
    }
  }
  @media (max-width: 360px) and (max-height: 640px) {
    height: 420px;
    svg {
      height: 36vh;
    }
  }
  @media (max-width: 320px) {
    height: 430px;
    svg {
      height: 36vh;
    }
  }
`;
