import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #23364b;
  margin-top: 24px;
`;
export const MouthContainer = styled.div`
  font-size: ${pixelToRem(12)};
  line-height: 13px;
  text-align: center;
`;
export const DayContainer = styled.div`
  font-weight: 500;
  font-size: ${pixelToRem(20)};
  line-height: 140%;
  text-align: center;
`;
export const WeekDayContainer = styled.div`
  font-weight: 600;
  font-size: ${pixelToRem(12)};
  line-height: 15px;
  text-align: center;
`;
