import { IonContent } from '@ionic/react';
import { Container } from '../../../../components/ActivitiesTab/ActivitiesTab.style';
import { Space } from '../../../../components/Space/Space';
import { DateSelector } from '../../../../components/Date/DateSelector';
import {
  AppointmentActivitiesFilter,
  AppointmentRequestActivitiesStatus,
  GraphqlAppointmentRequest,
  useGetCircleMemberActivitiesQuery,
} from '../../../../graphql/generated';
import { useMemo, useState } from 'react';
import ActivityCardSkeleton from '../../../../components/ActivityCard/ActivityCard.Skeleton';
import { ActivitiesStatusFilters } from '../components/ActivitiesStatusFilters';
import {
  ConfirmedActivitiesEmptyState,
  RequestedActivitiesEmptyState,
} from '../components/ActivitiesEmptyStates';
import { AppointmentRequestsList } from '../components/AppointmentRequestsList';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../providers/authProvider';
import { useDateWithTimezone } from '../../../../utils/hooks/useDateWithTimezone';

const CircleMemberActivities: React.FC = () => {
  const { user } = useAuthContext();
  const { transformDateToTimezone } = useDateWithTimezone();

  const initialIntervalFilter = useMemo(() => {
    return {
      fromDate: transformDateToTimezone(dayjs().startOf('day').toDate()),
      untilDate: transformDateToTimezone(
        dayjs().add(6, 'day').endOf('day').toDate()
      ),
    };
  }, [transformDateToTimezone]);
  const [filters, setFilters] = useState<AppointmentActivitiesFilter>({
    ...initialIntervalFilter,
    status: AppointmentRequestActivitiesStatus.Confirmed,
  });
  // TODO: handle error when defined
  const { data, loading } = useGetCircleMemberActivitiesQuery({
    variables: {
      filter: filters,
      // TODO: implement pagination if necessary
      page: 1,
      pageSize: 100,
    },
    skip: !user,
  });

  const activities = data?.getCircleMemberActivities
    ?.activities as GraphqlAppointmentRequest[];

  return (
    <IonContent>
      <Container style={{ padding: '32px 24px' }}>
        <Space direction="column" size="large">
          <ActivitiesStatusFilters
            selected={filters.status as AppointmentRequestActivitiesStatus}
            onFilterChange={(filter) => {
              if (filter === 'requested') {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  status: AppointmentRequestActivitiesStatus.Requested,
                  fromDate: undefined,
                  untilDate: undefined,
                }));
              } else {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  ...initialIntervalFilter,
                  status: AppointmentRequestActivitiesStatus.Confirmed,
                }));
              }
            }}
          />
          {filters.status === AppointmentRequestActivitiesStatus.Confirmed ? (
            <DateSelector
              range="week"
              disablePastDates
              onSelected={(fromDate, untilDate) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  fromDate: transformDateToTimezone(fromDate),
                  untilDate: transformDateToTimezone(untilDate),
                }))
              }
            />
          ) : null}
          {loading ? (
            <>
              <ActivityCardSkeleton textStyles={{ width: '100%' }} />
              <ActivityCardSkeleton textStyles={{ display: 'none' }} />
              <ActivityCardSkeleton textStyles={{ display: 'none' }} />
            </>
          ) : (
            <>
              {activities?.length === 0 ? (
                filters.status ===
                AppointmentRequestActivitiesStatus.Confirmed ? (
                  <ConfirmedActivitiesEmptyState />
                ) : (
                  <RequestedActivitiesEmptyState />
                )
              ) : null}
              <AppointmentRequestsList
                appointmentRequests={activities}
                withDateDayDivider={
                  filters.status ===
                  AppointmentRequestActivitiesStatus.Confirmed
                }
              />
            </>
          )}
        </Space>
      </Container>
    </IonContent>
  );
};

export default CircleMemberActivities;
