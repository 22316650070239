/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  BodyContainer,
  ButtonContainer,
  IconContainer,
  RecurringText,
  Subtitle,
  Title,
  ViewEditRequestButton,
} from '../ActivityStatus.styles';
import ActivitiesSections from '../../ActivitiesSections/ActivitiesSections';
import {
  arrowForwardOutline,
  calendarClearOutline,
  checkmarkOutline,
  chevronDown,
  clipboardOutline,
  createOutline,
  heartCircleOutline,
  homeOutline,
  locationOutline,
  personOutline,
} from 'ionicons/icons';
import {
  GetCircleRequestQueryHookResult,
  GraphqlServiceRequest,
  useGetCircleRequestQuery,
  useGetCoachVisitQuery,
  useGetMobileSocialEventQuery,
  useGetServiceRequestQuery,
} from '../../../graphql/generated';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import { DollarIcon } from '../../../assets/images/DollarIcon';
import ActivitiesStatusCompleted from '../../../assets/images/ActivitiesStatusCompleted';
import IconActivityCompleted from '../../../assets/images/IconActivityCompleted';
import { useMemo, useState } from 'react';
import ActivityStatusSkeleton from '../StatusContentSkeleton';
import Badge from '../../Badge/Badge';
import { useGetOutstandingBalanceStatus } from '../../../utils/hooks/useGetOutstandingBalanceStatus';
import { ServiceSummary } from '../../ServiceSummary/ServiceSummary';
import { useDirectChatMessage } from '../../../utils/hooks/useDirectChatMessage';
import { formatAddress } from '../../../utils/address/formatAddress';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface CompletedStatusProps {
  title?: string;
  serviceRequestId?: number;
  requestType?: string;
  customerId?: number;
}

const CompletedStatus: React.FC<CompletedStatusProps> = ({
  serviceRequestId,
  requestType,
  customerId: initialCustomerId,
}) => {
  const { directChatMessage: goToChat } = useDirectChatMessage();
  const [isDataReady, setIsDataReady] = useState(false);
  const [showServiceSummaryModal, setShowServiceSummaryModal] = useState(false);
  const i18n = useTranslation();
  const { width } = useWindowDimensions();
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();

  const completedOptions = [];

  const { data: getServiceRequestData } = useGetServiceRequestQuery({
    variables: {
      getServiceRequestId: serviceRequestId ? serviceRequestId : 0,
    },
    skip: requestType !== 'service',
    onCompleted: () => setIsDataReady(true),
  });

  const showBadgeOutstandingBalance = useGetOutstandingBalanceStatus(
    requestType === 'service' ? serviceRequestId : undefined
  );

  const { data: getSocialRequestData } = useGetMobileSocialEventQuery({
    variables: {
      socialEventId: serviceRequestId ? serviceRequestId : 0,
      customerId: initialCustomerId as number,
    },
    skip: requestType !== 'social' || !initialCustomerId,
    onCompleted: () => setIsDataReady(true),
  });

  const { data: getCircleRequestData }: GetCircleRequestQueryHookResult =
    useGetCircleRequestQuery({
      variables: {
        getCircleRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'circle',
      onCompleted: () => setIsDataReady(true),
    });

  const { data: getCoachRequestData } = useGetCoachVisitQuery({
    variables: {
      coachVisitId: serviceRequestId ? serviceRequestId : 0,
    },
    skip: requestType !== 'coachVisit',
    onCompleted: () => setIsDataReady(true),
  });

  const coachData = getCoachRequestData?.getCoachVisit;
  const addressCoach = formatAddress(
    coachData?.Address,
    Boolean(coachData?.Address?.place)
  );
  const coachDateScheduled = coachData?.scheduledAt
    ? formatDateAndTimeInTimezone(coachData?.scheduledAt)
    : '';
  const coachFullName = coachData?.Coach.fullName as string;

  const addressInf = `${getServiceRequestData?.getServiceRequest.Address?.addressLine1}, ${getServiceRequestData?.getServiceRequest.Address?.city}`;
  const ratingInf =
    getServiceRequestData?.getServiceRequest.Agent?.rating?.toFixed() as
      | number
      | '';
  const userFullName = getServiceRequestData?.getServiceRequest.Agent?.User
    .fullName as string;

  const scheduledAt: Date | null | undefined =
    getServiceRequestData?.getServiceRequest.scheduledAt;
  const dateScheduled = scheduledAt
    ? formatDateAndTimeInTimezone(scheduledAt)
    : '';

  const requestByName = `Organized by ${
    getSocialRequestData?.getMobileSocialEvent.RequestedBy.fullName as string
  }`;
  const addressInfEvent = `${getSocialRequestData?.getMobileSocialEvent.addressLine1}, ${getSocialRequestData?.getMobileSocialEvent?.city}`;
  const eventScheduledAt: Date | null | undefined =
    getSocialRequestData?.getMobileSocialEvent.scheduledAt;
  const eventDateScheduled = eventScheduledAt
    ? formatDateAndTimeInTimezone(eventScheduledAt)
    : '';

  const addressCircle = `${getCircleRequestData?.getCircleRequest.Address?.addressLine1}, ${getCircleRequestData?.getCircleRequest.Address?.city}`;
  const circleScheduledAt: Date | null | undefined =
    getCircleRequestData?.getCircleRequest.scheduledAt;
  const circleDateScheduled = circleScheduledAt
    ? formatDateAndTimeInTimezone(circleScheduledAt)
    : '';

  const requestedToCircle = getCircleRequestData?.getCircleRequest.RequestedBy
    .fullName as string;

  const abbrevAvatar = useMemo(() => {
    return {
      firstName:
        getCircleRequestData?.getCircleRequest.RequestedBy?.firstName?.charAt(
          0
        ) || '',
      lastName:
        getCircleRequestData?.getCircleRequest.RequestedBy?.lastName?.charAt(
          0
        ) || '',
    };
  }, [getCircleRequestData]);

  const totalCustomerCost = useMemo(() => {
    const totalCost =
      getServiceRequestData?.getServiceRequest.ServiceRequestPayments?.reduce(
        (total, payment) => total + payment.amount + payment.fee + payment.tax,
        0
      );

    return (
      <>
        {`${i18n.t('completedStatus.totalCustomerCost')} $${totalCost?.toFixed(
          2
        )}`}
        {showBadgeOutstandingBalance && (
          <Badge icon="!" variant="warning">
            {i18n.t('completedStatus.outstandingBalance')}
          </Badge>
        )}
      </>
    );
  }, [getServiceRequestData]);

  if (requestType === 'social') {
    completedOptions.push(
      {
        iconStartName: checkmarkOutline,
        iconEndName: '',
        customIcon: false,
        title: requestByName,
        readOnly: true,
      },
      {
        iconStartName: locationOutline,
        iconEndName: '',
        title: addressInfEvent,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: eventDateScheduled,
        ratingValue: '',
        readOnly: true,
      }
    );
  } else if (requestType === 'circle') {
    if (getCircleRequestData?.getCircleRequest.Address) {
      completedOptions.unshift({
        iconStartName: homeOutline,
        iconEndName: '',
        title: addressCircle,
        ratingValue: '',
        readOnly: true,
      });
    }
    completedOptions.push(
      {
        iconStartName: '',
        iconEndName: '',
        customIcon: false,
        title: requestedToCircle,
        ratingValue: '',
        readOnly: true,
        customClass: false,
        subTitle: 'Connect',
        circleAvatarAbbrev: abbrevAvatar,
        onSubtitleClick: () => {
          const circleAcceptorId =
            getCircleRequestData?.getCircleRequest?.AcceptedBy?.id;
          if (circleAcceptorId) {
            return goToChat(circleAcceptorId);
          }

          return Promise.resolve();
        },
      },
      {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: circleDateScheduled,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: (getCircleRequestData?.getCircleRequest
          ?.Timeline as []) || [{ title: 'test' }],
        ratingValue: '',
        readOnly: false,
      }
    );
  } else if (requestType === 'coachVisit') {
    if (coachFullName) {
      completedOptions.push({
        iconStartName: heartCircleOutline,
        iconEndName: '',
        customIcon: false,
        title: coachFullName,
        ratingValue: '',
        readOnly: true,
      });
    }
    if (coachDateScheduled) {
      completedOptions.push({
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: coachDateScheduled,
        ratingValue: '',
        readOnly: true,
      });
    }
    completedOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: '',
        title: addressCoach,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: clipboardOutline,
        iconEndName: arrowForwardOutline,
        title: 'Notes',
        ratingValue: '',
        readOnly: true,
      }
    );
  } else {
    completedOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: '',
        title: addressInf,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: DollarIcon,
        iconEndName: chevronDown,
        title: totalCustomerCost,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getServiceRequestData?.getServiceRequest
          ?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
    if (userFullName) {
      completedOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: ratingInf ? true : false,
        title: userFullName,
        ratingValue: ratingInf,
        readOnly: false,
        subTitle: 'see profile',
      });
    }
    if (dateScheduled) {
      completedOptions.splice(2, 0, {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: dateScheduled,
        ratingValue: '',
        readOnly: true,
      });
    }
  }

  const handleOpenServiceSummary = () => setShowServiceSummaryModal(true);
  const onCloseServiceSummaryModal = () => setShowServiceSummaryModal(false);

  return (
    <>
      <ActivitiesStatusCompleted width={width} />
      <BodyContainer width={width} className="completed">
        <IconContainer>
          <IconActivityCompleted />
        </IconContainer>
        <Title>
          {requestType === 'social' && i18n.t('eventCompleted')}
          {requestType !== 'social' && i18n.t('requestCompleted')}
        </Title>
        {getServiceRequestData?.getServiceRequest.recurring && (
          <RecurringText>{i18n.t('serviceForm.recurringEvent')}</RecurringText>
        )}
        <Subtitle className="cancelledSubTitle">
          {requestType === 'social' && i18n.t('subTitleEventCompleted')}
          {requestType === 'service' && i18n.t('subTitleRequestCompleted')}
          {requestType === 'circle' && i18n.t('subTitleCircleCompleted')}
          {requestType === 'coachVisit' && i18n.t('coachVisitComplete')}
        </Subtitle>
        {!isDataReady && <ActivityStatusSkeleton />}
        {isDataReady && (
          <ActivitiesSections
            requestType={requestType}
            itemLists={completedOptions}
            category={getServiceRequestData?.getServiceRequest.category}
            agentId={
              getServiceRequestData?.getServiceRequest?.agentId as number
            }
            status={'completed'}
            serviceRequestId={serviceRequestId}
            serviceRequest={
              getServiceRequestData?.getServiceRequest as GraphqlServiceRequest
            }
          />
        )}
        {requestType === 'service' && (
          <ButtonContainer>
            <ViewEditRequestButton
              slot={'primary'}
              className="completed"
              onClick={handleOpenServiceSummary}
            >
              {i18n.t('serviceForm.viewPostVisitSummary')}
            </ViewEditRequestButton>
          </ButtonContainer>
        )}
        {showServiceSummaryModal && (
          <ServiceSummary
            onClose={onCloseServiceSummaryModal}
            serviceRequestId={serviceRequestId}
          />
        )}
      </BodyContainer>
    </>
  );
};

export default CompletedStatus;
