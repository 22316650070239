import { IonButton, IonModal } from '@ionic/react';
import styled, { CSSProperties } from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import ChevronDown from '../../assets/chevron-down-outline.svg';

export type FontProps = {
  fontSize?: string;
  color?: string;
};

export type ButtonProps = {
  width?: number;
};

export type MainProps = {
  height?: CSSProperties['height'];
};

export const MainContainer = styled.div<MainProps>`
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 24px;
  height: ${({ height }) => height};

  form {
    ion-button {
      --color: #fff;
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 22px;
      letter-spacing: -0.4px;
    }

    ion-select::part(icon) {
      content: url(${ChevronDown});
    }
    ion-select::text {
      font-size: ${pixelToRem(14)};
    }
    ion-select-option {
      font-size: ${pixelToRem(14)};
    }
    ion-select {
      --placeholder-color: #212121;
      width: 100%;
      border: 1px solid #212121;
      border-radius: 14px;
      padding: 0 16px;
      min-height: 48px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    ion-label {
      font-style: normal;
      font-weight: 600;
      color: #000000;
      font-size: ${pixelToRem(14)};
      margin-left: 4px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #212121;
  border-radius: 14px;
  padding: 2px 16px;
  margin-bottom: 20px;
  ion-input {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    --color: #212121;
    --placeholder-color: #212121;
    --placeholder-font-weight: 400;
    --padding-start: 4px;
  }
`;

export const Title = styled.p<FontProps>`
  margin-top: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(24)};
  line-height: 120%;
  color: #000000;
  margin-left: 20px;
`;

export const Subtitle = styled.p<FontProps>`
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(18)};
  line-height: 120%;
  color: #000000;
`;

export const Description = styled.p<FontProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: #616161;
  margin-top: 0px;
  margin-left: 4px;
`;

export const BottomButton = styled(IonButton)<ButtonProps>`
  height: 58px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
  --background-activated: #e59b62;
  --border-color: #ea8231;
  --border-radius: 50px;
`;

export const DontAllowButton = styled(IonButton)<ButtonProps>`
  position: absolute;
  bottom: 44px;
  height: 56px;
  width: 327px;
  font-weight: 600;
  text-transform: none;
  --border-color: #ea8231;
  --background: #ffffff;
  --border-radius: 50px;
  --background-activated: #f5f0f0;
`;

export const StyledIonModal = styled(IonModal)``;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 8px;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const ModalHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const RowContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin: 0;
`;

export const SummaryLeft = styled.p<FontProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${pixelToRem(15)};
  color: #000;
`;

export const SummaryRight = styled.p<FontProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(15)};
  color: #000;
`;

export const Wrapper = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ion-textarea {
    background: #ffffff;
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
    border-radius: 14px;
    padding: 16px;
    min-height: 148px;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: #212121;
  }
  .input-container {
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
  }
  ion-select {
    --placeholder-color: #212121;
    width: 100%;
    border: 1px solid ${({ hasError }) => (hasError ? '#ea4255' : '#212121')};
    border-radius: 14px;
    padding: 0 16px;
    min-height: 48px;
    display: flex;
    align-items: center;
  }

  div {
    width: 100%;
  }
  h2 {
    font-weight: 700;
    font-size: ${pixelToRem(20)};
    line-height: 120%;
    color: #212121;
    margin-bottom: 16px;
    &.first-item {
      margin-bottom: 32px;
    }
  }
  .form-error {
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.012em;
    color: #ea4255;
  }
  &.form-actions {
    ion-button {
      font-weight: 600;
      font-size: ${pixelToRem(16)};
      width: 100%;
      text-transform: capitalize;
      &:first-child {
        border-radius: 50px;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
          0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      }
      &:last-child {
        color: #ea8231;
        margin-top: 16px;
      }
    }
  }
`;
