import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  CircleRequestCategoriesEnum,
  GetCircleRequestQuery,
  useUserCancelCircleRequestMutation,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityPending from '../../assets/images/IconActivityPending';
import { Button } from '../Button/Button';
import { useState } from 'react';
import { AlertWithInput } from '../AlertWithInput/AlertWithInput';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestReceivers } from '../ActivityCardItemRequestReceivers/ActivityCardItemRequestReceivers';
import { ActivityCardItemRequestDescription } from '../ActivityCardItemRequestDescription/ActivityCardItemRequestDescription';
import { ActivityCardItemRequestTimeline } from '../ActivityCardItemRequestTimeline/ActivityCardItemRequestTimeline';
import { useHistory } from 'react-router';
import { ActivityCardItemRideInformation } from '../ActivityCardItemRideInformation/ActivityCardItemRideInformation';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { RequestCategoryName } from '../RequestCategoryName/RequestCategoryName';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type CircleRequestQueryResult = GetCircleRequestQuery['getCircleRequest'];

type PendingCustomerActivityCircleRequestProps = {
  circleRequest: CircleRequestQueryResult;
  onCircleRequestCancelled: () => unknown;
};

export const PendingCustomerActivityCircleRequest: React.FC<
  PendingCustomerActivityCircleRequestProps
> = ({ circleRequest, onCircleRequestCancelled }) => {
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(circleRequest.recurring);
  const isRide =
    circleRequest.RequestCategory?.id === CircleRequestCategoriesEnum.Ride;
  const history = useHistory();
  const { globalAlert } = useAppContext();
  const [cancelCircleRequest, { loading }] =
    useUserCancelCircleRequestMutation();

  const handleCancel = () => {
    setShowCancelAlert(true);
  };

  const handleCancelDismiss = () => {
    setShowCancelAlert(false);
  };

  const handleCancelConfirmation = async (cancellationReason: string) => {
    setShowCancelAlert(false);
    try {
      await cancelCircleRequest({
        variables: {
          circleRequestId: circleRequest.id,
          cancellationReason,
        },
      });
      onCircleRequestCancelled();
    } catch (err) {
      logger.error({
        tag: '[PendingCustomerActivityCircleRequest][CancelCircleRequest]',
        message: `Error cancelling CR: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      globalAlert({
        title: t('genericError.title'),
        subtitle: getApolloErrorMessage(err) || t('genericError.subtitle'),
        firstButtonLabel: t('genericError.primaryLabel'),
      });
    }
  };

  return (
    <>
      <PageWithBottomSheetLayout
        title={
          isRecurringEvent ? (
            <RecurringEventPageTitle
              title={
                <RequestCategoryName
                  category={circleRequest.RequestCategory?.name}
                />
              }
            />
          ) : (
            <RequestCategoryName
              category={circleRequest.RequestCategory?.name}
            />
          )
        }
        background="var(--colors-gradient-purple)"
        bottomSheetBackground="var(--colors-gradient-purple-sheet)"
        bottomSheetTitle={
          <>
            <IconActivityPending />
            {t('PendingCustomerActivityCircleRequest.SheetTitle')}
          </>
        }
        bottomSheetSubtitle={
          isRecurringEvent
            ? t('PendingCustomerActivityCircleRequest.SheetSubtitle')
            : undefined
        }
        startSlotHeaderAction={
          <HeaderBackButton onClick={() => history.goBack()} />
        }
      >
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <BodyText>
              {t('PendingCustomerActivityCircleRequest.SheetBody')}
            </BodyText>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <CardList>
              {isRide ? (
                <ActivityCardItemRideInformation ride={circleRequest.ride} />
              ) : (
                <ActivityCardItemAddress address={circleRequest.Address} />
              )}
              <ActivityCardItemRequestReceivers
                receivers={circleRequest.AskTo}
              />
              <ActivityCardItemRequestDescription
                description={circleRequest.description}
              />
              <ActivityCardItemRequestTimeline
                timeline={circleRequest.Timeline}
              />
            </CardList>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <Button
              color="secondary-purple"
              onClick={handleCancel}
              loading={loading}
            >
              {t('PendingCustomerActivityCircleRequest.SecondaryCta')}
            </Button>
          </IonRow>
        </IonGrid>
      </PageWithBottomSheetLayout>
      <AlertWithInput
        isOpen={showCancelAlert}
        title={t(
          'PendingCustomerActivityCircleRequest.CancelRequestAlertTitle'
        )}
        subtitle={t(
          'PendingCustomerActivityCircleRequest.CancelRequestAlertSubtitle'
        )}
        firstButtonLabel={t(
          'PendingCustomerActivityCircleRequest.CancelRequestAlertFirstButtonLabel'
        )}
        secondButtonLabel={t(
          'PendingCustomerActivityCircleRequest.CancelRequestAlertSecondButtonLabel'
        )}
        onSubmit={handleCancelConfirmation}
        onCancel={handleCancelDismiss}
        placeholder={t(
          'PendingCustomerActivityCircleRequest.CancelRequestAlertPlaceholder'
        )}
      />
    </>
  );
};
