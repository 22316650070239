import { IonLabel, IonRadio, IonRadioGroup, IonText } from '@ionic/react';
import React, { ReactNode, forwardRef } from 'react';
import { ChangeHandler, FieldErrors, FieldValues } from 'react-hook-form';

interface RadioGroupOption {
  label: ReactNode;
  value: string | number | boolean;
}

interface IFormRadioGroup {
  name: string;
  label?: ReactNode;
  errors: FieldErrors<FieldValues>;
  options?: RadioGroupOption[];
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  containerClassName?: string;
}

type IonRadioGroupProps = React.ComponentProps<typeof IonRadioGroup>;

/**
 * FormRadioGroup is an abstraction to integrate native ionic radio group and react-hook-form
 */
const FormRadioGroup = forwardRef(
  (
    {
      name,
      label = null,
      options,
      onChange,
      onBlur,
      errors,
      containerClassName,
      ...rest
    }: IFormRadioGroup & IonRadioGroupProps,
    ref: React.Ref<HTMLIonRadioGroupElement>
  ) => {
    return (
      <div className={containerClassName || 'ion-margin-bottom'}>
        {typeof label === 'string' ? <IonLabel>{label}</IonLabel> : label}
        <IonRadioGroup {...rest} ref={ref} name={name} onIonChange={onChange}>
          {options?.map((option) => (
            <React.Fragment key={String(option.value)}>
              <IonRadio
                className="ion-margin-bottom"
                mode="md"
                value={option.value}
                labelPlacement="end"
              >
                {option.label}
              </IonRadio>
              <br />
            </React.Fragment>
          ))}
        </IonRadioGroup>
        {name && errors && errors[name] ? (
          <IonText color="primary-red">
            {errors[name]?.message as string}
          </IonText>
        ) : null}
      </div>
    );
  }
);

FormRadioGroup.displayName = 'FormRadioGroup';

export default FormRadioGroup;
