import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { GetMobileSocialEventQuery } from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import { locationOutline } from 'ionicons/icons';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { useHistory } from 'react-router';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { ActivityCardItemOrganizedBy } from '../ActivityCardItemOrganizedBy/ActivityCardItemOrganizedBy';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type SocialEventQueryResult = GetMobileSocialEventQuery['getMobileSocialEvent'];

type CancelledCustomerActivitySocialEventProps = {
  socialEvent: SocialEventQueryResult;
};

export const CancelledCustomerActivitySocialEvent: React.FC<
  CancelledCustomerActivitySocialEventProps
> = ({ socialEvent }) => {
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(socialEvent.recurring);
  const history = useHistory();

  const address = {
    addressLine1: socialEvent.addressLine1,
    addressLine2: socialEvent.addressLine2,
    zipCode: socialEvent.zipCode,
    city: socialEvent.city,
    state: socialEvent.state,
    country: socialEvent.country,
    place: socialEvent.place,
  };

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle title={socialEvent.eventName} />
        ) : (
          socialEvent.eventName
        )
      }
      background="var(--colors-gradient-red)"
      bottomSheetBackground="var(--colors-gradient-red-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityCancelled />
          {t('CancelledCustomerActivitySocialEvent.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('CancelledCustomerActivitySocialEvent.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <BodyText>
            {t('CancelledCustomerActivitySocialEvent.SheetBody')}
          </BodyText>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <CardList>
            <ActivityCardItemAddress icon={locationOutline} address={address} />
            <ActivityCardItemOrganizedBy organizer={socialEvent.RequestedBy} />
            <ActivityCardItemRequestScheduledAt
              scheduledAt={socialEvent.scheduledAt}
            />
            {/* TODO: display timeline when provided by the BE */}
          </CardList>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
