import styled from 'styled-components';

export const Card = styled.div`
  box-shadow: 0px 9px 28px 8px #00000012;
  border-radius: 10px;
  width: 100%;
`;

export const Divider = styled.hr`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
`;
