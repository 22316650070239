/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext } from 'react';
import {
  Exact,
  GetBryaPartnerChatIdQuery,
  GetBryaSupportChatIdQuery,
  GetBryaTeamChatIdQuery,
  useGetBryaPartnerChatIdLazyQuery,
  useGetBryaSupportChatIdLazyQuery,
  useGetBryaTeamChatIdLazyQuery,
} from '../../graphql/generated';
import { useAppContext } from '../../providers/appContextProvider';
import { useHistory } from 'react-router';
import { TalkJsContext } from '../../providers/talkJsContext';
import { Trans } from 'react-i18next';
import { LazyQueryResultTuple } from '@apollo/client';
import { getApolloErrorMessage } from '../apollo/errors';
import { logger } from '../../logger';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';

export const useTalkJsChat = () => {
  const { globalAlert } = useAppContext();
  const history = useHistory();
  const TalkJs = useContext(TalkJsContext);

  const [
    getBryaPartnerChatQuery,
    { loading: getBryaPartnerChatQueryLoading },
  ]: LazyQueryResultTuple<
    GetBryaPartnerChatIdQuery,
    Exact<{
      [key: string]: never;
    }>
  > = useGetBryaPartnerChatIdLazyQuery();

  const [
    getBryaSupportChatQuery,
    { loading: getBryaSupportChatQueryLoading },
  ]: LazyQueryResultTuple<
    GetBryaSupportChatIdQuery,
    Exact<{
      [key: string]: never;
    }>
  > = useGetBryaSupportChatIdLazyQuery();

  const [
    getBryaTeamChatQuery,
    { loading: getBryaTeamChatQueryLoading },
  ]: LazyQueryResultTuple<
    GetBryaTeamChatIdQuery,
    Exact<{
      [key: string]: never;
    }>
  > = useGetBryaTeamChatIdLazyQuery();

  const hasTalkJsSession = () => {
    if (!TalkJs?.session) {
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: <Trans i18nKey="genericError.subtitle" />,
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });

      return false;
    }

    return true;
  };

  const handleGetBryaPartnerChat = async () => {
    if (hasTalkJsSession()) {
      const { data, error } = await getBryaPartnerChatQuery();

      if (data) {
        history.push(
          ROUTES.CHATBOX.replace(
            PATH_PARAMS.CONVERSATION_ID,
            data.getBryaPartnerChatId.id
          )
        );
      } else {
        globalAlert({
          title: <Trans i18nKey="noBryaPartnerModal.title" />,
          subtitle: <Trans i18nKey="noBryaPartnerModal.description" />,
          firstButtonLabel: (
            <Trans i18nKey="noBryaPartnerModal.contactSupport" />
          ),
          firstButtonAction: handleGetBryaSupportChat,
          secondButtonLabel: <Trans i18nKey="noBryaPartnerModal.cancel" />,
        });
        logger.error({
          tag: '[useTalkJsChat]',
          message: 'Getting getBryaPartnerChatQuery failed',
          error: error as Error,
        });
      }
    }
  };

  const handleGetBryaSupportChat = async () => {
    if (hasTalkJsSession()) {
      const { data, error } = await getBryaSupportChatQuery();

      if (data) {
        history.push(
          ROUTES.CHATBOX.replace(
            PATH_PARAMS.CONVERSATION_ID,
            data.getBryaSupportChatId.id
          )
        );
      } else {
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(error) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
        logger.error({
          tag: '[useTalkJsChat]',
          message: 'Getting getBryaSupportChatQuery failed',
          error: error as Error,
        });
      }
    }
  };

  const handleGetBryaTeamChat = async () => {
    if (hasTalkJsSession()) {
      const { data, error } = await getBryaTeamChatQuery();

      if (data) {
        history.push(
          ROUTES.CHATBOX.replace(
            PATH_PARAMS.CONVERSATION_ID,
            data.getBryaTeamChatId.id
          )
        );
      } else {
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(error) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
        logger.error({
          tag: '[useTalkJsChat]',
          message: 'Getting getBryaTeamChatQuery failed',
          error: error as Error,
        });
      }
    }
  };

  return {
    handleGetBryaPartnerChat,
    handleGetBryaSupportChat,
    handleGetBryaTeamChat,
    getBryaPartnerChatQueryLoading,
    getBryaSupportChatQueryLoading,
    getBryaTeamChatQueryLoading,
  };
};
