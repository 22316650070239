import { IonCard, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { personAddOutline } from 'ionicons/icons';
import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';
import { ReactNode } from 'react';
import { ArrowRight } from '../../../assets/images/ArrowRight';

const CardTitle = styled.span`
  font-size: ${() => pixelToRem(18)};
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.012em;
  color: var(--colors-primary-blue);
`;

interface ActionableCardProps {
  onAction: () => void;
  actionText: ReactNode;
  iconSrc?: string;
}
export const ActionableCard: React.FC<ActionableCardProps> = ({
  onAction,
  actionText,
  iconSrc,
}) => {
  return (
    <IonCard className="ion-no-margin" onClick={onAction}>
      <IonGrid>
        <IonRow className="ion-padding ion-align-items-center">
          <IonCol>
            <IonIcon
              src={iconSrc || personAddOutline}
              style={{
                minWidth: '30px',
                fontSize: '30px',
              }}
            />
          </IonCol>
          <IonCol className="ion-text-start" size="9">
            <CardTitle>{actionText}</CardTitle>
          </IonCol>
          <IonCol className="ion-text-end">
            <ArrowRight
              style={{
                minWidth: '36px',
                minHeight: '36px',
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};
