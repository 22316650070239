import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { chevronDownOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

// NOTE: Accordion implementation can move to components folder and easy reusable
interface AccordionProps {
  expanded: boolean;
}
export const AccordionContent = styled.div<AccordionProps>`
  padding: ${({ expanded }) => (expanded ? '32px 0px' : '0')};
  max-height: ${({ expanded }) => (expanded ? '100vh' : '0vh')};
  transition: all 350ms cubic-bezier(0.25, 0.8, 0.5, 1);
`;

export const AccordionIcon: React.FC<AccordionProps> = ({ expanded }) => (
  <IonIcon
    src={chevronDownOutline}
    style={{
      fontSize: '18px',
      minWidth: '18px',
      color: 'var(--colors-primary-blue)',
      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'all 350ms cubic-bezier(0.25, 0.8, 0.5, 1)',
    }}
  />
);

export const ActivityDetailItemDivider = styled.hr`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin: 0;
`;

export const ActivityDetailItemDescription = styled.span`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  color: var(--colors-primary-blue);
  flex-grow: 1;
  display: flex;
  align-items: center;
  svg:last-child,
  ion-icon:last-child {
    margin-left: 0.38rem;
  }
`;

export const ActivityDetailsCard = styled.div`
  box-shadow: 0px 9px 28px 8px #00000012;
  padding: 16px;
  border-radius: 10px;
`;

export const CardItem = styled.div`
  flex-grow: 1;
  background: linear-gradient(90deg, #fff 0%, #f6f6f6 57.29%, #fff 100%);
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  min-height: 78px;
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const CardItemTitle = styled.span`
  font-size: ${() => pixelToRem(16)};
  font-weight: 600;
  line-height: 22px;
  color: var(--colors-primary-blue);
`;

export const CardItemDescription = styled.span`
  font-size: ${() => pixelToRem(14)};
  font-weight: 400;
  line-height: 20px;
  color: var(--neutral);
`;

export const ConnectText = styled.span`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  color: #595959;
`;

export const ConnectButton = styled.button`
  font-family: Inter;
  font-size: ${() => pixelToRem(14)};
  font-weight: 600;
  line-height: 20px;
  color: var(--colors-primary-orange);
  background-color: unset;
  padding: 0;
  width: fit-content;
`;

export const TimelineEventMonth = styled.span`
  font-size: ${() => pixelToRem(12)};
  font-weight: 500;
  line-height: 13px;
  color: var(--colors-primary-blue);
  text-transform: uppercase;
`;

export const TimelineEventDayNumber = styled.span`
  font-size: ${() => pixelToRem(20)};
  font-weight: 500;
  line-height: 28px;
  color: var(--colors-primary-blue);
`;

export const TimelineEventDayName = styled.span`
  font-size: ${() => pixelToRem(12)};
  font-weight: 600;
  line-height: 15px;
  color: var(--colors-primary-blue);
  text-transform: uppercase;
`;

export const TimelineEventLine = styled.span`
  border-left: 1px solid #d9d9d9;
  height: 17px;
`;
