import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import Circle from '../../assets/circle.svg';

export const Container = styled.div`
  padding: 0 24px;
  h1 {
    color: var(--body-text-900, #212121);
    font-size: ${pixelToRem(22)};
    font-weight: 500;
  }
`;
export const Content = styled.div`
  overflow: scroll;
  height: 80vh;
  padding-bottom: 180px;
`;

export const ReviewsContainer = styled.div`
  p {
    color: var(--base-1, #392a27);
    font-size: ${pixelToRem(16)};
    font-weight: 500;
    line-height: 140%;
  }
`;

export const EmptyReviewsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3a3a3a;
  justify-content: center;

  h2 {
    margin-top: 24px;
    font-size: ${pixelToRem(24)};
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
    font-size: ${pixelToRem(16)};
    font-weight: 400;
    line-height: 140%;
    color: #3a3a3a;
    max-width: 280px;
    text-align: center;
  }
`;

export const Wrapper = styled.div<{
  justifyContent?: 'space-between' | 'space-around' | 'center' | 'flex-start';
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  width: 100%;

  span {
    color: var(--body-text-700, #616161);
    font-size: ${pixelToRem(18)};
    font-weight: 500;
    line-height: 140%;
    margin-left: 8px;
  }

  small {
    color: var(--base-2, #92847b);
    font-size: ${pixelToRem(12)};
    font-weight: 400;
  }
`;

export const ReviewCard = styled.div`
  h2 {
    color: var(--base-1, #392a27);
    font-size: ${pixelToRem(16)};
    font-weight: 700;
    line-height: 140%;
  }
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--body-text-400, #bdbdbd);
  background: #fff;

  margin-bottom: 16px;
`;

export const SelectContainer = styled.div`
  margin-left: 20px;
  ion-select {
    --placeholder-color: #424242;
    --placeholder-opacity: 1;
    justify-content: center;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.408px;
    .item-inner {
      border: none;
    }
  }

  ion-select-option,
  .select-interface-option,
  ion-label {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.408px;
    max-width: 290px !important;
  }
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }
  ion-select::part(text) {
    width: fit-content;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-select::part(icon) {
    width: 46px;
    height: 46px;
    content: url(${Circle});
  }
  ion-radio {
    display: flex;
    align-items: center;
  }
  ion-popover::part(content) {
    --width: 200px;
  }
`;
