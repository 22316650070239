import React from 'react';
import {
  UserCard,
  UserCardContainer,
  UserCardName,
  UserCardNameContainer,
  UserCardSubtitle,
} from './WhoIsThisFor.styles';
import { IonSkeletonText } from '@ionic/react';

const UserCardSkeleton: React.FC = () => {
  return (
    <UserCard>
      <UserCardContainer>
        <IonSkeletonText
          animated
          style={{ width: '3.5rem', height: '3.5rem', borderRadius: '50%' }}
        />
        <UserCardNameContainer>
          <UserCardName>
            <IonSkeletonText animated style={{ width: '100px' }} />
          </UserCardName>
          <UserCardSubtitle>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </UserCardSubtitle>
        </UserCardNameContainer>
        <IonSkeletonText
          animated
          style={{ width: '10%', marginLeft: 'auto' }}
        />
      </UserCardContainer>
    </UserCard>
  );
};

export default UserCardSkeleton;
