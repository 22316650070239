import { useTranslation } from 'react-i18next';
import {
  BodyContainer,
  ConnectButton,
  ConnectButtonText,
  UserContent,
  HeaderContentColumn,
  HeaderContentFixed,
  InfoText,
  TitleHeader,
  Block,
} from './CoachProfile.styles';

import { IonCard, IonPage, IonSkeletonText } from '@ionic/react';
import FeedbackTab from '../../components/FeedbackTab/FeedbackTab';
import { useGetCoachProfileQuery } from '../../graphql/generated';
import HeaderWithBackground from '../../components/HeaderWithBackground/HeaderWithBackground';
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar';
import { useTalkJsChat } from '../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../../components/FullScreenLoader/FullScreenLoader';

const CoachProfile: React.FC = () => {
  const i18n = useTranslation();

  const { handleGetBryaPartnerChat, getBryaPartnerChatQueryLoading } =
    useTalkJsChat();

  const { data: coachProfile, loading } = useGetCoachProfileQuery();

  return (
    <>
      {getBryaPartnerChatQueryLoading && <FullScreenLoader />}
      <IonPage>
        <BodyContainer>
          <HeaderWithBackground background="#23364B" />
          <HeaderContentFixed />
          <UserContent>
            <ProfileAvatar
              loading={loading}
              firstName={coachProfile?.getCoachProfile?.firstName as string}
              lastName={coachProfile?.getCoachProfile?.lastName as string}
              src={coachProfile?.getCoachProfile?.profilePicture as string}
            />

            <HeaderContentColumn>
              <TitleHeader>
                {loading && (
                  <IonSkeletonText
                    animated
                    style={{ background: '#fff', height: '1rem' }}
                  />
                )}
                {coachProfile?.getCoachProfile &&
                  coachProfile?.getCoachProfile.fullName}
              </TitleHeader>
              <ConnectButton size="small">
                <ConnectButtonText onClick={handleGetBryaPartnerChat}>
                  {i18n.t('connect')}
                </ConnectButtonText>
              </ConnectButton>
            </HeaderContentColumn>
          </UserContent>

          <Block />
          <IonCard className="coach-card-description">
            {loading && <IonSkeletonText animated style={{ height: '5rem' }} />}
            {coachProfile?.getCoachProfile && (
              <InfoText>{coachProfile?.getCoachProfile.bio}</InfoText>
            )}
          </IonCard>
          <IonCard className="coach-card-feedback">
            {coachProfile?.getCoachProfile && (
              <FeedbackTab
                coachId={coachProfile?.getCoachProfile.id}
                coachName={coachProfile?.getCoachProfile.fullName}
              />
            )}
          </IonCard>
        </BodyContainer>
      </IonPage>
    </>
  );
};

export default CoachProfile;
