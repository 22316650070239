import { IonInput } from '@ionic/react';
import styled from 'styled-components';

export const CustomIonInput = styled(IonInput)`
  height: 48px;
  background: #ffffff;
  border: 1px solid #212121;
  border-radius: 14px;
  margin-bottom: 16px;
  margin-top: 8px;
  --placeholder-color: rgba(158, 158, 158, 1);
  color: rgba(33, 33, 33, 1);
  padding-inline-start: 12px !important;
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;
