/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useContext, useEffect } from 'react';
import {
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/react';
import { useSaveFirebaseTokenMutation } from '../graphql/generated';
import { AuthContext } from '../providers/authProvider';
import { logger } from '../logger';
import { Device } from '@capacitor/device';

const PushNotificationsService: React.FC = () => {
  const [saveFirebaseToken] = useSaveFirebaseTokenMutation();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const isFeatureEnabled = isPlatform('hybrid');
    const registerPushNotifications = async () => {
      if (isFeatureEnabled) {
        if (isPlatform('android')) {
          // channel names to be defined
          await PushNotifications.createChannel({
            id: 'my-channel',
            name: 'My Channel',
            description: 'My notification channel',
            importance: 5, // HIGH importance
            visibility: 1, // PUBLIC visibility
          });
        }
        try {
          await PushNotifications.addListener('registration', async (token) => {
            // Update user firebase Token
            if (user?.id) {
              const id = await Device.getId();
              await saveFirebaseToken({
                variables: {
                  uuid: id.identifier,
                  firebaseToken: token.value,
                },
              });
            }
          });
        } catch (err) {
          logger.error({
            tag: '[PushNotificationsService]',
            message: 'Error listening to registration event',
            error: err as Error,
          });
        }

        // Listen for incoming notifications
        try {
          await PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              logger.info({
                tag: '[PushNotificationsService]',
                message: `Push notification received: ${JSON.stringify(
                  notification
                )}`,
              });
            }
          );
        } catch (err) {
          logger.error({
            tag: '[PushNotificationsService]',
            message: 'Error listening to pushNotificationReceived event',
            error: err as Error,
          });
        }

        // Listen for click on notification
        try {
          await PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification) => {
              logger.info({
                tag: '[PushNotificationsService]',
                message: `Push notification action performed: ${JSON.stringify(
                  notification
                )}`,
              });
            }
          );
        } catch (err) {
          logger.error({
            tag: '[PushNotificationsService]',
            message: 'Error listening to pushNotificationActionPerformed event',
            error: err as Error,
          });
        }
      }
    };

    void registerPushNotifications();

    // Cleanup function to remove event listeners
    return () => {
      const cleanup = async () => {
        try {
          await PushNotifications.removeAllListeners();
        } catch (err) {
          logger.error({
            tag: '[PushNotificationsService]',
            message: 'Error removing all listeners',
            error: err as Error,
          });
        }
      };

      if (isFeatureEnabled) {
        void cleanup();
      }
    };
  }, [user]);

  return null;
};

export default PushNotificationsService;
