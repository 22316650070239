import {
  IonIcon,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import React, { ReactNode, forwardRef } from 'react';
import { Container } from './FormSelect.styles';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { ChangeHandler, FieldErrors, FieldValues } from 'react-hook-form';

interface SelectOption {
  label: ReactNode;
  value: string | number;
}

interface IFormSelect {
  name: string;
  label: ReactNode;
  placeholder?: string;
  errors: FieldErrors<FieldValues>;
  options?: SelectOption[];
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  leftIcon?: string;
  containerClassName?: string;
}

/**
 * FormSelect is an abstraction to integrate native ionic select and react-hook-form
 */
const FormSelect = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      options,
      onChange,
      onBlur,
      errors,
      leftIcon,
      containerClassName,
    }: IFormSelect,
    ref: React.Ref<HTMLIonSelectElement>
  ) => {
    return (
      <Container className={containerClassName || 'ion-margin-bottom'}>
        {typeof label === 'string' ? <IonLabel>{label}</IonLabel> : label}
        <IonSelect
          ref={ref}
          name={name}
          interface="popover"
          placeholder={placeholder}
          onIonChange={onChange}
          onIonBlur={onBlur}
          toggleIcon={chevronDownOutline}
          expandedIcon={chevronUpOutline}
          interfaceOptions={{ size: 'cover' }}
        >
          {leftIcon && <IonIcon slot="label" icon={leftIcon} />}
          {options?.map((option) => (
            <IonSelectOption key={option.value} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        {name && errors && errors[name] ? (
          <IonText color="primary-red">
            {errors[name]?.message as string}
          </IonText>
        ) : null}
      </Container>
    );
  }
);

FormSelect.displayName = 'FormSelect';

export default FormSelect;
