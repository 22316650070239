import { gql } from '@apollo/client';

export const GET_AGENT = gql`
  query GetCustomerAgent($agentId: Int!) {
    getCustomerAgent(agentId: $agentId) {
      userId
      rating
      serviceTypes
      serviceTypeInfo {
        id
        name
      }
      favorite
      Company {
        name
        website
        city
        state
      }
      User {
        firstName
        lastName
        fullName
        bio
        profilePicture
      }
      CustomerFavoriteAgents {
        customerId
        Customer {
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;
export const GET_AGENT_REVIEWS = gql`
  query Reviews($agentId: Int!, $category: ServiceRequestCategoriesEnum) {
    getAgentReviews(agentId: $agentId, category: $category) {
      reviews {
        comment
        rating
        ReviewedBy {
          firstName
          createdAt
        }
        serviceRequestCategory
      }
    }
  }
`;

export const FAVORITE_AGENT = gql`
  mutation FavoriteAgent(
    $serviceType: ServiceRequestCategoriesEnum!
    $agentId: Int!
  ) {
    favoriteAgent(serviceType: $serviceType, agentId: $agentId) {
      userId
    }
  }
`;

export const UNFAVORITE_AGENT = gql`
  mutation UnfavoriteAgent(
    $serviceType: ServiceRequestCategoriesEnum!
    $agentId: Int!
  ) {
    unfavoriteAgent(serviceType: $serviceType, agentId: $agentId) {
      userId
    }
  }
`;
