import React, { useCallback, useEffect, useState } from 'react';
import {
  CalendarContainer,
  Container,
  SlotContent,
  StyledIonContent,
} from './AvailabilityDates.styles';
import { IonIcon, IonDatetime } from '@ionic/react';
import { chevronForward, chevronBack } from 'ionicons/icons';
import AvailabilitySlot from '../AvailabilitySlot/AvailabilitySlot';
import { IAvailabilitySlot, IAvailabilityTime } from '../../types';
import { AnimatePresence, motion } from 'framer-motion';
import dayjs from 'dayjs';
import { generateRange } from '../../utils/date';
import { ServiceRequestTentativeSchedule } from '../../graphql/generated';

enum DATERANGE {
  MONTHS = 31 * 6,
  WITHIN_THREE_DAYS = 3,
  WITHIN_A_WEEK = 7,
}
const AvailabilityDates: React.FC<{
  setSelectedAvailability: (params: []) => void;
  selectedAvailability: IAvailabilityTime[];
  tentativeSchedule: ServiceRequestTentativeSchedule;
}> = ({ setSelectedAvailability, selectedAvailability, tentativeSchedule }) => {
  const [availabilityData, setAvailabilityData] = useState<IAvailabilitySlot[]>(
    []
  );
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [DatesPerPage] = useState(7);
  const indexOfLastDate = currentPage * DatesPerPage;
  const indexOfFirstDate = indexOfLastDate - DatesPerPage;
  const currentAvailability = availabilityData.slice(
    indexOfFirstDate,
    indexOfLastDate
  );

  const getWeekDates = useCallback(() => {
    let range = DATERANGE.MONTHS;
    if (tentativeSchedule === ServiceRequestTentativeSchedule.WithinDays) {
      range = DATERANGE.WITHIN_THREE_DAYS;
    } else if (
      tentativeSchedule === ServiceRequestTentativeSchedule.WithinWeeks
    ) {
      range = DATERANGE.WITHIN_A_WEEK;
    }

    const daysOfWeek = generateRange(
      dayjs().startOf('day'),
      dayjs().add(range, 'days'),
      1,
      'day'
    );

    const formattedWeekDays = daysOfWeek.map((day: dayjs.Dayjs) => {
      return {
        fullDate: day.format('YYYY-MM-DD'),
        weekDay: day.format('ddd DD'),
        monthWeekDay: day.format('MMM DD'),
      };
    });

    setAvailabilityData(
      formattedWeekDays.map(({ weekDay, fullDate, monthWeekDay }) => {
        return {
          id: fullDate,
          weekDay,
          fullDate,
          monthWeekDay,
          isDaySelected: false,
          additionalTimeSlots: [],
        };
      })
    );

    setCurrentPage(1);
  }, [tentativeSchedule]);

  const handleChangeWeek = useCallback(
    (nextWeek?: boolean): void => {
      if (nextWeek) {
        setCurrentPage(currentPage + 1);
      } else {
        setCurrentPage(currentPage - 1);
      }
    },
    [currentPage]
  );

  {
    /* TODO: add in another pr */
  }
  // const handleCalendar = () => {
  //   if (showCalendar) {
  //     setShowCalendar(false);
  //   } else {
  //     setShowCalendar(true);
  //   }
  // };

  useEffect(() => {
    getWeekDates();
    return () => {
      setShowCalendar(false);
    };
  }, [tentativeSchedule]);

  return (
    <>
      <Container>
        <IonIcon
          icon={chevronBack}
          onClick={() => handleChangeWeek()}
          style={{
            opacity: currentPage === 1 ? 0.3 : 1,
            pointerEvents: currentPage === 1 ? 'none' : 'unset',
          }}
        />

        <>
          {currentAvailability.at(0)?.monthWeekDay} -{' '}
          {currentAvailability.at(-1)?.monthWeekDay}
        </>
        <IonIcon
          icon={chevronForward}
          onClick={() => handleChangeWeek(true)}
          style={{
            opacity: currentAvailability.length < 7 ? 0.3 : 1,
            pointerEvents: currentAvailability.length < 7 ? 'none' : 'unset',
          }}
        />
      </Container>
      <br />
      {/* TODO: add in another pr */}
      {/* <ShowCalendarContainer>
        <IonButton
          className="show-calendar"
          fill="clear"
          color="#23364b"
          onClick={handleCalendar}
        >
          {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
        </IonButton>
      </ShowCalendarContainer> */}

      <StyledIonContent className="ion-padding">
        <CalendarContainer>
          {showCalendar && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <IonDatetime
                  firstDayOfWeek={0}
                  presentation="date"
                  locale="en-US"
                  onIonChange={() => {
                    // setCalendarValue(
                    //   new Date(detail.value as string).getDate()
                    // );
                    setTimeout(() => {
                      getWeekDates();
                    }, 300);
                  }}
                />
              </motion.div>
            </AnimatePresence>
          )}
        </CalendarContainer>
        {currentAvailability?.map((time: IAvailabilitySlot) => (
          <SlotContent key={time.id}>
            <AvailabilitySlot
              setAvailabilityData={setAvailabilityData}
              availabilityData={availabilityData}
              data={time}
              setSelectedAvailability={setSelectedAvailability}
              selectedAvailability={selectedAvailability}
            />
          </SlotContent>
        ))}
      </StyledIonContent>
    </>
  );
};

export default AvailabilityDates;
