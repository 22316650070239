import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

interface ContainerProps {
  direction: CSSProperties['flexDirection'];
  size: string | number | undefined;
  justify: CSSProperties['justifyContent'];
  align: CSSProperties['alignItems'];
  wrap: CSSProperties['flexWrap'];
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => props.size};
  flex-wrap: ${(props) => props.wrap};
`;

const SIZE_ENUM: Record<string, string> = {
  small: '8px',
  medium: '16px',
  large: '32px',
};

interface SpaceProps {
  direction: CSSProperties['flexDirection'];
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  size?: 'small' | 'medium' | 'large' | string | number;
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  wrap?: CSSProperties['flexWrap'];
  style?: React.CSSProperties;
  onClick?: () => void;
}
export function Space({
  direction,
  children,
  size,
  style,
  justify,
  align,
  wrap,
  onClick,
}: SpaceProps) {
  return (
    <Container
      onClick={onClick}
      direction={direction}
      size={size ? SIZE_ENUM[size] || size : undefined}
      style={style}
      justify={justify}
      align={align}
      wrap={wrap}
    >
      {children}
    </Container>
  );
}
