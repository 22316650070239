import { GetCircleRequestQuery } from '../../graphql/generated';
import { IonCol, IonDatetime, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { calendarClearOutline, createOutline } from 'ionicons/icons';
import { Accordion } from '../Accordion/Accordion';
import { useState } from 'react';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

type ActivityCardItemRequestScheduledAtRowProps = {
  scheduledAtFormatted: string;
};
const ActivityCardItemRequestScheduledAtRow: React.FC<
  ActivityCardItemRequestScheduledAtRowProps
> = ({ scheduledAtFormatted }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="auto" className="ion-align-self-center">
          <IonIcon
            icon={calendarClearOutline}
            color="var(--colors-primary-blue)"
            style={{ width: '24px', height: '24px', marginInlineEnd: '8px' }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {scheduledAtFormatted}
          </BodyText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

type ScheduledAt = GetCircleRequestQuery['getCircleRequest']['scheduledAt'];

type ActivityCardItemRequestScheduledAtProps = {
  scheduledAt: ScheduledAt;
  onDateSelected?: (date: Date) => unknown;
  // TODO: support timezone
};
export const ActivityCardItemRequestScheduledAt: React.FC<
  ActivityCardItemRequestScheduledAtProps
> = ({ scheduledAt, onDateSelected }) => {
  const {
    formatDateAndTimeInTimezone,
    formatIonDateTimeValueInTimezone,
    transformDateToTimezone,
  } = useDateWithTimezone();

  const [dateSelected, setDateSelected] = useState<string>('');
  const dateSelectedFormatted = dateSelected
    ? formatDateAndTimeInTimezone(
        // NOTE: first transform to match date and time selected by the user
        // in the target timezone
        transformDateToTimezone(parseISOLocal(dateSelected))
      )
    : '';
  if (onDateSelected) {
    return (
      <Accordion
        header={
          <ActivityCardItemRequestScheduledAtRow
            scheduledAtFormatted={
              dateSelectedFormatted || formatDateAndTimeInTimezone(scheduledAt)
            }
          />
        }
        content={
          <IonDatetime
            className="ion-margin-vertical"
            locale="en-US"
            onIonChange={(e) => {
              setDateSelected(e.detail.value as string);
              onDateSelected(
                transformDateToTimezone(parseISOLocal(e.detail.value as string))
              );
            }}
            minuteValues="0,15,30,45"
            value={
              dateSelected || formatIonDateTimeValueInTimezone(scheduledAt)
            }
          />
        }
        icon={createOutline}
        iconSize={'24px'}
        animateIcon={false}
      />
    );
  }

  return (
    <ActivityCardItemRequestScheduledAtRow
      scheduledAtFormatted={formatDateAndTimeInTimezone(scheduledAt)}
    />
  );
};
