interface CircleRequestIconProps {
  color: string;
  style?: React.CSSProperties;
}
export const CircleRequestIcon: React.FC<CircleRequestIconProps> = (
  { color, style } = {
    color: '#000',
    style: {},
  }
) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_2885_126899)">
        <path
          d="M13.1467 18.0157C14.2589 17.7035 15.2902 17.1656 16.1785 16.4403V16.5948L17.0512 15.6191C17.1121 15.5509 17.186 15.4956 17.2685 15.4562C17.351 15.4168 17.4405 15.3941 17.5318 15.3895C17.6231 15.385 17.7144 15.3985 17.8004 15.4295C17.8865 15.4605 17.9655 15.5082 18.0329 15.5699C18.1004 15.6316 18.1549 15.7061 18.1934 15.789C18.2319 15.872 18.2535 15.9617 18.2571 16.0531C18.2606 16.1444 18.246 16.2356 18.2141 16.3213C18.1821 16.4069 18.1335 16.4854 18.0711 16.5522L18.071 16.5522L18.068 16.5555C16.7669 17.9712 15.0764 18.9708 13.2089 19.4286L12.9481 19.4925L12.8574 19.7452C12.7131 20.1469 12.4483 20.4943 12.0992 20.7399C11.7501 20.9855 11.3336 21.1173 10.9068 21.1173C10.4799 21.1173 10.0635 20.9855 9.71432 20.7399C9.36519 20.4943 9.1004 20.1469 8.95614 19.7452L8.8654 19.4925L8.60466 19.4286C6.73767 18.9707 5.04763 17.9712 3.74688 16.5558C3.68562 16.4889 3.63813 16.4106 3.60711 16.3253C3.57605 16.2399 3.56212 16.1493 3.56609 16.0585C3.57007 15.9677 3.59189 15.8786 3.63029 15.7963C3.6687 15.714 3.72295 15.64 3.78994 15.5786C3.85694 15.5173 3.93536 15.4697 4.02073 15.4386C4.10611 15.4076 4.19677 15.3936 4.28753 15.3976C4.3783 15.4016 4.46739 15.4234 4.54972 15.4618C4.63205 15.5002 4.70602 15.5545 4.76738 15.6215L4.76788 15.622C5.81987 16.767 7.17034 17.596 8.66753 18.0157L9.0081 18.1112L9.21153 17.8219C9.40278 17.5499 9.65667 17.3278 9.95176 17.1746C10.2469 17.0213 10.5745 16.9413 10.907 16.9413C11.2395 16.9413 11.5672 17.0213 11.8623 17.1746C12.1574 17.3278 12.4113 17.5499 12.6025 17.8219L12.806 18.1113L13.1467 18.0157Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M20.2039 6.63627L20.0724 6.837L20.1467 7.06522C20.4562 8.01556 20.6149 9.00851 20.617 10.008C20.6171 11.0099 20.462 12.0059 20.1574 12.9604L20.1568 12.9623C20.1295 13.0491 20.0853 13.1296 20.0268 13.1993C19.9683 13.2689 19.8966 13.3264 19.8159 13.3683C19.7351 13.4102 19.6469 13.4357 19.5562 13.4435L19.5986 13.9417L19.5562 13.4435C19.4655 13.4512 19.3742 13.4409 19.2876 13.4133L19.1357 13.8897L19.2876 13.4133C19.2009 13.3857 19.1205 13.3412 19.051 13.2824C18.9816 13.2236 18.9244 13.1517 18.8828 13.0708C18.8412 12.9899 18.816 12.9016 18.8086 12.8109C18.8012 12.7202 18.8118 12.629 18.8397 12.5424L18.8402 12.5411C19.1024 11.7214 19.2358 10.8659 19.2356 10.0052L19.2356 10.0045C19.2345 9.31444 19.1465 8.62726 18.9738 7.95917L18.8768 7.58436H18.4897C18.4651 7.58436 18.4441 7.58602 18.4288 7.58768C17.8858 7.57952 17.3668 7.36024 16.9822 6.97567C16.5914 6.58493 16.3713 6.05539 16.37 5.50285C16.3726 5.15334 16.4644 4.81028 16.6367 4.50614L16.8312 4.16275L16.5423 3.8938C15.46 2.88589 14.127 2.18688 12.6825 1.86968L12.6825 1.86963L12.6759 1.86827C12.5863 1.84986 12.5012 1.81386 12.4257 1.76234C12.3501 1.71083 12.2855 1.64483 12.2356 1.56816C12.1857 1.4915 12.1516 1.4057 12.1351 1.31574C12.1186 1.22577 12.1202 1.13343 12.1396 1.04407C12.1591 0.954707 12.1961 0.870095 12.2485 0.795138C12.3009 0.720181 12.3677 0.656371 12.4449 0.607405C12.5222 0.558439 12.6084 0.525291 12.6985 0.509881C12.7887 0.494472 12.881 0.497107 12.9701 0.517635L12.9701 0.517662L12.9753 0.518801C14.8518 0.93003 16.5629 1.89178 17.8898 3.28098L18.0541 3.45309L18.2914 3.43404C18.3201 3.43173 18.3461 3.42862 18.3671 3.42591L18.3962 3.42202L18.4196 3.41886C18.4337 3.41699 18.4426 3.41598 18.4493 3.4154C18.4551 3.41489 18.4576 3.41485 18.4578 3.41485L18.4589 3.41485C18.8346 3.41397 19.2035 3.5148 19.5264 3.70664C19.8494 3.89849 20.1144 4.1742 20.2933 4.5045C20.4723 4.83481 20.5584 5.20739 20.5427 5.58272C20.527 5.95804 20.4099 6.32211 20.2039 6.63627Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M3.4467 12.3872C3.20008 11.6168 3.07451 10.8127 3.07448 10.0038C3.0752 9.34853 3.15938 8.69603 3.325 8.06205C3.73537 8.02702 4.13126 7.89358 4.47911 7.67305C4.82696 7.45251 5.11653 7.15138 5.32327 6.79516C5.53002 6.43894 5.64785 6.03813 5.66679 5.6267C5.68572 5.21527 5.6052 4.80533 5.43205 4.43163C6.47759 3.39381 7.79666 2.67466 9.2354 2.35804C9.38979 2.32632 9.53636 2.26427 9.6666 2.17549C9.79684 2.08671 9.90816 1.97296 9.99412 1.84084C10.0801 1.70872 10.139 1.56086 10.1674 1.40582C10.1958 1.25078 10.1931 1.09164 10.1596 0.937634C10.126 0.783627 10.0622 0.637809 9.9719 0.508631C9.88158 0.379454 9.76652 0.269486 9.63339 0.185099C9.50026 0.100713 9.35171 0.0435857 9.19634 0.0170292C9.04098 -0.00952742 8.88189 -0.00498573 8.72829 0.0303912C6.74323 0.465713 4.93436 1.48638 3.53545 2.96049C3.38632 2.93188 3.23494 2.9166 3.0831 2.91485C2.39743 2.91566 1.74007 3.1884 1.25522 3.67324C0.770372 4.15809 0.497631 4.81545 0.496826 5.50113C0.499271 6.10872 0.717453 6.69569 1.11246 7.15736C0.836694 8.08197 0.695452 9.04146 0.693079 10.0063C0.693145 11.06 0.856515 12.1073 1.17737 13.1109C1.25415 13.3516 1.40547 13.5617 1.60947 13.7109C1.81347 13.86 2.05959 13.9404 2.31229 13.9405C2.49963 13.9402 2.68426 13.8958 2.85122 13.8108C3.01819 13.7258 3.16277 13.6027 3.27326 13.4514C3.38375 13.3002 3.45703 13.125 3.48717 12.9401C3.5173 12.7552 3.50344 12.5658 3.4467 12.3872Z"
          fill={color}
        />
        <ellipse
          cx="3.64484"
          cy="5.43634"
          rx="3.13361"
          ry="3.13361"
          fill={color}
        />
        <circle cx="18.8663" cy="5.43634" r="3.13361" fill={color} />
        <ellipse
          cx="10.8084"
          cy="18.866"
          rx="3.13361"
          ry="3.13361"
          fill={color}
        />
        <ellipse
          cx="3.64454"
          cy="5.43653"
          rx="2.23829"
          ry="2.23829"
          fill="white"
        />
        <ellipse
          cx="18.8662"
          cy="5.43653"
          rx="2.23829"
          ry="2.23829"
          fill="white"
        />
        <circle cx="10.8062" cy="18.8662" r="2.23829" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2885_126899">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
