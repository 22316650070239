import { IonPage, IonContent } from '@ionic/react';
import BryaHeader from '../../components/BryaHeader/BryaHeader';
import React from 'react';
import { GlobalRole } from '../../graphql/generated';
import { useAuthContext } from '../../providers/authProvider';
import ProHeader from '../ProHeader/ProHeader';

type LayoutWithHeaderProps = {
  children: React.ReactNode;
  scrollY?: boolean;
};

const LayoutWithHeader: React.FC<LayoutWithHeaderProps> = ({
  children,
  ...props
}) => {
  const { user } = useAuthContext();

  return (
    <IonPage>
      {user?.role !== GlobalRole.Agent &&
        user?.role !== GlobalRole.AdminAgent && <BryaHeader />}
      {(user?.role === GlobalRole.Agent ||
        user?.role === GlobalRole.AdminAgent) && <ProHeader />}
      <IonContent className="ion-padding" {...props}>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default LayoutWithHeader;
