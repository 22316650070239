import * as React from 'react';

export const Community = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={52}
    height={53}
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.8} stroke={props.color} strokeWidth={3}>
      <path
        d="M40.828 17.71c-.297 4.131-3.362 7.313-6.703 7.313s-6.41-3.18-6.703-7.312c-.305-4.297 2.678-7.313 6.703-7.313s7.008 3.094 6.703 7.313z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.125 31.521c-6.619 0-12.984 3.288-14.578 9.69-.212.848.32 1.686 1.19 1.686h26.777c.87 0 1.398-.838 1.19-1.685-1.594-6.506-7.96-9.69-14.579-9.69z"
        strokeMiterlimit={10}
      />
      <path
        d="M20.313 19.535c-.238 3.299-2.714 5.897-5.383 5.897-2.67 0-5.15-2.597-5.383-5.897-.243-3.432 2.167-5.885 5.383-5.885 3.215 0 5.625 2.516 5.383 5.885z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.922 31.727c-1.833-.84-3.852-1.163-5.992-1.163-5.281 0-10.37 2.626-11.644 7.74-.168.676.257 1.345.951 1.345h11.404"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
