import React, { useCallback } from 'react';
import GooglePlacesSearch from '../GooglePlacesSearch/GooglePlacesSearch';
import { CreateAddressInput } from '../../graphql/generated';
import { buildAddressFromPlaceResult } from '../../utils/googlePlaces';

type OnAddressHandler = (address: Partial<CreateAddressInput>) => unknown;

interface GooglePlacesSearchAddressOutputProps {
  googlePlaceApiKey: string;
  onAddress: OnAddressHandler;
}

const GooglePlacesSearchAddressOutput: React.FC<
  GooglePlacesSearchAddressOutputProps
> = ({ googlePlaceApiKey, onAddress }) => {
  const handlePlaceResult = useCallback(
    (placeResult: google.maps.places.PlaceResult | null) => {
      onAddress(buildAddressFromPlaceResult(placeResult));
    },
    []
  );

  return (
    <GooglePlacesSearch
      googlePlaceApiKey={googlePlaceApiKey}
      onPlaceResult={handlePlaceResult}
    />
  );
};

export default GooglePlacesSearchAddressOutput;
