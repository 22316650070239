import {
  useContext,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
interface INotificationsContext {
  setUnReadNotificationsCount: Dispatch<SetStateAction<number>>;
  unReadNotificationsCount: number;
  setNewServiceNotification: Dispatch<SetStateAction<boolean>>;
  hasServiceRequestNotification: boolean;
  setNewCircleNotification: Dispatch<SetStateAction<boolean>>;
  hasCircleRequestNotification: boolean;
}
const NotificationsContext = createContext<INotificationsContext>({
  setUnReadNotificationsCount: () => {},
  unReadNotificationsCount: 0,
  setNewServiceNotification: () => {},
  hasServiceRequestNotification: false,
  setNewCircleNotification: () => {},
  hasCircleRequestNotification: false,
});

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

const NotificationsProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [unReadNotificationsCount, setUnReadNotificationsCount] =
    useState<number>(0);
  const [hasServiceRequestNotification, setNewServiceNotification] =
    useState<boolean>(false);
  const [hasCircleRequestNotification, setNewCircleNotification] =
    useState<boolean>(false);

  return (
    <NotificationsContext.Provider
      value={{
        unReadNotificationsCount,
        setUnReadNotificationsCount,
        setNewServiceNotification,
        hasServiceRequestNotification,
        setNewCircleNotification,
        hasCircleRequestNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
