const CardIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 48095698">
      <g id="Group 48095697">
        <g id="Group 48095699">
          <path
            id="Vector"
            d="M20.1501 0.999939H2.91501C1.85738 0.999939 1 1.85732 1 2.91495V14.405C1 15.4627 1.85738 16.32 2.91501 16.32H20.1501C21.2078 16.32 22.0651 15.4627 22.0651 14.405V2.91495C22.0651 1.85732 21.2078 0.999939 20.1501 0.999939Z"
            stroke="#23364B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M1 6.745H22.0651"
            stroke="#23364B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Group 48095696">
          <circle
            id="Ellipse 820"
            cx="19.3998"
            cy="15.3999"
            r="4.6"
            fill="#23364B"
          />
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2885 13.5911C22.406 13.7049 22.406 13.8894 22.2885 14.0032L18.9814 17.2086C18.8639 17.3224 18.6736 17.3224 18.5562 17.2086L17.0529 15.7516C16.9355 15.6378 16.9355 15.4533 17.0529 15.3395C17.1703 15.2257 17.3607 15.2257 17.4781 15.3395L18.7688 16.5905L21.8634 13.5911C21.9808 13.4773 22.1711 13.4773 22.2885 13.5911Z"
            fill="white"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CardIcon;
