import { IonModal } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const StyledIonModal = styled(IonModal)`
  --width: 100%;
  --max-width: 350px;
  --max-height: 350px;
  --height: fit-content;
  --border-radius: 16px;
  --box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  img {
    width: 100%;
    height: 100%;
  }
`;
export const ProfileImage = styled.div<{ src: string }>`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border: 2px solid var(--neutral-1, #fff);
`;
export const ImagePlaceHolder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  justify-content: center;
  align-items: center;

  border-radius: 100px;
  border: 1px solid #fff;
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  color: #fff;
  font-size: ${pixelToRem(24)};
  font-weight: 400;
`;
