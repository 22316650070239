import { IonToast } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  padding: 10px;
  margin: 32px 24px;
`;

export const ProContainer = styled.div`
  gap: 8px;
  padding: 16px 0;

  &:not(:nth-child(5)) {
    border-bottom: 1px solid var(--border-color, #bdbdbd);
  }
`;
export const ProLabel = styled.label`
  color: var(--body-text-500, #9e9e9e);
  text-align: center;
  font-size: ${pixelToRem(14)};
  font-weight: 500;
  line-height: 140%;
`;

export const ProDescription = styled.p`
  color: var(--body-text-900, #212121);
  font-size: ${pixelToRem(18)};
  font-weight: 500;
  line-height: 140%;
  margin: 8px 0 0;
`;

export const FavoriteContainer = styled.div`
  position: relative;
  margin-top: 32px;
  border-radius: 10px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  gap: 8px;
`;
export const FavoriteTextContainer = styled.div`
  color: var(--primary-blue, #23364b);

  h3 {
    font-size: ${pixelToRem(18)};
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }
  small {
    font-size: ${pixelToRem(12)};
    font-style: italic;
    font-weight: 400;
  }
`;

export const StyledIonToast = styled(IonToast)`
  --background: var(--primary-orange, #ea8231);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #fff;
  font-weight: 600;
  font-size: ${pixelToRem(16)};
`;
