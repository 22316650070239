import * as React from 'react';

export const EmptyReviews = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="226"
    height="230"
    viewBox="0 0 226 230"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5009 51.4534C28.4284 13.1965 136.052 20.1984 169.168 69.8619C202.283 119.525 164.437 229.859 130.512 229.859C96.587 229.859 96.587 155.366 82.5988 133.488C68.6105 111.611 -5.42658 89.7103 11.5009 51.4534Z"
      fill="#FFE7BA"
    />
    <g clipPath="url(#clip0_6491_10872)">
      <g clipPath="url(#clip1_6491_10872)">
        <path
          d="M0 39.197C0 37.397 1.44909 35.9377 3.23664 35.9377H102.763C104.551 35.9377 106 37.397 106 39.197V76.6785C106 78.4785 104.551 79.9377 102.763 79.9377H3.23664C1.44909 79.9377 0 78.4785 0 76.6785V39.197Z"
          fill="white"
        />
        <g clipPath="url(#clip2_6491_10872)">
          <path
            d="M39 51.473C39 50.1324 39.8832 49.0457 40.9726 49.0457H85.0274C86.1168 49.0457 87 50.1324 87 51.473V53.1722C87 54.5128 86.1168 55.5996 85.0274 55.5996H40.9726C39.8832 55.5996 39 54.5128 39 53.1722V51.473Z"
            fill="#DDE3E9"
          />
          <path
            d="M39 62.3963C39 61.0556 39.8832 59.9689 40.9726 59.9689H69.2466C70.336 59.9689 71.2192 61.0556 71.2192 62.3963V64.0954C71.2192 65.436 70.336 66.5228 69.2466 66.5228H40.9726C39.8832 66.5228 39 65.436 39 64.0954V62.3963Z"
            fill="#DDE3E9"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 69.9377C27.6274 69.9377 33 64.5652 33 57.9377C33 51.3103 27.6274 45.9377 21 45.9377C14.3726 45.9377 9 51.3103 9 57.9377C9 64.5652 14.3726 69.9377 21 69.9377Z"
          fill="#C5CFD6"
        />
      </g>
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.008 40.6027C137.515 42.7089 134.082 43.7266 132.471 43.3111C128.431 42.2694 128.059 27.4852 131.185 21.6568C134.312 15.8283 150.383 13.2879 151.193 24.8359C151.474 28.8438 149.791 32.4206 147.306 35.3393L151.763 56.1197H138.815L141.008 40.6027Z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.3 15.3088C138.069 14.7692 140.637 14.5862 141.717 14.71C141.103 13.2371 140.859 11.5962 141.058 9.99852C141.251 8.44605 141.945 7.12416 142.586 5.73898C142.882 5.1005 143.217 4.46551 143.716 3.97714C144.225 3.4796 144.869 3.15119 145.545 2.9945C146.236 2.83412 146.959 2.84748 147.651 2.99303C147.957 3.0574 148.232 3.19747 148.533 3.27533C148.84 3.35511 148.989 3.16254 149.213 2.96901C149.751 2.50271 150.54 2.27251 151.22 2.19012C151.984 2.09773 152.776 2.21296 153.505 2.46408C154.214 2.70854 154.895 3.10551 155.407 3.68383C155.635 3.94126 155.843 4.23427 155.977 4.55777C156.033 4.69053 156.042 4.76917 156.174 4.8178C156.297 4.86283 156.44 4.86413 156.566 4.90698C157.789 5.32109 158.834 6.2658 159.403 7.47876C159.735 8.1864 159.892 9.43231 159.849 10.8288C159.806 12.2252 158.545 15.869 157.366 17.198C156.314 18.3843 154.767 19.1672 153.274 19.5099C154.265 20.6428 154.925 22.0585 155.412 23.4983C155.942 25.0623 156.27 26.6874 156.391 28.3432C156.506 29.9164 156.517 31.58 156.148 33.1196C155.803 34.5594 155.066 35.7405 153.834 36.4898C153.27 36.8331 152.663 37.0909 152.053 37.3266C151.451 37.5589 150.848 37.7847 150.218 37.915C149.025 38.1615 147.651 38.1192 146.732 37.1697C146.401 36.8274 146.037 36.3973 145.796 35.9792C145.709 35.8288 145.656 35.7304 145.762 35.5834C145.888 35.4074 146.093 35.2725 146.255 35.1348C147.181 34.3496 147.754 33.3014 147.899 32.0556C148.045 30.7917 147.706 29.1603 146.477 28.6087C144.961 27.9277 143.291 28.7093 141.943 29.4379C141.37 27.4155 140.194 25.5946 138.35 24.667C136.309 23.6407 133.968 23.5206 131.872 22.64C131.325 22.4106 130.769 22.1501 130.367 21.6826C130.199 21.4872 130.001 21.2337 129.934 20.9753C129.846 20.6358 130.065 20.3512 130.234 20.0866C131.131 18.6833 134.53 15.8484 136.3 15.3088Z"
      fill="#EEEEEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.079 100.906L136.52 169.559L122.25 220.161H111.842L127.006 100.906H156.079Z"
      fill="#2B44FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.309 100.906C161.762 136.291 163.305 155.591 163.78 158.806C164.255 162.02 171.437 182.472 185.326 220.161H174.544C156.862 184.005 146.991 163.553 144.932 158.806C142.873 154.058 136.518 134.758 126.947 100.906H161.309Z"
      fill="#1F28CF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.29 229.874L185.75 218.542L174.421 218.542C166.363 224.208 153.382 227.715 153.382 227.715V229.874H173.882L180.895 228.795V229.874H186.29Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.71 229.874L123.171 218.542L111.842 218.542C103.784 224.208 90.8021 227.715 90.8021 227.715V229.874H111.302L118.315 228.795V229.874H123.71Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.8997 77.5466L106.447 86.1334L100.206 94.1316L85.5657 82.9042C78.2531 81.5607 74.8618 80.4066 75.3917 79.4417C75.8385 78.6282 76.6698 78.5823 77.4356 78.5401C78.0321 78.5072 78.5888 78.4765 78.893 78.0868C79.5875 77.1968 77.8734 74.4475 76.1352 71.9081C74.3971 69.3687 76.0262 69.4605 76.9798 69.7641C80.1688 71.5393 83.4754 74.1334 86.8997 77.5466ZM174.422 90.6924L185.476 88.4984C192.974 108.264 197.444 119.096 198.886 120.993C199.943 122.384 206.465 125.968 210.88 127.881C212.652 128.65 212.271 130.147 211.751 130.626C208.473 133.649 205.426 132.589 202.109 131.436C201.148 131.102 200.165 130.761 199.147 130.508C196.513 129.854 195.712 131.209 194.954 132.489C194.283 133.623 193.647 134.698 191.804 134.269C191.888 133.907 191.991 133.502 192.102 133.064C192.82 130.237 193.88 126.066 192.308 123.644C191.501 122.4 185.539 111.416 174.422 90.6924Z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.375 47.1275L139.71 46.3272C129.55 56.1859 125.403 79.8714 118.632 83.0066C112.23 85.9707 103.155 83.1024 92.3672 79.2293L89.0636 86.6123C98.8651 97.2189 119.746 106.18 128.21 101.064C141.742 92.8854 144.866 63.6729 144.375 47.1275Z"
      fill="#FA8C16"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.711 105.766C164.711 105.766 136.654 105.766 123.435 105.766C121.549 105.766 121.904 103.042 122.177 101.667C125.323 85.8024 137.607 68.7009 137.607 46.1177L149.513 44.2502C159.361 60.0924 162.766 79.5973 164.711 105.766Z"
      fill="#DDE3E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.145 44.2502H150.281C176.986 60.7108 193.125 86.2271 198.698 120.799L190.046 122.494C184.315 115.816 177.242 104.885 168.828 89.7035C171.409 98.8489 173.294 108.229 173.882 117.098H136.727C129.534 98.9159 145.567 44.2502 145.567 44.2502H148.132H150.145Z"
      fill="#FFA940"
    />
    <defs>
      <clipPath id="clip0_6491_10872">
        <rect
          width="105.996"
          height="165.062"
          fill="white"
          transform="translate(0 35.9377)"
        />
      </clipPath>
      <clipPath id="clip1_6491_10872">
        <rect
          width="105.996"
          height="43.6929"
          fill="white"
          transform="translate(0 35.9377)"
        />
      </clipPath>
      <clipPath id="clip2_6491_10872">
        <rect
          width="48"
          height="17.4772"
          fill="white"
          transform="translate(39 49.0457)"
        />
      </clipPath>
    </defs>
  </svg>
);
