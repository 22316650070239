import { gql } from '@apollo/client';

export const INTERPRET_SERVICE_QUERY = gql`
  query InterpretServiceRequest($text: String!) {
    interpretServiceRequest(text: $text) {
      category
      recurring
    }
  }
`;

export const INTERPRET_CIRCLE_QUERY = gql`
  query InterpretCircleRequest($text: String!) {
    interpretCircleRequest(text: $text) {
      category
      recurring
    }
  }
`;

export const UPDATE_SERVICE_REQUEST = gql`
  mutation UpdateServiceRequest(
    $data: UpdateServiceRequestInput!
    $updateServiceRequestId: Int!
  ) {
    updateServiceRequest(data: $data, id: $updateServiceRequestId) {
      id
      status
    }
  }
`;

export const GET_SERVICE_REQUEST = gql`
  query GetServiceRequest($getServiceRequestId: Float!) {
    getServiceRequest(id: $getServiceRequestId) {
      id
      customerId
      category
      otherCategory
      recurring
      description
      frequency
      addressId
      status
      rejectedReason
      cancellationReason
      requestedBy
      agentId
      scheduledAt
      acceptedAt
      createdAt
      updatedAt
      requestType
      tentativeSchedule
      isAvailabilityFlexible
      requiresAction
      actionNeeded
      commentForCustomer
      availabilities {
        date
        from
        until
      }
      RequestedBy {
        id
        fullName
      }
      CreatedBy {
        id
        fullName
      }
      Customer {
        id
        fullName
      }
      ServiceRequestPayments {
        id
        type
        amount
        fee
        tax
        estimateHours
        createdAt
        updatedAt
        serviceRequestId
        stripePaymentMethodId
        stripeTransactionId
        notes
        StripeTransaction {
          status
          activityId
          amount
          createdAt
          stripeCustomerId
        }
      }
      RequestCategory {
        id
        name
      }
      Address {
        addressLine1
        addressLine2
        id
        place
        state
        zipCode
        country
        city
        customerId
      }
      ride {
        dropoffAddress {
          addressLine2
          addressLine1
          city
          country
          place
          state
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        type
        waitTime
      }
      Timeline {
        id
        User {
          id
          fullName
          createdAt
          updatedAt
        }
        userId
        customerId
        title
        description
        createdAt
        isAppVisible
      }
      Agent {
        Company {
          rating
          state
          updatedAt
          website
          name
          phoneNumber
          id
          hours
          createdAt
        }
        rating
        serviceTypes
        User {
          firstName
          lastName
          fullName
          profilePicture
        }
      }
    }
  }
`;

export const UPDATE_USER_APPROVAL_REQUEST = gql`
  mutation UserApprovalServiceRequest(
    $action: ApprovalServiceRequestStatus!
    $serviceRequestId: Int!
    $rejectedReason: String
  ) {
    userApprovalServiceRequest(
      action: $action
      serviceRequestId: $serviceRequestId
      rejectedReason: $rejectedReason
    ) {
      Agent {
        userId
      }
    }
  }
`;

export const UPDATE_SERVICE_REQUEST_TIME = gql`
  mutation UpdateServiceRequestTime(
    $newTime: DateTime!
    $serviceRequestId: Int!
  ) {
    updateServiceRequestTime(
      newTime: $newTime
      serviceRequestId: $serviceRequestId
    ) {
      scheduledAt
      status
    }
  }
`;

export const GET_SOCIAL_EVENT_REQUEST = gql`
  query GetMobileSocialEvent($socialEventId: Int!, $customerId: Int!) {
    getMobileSocialEvent(
      socialEventId: $socialEventId
      customerId: $customerId
    ) {
      id
      customerActivityId
      cancellationReason
      createdAt
      description
      eventName
      frequency
      recurring
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      ContactRequests {
        id
        createdAt
        updatedAt
        userId
        status
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
        role
        email
      }
      Customer {
        firstName
        fullName
        lastName
        createdAt
        id
      }
      addressLine1
      addressLine2
      city
      country
      place
      state
      zipCode
    }
  }
`;

export const USER_CANCEL_SERVICE_REQUEST = gql`
  mutation UserCancelServiceRequest(
    $cancellationReason: String!
    $serviceRequestId: Int!
  ) {
    userCancelServiceRequest(
      cancellationReason: $cancellationReason
      serviceRequestId: $serviceRequestId
    ) {
      requestType
      requestedBy
      requiresAction
      scheduledAt
      status
      rejectedReason
      otherCategory
      description
      customerId
      RequestedBy {
        firstName
        fullName
        id
        email
        role
        updatedAt
        lastName
      }
      Address {
        place
        state
        zipCode
        addressLine2
        addressLine1
        city
        country
      }
      InternalNotes {
        note
        serviceRequestId
      }
      cancellationReason
    }
  }
`;

export const USER_APPROVAL_SOCIAL_REQUEST = gql`
  mutation UserApprovalSocialEvent(
    $action: ApprovalServiceRequestStatus!
    $customerId: Int!
    $socialEventId: Int!
  ) {
    userApprovalSocialEvent(
      action: $action
      customerId: $customerId
      socialEventId: $socialEventId
    ) {
      Address {
        state
        place
        country
        city
        addressLine2
        addressLine1
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        role
        profilePicture
        email
      }
      cancellationReason
      createdAt
      description
      eventName
      requestType
      requestedBy
      scheduledAt
      status
    }
  }
`;

export const GET_CIRCLE_REQUEST = gql`
  query GetCircleRequest($getCircleRequestId: Float!) {
    getCircleRequest(id: $getCircleRequestId) {
      AcceptedBy {
        id
        fullName
        firstName
        lastName
        phoneNumber
        profilePicture
        role
        email
      }
      Timeline {
        id
        User {
          id
          fullName
          createdAt
          updatedAt
        }
        userId
        customerId
        title
        description
        createdAt
        isAppVisible
      }
      Address {
        addressLine1
        addressLine2
        city
        country
        place
        state
        zipCode
      }
      id
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        pickupAddress {
          addressLine1
          addressLine2
          country
          city
          place
          state
          zipCode
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          place
          state
          zipCode
        }
        type
        waitTime
      }
      scheduledAt
      status
      AskTo {
        id
        fullName
        firstName
        lastName
        phoneNumber
        role
        email
      }
      RequestedBy {
        fullName
        firstName
        lastName
        phoneNumber
        role
        profilePicture
        email
      }
      RequestCategory {
        id
        name
      }
      description
      recurring
      frequency
    }
  }
`;

export const USER_CANCEL_CIRCLE_REQUEST = gql`
  mutation UserCancelCircleRequest(
    $circleRequestId: Int!
    $cancellationReason: String
  ) {
    userCancelCircleRequest(
      circleRequestId: $circleRequestId
      cancellationReason: $cancellationReason
    ) {
      id
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      description
    }
  }
`;

export const UPDATE_CIRCLE_REQUEST_TIME = gql`
  mutation UpdateCircleRequestTime(
    $newTime: DateTime!
    $circleRequestId: Int!
    $untilTime: DateTime
  ) {
    updateCircleRequestTime(
      newTime: $newTime
      circleRequestId: $circleRequestId
      untilTime: $untilTime
    ) {
      id
      requestedBy
      requestType
      scheduledAt
      status
      updatedAt
    }
  }
`;

export const LIST_SERVICE_CATEGORIES = gql`
  query ListRequestCategories(
    $circleRequest: Boolean
    $serviceRequest: Boolean
  ) {
    listRequestCategories(
      circleRequest: $circleRequest
      serviceRequest: $serviceRequest
    ) {
      id
      name
      serviceRequest
      circleRequest
      createdAt
      updatedAt
    }
  }
`;

export const REVIEW_SERVICE_REQUEST = gql`
  mutation ReviewServiceRequest($data: CreateServiceRequestReviewInput!) {
    reviewServiceRequest(data: $data) {
      serviceRequestId
      updatedAt
      createdAt
    }
  }
`;

export const GET_ZIPCODE_MATCHES_BUSINESS_RULES = gql`
  query GetZipCodeMatchesBusinessRules($zipCode: String!) {
    getZipCodeMatchesBusinessRules(zipCode: $zipCode) {
      success
      message
    }
  }
`;

export const GET_STRIPE_TRANSACTIONS = gql`
  query GetStripeTransactions($filter: StripeTransactionFilter!) {
    getStripeTransactions(filter: $filter) {
      transactions {
        status
        amount
        activityId
        stripeTransactionId
      }
    }
  }
`;

export const GET_SERVICE_REQUEST_REVIEW = gql`
  query getServiceRequestReview($serviceRequestId: Int!) {
    getServiceRequestReview(serviceRequestId: $serviceRequestId) {
      rating
    }
  }
`;
