import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const IonContentContainer = styled(IonContent)`
  position: relative;
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 104px;

  p {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    color: #616161;
  }
`;
