import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { GetCoachVisitQuery } from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityConfirmed from '../../assets/images/IconActivityConfirmed';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { useHistory } from 'react-router';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { useAppContext } from '../../providers/appContextProvider';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { ActivityCardItemCoach } from '../ActivityCardItemCoach/ActivityCardItemCoach';
import { ActivityCardItemPhoneCall } from '../ActivityCardItemPhoneCall/ActivityCardItemPhoneCall';

type CoachVisitQueryResult = GetCoachVisitQuery['getCoachVisit'];

type ConfirmedCustomerActivityCoachVisitProps = {
  coachVisit: CoachVisitQueryResult;
};

export const ConfirmedCustomerActivityCoachVisit: React.FC<
  ConfirmedCustomerActivityCoachVisitProps
> = ({ coachVisit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { globalAlert } = useAppContext();
  const connectWithCoachErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmedCustomerActivityCoachVisit][ConnectWithCoach]',
  });
  const { directChatMessage, loading: isCreatingChat } = useDirectChatMessage({
    onError: connectWithCoachErrorHandler,
  });

  const handleCancel = () => {
    globalAlert({
      title: t('ConfirmedCustomerActivityCoachVisit.CancelVisitAlertTitle'),
      subtitle: t(
        'ConfirmedCustomerActivityCoachVisit.CancelVisitAlertSubtitle'
      ),
      firstButtonLabel: t(
        'ConfirmedCustomerActivityCoachVisit.CancelVisitAlertFirstButtonLabel'
      ),
      firstButtonAction: () => {
        return directChatMessage(coachVisit.Coach.id);
      },
      secondButtonLabel: t(
        'ConfirmedCustomerActivityCoachVisit.CancelVisitAlertSecondButtonLabel'
      ),
    });
  };

  const loading = isCreatingChat;

  return (
    <>
      <PageWithBottomSheetLayout
        title={t('ConfirmedCustomerActivityCoachVisit.Title')}
        background="var(--colors-gradient-green)"
        bottomSheetBackground="var(--colors-gradient-green-sheet)"
        bottomSheetTitle={
          <>
            <IconActivityConfirmed />
            {t('ConfirmedCustomerActivityCoachVisit.SheetTitle')}
          </>
        }
        startSlotHeaderAction={
          <HeaderBackButton onClick={() => history.goBack()} />
        }
      >
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <BodyText>
              {t('ConfirmedCustomerActivityCoachVisit.SheetBody')}
            </BodyText>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <CardList>
              <ActivityCardItemCoach
                coach={coachVisit.Coach}
                onConnect={() => directChatMessage(coachVisit.Coach.id)}
              />
              {coachVisit.Address ? (
                <ActivityCardItemAddress address={coachVisit.Address} />
              ) : (
                <ActivityCardItemPhoneCall />
              )}
              <ActivityCardItemRequestScheduledAt
                scheduledAt={coachVisit.scheduledAt}
              />
            </CardList>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <Button
              color="secondary-green"
              onClick={handleCancel}
              loading={loading}
            >
              {t('ConfirmedCustomerActivityCoachVisit.SecondaryCta')}
            </Button>
          </IonRow>
        </IonGrid>
      </PageWithBottomSheetLayout>
    </>
  );
};
