/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IonIcon, IonPage, IonToolbar, IonHeader } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  AccountHeader,
  ContinueButton,
  PageTitleStep2,
  StyledIonContent,
  SubtitleStep2,
  RowInputs,
} from './welcome.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../components/FormInput/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputProps } from '../../components/CustomInput/CustomInput';

const parentVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const childrenVariant: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

const formSchema = yup.object().shape({
  phone: yup.string().required('Phone is required'),
  street: yup.string(),
  apartment: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipCode: yup.string(),
});

const WelcomeStep2Yourself: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });

  const goBack = useCallback(() => {
    router.goBack();
  }, []);

  const formFields: InputProps[] = [
    {
      name: 'welcome.phoneNumber',
      label: t('welcome.phoneNumber') ?? '',
    },
    {
      name: 'welcome.street',
      label: t('welcome.street') || '',
    },
    {
      name: 'welcome.apartment',
      label: t('welcome.apartment') || '',
    },
    {
      name: 'welcome.city',
      label: t('welcome.city') || '',
    },
  ];

  // TO BE finished when we have BE query
  // eslint-disable-next-line @typescript-eslint/require-await
  const onSubmit = useCallback(async (formData): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('formData === ', formData);
    router.push('/welcome-step3');
  }, []) as SubmitHandler<FieldValues>;

  return (
    <IonPage>
      <IonHeader className="ion-no-border" style={{ height: 100 }}>
        <IonToolbar className="ion-padding" color={'#fff'}>
          <AccountHeader style={{ marginTop: 24 }}>
            <BackButton onClick={goBack}>
              <IonIcon
                icon={chevronBack}
                color="primary"
                style={{ width: 22, height: 26 }}
              />
              <BackLabel>{t('back')}</BackLabel>
            </BackButton>
            <BackButton onClick={onSubmit}>
              <BackLabel>{t('Next')}</BackLabel>
            </BackButton>
          </AccountHeader>
        </IonToolbar>
      </IonHeader>
      <StyledIonContent>
        <motion.div
          initial="initial"
          animate="animate"
          variants={parentVariant}
        >
          <motion.div variants={childrenVariant}>
            <PageTitleStep2>{t('welcome.tellAbout')}</PageTitleStep2>
          </motion.div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 24 }}>
            {formFields.map((field, index) => {
              if (field.name === 'welcome.street') {
                return (
                  <>
                    <motion.div variants={childrenVariant}>
                      <div style={{ marginBottom: 16 }}>
                        <SubtitleStep2>{t('welcome.whatIsHome')}</SubtitleStep2>
                      </div>
                      <motion.div key={index} variants={childrenVariant}>
                        <FormInput
                          label={field.label}
                          register={register(field.name)}
                          closeLabels
                        />
                      </motion.div>
                    </motion.div>
                  </>
                );
              }
              return (
                <motion.div key={index} variants={childrenVariant}>
                  <FormInput
                    label={field.label}
                    register={register(field.name)}
                    closeLabels
                  />
                </motion.div>
              );
            })}
            <motion.div variants={childrenVariant}>
              <RowInputs>
                <FormInput
                  label={t('welcome.state') ?? ''}
                  register={register('welcome.state')}
                  closeLabels
                />
                <div style={{ width: 16 }}></div>
                <FormInput
                  label={t('welcome.zipCode') ?? ''}
                  register={register('welcome.zipCode')}
                  closeLabels
                />
              </RowInputs>
            </motion.div>
            <motion.div variants={childrenVariant}>
              <ContinueButton type="submit">
                <>{t('continue')}</>
              </ContinueButton>
            </motion.div>
          </form>
        </motion.div>
      </StyledIonContent>
    </IonPage>
  );
};

export default WelcomeStep2Yourself;
