import * as React from 'react';

export const ChatTabIconNotSelected = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.625 3.00134H5.375C4.67942 3.00332 4.01289 3.28052 3.52103 3.77237C3.02918 4.26423 2.75198 4.93076 2.75 5.62634V14.6263C2.75198 15.3219 3.02918 15.9885 3.52103 16.4803C4.01289 16.9722 4.67942 17.2494 5.375 17.2513H7.25V21.0013L11.6431 17.3385C11.7106 17.2822 11.7957 17.2513 11.8836 17.2513H19.625C20.3206 17.2494 20.9871 16.9722 21.479 16.4803C21.9708 15.9885 22.248 15.3219 22.25 14.6263V5.62634C22.248 4.93076 21.9708 4.26423 21.479 3.77237C20.9871 3.28052 20.3206 3.00332 19.625 3.00134V3.00134Z"
      stroke="#23364B"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
