/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback } from 'react';
/* eslint-disable import/no-extraneous-dependencies */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container } from './FeedbackTab.styles';
import { useTranslation } from 'react-i18next';
import { IonSkeletonText } from '@ionic/react';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const starsSkeletonCount = 5;
const reviewsSkeletonCount = 3;

const FeedbackTabSkeleton: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const renderStarsSkeletons = useCallback(() => {
    const skeletons = [];
    for (let i = 0; i < starsSkeletonCount; i++) {
      skeletons.push(
        <div style={{ marginLeft: 4 }} key={i}>
          <IonSkeletonText
            key={i}
            animated={true}
            style={{
              width: '24px',
              marginTop: 12,
              height: 24,
              borderRadius: 12,
            }}
          />
        </div>
      );
    }
    return skeletons;
  }, []);

  const renderReviewsSkeleton = useCallback(() => {
    const skeletons = [];
    for (let i = 0; i < reviewsSkeletonCount; i++) {
      skeletons.push(
        <IonSkeletonText
          key={i}
          animated={true}
          style={{
            width: '180px',
            marginTop: 12,
            height: 100,
            borderRadius: 6,
          }}
        />
      );
    }
    return skeletons;
  }, []);

  return (
    <Container>
      <Tabs defaultIndex={0}>
        <TabList>
          <Tab>{t('giveFeedback')}</Tab>
          <Tab>{t('prevFeedback')}</Tab>
        </TabList>
        <TabPanel style={{ marginBottom: 100 }}>
          <div
            style={{
              width: '120px',
              textAlign: 'center',
              marginTop: 24,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: width / 2 - 92,
            }}
          >
            <IonSkeletonText
              animated={true}
              style={{
                width: '120px',
                marginTop: 24,
                height: 24,
              }}
            />
            <IonSkeletonText
              animated={true}
              style={{
                width: '120px',
                marginTop: 12,
                height: 24,
              }}
            />
            <div style={{ display: 'flex', marginLeft: -12 }}>
              {renderStarsSkeletons()}
            </div>
            <IonSkeletonText
              animated={true}
              style={{
                width: '200px',
                marginTop: 12,
                height: 96,
                marginLeft: -36,
              }}
            />
            <IonSkeletonText
              animated={true}
              style={{
                width: '160px',
                marginTop: 12,
                height: 32,
                marginLeft: -14,
              }}
            />
          </div>
        </TabPanel>
        <TabPanel style={{ marginBottom: 32, marginTop: 24 }}>
          <div
            style={{
              width: '120px',
              textAlign: 'center',
              marginTop: 24,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: width / 2 - 120,
            }}
          >
            {renderReviewsSkeleton()}
          </div>
        </TabPanel>
      </Tabs>
    </Container>
  );
};

export default FeedbackTabSkeleton;
