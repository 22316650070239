import * as React from 'react';
interface ActivitiesSelectedProps extends React.SVGProps<SVGSVGElement> {
  hasUnreadServiceNotification?: boolean;
}

export const ActivitiesSelected = (props: ActivitiesSelectedProps) => (
  <>
    {!props.hasUnreadServiceNotification && (
      <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6.37 1.5l-.002.023c.39.02.692.355.692.747v.753h10.546v-.774a.727.727 0 01.762-.726c.39.02.692.355.692.747v.753h.773a2.977 2.977 0 012.977 2.976v.563a.164.164 0 01-.165.164H2.021a.164.164 0 01-.165-.164v-.563a2.977 2.977 0 012.977-2.976h.773v-.774a.726.726 0 01.762-.726l.001-.023zm0 0a.75.75 0 00-.787.75v.726l13.5-.706c0-.403-.31-.75-.714-.77a.75.75 0 00-.786.75v.749h-10.5V2.27c0-.403-.31-.75-.714-.77zM2.727 21.604a2.977 2.977 0 01-.872-2.105V8.437a.164.164 0 01.165-.164h20.624a.164.164 0 01.165.164v11.062a2.977 2.977 0 01-2.977 2.977h-15c-.79 0-1.547-.314-2.105-.872z"
          fill="#EA8231"
          stroke="#EA8231"
          strokeWidth={0.046875}
        />
      </svg>
    )}

    {props.hasUnreadServiceNotification && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M20.334 3.75006H5.33398C4.09134 3.75006 3.08398 4.75742 3.08398 6.00006V19.5001C3.08398 20.7427 4.09134 21.7501 5.33398 21.7501H20.334C21.5766 21.7501 22.584 20.7427 22.584 19.5001V6.00006C22.584 4.75742 21.5766 3.75006 20.334 3.75006Z"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinejoin="round"
        />
        <path
          d="M6.83301 2.24939V3.76288"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.834 2.24948V3.76298"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.584 7.50036H3.08398"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="20" cy="3.5" r="3.5" fill="#FAAD14" />
      </svg>
    )}
  </>
);
