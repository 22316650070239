/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Trans, useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  BodyContainer,
  CancelButton,
  ContainerButtons,
  IconContainer,
  RecurringText,
  Subtitle,
  Title,
  ViewEditRequestButton,
} from '../ActivityStatus.styles';
import ActivityStatusCancelled from '../../../assets/images/ActivitiesStatusCancelled';
import IconActivityCancelled from '../../../assets/images/IconActivityCancelled';
import ActivitiesSections from '../../ActivitiesSections/ActivitiesSections';
import {
  calendarClearOutline,
  checkmarkOutline,
  chevronDown,
  heartCircleOutline,
  homeOutline,
  informationOutline,
  locationOutline,
  personOutline,
} from 'ionicons/icons';
import {
  ApprovalServiceRequestStatus,
  GetCircleRequestQueryHookResult,
  GetCoachVisitQueryHookResult,
  useGetCircleRequestQuery,
  useGetCoachVisitQuery,
  useGetMobileSocialEventQuery,
  useGetServiceRequestQuery,
  useUserApprovalSocialEventMutation,
} from '../../../graphql/generated';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import { useCallback, useContext, useMemo, useState } from 'react';
import { buildRideStops } from '../../../utils/buildRideObject';
import CircleRequestIcon from '../../../assets/images/ServiceCategory/Circle_Activities_Icon.svg';
import ActivityStatusSkeleton from '../StatusContentSkeleton';
import CustomAlert from '../../CustomAlert/CustomAlert';
import { getSentToData } from '../../../utils/getSentToData';
import { compareDates } from '../../../utils/date';
import { getAddressInformationCircle } from '../../../utils/getAddressInformationCircle';
import { useHistory, useLocation } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { logger } from '../../../logger';
import { AuthContext } from '../../../providers/authProvider';
import { ActivityStatusEnum } from '../../../pages/activity-status/activity-statusEnum';
import { ActivityStatus } from '../StatusComponent.interface';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import { useAppContext } from '../../../providers/appContextProvider';
import { RequestType } from '../../../constants/enums';
import { formatAddress } from '../../../utils/address/formatAddress';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface CancelledStatusProps {
  title?: string;
  serviceRequestId?: number;
  requestType?: string;
  customerId?: number;
  typeChange?: {
    iconStartName: React.ReactElement;
    iconEndName: React.ReactElement;
    title: string;
    readOnly: boolean;
    ratingValue: '';
    customClass?: true;
    customIcon: false;
    reason: string;
  };
}

const CancelledStatus: React.FC<CancelledStatusProps> = ({
  typeChange,
  serviceRequestId,
  requestType,
  customerId: initialCustomerId,
}) => {
  const { t } = useTranslation();
  const { formatDateAndTimeInTimezone, formatIonDateTimeValueInTimezone } =
    useDateWithTimezone();
  const { globalAlert } = useAppContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editResponseModalOpen, setEditResponseModalOpen] =
    useState<boolean>(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const i18n = useTranslation();
  const { width } = useWindowDimensions();
  const cancelledOptions = [];
  const router = useHistory();
  const location = useLocation<ActivityStatus>();
  const { user } = useContext(AuthContext);

  const { data: getServiceRequestData } = useGetServiceRequestQuery({
    variables: {
      getServiceRequestId: serviceRequestId ? serviceRequestId : 0,
    },
    skip: requestType !== 'service',
    onCompleted: () => setIsDataReady(true),
  });

  const { data: getSocialRequestData } = useGetMobileSocialEventQuery({
    variables: {
      socialEventId: serviceRequestId ? serviceRequestId : 0,
      customerId: initialCustomerId as number,
    },
    skip: requestType !== 'social' || !initialCustomerId,
    onCompleted: () => setIsDataReady(true),
  });

  const { data: getCircleRequestData }: GetCircleRequestQueryHookResult =
    useGetCircleRequestQuery({
      variables: {
        getCircleRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'circle',
      onCompleted: () => setIsDataReady(true),
    });

  const { data: getCoachRequestData }: GetCoachVisitQueryHookResult =
    useGetCoachVisitQuery({
      variables: {
        coachVisitId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'coachVisit',
      onCompleted: () => setIsDataReady(true),
    });

  const [userApprovalSocialRequestMutation] =
    useUserApprovalSocialEventMutation();

  const addressInf = `${getServiceRequestData?.getServiceRequest.Address?.addressLine1}, ${getServiceRequestData?.getServiceRequest.Address?.city}`;
  const ratingInf =
    getServiceRequestData?.getServiceRequest.Agent?.rating?.toFixed() as
      | number
      | '';
  const userFullName = getServiceRequestData?.getServiceRequest.Agent?.User
    .fullName as string;

  const scheduledAt: Date | null | undefined =
    getServiceRequestData?.getServiceRequest.scheduledAt;
  const dateScheduled = scheduledAt
    ? formatDateAndTimeInTimezone(scheduledAt)
    : '';

  const requestByName = `Organized by ${
    getSocialRequestData?.getMobileSocialEvent.RequestedBy.fullName as string
  }`;
  const addressInfEvent = `${getSocialRequestData?.getMobileSocialEvent.addressLine1}, ${getSocialRequestData?.getMobileSocialEvent?.city}`;
  const eventScheduledAt: Date | null | undefined =
    getSocialRequestData?.getMobileSocialEvent.scheduledAt;
  const eventDateScheduled = eventScheduledAt
    ? formatDateAndTimeInTimezone(eventScheduledAt)
    : '';

  const addressCoach = formatAddress(
    getCoachRequestData?.getCoachVisit.Address,
    Boolean(getCoachRequestData?.getCoachVisit.Address?.place)
  );

  const sentToCircle = useMemo(() => {
    if (getCircleRequestData?.getCircleRequest.AskTo) {
      return getSentToData(getCircleRequestData?.getCircleRequest.AskTo) || [];
    }
  }, [getSentToData, getCircleRequestData?.getCircleRequest.AskTo]);

  const rideStops = useMemo(() => {
    let data;

    if (requestType === 'service') {
      data = getServiceRequestData?.getServiceRequest;
    } else if (requestType === 'circle') {
      data = getCircleRequestData?.getCircleRequest;
    }

    return buildRideStops(data);
  }, [getServiceRequestData, getCircleRequestData, requestType]);

  const coachScheduledAt: Date | null | undefined =
    getCoachRequestData?.getCoachVisit.scheduledAt;
  const coachDateScheduled = coachScheduledAt
    ? formatDateAndTimeInTimezone(coachScheduledAt)
    : '';
  const coachFullName = getCoachRequestData?.getCoachVisit.Coach.fullName as
    | string;
  if (requestType === 'social') {
    cancelledOptions.push(
      {
        iconStartName: checkmarkOutline,
        iconEndName: '',
        customIcon: false,
        title: requestByName,
        readOnly: true,
      },
      {
        iconStartName: locationOutline,
        iconEndName: '',
        title: addressInfEvent,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: eventDateScheduled,
        ratingValue: '',
        readOnly: true,
      }
    );
  } else if (requestType === 'circle') {
    cancelledOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: rideStops.length > 0 ? chevronDown : '',
        title: getAddressInformationCircle(getCircleRequestData),
        ratingValue: '',
        readOnly: rideStops.length > 0 ? false : true,
      },
      {
        iconStartName: CircleRequestIcon,
        iconEndName: '',
        customIcon: false,
        title: t('sentToCircleMembers', { circleMembers: sentToCircle }),
        ratingValue: '',
        readOnly: true,
        customClass: false,
        subTitle: '',
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getCircleRequestData?.getCircleRequest?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
  } else if (requestType === 'service') {
    cancelledOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: '',
        title: addressInf,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: informationOutline,
        iconEndName: chevronDown,
        title: 'Reason',
        reason: typeChange?.reason,
        ratingValue: '',
        readOnly: false,
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getServiceRequestData?.getServiceRequest
          ?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
    if (userFullName) {
      cancelledOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: ratingInf ? true : false,
        title: userFullName,
        ratingValue: ratingInf,
        readOnly: false,
        subTitle: 'see profile',
      });
    }
    if (dateScheduled) {
      cancelledOptions.splice(2, 0, {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: dateScheduled,
        ratingValue: '',
        readOnly: true,
      });
    }
  } else if (requestType === 'coachVisit') {
    cancelledOptions.push({
      iconStartName: homeOutline,
      iconEndName: '',
      title: addressCoach,
      ratingValue: '',
      readOnly: true,
    });
    if (getCoachRequestData?.getCoachVisit.Coach?.fullName) {
      cancelledOptions.unshift({
        iconStartName: heartCircleOutline,
        iconEndName: '',
        customIcon: false,
        title: coachFullName,
        ratingValue: '',
        readOnly: true,
      });
    }
    if (getCoachRequestData?.getCoachVisit?.scheduledAt) {
      const newCancelledOption = {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: coachDateScheduled,
        ratingValue: '',
        readOnly: true,
        // NOTE: not sure if this is even used, because I couldn't
        // edit the date of a Brya Partner Meeting cancelled (it opens a modal for Connect with Coach)
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getCoachRequestData?.getCoachVisit?.scheduledAt
            )
          : '',
      };

      cancelledOptions.splice(2, 0, newCancelledOption);
    }
  }

  const goToServiceRequestPage = (type: string) => {
    router.push({
      pathname: ROUTES.MEMBERSHIP.WHO_IS_THIS,
      state: {
        requestType: type,
      },
    });
  };

  const reconfirmAttendance = useCallback(() => {
    if (requestType === 'social') {
      try {
        void userApprovalSocialRequestMutation({
          variables: {
            action: ApprovalServiceRequestStatus.Accept,
            socialEventId: serviceRequestId ? serviceRequestId : 0,
            customerId: initialCustomerId as number,
          },
        })
          .then(() => {
            router.replace('/activityStatus', {
              status: ActivityStatusEnum.Confirmed,
              serviceRequestId,
              requestType,
              title: location.state.title,
              customerId: initialCustomerId,
            });
          })
          .catch((err) => {
            logger.error({
              tag: '[userApprovalSocialRequestMutation]',
              message: 'User Approval Social Request Mutation Update failed',
              error: err as Error,
            });
          });
      } catch (err) {
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(err) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
        logger.error({
          tag: '[userApprovalSocialRequestMutation]',
          message: 'User Approval Social Request Mutation Update failed',
          error: err as Error,
        });
      }
    }
    setEditResponseModalOpen(false);
  }, [user, requestType]);

  return (
    <>
      <ActivityStatusCancelled width={width} />
      <BodyContainer width={width} className="cancelled">
        <IconContainer>
          <IconActivityCancelled />
        </IconContainer>
        <Title className="cancelled" data-testid="cancelled-title">
          {requestType === 'social' && i18n.t('sorryImUnavailable')}
          {requestType === 'service' && i18n.t('cancelled')}
          {requestType === 'circle' && i18n.t('circleCancelled')}
          {requestType === 'coachVisit' && i18n.t('coachVisitCancelled')}
        </Title>
        {getServiceRequestData?.getServiceRequest.recurring && (
          <RecurringText>{i18n.t('serviceForm.recurringEvent')}</RecurringText>
        )}
        <Subtitle
          className="cancelledSubTitle"
          data-testid="cancelled-subtitle"
        >
          {requestType === 'social' &&
            getSocialRequestData?.getMobileSocialEvent.description}
          {requestType === 'service' &&
            getServiceRequestData?.getServiceRequest.recurring &&
            i18n.t('serviceForm.cancelledRecurringInfo')}
          {requestType === 'service' &&
            !getServiceRequestData?.getServiceRequest.recurring &&
            i18n.t('cancelledSubtitle')}
          {requestType === 'circle' &&
            !getCircleRequestData?.getCircleRequest.recurring &&
            i18n.t('serviceForm.cancelledRecurringInfo')}
          {requestType === 'circle' &&
            getCircleRequestData?.getCircleRequest.recurring &&
            getCircleRequestData?.getCircleRequest.description}
          {requestType === 'coachVisit' &&
            getCoachRequestData?.getCoachVisit.description}
          {requestType === 'coachVisit' &&
            !getCoachRequestData?.getCoachVisit.description &&
            i18n.t('coachVisitSubtitleCancelled')}
        </Subtitle>
        {!isDataReady && <ActivityStatusSkeleton />}
        {isDataReady && (
          <ActivitiesSections
            requestType={requestType}
            rideOptions={rideStops}
            itemLists={cancelledOptions}
            category={getServiceRequestData?.getServiceRequest.category}
            agentId={
              getServiceRequestData?.getServiceRequest?.agentId as number
            }
            status={'cancelled'}
            cancelledReason={
              getServiceRequestData?.getServiceRequest.cancellationReason
            }
          />
        )}
        <ContainerButtons>
          {requestType === 'service' && (
            <CancelButton
              className="cancelled"
              data-testid="cancelled-button"
              fill={'outline'}
              onClick={() => goToServiceRequestPage(RequestType.Service)}
            >
              <>{i18n.t('cancelledButton')}</>
            </CancelButton>
          )}
          {requestType === 'circle' && (
            <CancelButton
              className="cancelled"
              data-testid="cancelled-button"
              fill={'outline'}
              onClick={() => goToServiceRequestPage(RequestType.Circle)}
            >
              <>{i18n.t('circleBtnCancelled')}</>
            </CancelButton>
          )}
          {requestType === 'coachVisit' && (
            <CancelButton
              className="cancelled"
              data-testid="cancelled-button"
              fill={'outline'}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              <>{i18n.t('cancelCoachVisitBtn')}</>
            </CancelButton>
          )}
          {requestType === 'social' && compareDates(eventScheduledAt) && (
            <ViewEditRequestButton
              slot={'primary'}
              className="cancelled"
              onClick={() => setEditResponseModalOpen(true)}
            >
              <>{i18n.t('editResponse')}</>
            </ViewEditRequestButton>
          )}
        </ContainerButtons>
        <CustomAlert
          isDisabled={true}
          isOpen={modalOpen}
          onDidDismiss={() => setModalOpen(false)}
          title={i18n.t('cancelCoachVisitBtnTitleCancelled')}
          subtitle={i18n.t('cancelCoachVisitBtnSubtitle')}
          firstButtonLabel={i18n.t('connectCoach')}
          firstButtonAction={() => {}}
          secondButtonLabel={i18n.t('cancelCoach')}
          secondButtonAction={() => setModalOpen(false)}
        />
        <CustomAlert
          onDidDismiss={() => setEditResponseModalOpen(false)}
          isOpen={editResponseModalOpen}
          title={i18n.t('editYourResponse')}
          subtitle={i18n.t('hasYourAvailability')}
          firstButtonLabel={i18n.t('yesSeeYouThere')}
          firstButtonAction={reconfirmAttendance}
          secondButtonLabel={i18n.t('ImUnavailable')}
          secondButtonAction={() => setEditResponseModalOpen(false)}
        />
      </BodyContainer>
    </>
  );
};

export default CancelledStatus;
