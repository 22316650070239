import {
  IonIcon,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCol,
  IonRow,
  IonList,
  IonItem,
  IonSpinner,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardText,
  MainContainer,
  CardContent,
  StyledAvatar,
  UserInfoRow,
  Divider,
  LeaveButton,
  LoadingContainer,
} from './MyProfile.style';
import { personAddOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { RightArrow } from '../../assets/images/RightArrow';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { AuthContext } from '../../providers/authProvider';
import {
  SecondaryTitle,
  TertiaryTitle,
} from '../../components/Typography/Headings/Headings';
import {
  useGetUserInvitesByCustomerQuery,
  useLeaveCircleMutation,
} from '../../graphql/generated';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { ROUTES } from '../../constants/routes';

const MyProfilePage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [openLeaveAlert, setOpenLeaveAlert] = useState<boolean>(false);
  const [leaveCircle] = useLeaveCircleMutation();

  const { data, loading } = useGetUserInvitesByCustomerQuery({
    variables: {
      customerId: user?.customerId ?? 0,
    },
  });

  const handleLeaveAction = async () => {
    try {
      await leaveCircle();
    } catch (err) {
      logger.error({
        tag: '[LEAVE_CIRCLE]',
        message: `Fail leaving circle: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
    }
  };

  return (
    <IonPage>
      <HeaderWithAction actionText={<Trans i18nKey="back" />} />
      <MainContainer className="ion-padding">
        <IonRow className="ion-padding-vertical">
          <IonCol>
            <SecondaryTitle>
              <Trans i18nKey="myAccount.myAccountTitle" />
            </SecondaryTitle>
          </IonCol>
        </IonRow>
        <UserInfoRow>
          <StyledAvatar>
            {user?.firstName?.charAt(0) || 'A'}
            {user?.lastName?.charAt(0) || 'A'}
          </StyledAvatar>
          <TertiaryTitle style={{ marginLeft: 8 }}>
            {user?.firstName + ' ' + user?.lastName}
          </TertiaryTitle>
        </UserInfoRow>
        <Divider />
        <IonCard onClick={() => history.push(ROUTES.TABS.CONNECT)}>
          <IonCardHeader
            style={{
              padding: '16px',
            }}
          >
            <CardContent>
              <CardContent>
                <IonIcon icon={personAddOutline} color="#23364b"></IonIcon>
                <CardText>{t('myAccount.invitePeople')}</CardText>
              </CardContent>
              <RightArrow color="#23364b" />
            </CardContent>
          </IonCardHeader>
        </IonCard>
        <IonRow className="ion-padding-vertical">
          <IonCol>
            <SecondaryTitle>
              <Trans i18nKey="myAccount.circleFamilyConn" />
            </SecondaryTitle>
          </IonCol>
        </IonRow>
        {loading && (
          <LoadingContainer>
            <IonSpinner />
          </LoadingContainer>
        )}
        {!loading && (
          <IonList>
            <IonItem button={true} onClick={() => history.push('/my-circle')}>
              <Trans i18nKey="myAccount.myCircle" />
            </IonItem>
            <IonItem button={true} onClick={() => history.push('/my-family')}>
              <Trans i18nKey="myAccount.myFamily" />
            </IonItem>
            {data?.getUserInvitesByCustomer.map((item) => (
              <IonItem key={item.id}>
                {item.name}
                <LeaveButton slot="end" onClick={() => setOpenLeaveAlert(true)}>
                  <Trans i18nKey="myAccount.leave" />
                </LeaveButton>
              </IonItem>
            ))}
          </IonList>
        )}
      </MainContainer>
      <CustomAlert
        isDisabled={false}
        isOpen={openLeaveAlert}
        onDidDismiss={() => setOpenLeaveAlert(false)}
        title={t('myAccount.leaveAlertTitle')}
        subtitle1={t('myAccount.leaveAlertSubtitle')}
        firstButtonLabel={t('myAccount.leave')}
        firstButtonAction={() => {
          void handleLeaveAction();
          setOpenLeaveAlert(false);
        }}
        secondButtonLabel={t('cancel')}
        secondButtonAction={() => {
          setOpenLeaveAlert(false);
        }}
      />
    </IonPage>
  );
};

export default MyProfilePage;
