import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Preferences } from '@capacitor/preferences';
import { withScalars } from 'apollo-link-scalars';
import introspectionResult from '../graphql/graphql.schema.json';
import { buildClientSchema, type IntrospectionQuery } from 'graphql';
import { useContext, useMemo } from 'react';
import { BuildEnv, environments } from '../utils/env';
import { AppContext } from './appContextProvider';
import { StorageKey } from '../utils/storage';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery
);

const DateMap = {
  serialize: (parsed: unknown): string | null => {
    if (parsed instanceof Date) return parsed.toISOString();

    throw new Error('Must be date');
  },
  parseValue: (raw: unknown): Date | null => {
    if (!raw) return null;
    if (typeof raw === 'string') {
      return new Date(raw);
    }

    throw new Error('Invalid value to parse');
  },
};

const typesMap = {
  Date: DateMap,
  DateTime: DateMap,
};

const getApolloClientLink = (environment: BuildEnv) => {
  const authLink = setContext(
    async (_, { headers }): Promise<Record<string, unknown>> => {
      let augmentedHeaders: Record<string, unknown> = {
        ...(headers as Record<string, unknown>),
      };
      const { value: token } = await Preferences.get({
        key: StorageKey.Token,
      });

      if (token) {
        augmentedHeaders = {
          ...augmentedHeaders,
          Authorization: token,
        };
      }

      return {
        headers: {
          ...augmentedHeaders,
        },
      };
    }
  );

  const httpLink = createHttpLink({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    uri: `${environments[environment].apiUrl}/graphql`,
  });

  return ApolloLink.from([
    authLink,
    withScalars({ typesMap, schema }),
    httpLink,
  ]);
};

const getApolloClient = (environment: BuildEnv) => {
  const link = getApolloClientLink(environment);
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

const Apollo = ({ children }: { children: JSX.Element }) => {
  const { env } = useContext(AppContext);

  const queryClient = useMemo(() => {
    const client = getApolloClient(env);
    return client;
  }, [env]);

  return <ApolloProvider client={queryClient}>{children}</ApolloProvider>;
};

export default Apollo;
