import { FC } from 'react';
import styled from 'styled-components';

type IsTotal = {
  isTotal: boolean;
  finalBorder: boolean;
};

const Line = styled.div<IsTotal>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
  border-top: ${(props) => (props.isTotal ? '0.50px #E0E0E0 solid' : 'none')};
  border-bottom: ${(props) =>
    props.finalBorder ? '0.50px #E0E0E0 solid' : 'none'};
  font-weight: ${(props) => (props.isTotal ? '600' : '400')};
  padding-top: ${(props) => (props.isTotal ? '8px' : '2px')};
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  color: #212121;
`;

const Value = styled.div`
  text-align: right;
  font-size: 16px;
  line-height: 22.4px;
  color: #212121;
`;

type CostLineProps = {
  label: string;
  value?: string | number | null;
  isTotal?: boolean;
  finalBorder?: boolean;
};

const CostLine: FC<CostLineProps> = ({
  label,
  value,
  isTotal = false,
  finalBorder = false,
}) => (
  <Line finalBorder={finalBorder} isTotal={isTotal}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Line>
);

export default CostLine;
