import * as React from 'react';

export const RecurringService = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.5 13.9955C24.5 8.19861 19.7968 3.49548 14 3.49548C8.20309 3.49548 3.49997 8.19861 3.49997 13.9955C3.49997 19.7924 8.20309 24.4955 14 24.4955C19.7968 24.4955 24.5 19.7924 24.5 13.9955Z"
      stroke="url(#paint0_linear_2666_19623)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.2401 14.8641V13.9891C19.2365 12.9263 18.9109 11.8895 18.3063 11.0155C17.7017 10.1414 16.8464 9.47108 15.8531 9.09287C14.8599 8.71466 13.7754 8.6463 12.7425 8.89682C11.7096 9.14733 10.7769 9.70495 10.0674 10.4962M8.74392 13.1305V14.0055C8.74764 15.0695 9.07382 16.1073 9.67944 16.9822C10.2851 17.857 11.1417 18.5277 12.1362 18.9057C13.1308 19.2838 14.2166 19.3515 15.2504 19.0998C16.2842 18.8482 17.2175 18.289 17.927 17.4962"
      stroke="url(#paint1_linear_2666_19623)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5697 13.9989L19.2796 15.2895L17.9305 13.9989M7.43047 13.9989L8.72054 12.7083L10.0697 13.9989H7.43047Z"
      stroke="#666666"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2666_19623"
        x1="24.1146"
        y1="-6.25452"
        x2="1.86025"
        y2="-5.16223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#084282" />
        <stop offset="0.411458" stopColor="#00356F" />
        <stop offset="1" stopColor="#00244A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2666_19623"
        x1="19.0475"
        y1="3.87344"
        x2="7.92434"
        y2="4.41917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#084282" />
        <stop offset="0.411458" stopColor="#00356F" />
        <stop offset="1" stopColor="#00244A" />
      </linearGradient>
    </defs>
  </svg>
);
