import {
  IonHeader,
  IonButton,
  IonToolbar,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { Trans } from 'react-i18next';
import { chevronBackOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

export const Toolbar = styled(IonToolbar)`
  --background: white;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

export const ToolbarButton = styled(IonButton)`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  background-color: unset;
  letter-spacing: -0.4000000059604645px;
  color: var(--colors-primary-orange);
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;

  ::part(native) {
    padding: 0;
  }
`;

interface HeaderWithActionProps {
  onAction?: () => void | Promise<void>;
  actionText?: ReactNode;
}
export const HeaderWithAction: React.FC<HeaderWithActionProps> = ({
  onAction,
  actionText,
}) => {
  const router = useHistory();
  return (
    <IonHeader>
      <Toolbar>
        <IonButtons slot="start">
          <ToolbarButton
            onClick={() => {
              if (onAction) {
                void onAction();
              } else {
                router.goBack();
              }
            }}
          >
            <IonIcon
              slot="start"
              src={chevronBackOutline}
              style={{ fontSize: '20px' }}
            />
            {actionText || <Trans i18nKey="back" />}
          </ToolbarButton>
        </IonButtons>
      </Toolbar>
    </IonHeader>
  );
};
