/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import {
  StyledIonModal,
  Title,
  BackLabel,
  PayWith,
} from './ModalAddCard.style';
import { useTranslation } from 'react-i18next';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { t } from 'i18next';
import { SelectPaymentCard } from '../SelectPaymentCard/SelectPaymentCard';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';

interface ModalAddCardI {
  isOpenModal: boolean;
  onCardSelect: (card: string) => void;
  onDidDismiss: (event?: CustomEvent<OverlayEventDetail<any>>) => void;
}

const ModalAddCard: React.FC<ModalAddCardI> = ({
  isOpenModal,
  onCardSelect,
  onDidDismiss,
}) => {
  const i18n = useTranslation();

  return (
    <StyledIonModal
      initialBreakpoint={0.7}
      breakpoints={[0, 0.7, 1]}
      isOpen={isOpenModal}
      onDidDismiss={onDidDismiss}
    >
      <IonHeader className="ion-padding ion-no-border">
        <IonToolbar color="#fff">
          <IonButtons slot="start" onClick={() => onDidDismiss()}>
            <BackLabel>{t('back')}</BackLabel>
          </IonButtons>
          <IonTitle className="ion-text-center">
            <Title> {i18n.t('payment.paymentMethod')}</Title>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <PayWith>{t('payment.payWith')}</PayWith>
        <SelectPaymentCard
          buttonLabel={t('confirm')}
          onCardSelect={onCardSelect}
        />
      </IonContent>
    </StyledIonModal>
  );
};

export default ModalAddCard;
