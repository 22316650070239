import styled from 'styled-components';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { pixelToRem } from '../../utils/helper';
import { ServiceRequestStatus } from '../../graphql/generated';

const statusColorLabel: { [key: string]: string } = {
  confirmationNeeded: '#ea8231',
  confirmed: '#3A868C',
  pending: '#9254DE',
  cancelled: '#EA4255',
  userUnavailable: '#EA4255',
  paymentAuthorizationFailed: '#EA4255',
  paymentFailed: '#EA4255',
  completed: '#3F51B5',
  loading: '#eeeeee',
};

export const Container = styled.div`
  position: relative;
  width: 100%;
  .card-status {
    position: absolute;
    left: -8px;
    top: 16px;
    z-index: 1;

    &.pending {
      left: 4px;
    }
    &.confirmationNeeded {
      left: 4px;
    }
    &.needed {
      left: 8px;
    }
    &.payment-issue {
      left: -11px;
    }
  }
`;
export const StyledIonCardHeader = styled(IonCardHeader)`
  padding: 16px;
  &.md {
    padding: 8px 16px;
  }
`;

export const RequestServiceDescription = styled.div`
  p {
    font-weight: 400;
    font-size: ${pixelToRem(14)};
    line-height: 140%;
    color: #23364b;
    margin: 0 0 4px;
    &.center {
      display: flex;
      text-align: center;
    }
    .bold {
      font-weight: 700;
    }
    ion-icon {
      margin-right: 0.5rem;
      font-size: 20px;
    }
  }
`;

export const StyledIonCard = styled(IonCard)<{ status: string }>`
  width: 100%;
  background: radial-gradient(
    83.79% 194.31% at 11.62% 50%,
    #ffffff 0%,
    #f7f7f7 57.29%,
    #ffffff 100%
  );
  border: 1px solid ${({ status }) => statusColorLabel[status]};
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0 0 16px;
`;

export const StyledIonCardTitle = styled(IonCardTitle)`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: ${pixelToRem(18)};
  line-height: 140%;
  color: #212121;
  .title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    ion-icon {
      margin-right: 0.5rem;
      font-size: 20px;
    }
  }
  &.md {
    order: 1 !important;
  }
  svg:nth-child(1) {
    margin-right: 0.5rem;
  }
  svg:nth-child(2) {
    margin-left: auto;
  }
`;

export const StyledIonCardSubtitle = styled(IonCardSubtitle)<{
  status: string;
}>`
  &.md {
    order: 0 !important;
  }
  span {
    color: ${({ status }) => statusColorLabel[status]};
    white-space: nowrap;
  }
  font-weight: 500;
  font-size: ${pixelToRem(12)};
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 8px;
  ${({ status }) =>
    (status === 'pending' || status == 'confirmationNeeded') &&
    'margin-left: 16px;'}
  svg {
    margin-left: 0.25rem;
  }

  div {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .request-type-container {
    font-size: ${pixelToRem(16)};
    ion-icon {
      font-size: 25px;
    }
  }
`;

export const StyledRecurringDiv = styled.div`
  padding-left: 54px;
  padding-bottom: 6px;
`;

export const StyledStatusSpan = styled.span<{
  status: string;
}>`
  padding-left: ${({ status }) =>
    status === ServiceRequestStatus.ConfirmationNeeded ? '18px' : '0px'};
  margin-bottom: ${({ status }) =>
    status !== ServiceRequestStatus.ConfirmationNeeded &&
    status !== ServiceRequestStatus.Pending
      ? '6px'
      : '0px'};
`;
