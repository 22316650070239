import { chevronBackOutline } from 'ionicons/icons';
import { BackButton } from './HeaderBackButton.styles';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { ButtonHTMLAttributes } from 'react';

interface HeaderBackButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <BackButton {...props}>
      <IonIcon src={chevronBackOutline} style={{ fontSize: '20px' }} />
      {t('HeaderBackButton.Label')}
    </BackButton>
  );
};
