import styled from 'styled-components';
import { IonButton, IonContent, IonInput, IonToolbar } from '@ionic/react';

const PAGE_BACKGROUND =
  'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 26.72%, #F3F3F3 42.12%)';

export const StyledIonContent = styled(IonContent)`
  --background: ${PAGE_BACKGROUND};
`;

export const UserMessageCard = styled.article`
  padding: 16px;
  border-radius: 20px;
  background: #e8eaed;
  max-width: 65vw;

  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

type ToolbarProps = {
  show?: boolean;
};

export const StyledIonFooterToolbar = styled(IonToolbar)<ToolbarProps>`
  display: ${({ show }) => (show ? undefined : 'none')};
  --background: white;
  --padding-bottom: 13px;
  --padding-end: 13px;
  --padding-start: 13px;
  --padding-top: 13px;
`;

export const StyledIonInput = styled(IonInput)`
  min-height: 36px !important;
  --placeholder-color: rgba(0, 0, 0, 0.25);
  --placeholder-font-style: 16px;
  --placeholder-font-weight: 400;
`;

export const SendMessageButton = styled(IonButton)`
  min-height: unset;
  height: 32px;
  aspect-ratio: 1;
  margin: 0 0 0 8px;
`;

export const EditButton = styled(IonButton)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  --color: var(--colors-primary-orange);
  --background: transparent;
  --background-activated: transparent;
  --padding-bottom: 8px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: 8px;
  --border-color: var(--colors-primary-orange);
  --border-radius: 20px;
  --border-style: solid;
  --border-width: 1px;
`;
