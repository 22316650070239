import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import HalfCircle from '../../assets/half-circle.svg';
import { IonContent, IonHeader } from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 24px;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const RadioInfo = styled.span`
  font-size: ${pixelToRem(16)};
  color: #616161;
  margin-left: 46px;
`;

export const PageTitle = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(20)};
  text-align: left;
  color: #000000;
  font-weight: 700;
  line-height: 24px;
  margin-top: -24px;
`;

export const AccountHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardMiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const CardText = styled.div`
  color: #23364b;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
  margin-left: 12px;
`;

export const StyledIonHeader = styled(IonHeader)`
  min-height: 185px;
  .border {
    width: 100%;
    height: 10px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 24px;
    z-index: 99;
  }
  h1 {
    position: absolute;
    z-index: 999;
    bottom: 50px;
    left: 16px;
    font-weight: 700;
    font-size: ${pixelToRem(24)};
    line-height: 120%;
    color: #ffffff;
  }
  ion-toolbar {
    width: 100%;
    height: 160px;
    color: #fff;
    --background: linear-gradient(
      267.19deg,
      #084282 -0.42%,
      #00356f 41.24%,
      #00244a 100.84%
    );
    &:before {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 50px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 0;
      bottom: -15px;
      opacity: 0.2;
      transform: rotate(-100deg);
    }
    &:after {
      content: '';
      background: url(${HalfCircle});
      position: absolute;
      width: 30px;
      height: 150px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      right: 2px;
      top: 0;
      opacity: 0.3;
    }
  }
  ion-button {
    --color: #fff;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.4px;
  }

  .back-button {
    margin-top: 0px;
  }
`;

export const StyledIonContent = styled(IonContent)`
  ion-icon {
    font-size: 24px;
  }

  ion-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ion-radio {
    --inner-border-radius: 14px;
    --color: #ddd;
  }

  ion-radio::part(container) {
    width: 30px;
    height: 30px;
    border: 1.5px solid #b0b2c4;
    border-radius: 100px;
    --inner-border-radius: 14px;
  }

  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  ion-radio.radio-checked::part(container) {
    background: #ea8231;
  }

  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
    transform: rotate(45deg);
  }

  ion-radio {
    color: #23364b;
    font-size: ${pixelToRem(16)};
    line-height: 20px;
    font-weight: 700;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
