import * as React from 'react';

export const EmptyNotification = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.50083 14.4302V14.2112C3.53295 13.5633 3.7406 12.9356 4.10236 12.3927C4.7045 11.7405 5.1167 10.9414 5.29571 10.079C5.29571 9.41257 5.29571 8.73657 5.35393 8.07009C5.65469 4.86148 8.82728 2.64307 11.9611 2.64307H12.0387C15.1725 2.64307 18.345 4.86148 18.6555 8.07009C18.7137 8.73657 18.6555 9.41257 18.704 10.079C18.8854 10.9434 19.2972 11.745 19.8974 12.4022C20.2618 12.9403 20.4698 13.5657 20.4989 14.2112V14.4207C20.5206 15.2911 20.2208 16.1399 19.6548 16.8105C18.907 17.5945 17.8921 18.0823 16.8024 18.1815C13.607 18.5243 10.383 18.5243 7.18762 18.1815C6.09914 18.0781 5.08576 17.591 4.33521 16.8105C3.778 16.1394 3.48224 15.2957 3.50083 14.4302Z"
      stroke="#898D9E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.55493 21.4946C10.0542 22.1213 10.7874 22.5269 11.5922 22.6216C12.3971 22.7163 13.2072 22.4923 13.8433 21.9992C14.0389 21.8534 14.2149 21.6839 14.3672 21.4946"
      stroke="#898D9E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
