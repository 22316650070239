import {
  IonContent,
  IonIcon,
  IonList,
  IonPage,
  IonToast,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  CardContent,
  CustomIonToggle,
  Info,
  InfoContainer,
  IonLabelStyled,
  MainContainer,
  PushAreOffContainer,
  StyledFooter,
  StyledIonCard,
  Subtitle,
} from './notification-settings.styles';
import { informationCircleOutline, warningOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PushNotifications } from '@capacitor/push-notifications';
import ModalNotificationsInfo from '../../components/ModalNotificationsInfo/ModalNotificationsInfo';
import {
  useGetUserNotificationSettingsQuery,
  useSaveUserNotificationSettingsMutation,
} from '../../graphql/generated';
import { useAuthContext } from '../../providers/authProvider';
import { IUserData } from '../../types';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import { App } from '@capacitor/app';
import { PushPermissionsEnums } from '../../constants/enums';
import { Button } from '../../components/Button/Button';
import HeaderWithTitle from '../../components/HeaderWithTitle/HeaderWithTitle';
import { QuaternaryTitle } from '../../components/Typography/Headings/Headings';
import { usePushNotifications } from '../../utils/hooks/usePushNotifications';

interface NotificationTypeI {
  email: boolean | undefined;
  push: boolean | undefined;
  sms: boolean | undefined;
}

type NotificationTypeKeys = 'email' | 'push' | 'sms';

const NotificationSettingsPage: React.FC = () => {
  const i18n = useTranslation();
  const { user } = useAuthContext() as { user: IUserData };
  const [permission, setPermission] = useState<string>('');
  const [permissionsErrorMessage, setPermissionsErrorMessage] =
    useState<string>('');
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [notSavedModal, setNotSavedModal] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [permissionsErrorAlert, setPermissionsErrorAlert] =
    useState<boolean>(false);
  const history = useHistory();
  const [
    saveUserNotificationSettingsMutation,
    { loading: isSavingNotificationsSettings },
  ] = useSaveUserNotificationSettingsMutation();
  const {
    isPushNotificationsPluginAvailable,
    requestPushNotificationsPermissions,
  } = usePushNotifications();

  const {
    data: queryData,
    refetch,
    loading,
  } = useGetUserNotificationSettingsQuery({
    variables: {
      notificationType: 'general',
    },
  });

  const [notificationSettings, setNotificationSettings] =
    useState<NotificationTypeI>({
      email: queryData?.getUserNotificationSettings.email,
      push: queryData?.getUserNotificationSettings.push,
      sms: queryData?.getUserNotificationSettings.sms,
    });

  const checkPermission = async () => {
    if (!isPlatform('hybrid')) return;
    const { receive } = await PushNotifications.checkPermissions();
    if (receive === 'granted') {
      return true;
    }
    return false;
  };
  checkPermission().catch((err) => {
    // eslint-disable-next-line no-console
    console.error('[PushNotifications] fail checking permission', err);
  });

  useEffect(() => {
    if (!loading) {
      setNotificationSettings({
        email: queryData?.getUserNotificationSettings.email,
        push:
          permission !== 'granted'
            ? false
            : queryData?.getUserNotificationSettings.push,
        sms: queryData?.getUserNotificationSettings.sms,
      });
    }
  }, [queryData, loading, permission]);

  useEffect(() => {
    const checkPermission = async () => {
      try {
        if (!isPlatform('hybrid')) return;
        const { receive } = await PushNotifications.checkPermissions();
        setPermission(receive);
      } catch (err) {
        setPermissionsErrorAlert(true);
        setPermissionsErrorMessage(JSON.stringify(err));
        logger.error({
          tag: '[PushNotifications]',
          message: `[PushNotifications] fail checking permission: ${getApolloErrorMessage(
            err
          )}`,
          error: err as Error,
        });
      }
    };
    void checkPermission();
    void App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        void checkPermission();
      }
    });
    return () => {
      // TODO: isn't this possible removing other listeners in the app?
      void App.removeAllListeners();
    };
  }, []);

  const onSave = useCallback(async () => {
    try {
      await saveUserNotificationSettingsMutation({
        variables: {
          userId: user?.id,
          saveNotificationSettingsInput: {
            email: notificationSettings.email,
            push: notificationSettings.push,
            sms: notificationSettings.sms,
            notificationType: 'general',
          },
        },
        onCompleted: () => {
          void refetch();
          setSuccessToast(true);
        },
      });
    } catch (err) {
      logger.error({
        tag: '[SAVE_USER_NOTIFICATION_SETTINGS_MUTATION]',
        message: `Fail trying to save user notification settings: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
    }
  }, [notificationSettings]);

  const handleToggleChange = async (type: NotificationTypeKeys) => {
    if (type === 'push' && !notificationSettings.push) {
      const result = await requestPushNotificationsPermissions();
      if (result) {
        setNotificationSettings((prevSettings) => ({
          ...prevSettings,
          push: result,
        }));
      }
    } else {
      setNotificationSettings((prevSettings) => ({
        ...prevSettings,
        [type]: !prevSettings[type],
      }));
      if (type === 'push') {
        await PushNotifications.removeAllDeliveredNotifications();
      }
    }
  };

  const hasDifferences = useCallback(() => {
    const getOnlyComparableParametersObject = {
      email: queryData?.getUserNotificationSettings.email,
      push:
        permission !== PushPermissionsEnums.Granted
          ? false
          : queryData?.getUserNotificationSettings.push,
      sms: queryData?.getUserNotificationSettings.sms,
    };
    return (
      JSON.stringify(getOnlyComparableParametersObject) !==
      JSON.stringify(notificationSettings)
    ); // No differences found, return false
  }, [queryData?.getUserNotificationSettings, notificationSettings]);

  const handleBack = useCallback(() => {
    if (hasDifferences()) {
      setNotSavedModal(true);
    } else {
      history.goBack();
    }
  }, [hasDifferences]);

  const isPushNotificationButtonDisabled =
    loading ||
    (isPlatform('ios') && permission !== PushPermissionsEnums.Granted);

  return (
    <IonPage>
      <HeaderWithTitle
        title={i18n.t('settings.notifications')}
        onBack={handleBack}
      />
      <IonContent>
        <MainContainer>
          {permission !== PushPermissionsEnums.Granted &&
            isPushNotificationsPluginAvailable && (
              <PushAreOffContainer>
                <IonIcon
                  icon={warningOutline}
                  style={{
                    fontSize: '24px',
                    minWidth: '24px',
                    color: 'var(--ion-color-warning-shade)',
                  }}
                />
                <Subtitle>{i18n.t('settings.pushAreOff')}</Subtitle>
              </PushAreOffContainer>
            )}
          <Info>{i18n.t('settings.dontMiss')}</Info>
          <IonList>
            <StyledIonCard>
              <CardContent>
                <IonLabelStyled>{i18n.t('settings.email')}</IonLabelStyled>
                <CustomIonToggle
                  checked={notificationSettings.email}
                  slot="end"
                  disabled={loading}
                  onIonChange={() => handleToggleChange('email')}
                ></CustomIonToggle>
              </CardContent>
            </StyledIonCard>
            <StyledIonCard>
              <CardContent>
                <IonLabelStyled>
                  {i18n.t('settings.pushNotifications')}
                </IonLabelStyled>
                <CustomIonToggle
                  checked={notificationSettings.push}
                  slot="end"
                  disabled={isPushNotificationButtonDisabled}
                  onIonChange={() => handleToggleChange('push')}
                ></CustomIonToggle>
              </CardContent>
            </StyledIonCard>
            <Button
              color="primary-orange"
              onClick={onSave}
              loading={isSavingNotificationsSettings}
            >
              {i18n.t('settings.save')}
            </Button>
          </IonList>
        </MainContainer>
      </IonContent>
      <StyledFooter>
        <IonToolbar>
          <InfoContainer onClick={() => setOpenInfoModal(true)}>
            <IonIcon
              icon={informationCircleOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
              }}
            />
            <QuaternaryTitle color="var('--body-text-900')">
              {i18n.t('settings.info')}
            </QuaternaryTitle>
          </InfoContainer>
        </IonToolbar>
      </StyledFooter>
      <ModalNotificationsInfo
        isInfoModalOpen={openInfoModal}
        openInfoModal={(isOpen: boolean) => setOpenInfoModal(isOpen)}
      />
      <CustomAlert
        isDisabled={false}
        isOpen={notSavedModal}
        onDidDismiss={() => setNotSavedModal(false)}
        title={i18n.t('settings.unsavedChanges')}
        subtitle1={i18n.t('settings.unsavedChangesSubtitle')}
        firstButtonLabel={i18n.t('settings.saveChanges')}
        firstButtonAction={() => {
          setNotSavedModal(false);
          void onSave();
        }}
        secondButtonLabel={i18n.t('settings.leave')}
        secondButtonAction={() => {
          setNotSavedModal(false);
          history.goBack();
        }}
      />
      <IonToast
        isOpen={successToast}
        message={i18n.t('settings.settingsSaved') ?? ''}
        onDidDismiss={() => {
          setSuccessToast(false);
        }}
        duration={2000}
        position="top"
        color={'success'}
      ></IonToast>
      <CustomAlert
        isDisabled={false}
        isOpen={permissionsErrorAlert}
        title={i18n.t('notificationPermissionsError')}
        subtitle1={permissionsErrorMessage}
        firstButtonLabel={i18n.t('postVisitModal.ok')}
        firstButtonAction={() => {
          setPermissionsErrorAlert(false);
        }}
      />
    </IonPage>
  );
};

export default NotificationSettingsPage;
