import styled from 'styled-components';
import { IonAvatar, isPlatform } from '@ionic/react';
import { pixelToRem } from '../../utils/helper';

interface IStyledBryaHeader {
  hasUnreadNotification?: boolean;
}
export const Container = styled.div`
  ion-toolbar {
    --padding-end: 2.4rem;
    --padding-start: 2.4rem;
    ${isPlatform('android') &&
    `
        --padding-top: 1.5rem;
    --padding-bottom: 0.5rem;
    `}
  }
`;

export const StyledAvatar = styled(IonAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #ea8231;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  font-weight: 500;
  font-size: ${pixelToRem(14)};
  line-height: 140%;
  color: #ea8231;
`;

export const NotificationBellContainer = styled.div<IStyledBryaHeader>`
  position: relative;

  ${({ hasUnreadNotification }) =>
    hasUnreadNotification &&
    `
      border-radius: 3.125rem;
      background: var(--sunset-orange-1, #fff7e6);
      display: flex;
      width: 2.26088rem;
      height: 2.36363rem;
      padding: 0.25rem;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
  `}
`;

export const NotificationBellCounter = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  width: fit-content;
  padding: 0.2rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 0.3125rem;
  background: var(--primary-orange, #ea8231);

  color: var(--sunset-orange-1, #fff7e6);
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 700;
`;
