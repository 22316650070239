import styled from 'styled-components';
import { IonToggle } from '@ionic/react';
import { pixelToRem } from '../../utils/helper';
import ChevronDown from '../../assets/chevron-down-outline.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(170px, 2fr) 24px;
  align-items: center;
  justify-content: center;
  padding: 12px 8px 4px;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  ion-modal {
    --height: auto;
  }

  ion-toggle {
    min-width: 115px;
  }

  ion-select {
    --placeholder-color: #757575;
    --placeholder-opacity: 1;
    display: flex;
    align-items: center;
    padding: 14px 8px;
    height: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 14px;
    font-weight: 400;
    font-size: ${pixelToRem(14)};
    margin: 0 4px 8px;
    max-width: 177px;
    align-self: flex-end;
  }

  ion-select::part(icon) {
    content: url(${ChevronDown});
  }
  ion-select::text {
    font-size: ${pixelToRem(14)};
  }
  ion-select-option {
    font-size: ${pixelToRem(14)};
  }
`;

export const StyledIonToggle = styled(IonToggle)`
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  line-height: 140%;
  color: #212121;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-intens: center;
  width: 100%;

  .action-icon {
    width: 24px;
    height: 24px;
  }
`;

export const SlotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  .action-icon {
    width: 24px;
    height: 24px;
  }
`;
