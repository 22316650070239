import { IonHeader } from '@ionic/react';
import styled, { CSSProperties } from 'styled-components';

interface BackgroundProp {
  background: CSSProperties['background'];
}
export const StyledIonHeader = styled(IonHeader)<BackgroundProp>`
  ion-toolbar {
    background: ${({ background }) => background};
    --background: ${({ background }) => background};
    min-height: 180px;
    &:after {
      content: '';
      display: inline-block;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      width: 157px;
      height: 76px;
      left: -57px;
      top: -38px;
      background: white;
      opacity: 0.25;
    }
    &:before {
      content: '';
      display: inline-block;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      width: 142px;
      height: 90px;
      right: -70px;
      top: 56px;
      background: #ffffff;
      opacity: 0.3;
      transform: rotate(90deg);
      z-index: 2;
    }
  }
  ion-button {
    color: #ffffff;
  }
`;
