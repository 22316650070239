/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  IonIcon,
  IonLabel,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonHeader,
  IonContent,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  CustomToast,
  LocationsHeader,
  PageTitle,
  StyledIonModal,
} from './recent-searches.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack, locationOutline, trash } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import {
  HomeItemTitle,
  HomeItemSubtitle,
} from '../../components/ModalAddAddress/ModalAddAddress.style';
import {
  removeStorage,
  getStorage,
  setStorage,
  StorageKey,
} from '../../utils/storage';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

interface RecentSearchesPageI {
  openRecentSearchesModal: (modalOpen: boolean) => void;
  isOpenRecentSearchessModal: boolean;
}

const RecentSearchesPage: React.FC<RecentSearchesPageI> = ({
  openRecentSearchesModal,
  isOpenRecentSearchessModal,
}) => {
  const i18n = useTranslation();
  const [recentSearches, setRecentSearches] = useState([]);
  const [deleteToastVisible, setDeleteToastVisible] = useState<boolean>(false);
  const { height } = useWindowDimensions();

  const parentVariant: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const childrenVariant: Variants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
  };

  const onDeleteRecentSearchItem = useCallback(
    async (index: number) => {
      try {
        if (index !== 0) {
          delete recentSearches[index];
          const filtered = recentSearches.filter(function (el: null) {
            return el != null;
          });
          await setStorage(StorageKey.RecentSearches, JSON.stringify(filtered));
        } else if (index === 0) {
          setRecentSearches([]);
          await removeStorage(StorageKey.RecentSearches);
        }
        setDeleteToastVisible(true);
      } catch (err) {
        throw new Error('error');
      }
    },
    [recentSearches, setRecentSearches]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { value } = await getStorage(StorageKey.RecentSearches);
        if (value) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const parsedValue = JSON.parse(value);

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setRecentSearches(parsedValue);
        }
      } catch (error) {
        throw new Error('error');
      }
    };
    void fetchData().catch((e: string) => new Error(e));
  }, []);

  const handleModalDismiss = useCallback(() => {
    openRecentSearchesModal(false);
  }, []);

  return (
    <StyledIonModal
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpenRecentSearchessModal}
      onDidDismiss={handleModalDismiss}
    >
      <motion.div initial="initial" animate="animate" variants={parentVariant}>
        <IonHeader className="ion-padding">
          <LocationsHeader style={{ marginTop: 24 }}>
            <BackButton onClick={handleModalDismiss}>
              <IonIcon
                icon={chevronBack}
                color="primary"
                style={{ width: 22, height: 26 }}
              />
              <BackLabel>{i18n.t('back')}</BackLabel>
            </BackButton>
          </LocationsHeader>
          <motion.div variants={childrenVariant}>
            <PageTitle>{i18n.t('recentSearches')}</PageTitle>
          </motion.div>
        </IonHeader>

        <IonContent className="ion-padding" style={{ height: height * 0.9 }}>
          <div style={{ marginBottom: 90 }}>
            {recentSearches.map((field, index) => {
              return (
                <motion.div variants={childrenVariant} key={index}>
                  <IonItemSliding>
                    <IonItem>
                      <IonIcon
                        icon={locationOutline}
                        slot="start"
                        style={{ position: 'absolute' }}
                        color="rgba(35, 54, 75, 1)"
                      ></IonIcon>
                      <IonLabel style={{ marginLeft: 40 }}>
                        <HomeItemTitle>{field}</HomeItemTitle>
                        <HomeItemSubtitle>{i18n.t('other')}</HomeItemSubtitle>
                      </IonLabel>
                    </IonItem>

                    <IonItemOptions side="end">
                      <IonItemOption
                        color="danger"
                        onClick={() => {
                          void onDeleteRecentSearchItem(index);
                        }}
                      >
                        <IonIcon slot="start" icon={trash}></IonIcon>
                        Delete
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                </motion.div>
              );
            })}
          </div>
        </IonContent>
        <CustomToast
          isOpen={deleteToastVisible}
          message={i18n.t('deletedSuccessful') ?? ''}
          onDidDismiss={() => setDeleteToastVisible(false)}
          duration={2500}
        ></CustomToast>
      </motion.div>
    </StyledIonModal>
  );
};

export default RecentSearchesPage;
