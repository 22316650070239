import { ButtonContainer, HeaderContainer } from './Success.style';
import BryaButton from '../../components/BryaButton/BryanButton';

interface ContainerProps {
  title: string;
  message: string;
  buttonLabel: string;
  buttonAction: () => void;
  icon: JSX.Element;
}

const Success: React.FC<ContainerProps> = ({
  title,
  icon,
  message,
  buttonLabel,
  buttonAction,
}) => {
  return (
    <>
      <HeaderContainer>
        {icon}
        <h1>{title}</h1>
      </HeaderContainer>
      <div style={{ position: 'relative', height: 'calc(100% - 172px)' }}>
        <p>{message}</p>
        <ButtonContainer>
          <BryaButton onClick={buttonAction} expand="full" shape="round">
            {buttonLabel}
          </BryaButton>
        </ButtonContainer>
      </div>
    </>
  );
};

export default Success;
