import React, { FC } from 'react';
import styled from 'styled-components';

interface BadgeProps {
  children?: React.ReactNode;
  icon: string;
  onClick?: () => void;
  variant?: 'success' | 'warning';
}

const getBackgroundColor = (variant?: 'success' | 'warning'): string => {
  switch (variant) {
    case 'success':
      return 'var(--ion-color-secondary-green-contrast)';
    case 'warning':
      return 'var(--ion-color-warning-tint)';
    default:
      return '#FFF';
  }
};

const Container = styled.div<{ variant?: 'success' | 'warning' }>`
  width: auto;
  height: 19px;
  padding-left: 7px;
  padding-right: 9px;
  background: ${(props) => getBackgroundColor(props.variant)};
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

const InnerContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const Text = styled.div`
  text-align: center;
  color: white;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 18.2px;
  word-wrap: break-word;
`;

const Badge: FC<BadgeProps> = ({ children, icon, onClick, variant }) => (
  <Container onClick={onClick} variant={variant}>
    <InnerContainer>
      <Text>
        {icon} {children}
      </Text>
    </InnerContainer>
  </Container>
);

export default Badge;
