/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Description,
  MainContainer,
  StyledIonModal,
  Title,
  ModalHeaderStep3,
  BottomButton,
  DescriptionNotCentered,
  Divider,
  InputContainer,
  ModalHeader,
  ProvideInfoLabel,
  RowContainer,
  StyledIonCard,
  Subtitle,
  SummaryLeft,
  SummaryLeftSmall,
  SummaryRight,
  BackButton,
  BackLabel,
  ModalHeaderStep2,
  ErrorContainer,
} from './ModalPostVisitInfo.style';
import { Trans, useTranslation } from 'react-i18next';

import { SubmitHandler, useForm } from 'react-hook-form';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import CustomAlert from '../CustomAlert/CustomAlert';
import IconActivityConfirmed from '../../assets/images/IconActivityConfirmed';
import { formatNumber } from '../../utils/formatNumber';
import {
  GetAgentActivityByAppointmentIdQuery,
  ServiceRequestPaymentSummaryVisitType,
  useCreateServiceRequestSummaryMutation,
} from '../../graphql/generated';
import { ApolloQueryResult } from '@apollo/client';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonLabel,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonText,
} from '@ionic/react';
import { DollarIconNoCircle } from '../../assets/images/DollarIconNoCircle';
import { CostBreakDown } from '../CostBreakDown/CostBreakDown';

type Appointment =
  GetAgentActivityByAppointmentIdQuery['getAgentActivityByAppointmentId'];
interface ModalPostVisitInfoI {
  isOpenModal: boolean;
  onOpenModal: (modalOpen: boolean) => void;
  appointment: Appointment;
  refetch: () => Promise<
    ApolloQueryResult<GetAgentActivityByAppointmentIdQuery>
  >;
}

export interface FormDataI {
  aboutComment: string;
  comments: string;
  adjustments: string;
  hours: number;
  amount: number;
  notes: string;
}

const paymentHours = [
  { hour: 1 },
  { hour: 1.25 },
  { hour: 1.5 },
  { hour: 1.75 },
  { hour: 2 },
  { hour: 2.25 },
  { hour: 2.5 },
  { hour: 2.75 },
  { hour: 3 },
  { hour: 3.25 },
  { hour: 3.5 },
  { hour: 3.75 },
  { hour: 4 },
  { hour: 4.25 },
  { hour: 4.5 },
  { hour: 4.75 },
  { hour: 5 },
  { hour: 5.25 },
  { hour: 5.5 },
  { hour: 5.75 },
  { hour: 6 },
  { hour: 6.25 },
  { hour: 6.5 },
  { hour: 6.75 },
  { hour: 7 },
  { hour: 7.25 },
  { hour: 7.5 },
  { hour: 7.75 },
  { hour: 8 },
  { hour: 8.25 },
  { hour: 8.5 },
  { hour: 8.75 },
  { hour: 9 },
  { hour: 9.25 },
  { hour: 9.5 },
  { hour: 9.75 },
  { hour: 10 },
  { hour: 10.25 },
  { hour: 10.5 },
  { hour: 10.75 },
  { hour: 11 },
  { hour: 11.25 },
  { hour: 11.5 },
  { hour: 11.75 },
  { hour: 12 },
];

const ModalPostVisitInfo: React.FC<ModalPostVisitInfoI> = ({
  isOpenModal,
  onOpenModal,
  appointment,
  refetch,
}) => {
  const i18n = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [localErrorMessage, setLocalErrorMessage] = useState<string>('');
  const [step, setStep] = useState<string>('step1');
  const [localAdjustments, setLocalAdjustments] =
    useState<string>('Keep Current Cost');
  const history = useHistory();
  const [
    createServiceRequestSummary,
    { loading: createServiceSummaryLoading },
  ] = useCreateServiceRequestSummaryMutation();

  const getTypeFormReverse = useCallback(() => {
    if (appointment?.AppointmentRequestPayments) {
      if (appointment?.AppointmentRequestPayments[0].type === 'hour') {
        return i18n.t('paymentSetupModal.hourly');
      } else if (appointment?.AppointmentRequestPayments[0].type === 'fixed') {
        return i18n.t('paymentSetupModal.setPrice');
      } else {
        return i18n.t('paymentSetupModal.visitPrice');
      }
    } else {
      return '';
    }
  }, [appointment?.AppointmentRequestPayments]);

  const [categoryValue] = useState<string>(getTypeFormReverse());
  const [rate] = useState<number>(
    appointment?.AppointmentRequestPayments &&
      appointment?.AppointmentRequestPayments[0].amount
      ? appointment?.AppointmentRequestPayments[0].amount
      : 0
  );

  interface PaymentTypeI {
    hour: number;
  }

  interface ListTypeI {
    name: string;
  }

  const adjustmentsList = [
    { name: i18n.t('postVisitModal.keepCurrent') },
    { name: i18n.t('postVisitModal.makePartial') },
    { name: i18n.t('postVisitModal.addExtra') },
  ];

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        comments: yup
          .string()
          .required(i18n.t('forms.postVisitModal.commentsRequired')),
      }),
    []
  );

  const formSchemaAddExtraOrRefund = useMemo(
    () =>
      yup.object().shape({
        comments: yup
          .string()
          .required(i18n.t('forms.postVisitModal.commentsRequired')),
        notes: yup
          .string()
          .required(i18n.t('forms.postVisitModal.notesRequired')),
        amount: yup
          .number()
          .required(i18n.t('forms.postVisitModal.amountRequired'))
          .test(
            'isNonZero',
            i18n.t('forms.postVisitModal.amountGreaterThanZero'),
            (value) => value > 0
          ),
      }),
    []
  );

  const formSchemaStep2 = useMemo(
    () =>
      yup.object().shape({
        aboutComment: yup
          .string()
          .required(i18n.t('forms.postVisitModal.aboutCommentRequired')),
      }),
    []
  );

  const getFormValidation = () => {
    if (step === 'step1') {
      if (localAdjustments === 'Keep Current Cost') {
        return formSchema;
      } else if (
        localAdjustments === 'Add Extra Charge' ||
        localAdjustments === 'Make a Partial Refund'
      ) {
        return formSchemaAddExtraOrRefund;
      }
    } else {
      return formSchemaStep2;
    }
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      aboutComment: '',
      comments: '',
      adjustments: 'keepCost',
      hours: 1,
      amount: 0,
      notes: '',
    },
    resolver: yupResolver(getFormValidation() ?? formSchema),
  });

  const [watchedAdjustments, watchedHours, watchedAmount] = watch([
    'adjustments',
    'hours',
    'amount',
  ]);

  const clearForm = () => {
    resetField('hours');
    resetField('amount');
    resetField('notes');
    resetField('adjustments');
    resetField('comments');
    resetField('aboutComment');
  };

  const getSummaryVisitType = () => {
    if (watchedAdjustments === i18n.t('postVisitModal.addExtra')) {
      return ServiceRequestPaymentSummaryVisitType.AddExtraCharge;
    } else if (watchedAdjustments === i18n.t('postVisitModal.makePartial')) {
      return ServiceRequestPaymentSummaryVisitType.Refund;
    } else {
      return ServiceRequestPaymentSummaryVisitType.KeepCost;
    }
  };

  const getType = () => {
    if (categoryValue === i18n.t('postVisitModal.hourlyRate')) {
      return i18n.t('postVisitModal.hourlyRate');
    } else if (categoryValue === i18n.t('postVisitModal.setPrice')) {
      return i18n.t('postVisitModal.setPrice');
    } else {
      return i18n.t('postVisitModal.visitPrice');
    }
  };

  const getTotalServiceCharge = () => {
    return watchedAdjustments === i18n.t('postVisitModal.makePartial')
      ? formatNumber(watchedHours * rate - watchedAmount)
      : formatNumber(watchedHours * rate + watchedAmount);
  };

  const getSafetyFee = () => {
    if (
      watchedAdjustments === i18n.t('postVisitModal.keepCurrent') ||
      watchedAdjustments === ''
    ) {
      return formatNumber(
        (watchedHours * rate) /
          ((appointment?.ServiceRequest?.serviceFee as number) ?? 1)
      );
    } else if (watchedAdjustments === i18n.t('postVisitModal.makePartial')) {
      return formatNumber(
        (rate - watchedAmount) /
          ((appointment?.ServiceRequest?.serviceFee as number) ?? 1)
      );
    } else {
      if (categoryValue === 'Hourly Rate') {
        return formatNumber(
          (rate * watchedHours) /
            ((appointment?.ServiceRequest?.serviceFee as number) ?? 1)
        );
      }

      return formatNumber(
        (rate + watchedAmount) /
          ((appointment?.ServiceRequest?.serviceFee as number) ?? 1)
      );
    }
  };

  const getTaxFee = () => {
    if (
      watchedAdjustments === i18n.t('postVisitModal.keepCurrent') ||
      watchedAdjustments === ''
    ) {
      return formatNumber(
        watchedHours *
          rate *
          ((appointment?.ServiceRequest?.taxFee as number) / 100)
      );
    } else if (watchedAdjustments === i18n.t('postVisitModal.makePartial')) {
      return formatNumber(
        (rate - watchedAmount) *
          ((appointment?.ServiceRequest?.taxFee as number) / 100)
      );
    } else {
      if (categoryValue === 'Hourly Rate') {
        return formatNumber(
          rate *
            watchedHours *
            ((appointment?.ServiceRequest?.taxFee as number) / 100)
        );
      }

      return formatNumber(
        (rate + watchedAmount) *
          ((appointment?.ServiceRequest?.taxFee as number) / 100)
      );
    }
  };

  const getTotal = () => {
    if (
      watchedAdjustments === i18n.t('postVisitModal.keepCurrent') ||
      watchedAdjustments === '' ||
      watchedAdjustments === ServiceRequestPaymentSummaryVisitType.KeepCost
    ) {
      const taxFee = appointment?.ServiceRequest?.taxFee
        ? watchedHours * rate * (appointment?.ServiceRequest?.taxFee / 100)
        : 0;
      return formatNumber(
        watchedHours * rate +
          ((watchedHours * rate) /
            ((appointment?.ServiceRequest?.serviceFee as number) ?? 1) +
            taxFee)
      );
    } else if (watchedAdjustments === i18n.t('postVisitModal.makePartial')) {
      const taxFee = appointment?.ServiceRequest?.taxFee
        ? (rate - watchedAmount) * (appointment?.ServiceRequest?.taxFee / 100)
        : 0;
      return formatNumber(
        (rate - watchedAmount) /
          ((appointment?.ServiceRequest?.serviceFee as number) ?? 1) +
          (rate - watchedAmount) +
          taxFee
      );
    } else {
      if (categoryValue === 'Hourly Rate') {
        const taxFee = appointment?.ServiceRequest?.taxFee
          ? rate * watchedHours * (appointment?.ServiceRequest?.taxFee / 100)
          : 0;
        return formatNumber(
          rate *
            watchedHours *
            ((appointment?.ServiceRequest?.taxFee as number) / 100) +
            rate +
            taxFee
        );
      }

      const taxFee = appointment?.ServiceRequest?.taxFee
        ? (rate + watchedAmount) * (appointment?.ServiceRequest?.taxFee / 100)
        : 0;
      return formatNumber(
        (rate + watchedAmount) /
          ((appointment?.ServiceRequest?.serviceFee as number) ?? 1) +
          (rate + watchedAmount) +
          taxFee
      );
    }
  };

  const goBack = () => {
    setStep('step1');
  };

  const goToStep2: SubmitHandler<FormDataI> = () => {
    if (JSON.stringify(errors) === '{}') {
      setStep('step2');
    }
  };

  const handleModalDismiss = useCallback(() => {
    clearForm();
    onOpenModal(false);
  }, []);

  const handleAllDone = async () => {
    clearForm();
    onOpenModal(false);
    await refetch();
    history.push('/tabs/activities');
  };
  const onSubmit: SubmitHandler<FormDataI> = useCallback(
    async (formData) => {
      if (JSON.stringify(errors) === '{}') {
        try {
          if (appointment.AppointmentRequestPayments) {
            await createServiceRequestSummary({
              variables: {
                amount: formData.amount,
                notes: formData.notes,
                commentForBrya: formData.aboutComment,
                commentForCustomer: formData.comments,
                serviceRequestId: appointment.ServiceRequest?.id as number,
                visitPaymentType:
                  categoryValue === 'Hourly Rate'
                    ? undefined
                    : getSummaryVisitType(),
                hours:
                  categoryValue === 'Hourly Rate' ? formData.hours : undefined,
              },
            });
          }
        } catch (error) {
          const networkError = error as {
            networkError: { result: { errors: Array<{ message: string }> } };
          };
          const errorMessage =
            networkError.networkError.result.errors[0].message;
          setErrorAlert(true);
          setLocalErrorMessage(errorMessage);
        }
        setStep('step3');
      }
    },
    [watchedHours, getTotal(), watchedAmount, rate]
  );

  return (
    <StyledIonModal
      ref={modal}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpenModal}
      animated={true}
      onDidDismiss={handleModalDismiss}
    >
      {/* Purpose of this is just for clean code not reusability of course */}
      {step === 'step1' && (
        <>
          <ModalHeader>
            <Title>{i18n.t('postVisitModal.title')}</Title>
          </ModalHeader>
          <MainContainer className="ion-padding">
            <Subtitle>{i18n.t('postVisitModal.summary')}</Subtitle>
            <DescriptionNotCentered>
              <Trans
                values={{
                  seniorName: appointment.ServiceRequest?.Customer.firstName,
                }}
                i18nKey="postVisitModal.pleaseProvideInfo"
              />
            </DescriptionNotCentered>
            <form onSubmit={handleSubmit(goToStep2)}>
              <>
                <AnimateMotionDiv>
                  <IonLabel>{i18n.t('postVisitModal.comments') ?? ''}</IonLabel>
                  <IonTextarea
                    counter={true}
                    autoGrow
                    {...register('comments')}
                    onIonBlur={() => clearErrors('comments')}
                    onIonInput={({ detail }) => {
                      setValue('comments', detail.value as string);
                    }}
                  ></IonTextarea>
                  {errors.comments?.message && (
                    <ErrorContainer>
                      <IonText color="primary-red">
                        {errors.comments?.message}
                      </IonText>
                    </ErrorContainer>
                  )}
                </AnimateMotionDiv>

                <AnimateMotionDiv>
                  <Subtitle>{i18n.t('postVisitModal.paymentDetails')}</Subtitle>
                </AnimateMotionDiv>

                <AnimateMotionDiv>
                  <div style={{ marginBottom: 16 }}>
                    <RowContainer>
                      <SummaryLeft>
                        {i18n.t('postVisitModal.paymentType') ?? ''}
                      </SummaryLeft>
                      <SummaryRight>{getType()}</SummaryRight>
                    </RowContainer>
                    <RowContainer>
                      <SummaryLeft>
                        {i18n.t('postVisitModal.rate') ?? ''}
                      </SummaryLeft>
                      <SummaryRight>${rate}</SummaryRight>
                    </RowContainer>
                  </div>
                </AnimateMotionDiv>

                <Divider style={{ marginBottom: 12 }} />
                {categoryValue === 'Hourly Rate' && (
                  <>
                    <IonLabel>{i18n.t('postVisitModal.hours') ?? ''}</IonLabel>
                    <IonSelect
                      {...register('hours')}
                      labelPlacement="floating"
                      interface="popover"
                      shape="round"
                      value={watchedHours}
                      placeholder={watchedHours.toString()}
                      onIonBlur={() => clearErrors('hours')}
                      onIonChange={({ detail }) => {
                        setValue('hours', Number(detail.value.hour));
                      }}
                    >
                      {paymentHours?.map((paymentType: PaymentTypeI) => (
                        <IonSelectOption
                          key={paymentType.hour}
                          value={paymentType}
                        >
                          {paymentType.hour}
                        </IonSelectOption>
                      ))}
                    </IonSelect>

                    <ProvideInfoLabel>
                      {i18n.t('postVisitModal.duration') ?? ''}
                    </ProvideInfoLabel>
                  </>
                )}
                {(categoryValue === i18n.t('postVisitModal.visitPrice') ||
                  categoryValue === i18n.t('postVisitModal.setPrice')) && (
                  <>
                    <IonLabel>
                      {i18n.t('postVisitModal.adjustments') ?? ''}
                    </IonLabel>
                    <IonSelect
                      {...register('adjustments')}
                      labelPlacement="floating"
                      interface="popover"
                      shape="round"
                      value={watchedAdjustments}
                      placeholder={
                        watchedAdjustments === 'keepCost'
                          ? i18n.t('postVisitModal.keepCurrent')
                          : watchedAdjustments
                      }
                      onIonBlur={() => clearErrors('adjustments')}
                      onIonChange={({ detail }) => {
                        setValue('adjustments', detail.value.name as string);
                        setLocalAdjustments(detail.value.name as string);
                        setValue('amount', 0);
                      }}
                    >
                      {adjustmentsList?.map((listType: ListTypeI) => (
                        <IonSelectOption key={listType.name} value={listType}>
                          {listType.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </>
                )}
                {(watchedAdjustments === i18n.t('postVisitModal.makePartial') ||
                  watchedAdjustments === i18n.t('postVisitModal.addExtra')) && (
                  <>
                    <AnimateMotionDiv>
                      <IonLabel>
                        {watchedAdjustments ===
                        i18n.t('postVisitModal.makePartial')
                          ? i18n.t('postVisitModal.refundAmount')
                          : i18n.t('postVisitModal.addExtra')}
                      </IonLabel>
                      <InputContainer style={{ marginTop: '8px' }}>
                        <DollarIconNoCircle
                          width={24}
                          height={24}
                          fill="#212121"
                        />
                        <IonInput
                          type="number"
                          {...register('amount')}
                          onIonInput={(e) => {
                            setValue('amount', Number(e.target.value));
                          }}
                        ></IonInput>
                      </InputContainer>
                      {errors.amount?.message && (
                        <ErrorContainer>
                          <IonText
                            color="primary-red"
                            style={{ marginBottom: 8 }}
                          >
                            {errors.amount?.message}
                          </IonText>
                        </ErrorContainer>
                      )}
                    </AnimateMotionDiv>
                    <AnimateMotionDiv>
                      <IonLabel>
                        {i18n.t('postVisitModal.notes') ?? ''}
                      </IonLabel>

                      <IonTextarea
                        counter={true}
                        autoGrow
                        {...register('notes')}
                        onIonBlur={() => clearErrors('notes')}
                        onIonInput={({ detail }) => {
                          setValue('notes', detail.value as string);
                        }}
                      ></IonTextarea>
                      {errors.notes?.message && (
                        <ErrorContainer>
                          <IonText
                            color="primary-red"
                            style={{ marginBottom: 8 }}
                          >
                            {errors.notes?.message}
                          </IonText>
                        </ErrorContainer>
                      )}
                    </AnimateMotionDiv>
                  </>
                )}
                <AnimateMotionDiv>
                  <StyledIonCard>
                    <div style={{ marginBottom: 16 }}>
                      <RowContainer>
                        <SummaryLeft>
                          {i18n.t('postVisitModal.totalServiceCharge') ?? ''}
                        </SummaryLeft>
                        <SummaryRight>${getTotalServiceCharge()}</SummaryRight>
                      </RowContainer>
                      {watchedAdjustments ===
                        i18n.t('postVisitModal.makePartial') && (
                        <>
                          <RowContainer>
                            <SummaryLeftSmall>
                              {i18n.t('postVisitModal.initialVisitPrice') ?? ''}
                              : ${formatNumber(rate)}
                            </SummaryLeftSmall>
                          </RowContainer>
                          <RowContainer>
                            <SummaryLeftSmall>
                              {i18n.t('postVisitModal.partialRefund') ?? ''}: -$
                              {formatNumber(watchedAmount)}
                            </SummaryLeftSmall>
                          </RowContainer>
                        </>
                      )}
                      {watchedAdjustments ===
                        i18n.t('postVisitModal.addExtra') && (
                        <>
                          <RowContainer>
                            <SummaryLeftSmall>
                              {i18n.t('postVisitModal.initialVisitPrice') ?? ''}
                              : ${formatNumber(rate)}
                            </SummaryLeftSmall>
                          </RowContainer>
                          <RowContainer>
                            <SummaryLeftSmall>
                              {i18n.t('postVisitModal.addExtra') ?? ''}: $
                              {formatNumber(watchedAmount)}
                            </SummaryLeftSmall>
                          </RowContainer>
                        </>
                      )}
                      <RowContainer>
                        <SummaryLeft>
                          {i18n.t('postVisitModal.safetyFee') ?? ''}
                        </SummaryLeft>
                        <SummaryRight>${getSafetyFee()}</SummaryRight>
                      </RowContainer>
                      {appointment?.ServiceRequest?.taxFee ? (
                        <RowContainer>
                          <SummaryLeft>
                            {i18n.t('postVisitModal.taxFee') ?? ''}
                          </SummaryLeft>
                          <SummaryRight>${getTaxFee()}</SummaryRight>
                        </RowContainer>
                      ) : null}
                      <Divider style={{ marginTop: 16 }} />
                      <RowContainer>
                        <SummaryLeft>
                          {i18n.t('postVisitModal.totalCustomerCost') ?? ''}
                        </SummaryLeft>
                        <SummaryRight>${getTotal()}</SummaryRight>
                      </RowContainer>
                    </div>
                  </StyledIonCard>
                </AnimateMotionDiv>

                <CostBreakDown isCustomer={false} />

                <BottomButton slot={'primary'} type="submit">
                  {i18n.t('confirm')}
                </BottomButton>
              </>
            </form>
          </MainContainer>
        </>
      )}
      {step === 'step2' && (
        <>
          <ModalHeaderStep2>
            <BackButton onClick={goBack}>
              <BackLabel>{i18n.t('back')}</BackLabel>
            </BackButton>
            <Title>
              <Trans
                i18nKey={'postVisitModal.about'}
                values={{
                  userName:
                    appointment.ServiceRequest?.Customer.firstName ?? '',
                }}
              />
            </Title>
            <BackButton
              onClick={handleSubmit(onSubmit)}
              disabled={createServiceSummaryLoading}
            >
              <BackLabel>{i18n.t('save')}</BackLabel>
            </BackButton>
          </ModalHeaderStep2>
          <MainContainer className="ion-padding">
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                <AnimateMotionDiv>
                  <DescriptionNotCentered style={{ marginLeft: 8 }}>
                    <Trans
                      values={{
                        userName:
                          appointment.ServiceRequest?.Customer.firstName ?? '',
                      }}
                      i18nKey="postVisitModal.aboutInfo"
                    />
                  </DescriptionNotCentered>
                </AnimateMotionDiv>
                <AnimateMotionDiv>
                  <IonLabel>{i18n.t('postVisitModal.comments') ?? ''}</IonLabel>
                  <IonTextarea
                    counter={true}
                    autoGrow
                    {...register('aboutComment')}
                    onIonBlur={() => clearErrors('aboutComment')}
                    onIonInput={({ detail }) => {
                      setValue('aboutComment', detail.value as string);
                    }}
                    style={{ marginBottom: 16 }}
                  ></IonTextarea>
                  {errors.aboutComment?.message && (
                    <ErrorContainer>
                      <IonText color="primary-red" style={{ marginBottom: 8 }}>
                        {errors.aboutComment?.message}
                      </IonText>
                    </ErrorContainer>
                  )}
                </AnimateMotionDiv>
                <BottomButton
                  slot={'primary'}
                  type={'submit'}
                  disabled={createServiceSummaryLoading}
                >
                  {i18n.t('confirm')}
                </BottomButton>
              </>
            </form>
          </MainContainer>
        </>
      )}
      {step === 'step3' && (
        <MainContainer className="ion-padding">
          <ModalHeaderStep3>
            <IconActivityConfirmed />
            <Title>{i18n.t('postVisitModal.detailsSubmitted') ?? ''}</Title>
          </ModalHeaderStep3>
          <AnimateMotionDiv>
            <Description>
              {i18n.t('postVisitModal.congratulations')}
            </Description>
          </AnimateMotionDiv>
          <AnimateMotionDiv>
            <BottomButton slot={'primary'} onClick={handleAllDone}>
              {i18n.t('postVisitModal.allDone')}
            </BottomButton>
          </AnimateMotionDiv>
        </MainContainer>
      )}
      <CustomAlert
        isDisabled={false}
        isOpen={errorAlert}
        onDidDismiss={() => setErrorAlert(false)}
        title={i18n.t('genericError.title')}
        subtitle1={localErrorMessage}
        firstButtonLabel={i18n.t('postVisitModal.ok')}
        firstButtonAction={() => {
          setErrorAlert(false);
        }}
      />
    </StyledIonModal>
  );
};

export default ModalPostVisitInfo;
