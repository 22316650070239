/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IonIcon, IonPage, IonToolbar, IonHeader } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  AccountHeader,
  ContinueButton,
  PageTitleStep2,
  StyledIonContent,
  SubtitleStep2,
  PageSubTitle,
} from './welcome.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack } from 'ionicons/icons';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from '../../components/FormInput/FormInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputProps } from '../../components/CustomInput/CustomInput';
import { getPhoneNumberInputYupValidator } from '../../utils/form/getPhoneNumberInputYupValidator';

const parentVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const childrenVariant: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

const WelcomeStep2Lovedone: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const formSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string(),
        preffered: yup.string(),
        email: yup.string().required('Email is required'),
        phoneNumber: yup
          .string()
          .test(
            ...getPhoneNumberInputYupValidator(
              t('forms.common.phoneNumber.errors.invalid')
            )
          )
          .required('Mobile number is required'),
        address: yup.string().required('Address is required'),
      }),
    []
  );
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });

  const goBack = useCallback(() => {
    router.goBack();
  }, []);

  const formFields: InputProps[] = [
    {
      name: 'welcome.firstName',
      label: t('welcome.firstName') ?? '',
    },
    {
      name: 'welcome.lastName',
      label: t('welcome.lastName') || '',
    },
    {
      name: 'welcome.preffered',
      label: t('welcome.preffered') || '',
    },
    {
      name: 'welcome.email',
      label: t('welcome.email') || '',
    },
    {
      name: 'welcome.phoneNumber',
      label: t('welcome.phoneNumber') || '',
    },
    {
      name: 'welcome.whatIsTheirAddress',
      label: t('welcome.whatIsTheirAddress') || '',
    },
  ];

  // TO BE finished when we have BE query
  // eslint-disable-next-line @typescript-eslint/require-await
  const onSubmit = useCallback(async (formData): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('formData === ', formData);
  }, []) as SubmitHandler<FieldValues>;

  return (
    <IonPage>
      <IonHeader className="ion-no-border" style={{ height: 100 }}>
        <IonToolbar className="ion-padding" color={'#fff'}>
          <AccountHeader style={{ marginTop: 24 }}>
            <BackButton onClick={goBack}>
              <IonIcon
                icon={chevronBack}
                color="primary"
                style={{ width: 22, height: 26 }}
              />
              <BackLabel>{t('back')}</BackLabel>
            </BackButton>
            <BackButton onClick={onSubmit}>
              <BackLabel>{t('Next')}</BackLabel>
            </BackButton>
          </AccountHeader>
        </IonToolbar>
      </IonHeader>
      <StyledIonContent>
        <motion.div
          initial="initial"
          animate="animate"
          variants={parentVariant}
        >
          <motion.div variants={childrenVariant}>
            <PageTitleStep2>{t('welcome.tellAboutLoved')}</PageTitleStep2>
          </motion.div>
          <motion.div variants={childrenVariant} style={{ marginTop: 24 }}>
            <PageSubTitle>{t('welcome.lovedSubtitle')}</PageSubTitle>
          </motion.div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 24 }}>
            {formFields.map((field, index) => {
              if (field.name === 'welcome.whatIsTheirAddress') {
                return (
                  <>
                    <motion.div variants={childrenVariant}>
                      <div style={{ marginBottom: 16 }}>
                        <SubtitleStep2>
                          {t('welcome.whatIsTheirAddress')}
                        </SubtitleStep2>
                      </div>
                    </motion.div>
                    <motion.div key={index} variants={childrenVariant}>
                      <FormInput register={register(field.name)} closeLabels />
                    </motion.div>
                  </>
                );
              }
              return (
                <motion.div key={index} variants={childrenVariant}>
                  <FormInput
                    label={field.label}
                    register={register(field.name)}
                    closeLabels
                  />
                </motion.div>
              );
            })}

            <motion.div variants={childrenVariant}>
              <ContinueButton type="submit">
                <>{t('continue')}</>
              </ContinueButton>
            </motion.div>
          </form>
        </motion.div>
      </StyledIonContent>
    </IonPage>
  );
};

export default WelcomeStep2Lovedone;
