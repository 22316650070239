import * as React from 'react';

export const Messenger = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.8}
      d="M10.912 36.438a4.453 4.453 0 00-1.711.335l-5.566 2.009 1.608-5.816.002-.007c.168-.618.04-1.198-.073-1.557a5.25 5.25 0 00-.452-1.01l-.007-.012-.008-.012a5.797 5.797 0 00-.3-.454 17.713 17.713 0 01-2.905-9.72v-.004C1.47 10.262 9.711 2.143 19.984 2.143c8.952 0 16.39 6.199 18.124 14.374v.003c.26 1.212.392 2.449.392 3.69h1.497H38.5c0 9.996-7.95 18.204-18.172 18.204-1.582 0-3.816-.41-5.025-.75a34.984 34.984 0 01-2.787-.926l-.002-.001a4.49 4.49 0 00-1.601-.299zm0 0l-.005 1.5.002-1.5h.003zm-8.139 4.205l.003-.868-.023-.001-.03-.003.05.872z"
      stroke={props.color}
      strokeWidth={3}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);
