import { IonLabel } from '@ionic/react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
  background: var(--White, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  justify-content: space-between;
`;

export const Label = styled(IonLabel)`
  color: var(--colors-primary-blue, #23364b);
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.0135rem;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ion-label {
    margin: 0 1rem;
  }
`;
export const ContentBlock = styled.div``;
