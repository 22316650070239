import { IonLabel, IonText } from '@ionic/react';
import React, { ReactNode, forwardRef } from 'react';
import { ChangeHandler, FieldErrors, FieldValues } from 'react-hook-form';
import { Container, StyledTextarea } from './FormTextArea.styles';

interface IFormTextArea {
  name: string;
  label: ReactNode;
  placeholder?: string;
  errors: FieldErrors<FieldValues>;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  containerClassName?: string;
  style?: {
    [key: string]: unknown;
  };
}

/**
 * FormTextArea is an abstraction to integrate native ionic textarea and react-hook-form
 */
const FormTextArea = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      onChange,
      onBlur,
      errors,
      containerClassName,
      style,
    }: IFormTextArea,
    ref: React.Ref<HTMLIonTextareaElement>
  ) => {
    const hasError = !!(name && errors && errors[name]);
    return (
      <Container className={containerClassName || 'ion-margin-bottom'}>
        {typeof label === 'string' ? <IonLabel>{label}</IonLabel> : label}
        <StyledTextarea
          ref={ref}
          name={name}
          placeholder={placeholder}
          onIonChange={onChange}
          onIonBlur={onBlur}
          counter={true}
          autoGrow
          hasError={hasError}
          style={style}
        ></StyledTextarea>
        {hasError ? (
          <IonText color="primary-red">
            {errors[name]?.message as string}
          </IonText>
        ) : null}
      </Container>
    );
  }
);

FormTextArea.displayName = 'FormTextArea';

export default FormTextArea;
