import { GetMobileSocialEventQuery } from '../../graphql/generated';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { useTranslation } from 'react-i18next';
import { checkmarkOutline } from 'ionicons/icons';

type Organizer =
  GetMobileSocialEventQuery['getMobileSocialEvent']['RequestedBy'];

type ActivityCardItemOrganizedByProps = {
  organizer: Organizer;
};
export const ActivityCardItemOrganizedBy: React.FC<
  ActivityCardItemOrganizedByProps
> = ({ organizer }) => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="auto"
          className="ion-align-self-center"
          style={{ height: '24px' }}
        >
          <IonIcon
            icon={checkmarkOutline}
            color="var(--colors-primary-blue)"
            style={{
              width: '24px',
              height: '24px',
              marginInlineEnd: '8px',
            }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {t('ActivityCardItemOrganizedBy.Label', {
              fullName: organizer.fullName,
            })}
          </BodyText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
