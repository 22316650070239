import { Preferences } from '@capacitor/preferences';

export enum StorageKey {
  Env = 'env',
  Token = 'token',
  TokenEnv = 'tokenEnv',
  RecentSearches = 'recentSearches',
  WhoIsThisId = 'whoIsThisId',
  LastSavedPaymentMethod = 'lastSavedPaymentMethod',
  CurrentSavedPaymentMethod = 'currentSavedPaymentMethod',
}

export function getStorage(key: string) {
  return Preferences.get({ key });
}

export function setStorage(key: string, value: string) {
  return Preferences.set({
    key,
    value,
  });
}

export function removeStorage(key: string) {
  return Preferences.remove({ key });
}

export function clearStorage() {
  return Preferences.clear();
}
