/* eslint-disable @typescript-eslint/no-floating-promises */
import { useCallback } from 'react';
import { Browser } from '@capacitor/browser';
import { InAppBrowser } from '@capgo/inappbrowser';
import { isPlatform } from '@ionic/react';
const BrowserAndroid = InAppBrowser;

function useInAppBrowser() {
  const openEvent = useCallback(
    (loginType: string) => {
      if (isPlatform('ios') || !isPlatform('hybrid')) {
        Browser.open({
          url: loginType,
          presentationStyle: 'popover',
        });
      } else {
        BrowserAndroid.openWebView({
          url: loginType,
          title: 'brya',
          headers: {
            'User-Agent':
              'Mozilla/5.0 (iPhone; CPU iPhone OS 16_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.4 Mobile/15E148 Safari/604.1',
          },
        });
      }
    },
    [Browser, BrowserAndroid]
  );
  const closeEvent = useCallback(
    (callBackFunction: () => void) => {
      if (isPlatform('ios')) {
        Browser.removeAllListeners();
        Browser.addListener('browserFinished', callBackFunction);
      } else {
        BrowserAndroid.removeAllListeners();
        BrowserAndroid.addListener('closeEvent', callBackFunction);
      }
    },
    [Browser, BrowserAndroid]
  );
  const closeBrowser = useCallback(() => {
    if (!isPlatform('hybrid')) return;
    if (isPlatform('ios')) {
      Browser.close();
    } else {
      BrowserAndroid.close();
    }
  }, [Browser, BrowserAndroid]);

  return {
    openEvent,
    closeEvent,
    closeBrowser,
  };
}

export default useInAppBrowser;
