import { Title } from '../ModalPostVisitInfo/ModalPostVisitInfo.style';
import { BodyText } from '../Typography/Body/Body';
import { Button } from '../Button/Button';
import { FC } from 'react';
import { Steps } from './ServiceSummary';
import { SecondaryTitle, TertiaryTitle } from '../Typography/Headings/Headings';
import { GetServiceRequestQuery } from '../../graphql/generated';
import { IconContainer } from './ServiceSummary.styles';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { pixelToRem } from '../../utils/helper';
import { useTranslation } from 'react-i18next';

type ServiceInformationProps = {
  setNewStep: (Steps: Steps) => void;
  serviceSummaryData?: GetServiceRequestQuery;
};

export const ServiceInformation: FC<ServiceInformationProps> = ({
  setNewStep,
  serviceSummaryData,
}) => {
  const { t } = useTranslation();
  const handleContinue = () => {
    setNewStep(Steps.ProcessPaymentStep);
  };

  return (
    <>
      <Title>
        {t('serviceSummary.title', {
          category: serviceSummaryData?.getServiceRequest.RequestCategory?.name,
        })}
      </Title>
      <IconContainer>
        <UserAvatar
          size="88px"
          fontSize={pixelToRem(32)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user={serviceSummaryData?.getServiceRequest.Agent!.User}
        />
      </IconContainer>
      <div className="ion-text-center">
        <SecondaryTitle>
          {serviceSummaryData?.getServiceRequest.Agent?.User?.fullName}
        </SecondaryTitle>
      </div>
      <TertiaryTitle style={{ marginTop: '16px' }}>
        {t('serviceSummary.aboutTheService')}
      </TertiaryTitle>
      <BodyText>
        {serviceSummaryData?.getServiceRequest?.commentForCustomer}
      </BodyText>
      <Button
        style={{ marginTop: '16px' }}
        color="primary-orange"
        onClick={handleContinue}
      >
        {t('serviceSummary.continue')}
      </Button>
    </>
  );
};
