import { IonButton, IonHeader } from '@ionic/react';
import styled from 'styled-components';

export const IonHeaderContainer = styled(IonHeader)`
  ion-toolbar {
    --background: #ffffff;
    --border-style: none;
  }

  h1 {
    color: var(--body-text-900, #212121);
    font-size: 2rem;
    font-weight: 700;
    line-height: 110%;
    padding: 2rem 1rem;
  }
`;

export const FilterContainer = styled.div`
  padding: 0rem 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  ion-select {
    color: var(--gray-gray-900, #1a1f36);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    max-width: 220px;
  }
`;

export const MarkAllReadButton = styled(IonButton)`
  color: var(--gray-gray-900, #1a1f36);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  width: fit-content;
  margin-left: auto;
`;

export const EmptyNotificationContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--labels-light-primary, #000);

  svg {
    width: 3.91275rem;
    height: 4.60325rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
  }
`;
