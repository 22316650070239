export const states = [
  { abbrev: 'AA' },
  { abbrev: 'AE' },
  { abbrev: 'AK' },
  { abbrev: 'AL' },
  { abbrev: 'AR' },
  { abbrev: 'AS' },
  { abbrev: 'AP' },
  { abbrev: 'AZ' },
  { abbrev: 'CA' },
  { abbrev: 'CO' },
  { abbrev: 'CT' },
  { abbrev: 'DC' },
  { abbrev: 'DE' },
  { abbrev: 'FL' },
  { abbrev: 'FM' },
  { abbrev: 'GA' },
  { abbrev: 'GU' },
  { abbrev: 'HI' },
  { abbrev: 'IA' },
  { abbrev: 'ID' },
  { abbrev: 'IL' },
  { abbrev: 'IN' },
  { abbrev: 'KS' },
  { abbrev: 'KY' },
  { abbrev: 'LA' },
  { abbrev: 'MA' },
  { abbrev: 'MD' },
  { abbrev: 'ME' },
  { abbrev: 'MI' },
  { abbrev: 'MN' },
  { abbrev: 'MO' },
  { abbrev: 'MP' },
  { abbrev: 'MS' },
  { abbrev: 'MT' },
  { abbrev: 'NC' },
  { abbrev: 'ND' },
  { abbrev: 'NE' },
  { abbrev: 'NH' },
  { abbrev: 'NJ' },
  { abbrev: 'NM' },
  { abbrev: 'NV' },
  { abbrev: 'NY' },
  { abbrev: 'OH' },
  { abbrev: 'OK' },
  { abbrev: 'OR' },
  { abbrev: 'PA' },
  { abbrev: 'PR' },
  { abbrev: 'PW' },
  { abbrev: 'RI' },
  { abbrev: 'SC' },
  { abbrev: 'SD' },
  { abbrev: 'TN' },
  { abbrev: 'TX' },
  { abbrev: 'UT' },
  { abbrev: 'VA' },
  { abbrev: 'VI' },
  { abbrev: 'VT' },
  { abbrev: 'WA' },
  { abbrev: 'WI' },
  { abbrev: 'WV' },
  { abbrev: 'WY' },
];
