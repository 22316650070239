import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_BY_USER_ID = gql`
  query GetNotificationsByUserId(
    $filter: NotificationsFilter!
    $pageSize: Int
    $page: Int
  ) {
    getNotificationsByUserId(
      filter: $filter
      pageSize: $pageSize
      page: $page
    ) {
      meta {
        pageCount
        isLastPage
        totalCount
      }
      notifications {
        id
        userId
        User {
          fullName
        }
        type
        title
        text
        readAt
        pushSentAt
        smsSentAt
        emailSentAt
        updatedAt
        createdAt
        payload
        deeplink
      }
    }
  }
`;

export const UNREAD_NOTIFICATIONS = gql`
  mutation UnreadNotifications($notificationsIds: [Int!]!) {
    unreadNotifications(notificationsIds: $notificationsIds) {
      success
    }
  }
`;
export const READ_NOTIFICATIONS = gql`
  mutation ReadNotifications($notificationsIds: [Int!]!) {
    readNotifications(notificationsIds: $notificationsIds) {
      success
    }
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllUserNotifications($userId: Int) {
    readAllUserNotifications(userId: $userId) {
      success
    }
  }
`;
export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($notificationId: Float!) {
    deleteNotification(notificationId: $notificationId) {
      success
    }
  }
`;
export const ANSWER_CIRCLE_JOIN_REQUEST = gql`
  mutation AnswerRequest($status: UserInvitesStatus!, $userRequestId: Int!) {
    answerRequest(status: $status, userRequestId: $userRequestId) {
      success
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query GetUserNotificationSettings($notificationType: String!) {
    getUserNotificationSettings(notificationType: $notificationType) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;

export const SAVE_USER_NOTIFICATION_SETTINGS = gql`
  mutation SaveUserNotificationSettings(
    $saveNotificationSettingsInput: SaveNotificationSettingsInput!
    $userId: Int
  ) {
    saveUserNotificationSettings(
      saveNotificationSettingsInput: $saveNotificationSettingsInput
      userId: $userId
    ) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;
