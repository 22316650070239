import * as React from 'react';

export const CancelMark = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.6875" width="20" height="20" rx="10" fill="#EA4255" />
    <path
      d="M13.3125 7.37567L8.0625 12.6257M13.3125 12.6257L8.0625 7.37567L13.3125 12.6257Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
