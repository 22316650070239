import { DoneButton } from './HeaderDoneButton.styles';
import { useTranslation } from 'react-i18next';
import React, { ButtonHTMLAttributes } from 'react';

interface HeaderDoneButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const HeaderDoneButton: React.FC<HeaderDoneButtonProps> = (props) => {
  const { t } = useTranslation();
  return <DoneButton {...props}>{t('HeaderDoneButton.Label')}</DoneButton>;
};
