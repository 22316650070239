import {
  ActivityCard,
  StyledIonContent,
  Subtitle,
  TimeLineContent,
  Title,
} from './ActivitiesRideRequest.style';

interface ActivitiesRideRequestProps {
  rideOptions?: {
    id: number;
    title: string;
    subtitle: string;
  }[];
}

const ActivitiesRideRequest: React.FC<ActivitiesRideRequestProps> = ({
  rideOptions,
}) => {
  return (
    <StyledIonContent>
      {rideOptions?.map(({ id, title, subtitle }) => (
        <TimeLineContent key={id}>
          <ActivityCard>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </ActivityCard>
        </TimeLineContent>
      ))}
    </StyledIonContent>
  );
};

export default ActivitiesRideRequest;
