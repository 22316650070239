import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonItem,
} from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const MainContainer = styled(IonContent)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  ion-icon {
    font-size: 24px;
  }
  ion-card {
    margin-inline: 0px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardText = styled.div`
  color: #23364b;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
  margin-left: 24px;
`;

export const StyledAvatar = styled(IonAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 96px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #ea8231;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  font-weight: 500;
  font-size: ${pixelToRem(36)};
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #ea8231;
`;

export const StyledHeaderColumn = styled(IonCol)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const LeaveButton = styled(IonButton)`
  height: 32px;
  width: 68px;
  font-weight: 500;
  text-transform: none;
  font-size: ${pixelToRem(12)};
  color: var(--ion-color-primary-red);
  --background: transparent;
  --background-activated: transparent;
  --border-radius: 50px;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-primary-red);
`;

export const CustomIonItem = styled(IonItem)`
  background-color: #f5f5f5;
  --ion-item-background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const StyledAvatarCircleMember = styled(IonAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ea8231;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  font-weight: 500;
  font-size: ${pixelToRem(12)};
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #ea8231;
`;

export const PendingTag = styled.div`
  height: 24px;
  background: #f5de79;
  color: var(--ion-color-primary-contrast);
  padding: 8px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  margin-left: 12px;
  font-size: ${pixelToRem(13)};
  font-weight: 700;
`;

export const ActiveTag = styled.div`
  height: 24px;
  background: #3a868c;
  color: var(--ion-color-primary-contrast);
  padding: 8px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  margin-left: 12px;
  font-size: ${pixelToRem(13)};
  font-weight: 700;
`;

export const UserName = styled.div`
  color: var(--colors-primary-blue);
  font-size: ${pixelToRem(14)};
  font-weight: 500;
  margin-left: 16px;
`;
