export enum BuildEnv {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

interface EnvConfig {
  apiUrl: string;
  signInUrl: string;
  signUpUrl: string;
  stripePublishableKey: string;
}

const local: EnvConfig = {
  apiUrl: 'http://localhost:8080/api/v1',
  signInUrl: 'http://localhost:3001/log-in',
  signUpUrl: 'http://localhost:3001/sign-up',
  stripePublishableKey:
    'pk_test_51NSkyjKm0FDGNxe5JADXjdbonCvVgoYBhiWqzay56GF78I2f3LufZYVMpENkahESUCsNiZwPGazKHunDAr4hDNgE00AAKCWqeI',
};

const development: EnvConfig = {
  apiUrl: 'https://api.brya.dev.cleverbuild.biz/api/v1',
  signInUrl: 'https://brya.dev.cleverbuild.biz/log-in',
  signUpUrl: 'https://brya.dev.cleverbuild.biz/sign-up',
  stripePublishableKey:
    'pk_test_51NSkyjKm0FDGNxe5JADXjdbonCvVgoYBhiWqzay56GF78I2f3LufZYVMpENkahESUCsNiZwPGazKHunDAr4hDNgE00AAKCWqeI',
};

const staging: EnvConfig = {
  apiUrl: 'https://api.brya.staging.cleverbuild.biz/api/v1',
  signInUrl: 'https://brya.staging.cleverbuild.biz/log-in',
  signUpUrl: 'https://brya.staging.cleverbuild.biz/sign-up',
  stripePublishableKey:
    'pk_test_51NYDOqKk5xUTFPb5JzZE9U6E2Qj1QgCyDPSMNGiinWZyYKOdSeMQQxj2r2EgsJ1hTLqRIPuTFfebiSIk4CjYtNSF00todM4YFI',
};

const production: EnvConfig = {
  apiUrl: 'https://api.brya.com/api/v1',
  signInUrl: 'https://app.brya.com/log-in',
  signUpUrl: 'https://app.brya.com/sign-up',
  stripePublishableKey:
    'pk_live_51NSkyjKm0FDGNxe5M4gFM1QfY1b6iEwcN0Zykk92h6vvGxrdefSZo1bXpSpelzmRdLkiPiNOyw3lR9YnWRzJZQfo00u6mbXedm',
};

export const environments = {
  local,
  development,
  staging,
  production,
};

export function getEnvLabel(env: BuildEnv) {
  return {
    [BuildEnv.Local]: 'Local',
    [BuildEnv.Development]: 'Development',
    [BuildEnv.Staging]: 'Staging',
    [BuildEnv.Production]: 'Production',
  }[env];
}

export const buildEnv = process.env.REACT_APP_BUILD_ENV as BuildEnv;
