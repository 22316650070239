import * as React from 'react';

function IconActivityPending(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={77}
      height={100}
      viewBox="0 0 77 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={12}
        width={76}
        height={76}
        rx={38}
        fill="#9254DE"
        fillOpacity={0.25}
      />
      <rect
        x={8.5}
        y={20}
        width={60}
        height={60}
        rx={30}
        fill="#9254DE"
        fillOpacity={0.5}
      />
      <rect x={16.5} y={28} width={44} height={44} rx={22} fill="#fff" />
      <path
        d="M38.5 28c-12.15 0-22 9.85-22 22s9.85 22 22 22 22-9.85 22-22-9.85-22-22-22zm10.154 25.385H38.5a1.692 1.692 0 01-1.692-1.693v-15.23a1.692 1.692 0 013.384 0V50h8.462a1.692 1.692 0 110 3.385z"
        fill="#9254DE"
      />
    </svg>
  );
}

export default IconActivityPending;
