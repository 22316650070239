import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

export const Header = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: ${pixelToRem(22)};
    line-height: ${pixelToRem(27)};
    color: #212121;
  }
`;
