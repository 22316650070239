import { GraphqlServiceRequestPayment } from '../graphql/generated';

export const pixelToRem = (pixel: number, base = 16) => {
  return `${(1 / base) * pixel}rem`;
};

export const calculateTotalValueWithLabel = (
  payment: GraphqlServiceRequestPayment
): string => {
  const total =
    Number(payment?.amount) + Number(payment?.fee) + Number(payment?.tax);
  return !isNaN(total) ? `$${total.toFixed(2)}` : '-';
};
