import { StorageKey, getStorage } from './storage';

export const getWhoIsThisId = async () => {
  try {
    const { value } = await getStorage(StorageKey.WhoIsThisId);
    return value;
  } catch (error) {
    alert(error);
  }
};
