import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';

export const FullScreenLoader = () => {
  const i18n = useTranslation();
  return (
    <IonLoading
      isOpen
      message={i18n.t('loading') ?? 'Loading...'}
      spinner="circles"
      data-testid="loader"
    />
  );
};
