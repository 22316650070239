import * as React from 'react';

export const ConnectNotSelected = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M17.7927 6.75122C17.6089 9.22986 15.7299 11.2519 13.6671 11.2519C11.6043 11.2519 9.72204 9.23033 9.54154 6.75122C9.35402 4.17273 11.1824 2.25058 13.6671 2.25058C16.1519 2.25058 17.9803 4.21961 17.7927 6.75122Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6665 14.251C9.5884 14.251 5.44934 16.501 4.68341 20.7479C4.58966 21.2597 4.88075 21.751 5.417 21.751H21.9165C22.4528 21.751 22.7425 21.2597 22.6501 20.7479C21.8837 16.501 17.7447 14.251 13.6665 14.251Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeMiterlimit="10"
    />
    <path
      d="M4.29199 8.25195V13.5041"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.92011 10.8769H1.66797"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
