import {
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner,
  useIonViewDidEnter,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  EditButton,
  SendMessageButton,
  StyledIonContent,
  StyledIonFooterToolbar,
  StyledIonInput,
  UserMessageCard,
} from './NewCircleRequestChatPage.styles';
import { chevronForwardOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../providers/authProvider';
import { useInterpretCircleRequestLazyQuery } from '../../graphql/generated';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button/Button';
import { logger } from '../../logger';
import { ROUTES } from '../../constants/routes';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { SystemChatMessage } from '../../components/SystemChatMessage/SystemChatMessage';

export const NewCircleRequestChatPage: React.FC = () => {
  const i18n = useTranslation();
  const { user } = useAuthContext();
  const [interpretCircleRequest, { data, loading: isInterpreting }] =
    useInterpretCircleRequestLazyQuery();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageSent, setMessageSent] = useState<string>('');
  const inputRef = useRef<HTMLIonInputElement>(null);

  const history = useHistory();

  useEffect(() => {
    if (isEditing) {
      void inputRef?.current?.setFocus();
    }
  }, [isEditing]);

  useIonViewDidEnter(() => {
    void inputRef?.current?.setFocus();
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleContinue = () => {
    history.push({
      pathname: ROUTES.CIRCLE_FORM,
      state: {
        text: message,
        category: data?.interpretCircleRequest?.category || '',
        recurring: data?.interpretCircleRequest?.recurring || false,
      },
    });
  };

  const handleSubmit = async () => {
    if (!message) return;

    setMessageSent(message);
    setIsEditing(false);

    try {
      await interpretCircleRequest({
        variables: { text: message },
      });
    } catch (err) {
      logger.error({
        tag: '[CIRCLE_AI][HANDLE_SUBMIT]',
        message: `Fail to interpret circle request: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      // NOTE: fail silently for the user and navigate anyway
    }
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <StyledIonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-bottom">
            <IonCol>
              <SecondaryTitle>
                <Trans i18nKey="NewCircleRequestChatPage.Title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <SystemChatMessage
                message={
                  <Trans
                    i18nKey="NewCircleRequestChatPage.InitialChatMessage"
                    values={{ firstName: user?.firstName }}
                  />
                }
              />
            </IonCol>
          </IonRow>

          {messageSent ? (
            <>
              <IonRow className="ion-padding-vertical ion-justify-content-end">
                <IonCol size="auto">
                  <UserMessageCard>{messageSent}</UserMessageCard>
                </IonCol>
              </IonRow>
              <IonRow className="ion-padding-bottom ion-justify-content-end">
                <IonCol size="auto">
                  {isInterpreting ? (
                    <IonSpinner name="dots"></IonSpinner>
                  ) : (
                    <EditButton onClick={handleEdit}>
                      <Trans i18nKey="NewCircleRequestChatPage.Edit" />
                    </EditButton>
                  )}
                </IonCol>
              </IonRow>
            </>
          ) : null}
          {messageSent && !isInterpreting ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <SystemChatMessage
                  message={
                    <Trans i18nKey="NewCircleRequestChatPage.AutomaticAnswer" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}
        </IonGrid>
      </StyledIonContent>
      <IonFooter>
        <>
          <StyledIonFooterToolbar show={Boolean(messageSent && !isEditing)}>
            <Button
              color="primary-orange"
              onClick={handleContinue}
              disabled={isInterpreting}
            >
              <Trans i18nKey="NewCircleRequestChatPage.Cta" />
            </Button>
          </StyledIonFooterToolbar>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void handleSubmit();
            }}
          >
            <StyledIonFooterToolbar show={!(messageSent && !isEditing)}>
              <StyledIonInput
                ref={inputRef}
                slot="start"
                type="text"
                placeholder={i18n.t(
                  'NewCircleRequestChatPage.InputPlaceholder'
                )}
                value={message}
                autofocus
                onIonInput={(e) => {
                  setMessage(e.detail.value!);
                }}
              />
              <SendMessageButton
                type="submit"
                slot="end"
                shape="round"
                color="primary-blue"
                className="ion-margin-start ion-no-padding"
              >
                <IonIcon
                  icon={chevronForwardOutline}
                  style={{ color: '#fff' }}
                />
              </SendMessageButton>
            </StyledIonFooterToolbar>
          </form>
        </>
      </IonFooter>
    </IonPage>
  );
};
