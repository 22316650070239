import React, { useState } from 'react';
import { CustomAlertV2 } from '../CustomAlert/CustomAlert';
import { Textarea } from './AlertWithInput.styles';

type AlertProps = Omit<
  React.ComponentProps<typeof CustomAlertV2>,
  'firstButtonAction' | 'secondButtonAction'
>;
type AlertWithInputProps = AlertProps & {
  onSubmit: (input: string) => void;
  onCancel: () => void;
  placeholder?: string;
};
export const AlertWithInput: React.FC<AlertWithInputProps> = ({
  onSubmit,
  onCancel,
  placeholder,
  ...rest
}) => {
  const [input, setInput] = useState<string>('');
  return (
    <CustomAlertV2
      {...rest}
      onDidDismiss={onCancel}
      firstButtonAction={() => {
        onSubmit(input);
        setInput('');
      }}
      secondButtonAction={() => {
        setInput('');
        onCancel();
      }}
    >
      <Textarea
        aria-label="Alert Input"
        fill="outline"
        placeholder={placeholder}
        value={input}
        onIonInput={(e) => {
          setInput(e.detail.value ?? '');
        }}
      />
    </CustomAlertV2>
  );
};
