import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { BodyText, BodyTextMedium } from '../Typography/Body/Body';
import { Trans, useTranslation } from 'react-i18next';
import { GetCircleRequestQuery } from '../../graphql/generated';
import { Accordion } from '../Accordion/Accordion';
import {
  CardItem,
  CardItemTitle,
  TimelineEventDayName,
  TimelineEventDayNumber,
  TimelineEventLine,
  TimelineEventMonth,
  TimelineTimeContainer,
} from './ActivityCardItemRequestTimeline.styles';
import { useAuthContext } from '../../providers/authProvider';
import dayjs from 'dayjs';
import { HistoryIcon } from '../../assets/images/HistoryIcon';

type Timeline = GetCircleRequestQuery['getCircleRequest']['Timeline'];

type ActivityCardItemRequestTimelineProps = {
  timeline: Timeline;
};
export const ActivityCardItemRequestTimeline: React.FC<
  ActivityCardItemRequestTimelineProps
> = ({ timeline }) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const sortedAndFilteredTimeline = timeline
    ? [...timeline]
        .filter((a) => a.isAppVisible)
        .sort((a, b) => {
          return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
        })
    : [];
  return (
    <Accordion
      header={
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="ion-align-self-center">
              <HistoryIcon
                color="var(--colors-primary-blue)"
                style={{
                  width: '24px',
                  height: '24px',
                  marginInlineEnd: '8px',
                }}
              />
            </IonCol>
            <IonCol className="ion-align-self-center">
              <BodyText color="var(--colors-primary-blue)">
                {t('ActivityCardItemRequestTimeline.Label')}
              </BodyText>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      content={
        <IonGrid>
          {sortedAndFilteredTimeline.map((timelineEvent, idx) => (
            <IonRow
              className="ion-margin-vertical"
              key={String(timelineEvent.createdAt) + String(idx)}
            >
              <IonCol size="auto" className="ion-margin-end">
                <TimelineTimeContainer>
                  <TimelineEventMonth>
                    {dayjs(timelineEvent.createdAt).format('MMM')}
                  </TimelineEventMonth>
                  <TimelineEventDayNumber>
                    {dayjs(timelineEvent.createdAt).format('DD')}
                  </TimelineEventDayNumber>
                  <TimelineEventDayName>
                    {dayjs(timelineEvent.createdAt).format('ddd')}
                  </TimelineEventDayName>
                  <TimelineEventLine />
                </TimelineTimeContainer>
              </IonCol>
              <IonCol>
                <CardItem className="ion-padding">
                  <CardItemTitle>{timelineEvent.title}</CardItemTitle>
                  <BodyTextMedium>
                    <Trans
                      i18nKey="ActivityCardItemRequestTimeline.TimelineEventDetail"
                      values={{
                        user:
                          timelineEvent?.User?.id === user?.id
                            ? t('you')
                            : String(timelineEvent.User?.fullName),
                        time: dayjs(timelineEvent.createdAt).format('h:mm A'),
                      }}
                    />
                  </BodyTextMedium>
                </CardItem>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      }
    />
  );
};
