import { useCallback } from 'react';
import { useNotificationParamsHandler } from './useNotificationParamsHandler';
import { QUERY_PARAMS, ROUTES } from '../../constants/routes';
import { useAuthContext } from '../../providers/authProvider';
import { useHistory } from 'react-router';
import { logger } from '../../logger';

export const useDeeplinkHandler = () => {
  const history = useHistory();
  const handleNotificationParams = useNotificationParamsHandler();
  const { user } = useAuthContext();

  return useCallback(
    (deeplink: string) => {
      try {
        const url = new URL(deeplink);
        const notificationId = url.searchParams.get(
          QUERY_PARAMS.NOTIFICATION_ID
        );
        const notificationChannel = url.searchParams.get(
          QUERY_PARAMS.NOTIFICATION_CHANNEL
        );
        if (notificationId && user) {
          void handleNotificationParams({
            notificationId,
            notificationChannel,
          });
        }

        if (!user) {
          const searchParams = new URLSearchParams();
          searchParams.append(QUERY_PARAMS.REDIRECT, url.pathname + url.search);
          history.replace(`${ROUTES.LOGIN}?${searchParams.toString()}`);
        } else if (url.pathname && history.location.pathname !== url.pathname) {
          history.push(`${url.pathname}${url.search}`);
        }
      } catch (err) {
        logger.error({
          tag: '[DeeplinkHandler]',
          message: 'Fail at deeplink handler',
          error: err as Error,
        });
      }
    },
    [user, history, handleNotificationParams]
  );
};
