import styled from 'styled-components';
import Circle from '../../assets/circle.svg';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  ion-select {
    --placeholder-color: #424242;
    --placeholder-opacity: 1;

    justify-content: center;
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.408px;

    .item-inner {
      border: none;
    }
  }

  ion-select-option,
  .select-interface-option,
  ion-label {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.408px;
    max-width: 290px !important;
  }
  ion-select::part(placeholder),
  ion-select::part(text) {
    flex: 0 0 auto;
  }
  ion-select::part(text) {
    width: fit-content;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-select::part(icon) {
    width: 46px;
    height: 46px;
    content: url(${Circle});
  }
  ion-radio {
    display: flex;
    align-items: center;
  }
  ion-popover::part(content) {
    --width: 200px;
  }
`;
