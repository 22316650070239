import { IonIcon } from '@ionic/react';
import { checkmarkOutline } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { EmptyOpenActivity } from '../../../../assets/images/EmptyOpenActivity';
import { Button } from '../../../../components/Button/Button';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../../constants/routes';
import { SecondaryTitle } from '../../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../../components/Typography/Body/Body';

export const ConfirmedActivitiesEmptyState: React.FC = () => {
  return (
    <div className="ion-text-center">
      <IonIcon
        src={checkmarkOutline}
        style={{
          fontSize: '47px',
          minWidth: '47px',
          color: '#666666',
        }}
      />
      <SecondaryTitle>
        <Trans i18nKey="activities.emptyState.title" />
      </SecondaryTitle>
    </div>
  );
};

export const RequestedActivitiesEmptyState: React.FC = () => {
  const history = useHistory();
  const handleSupport = () => {
    history.push(ROUTES.MEMBERSHIP.ABOUT_FRIEND);
  };

  return (
    <div className="ion-text-center">
      <EmptyOpenActivity />
      <SecondaryTitle>
        <Trans i18nKey="activities.emptyState.title" />
      </SecondaryTitle>
      <div className="ion-margin-top ion-margin-bottom">
        <BodyText>
          <Trans i18nKey="activities.emptyState.description" />
        </BodyText>
      </div>
      <Button onClick={handleSupport} color="primary-orange">
        <Trans i18nKey="activities.emptyState.support" />
      </Button>
    </div>
  );
};
