import {
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
} from '@ionic/react';
import { Geolocation } from '@capacitor/geolocation';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  DoneButton,
  DoneLabel,
  LocationsHeader,
  MainContainer,
  Subtitle,
  Title,
} from './locations.styles';
import { chevronBack, navigate } from 'ionicons/icons';
import { useCallback, useEffect, useState } from 'react';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import ModalDeviceLocation from '../../components/ModalDeviceLocation/ModalDeviceLocation';
import ModalAddAddress from '../../components/ModalAddAddress/ModalAddAddress';
import { useHistory } from 'react-router-dom';
import { useGetGooglePlacesApiKeyQuery } from '../../graphql/generated';

const LocationsPage: React.FC = () => {
  const i18n = useTranslation();
  const [, setPermission] = useState<boolean>(false);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenModalAddress, setOpenModalAddress] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { data: apiKeyData, loading: loadingApiKey } =
    useGetGooglePlacesApiKeyQuery();
  const googlePlaceApiKey =
    apiKeyData?.getGooglePlacesApiKey.apiKey ||
    'AIzaSyBuBLG_5YjAoQqWlroik6tvWDMdXKIonNI';

  const goToSearchAddress = useCallback(() => {
    setOpenModalAddress(true);
  }, []);

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const checkedPermission = await Geolocation.checkPermissions();
        if (checkedPermission.coarseLocation === 'granted') {
          setPermission(true);
        } else if (
          checkedPermission.coarseLocation === 'denied' ||
          checkedPermission.coarseLocation === 'prompt'
        ) {
          setPermission(false);
          setOpenModal(true);
        }
      } catch (error) {
        throw new Error('error');
      }
    };
    void fetchPermission().catch((e: string) => new Error(e));
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-padding">
        <LocationsHeader style={{ marginTop: 44 }}>
          <BackButton onClick={() => history.goBack()}>
            <IonIcon
              icon={chevronBack}
              color="primary"
              style={{ width: 22, height: 26 }}
            />
            <BackLabel>{i18n.t('back')}</BackLabel>
          </BackButton>
          <DoneButton onClick={() => history.goBack()}>
            <DoneLabel>{i18n.t('done')}</DoneLabel>
          </DoneButton>
        </LocationsHeader>
      </IonHeader>
      <IonContent>
        <MainContainer className="ion-padding">
          <ModalDeviceLocation
            isOpenModal={isOpenModal}
            setOpenModal={setOpenModal}
          />
          <>
            {isOpenModalAddress && !loadingApiKey && (
              <ModalAddAddress
                apiKey={String(googlePlaceApiKey)}
                isOpenModalAddress={isOpenModalAddress}
                setModalToParent={(isOpen) => setOpenModalAddress(isOpen)}
              />
            )}
          </>
          <Title>{i18n.t('whereLive')}</Title>
          <Subtitle width={width}>{i18n.t('ourAim')}</Subtitle>
          <IonItem onClick={goToSearchAddress}>
            <IonInput
              disabled={true}
              label={i18n.t('address') || ''}
              labelPlacement="stacked"
              placeholder={i18n.t('addOrChoose') || ''}
            ></IonInput>
            <IonIcon icon={navigate} style={{ marginTop: 4 }} />
          </IonItem>
        </MainContainer>
      </IonContent>
    </IonPage>
  );
};

export default LocationsPage;
