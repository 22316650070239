import { IonButton } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  width: 100%;
  height: 90vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  h2 {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: ${pixelToRem(24)};
    line-height: 120%;
    color: #212121;
  }
`;

export const HeaderButton = styled(IonButton)`
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  --padding-end: 0;
  --padding-start: 0;
`;

export const Content = styled.div``;
