import React from 'react';
import {
  IonAccordContainer,
  MainContainer,
  StyledItem,
} from '../ActivitiesSections/ActivitiesSections.styles';
import { IonAccordion, IonLabel, IonSkeletonText } from '@ionic/react';

type ActivityStatusSkeletonProps = {
  count?: number;
};
const ActivityStatusSkeleton: React.FC<ActivityStatusSkeletonProps> = ({
  count = 4,
}) => {
  return (
    <MainContainer>
      <IonAccordContainer>
        {new Array(count).fill(null).map(() => (
          <IonAccordion key={Math.random()} toggleIconSlot="end">
            <StyledItem slot="header">
              <div>
                <IonLabel
                  style={{
                    color: '#ea8231',
                    fontSize: '14px',
                    marginTop: '8px',
                    marginBottom: '10px',
                  }}
                >
                  <IonSkeletonText animated style={{ width: '200px' }} />
                </IonLabel>
              </div>
            </StyledItem>
          </IonAccordion>
        ))}
      </IonAccordContainer>
    </MainContainer>
  );
};

export default ActivityStatusSkeleton;
