import React from 'react';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  useIonRouter,
} from '@ionic/react';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';
import { Trans } from 'react-i18next';
import {
  UserInvitesType,
  useCreateUserInvitesMutation,
} from '../../graphql/generated';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { useParams } from 'react-router';
import { useAuthContext } from '../../providers/authProvider';
import {
  InviteCircleMemberForm,
  InviteCircleMemberFormFields,
} from '../../components/Forms/InviteCircleMemberForm';
import { ROUTES } from '../../constants/routes';

const InviteCircleMemberPage: React.FC = () => {
  const { globalAlert } = useAppContext();
  const router = useIonRouter();
  const { customerId } = useParams<{ customerId: string }>();
  const { user } = useAuthContext();
  const [createUserInvite] = useCreateUserInvitesMutation();
  const handleSubmit = async (values: InviteCircleMemberFormFields) => {
    try {
      await createUserInvite({
        variables: {
          userInvitesInput: {
            invites: [
              {
                ...values,
                inviteType: UserInvitesType.CircleMember,
                customerId: Number(customerId) || user?.customerId,
              },
            ],
          },
        },
      });
      router.push(ROUTES.INVITES.SUCCESS);
    } catch (err) {
      logger.error({
        tag: '[InviteCircleMemberPage]',
        message: `Fail trying submitting invite circle member form: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical">
            <IonCol>
              <SecondaryTitle>
                <Trans i18nKey="myAccount.invite" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <InviteCircleMemberForm onSubmit={handleSubmit} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default InviteCircleMemberPage;
