import styled from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

export const SummaryContainer = styled.div`
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding: 16px;
`;

export const Subtitle = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: #595959;
`;
