/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonToggle,
  IonTextarea,
  IonSpinner,
  IonPage,
  useIonViewWillEnter,
} from '@ionic/react';
import {
  CardActionContainer,
  InputContainer,
  StyledIonAlert,
  StyledIonAlertGoBack,
  StyledIonContent,
  StyledIonHeader,
  Wrapper,
} from './circle-form.styles';
import {
  arrowForwardOutline,
  calendarClearOutline,
  checkmarkCircle,
  chevronBack,
} from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { HomeIcon } from '../../assets/images/HomeIcon';
import { RecurringService } from '../../assets/images/RecurringService';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router';
import AvailabilityModal from '../../components/AvailabilityModal/AvailabilityModal';
import AnimateMotionDiv from '../../components/AnimateMotionDiv/AnimateMotionDiv';
import { CREATE_CIRCLE_REQUEST_MUTATION } from '../../graphql/activities';
import { useMutation } from '@apollo/client';
import {
  GlobalRole,
  GraphqlAddresses,
  ServiceRequestAvailabilityInput,
  ServiceRequestType,
  useGetAddressesQuery,
  useGetCustomerQuery,
  useGetGooglePlacesApiKeyQuery,
  useGetZipCodeMatchesBusinessRulesQuery,
  useListRequestCategoriesQuery,
} from '../../graphql/generated';
import RideForm from '../../components/RideForm/RideForm';
import {
  AddNewAddressErrorZipCode,
  AddNewAddressText,
  AddNewAddressTextOrange,
  FrequencyInfo,
  FrequencyTitle,
} from '../service-form/service-form.styles';
import { AuthContext } from '../../providers/authProvider';
import { getWhoIsThisId } from '../../utils/whoIsThisHelper';
import { CircleFormEnum, FeatureNames } from '../../constants/enums';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { ModalAddressForm } from '../../components/ModalAddressForm/ModalAddressForm';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

type LocationProps = {
  state: {
    text: string;
    category: string;
    recurring: boolean;
  };
};

const CircleForm: React.FC = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalAlert } = useAppContext();
  const location = useLocation() as unknown as LocationProps;
  const history = useHistory();
  const i18n = useTranslation();
  const { transformAvailabilityToTimezone } = useDateWithTimezone();

  const { data: apiKeyData } = useGetGooglePlacesApiKeyQuery();
  const googlePlaceApiKey =
    apiKeyData?.getGooglePlacesApiKey.apiKey ||
    'AIzaSyBuBLG_5YjAoQqWlroik6tvWDMdXKIonNI';
  const { user: currentUser } = useContext(AuthContext);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>();
  const [isMyCircleSelected, setIsMyCircleSelected] = useState<boolean>(false);

  const [dateType, setDateType] = useState<string>(CircleFormEnum.Flexible);
  const [categoryValue, setCategoryValue] = useState<string>(
    location?.state?.category
  );
  const [availabilitySaved, setAvailabilitySaved] = useState<boolean>(false);
  const [showGoBackAlert, setShowGoBackAlert] = useState<boolean>(false);
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [showAlertError, setShowAlertError] = useState<boolean>(false);
  const [showOtherCategory, setShowOtherCategory] = useState<boolean>(false);
  const [showRideForm, setShowRideForm] = useState<boolean>(false);
  const [showTimeDate, setShowTimeDate] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [addressId, setAddressId] = useState<number | null>(null);
  const [isOpenAddressModal, setOpenAddressModal] = useState<boolean>(false);

  const [userDefaultAddress, setUserDefaultAddress] =
    useState<GraphqlAddresses>();
  const [showAvailabilityModal, setShowAvailabilityModal] =
    useState<boolean>(false);

  const {
    data,
    loading: loadingAddress,
    refetch: refetchAddresses,
  } = useGetAddressesQuery({
    skip: !selectedCustomerId,
    variables: {
      customerId: Number(selectedCustomerId),
    },
  });

  const { data: zipCodeCheck } = useGetZipCodeMatchesBusinessRulesQuery({
    variables: {
      zipCode: zipCode ? zipCode : userDefaultAddress?.zipCode ?? '',
    },
    skip: !userDefaultAddress,
  });

  const { data: customerData, refetch } = useGetCustomerQuery({
    skip: !selectedCustomerId,
    variables: {
      customerId: Number(selectedCustomerId),
    },
  });

  useIonViewWillEnter(() => {
    void refetch();
  });

  const [createCircleRequest, { loading }] = useMutation(
    CREATE_CIRCLE_REQUEST_MUTATION
  );

  const { data: getListRequestCategories } = useListRequestCategoriesQuery({
    variables: {
      circleRequest: true,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    resetField,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm();

  const [tentativeScheduleValue] = watch(['tentativeSchedule']);

  const goBack = useCallback(() => {
    const FormValues = getValues([
      'description',
      'category',
      'askTo',
      'tentativeSchedule',
    ]);
    if (FormValues.length > 0) {
      setShowGoBackAlert(true);
    } else {
      history.goBack();
    }
  }, []);

  const clearForm = useCallback(() => {
    resetField('description');
    resetField('category');
    resetField('askTo');
    setCategoryValue('');
    resetField('tentativeSchedule');
    setIsRecurring(false);
    setDateType(CircleFormEnum.Flexible);
    setShowOtherCategory(false);
    setShowRideForm(false);
    setShowTimeDate(false);
  }, []);

  const errorText = {
    description: {
      type: 'manual',
      name: 'description',
      message: 'Description is required',
    },
    askTo: {
      type: 'manual',
      name: 'description',
      message: 'You must select a option',
    },
    category: {
      type: 'manual',
      name: 'description',
      message: 'Category is required',
    },
    tentativeSchedule: {
      type: 'manual',
      name: 'description',
      message: 'You must select an option',
    },
    frequency: {
      type: 'manual',
      name: 'description',
      message: 'You must add a frequency',
    },
    ride: {
      dropoffAddress: {
        addressLine1: {
          type: 'manual',
          name: 'description',
          message: 'You must add a Drop-off Location',
        },
      },
    },
  };

  const handleFormErrors = ({
    description,
    category,
    tentativeSchedule,
    frequency,
    ride,
    askTo,
  }: any) => {
    if (description === '') {
      setError('description', errorText.description);
    }
    if (!category) {
      setError('category', errorText.category);
    }
    if (!askTo) {
      setError('askTo', errorText.askTo);
    }
    if (!tentativeSchedule) {
      setError('tentativeSchedule', errorText.tentativeSchedule);
    }
    if (frequency === '') {
      setError('frequency', errorText.frequency);
    }
    if (!ride?.dropoffAddress?.addressLine1) {
      setError('dropoffAddress', errorText.ride?.dropoffAddress.addressLine1);
    }
    if (description === '' || !category || !tentativeSchedule) {
      setShowAlertError(true);
    }
    if (description !== '' && category && tentativeSchedule) return true;
  };

  const alertErrorMessage = (): string =>
    `
      ${errors.description ? `・${errors.description?.message as string}` : ''}
      ${
        errors.category?.message
          ? `・${errors.category?.message as string}`
          : ''
      }
      ${
        errors.tentativeSchedule?.message
          ? `・${errors.tentativeSchedule?.message as string}`
          : ''
      }
  `;
  const onSubmit = useCallback(
    async (formData: any): Promise<void> => {
      try {
        if (formData.category !== CircleFormEnum.Ride) {
          formData.frequency = formData.frequency?.toLowerCase();
          formData.addressId = addressId
            ? Number(addressId)
            : userDefaultAddress?.id;
          delete formData.ride;
          delete formData.home;
        }
        if (formData.category === CircleFormEnum.Ride) {
          delete formData.home;
          formData.ride.pickupAddress.addressLine1 =
            userDefaultAddress?.addressLine1;
          formData.ride.pickupAddress.addressLine2 =
            userDefaultAddress?.addressLine2;
          formData.ride.pickupAddress.city = userDefaultAddress?.city;
          formData.ride.pickupAddress.state = userDefaultAddress?.state;
          formData.ride.pickupAddress.country = userDefaultAddress?.country;
          formData.ride.pickupAddress.zipCode = userDefaultAddress?.zipCode;

          if (!formData.ride?.dropoffAddress?.addressLine1) {
            formData.ride.dropoffAddress = {};
            formData.ride.dropoffAddress.addressLine1 = '';
            formData.ride.dropoffAddress.addressLine2 = '';
            formData.ride.dropoffAddress.city = '';
            formData.ride.dropoffAddress.state = '';
            formData.ride.dropoffAddress.country = '';
            formData.ride.dropoffAddress.zipCode = '';
          }
        }
        if (formData.askTo.includes(CircleFormEnum.MyCircle)) {
          // send empty array if askTo is myCircle
          formData.askTo = [];
        }
        if (handleFormErrors(formData)) {
          if (formData?.availabilities?.length > 0) {
            await createCircleRequest({
              variables: {
                data: {
                  ...formData,
                  availabilities: formData.availabilities.map(
                    (availability: ServiceRequestAvailabilityInput) =>
                      transformAvailabilityToTimezone(availability)
                  ),
                },
              },
            });
          } else {
            await createCircleRequest({ variables: { data: formData } });
          }

          history.push(
            ROUTES.SERVICE_SUBMIT_SUCCESS.replace(
              PATH_PARAMS.REQUEST_TYPE,
              ServiceRequestType.Circle
            )
          );
        }
      } catch (err) {
        logger.error({
          tag: '[CIRCLE_FORM_SUBMISSION_ERROR]',
          message: `Something went wrong trying to submit the form: ${
            getApolloErrorMessage(err) || err?.toString()
          }`,
          error: err as Error,
        });
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(err) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
      }
    },
    [data, userDefaultAddress, createCircleRequest, handleFormErrors]
  );

  const handleUserTextRequest = useCallback((text: string) => {
    setValue('category', text);
    setCategoryValue(text);
    if (text === CircleFormEnum.Other) {
      setShowOtherCategory(true);
    }
  }, []);

  const handleCategory = useCallback((category: string) => {
    setValue('category', category);
    setCategoryValue(category);
    if (category === CircleFormEnum.Other) {
      setShowOtherCategory(true);
    } else {
      setShowOtherCategory(false);
    }
    if (category === CircleFormEnum.Ride) {
      setShowRideForm(true);
    } else {
      setShowRideForm(false);
    }
  }, []);

  const handleTimeAndDate = useCallback((value: string) => {
    setShowTimeDate(true);
    if (value === CircleFormEnum.ChooseTimeAndDate) {
      setDateType(CircleFormEnum.AddAvailability);
      setAvailabilitySaved(false);
      setValue('isAvailabilityFlexible', false);
    } else {
      setDateType(CircleFormEnum.Flexible);
      setValue('isAvailabilityFlexible', true);
    }
  }, []);

  useEffect(() => {
    if (data?.getAddresses) {
      setUserDefaultAddress(
        data?.getAddresses.filter(
          (item) => item.isDefault
        )[0] as GraphqlAddresses
      );
    }
  }, [data, loadingAddress]);

  useEffect(() => {
    setValue('requestedBy', currentUser?.id);
    setValue('availabilities', []);
    setValue('description', location?.state?.text);
    setValue('recurring', false);
    setValue('isAvailabilityFlexible', false);
    setValue(
      'home',
      (userDefaultAddress?.addressLine1 ?? '') +
        ', ' +
        (userDefaultAddress?.city ?? '')
    );
    if (getValues('category') === CircleFormEnum.Ride) {
      setShowRideForm(true);
      setValue('ride.pickupAddress.addressLine1', 'Home');
      setValue('ride.dropoffAddress.addressLine1', '');
    }
    getWhoIsThisId()
      .then((res) => {
        setSelectedCustomerId(res);
        setValue('customerId', res);
      })
      .catch((err) => alert(err));
  }, [userDefaultAddress]);

  const handleHome = useCallback((value: string) => {
    const parts = value.split(',');
    const beforeCommaZipCode = parts[0].trim();
    const afterCommaAddressLine = parts[1].trim();
    const lastAddressId = parts[2].trim();
    setValue('home', afterCommaAddressLine + ', ' + beforeCommaZipCode);
    setZipCode(beforeCommaZipCode);
    setAddressId(Number(lastAddressId));
  }, []);

  const circleMembers = useMemo(() => {
    return customerData?.getCustomer?.users
      ?.filter((user) =>
        [GlobalRole.CircleMember].includes(user?.role as GlobalRole)
      )
      .map((circleUser) => ({
        fullName: circleUser?.user?.fullName,
        id: circleUser?.user?.id,
      }));
  }, [customerData]);

  const frequencyOptions = [
    {
      name: i18n.t('serviceForm.weekly'),
    },
    {
      name: i18n.t('serviceForm.biWeekly'),
    },
    {
      name: i18n.t('serviceForm.monthly'),
    },
  ];

  const handleRecurringChange = useCallback((value: string) => {
    setValue('recurring', true);
    setValue('frequency', value);
  }, []);

  const handleOnSaveAddress = async () => {
    setOpenAddressModal(false);
    await refetchAddresses();
  };

  return (
    <IonPage>
      <StyledIonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={goBack}>
              <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <h1>{i18n.t('CircleForm.label.askCircle')}</h1>
        <div className="border" />
      </StyledIonHeader>

      <StyledIonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper hasError={errors.description && true}>
            <h2 className="fist-item">
              {i18n.t('CircleForm.label.doesThisLookRight')}
            </h2>
            <IonTextarea
              counter={true}
              autoGrow
              placeholder={i18n.t(
                'CircleForm.label.pleaseDescribeHowWeCanHelp'
              )}
              {...register('description')}
              onIonBlur={() => clearErrors('description')}
              onIonChange={({ detail }) => {
                handleUserTextRequest(detail.value as string);
              }}
            ></IonTextarea>
            {errors.description && (
              <p className="form-error">
                {errors.description?.message as string}
              </p>
            )}
          </Wrapper>
          <Wrapper hasError={errors.askTo && true}>
            <h2>{i18n.t('CircleForm.label.whoShouldWeAsk')}</h2>
            <IonSelect
              {...register('askTo')}
              labelPlacement="floating"
              interface="popover"
              multiple
              shape="round"
              placeholder="Select"
              onIonChange={({ detail }) => {
                if (detail.value.includes(CircleFormEnum.MyCircle)) {
                  setIsMyCircleSelected(true);
                } else {
                  setIsMyCircleSelected(false);
                }
              }}
              onIonBlur={() => clearErrors('askTo')}
            >
              <IonSelectOption value={CircleFormEnum.MyCircle}>
                {i18n.t('CircleForm.label.myCircle')}
              </IonSelectOption>
              {!isMyCircleSelected &&
                circleMembers?.map((member) => (
                  <IonSelectOption key={member.id} value={member.id}>
                    {member.fullName}
                  </IonSelectOption>
                ))}
              {/*disable if user select my circle,
              we need this to render again the options when the option selection is still open */}
              {isMyCircleSelected &&
                circleMembers?.map((member) => (
                  <IonSelectOption key={member.id} value={null} disabled={true}>
                    {member.fullName}
                  </IonSelectOption>
                ))}
            </IonSelect>
            {errors.askTo && (
              <p className="form-error">{errors.askTo?.message as string}</p>
            )}
          </Wrapper>
          <Wrapper hasError={errors.category && true}>
            <h2>{i18n.t('CircleForm.label.category')}</h2>
            <IonSelect
              {...register('category')}
              labelPlacement="floating"
              interface="popover"
              shape="round"
              placeholder="Select"
              value={categoryValue}
              onIonBlur={() => clearErrors('category')}
              onIonChange={({ detail }) => {
                handleCategory(detail.value as string);
              }}
            >
              {getListRequestCategories?.listRequestCategories.map(
                (serviceType) => (
                  <IonSelectOption
                    key={serviceType.name}
                    value={serviceType.id}
                  >
                    {serviceType.name}
                  </IonSelectOption>
                )
              )}
            </IonSelect>
            {errors.category && (
              <p className="form-error">{errors.category?.message as string}</p>
            )}
            {showRideForm && (
              <AnimateMotionDiv>
                <RideForm
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                  userDefaultAddress={userDefaultAddress}
                  userAddresses={data}
                  onAddressModal={() => setOpenAddressModal(true)}
                  getAddressId={(id: number) => setAddressId(id)}
                  getValues={getValues}
                />
              </AnimateMotionDiv>
            )}
            {showOtherCategory && (
              <AnimateMotionDiv>
                <InputContainer style={{ marginTop: '16px' }}>
                  <IonInput
                    aria-label="Enter category"
                    placeholder="Enter category"
                    {...register('otherCategory')}
                  ></IonInput>
                </InputContainer>
              </AnimateMotionDiv>
            )}
          </Wrapper>

          {!showRideForm && data?.getAddresses.length === 1 && (
            <AnimateMotionDiv>
              <Wrapper
                hasError={!zipCodeCheck?.getZipCodeMatchesBusinessRules.success}
              >
                <h2>Confirm your location</h2>
                <InputContainer>
                  <HomeIcon style={{ marginRight: '8px' }} />
                  <IonInput
                    aria-label="Custom input"
                    placeholder="Home"
                    value={'Home'}
                  ></IonInput>
                </InputContainer>
                {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                  <AddNewAddressErrorZipCode>
                    {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                  </AddNewAddressErrorZipCode>
                )}
              </Wrapper>
            </AnimateMotionDiv>
          )}

          {!showRideForm &&
            data?.getAddresses &&
            data?.getAddresses.length > 1 && (
              <AnimateMotionDiv>
                <Wrapper
                  hasError={
                    !zipCodeCheck?.getZipCodeMatchesBusinessRules.success
                  }
                >
                  <h2>{i18n.t('CircleForm.label.confirmYourLocation')}</h2>

                  <div style={{ position: 'relative', top: 15, left: 16 }}>
                    <HomeIcon style={{ position: 'absolute' }} />
                  </div>
                  <IonSelect
                    {...register('home')}
                    labelPlacement="floating"
                    interface="popover"
                    shape="round"
                    placeholder={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    value={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    onIonBlur={() => clearErrors('home')}
                    className="address-select"
                    onIonChange={({ detail }) => {
                      handleHome(detail.value as string);
                    }}
                  >
                    {data?.getAddresses.map((address) => (
                      <IonSelectOption
                        key={address.id}
                        value={
                          address.zipCode +
                          ',' +
                          address.addressLine1 +
                          ',' +
                          address.id.toString()
                        }
                      >
                        {address.addressLine1}, {address.city}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                    <AddNewAddressErrorZipCode>
                      {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                    </AddNewAddressErrorZipCode>
                  )}
                  {isFeatureEnabled(
                    FeatureNames.AddAddressForRequestEntryPoint
                  ) ? (
                    <AddNewAddressText>
                      {i18n.t('serviceRequest.lookingFor')}
                      <AddNewAddressTextOrange
                        onClick={() => setOpenAddressModal(true)}
                      >
                        {i18n.t('serviceRequest.addANew')}
                      </AddNewAddressTextOrange>
                    </AddNewAddressText>
                  ) : null}
                </Wrapper>
              </AnimateMotionDiv>
            )}

          <Wrapper hasError={errors.tentativeSchedule && true}>
            <h2>{i18n.t('CircleForm.label.whenCanWeMakeThisHappenForYou')}</h2>

            <IonSelect
              placeholder={getValues('tentativeSchedule') || 'Select'}
              {...register('tentativeSchedule')}
              onIonBlur={() => clearErrors('tentativeSchedule')}
              labelPlacement="floating"
              interface="popover"
              onIonChange={({ detail }) => {
                handleTimeAndDate(detail.value as string);
              }}
            >
              <IonSelectOption value="asSoonAsPossible">
                {i18n.t('CircleForm.label.asSoonAsPossible')}
              </IonSelectOption>
              <IonSelectOption value="withinDays">
                {i18n.t('CircleForm.label.within3days')}
              </IonSelectOption>
              <IonSelectOption value="withinWeeks">
                {i18n.t('CircleForm.label.withinAWeek')}
              </IonSelectOption>
              <IonSelectOption value={CircleFormEnum.ChooseTimeAndDate}>
                {i18n.t('CircleForm.label.chooseTime&date')}
              </IonSelectOption>
            </IonSelect>

            {errors.tentativeSchedule && (
              <p className="form-error">
                {errors.tentativeSchedule?.message as string}
              </p>
            )}
          </Wrapper>

          {showTimeDate && (
            <AnimateMotionDiv>
              <Wrapper>
                <IonRadioGroup
                  value={dateType}
                  onIonChange={({ detail }) => {
                    setDateType(detail.value as string);
                    setValue(
                      'isAvailabilityFlexible',
                      detail.value === CircleFormEnum.Flexible ? true : false
                    );
                  }}
                >
                  <IonRadio
                    labelPlacement="end"
                    value="flexible"
                    aria-label="Custom checkbox"
                  >
                    {i18n.t('CircleForm.label.flexible')}
                  </IonRadio>
                  <br />
                  <IonRadio
                    labelPlacement="end"
                    value="addAvailability"
                    aria-label="Custom checkbox that is checked"
                  >
                    {i18n.t('CircleForm.label.addAvailability')}
                  </IonRadio>
                </IonRadioGroup>
              </Wrapper>
            </AnimateMotionDiv>
          )}
          {dateType === CircleFormEnum.AddAvailability && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Wrapper>
                  <CardActionContainer
                    borderColor={availabilitySaved ? '#EA8231' : ''}
                    onClick={() => setShowAvailabilityModal(true)}
                  >
                    <div>
                      <IonIcon
                        icon={calendarClearOutline}
                        style={{
                          color: '#23364B',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                      <span>
                        {i18n.t('CircleForm.label.enterAvailability')}
                      </span>
                    </div>
                    {!availabilitySaved && (
                      <IonIcon
                        icon={arrowForwardOutline}
                        style={{
                          color: '#23364B',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    )}
                    {availabilitySaved && (
                      <IonIcon
                        icon={checkmarkCircle}
                        style={{
                          color: '#EA8231',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    )}
                  </CardActionContainer>
                </Wrapper>
              </motion.div>
            </AnimatePresence>
          )}

          {isFeatureEnabled(FeatureNames.RequestRecurringInput) ? (
            <Wrapper>
              <CardActionContainer>
                <div>
                  <RecurringService />
                  <span>{i18n.t('CircleForm.label.recurringService')}</span>
                </div>
                <IonToggle
                  checked={isRecurring}
                  onIonChange={({ detail }) => {
                    setIsRecurring(detail.checked);
                    setValue('recurring', detail.checked);
                  }}
                  {...register('recurring')}
                ></IonToggle>
              </CardActionContainer>
            </Wrapper>
          ) : null}
          {isRecurring && (
            <AnimateMotionDiv>
              <Wrapper hasError={errors.frequency && true}>
                <FrequencyTitle>
                  {i18n.t('serviceForm.frequency')}
                </FrequencyTitle>
                <FrequencyInfo>{i18n.t('serviceForm.freqInfo')}</FrequencyInfo>
                <IonSelect
                  {...register('frequency')}
                  labelPlacement="floating"
                  interface="popover"
                  shape="round"
                  placeholder={i18n.t('select')}
                  value={categoryValue}
                  onIonBlur={() => clearErrors('frequency')}
                  onIonChange={({ detail }) => {
                    handleRecurringChange(detail.value as string);
                  }}
                  style={{ marginTop: 16 }}
                >
                  {frequencyOptions.map(({ name }) => (
                    <IonSelectOption
                      key={name}
                      aria-label={name || 'All'}
                      className="activity-options"
                      value={name}
                    >
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {errors.frequency && (
                  <p className="form-error">
                    {errors.frequency?.message as string}
                  </p>
                )}
              </Wrapper>
            </AnimateMotionDiv>
          )}

          <Wrapper className="form-actions">
            <IonButton shape="round" size="large" type="submit">
              {i18n.t('CircleForm.label.askCircle!')}&nbsp;{' '}
              {loading && <IonSpinner name="lines-small" />}
            </IonButton>
            <IonButton
              onClick={clearForm}
              shape="round"
              fill="outline"
              type="reset"
            >
              {i18n.t('CircleForm.label.clearForm')}
            </IonButton>
          </Wrapper>
        </form>
        <StyledIonAlertGoBack
          isOpen={showGoBackAlert}
          header="You have unsaved changes. Are you sure you want to go back?"
          onDidDismiss={() => setShowGoBackAlert(false)}
          buttons={[
            {
              text: 'Yes, go back',
              cssClass: 'btn alert-button-confirm',
              handler: () => history.goBack(),
            },
            {
              text: 'Cancel',
              cssClass: 'btn alert-button-cancel',
            },
          ]}
        />
        <StyledIonAlert
          isOpen={showAlertError}
          header="Whoops! It looks like you are missing some information."
          message={alertErrorMessage()}
          onDidDismiss={() => setShowAlertError(false)}
          buttons={[
            {
              text: 'OK',
              cssClass: 'alert-button-confirm',
            },
          ]}
        />
        <AvailabilityModal
          setValue={setValue}
          AvailabilityModalIsOpen={showAvailabilityModal}
          setAvailabilityModalIsOpen={setShowAvailabilityModal}
          setAvailabilitySaved={setAvailabilitySaved}
          tentativeSchedule={tentativeScheduleValue}
        />
      </StyledIonContent>

      <StyledIonAlertGoBack
        isOpen={showGoBackAlert}
        header="You have unsaved changes. Are you sure you want to go back?"
        onDidDismiss={() => setShowGoBackAlert(false)}
        buttons={[
          {
            text: 'Yes, go back',
            cssClass: 'btn alert-button-confirm',
            handler: () => history.goBack(),
          },
          {
            text: 'Cancel',
            cssClass: 'btn alert-button-cancel',
          },
        ]}
      />
      <StyledIonAlert
        isOpen={showAlertError}
        header="Whoops! It looks like you are missing some information."
        message={alertErrorMessage()}
        onDidDismiss={() => setShowAlertError(false)}
        buttons={[
          {
            text: 'OK',
            cssClass: 'alert-button-confirm',
          },
        ]}
      />
      <AvailabilityModal
        setValue={setValue}
        AvailabilityModalIsOpen={showAvailabilityModal}
        setAvailabilityModalIsOpen={setShowAvailabilityModal}
        setAvailabilitySaved={setAvailabilitySaved}
        tentativeSchedule={tentativeScheduleValue}
      />

      <ModalAddressForm
        key={`${isOpenAddressModal}`}
        customerId={Number(selectedCustomerId)}
        googlePlacesApiKey={googlePlaceApiKey}
        isOpen={isOpenAddressModal}
        onDidDismiss={() => setOpenAddressModal(false)}
        onSaveAddress={handleOnSaveAddress}
      />
    </IonPage>
  );
};

export default CircleForm;
