/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Controller, Control } from 'react-hook-form';
import { CustomIonInput, InputContainer } from './CustomInput.style';

export interface InputProps {
  name: string;
  control?: Control;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  ref?: unknown;
  setFocus?: (val: string) => void;
  setOnFocus?: (val: string) => void;
}

const CustomInput: FC<InputProps> = ({
  name,
  control,
  label,
  placeholder,
  register,
  errors,
  ref,
  setOnFocus,
}) => {
  const showError = (_fieldName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const error = errors[_fieldName];
    return error ? (
      <div
        style={{
          marginBottom:
            _fieldName == 'zipCode' || _fieldName == 'state' ? 0 : 16,
        }}
      >
        <div
          style={{
            color: 'red',
            fontWeight: 'bold',
            marginLeft: 20,
            position: 'absolute',
            top: 90,
            zIndex: 999,
          }}
        >
          {error.message || 'Field Is Required'}
        </div>
      </div>
    ) : null;
  };

  // TODO THIS MIGHT BE NEEDED SOON as custom keys pressed
  // const onFocusToTheNext = (value: string) => {
  //   if (value == 'Enter' && setFocus) {
  //     // setFocus('addressLine2');
  //     // window.document.getElementById('addressline2')?.focus();
  //   }
  // };

  return (
    <InputContainer>
      <IonLabel
        style={{
          color: 'rgba(33, 33, 33, 1)',
          marginLeft: 20,
        }}
      >
        {label}
      </IonLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onBlur } }) => (
          <IonItem lines="none">
            <CustomIonInput
              clearInput={true}
              ref={ref}
              placeholder={placeholder}
              onBlur={onBlur}
              id={name}
              // onKeyPress={(e: { key: string }) => onFocusToTheNext(e.key)}
              onFocus={() => (setOnFocus ? void setOnFocus(name) : null)}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              {...register(name)}
            />
          </IonItem>
        )}
      />
      {showError(name)}
    </InputContainer>
  );
};

export default CustomInput;
