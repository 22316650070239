import { IonButton, IonButtons, IonIcon, IonToolbar } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React from 'react';
import { StyledIonHeader } from './HeaderWithTitle.styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

type HeaderWithTitleProps = {
  title: string;
  style?: React.CSSProperties;
  onBack?: () => void;
};
const HeaderWithTitle: React.FC<HeaderWithTitleProps> = ({
  title,
  style,
  onBack,
}) => {
  const history = useHistory();
  const i18n = useTranslation();

  return (
    <StyledIonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            fill="clear"
            onClick={onBack ? onBack : () => history.goBack()}
          >
            <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
            {i18n.t('back')}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <h1 style={style}>{title}</h1>
      </IonToolbar>
    </StyledIonHeader>
  );
};

export default HeaderWithTitle;
