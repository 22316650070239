import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 700;
    font-size: ${pixelToRem(24)};
    line-height: 120%;
    text-align: center;
    color: #000000;
    width: 327px;
    margin: 8px 0 16px;
  }
`;

export const ButtonContainer = styled.div`
  bottom: 42px;
  position: absolute;
  width: 100%;
`;
