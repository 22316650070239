import styled from 'styled-components';

import { IonIcon } from '@ionic/react';
import {
  checkmarkCircle,
  locationOutline,
  time,
  timeOutline,
} from 'ionicons/icons';
import { Trans } from 'react-i18next';
import {
  AppointmentRequestStatus,
  GraphqlAppointmentRequest,
  GraphqlServiceRequest,
  ServiceRequestCategoriesEnum,
  ServiceRequestStatus,
} from '../../../../graphql/generated';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Space } from '../../../../components/Space/Space';
import { ACTIVITIES_CATEGORIES_SRC_ICONS } from '../../../../components/ActivityCard/ActivityCard';
import { ROUTES } from '../../../../constants/routes';
import { pixelToRem } from '../../../../utils/helper';
import { formatAddress } from '../../../../utils/address/formatAddress';
import { Refresh } from '../../../../assets/images/Refresh';
import { RequestCategoryName } from '../../../../components/RequestCategoryName/RequestCategoryName';
import { useDateWithTimezone } from '../../../../utils/hooks/useDateWithTimezone';
import { DayjsDateInput } from '../../../../utils/date';

interface CardProps {
  borderColor: string;
  opacity?: number;
}
const Card = styled.article<CardProps>`
  display: flex;
  padding: 11px 16px 16px 16px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: #fff;
  color: var(--colors-primary-blue);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  opacity: ${({ opacity }) => opacity || 1};
`;

const Title = styled.div`
  font-size: ${() => pixelToRem(18)};
  font-weight: 500;
  line-height: 140%;

  display: flex;
  align-items: center;
  gap: 12px;
`;

const ScheduledAt = styled.div`
  font-size: ${() => pixelToRem(14)};
  font-weight: 400;
  line-height: 140%;
`;

const Description = styled.div`
  font-size: ${() => pixelToRem(14)};
  font-weight: 400;
  line-height: 140%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface AppointmentStatusProps {
  backgroundColor: string;
  color: string;
}
const AppointmentStatus = styled.div<AppointmentStatusProps>`
  padding: 0px 7px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  text-align: center;
  font-family: Roboto;
  font-size: ${() => pixelToRem(13)};
  font-weight: 700;
  line-height: 140%;
`;

const AskTo = styled.div`
  font-size: ${() => pixelToRem(14)};
  font-weight: 600;
  line-height: 140%;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const AskToMesssge: React.FC<{ askTo: number[] }> = ({ askTo }) => {
  switch (askTo.length) {
    case 1:
      return (
        <AskTo>
          <Trans i18nKey="circleMemberActivities.activitySentTo.sentToYou" />
        </AskTo>
      );
    case 2:
      return (
        <AskTo>
          <Trans i18nKey="circleMemberActivities.activitySentTo.sentToYouAndSomebodyElse" />
        </AskTo>
      );
    default:
      return (
        <AskTo>
          <Trans
            i18nKey="circleMemberActivities.activitySentTo.sentToYouAndOthers"
            values={{ rest: `+${askTo.length - 1}` }}
          />
        </AskTo>
      );
  }
};

interface ActivityIconProps {
  src: string;
  color: string;
}
interface ActivityStyles {
  card: CardProps;
  appointmentStatus?: AppointmentStatusProps & { label: ReactNode };
  icon: ActivityIconProps;
  scheduledAt?: ReactNode;
  askTo?: ReactNode;
}

const getStyles = (
  appointmentRequest: GraphqlAppointmentRequest,
  formatTimeInTimezoneFunction: (
    date: DayjsDateInput,
    timezone?: string
  ) => string
): ActivityStyles => {
  if (
    appointmentRequest.status === AppointmentRequestStatus.Accepted &&
    appointmentRequest?.CircleRequest?.status === ServiceRequestStatus.Completed
  ) {
    return {
      card: {
        borderColor: '#3F51B5',
        opacity: 0.5,
      },
      appointmentStatus: {
        backgroundColor: '#3F51B5',
        color: 'white',
        label: <Trans i18nKey="enums.appointmentStatus.completed" />,
      },
      icon: {
        src: checkmarkCircle,
        color: '#3F51B5',
      },
      scheduledAt: (
        <ScheduledAt>
          {formatTimeInTimezoneFunction(
            appointmentRequest.CircleRequest.scheduledAt
          )}
        </ScheduledAt>
      ),
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Accepted) {
    return {
      card: {
        borderColor: 'var(--interaction-success)',
      },
      icon: {
        src: checkmarkCircle,
        color: 'var(--interaction-success)',
      },
      scheduledAt: (
        <ScheduledAt>
          {formatTimeInTimezoneFunction(
            appointmentRequest?.CircleRequest?.scheduledAt
          )}
        </ScheduledAt>
      ),
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Declined) {
    return {
      card: {
        borderColor: 'var(--colors-primary-blue)',
      },
      appointmentStatus: {
        backgroundColor: 'var(--interaction-error)',
        color: 'white',
        label: <Trans i18nKey="enums.appointmentStatus.declined" />,
      },
      icon: {
        src: time,
        color: 'var(--colors-primary-blue)',
      },
      askTo: (
        <AskToMesssge askTo={appointmentRequest?.CircleRequest?.askTo || []} />
      ),
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Open) {
    return {
      card: {
        borderColor: 'var(--colors-primary-blue)',
      },
      appointmentStatus: {
        backgroundColor: 'var(--sunset-orange-4)',
        color: 'var(--body-text-900)',
        label: <Trans i18nKey="enums.appointmentStatus.open" />,
      },
      icon: {
        src: time,
        color: 'var(--colors-primary-blue)',
      },
      askTo: (
        <AskToMesssge askTo={appointmentRequest.CircleRequest?.askTo || []} />
      ),
    };
  }

  // default
  return {
    card: {
      borderColor: '',
    },
    appointmentStatus: {
      backgroundColor: '',
      color: '',
      label: null,
    },
    icon: {
      src: '',
      color: '',
    },
  };
};

interface RequestedServiceRequestStyles {
  appointmentStatus: AppointmentStatusProps & { label: ReactNode };
}
const getRequestedServiceRequestStyles = (
  appointmentRequest: GraphqlAppointmentRequest,
  serviceRequest: GraphqlServiceRequest
): RequestedServiceRequestStyles => {
  if (appointmentRequest.status === AppointmentRequestStatus.Open) {
    return {
      appointmentStatus: {
        backgroundColor: 'var(--sunset-orange-4)',
        color: 'var(--body-text-900)',
        label: <Trans i18nKey="enums.appointmentStatus.open" />,
      },
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Declined) {
    return {
      appointmentStatus: {
        backgroundColor: 'var(--interaction-error)',
        color: 'white',
        label: <Trans i18nKey="enums.appointmentStatus.declined" />,
      },
    };
  }

  if (
    (appointmentRequest.status === AppointmentRequestStatus.Accepted ||
      appointmentRequest.status === AppointmentRequestStatus.Assigned) &&
    serviceRequest.status !== ServiceRequestStatus.Confirmed
  ) {
    return {
      appointmentStatus: {
        backgroundColor: 'var(--colors-golden-purple)',
        color: 'white',
        label: <Trans i18nKey="enums.appointmentStatus.pending" />,
      },
    };
  }

  return {
    appointmentStatus: {
      backgroundColor: '',
      color: '',
      label: null,
    },
  };
};

interface ConfirmedServiceRequestStyles {
  appointmentStatus?: AppointmentStatusProps & { label: ReactNode };
  card: CardProps;
}
const getConfirmedServiceRequestStyles = (
  serviceRequest: GraphqlServiceRequest
): ConfirmedServiceRequestStyles => {
  if (serviceRequest.status === ServiceRequestStatus.Confirmed) {
    return {
      card: {
        borderColor: 'var(--interaction-success)',
        opacity: 1,
      },
    };
  }

  if (serviceRequest.status === ServiceRequestStatus.Completed) {
    return {
      card: {
        borderColor: '#3F51B5',
        opacity: 0.5,
      },
      appointmentStatus: {
        backgroundColor: '#3F51B5',
        color: 'white',
        label: <Trans i18nKey="enums.appointmentStatus.completed" />,
      },
    };
  }

  return {
    card: {
      borderColor: '',
    },
  };
};

interface AppointmentRequestListItemProps {
  appointmentRequest: GraphqlAppointmentRequest;
}

const AppointmentRequestForCircleRequest: React.FC<
  AppointmentRequestListItemProps
> = ({ appointmentRequest }) => {
  const { formatTimeInTimezone } = useDateWithTimezone();
  const history = useHistory();

  const styles = getStyles(appointmentRequest, formatTimeInTimezone);
  const isActivityCompleted =
    appointmentRequest.status === AppointmentRequestStatus.Accepted &&
    appointmentRequest?.CircleRequest?.status ===
      ServiceRequestStatus.Completed;

  if (!appointmentRequest.CircleRequest) return null;

  return (
    <Card
      borderColor={styles.card.borderColor}
      opacity={styles.card.opacity}
      onClick={() => {
        // NOTE: completed activities are not visitable
        if (isActivityCompleted) return;

        history.push(
          ROUTES.ACTIVITY_DETAIL.replace(
            ':appointmentId',
            String(appointmentRequest.id)
          )
        );
      }}
    >
      <Space direction="row" size="medium" style={{ width: '100%' }}>
        <IonIcon
          icon={
            ACTIVITIES_CATEGORIES_SRC_ICONS[
              appointmentRequest.CircleRequest.category as string
            ]
          }
          style={{ fontSize: '30px', minWidth: '30px' }}
        />
        <Space
          direction="column"
          size="small"
          style={{
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          <div>
            <Title>
              <RequestCategoryName
                category={
                  appointmentRequest.CircleRequest.RequestCategory?.name
                }
              />
              {appointmentRequest.CircleRequest.recurring && <Refresh />}
              {styles.appointmentStatus ? (
                <AppointmentStatus
                  backgroundColor={styles.appointmentStatus.backgroundColor}
                  color={styles.appointmentStatus.color}
                >
                  {styles.appointmentStatus.label}
                </AppointmentStatus>
              ) : null}
            </Title>
            {styles.scheduledAt ? styles.scheduledAt : null}
          </div>
          <Description
            title={appointmentRequest.CircleRequest.description as string}
          >
            {appointmentRequest.CircleRequest.description}
          </Description>
          {styles.askTo ? styles.askTo : null}
        </Space>
        <IonIcon
          src={styles.icon.src}
          style={{
            color: styles.icon.color,
            fontSize: '25px',
            minWidth: '25px',
          }}
        />
      </Space>
    </Card>
  );
};

const AppointmentRequestForServiceRequest: React.FC<
  AppointmentRequestListItemProps
> = ({ appointmentRequest }) => {
  const { formatTimeInTimezone } = useDateWithTimezone();
  const history = useHistory();
  const isActivityCompleted =
    appointmentRequest?.ServiceRequest?.status ===
    ServiceRequestStatus.Completed;

  const navigateToAppointmentDetail = () => {
    // NOTE: completed activities are not visitable
    if (isActivityCompleted) return;

    history.push(
      ROUTES.ACTIVITY_SERVICE_REQUEST_DETAIL.replace(
        ':appointmentId',
        String(appointmentRequest.id)
      )
    );
  };

  if (!appointmentRequest.ServiceRequest) return null;

  if (
    appointmentRequest.ServiceRequest.status ===
      ServiceRequestStatus.Confirmed ||
    appointmentRequest.ServiceRequest.status === ServiceRequestStatus.Completed
  ) {
    const styles = getConfirmedServiceRequestStyles(
      appointmentRequest.ServiceRequest
    );

    return (
      <Card
        borderColor={styles.card.borderColor}
        opacity={styles.card.opacity}
        onClick={navigateToAppointmentDetail}
      >
        <Space direction="column" size="small" style={{ width: '100%' }}>
          <Space direction="row" justify="space-between">
            <Space direction="row" size="small">
              <IonIcon
                icon={
                  ACTIVITIES_CATEGORIES_SRC_ICONS[
                    appointmentRequest.ServiceRequest.category as string
                  ]
                }
                style={{ fontSize: '20px', minWidth: '20px' }}
              />
              <Title style={{ fontWeight: 700 }}>
                <RequestCategoryName
                  category={
                    appointmentRequest.ServiceRequest.RequestCategory?.name
                  }
                />
                {appointmentRequest.ServiceRequest.recurring && <Refresh />}
                {styles.appointmentStatus ? (
                  <AppointmentStatus
                    backgroundColor={styles.appointmentStatus.backgroundColor}
                    color={styles.appointmentStatus.color}
                  >
                    {styles.appointmentStatus.label}
                  </AppointmentStatus>
                ) : null}
              </Title>
            </Space>
            <IonIcon
              src={checkmarkCircle}
              style={{
                color: styles.card.borderColor,
                fontSize: '20px',
                minWidth: '20px',
              }}
            />
          </Space>
          <Space direction="row" size="small">
            <IonIcon
              icon={timeOutline}
              style={{ fontSize: '20px', minWidth: '20px' }}
            />
            <Description>
              {formatTimeInTimezone(
                appointmentRequest.ServiceRequest.scheduledAt
              )}
            </Description>
          </Space>
          <Space direction="row" size="small">
            <IonIcon
              icon={locationOutline}
              style={{ fontSize: '20px', minWidth: '20px' }}
            />
            <Description>
              {appointmentRequest.ServiceRequest.category ===
              ServiceRequestCategoriesEnum.Ride
                ? formatAddress(
                    appointmentRequest.ServiceRequest?.ride?.pickupAddress
                  )
                : formatAddress(appointmentRequest.ServiceRequest?.Address)}
            </Description>
          </Space>
        </Space>
      </Card>
    );
  } else {
    const styles = getRequestedServiceRequestStyles(
      appointmentRequest,
      appointmentRequest.ServiceRequest
    );

    return (
      <Card
        borderColor="var(--colors-primary-blue)"
        opacity={1}
        onClick={navigateToAppointmentDetail}
      >
        <Space direction="column" size="small" style={{ width: '100%' }}>
          <Space direction="row" justify="space-between">
            <Space direction="row" size="small">
              <IonIcon
                icon={
                  ACTIVITIES_CATEGORIES_SRC_ICONS[
                    appointmentRequest.ServiceRequest.category as string
                  ]
                }
                style={{ fontSize: '20px', minWidth: '20px' }}
              />
              <Title style={{ fontWeight: 700 }}>
                <RequestCategoryName
                  category={
                    appointmentRequest.ServiceRequest.RequestCategory?.name
                  }
                />
                {appointmentRequest.ServiceRequest.recurring && <Refresh />}
                <AppointmentStatus
                  backgroundColor={styles.appointmentStatus.backgroundColor}
                  color={styles.appointmentStatus.color}
                >
                  {styles.appointmentStatus.label}
                </AppointmentStatus>
              </Title>
            </Space>
            <IonIcon
              src={time}
              style={{
                color: 'var(--colors-primary-blue)',
                fontSize: '20px',
                minWidth: '20px',
              }}
            />
          </Space>
          <Space direction="row" size="small">
            <IonIcon
              icon={locationOutline}
              style={{ fontSize: '20px', minWidth: '20px' }}
            />
            <Description>
              {appointmentRequest.ServiceRequest.category ===
              ServiceRequestCategoriesEnum.Ride ? (
                <Trans
                  i18nKey="activities.zipCode"
                  values={{
                    zipCode:
                      appointmentRequest.ServiceRequest?.ride?.pickupAddress
                        ?.zipCode,
                  }}
                />
              ) : (
                <Trans
                  i18nKey="activities.zipCode"
                  values={{
                    zipCode: appointmentRequest.ServiceRequest.Address?.zipCode,
                  }}
                />
              )}
            </Description>
          </Space>
        </Space>
      </Card>
    );
  }
};

export function AppointmentRequestListItem({
  appointmentRequest,
}: AppointmentRequestListItemProps) {
  if (appointmentRequest.CircleRequest)
    return (
      <AppointmentRequestForCircleRequest
        appointmentRequest={appointmentRequest}
      />
    );

  if (appointmentRequest.ServiceRequest)
    return (
      <AppointmentRequestForServiceRequest
        appointmentRequest={appointmentRequest}
      />
    );

  return null;
}
