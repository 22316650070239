import { IonButton, IonContent } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const InfoText = styled.span`
  font-size: ${pixelToRem(16)};
  line-height: 22px;
  color: #212121;
`;

export const HeaderContentFixed = styled.div`
  position: absolute;
  z-index: 150;
  width: 100%;
  height: 1rem;
  top: 166px;
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(
    rgb(252, 252, 252) 90%,
    rgba(255, 255, 255, 0) 3.23%
  );
`;

export const UserContent = styled.div<{ width?: number }>`
  position: absolute;
  z-index: 150;
  display: flex;
  top: 125px;
  left: 1rem;
`;

export const HeaderContentColumn = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 150;
`;

export const TitleHeader = styled.h2`
  font-size: ${pixelToRem(24)};
  color: #ffffff;
  margin-left: 24px;
`;

export const ConnectButton = styled(IonButton)`
  margin-left: 8px;
  width: 120px;
  padding: 0.5rem 1rem;
  --background-activated: #e59b62;
  --border-color: #ea8231;
  --border-radius: 1.25rem;
`;

export const BackButtonContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 85px;
  left: 0px;
`;
export const Block = styled.div`
  height: 4rem;
`;

export const BodyContainer = styled(IonContent)`
  --offset-top: 5rem;
  .coach-card-description {
    padding: 1rem;
  }
  .coach-card-feedback {
    padding: 1.5rem 1rem;
  }
`;

export const ConnectButtonText = styled.span`
  color: #fff;
  font-weight: 600;
  font-size: ${pixelToRem(12)};
  text-transform: capitalize;
`;
