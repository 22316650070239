import { yupResolver } from '@hookform/resolvers/yup';
import { Variants, motion } from 'framer-motion';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FormInput from '../FormInput/FormInput';
import { IonCol, IonRow } from '@ionic/react';
import { Button } from '../Button/Button';
import { CreateCustomerWithAdditionalInformationInput } from '../../graphql/generated';
import { getPhoneNumberInputYupValidator } from '../../utils/form/getPhoneNumberInputYupValidator';
import FormTextArea from '../FormTextArea/FormTextArea';

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

export type AboutYouFormFields = Omit<
  CreateCustomerWithAdditionalInformationInput,
  'birthday' | 'courtesyTitle' | 'email' | 'firstName' | 'lastName'
>;

interface AboutYouFormProps {
  onSubmit: SubmitHandler<AboutYouFormFields>;
}

export const AboutYouForm: React.FC<AboutYouFormProps> = ({ onSubmit }) => {
  const i18n = useTranslation();

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        phoneNumber: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          )
          .test(
            ...getPhoneNumberInputYupValidator(
              i18n.t('forms.common.phoneNumber.errors.invalid')
            )
          ),

        city: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        state: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        // .required(i18n.t('forms.aboutYou.state.errorMessage')),
        zipCode: yup
          .string()
          .required(i18n.t('forms.aboutYou.zipCode.errorMessage')),
        gender: yup.string(),
        age: yup
          .number()
          .transform((_, val): number | undefined =>
            val === Number(val) ? val : undefined
          ),
        retiredTime: yup
          .number()
          .transform((_, val): number | undefined =>
            val === Number(val) ? val : undefined
          ),
        hobbies: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        professionalBackground: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        educationalBackground: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        relationshipStatus: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
        otherInformation: yup
          .string()
          .transform((_, val): number | undefined =>
            val === '' ? undefined : val
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AboutYouFormFields>({
    resolver: yupResolver(formSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.phoneNumber.label')}
              name={i18n.t('forms.aboutYou.phoneNumber.name')}
              register={register('phoneNumber')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol className="ion-padding-end">
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.gender.label')}
              name={i18n.t('forms.aboutYou.gender.name')}
              register={register('gender')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.age.label')}
              name={i18n.t('forms.aboutYou.age.name')}
              register={register('age')}
              type="number"
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.retiredTime.label')}
              name={i18n.t('forms.aboutYou.retiredTime.name')}
              register={register('retiredTime')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.city.label')}
              name={i18n.t('forms.aboutYou.city.name')}
              register={register('city')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol className="ion-padding-end">
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.state.label')}
              name={i18n.t('forms.aboutYou.state.name')}
              register={register('state')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
        <IonCol>
          <motion.div variants={animation}>
            <FormInput
              label={i18n.t('forms.aboutYou.zipCode.label')}
              name={i18n.t('forms.aboutYou.zipCode.name')}
              register={register('zipCode')}
              closeLabels
              errors={errors}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormTextArea
              {...register('hobbies')}
              style={{ border: '1px solid rgb(191, 191, 191)' }}
              placeholder={i18n.t('forms.aboutYou.hobbies.placeholder')}
              errors={errors}
              label={i18n.t('forms.aboutYou.hobbies.label')}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormTextArea
              {...register('professionalBackground')}
              style={{ border: '1px solid rgb(191, 191, 191)' }}
              placeholder={i18n.t(
                'forms.aboutYou.professionalBackground.placeholder'
              )}
              errors={errors}
              label={i18n.t('forms.aboutYou.professionalBackground.label')}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormTextArea
              {...register('educationalBackground')}
              style={{ border: '1px solid rgb(191, 191, 191)' }}
              placeholder={i18n.t(
                'forms.aboutYou.educationalBackground.placeholder'
              )}
              errors={errors}
              label={i18n.t('forms.aboutYou.educationalBackground.label')}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormTextArea
              {...register('relationshipStatus')}
              style={{ border: '1px solid rgb(191, 191, 191)' }}
              placeholder={i18n.t(
                'forms.aboutYou.relationshipStatus.placeholder'
              )}
              errors={errors}
              label={i18n.t('forms.aboutYou.relationshipStatus.label')}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-bottom">
        <IonCol>
          <motion.div variants={animation}>
            <FormTextArea
              {...register('otherInformation')}
              style={{ border: '1px solid rgb(191, 191, 191)' }}
              placeholder={i18n.t(
                'forms.aboutYou.otherInformation.placeholder'
              )}
              errors={errors}
              label={i18n.t('forms.aboutYou.otherInformation.label')}
            />
          </motion.div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <motion.div variants={animation}>
            <Button color="primary-orange" type="submit" loading={isSubmitting}>
              <Trans i18nKey="forms.aboutYou.submit" />
            </Button>
          </motion.div>
        </IonCol>
      </IonRow>
    </form>
  );
};
