import React from 'react';
import {
  ActivityLine,
  CardContainer,
  TabContent,
  TimeLineContainer,
} from '../ActivitiesTab.style';
import ActivityCard from '../../ActivityCard/ActivityCard';
import ActivityEmptyStates from '../../ActivityEmptyStates/ActivityEmptyStates';
import { IActivitiesGroupedByDate, IActivityCard } from '../../../types';
import ActivityTimeLine from '../../ActivityTimeLine/ActivityTimeLine';

const HistoryTab: React.FC<{ data: IActivitiesGroupedByDate[] }> = ({
  data,
}) => {
  return (
    <>
      {data?.map((activity) => (
        <TabContent hasTimeLine key={activity?.id as unknown as number}>
          <TimeLineContainer>
            {activity?.timeline && (
              <ActivityTimeLine data={activity?.timeline} />
            )}
            <ActivityLine />
          </TimeLineContainer>
          <CardContainer>
            {activity?.activities?.map(
              (item) =>
                item.id && (
                  <ActivityCard
                    key={item?.id as unknown as number}
                    data={item as unknown as IActivityCard}
                  />
                )
            )}
          </CardContainer>
        </TabContent>
      ))}
      {data?.length === 0 && <ActivityEmptyStates tab="history" />}
    </>
  );
};

export default HistoryTab;
