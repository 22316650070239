import { useTranslation } from 'react-i18next';

type RequestCategoryNameProps = {
  category?: string | null;
};

export const RequestCategoryName: React.FC<RequestCategoryNameProps> = ({
  category,
}) => {
  const { t } = useTranslation();
  return <>{category || t('RequestCategoryName.Fallback')}</>;
};
