import { GraphqlAddresses, GraphqlRideAddress } from '../../graphql/generated';

export const formatAddress = (
  address: Partial<GraphqlAddresses | GraphqlRideAddress> | null | undefined,
  withPlace = false
): string | undefined => {
  if (!address) {
    return undefined;
  }

  const { addressLine1, addressLine2, city, state, zipCode } = address;
  return [
    withPlace ? address.place : undefined,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  ]
    .filter(Boolean)
    .join(', ');
};
