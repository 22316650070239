/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  IonIcon,
  IonLabel,
  IonItem,
  IonHeader,
  IonContent,
  IonList,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  CustomToast,
  LocationsHeader,
  PageTitle,
  StyledIonModal,
} from './saved-places.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack, homeOutline, locationOutline } from 'ionicons/icons';
import React, { useCallback, useContext, useState } from 'react';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { useGetAddressesQuery } from '../../graphql/generated';
import { AuthContext } from '../../providers/authProvider';
import AddressActionSelector from '../../components/AddressActionSelector/AddressActionSelector';
import {
  HomeItemTitle,
  HomeItemSubtitle,
} from '../../components/ModalAddAddress/ModalAddAddress.style';
import AddressForm, { AddressesFormProps } from '../address-form/address-form';

interface SavedPlacesPageI {
  openSavedPlacesModal: (modalOpen: boolean) => void;
  isOpenSavedPlacesModal: boolean;
}

const SavedPlacesPage: React.FC<SavedPlacesPageI> = ({
  openSavedPlacesModal,
  isOpenSavedPlacesModal,
}) => {
  const i18n = useTranslation();
  const { width, height } = useWindowDimensions();
  const { user } = useContext(AuthContext);
  const [deleteToastVisible, setDeleteToastVisible] = useState<boolean>(false);
  const [openConfirmEditAddressModal, setOpenConfirmEditAddressModal] =
    useState<boolean>(false);
  const [address, setAddress] = useState<null | AddressesFormProps>(null);

  const { data: getAddressQuery } = useGetAddressesQuery({
    variables: { customerId: user?.customerId ?? 0 },
  });

  const parentVariant: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const childrenVariant: Variants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
  };

  const handleAction = useCallback(
    (retrievedAddress: AddressesFormProps) => {
      setAddress(retrievedAddress);
      setOpenConfirmEditAddressModal(true);
    },
    [setAddress]
  );

  const handleModalDismiss = useCallback(() => {
    openSavedPlacesModal(false);
  }, []);

  return (
    <StyledIonModal
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpenSavedPlacesModal}
      onDidDismiss={handleModalDismiss}
    >
      <motion.div initial="initial" animate="animate" variants={parentVariant}>
        {!openConfirmEditAddressModal && (
          <>
            <IonHeader className="ion-padding">
              <LocationsHeader style={{ marginTop: 24 }}>
                <BackButton onClick={handleModalDismiss}>
                  <IonIcon
                    icon={chevronBack}
                    color="primary"
                    style={{ width: 22, height: 26 }}
                  />
                  <BackLabel>{i18n.t('back')}</BackLabel>
                </BackButton>
              </LocationsHeader>
              <motion.div variants={childrenVariant}>
                <PageTitle>{i18n.t('savedPlaces')}</PageTitle>
              </motion.div>
            </IonHeader>

            <IonContent
              className="ion-padding"
              style={{ height: height * 0.9 }}
            >
              <IonList style={{ marginBottom: 90 }}>
                {getAddressQuery?.getAddresses.map((field, index) => {
                  return (
                    <motion.div variants={childrenVariant} key={index}>
                      <IonItem style={{ width: width * 0.9 }}>
                        {field.isDefault && (
                          <IonIcon
                            icon={homeOutline}
                            slot="start"
                            style={{ position: 'absolute' }}
                            color="rgba(35, 54, 75, 1)"
                          ></IonIcon>
                        )}
                        {!field.isDefault && (
                          <IonIcon
                            icon={locationOutline}
                            slot="start"
                            style={{ position: 'absolute' }}
                            color="rgba(35, 54, 75, 1)"
                          ></IonIcon>
                        )}
                        <IonLabel style={{ marginLeft: 40 }}>
                          <HomeItemTitle>{field.addressLine1}</HomeItemTitle>
                          <HomeItemSubtitle>
                            {field.isDefault ? i18n.t('home') : i18n.t('other')}
                          </HomeItemSubtitle>
                        </IonLabel>
                        <AddressActionSelector
                          address={field as unknown as AddressesFormProps}
                          openDeleteToastVisible={(val) =>
                            setDeleteToastVisible(val)
                          }
                          onGoToConfirmScreen={(addressRetrieved) =>
                            handleAction(addressRetrieved)
                          }
                        />
                      </IonItem>
                    </motion.div>
                  );
                })}
              </IonList>
            </IonContent>
            <CustomToast
              isOpen={deleteToastVisible}
              message={i18n.t('deletedSuccessful') ?? ''}
              onDidDismiss={() => setDeleteToastVisible(false)}
              duration={3000}
            ></CustomToast>
          </>
        )}
      </motion.div>
      {address && openConfirmEditAddressModal && (
        <AddressForm
          addressLine1={address?.addressLine1 ?? ''}
          addressLine2={address?.addressLine2 ?? ''}
          city={address?.city ?? ''}
          state={address?.state ?? ''}
          zipCode={address?.zipCode ?? ''}
          place={address?.place ?? ''}
          isDefault={address?.isDefault}
          addressId={address?.id ?? 0}
          isOpen={openConfirmEditAddressModal}
          dismissModals={setOpenConfirmEditAddressModal}
        />
      )}
    </StyledIonModal>
  );
};

export default SavedPlacesPage;
