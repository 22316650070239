import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 1rem 1rem 1rem 0.5rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid var(--interaction-error, #ea4255);
  background: #fff;
  margin-bottom: 1.5rem;
`;
export const Wrapper = styled.div``;

export const Title = styled.h2`
  color: var(--body-text-900, #212121);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 140%;
`;
export const Description = styled.p`
  color: var(--body-text-900, #212121);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
`;
