/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  ImagePlaceHolder,
  ProfileImage,
  StyledIonModal,
} from './ProfileAvatar.styles';
import { IonSkeletonText } from '@ionic/react';

type ProfileAvatarProps = {
  loading: boolean;
  firstName: string;
  lastName: string;
  src: string;
};

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  src,
  loading,
  firstName,
  lastName,
}) => {
  const [showAvatar, setShowAvatar] = useState(false);

  return (
    <>
      {loading && (
        <IonSkeletonText
          animated
          style={{
            width: 100,
            height: 100,
            borderRadius: 100,
            border: '1px solid #fff',
          }}
        />
      )}
      {src && !loading && (
        <>
          <ProfileImage onClick={() => setShowAvatar(!showAvatar)} src={src} />
          <StyledIonModal
            isOpen={showAvatar}
            onIonModalDidDismiss={() => setShowAvatar(false)}
          >
            <img src={src} alt="profile" />
          </StyledIonModal>
        </>
      )}
      {!src && !loading && (
        <ImagePlaceHolder>
          {firstName?.at(0)}
          {lastName?.at(0)}
        </ImagePlaceHolder>
      )}
    </>
  );
};

export default ProfileAvatar;
