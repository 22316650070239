import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../components/Typography/Body/Body';
import { Button } from '../../../components/Button/Button';
import { GreenCheckMark } from '../../../assets/images/GreenCheckMark';
import { ActionableCard } from '../components/ActionableCard';
import { useHistory, useLocation } from 'react-router';
import { getUserLandingRoute } from '../../../utils/routes/getUserLandingRoute';
import { useAuthContext } from '../../../providers/authProvider';
import { IUserData } from '../../../types';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames, RequestType } from '../../../constants/enums';
import { RequestToNotExistingUserInput } from '../../../graphql/generated';
import { ROUTES } from '../../../constants/routes';

type LovedOneLocationState = Pick<
  RequestToNotExistingUserInput,
  'firstName' | 'lastName'
>;

const MembershipMatchLovedOnePage: React.FC = () => {
  const { user } = useAuthContext() as { user: IUserData };
  const { state: lovedOneFields } = useLocation<LovedOneLocationState>();
  const history = useHistory();
  const { isFeatureEnabled } = useFeatureFlags();
  const isInviteButtonEnable = isFeatureEnabled(
    FeatureNames.InviteOthersMembershipFlow
  );

  const handleAddFamilyPartner = () => {
    history.push(getUserLandingRoute(user));
    history.push(ROUTES.MEMBERSHIP.WHO_IS_THIS, {
      requestType: RequestType.InviteFamilyMember,
    });
  };

  const handleAddLovedOne = () => {
    history.push(ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE);
  };

  const handleContinue = () => {
    history.push(getUserLandingRoute(user));
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonCol size="auto">
              <GreenCheckMark />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <SecondaryTitle>
                <Trans i18nKey="membership.matchLovedOne.title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText>
                {lovedOneFields ? (
                  <Trans
                    i18nKey="membership.matchLovedOne.body"
                    values={lovedOneFields}
                  />
                ) : (
                  <Trans i18nKey="membership.matchLovedOne.fallbackBody" />
                )}
              </BodyText>
            </IonCol>
          </IonRow>

          {isInviteButtonEnable ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  onAction={handleAddFamilyPartner}
                  actionText={
                    <Trans i18nKey="membership.matchLovedOne.cardActions.addFamilyPartner" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          {isInviteButtonEnable ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  onAction={handleAddLovedOne}
                  actionText={
                    <Trans i18nKey="membership.matchLovedOne.cardActions.addLovedOne" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button color="primary-orange" onClick={handleContinue}>
                <Trans i18nKey="membership.matchLovedOne.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembershipMatchLovedOnePage;
