/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useReducer,
  createContext,
  PropsWithChildren,
  useCallback,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { BuildEnv, buildEnv } from '../utils/env';
import CustomAlert, {
  CustomAlertV2,
} from '../components/CustomAlert/CustomAlert';
import { StorageKey, getStorage, setStorage } from '../utils/storage';

interface SetEnvAction {
  type: 'SET_ENV';
  payload: BuildEnv;
}

interface GlobalAlertAction {
  type: 'GLOBAL_ALERT';
  payload: {
    isOpen: boolean;
    title: ReactNode;
    subtitle: ReactNode;
    firstButtonLabel: ReactNode;
    firstButtonAction?: () => void | Promise<unknown>;
    secondButtonLabel?: ReactNode;
    secondButtonAction?: (() => void | Promise<unknown>) | null;
    onDidDismiss?: () => void;
  };
}

// NOTE: add other app context related actions here
type AppContextAction = SetEnvAction | GlobalAlertAction;

interface AppContextState {
  env: BuildEnv;
  globalAlertState: GlobalAlertAction['payload'];
}

const initialState: AppContextState = {
  env: buildEnv,
  globalAlertState: {
    isOpen: false,
    title: null,
    subtitle: null,
    firstButtonLabel: null,
    firstButtonAction: () => {},
    secondButtonLabel: null,
    secondButtonAction: null,
  },
};

interface AppContextType extends AppContextState {
  setEnv: (env: BuildEnv) => Promise<void>;
  globalAlert: (params: Omit<GlobalAlertAction['payload'], 'isOpen'>) => void;
}

const AppContext = createContext<AppContextType>({
  ...initialState,
  setEnv: async (env: BuildEnv) => {},
  globalAlert: (params: Omit<GlobalAlertAction['payload'], 'isOpen'>) => {},
});

function appReducer(
  state: AppContextState,
  action: AppContextAction
): AppContextState {
  switch (action.type) {
    case 'SET_ENV':
      return {
        ...state,
        env: action.payload,
      };
    case 'GLOBAL_ALERT':
      return {
        ...state,
        globalAlertState: action.payload,
      };
    default:
      return state;
  }
}

function AppProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const setEnv = useCallback(async (env: BuildEnv) => {
    await setStorage(StorageKey.Env, env);
    dispatch({
      type: 'SET_ENV',
      payload: env,
    });
  }, []);

  const globalAlert = useCallback(
    (params: Omit<GlobalAlertAction['payload'], 'isOpen'>) => {
      // TODO: implement a queue for multiple globalAlert calls
      dispatch({
        type: 'GLOBAL_ALERT',
        payload: {
          ...params,
          isOpen: true,
        },
      });
    },
    []
  );

  useEffect(() => {
    void (async () => {
      const { value: envFromStorage } = await getStorage(StorageKey.Env);
      const isValidEnv = Object.values(BuildEnv).includes(
        envFromStorage as BuildEnv
      );

      if (envFromStorage && envFromStorage !== state.env && isValidEnv) {
        await setEnv(envFromStorage as BuildEnv);
      }
    })();
  }, []);

  return (
    <AppContext.Provider value={{ ...state, setEnv, globalAlert }} {...props}>
      {props.children}
      <CustomAlertV2
        {...state.globalAlertState}
        firstButtonAction={async () => {
          await state.globalAlertState?.firstButtonAction?.();
          dispatch({
            type: 'GLOBAL_ALERT',
            payload: initialState.globalAlertState,
          });
        }}
        secondButtonAction={
          state.globalAlertState?.secondButtonAction
            ? async () => {
                await state.globalAlertState?.secondButtonAction?.();
                dispatch({
                  type: 'GLOBAL_ALERT',
                  payload: initialState.globalAlertState,
                });
              }
            : () => {
                // NOTE: default is dismiss
                dispatch({
                  type: 'GLOBAL_ALERT',
                  payload: initialState.globalAlertState,
                });
              }
        }
        onDidDismiss={() => {
          if (typeof state.globalAlertState.onDidDismiss === 'function') {
            state.globalAlertState.onDidDismiss();
          }
          dispatch({
            type: 'GLOBAL_ALERT',
            payload: initialState.globalAlertState,
          });
        }}
      />
    </AppContext.Provider>
  );
}

const useAppContext = () => useContext(AppContext);

export { AppContext, AppProvider, useAppContext };
