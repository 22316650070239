import { IonIcon, IonPage, isPlatform, IonContent } from '@ionic/react';
import {
  MainContainer,
  BackButton,
  Header,
  BackLabel,
  BgContainer,
  TitleHeader,
  DoneButton,
  DoneButtonContent,
  RecurringIcon,
} from './activity-status.styles';
import { useTranslation } from 'react-i18next';
import { chevronBack, repeatOutline } from 'ionicons/icons';
import { ActivityStatus } from '../../components/ActivityStatus/StatusComponent.interface';
import StatusComponent from '../../components/ActivityStatus/StatusComponent/StatusComponent';
import { Redirect, useHistory, useLocation } from 'react-router';
import { ActivityStatusEnum } from './activity-statusEnum';
import { useEffect, useState } from 'react';
import {
  ServiceRequestStatus,
  ServiceRequestType,
} from '../../graphql/generated';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';

const ActivityStatusPage: React.FC<ActivityStatus> = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const i18n = useTranslation();
  const router = useHistory();
  const location = useLocation<ActivityStatus>();
  const [statusState, setStatusState] = useState<ActivityStatus | null>(null);
  const [isRecurring, setIsRecurring] = useState<boolean | null>(null);
  const hasDoneButton =
    location.state?.typeChange?.title ||
    location.state?.status === ActivityStatusEnum.Confirmed ||
    location.state?.status === ActivityStatusEnum.Cancelled;

  const onRecurringActivity = (recurring: boolean) => {
    setIsRecurring(recurring);
  };

  const isIOS = isPlatform('ios');
  useEffect(() => {
    if (location.state?.status) {
      setStatusState(location.state);
    }
  }, [location.state]);

  if (
    isFeatureEnabled(FeatureNames.CustomerActivityCircleRequestPageV2) &&
    location?.state?.requestType === ServiceRequestType.Circle &&
    location?.state?.serviceRequestId
  ) {
    return (
      <Redirect
        to={ROUTES.CUSTOMERS.CIRCLE_REQUEST_DETAIL.replace(
          PATH_PARAMS.ACTIVITY_ID,
          String(location.state.serviceRequestId)
        )}
      />
    );
  }

  if (
    isFeatureEnabled(FeatureNames.CustomerActivitySocialEventPageV2) &&
    location?.state?.requestType === ServiceRequestType.Social &&
    location?.state?.serviceRequestId &&
    location?.state?.customerId
  ) {
    return (
      <Redirect
        to={ROUTES.CUSTOMERS.SOCIAL_EVENT_DETAIL.replace(
          PATH_PARAMS.ACTIVITY_ID,
          String(location.state.serviceRequestId)
        ).replace(PATH_PARAMS.CUSTOMER_ID, String(location.state.customerId))}
      />
    );
  }

  if (
    isFeatureEnabled(FeatureNames.CustomerActivityCoachVisitPageV2) &&
    location?.state?.requestType === ServiceRequestType.CoachVisit &&
    location?.state?.serviceRequestId
  ) {
    return (
      <Redirect
        to={ROUTES.CUSTOMERS.COACH_VISIT_DETAIL.replace(
          PATH_PARAMS.ACTIVITY_ID,
          String(location.state.serviceRequestId)
        )}
      />
    );
  }

  return (
    <IonPage>
      {location.state?.status !==
        ServiceRequestStatus.PaymentAuthorizationFailed &&
        location.state?.status !== ServiceRequestStatus.PaymentFailed && (
          <IonContent scrollY={false} className="ion-padding">
            <Header className={hasDoneButton ? 'doneBtn' : ''}>
              {!hasDoneButton && (
                <BackButton
                  marginTop={isIOS ? 23 : 0}
                  onClick={() => router.goBack()}
                >
                  <IonIcon
                    icon={chevronBack}
                    style={{
                      width: 22,
                      height: 26,
                      color: '#ffffff',
                    }}
                  />
                  <BackLabel>{i18n.t('back')}</BackLabel>
                </BackButton>
              )}
              {hasDoneButton && (
                <DoneButton
                  margin={isIOS ? 20 : 0}
                  onClick={() => router.goBack()}
                >
                  <DoneButtonContent>
                    <BackLabel>{i18n.t('done')}</BackLabel>
                  </DoneButtonContent>
                </DoneButton>
              )}

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TitleHeader marginBottom={isIOS ? 40 : 10}>
                  {location.state?.title}
                </TitleHeader>
                {(location.state?.reccuring || isRecurring) && (
                  <RecurringIcon>
                    <IonIcon
                      icon={repeatOutline}
                      style={{ width: 20, height: 20, color: '#fff' }}
                    />
                  </RecurringIcon>
                )}
              </div>
            </Header>
            <MainContainer>
              <BgContainer>
                {statusState && (
                  <StatusComponent
                    requestType={statusState?.requestType}
                    serviceRequestId={statusState?.serviceRequestId}
                    title={statusState?.title}
                    status={statusState?.status}
                    typeChange={statusState?.typeChange}
                    reccuring={location.state?.reccuring}
                    customerId={location.state?.customerId}
                    onRecurringActivity={onRecurringActivity}
                  />
                )}
              </BgContainer>
            </MainContainer>
          </IonContent>
        )}
      {/* To use with ActivityLayout */}
      {(location.state?.status ===
        ServiceRequestStatus.PaymentAuthorizationFailed ||
        location.state?.status === ServiceRequestStatus.PaymentFailed) && (
        <StatusComponent
          requestType={statusState?.requestType}
          serviceRequestId={statusState?.serviceRequestId}
          title={statusState?.title}
          status={statusState?.status}
          typeChange={statusState?.typeChange}
        />
      )}
    </IonPage>
  );
};

export default ActivityStatusPage;
