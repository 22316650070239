import React, { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from '../FormInput/FormInput';
import FormRadioGroup from '../FormRadioGroup/FormRadioGroup';
import FormTextArea from '../FormTextArea/FormTextArea';
import { CustomAlertV2 } from '../CustomAlert/CustomAlert';
import { FormLabel, TitleContainer } from './ModalSuggestActivity.style';
import {
  SuggestedActivitiesRoleEnum,
  useCreateSuggestedActivityMutation,
} from '../../graphql/generated';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { useAuthContext } from '../../providers/authProvider';
import IconActivityCompleted from '../../assets/images/IconActivityCompleted';
import { SecondaryTitle } from '../Typography/Headings/Headings';

interface ModalSuggestActivityProps {
  isOpen: boolean;
  onClose: () => void;
}

interface CreateSuggestActivity {
  name: string;
  description: string;
  role: string;
}

const ModalSuggestActivity: React.FC<ModalSuggestActivityProps> = ({
  isOpen,
  onClose,
}) => {
  const [createSuggestActivity, { data, reset: apolloReset }] =
    useCreateSuggestedActivityMutation();
  const i18n = useTranslation();
  const { globalAlert } = useAppContext();
  const { user } = useAuthContext();

  const roleOptions = useMemo(
    () => [
      {
        label: i18n.t('ModalSuggestActivity.RoleHostOptionText'),
        value: SuggestedActivitiesRoleEnum.Host,
      },
      {
        label: i18n.t('ModalSuggestActivity.RoleParticipantOptionText'),
        value: SuggestedActivitiesRoleEnum.Participant,
      },
    ],
    []
  );

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .max(
            80,
            i18n.t('ModalSuggestActivity.NameFieldMaxCharacterErrorMessage')
          )
          .required(
            i18n.t('ModalSuggestActivity.NameFieldIsRequiredErrorMessage')
          ),
        description: yup
          .string()
          .max(
            500,
            i18n.t(
              'ModalSuggestActivity.DescriptionFieldMaxCharacterErrorMessage'
            )
          )
          .required(
            i18n.t(
              'ModalSuggestActivity.DescriptionFieldIsRequiredErrorMessage'
            )
          ),
        role: yup
          .string()
          .required(
            i18n.t('ModalSuggestActivity.RoleFieldIsRequiredErrorMessage')
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<CreateSuggestActivity>({
    resolver: yupResolver(formSchema),
  });

  const [role] = watch(['role']);

  const clearForm = () => {
    reset({
      name: '',
      description: '',
      role: '',
    });
  };

  const onCloseModal = () => {
    clearForm();
    onClose();
  };

  const onSubmit = async (formValues: CreateSuggestActivity) => {
    try {
      await createSuggestActivity({
        variables: {
          createSuggestedActivityInput: {
            ...formValues,
            role: formValues.role as SuggestedActivitiesRoleEnum,
            userId: user!.id,
          },
        },
      });
    } catch (err) {
      logger.error({
        tag: '[MODAL_SUGGEST_ACTIVITY]',
        message: `Failed to create a suggested activity: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: i18n.t('ModalSuggestActivity.SubmitFormErrorAlertTitle'),
        subtitle:
          getApolloErrorMessage(err) ||
          i18n.t('ModalSuggestActivity.SubmitFormErrorAlertSubtitle'),
        firstButtonLabel: i18n.t(
          'ModalSuggestActivity.SubmitFormErrorAlertFirstButtonLabel'
        ),
      });
    }
  };

  if (data) {
    return (
      <CustomAlertV2
        isOpen={isOpen}
        title={
          <TitleContainer>
            {<IconActivityCompleted color="#EA8231" />}
            <SecondaryTitle>
              {i18n.t('ModalSuggestActivity.ActivityCreatedAlertTitle')}
            </SecondaryTitle>
          </TitleContainer>
        }
        subtitle={i18n.t('ModalSuggestActivity.ActivityCreatedAlertSubtitle')}
        firstButtonLabel={i18n.t(
          'ModalSuggestActivity.ActivityCreatedAlertFirstButtonLabel'
        )}
        firstButtonAction={() => {
          onCloseModal();
          apolloReset();
        }}
        onDidDismiss={() => {
          onCloseModal();
          apolloReset();
        }}
        modalProps={{ width: '100%' }}
        containerGridProps={{ style: { width: '100%' } }}
      />
    );
  }

  return (
    <CustomAlertV2
      isOpen={isOpen}
      title={i18n.t('ModalSuggestActivity.Title')}
      firstButtonLabel={i18n.t('ModalSuggestActivity.PrimaryCta')}
      firstButtonAction={handleSubmit(onSubmit)}
      secondButtonLabel={i18n.t('ModalSuggestActivity.SecondaryCta')}
      secondButtonAction={onCloseModal}
      onDidDismiss={onCloseModal}
      modalProps={{ width: '100%', style: { paddingTop: 48 } }}
      containerGridProps={{ style: { width: '100%' } }}
    >
      <>
        <FormInput
          label={
            <FormLabel>
              {i18n.t('ModalSuggestActivity.NameFieldLabel')}
            </FormLabel>
          }
          name="name"
          register={register('name')}
          closeLabels
          errors={errors}
        />
        <FormTextArea
          containerClassName="ion-margin-bottom custom-ion-margin-32"
          {...register('description')}
          errors={errors}
          label={
            <FormLabel>
              {i18n.t('ModalSuggestActivity.DescriptionFieldLabel')}
            </FormLabel>
          }
        />
        <FormRadioGroup
          label={
            <FormLabel>
              {i18n.t('ModalSuggestActivity.RoleFieldLabel')}
            </FormLabel>
          }
          value={role}
          {...register('role')}
          errors={errors}
          options={roleOptions}
        />
      </>
    </CustomAlertV2>
  );
};

export default ModalSuggestActivity;
