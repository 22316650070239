/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable */
import { Trans, useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  BodyContainer,
  CancelButton,
  ContainerButtons,
  IconContainer,
  RecurringText,
  StyledNonCardHolderContainer,
  Subtitle,
  Title,
  ViewEditRequestButton,
} from '../ActivityStatus.styles';
import ActivityStatusNeeded from '../../../assets/images/ActivitiesStatusNeeded';
import IconActivityNeeded from '../../../assets/images/IconActivityNeeded';
import {
  personOutline,
  homeOutline,
  calendarClearOutline,
  chevronDown,
  createOutline,
  informationOutline,
  locationOutline,
  checkmarkOutline,
  repeatOutline,
} from 'ionicons/icons';
import ActivitiesSections from '../../ActivitiesSections/ActivitiesSections';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import { DollarIcon } from '../../../assets/images/DollarIcon';
import { useHistory, useLocation } from 'react-router';
import { ActivityStatusEnum } from '../../../pages/activity-status/activity-statusEnum';
import CustomAlert from '../../CustomAlert/CustomAlert';
import { ActivityStatus } from '../StatusComponent.interface';
import {
  ApprovalServiceRequestStatus,
  GraphqlServiceRequest,
  GraphqlServiceRequestPayment,
  useGetMobileSocialEventQuery,
  useGetServiceRequestQuery,
  useGetStripeCustomerPaymentMethodQuery,
  useUpdateServiceRequestTimeMutation,
  useUserApprovalServiceRequestMutation,
  useUserApprovalSocialEventMutation,
  useUserCancelServiceRequestMutation,
} from '../../../graphql/generated';
import { useMemo, useState } from 'react';
import ActivityStatusSkeleton from '../StatusContentSkeleton';
import { formatDayOfWeekInTimezone } from '../../../utils/date';
import { parseISOLocal } from '../../../utils/date/parseISOLocal';
import { buildRideStops } from '../../../utils/buildRideObject';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import { useAppContext } from '../../../providers/appContextProvider';
import { logger } from '../../../logger';
import { useAuthContext } from '../../../providers/authProvider';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface NeededStatusProps {
  requestType?: string;
  serviceRequestId?: number;
  customerId?: number;
}

const NeededStatus: React.FC<NeededStatusProps> = ({
  serviceRequestId,
  requestType,
  customerId: initialCustomerId,
}) => {
  const { globalAlert } = useAppContext();
  const { user } = useAuthContext();
  const [showSubmitChange, setShowSubmitChange] = useState<boolean>(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const i18n = useTranslation();
  const { width } = useWindowDimensions();
  const [newDate, setNewDate] = useState<string>(''); // Format YYYY-MM-DDTHH:mm:ss
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const location = useLocation<ActivityStatus>();
  const [updateServiceRequestTimeMutation] =
    useUpdateServiceRequestTimeMutation();
  const [userCancelServiceRequestMutation] =
    useUserCancelServiceRequestMutation();
  const [userApprovalServiceRequestMutation] =
    useUserApprovalServiceRequestMutation();
  const [userApprovalSocialRequestMutation] =
    useUserApprovalSocialEventMutation();
  const {
    formatDateAndTimeInTimezone,
    formatIonDateTimeValueInTimezone,
    formatTimeInTimezone,
    transformDateToTimezone,
  } = useDateWithTimezone();

  let reason = '';
  const neededOptions = [];

  const { data: getServiceRequestData, loading: isLoadingServiceRequest } =
    useGetServiceRequestQuery({
      variables: {
        getServiceRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'service',
      onCompleted: () => setIsDataReady(true),
    });

  const { data: getSocialRequestData } = useGetMobileSocialEventQuery({
    variables: {
      socialEventId: serviceRequestId ? serviceRequestId : 0,
      customerId: initialCustomerId as number,
    },
    skip: requestType !== 'social' || !initialCustomerId,
    onCompleted: () => setIsDataReady(true),
  });

  const paymentMethodId =
    (getServiceRequestData?.getServiceRequest.ServiceRequestPayments &&
      getServiceRequestData?.getServiceRequest.ServiceRequestPayments[0]
        ?.stripePaymentMethodId) ??
    '';

  const isCardHolder =
    getServiceRequestData?.getServiceRequest.requestedBy === user?.id;

  const { data: paymentMethod } = useGetStripeCustomerPaymentMethodQuery({
    variables: {
      paymentMethodId,
    },
    skip: !paymentMethodId,
  });

  const customerId =
    getSocialRequestData?.getMobileSocialEvent?.Customer?.id || 0;
  const ratingInf =
    getServiceRequestData?.getServiceRequest.Agent?.rating?.toFixed() as
      | number
      | '';
  const userFullName = getServiceRequestData?.getServiceRequest.Agent?.User
    .fullName as string | '';

  const scheduledAt: Date | null | undefined =
    getServiceRequestData?.getServiceRequest.scheduledAt;
  const dateScheduled = scheduledAt
    ? formatDateAndTimeInTimezone(scheduledAt)
    : '';

  const requestByName = `Organized by ${
    getSocialRequestData?.getMobileSocialEvent.RequestedBy.fullName as string
  }`;
  const place = getSocialRequestData?.getMobileSocialEvent?.place
    ? getSocialRequestData?.getMobileSocialEvent?.place + ', '
    : '';
  const addressLine1 = getSocialRequestData?.getMobileSocialEvent?.addressLine1;
  const city = getSocialRequestData?.getMobileSocialEvent?.city;

  const addressInfEvent = `${addressLine1 ? addressLine1 + ', ' : ''}${
    city || ''
  }`;
  const eventScheduledAt: Date | null | undefined =
    getSocialRequestData?.getMobileSocialEvent.scheduledAt;
  const eventDateScheduled = eventScheduledAt
    ? formatDateAndTimeInTimezone(eventScheduledAt)
    : '';

  const rideStops = useMemo(() => {
    return buildRideStops(getServiceRequestData?.getServiceRequest);
  }, [getServiceRequestData]);

  if (requestType === 'social') {
    neededOptions.push(
      {
        iconStartName: checkmarkOutline,
        iconEndName: '',
        customIcon: false,
        boldTitle: null,
        title: requestByName,
        readOnly: true,
      },
      {
        iconStartName: locationOutline,
        iconEndName: '',
        boldTitle: place,
        title: addressInfEvent,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: calendarClearOutline,
        iconEndName: '',
        boldTitle: null,
        title: eventDateScheduled,
        ratingValue: '',
        readOnly: true,
      }
    );
  } else {
    const addressServiceInformation = () => {
      if (getServiceRequestData?.getServiceRequest.Address) {
        return `${getServiceRequestData?.getServiceRequest.Address?.addressLine1}, ${getServiceRequestData?.getServiceRequest.Address?.city}`;
      }
      if (getServiceRequestData?.getServiceRequest.ride) {
        return `${getServiceRequestData?.getServiceRequest.ride?.pickupAddress.addressLine1}${getServiceRequestData?.getServiceRequest.ride?.pickupAddress.city}`;
      }
      return '';
    };
    neededOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: rideStops.length > 0 ? chevronDown : '',
        title: addressServiceInformation() as string | JSX.Element,
        ratingValue: '',
        readOnly: rideStops.length > 0 ? false : true,
      },
      {
        iconStartName: DollarIcon,
        iconEndName: chevronDown,
        title: 'Payment',
        ratingValue: '',
        readOnly: false,
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getServiceRequestData?.getServiceRequest
          ?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
    if (
      getServiceRequestData?.getServiceRequest.recurring &&
      getServiceRequestData?.getServiceRequest.frequency
    ) {
      neededOptions.splice(1, 0, {
        iconStartName: repeatOutline,
        iconEndName: '',
        title: (
          <Trans
            i18nKey="serviceForm.frequencyInfo"
            values={{
              frequency:
                getServiceRequestData?.getServiceRequest.frequency?.toLowerCase(),
              dayOfWeek: formatDayOfWeekInTimezone(
                getServiceRequestData?.getServiceRequest.scheduledAt ?? ''
              ),
              time: formatTimeInTimezone(eventScheduledAt),
            }}
          />
        ),
        ratingValue: '',
        readOnly: true,
      });
    }
    if (userFullName) {
      neededOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: ratingInf ? true : false,
        title: userFullName,
        ratingValue: ratingInf,
        readOnly: false,
        subTitle: 'Change Professionals',
      });
    }
    if (getServiceRequestData?.getServiceRequest.scheduledAt) {
      const newCalendarOption = {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: dateScheduled,
        ratingValue: '',
        readOnly: false,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getServiceRequestData?.getServiceRequest.scheduledAt
            )
          : '',
      };

      neededOptions.splice(2, 0, newCalendarOption);
    }
  }

  const handleTextAreaChange = (value: string) => {
    reason = value;
  };

  return (
    <>
      <ActivityStatusNeeded width={width} />
      <BodyContainer width={width} className="needed">
        <IconContainer>
          <IconActivityNeeded />
        </IconContainer>
        <Title data-testid="needed-title">{i18n.t('needed')}</Title>
        {getServiceRequestData?.getServiceRequest.recurring && (
          <RecurringText>{i18n.t('serviceForm.recurringEvent')}</RecurringText>
        )}
        <Subtitle data-testid="needed-subtitle" className="needSubtitle">
          {requestType === 'social' &&
            getSocialRequestData?.getMobileSocialEvent.description}
          {requestType === 'service' && i18n.t('neededTitle')}
        </Subtitle>
        {!isDataReady && <ActivityStatusSkeleton count={5} />}

        {isDataReady && (
          <ActivitiesSections
            requestType={requestType}
            itemLists={neededOptions}
            onCallbackChangeDate={setNewDate}
            serviceRequestId={serviceRequestId}
            showSubmitChange={setShowSubmitChange}
            serviceRequestPayment={
              getServiceRequestData?.getServiceRequest.ServiceRequestPayments &&
              !isLoadingServiceRequest
                ? (getServiceRequestData?.getServiceRequest
                    .ServiceRequestPayments[0] as GraphqlServiceRequestPayment)
                : null
            }
            serviceRequest={
              getServiceRequestData?.getServiceRequest as GraphqlServiceRequest
            }
            category={getServiceRequestData?.getServiceRequest.category}
            agentId={
              getServiceRequestData?.getServiceRequest?.agentId as number
            }
            status={'needed'}
            rideOptions={rideStops}
          />
        )}

        {!isCardHolder && requestType === 'service' && (
          <StyledNonCardHolderContainer>
            {i18n.t('payment.canNotApproveServiceRequest', {
              requestorName:
                getServiceRequestData?.getServiceRequest.RequestedBy.fullName,
            })}
          </StyledNonCardHolderContainer>
        )}

        <ContainerButtons>
          {showSubmitChange && (
            <ViewEditRequestButton
              slot={'primary'}
              onClick={() => {
                updateServiceRequestTimeMutation({
                  variables: {
                    newTime: transformDateToTimezone(newDate),
                    serviceRequestId: serviceRequestId ? serviceRequestId : 0,
                  },
                })
                  .then(() => {
                    const newDateProposed = newDate
                      ? formatDateAndTimeInTimezone(
                          transformDateToTimezone(parseISOLocal(newDate))
                        )
                      : '';
                    history.replace('/activityStatus', {
                      title: location.state?.title,
                      status: ActivityStatusEnum.Pending,
                      requestType: requestType,
                      serviceRequestId: serviceRequestId,
                      typeChange: {
                        iconStartName: calendarClearOutline,
                        iconEndName: createOutline,
                        title: newDateProposed,
                        ratingValue: '',
                        readOnly: true,
                        subTitle: 'New date and time proposed',
                      },
                    });
                  })
                  .catch(() => {
                    throw new Error('Update Service Request Time Failed');
                  });
              }}
            >
              <>{i18n.t('submitChange')}</>
            </ViewEditRequestButton>
          )}
          {!showSubmitChange && (
            <ViewEditRequestButton
              disabled={!isCardHolder && requestType === 'service'}
              data-testid="needed-btn-confirmed"
              slot={'primary'}
              onClick={() => {
                if (!paymentMethod && requestType !== 'social') {
                  setErrorModal(true);
                } else {
                  if (requestType === 'social') {
                    userApprovalSocialRequestMutation({
                      variables: {
                        action: ApprovalServiceRequestStatus.Accept,
                        socialEventId: serviceRequestId ? serviceRequestId : 0,
                        customerId,
                      },
                    })
                      .then(() => {
                        history.replace('/activityStatus', {
                          status: ActivityStatusEnum.Confirmed,
                          serviceRequestId: serviceRequestId,
                          requestType: requestType,
                          title: location.state.title,
                          customerId,
                        });
                      })
                      .catch((err) => {
                        globalAlert({
                          title: <Trans i18nKey="genericError.title" />,
                          subtitle: getApolloErrorMessage(err) || (
                            <Trans i18nKey="genericError.subtitle" />
                          ),
                          firstButtonLabel: (
                            <Trans i18nKey="genericError.primaryLabel" />
                          ),
                        });
                        logger.error({
                          tag: '[USER_APPROVAL_SOCIAL_EVENT]',
                          message: 'Error on userApprovalSocialRequestMutation',
                          error: err as Error,
                        });
                      });
                  } else {
                    userApprovalServiceRequestMutation({
                      variables: {
                        action: ApprovalServiceRequestStatus.Accept,
                        serviceRequestId: serviceRequestId
                          ? serviceRequestId
                          : 0,
                      },
                    })
                      .then(() => {
                        history.replace('/activityStatus', {
                          status: ActivityStatusEnum.Confirmed,
                          serviceRequestId: serviceRequestId,
                          requestType: requestType,
                          title: location.state.title,
                        });
                      })
                      .catch((err) => {
                        globalAlert({
                          title: <Trans i18nKey="genericError.title" />,
                          subtitle: getApolloErrorMessage(err) || (
                            <Trans i18nKey="genericError.subtitle" />
                          ),
                          firstButtonLabel: (
                            <Trans i18nKey="genericError.primaryLabel" />
                          ),
                        });
                        logger.error({
                          tag: '[USER_APPROVAL_SERVICE_REQUEST]',
                          message:
                            'Error on userApprovalServiceRequestMutation',
                          error: err as Error,
                        });
                      });
                  }
                }
              }}
            >
              {requestType === 'service' && <>{i18n.t('acceptAppointment')}</>}
              {requestType === 'social' && <>{i18n.t('yesSeeYouThere')}</>}
            </ViewEditRequestButton>
          )}
          {!showSubmitChange && requestType !== 'social' && (
            <CancelButton
              className="needed"
              data-testid="cancel-button"
              fill={'outline'}
              onClick={() => setModalOpen(true)}
            >
              {requestType === 'service' && <>{i18n.t('cancelRequest')}</>}
            </CancelButton>
          )}
          {!showSubmitChange && requestType === 'social' && (
            <CancelButton
              className="needed"
              data-testid="cancel-button"
              fill={'outline'}
              onClick={() => {
                userApprovalSocialRequestMutation({
                  variables: {
                    action: ApprovalServiceRequestStatus.Decline,
                    socialEventId: serviceRequestId ? serviceRequestId : 0,
                    customerId,
                  },
                }).then(() => {
                  setModalOpen(false);
                  history.replace('/activityStatus', {
                    title: location.state?.title,
                    requestType: requestType,
                    serviceRequestId: serviceRequestId,
                    status: ActivityStatusEnum.Cancelled,
                    customerId,
                    typeChange: {
                      iconStartName: informationOutline,
                      iconEndName: chevronDown,
                      title: 'Reason',
                      ratingValue: '',
                      readOnly: false,
                      reason,
                    },
                  });
                });
              }}
            >
              {requestType === 'social' && <>{i18n.t('ImUnavailable')}</>}
            </CancelButton>
          )}
        </ContainerButtons>

        <CustomAlert
          showTextArea={true}
          isOpen={modalOpen}
          onDidDismiss={() => setModalOpen(false)}
          title={
            requestType === 'social'
              ? getServiceRequestData?.getServiceRequest.recurring
                ? i18n.t('cancelEventBtnTitleRecurring')
                : i18n.t('cancelEventBtnTitle')
              : getServiceRequestData?.getServiceRequest.recurring
              ? i18n.t('cancelRequestBtnTitleRecurring')
              : i18n.t('cancelRequestBtnTitle')
          }
          subtitle={i18n.t('cancelRequestBtnSubtitle')}
          firstButtonLabel={i18n.t('yesCancelButton')}
          secondButtonLabel={i18n.t('noCancelButton')}
          firstButtonAction={() => {
            if (requestType === 'social') {
              userApprovalSocialRequestMutation({
                variables: {
                  action: ApprovalServiceRequestStatus.Decline,
                  socialEventId: serviceRequestId ? serviceRequestId : 0,
                  customerId,
                },
              }).then(() => {
                setModalOpen(false);
                history.replace('/activityStatus', {
                  title: location.state?.title,
                  requestType: requestType,
                  serviceRequestId: serviceRequestId,
                  status: ActivityStatusEnum.Cancelled,
                  customerId,
                  typeChange: {
                    iconStartName: informationOutline,
                    iconEndName: chevronDown,
                    title: 'Reason',
                    ratingValue: '',
                    readOnly: false,
                    reason,
                  },
                });
              });
            } else {
              userCancelServiceRequestMutation({
                variables: {
                  cancellationReason: reason,
                  serviceRequestId: serviceRequestId ? serviceRequestId : 0,
                },
              }).then(() => {
                setModalOpen(false);
                history.replace('/activityStatus', {
                  title: location.state?.title,
                  requestType: requestType,
                  serviceRequestId: serviceRequestId,
                  status: ActivityStatusEnum.Cancelled,
                  typeChange: {
                    iconStartName: informationOutline,
                    iconEndName: chevronDown,
                    title: 'Reason',
                    ratingValue: '',
                    readOnly: false,
                    reason,
                  },
                });
              });
            }
          }}
          onTextAreaChange={handleTextAreaChange}
          secondButtonAction={() => setModalOpen(false)}
        />

        <CustomAlert
          isOpen={errorModal}
          onDidDismiss={() => setErrorModal(false)}
          title={i18n.t('whoops')}
          subtitle={i18n.t('pleaseSelect')}
          firstButtonLabel={i18n.t('postVisitModal.ok')}
          firstButtonAction={() => {
            setErrorModal(false);
          }}
        />
      </BodyContainer>
    </>
  );
};

export default NeededStatus;
