import { IonPage } from '@ionic/react';
import { useParams } from 'react-router';
import {
  ServiceRequestStatus,
  useGetMobileSocialEventQuery,
} from '../../graphql/generated';
import { ActivityDetailSkeletonPage } from '../../components/ActivityDetailSkeletonPage/ActivityDetailSkeletonPage';
import { logger } from '../../logger';
import { ConfirmedCustomerActivitySocialEvent } from '../../components/ConfirmedCustomerActivitySocialEvent/ConfirmedCustomerActivitySocialEvent';
import { UserUnavailableCustomerActivitySocialEvent } from '../../components/UserUnavailableCustomerActivitySocialEvent/UserUnavailableCustomerActivitySocialEvent';
import { CancelledCustomerActivitySocialEvent } from '../../components/CancelledCustomerActivitySocialEvent/CancelledCustomerActivitySocialEvent';
import { CompletedCustomerActivitySocialEvent } from '../../components/CompletedCustomerActivitySocialEvent/CompletedCustomerActivitySocialEvent';
import { ConfirmationNeededCustomerActivitySocialEvent } from '../../components/ConfirmationNeededCustomerActivitySocialEvent/ConfirmationNeededCustomerActivitySocialEvent';

type PageParams = {
  activityId: string;
  customerId: string;
};
export const CustomerActivitySocialEventPage: React.FC = () => {
  const { activityId, customerId } = useParams<PageParams>();
  // TODO: handle error with generic error page?
  const { data, loading, refetch } = useGetMobileSocialEventQuery({
    variables: {
      socialEventId: Number(activityId),
      customerId: Number(customerId),
    },
  });

  const socialEvent = data?.getMobileSocialEvent;

  const getComponent = () => {
    if (!socialEvent) return null;
    switch (socialEvent.status) {
      case ServiceRequestStatus.ConfirmationNeeded:
        return (
          <ConfirmationNeededCustomerActivitySocialEvent
            socialEvent={socialEvent}
            customerId={Number(customerId)}
            onSocialEventAccepted={refetch}
            onSocialEventDeclined={refetch}
            onContactRequestCreated={refetch}
          />
        );
      case ServiceRequestStatus.Confirmed:
        return (
          <ConfirmedCustomerActivitySocialEvent
            socialEvent={socialEvent}
            customerId={Number(customerId)}
            onSocialEventDeclined={refetch}
          />
        );
      case ServiceRequestStatus.Completed:
        return (
          <CompletedCustomerActivitySocialEvent socialEvent={socialEvent} />
        );
      case ServiceRequestStatus.Cancelled:
        return (
          <CancelledCustomerActivitySocialEvent socialEvent={socialEvent} />
        );
      case ServiceRequestStatus.UserUnavailable:
        return (
          <UserUnavailableCustomerActivitySocialEvent
            socialEvent={socialEvent}
            customerId={Number(customerId)}
            onSocialEventAccepted={refetch}
          />
        );
      default:
        logger.error({
          tag: '[CustomerActivitySocialEventPage][UnhandledStatus]',
          message: `Unhandled circle request status: ${socialEvent.status}, for social event with ID: ${socialEvent.id}`,
        });
        // TODO: show error page
        return null;
    }
  };

  return (
    <IonPage>
      {loading || !socialEvent ? (
        <ActivityDetailSkeletonPage />
      ) : (
        getComponent()
      )}
    </IonPage>
  );
};
