import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  CircleRequestCategoriesEnum,
  GetCircleRequestQuery,
  ServiceRequestType,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestReceivers } from '../ActivityCardItemRequestReceivers/ActivityCardItemRequestReceivers';
import { ActivityCardItemRequestDescription } from '../ActivityCardItemRequestDescription/ActivityCardItemRequestDescription';
import { ActivityCardItemRequestTimeline } from '../ActivityCardItemRequestTimeline/ActivityCardItemRequestTimeline';
import { useHistory } from 'react-router';
import { ActivityCardItemRideInformation } from '../ActivityCardItemRideInformation/ActivityCardItemRideInformation';
import { ROUTES } from '../../constants/routes';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';
import { RequestCategoryName } from '../RequestCategoryName/RequestCategoryName';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type CircleRequestQueryResult = GetCircleRequestQuery['getCircleRequest'];

type CancelledCustomerActivityCircleRequestProps = {
  circleRequest: CircleRequestQueryResult;
};

export const CancelledCustomerActivityCircleRequest: React.FC<
  CancelledCustomerActivityCircleRequestProps
> = ({ circleRequest }) => {
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(circleRequest.recurring);
  const isRide =
    circleRequest.RequestCategory?.id === CircleRequestCategoriesEnum.Ride;
  const history = useHistory();

  const handleRequestHelp = () => {
    history.push({
      pathname: ROUTES.MEMBERSHIP.WHO_IS_THIS,
      state: {
        requestType: ServiceRequestType.Circle,
      },
    });
  };

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle
            title={
              <RequestCategoryName
                category={circleRequest.RequestCategory?.name}
              />
            }
          />
        ) : (
          <RequestCategoryName category={circleRequest.RequestCategory?.name} />
        )
      }
      background="var(--colors-gradient-red)"
      bottomSheetBackground="var(--colors-gradient-red-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityCancelled />
          {t('CancelledCustomerActivityCircleRequest.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('CancelledCustomerActivityCircleRequest.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <BodyText>
            {t('CancelledCustomerActivityCircleRequest.SheetBody')}
          </BodyText>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <CardList>
            {isRide ? (
              <ActivityCardItemRideInformation ride={circleRequest.ride} />
            ) : (
              <ActivityCardItemAddress address={circleRequest.Address} />
            )}
            <ActivityCardItemRequestReceivers receivers={circleRequest.AskTo} />
            <ActivityCardItemRequestDescription
              description={circleRequest.description}
            />
            <ActivityCardItemRequestTimeline
              timeline={circleRequest.Timeline}
            />
          </CardList>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <Button color="secondary-red" onClick={handleRequestHelp}>
            {t('CancelledCustomerActivityCircleRequest.SecondaryCta')}
          </Button>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
