/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* swiperjs imports */
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

/* Theme variables */
import './theme/variables.css';
import './global.css';
import './locales/i18next';
import { AppProvider } from './providers/appContextProvider';
import Apollo from './providers/apolloProvider';
import { AuthProvider } from './providers/authProvider';
import { ModalProvider } from './providers/modalProvider';
import PushNotificationsService from './utils/PushNotificationsService';

/* Dayjs plugins */
import { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import NotificationsProvider from './providers/notificationsProvider';
import { TalkJsProvider } from './providers/talkJsContext';
import { FeatureFlagsProvider } from './providers/featureFlagsProvider';
import { UnmountProvider } from './providers/unmountHiddenCompProvider';
import Routes from './Routes/Routes';
import { StripeProvider } from './providers/stripeProvider';

extend(utc);
extend(timezone);
extend(advancedFormat);
extend(minMax);
extend(isBetween);
extend(calendar);

setupIonicReact({ mode: 'ios' });

const App: React.FC = () => {
  return (
    <AppProvider>
      <Apollo>
        <AuthProvider>
          <FeatureFlagsProvider>
            <NotificationsProvider>
              <ModalProvider>
                <StripeProvider>
                  <TalkJsProvider>
                    <IonApp>
                      <PushNotificationsService />
                      <UnmountProvider>
                        <Routes />
                      </UnmountProvider>
                    </IonApp>
                  </TalkJsProvider>
                </StripeProvider>
              </ModalProvider>
            </NotificationsProvider>
          </FeatureFlagsProvider>
        </AuthProvider>
      </Apollo>
    </AppProvider>
  );
};

export default App;
