import { useRef, useCallback } from 'react';

export const usePersistentPolling = (
  startPolling: (pollInterval: number) => void,
  stopPolling: () => void,
  POLL_INTERVAL: number
) => {
  const isPollingActive = useRef(false);

  const startPersistentPolling = useCallback(() => {
    if (!isPollingActive.current) {
      startPolling(POLL_INTERVAL);
      isPollingActive.current = true;
    }
  }, [startPolling, POLL_INTERVAL]);

  const stopPersistentPolling = useCallback(() => {
    if (isPollingActive.current) {
      stopPolling();
      isPollingActive.current = false;
    }
  }, [stopPolling]);

  return { startPersistentPolling, stopPersistentPolling };
};
