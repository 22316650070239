import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const PageContainer = styled(IonPage)`
  ion-content {
    --padding-top: 32px;
  }
`;

export const UserCard = styled.div`
  padding: 1rem 1.25rem 1rem 1rem;
  border-radius: 0.625rem;
  border: 0px solid var(--body-text-400, #bdbdbd);
  background: var(--White, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  margin-bottom: 2rem;
`;
export const UserCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    margin-left: auto;
  }
  ion-icon {
    margin-right: 0.5rem;
  }
`;

export const UserCardNameContainer = styled.div``;
export const UserCardName = styled.h2`
  color: var(--primary-blue, #23364b);
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.0135rem;
`;
export const UserCardSubtitle = styled.span`
  color: var(--primary-blue, #23364b);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 140%;
`;

export const UserCardDescription = styled.p`
  color: var(--body-text-700, #616161);
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  margin: 0.5rem 0 0 0;
`;
