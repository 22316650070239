import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { GetCoachVisitQuery } from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import IconActivityCompleted from '../../assets/images/IconActivityCompleted';
import { CardList } from '../CardList/CardList';
import { useHistory } from 'react-router';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { ActivityCardItemCoach } from '../ActivityCardItemCoach/ActivityCardItemCoach';
import { ActivityCardItemCoachNotes } from '../ActivityCardItemCoachNotes/ActivityCardItemCoachNotes';
import { ActivityCardItemPhoneCall } from '../ActivityCardItemPhoneCall/ActivityCardItemPhoneCall';

type CoachVisitQueryResult = GetCoachVisitQuery['getCoachVisit'];

type CompletedCustomerActivityCoachVisitProps = {
  coachVisit: CoachVisitQueryResult;
};

export const CompletedCustomerActivityCoachVisit: React.FC<
  CompletedCustomerActivityCoachVisitProps
> = ({ coachVisit }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <PageWithBottomSheetLayout
        title={t('CompletedCustomerActivityCoachVisit.Title')}
        background="var(--colors-gradient-light-blue)"
        bottomSheetBackground="var(--colors-gradient-light-blue-sheet)"
        bottomSheetTitle={
          <>
            <IconActivityCompleted />
            {t('CompletedCustomerActivityCoachVisit.SheetTitle')}
          </>
        }
        startSlotHeaderAction={
          <HeaderBackButton onClick={() => history.goBack()} />
        }
      >
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <CardList>
              <ActivityCardItemCoach coach={coachVisit.Coach} />
              {coachVisit.Address ? (
                <ActivityCardItemAddress address={coachVisit.Address} />
              ) : (
                <ActivityCardItemPhoneCall />
              )}
              <ActivityCardItemRequestScheduledAt
                scheduledAt={coachVisit.scheduledAt}
              />
              <ActivityCardItemCoachNotes coachVisitId={coachVisit.id} />
            </CardList>
          </IonRow>
        </IonGrid>
      </PageWithBottomSheetLayout>
    </>
  );
};
