/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React, { useCallback, useMemo } from 'react';
import {
  Container,
  RequestServiceDescription,
  StyledIonCard,
  StyledIonCardHeader,
  StyledIonCardSubtitle,
  StyledIonCardTitle,
  StyledRecurringDiv,
  StyledStatusSpan,
} from './ActivityCard.styles';
import { ArrowRight } from '../../assets/images/ArrowRight';
import { ConfirmationNeeded } from '../../assets/images/ConfirmationNeeded';
import { CheckMark } from '../../assets/images/CheckMark';
import { TimeClock } from '../../assets/images/TimeClock';
import { Refresh } from '../../assets/images/Refresh';
import { useHistory } from 'react-router';
import CircleRequestIcon from '../../assets/images/ServiceCategory/Circle_icon.svg';
import ServiceRequestIcon from '../../assets/images/ServiceCategory/Service_request.svg';
import SocialRequestIcon from '../../assets/images/ServiceCategory/social-request.svg';
import Yardwork from '../../assets/images/ServiceCategory/yardwork.svg';
import { CancelMark } from '../../assets/images/CancelMark';
import { CompletedMark } from '../../assets/images/CompletedMark';

import {
  barbellOutline,
  bulbOutline,
  carOutline,
  cartOutline,
  cutOutline,
  fitnessOutline,
  heartCircleOutline,
  heartOutline,
  listCircleOutline,
  pawOutline,
  peopleCircleOutline,
  peopleOutline,
  pricetagsOutline,
  restaurantOutline,
  shirtOutline,
  sparklesOutline,
  timeOutline,
  wifiOutline,
} from 'ionicons/icons';
import Plumber from '../../assets/images/ServiceCategory/plumber-key.svg';
import Cooking from '../../assets/images/ServiceCategory/cooking.svg';
import NonMedicalCare from '../../assets/images/ServiceCategory/non_medical_care.svg';
import HomeSafety from '../../assets/images/ServiceCategory/home_safety.svg';
import Other from '../../assets/images/ServiceCategory/other.svg';
import ApplianceRepair from '../../assets/images/ServiceCategory/appliance_repair.svg';
import Handyman from '../../assets/images/ServiceCategory/handyman.svg';
import Appointment from '../../assets/images/CircleCategory/appointment.svg';
import Exercise from '../../assets/images/CircleCategory/exercise.svg';

import { IActivityCard } from '../../types';
import { Trans, useTranslation } from 'react-i18next';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import { IonIcon } from '@ionic/react';
import {
  ServiceRequestStatus,
  ServiceRequestType,
} from '../../graphql/generated';
import { PaymentIssueAlert } from '../../assets/images/PaymentIssueAlert';
import { ActivityStatusEnum } from '../../pages/activity-status/activity-statusEnum';
import { useAuthContext } from '../../providers/authProvider';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

interface ActivityCardProps {
  data: IActivityCard;
}
export const ACTIVITIES_CATEGORIES_SRC_ICONS: {
  [key: string]: string;
} = {
  appliance_repair: ApplianceRepair,
  auto_services: carOutline,
  beauty_home_care: cutOutline,
  cleaner: sparklesOutline,
  electrical: bulbOutline,
  food_prep: restaurantOutline,
  general_assistance: peopleOutline,
  companionship: heartOutline,
  pet_care: pawOutline,
  plumbing: Plumber,
  ride: carOutline,
  tech_assistance: wifiOutline,
  wellness: fitnessOutline,
  other: Other,
  meals: Cooking,
  meal_delivery: Cooking,
  house_care: NonMedicalCare,
  appointment: Appointment,
  errands: listCircleOutline,
  exercise: Exercise,
  visit: peopleCircleOutline,
  non_medical_home_care: NonMedicalCare,
  personal_shopping: pricetagsOutline,
  personal_training: barbellOutline,
  grocery_shopping: cartOutline,
  home_safety: HomeSafety,
  yardwork: Yardwork,
  laundry_service: shirtOutline,
  handyman: Handyman,
};

const ActivityCard: React.FC<ActivityCardProps> = ({ data }) => {
  const router = useHistory();
  const { user } = useAuthContext();
  const { formatMonthDateInTimezone, formatTimeInTimezone } =
    useDateWithTimezone();

  const statusLabel: {
    [key: string]: string;
  } = {
    confirmed: 'Confirmed',
    pending: 'Pending',
    cancelled: 'Cancelled',
    userUnavailable: 'Unavailable',
    completed: 'Completed',
    confirmationNeeded: 'Upcoming Activity',
    paymentAuthorizationFailed: 'Payment Issue',
    paymentFailed: 'Payment Issue',
  };

  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const handleActivityIcon = useCallback(() => {
    switch (data?.requestType) {
      case 'coachVisit':
        return <IonIcon icon={heartCircleOutline} />;
      case 'service':
        return (
          <IonIcon
            icon={
              ACTIVITIES_CATEGORIES_SRC_ICONS[
                data?.category || data?.circleCategory
              ]
            }
          />
        );
      case 'circle':
        return (
          <IonIcon
            icon={ACTIVITIES_CATEGORIES_SRC_ICONS[data?.circleCategory]}
          />
        );
      case 'social':
        return <IonIcon icon={peopleCircleOutline} />;
      default:
        return <IonIcon icon={peopleCircleOutline} />;
    }
  }, [data?.requestType, data?.category, data?.circleCategory]);

  const activityTitle = useMemo(() => {
    if (data?.eventName) return data?.eventName;

    if (data?.requestType === 'coachVisit') {
      return t('bryaPartnerVisit');
    }

    return data?.RequestCategory?.name || t('RequestCategoryName.Fallback');
  }, [
    data?.requestType,
    data?.category,
    data?.circleCategory,
    data?.eventName,
  ]);

  const handleActivityStatus = useCallback((): string => {
    return (data?.status || data?.statusCoachVisit) as string;
  }, [data?.status, data?.statusCoachVisit]);

  const socialEventDescription: JSX.Element = (
    <p>
      {data?.Address?.addressLine1 && (
        <>
          at <span className="bold">{data?.Address?.addressLine1}</span> <br />
        </>
      )}
      <Trans
        i18nKey="ActivityCard.OrganizedByLabel"
        values={{ fullName: data?.RequestedBy?.fullName }}
      />
      <br />
      {data?.status === (ServiceRequestStatus.ConfirmationNeeded as string) && (
        <span>
          on
          <span className="bold">
            {' '}
            {formatMonthDateInTimezone(data?.scheduledAt)}
          </span>
        </span>
      )}
    </p>
  );

  const serviceDescription: {
    [key: string]: () => JSX.Element;
  } = {
    confirmed: () => (
      <>
        <p className="center">
          <IonIcon src={timeOutline} />
          <span>{formatTimeInTimezone(data.scheduledAt)}</span>
        </p>
        {data?.requestType === 'social' && socialEventDescription}
      </>
    ),
    pending: () => (
      <p>
        <Trans
          i18nKey="RequestedBy"
          values={{
            requestor:
              data?.RequestedBy?.id === user?.id
                ? t('you')
                : data?.RequestedBy?.fullName,
            date: formatMonthDateInTimezone(data?.createdAt),
          }}
          components={{ b: <b /> }}
        />
      </p>
    ),
    cancelled: () => {
      if (data?.requestType === 'social') return socialEventDescription;
      return (
        <>
          {(data?.category === 'ride' || data?.circleCategory === 'ride') && (
            <p>
              {t('to')}&nbsp;
              <span className="bold">
                {data?.ride?.pickupAddress?.addressLine1}
              </span>
            </p>
          )}
          <p>
            <Trans
              i18nKey="CancelledBy"
              values={{
                requestor:
                  data?.CancelledBy?.id === user?.id
                    ? t('you')
                    : data?.CancelledBy?.fullName || 'Brya',
              }}
            />
          </p>
        </>
      );
    },
    completed: () => (
      <p className="center">
        <IonIcon src={timeOutline} />
        <span className="center">{formatTimeInTimezone(data.scheduledAt)}</span>
      </p>
    ),
    needed: () => {
      if (data?.requestType === 'social') return socialEventDescription;
      return (
        <>
          {(data?.category === 'ride' || data?.circleCategory === 'ride') && (
            <p>
              {t('to')}&nbsp;
              <span className="bold">
                {data?.ride?.dropoffAddress.addressLine1}
              </span>
            </p>
          )}
          <p>
            <Trans
              i18nKey="RequestedBy"
              values={{
                requestor:
                  data?.RequestedBy?.id === user?.id
                    ? t('you')
                    : data?.RequestedBy?.fullName,
                date: formatMonthDateInTimezone(data?.createdAt),
              }}
              components={{ b: <b /> }}
            />
          </p>
        </>
      );
    },
  };

  return (
    <Container>
      <AnimateMotionDiv>
        {handleActivityStatus() === ServiceRequestStatus.Confirmed && (
          <CheckMark className="card-status" />
        )}
        {handleActivityStatus() === ServiceRequestStatus.Pending && (
          <TimeClock className="card-status pending" />
        )}
        {handleActivityStatus() === ServiceRequestStatus.ConfirmationNeeded && (
          <ConfirmationNeeded className="card-status needed" />
        )}
        {(handleActivityStatus() === ServiceRequestStatus.Cancelled ||
          handleActivityStatus() === ServiceRequestStatus.UserUnavailable) && (
          <CancelMark className="card-status" />
        )}
        {handleActivityStatus() === ServiceRequestStatus.Completed && (
          <CompletedMark className="card-status" />
        )}
        {(handleActivityStatus() ===
          ServiceRequestStatus.PaymentAuthorizationFailed ||
          handleActivityStatus() === ServiceRequestStatus.PaymentFailed) && (
          <PaymentIssueAlert className="card-status payment-issue" />
        )}

        <StyledIonCard
          status={handleActivityStatus()}
          onClick={() => {
            // NOTE: also do redirects in `/activityStatus` legacy page
            // to avoid showing legacy page when it's not intended
            if (
              isFeatureEnabled(
                FeatureNames.CustomerActivityCircleRequestPageV2
              ) &&
              data?.requestType === ServiceRequestType.Circle &&
              data?.id
            ) {
              router.push(
                ROUTES.CUSTOMERS.CIRCLE_REQUEST_DETAIL.replace(
                  PATH_PARAMS.ACTIVITY_ID,
                  String(data.id)
                )
              );
            } else if (
              isFeatureEnabled(
                FeatureNames.CustomerActivitySocialEventPageV2
              ) &&
              data?.requestType === ServiceRequestType.Social &&
              data?.id &&
              data?.Customer?.id
            ) {
              router.push(
                ROUTES.CUSTOMERS.SOCIAL_EVENT_DETAIL.replace(
                  PATH_PARAMS.ACTIVITY_ID,
                  String(data.id)
                ).replace(PATH_PARAMS.CUSTOMER_ID, String(data.Customer.id))
              );
            } else if (
              isFeatureEnabled(FeatureNames.CustomerActivityCoachVisitPageV2) &&
              data?.requestType === ServiceRequestType.CoachVisit &&
              data?.id
            ) {
              router.push(
                ROUTES.CUSTOMERS.COACH_VISIT_DETAIL.replace(
                  PATH_PARAMS.ACTIVITY_ID,
                  String(data.id)
                )
              );
            } else {
              router.push('/activityStatus', {
                status: handleActivityStatus(),
                activityId: data?.id,
                title: activityTitle,
                serviceRequestId: data?.id,
                requestType: data?.requestType,
                reccuring: data?.recurring,
                customerId: data?.Customer?.id,
              });
            }
          }}
        >
          <StyledIonCardHeader>
            <StyledIonCardTitle>
              <div className="title-container">
                {handleActivityIcon()}
                {activityTitle}
                <ArrowRight />
              </div>
              <RequestServiceDescription>
                {handleActivityStatus() === ServiceRequestStatus.Confirmed &&
                  serviceDescription.confirmed()}
                {handleActivityStatus() === ServiceRequestStatus.Pending &&
                  serviceDescription.pending()}
                {(handleActivityStatus() === ServiceRequestStatus.Cancelled ||
                  handleActivityStatus() ===
                    ServiceRequestStatus.UserUnavailable) &&
                  serviceDescription.cancelled()}
                {handleActivityStatus() === ServiceRequestStatus.Completed &&
                  serviceDescription.completed()}
                {handleActivityStatus() ===
                  ServiceRequestStatus.ConfirmationNeeded &&
                  serviceDescription.needed()}
                {handleActivityStatus() ===
                  ServiceRequestStatus.PaymentAuthorizationFailed &&
                  serviceDescription.confirmed()}
                {handleActivityStatus() ===
                  ServiceRequestStatus.PaymentFailed &&
                  serviceDescription.completed()}
              </RequestServiceDescription>
            </StyledIonCardTitle>
            <StyledIonCardSubtitle status={handleActivityStatus()}>
              <StyledStatusSpan status={handleActivityStatus()}>
                {statusLabel[handleActivityStatus()]}
              </StyledStatusSpan>
              {data?.recurring &&
                handleActivityStatus() !== ActivityStatusEnum.Needed && (
                  <Refresh />
                )}
              {data?.recurring &&
                handleActivityStatus() === ActivityStatusEnum.Needed && (
                  <StyledRecurringDiv>
                    <Refresh />
                  </StyledRecurringDiv>
                )}
              <div className="request-type-container">
                {data?.requestType === 'circle' && (
                  <IonIcon src={CircleRequestIcon} />
                )}
                {(data?.requestType === 'social' ||
                  data?.requestType === 'coachVisit') && (
                  <IonIcon src={SocialRequestIcon} />
                )}
                {data?.requestType === 'service' && (
                  <IonIcon src={ServiceRequestIcon} />
                )}
              </div>
            </StyledIonCardSubtitle>
          </StyledIonCardHeader>
        </StyledIonCard>
      </AnimateMotionDiv>
    </Container>
  );
};

export default ActivityCard;
