export const PATH_PARAMS = {
  APPOINTMENT_ID: ':appointmentId',
  ACTIVITY_ID: ':activityId',
  CUSTOMER_ID: ':customerId',
  REQUEST_TYPE: ':requestType',
  CONVERSATION_ID: ':conversationId',
};

export const ROUTES = {
  TABS: {
    ASK_BRYA: '/tabs/ask-brya',
    ACTIVITIES: '/tabs/activities',
    CONNECT: '/tabs/connect',
    CHAT: '/tabs/chat',
    CIRCLE: '/tabs/circle',
    ACCOUNT: '/tabs/account',
  },
  ACCOUNT: {
    MY_ACCOUNT: '/my-account',
    MY_PROFILE: '/my-profile',
    MY_CIRCLE: '/my-circle',
    MY_FAMILY: '/my-family',
    MY_BRYA_PARTNER: '/account/my-brya-partner',
    PREFERENCES: '/account/preferences',
    HELP: '/account/help',
  },
  FEATURE_SELECTION: '/feature-selection',
  NOTIFICATION_CENTER: '/notification-center',
  CHAT: '/chat',
  CHATBOX: `/chatbox/${PATH_PARAMS.CONVERSATION_ID}`,
  VERIFY_EMAIL: '/verify-email',
  LOGIN: '/login',
  ACCOUNT_DELETED: '/account-deleted',
  MEMBERSHIP: {
    OUTRO: '/membership/outro',
    ABOUT_YOU: '/membership/about-you',
    ABOUT_LOVED_ONE: '/membership/about-loved-one',
    ABOUT_FRIEND: '/membership/about-friend',
    MATCH_LOVED_ONE: '/membership/match-loved-one',
    NOT_MATCH_LOVED_ONE: '/membership/not-match-loved-one',
    MATCH_FRIEND: '/membership/match-friend',
    NOT_MATCH_FRIEND: '/membership/not-match-friend',
    WHO_IS_THIS: '/membership/who-is-this-for',
  },
  INVITES: {
    INVITE_FAMILY_MEMBER: `/invites/invite-family-member/${PATH_PARAMS.CUSTOMER_ID}`,
    INVITE_CIRCLE_MEMBER: `/invites/invite-circle-member/${PATH_PARAMS.CUSTOMER_ID}`,
    SUCCESS: '/invites/success',
  },
  CUSTOMERS: {
    CIRCLE_REQUEST_DETAIL: `/customers/circle-requests/${PATH_PARAMS.ACTIVITY_ID}`,
    SOCIAL_EVENT_DETAIL: `/customers/${PATH_PARAMS.CUSTOMER_ID}/social-events/${PATH_PARAMS.ACTIVITY_ID}`,
    COACH_VISIT_DETAIL: `/customers/coach-visits/${PATH_PARAMS.ACTIVITY_ID}`,
  },
  ACTIVITY_DETAIL: '/activities/circle-request/:appointmentId',
  ACTIVITY_SERVICE_REQUEST_DETAIL: '/activities/service-request/:appointmentId',
  CIRCLE_REQUEST_REQUESTOR_DETAIL: `/activities/circle-request/${PATH_PARAMS.ACTIVITY_ID}/requestor`,
  SERVICE_REQUEST_REQUESTOR_DETAIL: `/activities/service-request/${PATH_PARAMS.ACTIVITY_ID}/requestor`,
  SOCIAL_EVENT_DETAIL: `/activities/social-event/${PATH_PARAMS.ACTIVITY_ID}`,
  COACH_VISIT_DETAIL: `/activities/coach-visit/${PATH_PARAMS.ACTIVITY_ID}`,
  SEND_MESSAGE: '/send-message',
  CIRCLE_FORM: '/circle-form',
  NEW_SERVICE_REQUEST_CHAT: `/new-request-chat/service`,
  NEW_CIRCLE_REQUEST_CHAT: `/new-request-chat/circle`,
  SERVICE_FORM: '/service-form',
  SERVICE_REQUEST: '/customers/:customerId/service-requests/create',
  BRYA_USER: '/brya-user',
  NOTIFICATION_SETTINGS: '/notification-settings',
  SETTINGS: '/settings',
  SERVICE_SUBMIT_SUCCESS: `/service-submit-success/${PATH_PARAMS.REQUEST_TYPE}`,
};

export const QUERY_PARAMS = {
  NOTIFICATION_ID: 'notificationId',
  NOTIFICATION_CHANNEL: 'notificationChannel',
  REDIRECT: 'redirect',
  FORM_ID: 'formId',
  SERVICE_REQUEST: {
    DESCRIPTION: 'description',
    CATEGORY: 'category',
  },
};
