import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonButton, IonContent, IonTextarea } from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  ion-icon {
    font-size: 24px;
  }

  ion-radio {
    --inner-border-radius: 14px;
    --color: #ddd;
  }

  ion-radio::part(container) {
    width: 30px;
    height: 30px;
    border: 1.5px solid #b0b2c4;
    border-radius: 100px;
    --inner-border-radius: 14px;
  }

  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  ion-radio.radio-checked::part(container) {
    background: #ea8231;
  }

  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;
    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;
    transform: rotate(45deg);
  }
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 24px;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const PageTitle = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(24)};
  text-align: left;
  color: #000000;
  font-weight: 700;
  line-height: 28px;
  margin-top: 24px;
`;

export const PageTitleStep2 = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(20)};
  text-align: left;
  color: #000000;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 24px;
`;

export const PageSubTitle = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(14)};
  text-align: left;
  color: #616161;
  font-weight: 500;
`;

export const AccountHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardMiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardText = styled.div`
  color: #23364b;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
  width: 180px;
`;

export const ContinueButton = styled(IonButton)`
  width: 90%;
  height: 56px;
  margin-bottom: 12px;
  font-weight: 600;
  margin-left: 5%;
  font-size: ${pixelToRem(16)};
  --background-activated: #e59b62;
  --border-radius: 50px;
`;

export const StyledIonContent = styled(IonContent)`
  --padding-start: 24px;
  --padding-end: 24px;

  form {
    ion-button {
      --color: #fff;
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 22px;
      letter-spacing: -0.4px;
      text-transform: uppercase;
    }
  }
`;

export const SkipButton = styled(IonButton)`
  width: 90%;
  height: 56px;
  margin-bottom: 12px;
  font-weight: 600;
  margin-left: 5%;
  font-size: ${pixelToRem(16)};
  --border-color: #ea8231;
  --background: #ffffff;
  --border-radius: 50px;
  --background-activated: #f5f0f0;
  --color: #ea8231 !important;
`;

export const SubtitleStep2 = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(16)};
  color: #000;
  font-weight: 600;
  margin-bottom: 24px;
`;

export const RowInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIonTextArea = styled(IonTextarea)`
  margin-bottom: 24px;
  height: 105px;
  width: 100%;
  border: solid 1px #e0e0e0;
  border-radius: 10px;
  --padding-start: 10px;
`;
