import React, { useContext, useState } from 'react';
import {
  BryaUsersAvatar,
  ProfileContainer,
  ProfileDescription,
  ProfileImageContainer,
  ProfileInfo,
  StyledIonContent,
  StyledIonHeader,
  TabContainer,
  UserFavoriteContainer,
  UserFavoriteLabel,
  Wrapper,
} from './pro-profile.styles';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { StarIcon } from '../../assets/images/StarIcon';

import { chevronBack, heart } from 'ionicons/icons';
import { useQuery } from '@apollo/client';
import { GET_AGENT, GET_AGENT_REVIEWS } from '../../graphql/proProfile';
import {
  CircleRequestCategoriesEnum,
  GetAgentReviewsOutput,
  GraphqlCustomerAgent,
  GraphqlCustomerFavoriteAgent,
  ServiceRequestCategoriesEnum,
} from '../../graphql/generated';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ProDetails from '../../components/ProDetails/ProDetails';
import ProReviews from '../../components/ProReviews/ProReviews';
import { useHistory, useLocation } from 'react-router';
import { AuthContext } from '../../providers/authProvider';
import ProfileAvatar from '../../components/ProfileAvatar/ProfileAvatar';
import { Trans, useTranslation } from 'react-i18next';

const ProProfile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const i18n = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const router = useHistory();
  const location = useLocation() as {
    state: {
      agentId: number;
      category: ServiceRequestCategoriesEnum | CircleRequestCategoriesEnum;
    };
  };

  const { data: agentData, loading: loadingAgent } = useQuery<{
    getCustomerAgent: GraphqlCustomerAgent;
  }>(GET_AGENT, {
    variables: {
      customerId: user?.customerId,
      agentId: location?.state?.agentId,
    },
    skip: !location?.state?.agentId,
  });
  const {
    data: agentReviewsData,
    loading: loadingReviews,
    refetch: refetchReviews,
  } = useQuery<{
    getAgentReviews: GetAgentReviewsOutput;
  }>(GET_AGENT_REVIEWS, {
    variables: {
      page: 1,
      pageSize: 100,
      agentId: location?.state?.agentId,
    },
    skip: !location?.state?.agentId,
  });

  const filterReviewsByCategory = async (category: string): Promise<void> => {
    if (category === 'all') {
      try {
        await refetchReviews({
          agentId: location?.state?.agentId,
          category: undefined,
        });
      } catch (error) {
        alert(error);
      }
    } else {
      try {
        await refetchReviews({ category, agentId: location?.state?.agentId });
      } catch (error) {
        alert(error);
      }
    }
  };

  const customerFavoriteList =
    agentData?.getCustomerAgent?.CustomerFavoriteAgents || [];

  const tabs = [
    {
      title: 'Details',
      content: (
        <ProDetails
          data={agentData?.getCustomerAgent}
          customerId={user?.customerId as number}
          agentServiceType={location?.state?.category}
          loading={loadingAgent}
          isFavorite={Boolean(
            customerFavoriteList.find(
              (item) => item.customerId === user?.customerId
            )
          )}
        />
      ),
    },
    {
      title: 'Reviews',
      content: (
        <ProReviews
          serviceTypeList={
            (agentData?.getCustomerAgent?.serviceTypeInfo as []) || []
          }
          AgentName={agentData?.getCustomerAgent?.User.fullName as string}
          data={agentReviewsData?.getAgentReviews.reviews}
          loading={loadingReviews}
          filterType={filterReviewsByCategory}
        />
      ),
    },
  ];

  const getCustomerFavoritesText = (
    customers: GraphqlCustomerFavoriteAgent[]
  ) => {
    // NOTE: current customer first
    customers.sort((a) => (a.customerId === user?.customerId ? -1 : 0));

    const [one, two, ...rest] = customers;
    const firstCustomerText =
      one?.customerId === user?.customerId
        ? i18n.t('you')
        : one.Customer?.fullName;
    switch (customers.length) {
      case 0:
        return '';
      case 1:
        return i18n.t('proProfile.customersThatMarkedAsFavorite.one', {
          one: firstCustomerText,
        });
      case 2:
        return i18n.t('proProfile.customersThatMarkedAsFavorite.two', {
          one: firstCustomerText,
          two: two.Customer?.fullName,
        });
      case 3:
        return i18n.t('proProfile.customersThatMarkedAsFavorite.three', {
          one: firstCustomerText,
          two: two.Customer?.fullName,
        });
      default:
        return i18n.t(
          'proProfile.customersThatMarkedAsFavorite.moreThanThree',
          {
            one: firstCustomerText,
            two: two.Customer?.fullName,
            rest: rest.length,
          }
        );
    }
  };

  return (
    <IonPage>
      <StyledIonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={() => router.goBack()}>
              <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
              <Trans i18nKey="back" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <div className="border" />
        <ProfileContainer>
          <ProfileImageContainer>
            <ProfileAvatar
              loading={loadingAgent}
              firstName={agentData?.getCustomerAgent?.User.firstName as string}
              lastName={agentData?.getCustomerAgent?.User.lastName as string}
              src={agentData?.getCustomerAgent?.User?.profilePicture as string}
            />
          </ProfileImageContainer>
          <ProfileInfo>
            <Wrapper>
              {loadingAgent && (
                <IonSkeletonText
                  animated
                  style={{ width: '100px', background: 'white' }}
                />
              )}
              {!loadingAgent && (
                <h1>{agentData?.getCustomerAgent?.User.fullName}</h1>
              )}
              {loadingAgent && (
                <IonSkeletonText
                  animated
                  style={{ width: '50px', background: 'white' }}
                />
              )}
              {!loadingAgent && (
                <span>
                  {agentData?.getCustomerAgent?.rating}
                  <StarIcon />
                </span>
              )}
            </Wrapper>
            <ProfileDescription onClick={() => setIsOpen(true)}>
              <Wrapper>
                {loadingAgent && (
                  <>
                    <IonSkeletonText animated style={{ width: 10 }} />
                    {[...(Array(3) as [])].map(() => (
                      <IonSkeletonText
                        key={Math.random()}
                        animated
                        style={{ width: 17, height: 17, borderRadius: '50%' }}
                      />
                    ))}
                  </>
                )}
                {!loadingAgent && customerFavoriteList?.length > 0 && (
                  <>
                    <IonIcon src={heart} color="primary" />
                    {(
                      customerFavoriteList as {
                        Customer: { firstName: string; lastName: string };
                      }[]
                    )
                      ?.map(({ Customer }, i) => (
                        <BryaUsersAvatar key={i}>
                          {Customer?.firstName?.at(0)}
                          {Customer?.lastName?.at(0)}
                        </BryaUsersAvatar>
                      ))
                      ?.slice(0, 3)}
                  </>
                )}
              </Wrapper>

              <p>
                {customerFavoriteList.length
                  ? getCustomerFavoritesText(customerFavoriteList)
                  : ''}
              </p>
            </ProfileDescription>
          </ProfileInfo>
        </ProfileContainer>
      </StyledIonHeader>
      <TabContainer>
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab>
              <Trans i18nKey="proProfile.tabs.details" />
            </Tab>
            <Tab>
              <Trans i18nKey="proProfile.tabs.reviews" />
            </Tab>
          </TabList>
          {tabs.map(({ title, content }) => (
            <TabPanel key={title}>{<>{content}</>}</TabPanel>
          ))}
        </Tabs>
        <IonModal
          onIonModalDidDismiss={() => setIsOpen(false)}
          isOpen={isOpen}
          initialBreakpoint={1}
          breakpoints={[0, 1]}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <Trans i18nKey="proProfile.markedAsFavorite" />
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>
                  <Trans i18nKey="close" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <StyledIonContent>
            {customerFavoriteList?.map(({ Customer, customerId }, i) => (
              <UserFavoriteContainer key={i}>
                <BryaUsersAvatar size={57} fontSize={22}>
                  {Customer?.firstName?.at(0)}
                  {Customer?.lastName?.at(0)}
                </BryaUsersAvatar>
                <UserFavoriteLabel>
                  {customerId === user?.customerId ? (
                    <>{Customer?.fullName} (You)</>
                  ) : (
                    Customer?.fullName
                  )}
                </UserFavoriteLabel>
              </UserFavoriteContainer>
            ))}
          </StyledIonContent>
        </IonModal>
      </TabContainer>
    </IonPage>
  );
};

export default ProProfile;
