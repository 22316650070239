import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonButton, IonToast } from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const PageHelp = styled.span`
  font-style: normal;
  font-size: ${pixelToRem(14)};
  text-align: left;
  font-weight: 600;
`;

export const NeedHelp = styled.p`
  font-style: normal;
  font-size: ${pixelToRem(14)};
  text-align: left;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ContactOur = styled.p`
  font-style: normal;
  font-size: ${pixelToRem(14)};
  text-align: left;
  font-weight: 500;
  margin: 5px 0 0 0;
`;

export const SupportLink = styled.a`
  font-style: normal;
  font-size: ${pixelToRem(14)};
  text-align: left;
  font-weight: 500;
  margin-top: 5px;
  text-decoration: none;
  color: #ea8231;
`;

export const ContinueButton = styled(IonButton)`
  width: 90%;
  height: 56px;
  font-weight: 600;
  margin: 30px 0 0 5%;
  font-size: ${pixelToRem(16)};
  --background-activated: #e59b62;
  --border-radius: 50px;
`;

export const CustomToast = styled(IonToast)`
  --background: #3a868c;
`;
