/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { IonButton, IonGrid, IonModal, IonRow } from '@ionic/react';
import { SearchButtonLabel } from '../../pages/locations/locations.styles';
import {
  CancelButton,
  CustomIonModal,
  ModalInnerContainer,
  ModalSubTitle,
  ModalSubTitleSecondary,
  ModalTitle,
  ModalTitleSecondary,
  StyledIonTextArea,
  StyledIonTextInput,
  Subtitle,
  Title,
} from './CustomAlert.style';
import React, { ReactNode, useState } from 'react';
import { Button } from '../Button/Button';

interface ContainerProps {
  icon?: ReactNode;
  title: string | ReactNode;
  title1?: string;
  title2?: string;
  title3?: string;
  subtitle?: string | null | ReactNode;
  subtitle1?: string | null;
  subtitle2?: string | null;
  subtitle3?: string | null;
  subtitle4?: string | null;
  firstButtonLabel: string | ReactNode;
  secondButtonLabel?: string | null;
  firstButtonAction: () => void | Promise<void>;
  secondButtonAction?: () => void | Promise<void>;
  isOpen: boolean;
  textAreaInitialValue?: string;
  onTextAreaChange?: (value: string) => void;
  onInputChange?: (value: string | number) => void;
  showTextArea?: boolean;
  showTextInput?: boolean;
  inputPlaceholder?: string;
  isDisabled?: boolean;
  onDidDismiss?: () => void;
}

// TODO: Refactor component to receive array of titles/subtitles
const CustomAlert: React.FC<ContainerProps> = ({
  icon,
  title,
  title1,
  title2,
  title3,
  subtitle,
  subtitle1,
  subtitle2,
  subtitle3,
  firstButtonLabel,
  secondButtonLabel,
  isOpen,
  firstButtonAction,
  secondButtonAction,
  textAreaInitialValue,
  showTextArea,
  showTextInput,
  inputPlaceholder,
  isDisabled = false,
  onInputChange = () => {},
  onTextAreaChange = () => {},
  onDidDismiss,
}) => {
  const [, setTextAreaValue] = useState<string>(textAreaInitialValue || '');

  return (
    <CustomIonModal
      id="example-modal"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <ModalInnerContainer>
        <ModalTitle className={showTextArea ? 'cancelRequestBtnTitle' : ''}>
          {icon}
          {title}
        </ModalTitle>
        {subtitle ? (
          <ModalSubTitle
            className={showTextArea ? 'cancelRequestBtnSubtitle' : ''}
          >
            {subtitle}
          </ModalSubTitle>
        ) : null}

        {title1 ? (
          <ModalTitleSecondary
            className={showTextArea ? 'cancelRequestBtnTitle' : ''}
          >
            {title1}
          </ModalTitleSecondary>
        ) : null}
        {subtitle1 ? (
          <ModalSubTitleSecondary
            className={showTextArea ? 'cancelRequestBtnSubtitle' : ''}
          >
            {subtitle1}
          </ModalSubTitleSecondary>
        ) : null}

        {title2 ? (
          <ModalTitleSecondary
            className={showTextArea ? 'cancelRequestBtnTitle' : ''}
          >
            {title2}
          </ModalTitleSecondary>
        ) : null}
        {subtitle2 ? (
          <ModalSubTitleSecondary
            className={showTextArea ? 'cancelRequestBtnSubtitle' : ''}
          >
            {subtitle2}
          </ModalSubTitleSecondary>
        ) : null}

        {title3 ? (
          <ModalTitleSecondary
            className={showTextArea ? 'cancelRequestBtnTitle' : ''}
          >
            {title3}
          </ModalTitleSecondary>
        ) : null}
        {subtitle3 ? (
          <ModalSubTitleSecondary
            className={showTextArea ? 'cancelRequestBtnSubtitle' : ''}
          >
            {subtitle3}
          </ModalSubTitleSecondary>
        ) : null}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 16,
          }}
        >
          {showTextArea && (
            <StyledIonTextArea
              fill="outline"
              placeholder=" i.e. service no longer needed"
              onIonChange={(e) => {
                setTextAreaValue(e.target.value ?? '');
                if (onTextAreaChange) {
                  onTextAreaChange(e.target.value ?? '');
                }
              }}
            ></StyledIonTextArea>
          )}

          {showTextInput && (
            <StyledIonTextInput
              fill="outline"
              placeholder={inputPlaceholder}
              onIonInput={(e) => {
                if (onInputChange) {
                  onInputChange(e.target.value ?? '');
                }
              }}
            ></StyledIonTextInput>
          )}

          <IonButton
            style={{
              height: 56,
              borderRadius: 50,
              backgroundColor: 'rgba(35, 54, 75, 1)',
              width: '100%',
            }}
            shape={'round'}
            onClick={() => void firstButtonAction()}
            disabled={isDisabled}
          >
            <SearchButtonLabel>{firstButtonLabel}</SearchButtonLabel>
          </IonButton>
          {secondButtonLabel && secondButtonAction ? (
            <CancelButton
              shape={'round'}
              fill={'outline'}
              onClick={() => void secondButtonAction()}
            >
              <SearchButtonLabel>{secondButtonLabel}</SearchButtonLabel>
            </CancelButton>
          ) : null}
        </div>
      </ModalInnerContainer>
    </CustomIonModal>
  );
};

// NOTE: Deprecate CustomAlert component using this one with less props
// but giving more flexibility to consumers
type CustomAlertV2Props = {
  isOpen: boolean;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  children?: ReactNode;
  firstButtonLabel: string | ReactNode;
  firstButtonAction: () => void | Promise<void>;
  secondButtonLabel?: string | ReactNode;
  secondButtonAction?: (() => void | Promise<void>) | null;
  onDidDismiss?: () => void;
  canDismiss?: boolean;
  modalProps?: React.ComponentProps<typeof IonModal> & { width?: string };
  containerGridProps?: React.ComponentProps<typeof IonGrid>;
};
export const CustomAlertV2: React.FC<CustomAlertV2Props> = ({
  isOpen,
  title,
  subtitle,
  children,
  firstButtonLabel,
  firstButtonAction,
  secondButtonLabel,
  secondButtonAction,
  onDidDismiss,
  modalProps,
  containerGridProps,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <CustomIonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss ? onDidDismiss : undefined}
      {...modalProps}
    >
      <IonGrid className="ion-padding" {...containerGridProps}>
        <IonRow className="ion-padding-vertical ion-justify-content-center">
          <Title>{title}</Title>
        </IonRow>
        {subtitle ? (
          <IonRow className="ion-padding-vertical">
            <Subtitle>{subtitle}</Subtitle>
          </IonRow>
        ) : null}
        {children}
        <IonRow className="ion-padding-vertical">
          <Button
            color="primary-orange"
            onClick={async () => {
              try {
                setLoading(true);
                await firstButtonAction();
              } finally {
                setLoading(false);
              }
            }}
            loading={loading}
          >
            {firstButtonLabel}
          </Button>
        </IonRow>
        {secondButtonLabel && secondButtonAction ? (
          <IonRow>
            <Button
              color="secondary-orange"
              onClick={async () => {
                try {
                  setLoading(true);
                  await secondButtonAction();
                } finally {
                  setLoading(false);
                }
              }}
              loading={loading}
            >
              {secondButtonLabel}
            </Button>
          </IonRow>
        ) : null}
      </IonGrid>
    </CustomIonModal>
  );
};

export default CustomAlert;
