import * as React from 'react';

export const BryaLogo = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="84"
    height="34"
    viewBox="0 0 84 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1.86723C0 1.60732 0.047796 1.36793 0.143388 1.14906C0.23898 0.916516 0.368712 0.718165 0.532584 0.554013C0.696456 0.376182 0.88764 0.239388 1.10614 0.143633C1.32463 0.0478777 1.56361 0 1.82308 0C2.342 0 2.76534 0.184671 3.09309 0.554013C3.43449 0.909676 3.60519 1.34741 3.60519 1.86723V11.0597C3.85099 10.5126 4.19239 10.0064 4.62939 9.54134C5.08003 9.06256 5.58531 8.65218 6.1452 8.3102C6.71875 7.96822 7.33327 7.70147 7.98876 7.50996C8.65791 7.31845 9.33388 7.22269 10.0167 7.22269C11.3959 7.22269 12.6933 7.48944 13.9086 8.02293C15.124 8.54275 16.1824 9.26091 17.0837 10.1774C17.985 11.0803 18.6951 12.1404 19.214 13.3579C19.7329 14.5753 19.9924 15.8749 19.9924 17.2565C19.9924 18.6381 19.7329 19.9376 19.214 21.1551C18.6951 22.3589 17.985 23.4122 17.0837 24.315C16.1824 25.2179 15.124 25.936 13.9086 26.4695C12.6933 26.9893 11.3959 27.2492 10.0167 27.2492C8.63742 27.2492 7.3401 26.9893 6.12472 26.4695C4.90933 25.936 3.84417 25.2179 2.92921 24.315C2.02792 23.4122 1.31098 22.3589 0.778392 21.1551C0.259464 19.9376 0 18.6381 0 17.2565V1.86723ZM3.62567 17.2565C3.62567 18.132 3.78954 18.9596 4.11729 19.7393C4.45869 20.5053 4.91616 21.1756 5.48971 21.7502C6.06327 22.3247 6.73924 22.7829 7.51763 23.1249C8.29602 23.4532 9.12904 23.6174 10.0167 23.6174C10.9043 23.6174 11.7373 23.4532 12.5157 23.1249C13.2941 22.7829 13.9701 22.3247 14.5436 21.7502C15.1172 21.1756 15.5678 20.5053 15.8956 19.7393C16.2233 18.9596 16.3872 18.132 16.3872 17.2565C16.3872 16.3673 16.2233 15.5397 15.8956 14.7737C15.5678 13.994 15.1172 13.3168 14.5436 12.7423C13.9701 12.1678 13.2941 11.7164 12.5157 11.3881C11.7373 11.0597 10.9043 10.8956 10.0167 10.8956C9.12904 10.8956 8.29602 11.0597 7.51763 11.3881C6.73924 11.7164 6.06327 12.1678 5.48971 12.7423C4.91616 13.3168 4.45869 13.994 4.11729 14.7737C3.78954 15.5397 3.62567 16.3673 3.62567 17.2565Z"
      fill="#EA8231"
    />
    <path
      d="M24.4153 17.7079C24.4153 16.2716 24.6884 14.9173 25.2347 13.6451C25.7809 12.373 26.5252 11.2649 27.4674 10.3211C28.4097 9.36351 29.509 8.61114 30.7653 8.06397C32.0354 7.50312 33.3873 7.22269 34.8212 7.22269H34.9236C35.5381 7.22269 36.0024 7.39368 36.3165 7.73567C36.6443 8.07765 36.8081 8.50855 36.8081 9.02836C36.8081 9.54818 36.6238 9.97908 36.2551 10.3211C35.8863 10.663 35.4425 10.834 34.9236 10.834H34.8212C33.8926 10.834 33.0186 11.0187 32.1992 11.3881C31.3799 11.7437 30.6561 12.2362 30.0279 12.8654C29.4134 13.481 28.9218 14.206 28.5531 15.0404C28.198 15.8749 28.0205 16.764 28.0205 17.7079V25.2179C28.0205 25.7377 27.8498 26.1686 27.5084 26.5106C27.1806 26.8525 26.771 27.0235 26.2793 27.0235C26.0335 27.0235 25.7946 26.9825 25.5624 26.9004C25.3439 26.8047 25.1459 26.6747 24.9684 26.5106C24.8045 26.3464 24.6679 26.1549 24.5587 25.936C24.4631 25.7172 24.4153 25.4778 24.4153 25.2179V17.7079Z"
      fill="#EA8231"
    />
    <path
      d="M40.5755 9.02836C40.5755 8.52223 40.7462 8.09817 41.0876 7.75619C41.4427 7.40052 41.8729 7.22269 42.3781 7.22269C42.6376 7.22269 42.8698 7.27057 43.0746 7.36632C43.2931 7.4484 43.4843 7.57151 43.6481 7.73567C43.8257 7.89982 43.9622 8.09133 44.0578 8.3102C44.1534 8.52907 44.2012 8.76846 44.2012 9.02836V17.5232C44.2012 18.3714 44.3583 19.1579 44.6723 19.8829C44.9864 20.6079 45.4166 21.244 45.9628 21.7912C46.5091 22.3247 47.1441 22.7487 47.8678 23.0634C48.6053 23.3643 49.3905 23.5148 50.2235 23.5148C51.0565 23.5148 51.8349 23.3575 52.5587 23.0428C53.2825 22.7282 53.9106 22.2973 54.4432 21.7502C54.9895 21.1893 55.4196 20.5395 55.7337 19.8008C56.0478 19.0622 56.2048 18.2619 56.2048 17.4001V9.02836C56.2048 8.52223 56.3824 8.09817 56.7374 7.75619C57.1061 7.40052 57.5431 7.22269 58.0484 7.22269C58.5673 7.22269 58.9907 7.40052 59.3184 7.75619C59.6598 8.09817 59.8305 8.52223 59.8305 9.02836V17.4822C59.8305 18.6723 59.6325 19.8008 59.2365 20.8678C58.8405 21.9211 58.2874 22.8719 57.5773 23.72C56.8808 24.5544 56.0546 25.2589 55.0987 25.8334C54.1428 26.3943 53.1049 26.7773 51.9851 26.9825V32.1738C51.9851 32.42 51.9373 32.6526 51.8417 32.8715C51.7462 33.104 51.6164 33.3024 51.4525 33.4665C51.2887 33.6443 51.0975 33.7743 50.879 33.8564C50.6742 33.9521 50.442 34 50.1825 34C49.6636 34 49.2266 33.8222 48.8716 33.4665C48.5302 33.1245 48.3595 32.6936 48.3595 32.1738V26.962C47.2397 26.7568 46.2086 26.3669 45.2664 25.7924C44.3241 25.2179 43.4979 24.5134 42.7878 23.6789C42.0914 22.8308 41.5451 21.8801 41.1491 20.8268C40.7667 19.7735 40.5755 18.6586 40.5755 17.4822V9.02836Z"
      fill="#EA8231"
    />
    <path
      d="M74.0857 27.2698C72.7065 27.2698 71.416 27.0099 70.2143 26.49C69.0125 25.9565 67.961 25.2384 67.0597 24.3355C66.1721 23.4327 65.4688 22.3726 64.9499 21.1551C64.4309 19.9376 64.1715 18.6381 64.1715 17.2565C64.1715 15.8749 64.4309 14.5753 64.9499 13.3579C65.4688 12.1404 66.1721 11.0803 67.0597 10.1774C67.961 9.26091 69.0125 8.54275 70.2143 8.02293C71.416 7.48944 72.7065 7.22269 74.0857 7.22269C75.465 7.22269 76.7487 7.4826 77.9367 8.00241C79.1385 8.52223 80.1831 9.23355 81.0708 10.1364C81.9584 11.0255 82.6617 12.072 83.1806 13.2758C83.6996 14.4796 83.9727 15.7654 84 17.1334V17.2565V25.2999C84 25.8198 83.8225 26.2643 83.4674 26.6337C83.1124 26.9893 82.6754 27.1672 82.1564 27.1672C81.6375 27.1672 81.2142 26.9893 80.8864 26.6337C80.5587 26.2643 80.3948 25.8198 80.3948 25.2999L80.3743 25.0127C79.514 25.724 78.5513 26.278 77.4861 26.6747C76.4209 27.0714 75.2875 27.2698 74.0857 27.2698ZM74.0857 23.6379C74.987 23.6379 75.8201 23.4737 76.5848 23.1454C77.3495 22.8035 78.0118 22.3452 78.5717 21.7707C79.1453 21.1825 79.5891 20.5053 79.9032 19.7393C80.2309 18.9596 80.3948 18.132 80.3948 17.2565C80.3948 16.381 80.2309 15.5602 79.9032 14.7942C79.5891 14.0145 79.1453 13.3374 78.5717 12.7628C78.0118 12.1883 77.3495 11.7369 76.5848 11.4086C75.8201 11.0666 74.987 10.8956 74.0857 10.8956C73.1981 10.8956 72.3719 11.0666 71.6072 11.4086C70.8424 11.7369 70.1801 12.1883 69.6202 12.7628C69.0603 13.3374 68.6165 14.0145 68.2888 14.7942C67.961 15.5602 67.7971 16.381 67.7971 17.2565C67.7971 18.132 67.961 18.9596 68.2888 19.7393C68.6165 20.5053 69.0603 21.1825 69.6202 21.7707C70.1801 22.3452 70.8424 22.8035 71.6072 23.1454C72.3719 23.4737 73.1981 23.6379 74.0857 23.6379Z"
      fill="#EA8231"
    />
  </svg>
);
