import { IonAccordion, IonIcon } from '@ionic/react';
import styled, { CSSProperties, css } from 'styled-components';

export const StyledAccordion = styled(IonAccordion)`
  background: unset;
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type AccordionIconProps = {
  iconSize?: CSSProperties['width'];
  animateIcon?: boolean;
};
export const AccordionIcon = styled(IonIcon)<AccordionIconProps>`
  color: var(--colors-primary-blue);
  font-size: ${({ iconSize }) => iconSize || '18px'};
  min-width: ${({ iconSize }) => iconSize || '18px'};

  ${({ animateIcon }) =>
    animateIcon
      ? '' // Ionic default is animated
      : css`
          &.ion-accordion-toggle-icon {
            transform: none !important;
          }
        `};
`;
