import {
  IonIcon,
  IonButton,
  IonLabel,
  useIonAlert,
  IonList,
  IonItem,
  useIonViewDidLeave,
  IonContent,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  chatboxOutline,
  helpCircleOutline,
  logOutOutline,
} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { CustomAlertV2 } from '../../components/CustomAlert/CustomAlert';
import { GraphqlUser, useDeleteAccountMutation } from '../../graphql/generated';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { AppContext, useAppContext } from '../../providers/appContextProvider';
import { useAuthContext } from '../../providers/authProvider';
import { ROUTES } from '../../constants/routes';
import { getEnvLabel } from '../../utils/env';
import AccountLink from '../../components/AccountLink/AccountLink';
import { BryaLinks } from '../../constants/enums';
import { useTalkJsChat } from '../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../../components/FullScreenLoader/FullScreenLoader';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { NotificationOutline } from '../../assets/images/NotificationOutline';
import { ShieldOutline } from '../../assets/images/ShieldOutline';
import { ListOutline } from '../../assets/images/ListOutline';
import useInAppBrowser from '../../utils/hooks/useInAppBrowser';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';

const AccountPage: React.FC<{ hasCoach: boolean }> = ({ hasCoach }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { globalAlert } = useAppContext();
  const { user, logout } = useAuthContext();
  const [presentAlert] = useIonAlert();
  const { env } = useContext(AppContext);
  const { openEvent, closeBrowser } = useInAppBrowser();
  const [showRemoveAccountConfirm, setShowRemoveAccountConfirm] =
    useState(false);
  const [deleteAccount] = useDeleteAccountMutation();

  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();

  useIonViewDidLeave(() => {
    closeBrowser();
  });

  const handleRemoveAccount = async () => {
    try {
      await deleteAccount();
      await logout();
      history.push(ROUTES.ACCOUNT_DELETED);
    } catch (err) {
      logger.error({
        tag: '[USER_DELETE_ACCOUNT]',
        message: `Error on deleteAccount mutation ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    } finally {
      setShowRemoveAccountConfirm(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      logger.error({
        tag: '[LOGOUT]',
        message: `Something went wrong when logging out: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
    } finally {
      history.replace('/login');
    }
  };

  return (
    <>
      {getBryaTeamChatQueryLoading && <FullScreenLoader />}
      <IonContent>
        <div className="ion-padding">
          <SecondaryTitle>{t('AccountPage.Title')}</SecondaryTitle>
        </div>
        <IonList lines="none">
          <IonItem>
            <div slot="start">
              <UserAvatar user={user as unknown as GraphqlUser} />
            </div>
            <IonLabel>
              <h2>
                <strong>
                  {user?.firstName} {user?.lastName}
                </strong>
              </h2>
              <p>{user?.email}</p>
            </IonLabel>
          </IonItem>
        </IonList>
        <div className="ion-padding">
          <div className="ion-margin-top">
            {hasCoach && (
              <div className="ion-margin-bottom">
                <AccountLink
                  icon={
                    <IonIcon
                      style={{
                        '--ionicon-stroke-width': '19px',
                        color: 'var(--ion-color-primary-blue)',
                        width: 28,
                        height: 28,
                      }}
                      src={chatboxOutline}
                    />
                  }
                  label={t('AccountPage.BryaPartnerItemText')}
                  action={() => history.push(ROUTES.ACCOUNT.MY_BRYA_PARTNER)}
                />
              </div>
            )}
            <div className="ion-margin-bottom">
              <AccountLink
                label={t('AccountPage.NotificationsItemText')}
                icon={<NotificationOutline />}
                action={() => history.push(ROUTES.NOTIFICATION_SETTINGS)}
              />
            </div>
            <div className="ion-margin-bottom">
              <AccountLink
                label={t('AccountPage.PrivacyPolicyItemText')}
                icon={<ShieldOutline />}
                action={() => openEvent(BryaLinks.PrivacyPolicy)}
              />
            </div>
            <div className="ion-margin-bottom">
              <AccountLink
                label={t('AccountPage.TermsItemText')}
                icon={<ListOutline />}
                action={() => openEvent(BryaLinks.TermsConditions)}
              />
            </div>
            <div className="ion-margin-bottom">
              <AccountLink
                icon={
                  <IonIcon
                    style={{
                      '--ionicon-stroke-width': '19px',
                      color: 'var(--colors-primary-blue)',
                      width: 28,
                      height: 28,
                    }}
                    src={helpCircleOutline}
                  />
                }
                label={t('AccountPage.HelpItemText')}
                action={handleGetBryaTeamChat}
              />
            </div>
          </div>
          <IonButton
            color="dark"
            fill="clear"
            onClick={() => {
              void presentAlert({
                header: t('AccountPage.LogoutConfirmHeader'),
                buttons: [
                  {
                    text: t('AccountPage.CancelLogoutButtonLabel'),
                    role: 'cancel',
                  },
                  {
                    text: t('AccountPage.ConfirmLogoutButtonLabel'),
                    role: 'confirm',
                    handler: handleLogout,
                  },
                ],
              });
            }}
          >
            <IonIcon slot="start" icon={logOutOutline} />
            <strong>{t('AccountPage.LogoutButtonLabel')}</strong>
          </IonButton>
        </div>
        <div className="ion-padding">
          <div className="ion-margin-top">
            <IonButton
              className="ion-text-start"
              fill="clear"
              color="danger"
              onClick={() => setShowRemoveAccountConfirm(true)}
              style={{ '--padding-start': '12px' }}
            >
              {t('AccountPage.DeleteAccountButtonLabel')}
            </IonButton>
            <p className="ion-padding-horizontal ion-no-margin">
              {t('AccountPage.VersionText')}{' '}
              {process.env.REACT_APP_BUILD_VERSION || '0.0.0'}
              {` (${getEnvLabel(env)})`}
            </p>
          </div>
          <CustomAlertV2
            isOpen={showRemoveAccountConfirm}
            onDidDismiss={() => setShowRemoveAccountConfirm(false)}
            title={t('AccountPage.DeleteAccountConfirmTitle')}
            subtitle={t('AccountPage.DeleteAccountConfirmSubtitle')}
            firstButtonLabel={t('AccountPage.ConfirmDeleteAccountButtonLabel')}
            secondButtonLabel={t('AccountPage.CancelDeleteAccountButtonLabel')}
            firstButtonAction={handleRemoveAccount}
            secondButtonAction={() => setShowRemoveAccountConfirm(false)}
          />
        </div>
      </IonContent>
    </>
  );
};

export default AccountPage;
