import { homeOutline } from 'ionicons/icons';
import {
  GetCircleRequestQuery,
  GetCoachVisitQuery,
} from '../../graphql/generated';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { formatAddress } from '../../utils/address/formatAddress';

type Address =
  | GetCircleRequestQuery['getCircleRequest']['Address']
  | GetCoachVisitQuery['getCoachVisit']['Address'];

type ActivityCardItemAddressProps = {
  address: Address;
  icon?: string;
};
export const ActivityCardItemAddress: React.FC<
  ActivityCardItemAddressProps
> = ({ address, icon }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="auto" className="ion-align-self-center">
          <IonIcon
            icon={icon || homeOutline}
            color="var(--colors-primary-blue)"
            style={{ width: '24px', height: '24px', marginInlineEnd: '8px' }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {formatAddress(address, Boolean(address?.place))}
          </BodyText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
