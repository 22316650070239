import * as React from 'react';

export const DollarIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg width={26} height={26} viewBox="0 0 36 36" fill="none" {...props}>
    <g clipPath="url(#clip0_806_8627)">
      <path
        d="M18 0C8.06 0 0 8.06 0 18s8.06 18 18 18 18-8.06 18-18S27.94 0 18 0zm0 32.946C9.747 32.946 3.054 26.253 3.054 18S9.747 3.054 18 3.054 32.946 9.747 32.946 18 26.253 32.946 18 32.946zm1.916-15.878l-1.02-.237v-5.396c1.527.209 2.471 1.165 2.632 2.338.02.16.156.277.317.277h1.804a.325.325 0 00.322-.353c-.246-2.503-2.307-4.11-5.059-4.388V7.996a.322.322 0 00-.321-.322h-1.13a.322.322 0 00-.32.322V9.32c-2.845.278-5.071 1.849-5.071 4.782 0 2.716 2 4.026 4.102 4.528l.992.253v5.733c-1.776-.237-2.772-1.185-2.977-2.463a.32.32 0 00-.317-.265h-1.857a.322.322 0 00-.321.35c.18 2.21 1.856 4.243 5.432 4.504v1.262c0 .176.145.32.322.32h1.14a.323.323 0 00.322-.325l-.008-1.273c3.146-.277 5.396-1.96 5.396-4.982-.004-2.789-1.776-4.034-4.38-4.677zm-2.756-.651a5.89 5.89 0 01-.602-.2c-1.358-.491-1.99-1.283-1.99-2.303 0-1.459 1.106-2.29 2.592-2.48v4.983zm1.736 8.212v-5.371c.124.036.237.064.354.088 1.9.579 2.539 1.382 2.539 2.616 0 1.57-1.181 2.515-2.893 2.668z"
        fill="#000"
        fillOpacity={0.85}
      />
    </g>
    <defs>
      <clipPath id="clip0_806_8627">
        <path fill="#fff" d="M0 0H36V36H0z" />
      </clipPath>
    </defs>
  </svg>
);
