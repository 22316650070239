import { IonAvatar, IonButton } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export type DivProps = {
  width?: number;
};

export type ButtonProps = {
  width?: number;
};

export const BodyContainer = styled.div<DivProps>`
  position: absolute;
  width: ${(props) => (props.width ? props.width : 375)}px;
  height: 400%;
  top: 85%;
  border-top: 1px solid #ffffff;
  border-radius: 16px 16px 0px 0px;
  overflow: scroll;

  &.confirmed {
    background: linear-gradient(180deg, #dbf9fc 0%, #f3f3f3 20.45%);
  }

  &.cancelled {
    background: linear-gradient(180deg, #ffe4e2 0%, #f3f3f3 20.45%);
  }

  &.pending {
    background: linear-gradient(180deg, #f0e3ff 0%, #f3f3f3 20.45%);
  }

  &.needed {
    background: linear-gradient(180deg, #f0e3ff 0%, #f3f3f3 20.45%);
  }

  &.completed {
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 26.72%,
      #f3f3f3 42.12%
    );
  }
`;

export const IconContainer = styled.div`
  position: relative;
  left: 47%;
  transform: translateX(-22px);
  top: 1%;
  bottom: 0;
  width: 44px;
  height: 44px;
`;

export const Title = styled.p`
  color: #23364b;
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(24)};
  line-height: 30px;
  margin-top: 60px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: #616161;
  font-size: ${pixelToRem(18)};
  line-height: 20px;
  margin-top: 30px;
  margin-left: 39px;
  @media only screen and (max-width: 420px) {
    margin-bottom: 6%;
  }
  @media only screen and (max-width: 820px) {
    margin-bottom: 25%;
  }
  @media only screen and (max-width: 390px) {
    margin-bottom: 9%;
  }
  @media only screen and (min-width: 390px) {
    margin-bottom: 8%;
  }

  .bold {
    font-weight: bold;
    margin-right: 0px;
  }

  span {
    display: block;
    margin-top: 10px;
  }

  &.confirmedSubTitle {
    position: relative;
    right: 3%;
  }

  &.needSubtitle {
    margin-left: 26px;
    margin-right: 26px;
  }

  &.pendingSubtitle {
    margin-left: 26px;
    margin-right: 26px;
  }

  &.cancelledSubTitle {
    margin-left: 26px;
    margin-right: 26px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
  height: 480px;
`;

export const ViewEditRequestButton = styled(IonButton)<ButtonProps>`
  width: 88%;
  height: 56px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: none;
  font-size: ${pixelToRem(16)};
  --background-activated: rgba(0, 0, 0, 0.15);
  --border-radius: 50px;

  &.confirmed {
    --background: linear-gradient(
      267.19deg,
      #13c4ca -0.42%,
      #08979c 57.59%,
      #017c81 100.84%
    );

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }

  &.pending {
    --background: linear-gradient(
      267.19deg,
      #b179f5 -0.42%,
      #9254de 57.59%,
      #7a33d1 100.84%
    );

    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }

  &.cancelled {
    --background: #ea4255;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }

  &.completed {
    --background: linear-gradient(
      267.19deg,
      #6073dc -0.42%,
      #4c5dbd 37.02%,
      #3f51b5 100.84%,
      #101e6d 100.84%
    );
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }
`;

export const StyledNonCardHolderContainer = styled.div`
  font-size: ${pixelToRem(17)};
  margin-top: 30px;
  padding: 0 20px;
  font-weight: bold;
  text-align: center;
`;

export const CancelButton = styled(IonButton)<ButtonProps>`
  height: 56px;
  width: 88%;
  font-weight: 600;
  border: 1px solid;
  text-transform: none;
  font-size: ${pixelToRem(16)};
  --border-radius: 50px;
  --background-activated: #fff;

  &.confirmed {
    --border-color: #13c4ca;
    --border-image: linear-gradient(
      267.19deg,
      #13c4ca -0.42%,
      #08979c 57.59%,
      #017c81 100.84%
    );
    color: #13c4ca;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }

  &.pending {
    --border-color: #b179f5;
    border-image: linear-gradient(
      267.19deg,
      #b179f5 -0.42%,
      #9254de 57.59%,
      #7a33d1 100.84%
    );
    color: #b179f5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }

  &.cancelled {
    --background: #ffffff;
    --border-color: #ea4255;
    border: 1px solid;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    color: #ea4255;
  }

  &.needed {
    --background: #ffffff;
    --border-color: #ea8231;
    border: 1px solid;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    color: #ea8231;
  }
`;

export const StyledCircleAvatar = styled(IonAvatar)`
  display: flex;
  justify-content: center;
  padding: 0px;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ea8231;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  font-weight: 500;
  font-size: ${pixelToRem(14)};
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #ea8231;
  margin-right: 6px;
`;

export const RecurringText = styled.p`
  font-weight: 500;
  font-color: var(--ion-color-light-contrast);
  font-size: ${pixelToRem(12)};
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin: 24px auto 150px auto;
  text-align: center;
`;
