import { IonSkeletonText } from '@ionic/react';
import { Space } from '../Space/Space';
import { SkeletonActivityDetailCard } from '../ActivitiesDetailCard/ActivitiesDetailCard';
import { PageWithBottomSheetLayout } from '../PageWithBottomSheetLayout/PageWithBottomSheetLayout';

export const ActivityDetailSkeletonPage: React.FC = () => {
  return (
    <PageWithBottomSheetLayout
      background="linear-gradient(267.19deg, #f8f8f8 -0.42%, #b1b1b1 41.24%, #ababab 100.84%)"
      bottomSheetBackground="white"
      title={
        <IonSkeletonText
          animated
          style={{ width: '80px', height: '29px', borderRadius: 0 }}
        />
      }
      bottomSheetTitle={
        <>
          <IonSkeletonText
            animated
            style={{ width: '60px', height: '60px', borderRadius: '50%' }}
          />
          <IonSkeletonText
            animated
            style={{ width: '240px', height: '30px', borderRadius: 0 }}
          />
        </>
      }
    >
      <Space size="large" direction="column">
        <IonSkeletonText animated style={{ width: '100%', height: '45px' }} />

        <SkeletonActivityDetailCard />

        <Space direction="column" size="small">
          <IonSkeletonText
            animated
            style={{ width: '100%', height: '56px', borderRadius: '50px' }}
          />
        </Space>
      </Space>
    </PageWithBottomSheetLayout>
  );
};
