import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonCard, IonContent, IonLabel, IonListHeader } from '@ionic/react';

export const MainContainer = styled(IonContent)``;

export const Close = styled.p`
  font-size: ${pixelToRem(16)};
  color: var(--body-text-700);
  font-weight: 400;
  line-height: 18px;
  text-align: right;
`;

export const Title = styled.p`
  font-size: ${pixelToRem(20)};
  color: #000000;
  font-weight: 700;
  line-height: 24px;
  margin-top: 40px;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: ${pixelToRem(16)};
  color: var(--body-text-700);
  font-weight: 400;
  line-height: 18px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ion-toggle::part(track) {
    height: 18px;
    width: 44px;
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IonLabelStyled = styled(IonLabel)`
  font-size: ${pixelToRem(16)};
  line-height: 16px;
  line-height: 18px;
  font-weight: 400;
  color: var(--ion-color-warning-contrast);
`;

export const IonListHeaderStyled = styled(IonListHeader)`
  color: var(--body-text-900, #212121);
  font-size: ${pixelToRem(18)};
  line-height: 20px;
  font-weight: 600;
`;

export const LeftItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledIonCard = styled(IonCard)`
  padding: 16px;
  box-shadow: 0px 9px 30px 8px rgba(0, 0, 0, 0.05);
`;
