import { gql } from '@apollo/client';

export const GET_SIGNATURE_QUERY = gql`
  query GetSignature {
    getSignature {
      signature
      talkJsId
      talkJsAppId
    }
  }
`;

export const GET_USER_PARTICIPANTS = gql`
  query GetUserParticipants(
    $filter: GetUserParticipantsFilter
    $conversationId: String
  ) {
    getUserParticipants(filter: $filter, conversationId: $conversationId) {
      users {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        trustedMember
      }
      usersFromTalkJs {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        trustedMember
      }
    }
  }
`;

export const TRUST_UNTRUST_MUTATION = gql`
  mutation TrustUntrustMembers(
    $action: TrustUntrustActionType!
    $trustedMembersIds: [Int!]!
  ) {
    trustUntrustMembers(
      action: $action
      trustedMembersIds: $trustedMembersIds
    ) {
      success
      action
    }
  }
`;

export const START_CONVERSATION_MUTATION = gql`
  mutation StartConversation($startConversationInput: StartConversationInput!) {
    startConversation(startConversationInput: $startConversationInput) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
      }
      lastMessage {
        attachment {
          url
          size
        }
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        senderId
        text
        type
        referencedMessageId
      }
      participants {
        id
        access
        notify
      }
      createdAt
    }
  }
`;

export const GET_CONVERSATION = gql`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
    }
  }
`;

export const GET_BRYA_PARTNER_CHAT = gql`
  query GetBryaPartnerChatId {
    getBryaPartnerChatId {
      id
    }
  }
`;

export const GET_BRYA_SUPPORT_CHAT = gql`
  query GetBryaSupportChatId {
    getBryaSupportChatId {
      id
    }
  }
`;

export const GET_BRYA_TEAM_CHAT = gql`
  query GetBryaTeamChatId {
    getBryaTeamChatId {
      id
    }
  }
`;

export const BLOCK_USER = gql`
  mutation BlockUser($blockedUserId: Int!) {
    blockUser(blockedUserId: $blockedUserId) {
      id
    }
  }
`;

export const UNBLOCK_USER = gql`
  mutation UnblockUser($unblockUserId: Int!) {
    unblockUser(id: $unblockUserId) {
      id
    }
  }
`;

export const GET_BLOCKED_USERS = gql`
  query GetBlockedUsersByBlockerUserId {
    getBlockedUsersByBlockerUserId {
      id
      blockedUserId
      blockerUserId
    }
  }
`;

export const GET_BLOCKER_USERS = gql`
  query GetBlockerUsersByBlockedUserId {
    getBlockerUsersByBlockedUserId {
      id
      blockedUserId
      blockerUserId
    }
  }
`;
