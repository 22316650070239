import styled, { CSSProperties } from 'styled-components';
import { pixelToRem } from '../../../utils/helper';

interface BodyTextProps {
  color?: CSSProperties['color'];
}

export const BodyText = styled.p<BodyTextProps>`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  white-space: pre-wrap;
  color: ${({ color }) => color || 'var(--body-text-700)'};
`;

export const BodyTextMedium = styled.p<BodyTextProps>`
  font-size: ${() => pixelToRem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  white-space: pre-wrap;
  color: ${({ color }) => color || 'var(--body-text-700)'};
`;

export const BodyTextSmall = styled.p<BodyTextProps>`
  font-size: ${() => pixelToRem(12)};
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${({ color }) => color || '#000000'};
`;
