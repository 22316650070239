import { GetCircleRequestQuery } from '../../graphql/generated';
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { ConnectButton } from './ActivityCardItemRequestConnectAcceptedBy.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type AcceptedBy = GetCircleRequestQuery['getCircleRequest']['AcceptedBy'];

type ActivityCardItemRequestConnectAcceptedByProps = {
  acceptedBy: AcceptedBy;
  onConnect: () => Promise<void>;
};
export const ActivityCardItemRequestConnectAcceptedBy: React.FC<
  ActivityCardItemRequestConnectAcceptedByProps
> = ({ acceptedBy, onConnect }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="auto"
          className="ion-align-self-center"
          style={{ marginInlineEnd: '8px' }}
        >
          {acceptedBy && <UserAvatar size={40} user={acceptedBy} />}
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {acceptedBy?.fullName}
          </BodyText>
          <ConnectButton
            className="ion-no-padding ion-no-margin"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              void onConnect().finally(() => setLoading(false));
            }}
          >
            {loading ? (
              <IonSpinner
                color="primary-orange"
                name="dots"
                style={{ maxHeight: '19px' }}
              />
            ) : (
              t('ActivityCardItemRequestConnectAcceptedBy.Connect')
            )}
          </ConnectButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
