/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useReducer,
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { IUserData } from '../types';
import {
  StorageKey,
  clearStorage,
  getStorage,
  setStorage,
} from '../utils/storage';
import { parseJWT } from '../utils/parseJTWS';
import {
  useExchangeAuthIdMutation,
  useLogoutMutation,
  useRefreshAccessTokenMutation,
} from '../graphql/generated';
import { AppContext } from './appContextProvider';
import { logger } from '../logger';
import { getApolloErrorMessage } from '../utils/apollo/errors';
import { useApolloClient } from '@apollo/client';
import { Device } from '@capacitor/device';
import { usePushNotifications } from '../utils/hooks/usePushNotifications';

interface AuthContextState {
  user: IUserData | null;
  authLoaded: boolean;
}

interface AuthContextType extends AuthContextState {
  login: (
    authId: string,
    secureToken: string
  ) => Promise<IUserData | undefined>;
  logout: () => Promise<void>;
  refreshToken: () => Promise<IUserData | undefined>;
}

interface SetUserDataAction {
  type: 'SET_USER_DATA';
  payload: IUserData | null;
}

type AuthAction = SetUserDataAction;

const initialState: AuthContextState = {
  user: null,
  authLoaded: false,
};

const AuthContext = createContext<AuthContextType>({
  ...initialState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (authId: string, secureToken: string) => Promise.resolve(undefined),
  logout: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(undefined),
});

const useAuthContext = () => useContext(AuthContext);

function authReducer(
  state: AuthContextState,
  action: AuthAction
): AuthContextState {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        user: action.payload,
        authLoaded: true,
      };
    default:
      return state;
  }
}

function AuthProvider(props: PropsWithChildren) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [exchangeAuthIdMutation] = useExchangeAuthIdMutation();
  const [logoutMutation] = useLogoutMutation();
  const [refreshAccessToken] = useRefreshAccessTokenMutation();
  const { requestPushNotificationsPermissions } = usePushNotifications();

  const { env } = useContext(AppContext);
  const apolloClient = useApolloClient();

  const refreshToken = useCallback(async () => {
    try {
      const { data } = await refreshAccessToken();
      const token = data?.refreshAccessToken.accessToken;
      if (!token) throw new Error('Empty token from response');
      await setStorage(StorageKey.Token, token);
      const user = parseJWT(token);
      dispatch({
        type: 'SET_USER_DATA',
        payload: user,
      });
      return user;
    } catch (err) {
      logger.error({
        tag: '[AuthProvider][refreshToken]',
        message: `Fail refreshing token: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
    }
  }, []);

  const login = useCallback(
    async (authId: string, secureToken: string) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data } = await exchangeAuthIdMutation({
          variables: { authId, secureToken },
        });
        if (!data?.exchangeAuthId.finished) return;
        const token = data.exchangeAuthId.accessToken!;
        await setStorage(StorageKey.Token, token);
        await setStorage(StorageKey.TokenEnv, env);
        await requestPushNotificationsPermissions();
        const user = parseJWT(token);
        dispatch({
          type: 'SET_USER_DATA',
          payload: user,
        });
        return user;
      } catch (error) {
        logger.info({
          tag: '[AUTH_PROVIDER_LOGIN]',
          message:
            'exchangeAuthIdMutation error. This is probably because the user is already logged in.',
        });
      }
    },
    [env]
  );

  const logout = useCallback(async () => {
    await apolloClient.clearStore();
    await clearStorage();
    const id = await Device.getId();
    try {
      await logoutMutation({ variables: { deviceId: id.identifier } });
    } catch (err) {
      logger.error({
        tag: '[AuthProvider][logoutMutation]',
        message: `Logout mutation failed: ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
    }
    dispatch({
      type: 'SET_USER_DATA',
      payload: null,
    });
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { value: token } = await getStorage(StorageKey.Token);
        const user = token ? parseJWT(token) : null;

        dispatch({
          type: 'SET_USER_DATA',
          payload: user,
        });
      } catch (error) {
        dispatch({
          type: 'SET_USER_DATA',
          payload: null,
        });
      }
    };
    void loadUserData();
  }, []);

  return state.authLoaded ? (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        refreshToken,
      }}
      {...props}
    />
  ) : null;
}

export { AuthContext, AuthProvider, useAuthContext };
