/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React from 'react';
import { IonModal, IonRow, IonList } from '@ionic/react';
import {
  MainContainer,
  ContentContainer,
  Title,
  Subtitle,
  CardContent,
  IonLabelStyled,
  IonListHeaderStyled,
  LeftItems,
  StyledIonCard,
  Close,
} from './ModalNotificationsInfo.style';
import { Trans, useTranslation } from 'react-i18next';

import { Variants, motion } from 'framer-motion';
import { Button } from '../Button/Button';
import { TertiaryTitle } from '../Typography/Headings/Headings';
import { useAuthContext } from '../../providers/authProvider';
import { GlobalRole } from '../../graphql/generated';

const animation: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

type ModalNotificationsInfoProps = {
  isInfoModalOpen: boolean;
  openInfoModal: (setModalIsOpen: boolean) => void;
};

const ModalNotificationsInfo: React.FC<ModalNotificationsInfoProps> = ({
  openInfoModal,
  isInfoModalOpen,
}) => {
  const i18n = useTranslation();
  const { user } = useAuthContext();
  const isPro =
    user?.role === GlobalRole.Agent || user?.role === GlobalRole.AdminAgent;
  const handleDidDismiss = () => {
    openInfoModal(false);
  };

  return (
    <IonModal
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isInfoModalOpen}
      onDidDismiss={() => handleDidDismiss()}
    >
      <MainContainer scrollY={true} class="ion-padding">
        <Close onClick={() => handleDidDismiss()}>{i18n.t('close')}</Close>
        <Title>{i18n.t('settings.Reminders')}</Title>
        <Subtitle>{i18n.t('settings.infoSubtitle')}</Subtitle>
        <ContentContainer>
          <IonList>
            <IonListHeaderStyled>
              {i18n.t('settings.Reminders')}
            </IonListHeaderStyled>
            <StyledIonCard>
              <CardContent>
                <LeftItems>
                  <TertiaryTitle>
                    {i18n.t('settings.activityNotifications')}
                  </TertiaryTitle>
                  <IonLabelStyled>
                    {i18n.t(
                      isPro
                        ? 'settings.propAgent.eventNotificationsSubtitle'
                        : 'settings.eventRemindersSubtitle'
                    )}
                  </IonLabelStyled>
                </LeftItems>
              </CardContent>
            </StyledIonCard>
            <IonListHeaderStyled>
              {i18n.t('settings.updates')}
            </IonListHeaderStyled>
            <StyledIonCard>
              <CardContent>
                <LeftItems>
                  <TertiaryTitle>
                    {i18n.t('settings.statusUpdates')}
                  </TertiaryTitle>
                  <IonLabelStyled>
                    {i18n.t(
                      isPro
                        ? 'settings.propAgent.statusUpdatesSubtitle'
                        : 'settings.statusUpdatesSubtitle'
                    )}
                  </IonLabelStyled>
                </LeftItems>
              </CardContent>
            </StyledIonCard>
            <StyledIonCard>
              <CardContent>
                <LeftItems>
                  <TertiaryTitle>
                    {i18n.t('settings.newActivity')}
                  </TertiaryTitle>
                  <IonLabelStyled>
                    {i18n.t(
                      isPro
                        ? 'settings.propAgent.newActivitySubtitle'
                        : 'settings.newActivitySubtitle'
                    )}
                  </IonLabelStyled>
                </LeftItems>
              </CardContent>
            </StyledIonCard>
            <StyledIonCard>
              <CardContent>
                <LeftItems>
                  <TertiaryTitle>
                    {i18n.t('settings.actionNeeded')}
                  </TertiaryTitle>
                  <IonLabelStyled>
                    {i18n.t(
                      isPro
                        ? 'settings.propAgent.actionNeededSubtitle'
                        : 'settings.actionNeededSubtitle'
                    )}
                  </IonLabelStyled>
                </LeftItems>
              </CardContent>
            </StyledIonCard>
            <IonListHeaderStyled>{i18n.t('settings.chat')}</IonListHeaderStyled>
            <StyledIonCard>
              <CardContent>
                <LeftItems>
                  <TertiaryTitle>
                    {i18n.t('settings.newMessages')}
                  </TertiaryTitle>
                  <IonLabelStyled>
                    {i18n.t(
                      isPro
                        ? 'settings.propAgent.newMessagesSubtitle'
                        : 'settings.newMessagesSubtitle'
                    )}
                  </IonLabelStyled>
                </LeftItems>
              </CardContent>
            </StyledIonCard>
          </IonList>
        </ContentContainer>
        <IonRow style={{ marginBottom: 40, justifyContent: 'center' }}>
          <motion.div variants={animation} style={{ width: '90%' }}>
            <Button color="primary-orange" onClick={handleDidDismiss}>
              <Trans i18nKey="settings.ok" />
            </Button>
          </motion.div>
        </IonRow>
      </MainContainer>
    </IonModal>
  );
};

export default ModalNotificationsInfo;
