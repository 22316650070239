import styled from 'styled-components';

export const SystemChatMessageCard = styled.article`
  padding: 16px;
  border-radius: 20px;
  border: 2px solid #e9eaec;
  background: #fff;

  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
