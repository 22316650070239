import * as React from 'react';

export const HomeOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.12508 8.28253V17.5013C3.12508 17.667 3.19092 17.826 3.30813 17.9432C3.42534 18.0604 3.58432 18.1263 3.75008 18.1263H7.50008V12.8138C7.50008 12.5651 7.59885 12.3267 7.77466 12.1509C7.95048 11.9751 8.18894 11.8763 8.43758 11.8763H11.5626C11.8112 11.8763 12.0497 11.9751 12.2255 12.1509C12.4013 12.3267 12.5001 12.5651 12.5001 12.8138V18.1263H16.2501C16.4158 18.1263 16.5748 18.0604 16.692 17.9432C16.8092 17.826 16.8751 17.667 16.8751 17.5013V8.28253"
      stroke="#23364B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 10.0029L10.4254 2.03411C10.2301 1.82786 9.77344 1.82551 9.57461 2.03411L1.25 10.0029M15.625 6.99504V2.50286H13.75V5.19817"
      stroke="#23364B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
