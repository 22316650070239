import { IonAccordionGroup } from '@ionic/react';
import React, { ReactNode } from 'react';
import {
  AccordionHeader,
  AccordionIcon,
  AccordionIconProps,
  StyledAccordion,
} from './Accordion.styles';
import { chevronDown } from 'ionicons/icons';

type AccordionProps = {
  header: ReactNode;
  content: ReactNode;
  icon?: string;
} & AccordionIconProps;

export const Accordion: React.FC<AccordionProps> = ({
  header,
  content,
  icon = chevronDown,
  animateIcon = true,
  iconSize = '18px',
}) => {
  return (
    <IonAccordionGroup>
      <StyledAccordion value="first">
        <AccordionHeader slot="header" className="ion-no-padding">
          <div>{header}</div>
          <AccordionIcon
            slot="end"
            className="ion-accordion-toggle-icon"
            src={icon}
            animateIcon={animateIcon}
            iconSize={iconSize}
          />
        </AccordionHeader>
        <div slot="content">{content}</div>
      </StyledAccordion>
    </IonAccordionGroup>
  );
};
