import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import { BryaCardButtonIcon } from './CardButton.styles';

interface IBryaCardButtonProps {
  leftIcon?: string;
  rightIcon?: string;
  label: string;
}

type BryaCardButtonProps = React.ComponentProps<typeof IonCard> &
  IBryaCardButtonProps;

const CardButton: React.FC<BryaCardButtonProps> = (props) => {
  const { leftIcon, rightIcon, label, ...rest } = props;
  return (
    <IonCard {...rest} button className="ion-no-margin">
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText color="dark">
                <IonRow className="ion-align-items-center">
                  {leftIcon && (
                    <BryaCardButtonIcon
                      icon={leftIcon}
                      className="ion-margin-end"
                    />
                  )}
                  {label}
                </IonRow>
              </IonText>
            </IonCol>
            {rightIcon && (
              <IonCol size="auto">
                <IonRow className="ion-align-items-center ion-justify-content-end">
                  <BryaCardButtonIcon icon={rightIcon} />
                </IonRow>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default CardButton;
