import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  ApprovalServiceRequestStatus,
  GetMobileSocialEventQuery,
  useUserApprovalSocialEventMutation,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityConfirmed from '../../assets/images/IconActivityConfirmed';
import { locationOutline } from 'ionicons/icons';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { useHistory } from 'react-router';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { ActivityCardItemOrganizedBy } from '../ActivityCardItemOrganizedBy/ActivityCardItemOrganizedBy';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type SocialEventQueryResult = GetMobileSocialEventQuery['getMobileSocialEvent'];

type ConfirmedCustomerActivitySocialEventProps = {
  customerId: number;
  socialEvent: SocialEventQueryResult;
  onSocialEventDeclined: () => unknown;
};

export const ConfirmedCustomerActivitySocialEvent: React.FC<
  ConfirmedCustomerActivitySocialEventProps
> = ({ customerId, socialEvent, onSocialEventDeclined }) => {
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(socialEvent.recurring);
  const history = useHistory();
  const declineSocialEventErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmedCustomerActivitySocialEvent][DeclineSocialEvent]',
  });
  const [declineSocialEvent, { loading: isDeclining }] =
    useUserApprovalSocialEventMutation({
      onError: declineSocialEventErrorHandler,
      onCompleted: onSocialEventDeclined,
    });

  const loading = isDeclining;
  const address = {
    addressLine1: socialEvent.addressLine1,
    addressLine2: socialEvent.addressLine2,
    zipCode: socialEvent.zipCode,
    city: socialEvent.city,
    state: socialEvent.state,
    country: socialEvent.country,
    place: socialEvent.place,
  };

  const handleDecline = () => {
    void declineSocialEvent({
      variables: {
        action: ApprovalServiceRequestStatus.Decline,
        socialEventId: socialEvent.id,
        customerId,
      },
    });
  };

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle title={socialEvent.eventName} />
        ) : (
          socialEvent.eventName
        )
      }
      background="var(--colors-gradient-green)"
      bottomSheetBackground="var(--colors-gradient-green-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityConfirmed />
          {t('ConfirmedCustomerActivitySocialEvent.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('ConfirmedCustomerActivitySocialEvent.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <BodyText>{socialEvent.description}</BodyText>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <CardList>
            <ActivityCardItemAddress icon={locationOutline} address={address} />
            <ActivityCardItemOrganizedBy organizer={socialEvent.RequestedBy} />
            <ActivityCardItemRequestScheduledAt
              scheduledAt={socialEvent.scheduledAt}
            />
          </CardList>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <Button
            color="secondary-green"
            onClick={handleDecline}
            loading={loading}
          >
            {t('ConfirmedCustomerActivitySocialEvent.SecondaryCta')}
          </Button>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
