import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  Container,
  FeedbackItem,
  FeedbackItemBottom,
  FeedbackItemBottomTexts,
  FeedbackItemText,
  FeedbackLabel,
  FeedbackSubtitle,
  FeedbackTitle,
  SaveButton,
  StyledIonContent,
  StyledIonContentPost,
  StyledIonTextArea,
  NoFeedbackContainer,
  NoFeedbackSpan,
} from './FeedbackTab.styles';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import RatingCompleted from '../../assets/images/RatingCompleted';
import RatingEmpty from '../../assets/images/RatingEmpty';
import {
  GraphqlCoachReview,
  PaginationMeta,
  useGetUserCoachReviewsQuery,
  useReviewCoachMutation,
} from '../../graphql/generated';
import { AuthContext } from '../../providers/authProvider';
import { GET_COACH_REVIEWS } from '../../graphql/coach';
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
} from '@ionic/react';
import FeedbackTabSkeleton from './FeedbackTabSkeleton';
import dayjs from 'dayjs';
import { logger } from '../../logger';
import ToastHandler from '../ToastHandler/ToastHandler';

type ActivitiesTabProps = {
  coachId: number;
  coachName: string;
};

const PAGE_SIZE = 100;

const FeedbackTab: React.FC<ActivitiesTabProps> = ({ coachId, coachName }) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>('');
  const i18n = useTranslation();
  const { user } = useContext(AuthContext);
  const [reviewCoachMutation] = useReviewCoachMutation();
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [, setRefetchDone] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const [reviews, setReviews] = useState<Partial<GraphqlCoachReview>[]>([]);
  const [meta, setPaginationMeta] = useState<PaginationMeta | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const { data, previousData, fetchMore, refetch } =
    useGetUserCoachReviewsQuery({
      variables: {
        page: 1,
        pageSize: PAGE_SIZE,
      },
      notifyOnNetworkStatusChange: true,
    });

  const reviewsFromQuery = useMemo(
    () =>
      ((data || previousData)?.getUserCoachReviews
        .reviews as GraphqlCoachReview[]) || [],
    [data, previousData]
  );
  const metaFromQuery = useMemo(
    () =>
      ((data || previousData)?.getUserCoachReviews.meta as PaginationMeta) ||
      [],
    [data, previousData]
  );

  useEffect(() => {
    if (reviewsFromQuery && metaFromQuery) {
      // NOTE: if the query was re-executed we need to discard the previous data
      setReviews(reviewsFromQuery);
      setInitialLoading(false);
      setPaginationMeta(metaFromQuery);
    }
  }, [data]);
  const sendFeedback = useCallback(async () => {
    try {
      await reviewCoachMutation({
        variables: {
          data: {
            coachId,
            comment: feedback,
            rating,
          },
        },
        refetchQueries: [
          {
            query: GET_COACH_REVIEWS,
          },
        ],
        awaitRefetchQueries: true,
      });
      await refetch();
      setSuccessToast(true);
      setRefetchDone(true);
      setRating(0);
      setFeedback('');
    } catch (err) {
      setErrorMessage(!rating ? i18n.t('noRating') : (err as string));
      setErrorToast(true);
    }
  }, [rating, feedback]);

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handleTextareaChange = (event: CustomEvent) => {
    const newValue = event.detail.value as string;
    setFeedback(newValue);
  };

  // Handle loading or error states
  if (initialLoading) return <FeedbackTabSkeleton />;

  // Handle fetching more data when scrolling down
  const handleLoadMore = async () => {
    if (meta && !meta.isLastPage && reviews.length > 0) {
      const { data: queryResult } = await fetchMore({
        variables: {
          page: meta.currentPage + 1, // Load the next page
          pageSize: PAGE_SIZE,
        },
      });
      setReviews((prev) => [
        ...prev,
        ...queryResult.getUserCoachReviews.reviews,
      ]);
      setPaginationMeta(queryResult.getUserCoachReviews.meta);
    }
  };

  return (
    <Container>
      <Tabs
        defaultIndex={selectedTabIndex}
        onSelect={(index) => setSelectedTabIndex(index)}
      >
        <TabList>
          <Tab>{t('giveFeedback')}</Tab>
          <Tab>{t('prevFeedback')}</Tab>
        </TabList>

        <TabPanel>
          <StyledIonContentPost scrollEvents={true} scrollY={false}>
            <FeedbackTitle>{t('howWasYourExp', { coachName })}</FeedbackTitle>
            <FeedbackSubtitle>{t('feedbackYouShare')} </FeedbackSubtitle>
            <div style={{ textAlign: 'center' }}>
              <Rating
                onClick={handleRating}
                emptyIcon={RatingEmpty()}
                fillIcon={RatingCompleted()}
                initialValue={rating}
              />
            </div>
            <FeedbackLabel>{t('leaveComment')}</FeedbackLabel>
            <StyledIonTextArea
              aria-label="Custom textarea"
              class="custom"
              placeholder={t('comments') ?? ''}
              maxlength={240}
              counter={true}
              value={feedback}
              onIonInput={handleTextareaChange}
              autoCapitalize="on"
            ></StyledIonTextArea>
            <SaveButton onClick={() => sendFeedback()}>
              <>{i18n.t('send')}</>
            </SaveButton>
          </StyledIonContentPost>
        </TabPanel>

        <TabPanel>
          <StyledIonContent id="inner-content" scrollEvents={true}>
            {data?.getUserCoachReviews?.reviews.length === 0 && (
              <NoFeedbackContainer>
                <NoFeedbackSpan>{i18n.t('noFeedbackYet')}</NoFeedbackSpan>
              </NoFeedbackContainer>
            )}
            {reviews.length >= 0 && (
              <IonList lines="none">
                {data?.getUserCoachReviews?.reviews?.map((field) => (
                  <IonItem key={field.id}>
                    <FeedbackItem>
                      <Rating
                        readonly={true}
                        initialValue={field.rating}
                        style={{ marginBottom: 8 }}
                        emptyIcon={RatingEmpty()}
                        fillIcon={RatingCompleted()}
                      />
                      <FeedbackItemText>{field.comment}</FeedbackItemText>
                      <FeedbackItemBottom>
                        <FeedbackItemBottomTexts>
                          {field.ReviewedBy &&
                          field.ReviewedBy.firstName === user?.firstName
                            ? i18n.t('you')
                            : field?.ReviewedBy?.firstName}
                        </FeedbackItemBottomTexts>
                        <FeedbackItemBottomTexts>
                          {dayjs(field.createdAt).format('ddd DD')}
                        </FeedbackItemBottomTexts>
                      </FeedbackItemBottom>
                    </FeedbackItem>
                  </IonItem>
                ))}
              </IonList>
            )}
            <IonInfiniteScroll
              disabled={meta?.isLastPage} // Disable when reaching the last page
              onIonInfinite={(event) => {
                handleLoadMore()
                  .then(() => event.target.complete())
                  .catch((err) => {
                    logger.error({
                      tag: '[InfiniteScroll][Error]',
                      message: `Fail loading more content`,
                      error: err as Error,
                    });
                  });
              }}
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles" />
            </IonInfiniteScroll>
          </StyledIonContent>
        </TabPanel>
      </Tabs>
      <ToastHandler
        isOpen={successToast}
        message={i18n.t('feedbackSuccesfully') ?? ''}
        onDidDismiss={() => setSuccessToast(false)}
        duration={12000}
        position="top"
      ></ToastHandler>
      <ToastHandler
        isOpen={errorToast}
        message={errorMessage}
        onDidDismiss={() => setErrorToast(false)}
        duration={2000}
        position="top"
        backgroundColor={'danger'}
        fontColor="#ffffff"
      ></ToastHandler>
    </Container>
  );
};

export default FeedbackTab;
