import { IonContent, IonIcon, useIonViewWillEnter } from '@ionic/react';
import React, { useContext, useMemo } from 'react';
import HeaderWithTitle from '../../components/HeaderWithTitle/HeaderWithTitle';
import {
  PageContainer,
  UserCard,
  UserCardContainer,
  UserCardDescription,
  UserCardName,
  UserCardNameContainer,
  UserCardSubtitle,
} from './WhoIsThisFor.styles';
import { useHistory, useLocation } from 'react-router';
import { RequestType } from '../../constants/enums';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { RightArrow } from '../../assets/images/RightArrow';
import { personAddOutline } from 'ionicons/icons';
import { AuthContext } from '../../providers/authProvider';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';
import {
  AppUser,
  GraphqlCustomer,
  Role,
  useGetUserRelatedCustomersQuery,
} from '../../graphql/generated';
import UserCardSkeleton from './UserCard.sketon';
import { useTranslation } from 'react-i18next';
import { StorageKey, setStorage } from '../../utils/storage';
import { useAppContext } from '../../providers/appContextProvider';

interface IWhoIsThisFor {
  requestType: string;
}

type PageParams = {
  titleKey: string;
  descriptionKey: string;
  route: string;
};

const WhoIsThisFor: React.FC = () => {
  const { globalAlert } = useAppContext();
  const { user: currentUser } = useContext(AuthContext);
  const { state } = useLocation<IWhoIsThisFor>();
  const requestType = state?.requestType;
  const history = useHistory();
  const i18n = useTranslation();

  const { data, loading, refetch } = useGetUserRelatedCustomersQuery({
    variables: {
      roles: [AppUser.FamilyPartner, AppUser.SeniorMember],
      userId: currentUser?.id,
    },
  });

  useIonViewWillEnter(() => {
    void refetch();
  });

  const { titleKey, descriptionKey, route } = useMemo((): PageParams => {
    switch (requestType) {
      case RequestType.Service:
        return {
          titleKey: 'WhoIsThis.service.title',
          descriptionKey: 'WhoIsThis.service.description',
          route: ROUTES.NEW_SERVICE_REQUEST_CHAT,
        };
      case RequestType.Circle:
        return {
          titleKey: 'WhoIsThis.circle.title',
          descriptionKey: 'WhoIsThis.circle.description',
          route: ROUTES.NEW_CIRCLE_REQUEST_CHAT,
        };
      case RequestType.Message:
        return {
          titleKey: 'WhoIsThis.message.title',
          descriptionKey: 'WhoIsThis.message.description',
          route: ROUTES.SEND_MESSAGE,
        };
      case RequestType.InviteFamilyMember:
        return {
          titleKey: 'WhoIsThis.inviteFamilyMember.title',
          descriptionKey: 'WhoIsThis.inviteFamilyMember.description',
          route: ROUTES.INVITES.INVITE_FAMILY_MEMBER,
        };
      case RequestType.InviteCircleMember:
        return {
          titleKey: 'WhoIsThis.inviteCircleMember.title',
          descriptionKey: 'WhoIsThis.inviteCircleMember.description',
          route: ROUTES.INVITES.INVITE_CIRCLE_MEMBER,
        };
      default:
        return {
          titleKey: 'WhoIsThis.genericTitle',
          descriptionKey: '',
          route: '',
        };
    }
  }, [requestType, currentUser?.appFeaturesSelected?.seniorMember]);

  const hasCustomerCircleMembers = (customer: GraphqlCustomer) => {
    return Boolean(
      customer?.UserCustomerRoles?.find(
        (userCustomerRole) => userCustomerRole.role === Role.CircleMember
      )
    );
  };

  const setWhoIsThisId = (customer: GraphqlCustomer) => {
    if (
      requestType === RequestType.Circle &&
      !hasCustomerCircleMembers(customer)
    ) {
      globalAlert({
        title: i18n.t('noCircleMemberAlert.title'),
        subtitle: i18n.t('noCircleMemberAlert.description'),
        firstButtonLabel: i18n.t('noCircleMemberAlert.continue'),
        firstButtonAction: () => {
          history.push(
            ROUTES.INVITES.INVITE_CIRCLE_MEMBER.replace(
              PATH_PARAMS.CUSTOMER_ID,
              String(customer.id)
            )
          );
        },
        secondButtonLabel: i18n.t('noCircleMemberAlert.cancel'),
        secondButtonAction: () => {
          history.goBack();
        },
      });
      return;
    }

    // TODO: deprecate use setStorage for this as it is error prone.
    // Instead, use URL state (query params, path params, location state, etc.)
    void setStorage(StorageKey.WhoIsThisId, customer.id.toString());

    history.push(
      route.replace(PATH_PARAMS.CUSTOMER_ID, customer.id.toString()),
      {
        isMyself: customer.id === currentUser?.customerId ? true : false,
        userId: currentUser?.id,
      }
    );
  };

  return (
    <PageContainer>
      <HeaderWithTitle title={i18n.t(titleKey)} />
      <IonContent className="ion-padding">
        {loading &&
          [...(Array(3) as [])].map(() => (
            <UserCardSkeleton key={Math.random()} />
          ))}
        {!loading &&
          data?.getUserRelatedCustomers?.map((item) => (
            <UserCard key={item.id} onClick={() => setWhoIsThisId(item)}>
              <UserCardContainer>
                <UserAvatar
                  size="3.5rem"
                  user={{ firstName: item.firstName, lastName: item.lastName }}
                />
                <UserCardNameContainer>
                  <UserCardName>{item?.fullName}</UserCardName>
                  <UserCardSubtitle>
                    {currentUser?.customerId === item?.id
                      ? i18n.t('WhoIsThis.MySelf')
                      : i18n.t('WhoIsThis.Member')}
                  </UserCardSubtitle>
                </UserCardNameContainer>
                <RightArrow color="#23364B" />
              </UserCardContainer>
            </UserCard>
          ))}
        {/* TODO: add familyPartner logic in add individual pr */}
        <UserCard
          onClick={() => history.push(ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE)}
        >
          <UserCardContainer>
            <IonIcon src={personAddOutline} size="large" />
            <UserCardNameContainer>
              <UserCardName> {i18n.t('WhoIsThis.AddIndividual')}</UserCardName>
              <UserCardDescription>
                {i18n.t(descriptionKey)}
              </UserCardDescription>
            </UserCardNameContainer>
            <RightArrow color="#23364B" />
          </UserCardContainer>
        </UserCard>
      </IonContent>
    </PageContainer>
  );
};

export default WhoIsThisFor;
