import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  ion-list {
    margin-top: 16px;
  }
`;
export const StyledLabel = styled.p`
  color: var(--body-text-700, #616161);
  font-size: ${pixelToRem(16)};
  font-family: Inter;
  line-height: 140%;
  margin-bottom: 16px;
`;
export const StyledInput = styled.input`
  display: flex;
  width: 100%;
  padding: 13px 32px 13px 44px;
  align-items: center;
  border-radius: 14px;

  border: 1px solid var(--neutral-6, #bfbfbf);
  background: var(--neutral-1, #fff);
`;

export const InputContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 14px;
    left: 16px;
  }
  ion-spinner {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 18px;
  }
`;
