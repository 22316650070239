import * as React from 'react';

export const WelcomeSlide3 = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="291"
    height="378"
    viewBox="0 0 291 378"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107 217.665C158.362 217.665 200 176.048 200 124.712C200 73.3755 158.362 31.7588 107 31.7588C55.6377 31.7588 14 73.3755 14 124.712C14 176.048 55.6377 217.665 107 217.665Z"
        fill="#FF9B21"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.7034 12L116.516 31.8031L96.7034 51.6061L76.8904 31.8031L96.7034 12Z"
        fill="#2B44FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7418 55.7207L45.594 60.5704L40.7418 65.4202L35.8896 60.5704L40.7418 55.7207Z"
        fill="#2026A2"
      />
    </g>
    <g filter="url(#filter0_d_7594_267017)">
      <path
        d="M154.38 12.9752L242.005 16.043C251.91 16.3898 259.658 24.7207 259.312 34.6506L251.699 253.219C251.354 263.149 243.044 270.918 233.14 270.571L145.514 267.503C135.61 267.156 127.861 258.825 128.207 248.895L135.82 30.327C136.166 20.3971 144.475 12.6284 154.38 12.9752Z"
        fill="#F2F2F2"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.382 21.4899C248.985 21.7213 254.15 27.275 253.92 33.8948L246.279 253.262C246.049 259.882 240.509 265.061 233.906 264.83L145.506 261.735C138.903 261.503 133.737 255.95 133.968 249.33L141.609 29.963C141.839 23.3427 147.379 18.1637 153.982 18.3952L167.763 18.8774L167.693 20.8759C167.563 24.627 170.49 27.7745 174.232 27.9053L221.18 29.5489C224.922 29.6802 228.061 26.7449 228.192 22.9938L228.261 20.9958L242.382 21.4899Z"
      fill="#FFF7E6"
    />
    <g filter="url(#filter1_d_7594_267017)">
      <mask
        id="mask0_7594_267017"
        maskUnits="userSpaceOnUse"
        x="193"
        y="52"
        width="89"
        height="138"
      >
        <path d="M281.653 52H194V189.09H281.653V52Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7594_267017)">
        <mask
          id="mask1_7594_267017"
          maskUnits="userSpaceOnUse"
          x="194"
          y="52"
          width="88"
          height="37"
        >
          <path d="M281.654 52H194.001V88.2886H281.654V52Z" fill="white" />
        </mask>
        <g mask="url(#mask1_7594_267017)">
          <path
            d="M278.977 52H196.677C195.199 52 194.001 53.2035 194.001 54.6881V85.6007C194.001 87.085 195.199 88.2886 196.677 88.2886H278.977C280.456 88.2886 281.654 87.085 281.654 85.6007V54.6881C281.654 53.2035 280.456 52 278.977 52Z"
            fill="white"
          />
          <mask
            id="mask2_7594_267017"
            maskUnits="userSpaceOnUse"
            x="200"
            y="62"
            width="49"
            height="16"
          >
            <path
              d="M248.867 62.8857H200.022V77.4012H248.867V62.8857Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_7594_267017)">
            <path
              d="M246.86 62.8857H202.029C200.921 62.8857 200.022 63.7884 200.022 64.9018V66.313C200.022 67.4264 200.921 68.329 202.029 68.329H246.86C247.968 68.329 248.867 67.4264 248.867 66.313V64.9018C248.867 63.7884 247.968 62.8857 246.86 62.8857Z"
              fill="#DDE3E9"
            />
            <path
              d="M230.801 71.958H202.029C200.921 71.958 200.022 72.8606 200.022 73.974V75.3853C200.022 76.4987 200.921 77.4013 202.029 77.4013H230.801C231.909 77.4013 232.808 76.4987 232.808 75.3853V73.974C232.808 72.8606 231.909 71.958 230.801 71.958Z"
              fill="#DDE3E9"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.286 74.8486C274.872 74.8486 276.97 72.7425 276.97 70.1445C276.97 67.5465 274.872 65.4404 272.286 65.4404C269.699 65.4404 267.602 67.5465 267.602 70.1445C267.602 72.7425 269.699 74.8486 272.286 74.8486Z"
            fill="#DDE3E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.286 72.161C273.395 72.161 274.294 71.2584 274.294 70.1449C274.294 69.0315 273.395 68.1289 272.286 68.1289C271.178 68.1289 270.279 69.0315 270.279 70.1449C270.279 71.2584 271.178 72.161 272.286 72.161Z"
            fill="#F2F2F2"
          />
        </g>
        <mask
          id="mask3_7594_267017"
          maskUnits="userSpaceOnUse"
          x="194"
          y="93"
          width="88"
          height="29"
        >
          <path
            d="M281.654 93.666H194.001V121.89H281.654V93.666Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_7594_267017)">
          <path
            d="M278.977 93.666H196.677C195.199 93.666 194.001 94.8696 194.001 96.3539V119.203C194.001 120.687 195.199 121.89 196.677 121.89H278.977C280.456 121.89 281.654 120.687 281.654 119.203V96.3539C281.654 94.8696 280.456 93.666 278.977 93.666Z"
            fill="white"
          />
          <mask
            id="mask4_7594_267017"
            maskUnits="userSpaceOnUse"
            x="220"
            y="102"
            width="49"
            height="12"
          >
            <path
              d="M268.941 102.135H220.096V113.425H268.941V102.135Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_7594_267017)">
            <path
              d="M266.934 102.135H222.103C220.995 102.135 220.096 103.037 220.096 104.151V104.352C220.096 105.466 220.995 106.368 222.103 106.368H266.934C268.042 106.368 268.941 105.466 268.941 104.352V104.151C268.941 103.037 268.042 102.135 266.934 102.135Z"
              fill="#DDE3E9"
            />
            <path
              d="M250.875 109.191H222.103C220.995 109.191 220.096 110.094 220.096 111.208V111.409C220.096 112.522 220.995 113.425 222.103 113.425H250.875C251.984 113.425 252.883 112.522 252.883 111.409V111.208C252.883 110.094 251.984 109.191 250.875 109.191Z"
              fill="#DDE3E9"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.051 115.843C212.486 115.843 216.08 112.233 216.08 107.779C216.08 103.326 212.486 99.7148 208.051 99.7148C203.617 99.7148 200.022 103.326 200.022 107.779C200.022 112.233 203.617 115.843 208.051 115.843Z"
            fill="#C5CFD6"
          />
        </g>
        <mask
          id="mask5_7594_267017"
          maskUnits="userSpaceOnUse"
          x="194"
          y="127"
          width="88"
          height="29"
        >
          <path
            d="M281.654 127.264H194.001V155.488H281.654V127.264Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_7594_267017)">
          <path
            d="M278.977 127.264H196.677C195.199 127.264 194.001 128.467 194.001 129.952V152.8C194.001 154.285 195.199 155.488 196.677 155.488H278.977C280.456 155.488 281.654 154.285 281.654 152.8V129.952C281.654 128.467 280.456 127.264 278.977 127.264Z"
            fill="white"
          />
          <mask
            id="mask6_7594_267017"
            maskUnits="userSpaceOnUse"
            x="200"
            y="135"
            width="49"
            height="13"
          >
            <path
              d="M248.867 135.73H200.022V147.02H248.867V135.73Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_7594_267017)">
            <path
              d="M246.86 135.73H202.029C200.921 135.73 200.022 136.633 200.022 137.747V137.948C200.022 139.062 200.921 139.965 202.029 139.965H246.86C247.968 139.965 248.867 139.062 248.867 137.948V137.747C248.867 136.633 247.968 135.73 246.86 135.73Z"
              fill="#DDE3E9"
            />
            <path
              d="M230.801 142.787H202.029C200.921 142.787 200.022 143.69 200.022 144.803V145.005C200.022 146.119 200.921 147.021 202.029 147.021H230.801C231.909 147.021 232.808 146.119 232.808 145.005V144.803C232.808 143.69 231.909 142.787 230.801 142.787Z"
              fill="#AFB9C5"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.286 146.079C274.872 146.079 276.97 143.973 276.97 141.375C276.97 138.777 274.872 136.671 272.286 136.671C269.699 136.671 267.602 138.777 267.602 141.375C267.602 143.973 269.699 146.079 272.286 146.079Z"
            fill="#DDE3E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M272.286 143.392C273.395 143.392 274.294 142.489 274.294 141.376C274.294 140.262 273.395 139.359 272.286 139.359C271.178 139.359 270.279 140.262 270.279 141.376C270.279 142.489 271.178 143.392 272.286 143.392Z"
            fill="#F2F2F2"
          />
        </g>
        <mask
          id="mask7_7594_267017"
          maskUnits="userSpaceOnUse"
          x="194"
          y="160"
          width="88"
          height="30"
        >
          <path
            d="M281.654 160.866H194.001V189.091H281.654V160.866Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_7594_267017)">
          <path
            d="M278.977 160.866H196.677C195.199 160.866 194.001 162.07 194.001 163.554V186.403C194.001 187.887 195.199 189.091 196.677 189.091H278.977C280.456 189.091 281.654 187.887 281.654 186.403V163.554C281.654 162.07 280.456 160.866 278.977 160.866Z"
            fill="white"
          />
          <mask
            id="mask8_7594_267017"
            maskUnits="userSpaceOnUse"
            x="220"
            y="169"
            width="49"
            height="12"
          >
            <path
              d="M268.941 169.334H220.096V180.624H268.941V169.334Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask8_7594_267017)">
            <path
              d="M266.934 169.334H222.103C220.995 169.334 220.096 170.237 220.096 171.35V171.552C220.096 172.665 220.995 173.568 222.103 173.568H266.934C268.042 173.568 268.941 172.665 268.941 171.552V171.35C268.941 170.237 268.042 169.334 266.934 169.334Z"
              fill="#DDE3E9"
            />
            <path
              d="M250.875 176.391H222.103C220.995 176.391 220.096 177.293 220.096 178.407V178.609C220.096 179.722 220.995 180.625 222.103 180.625H250.875C251.984 180.625 252.883 179.722 252.883 178.609V178.407C252.883 177.293 251.984 176.391 250.875 176.391Z"
              fill="#DDE3E9"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.051 183.044C212.486 183.044 216.08 179.433 216.08 174.979C216.08 170.526 212.486 166.915 208.051 166.915C203.617 166.915 200.022 170.526 200.022 174.979C200.022 179.433 203.617 183.044 208.051 183.044Z"
            fill="#C5CFD6"
          />
        </g>
      </g>
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.7669 184.396C89.3286 186.543 92.8281 187.581 94.4708 187.157C98.5901 186.095 98.9688 171.022 95.7816 165.08C92.5943 159.137 76.2092 156.547 75.3834 168.321C75.0967 172.407 76.8121 176.054 79.3465 179.03L74.8021 200.216H88.0025L85.7669 184.396Z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.0278 167.748C96.8971 166.978 96.5775 166.122 96.3197 165.393C95.9872 164.454 95.4841 163.607 94.9305 162.809C93.8882 161.306 92.6379 159.949 91.2573 158.822C88.7356 156.765 85.5256 155.578 82.369 155.963C80.7752 156.158 79.228 156.766 77.9112 157.778C76.7214 158.693 75.577 160.039 74.0364 160.136C72.3601 160.241 70.8235 158.761 69.4791 157.847C67.9633 156.816 66.3508 156.066 64.5792 155.774C61.6113 155.285 58.8233 156.288 56.7779 158.723C54.6013 161.315 53.0191 165.116 54.4485 168.55C54.7142 169.189 55.056 169.727 55.5465 170.173C55.9964 170.582 56.6835 171.017 56.8659 171.671C57.0597 172.365 56.4809 173.262 56.2565 173.888C55.935 174.787 55.671 175.729 55.7648 176.705C55.919 178.309 56.9262 179.839 57.9897 180.893C59.072 181.965 60.404 182.58 61.8267 182.863C62.7767 183.052 63.7476 183.157 64.7131 183.086C65.1924 183.05 65.6153 182.917 66.0797 182.811C66.5313 182.708 66.7837 182.828 67.1669 183.076C68.9467 184.227 70.8605 184.672 72.9157 184.501C74.6502 184.357 76.6494 183.936 78.0644 182.747C79.6357 181.427 79.6044 179.607 79.2347 177.662C79.6264 177.856 80.7102 177.976 80.0626 177.202C79.806 176.895 79.3213 176.771 78.9921 176.59C78.6088 176.38 78.2196 176.109 77.9219 175.766C76.7201 174.384 77.9339 171.569 79.4445 171.106C81.6889 170.417 82.1914 173.591 83.7935 174.413C84.7147 174.885 85.6783 174.239 86.3685 173.58C87.293 172.698 87.9231 171.528 88.467 170.338C88.9151 169.358 89.3321 168.364 89.7888 167.389C90.0021 166.933 90.7404 165.646 90.3697 165.131C91.9772 164.81 93.7676 165.479 95.1521 166.355C95.7243 166.717 96.1605 167.116 96.4658 167.756C96.5311 167.893 96.6735 168.3 96.8522 168.317C97.185 168.348 97.0606 167.941 97.0278 167.748Z"
      fill="#191847"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9439 268.985H93.435L99.0027 337.205H35.751L50.9439 268.985Z"
      fill="#C5CFD6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9439 268.985H64.0123L71.8341 337.205H35.751L50.9439 268.985Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.781 282.501C112.006 292.63 104.545 317.219 104.545 317.219L110.879 320.114C110.879 320.114 126.951 294.267 136.774 276.598C136.316 280.575 135.85 285.053 135.418 289.761C134.395 300.913 135.417 325.006 135.968 331.966C136.314 336.34 142.256 335.424 142.811 331.955C142.904 331.367 143.287 329.304 143.859 326.226C146.66 311.135 153.991 271.636 154.004 261.965C154.01 258.073 146.409 254.577 141.878 257.298C138.766 253.977 132.877 252.063 129.493 257.549C127.342 261.037 122.232 270.938 116.781 282.501Z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.998 308.115L126.739 256.6C131.947 248.219 146.549 258.305 144.773 263.165C140.741 274.2 120.233 309.925 119.032 313.212L104.998 308.115Z"
      fill="#2F3676"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.015 314.977C105.28 314.065 103.939 313.832 103.067 314.613C102.305 315.296 101.496 316.099 101.148 316.702C100.404 317.991 99.4058 320.711 99.4058 320.711C100.901 321.575 126.272 336.226 126.272 336.226C126.272 336.226 129.318 333.368 127.221 331.789C125.124 330.21 123.76 329.126 123.76 329.126L114.759 316.721C114.428 316.265 113.786 316.172 113.34 316.516L111.779 317.717C111.779 317.717 109.128 317.486 107.822 316.732C107.248 316.4 106.581 315.68 106.015 314.977Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M137.007 328.934C135.914 328.512 134.636 328.98 134.272 330.093C133.953 331.065 133.654 332.165 133.654 332.862C133.654 334.35 134.15 337.205 134.15 337.205C135.875 337.205 165.171 337.205 165.171 337.205C165.171 337.205 166.381 333.206 163.775 332.887C161.17 332.568 159.447 332.311 159.447 332.311L145.451 326.07C144.936 325.841 144.334 326.082 144.119 326.603L143.369 328.423C143.369 328.423 140.957 329.549 139.449 329.549C138.786 329.549 137.848 329.259 137.007 328.934Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.528 265.923C155.528 280.539 148.589 314.632 148.584 317.85L133.654 317.862C133.654 317.862 136.932 272.23 135.978 271.739C135.025 271.247 96.8994 275.546 85.4648 275.546C68.9762 275.546 62.1552 265.146 61.6017 245.879H94.2651C100.916 246.591 135.606 253.589 148.489 255.995C154.004 257.025 155.528 261.896 155.528 265.923Z"
      fill="#5C63AB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.825 238.98L137.405 254.184C141.939 256.118 145.653 258.254 148.549 260.591C149.2 261.377 149.909 262.882 146.845 262.209C143.782 261.535 140.529 260.964 139.953 261.961C139.377 262.958 141.074 264.485 140.01 265.789C139.3 266.659 136.914 263.893 132.852 257.492L116.65 247.937L121.825 238.98Z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.3346 191.047L87.0906 190.231C94.5828 216.722 133.078 234.779 135.441 244.683C136.307 248.314 133.694 250.15 135.044 251.256L128.862 255.783C127.815 254.561 125.581 256.796 120.916 255.233C116.251 253.669 82.3346 222.415 82.3346 191.047Z"
      fill="#E87613"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.3328 271.063C67.042 283.247 100.035 269.885 103.482 257.456C107.809 241.853 87.511 232.139 89.5587 191.072L76.518 188.408C66.1256 205.687 50.9242 248.851 61.3328 271.063Z"
      fill="#FF9B21"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M228.415 186.011C224.714 188.241 221.078 189.319 219.372 188.879C215.092 187.776 214.698 172.117 218.01 165.944C221.321 159.771 238.344 157.08 239.203 169.311C239.5 173.556 237.718 177.344 235.085 180.436L239.806 202.445H226.092L228.415 186.011Z"
      fill="#FFC069"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M223.427 159.22C225.301 158.649 228.021 158.455 229.166 158.586C228.515 157.026 228.256 155.288 228.467 153.596C228.672 151.951 229.406 150.551 230.086 149.084C230.399 148.408 230.754 147.736 231.283 147.218C231.822 146.691 232.504 146.343 233.22 146.178C233.952 146.008 234.718 146.022 235.451 146.176C235.776 146.244 236.067 146.392 236.385 146.475C236.711 146.559 236.869 146.355 237.105 146.151C237.675 145.657 238.511 145.413 239.232 145.326C240.041 145.228 240.88 145.35 241.651 145.616C242.402 145.875 243.124 146.295 243.667 146.908C243.908 147.18 244.128 147.491 244.27 147.833C244.329 147.974 244.339 148.057 244.479 148.109C244.609 148.156 244.76 148.158 244.894 148.203C246.189 148.642 247.296 149.642 247.899 150.927C248.251 151.676 248.417 152.996 248.372 154.475C248.326 155.954 246.991 159.813 245.742 161.221C244.627 162.478 242.988 163.307 241.407 163.67C242.457 164.87 243.155 166.369 243.672 167.894C244.233 169.551 244.581 171.272 244.709 173.026C244.831 174.692 244.842 176.454 244.451 178.084C244.086 179.609 243.305 180.86 242 181.654C241.403 182.018 240.76 182.291 240.113 182.54C239.476 182.786 238.837 183.025 238.17 183.163C236.906 183.425 235.451 183.38 234.478 182.374C234.127 182.012 233.741 181.556 233.485 181.113C233.393 180.954 233.338 180.85 233.45 180.694C233.584 180.508 233.8 180.365 233.972 180.219C234.953 179.387 235.56 178.277 235.713 176.958C235.869 175.619 235.509 173.891 234.208 173.307C232.601 172.585 230.832 173.413 229.405 174.185C228.798 172.043 227.552 170.114 225.599 169.132C223.437 168.045 220.958 167.918 218.737 166.985C218.158 166.742 217.569 166.466 217.143 165.971C216.965 165.764 216.756 165.496 216.685 165.222C216.591 164.862 216.823 164.561 217.002 164.281C217.952 162.794 221.553 159.792 223.427 159.22Z"
      fill="#964B00"
    />
    <rect
      x="209.994"
      y="321.005"
      width="7.40495"
      height="7.12552"
      fill="#FFC069"
    />
    <path
      d="M178.286 316.954L169.196 327.901L178.286 329.948L184.286 319.624L178.286 316.954Z"
      fill="#FFC069"
    />
    <path
      d="M232.959 330.786L240.923 280.978H234.985C232.866 295.811 228.767 326.315 229.326 329.669C229.885 333.022 231.981 331.811 232.959 330.786Z"
      fill="#D9D9D9"
      stroke="#D9D9D9"
    />
    <rect
      x="230.583"
      y="270.708"
      width="51.4155"
      height="10.1993"
      rx="5.09963"
      fill="#D9D9D9"
    />
    <path
      d="M273.477 280.698H267.189L276.271 333.511C276.341 334.046 276.816 335.104 278.157 335.048C279.498 334.992 279.647 333.767 279.554 333.162L273.477 280.698Z"
      fill="#D9D9D9"
      stroke="#D9D9D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M216.705 323.786C217.795 323.359 219.073 323.829 219.44 324.94C219.781 325.97 220.109 327.154 220.109 327.898C220.109 329.443 219.594 332.409 219.594 332.409C217.801 332.409 187.364 332.409 187.364 332.409C187.364 332.409 186.108 328.255 188.814 327.924C191.521 327.593 193.311 327.326 193.311 327.326L207.889 320.826C208.403 320.597 209.006 320.838 209.221 321.358L210.016 323.287C210.016 323.287 212.521 324.456 214.088 324.456C214.798 324.456 215.81 324.136 216.705 323.786Z"
      fill="#E4E4E4"
    />
    <path
      d="M252.467 253.521L278.734 255.338C282.925 266.459 275.73 271.428 271.608 272.523L214.534 275.457L217.582 321.38C217.688 321.454 217.768 321.54 217.764 321.619C218.472 322.207 212.195 322.438 210.343 322.122C206.105 303.097 198.718 264.391 203.078 261.765C207.437 259.138 237.82 255.175 252.467 253.521Z"
      fill="#1F28CF"
      stroke="#1F28CF"
    />
    <path
      d="M217.887 229.422C220.793 227.801 220.961 218.221 220.681 213.634C202.518 231.629 188.57 234.777 183.866 234.102L189.525 239.621C196.566 241.018 210.855 233.497 217.119 229.561C216.164 230.19 214.981 231.042 217.887 229.422Z"
      fill="#DB2721"
      stroke="#DB2721"
    />
    <path
      d="M252.955 252.754C252.061 246.271 239.729 232.076 233.675 225.788V216.847C232.697 244.021 206.64 244.441 206.5 243.253C206.388 242.303 204.87 240.622 204.125 239.9C220.332 232.411 221.915 217.219 220.681 210.559C224.202 198.376 234.49 191.558 239.193 189.672C242.714 190.622 255.936 204.784 262.107 211.747C273.843 228.904 277.615 247.491 278.035 254.64C262.554 256.149 254.865 254.011 252.955 252.754Z"
      fill="#FF4133"
    />
    <rect
      x="171.598"
      y="222.938"
      width="9.86253"
      height="18.3727"
      rx="4.93127"
      transform="rotate(-45 171.598 222.938)"
      fill="#E4E7EE"
      stroke="#FFBBBA"
      strokeWidth="3"
    />
    <rect
      x="175.215"
      y="216.887"
      width="12.1481"
      height="22.1704"
      rx="6.07403"
      transform="rotate(-18.896 175.215 216.887)"
      fill="#E4E7EE"
      stroke="#FF837C"
      strokeWidth="3"
    />
    <rect
      x="180.369"
      y="214.886"
      width="12.1481"
      height="22.1704"
      rx="6.07403"
      transform="rotate(-10.5688 180.369 214.886)"
      fill="#E4E7EE"
      stroke="#FFBBBA"
      strokeWidth="3"
    />
    <rect
      x="91.8197"
      y="226.431"
      width="9.86253"
      height="18.3727"
      rx="4.93127"
      transform="rotate(-45 91.8197 226.431)"
      fill="#E4E7EE"
      stroke="#FFBBBA"
      strokeWidth="3"
    />
    <rect
      x="95.4362"
      y="220.38"
      width="12.1481"
      height="22.1704"
      rx="6.07403"
      transform="rotate(-18.896 95.4362 220.38)"
      fill="#E4E7EE"
      stroke="#FF837C"
      strokeWidth="3"
    />
    <rect
      x="100.59"
      y="218.379"
      width="12.1481"
      height="22.1704"
      rx="6.07403"
      transform="rotate(-10.5688 100.59 218.379)"
      fill="#E4E7EE"
      stroke="#FFBBBA"
      strokeWidth="3"
    />
    <path
      d="M177.09 236.897C175.809 234.731 173.248 230.274 173.248 229.771C174.869 228.877 177.137 229.398 178.068 229.771C181.142 230.665 182.143 232.379 182.26 233.124C182.818 235.807 179.046 236.757 177.09 236.897Z"
      fill="#FFC069"
      stroke="#FFC069"
    />
    <path
      d="M196.721 239.196L190.084 243.812L209.365 243.881C209.505 243.742 209.127 244.184 209.854 243.457C210.58 242.731 208.061 240.826 206.71 239.964L199.445 238.707C198.439 238.316 197.21 238.87 196.721 239.196Z"
      fill="#FFC069"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M278.455 254.919H237.414C229.343 238.722 234.575 196.036 234.575 196.036L239.404 189.532C254.435 199.253 276.503 231.538 278.455 254.919Z"
      fill="#FF4133"
    />
    <path
      d="M206.006 268.891L176.177 319.608L180.717 322.892C181.043 323.008 181.891 323.171 182.673 322.892C193.105 313.205 214.864 290.044 218.441 274.899L270.066 272.664C274.7 272.081 282.947 267.62 278.868 254.431C275.049 255.013 264.589 255.828 253.3 254.431L209.429 265.818C208.288 266.307 206.006 267.606 206.006 268.891Z"
      fill="#2B44FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.474 328.869C178.634 329.025 179.524 330.055 179.307 331.205C179.106 332.271 178.818 333.465 178.458 334.116C177.708 335.468 175.82 337.812 175.82 337.812C174.252 336.943 147.631 322.187 147.631 322.187C147.631 322.187 148.546 317.944 151.074 318.967C153.602 319.989 155.297 320.624 155.297 320.624L171.198 322.006C171.759 322.055 172.17 322.558 172.105 323.118L171.865 325.19C171.865 325.19 173.49 327.427 174.86 328.187C175.481 328.531 176.522 328.742 177.474 328.869Z"
      fill="#E4E4E4"
    />
    <rect
      x="32.6787"
      y="252.68"
      width="212.644"
      height="4.59985"
      fill="#C5CFD6"
    />
    <path
      d="M20.2601 246.371C20.2601 244.163 21.881 244.005 22.6915 244.203L255.115 243.874C257.428 244.084 257.612 245.451 257.415 246.108L255.838 252.745H23.6772L20.2601 246.371Z"
      fill="#DDE3E9"
      stroke="#DDE3E9"
    />
    <path
      d="M35.2101 257.286H42.3356V334.759C42.3356 336.726 40.7405 338.321 38.7728 338.321C36.8052 338.321 35.2101 336.726 35.2101 334.759V257.286Z"
      fill="#C5CFD6"
    />
    <path
      d="M48.9022 257.286H56.0277V333.082C56.0277 335.05 54.4326 336.645 52.465 336.645C50.4973 336.645 48.9022 335.05 48.9022 333.082V257.286Z"
      fill="#C5CFD6"
    />
    <path
      d="M221.731 257.286H228.996V334.689C228.996 336.695 227.369 338.321 225.363 338.321C223.357 338.321 221.731 336.695 221.731 334.689V257.286Z"
      fill="#C5CFD6"
    />
    <path
      d="M235.423 257.286H242.688V334.689C242.688 336.695 241.062 338.321 239.055 338.321C237.049 338.321 235.423 336.695 235.423 334.689V257.286Z"
      fill="#C5CFD6"
    />
    <path
      d="M72.6007 236.889L54.9459 231.161L52.6154 237.309C52.1069 238.65 52.5919 240.01 52.8979 240.522C53.0674 241.36 55.4637 242.734 56.6407 243.316H102.332L109.817 240.103L96.1949 233.397L91.4562 237.239C84.4507 238.524 75.9669 237.541 72.6007 236.889Z"
      fill="#B28B67"
      stroke="#B28B67"
    />
    <path
      d="M76.4948 188.617C69.6309 194.487 59.1914 214.696 55.188 230.988L63.1257 234.416C65.714 236.029 74.6525 239.376 83.7174 237.171L87.2261 233.407L87.2261 216.56L87.9739 194.559H86.4208C85.3471 194.536 82.9007 194.169 81.7043 192.879L76.4948 188.617Z"
      fill="#FF9B21"
    />
    <path
      d="M110.38 239.732L105.273 241.64C103.214 239.855 90.1417 239.265 91.1504 238.459C92.4112 237.452 97.7703 236.392 101.048 237.611C104.327 238.83 116.495 230.137 115.486 233.953C114.679 237.007 111.746 239.078 110.38 239.732Z"
      fill="#B28B67"
      stroke="#B28B67"
    />
    <defs>
      <filter
        id="filter0_d_7594_267017"
        x="84.1959"
        y="0.963867"
        width="199.128"
        height="325.618"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-10" dy="22" />
        <feGaussianBlur stdDeviation="17" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7594_267017"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7594_267017"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_7594_267017"
        x="190.001"
        y="52"
        width="95.6521"
        height="145.091"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7594_267017"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7594_267017"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
