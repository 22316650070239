import * as React from 'react';

export const StarIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg width={20} height={31} viewBox="0 0 20 31" fill="none" {...props}>
    <path
      d="M19.284 12.697l-5.95-.865-2.66-5.393a.753.753 0 00-1.345 0l-2.661 5.393-5.951.865a.749.749 0 00-.415 1.28l4.305 4.197-1.017 5.928a.75.75 0 001.088.79L10 22.093l5.322 2.799a.749.749 0 001.087-.79l-1.017-5.928 4.306-4.197a.749.749 0 00.218-.43.747.747 0 00-.633-.85z"
      fill="#FFC53D"
    />
  </svg>
);
