import { IonCard } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  ion-radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  ion-radio::part(container) {
    width: 20px;
    height: 20px;

    border-radius: 10px;
    border: 2px solid #808080;
  }

  ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
  }

  ion-radio.radio-checked::part(container) {
    background: var(--colors-primary-orange);
    border-color: transparent;
  }

  ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;

    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: var(--ion-color-success-contrast);

    transform: rotate(45deg);
  }

  ion-radio {
    color: var(--colors-primary-blue);
    font-size: ${pixelToRem(16)};
    line-height: 20px;
    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardText = styled.span`
  color: var(--body-text-900);
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 400;
  margin-left: 12px;
`;

export const Card = styled(IonCard)`
  margin-bottom: 32px;
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
`;

export const RadioItem = styled.div`
  border-bottom: 1px solid #e5e6ee;
  height: 80px;
  width: 100%;
  align-items: center;
  display: flex;
`;

export const RadioInfo = styled.span`
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  color: var(--body-text-700);
`;
