import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
export const StyledIonContent = styled(IonContent)`
  height: calc(50vh - 100px);
`;

export const TimeLineContent = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: start;
`;

export const ActivityCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 18px;
  padding-top: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background-color: #fcfcfc;
  margin-top: 15px;
  width: 100%;
`;

export const Title = styled.h2`
  margin-top: 10px;
  font-weight: 600;
  font-size: ${pixelToRem(17)};
  color: #23364b;
`;

export const Subtitle = styled.p`
  margin-top: 8px;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: #595959;
`;
