import { ROUTES } from '../../constants/routes';
import { GlobalRole } from '../../graphql/generated';
import { IUserData } from '../../types';

const isPro = (role?: string) =>
  role === GlobalRole.AdminAgent || role === GlobalRole.Agent;

const isBryaUser = (role?: string) =>
  role === GlobalRole.Admin ||
  role === GlobalRole.Coach ||
  role === GlobalRole.Support;

export const getUserLandingRoute = (user?: IUserData | null) => {
  if (!user) return ROUTES.LOGIN;

  if (!user.isEmailVerified) return ROUTES.VERIFY_EMAIL;

  if (user.appFeaturesSelected?.circleMember) return ROUTES.TABS.CIRCLE;

  if (isPro(user.role)) return ROUTES.TABS.ACTIVITIES;

  if (isBryaUser(user.role)) return ROUTES.BRYA_USER;

  if (!user.customerId) {
    return ROUTES.MEMBERSHIP.ABOUT_YOU;
  }

  // TODO: how to detect if user needs to "tell about loved one / friend"?
  // return ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE || ROUTES.MEMBERSHIP.ABOUT_FRIEND

  return ROUTES.TABS.ASK_BRYA;
};
