import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  ion-label {
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    line-height: 20px;
    color: var(--body-text-900);
    padding-bottom: 4px;
  }

  ion-select {
    padding: 0px 16px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #bfbfbf;
    border-radius: 14px;

    ::part(container) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    ::part(placeholder),
    ::part(icon) {
      font-size: ${pixelToRem(16)};
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      color: var(--body-text-500);
    }
  }
`;
