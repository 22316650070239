import { IonContent, IonHeader, IonSkeletonText, IonTitle } from '@ionic/react';
import { AvatarContainer, Toolbar } from './RateProfessionalModalSheet.styles';
import { Space } from '../Space/Space';

export const RateProfessionalSkeleton: React.FC = () => {
  return (
    <>
      <IonHeader>
        <Toolbar>
          <IonTitle>
            <IonSkeletonText
              animated
              style={{ width: 'auto', height: '29px', borderRadius: 0 }}
            />
          </IonTitle>
        </Toolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <AvatarContainer>
          <IonSkeletonText
            animated
            style={{ width: '88px', height: '88px', borderRadius: '50%' }}
          />
        </AvatarContainer>
        <Space direction="column" size="large" align="center">
          <IonSkeletonText
            animated
            style={{ width: '160px', height: '54px', borderRadius: 0 }}
          />

          <IonSkeletonText
            animated
            style={{ width: '140px', height: '24px', borderRadius: 0 }}
          />

          <Space direction="column" size="small" style={{ width: '100%' }}>
            <IonSkeletonText
              animated
              style={{ width: '', height: '50px', borderRadius: 0 }}
            />
            <IonSkeletonText
              animated
              style={{ width: '100%', height: '95px', borderRadius: '14px' }}
            />
          </Space>

          <IonSkeletonText
            animated
            style={{ width: '100%', height: '56px', borderRadius: '50px' }}
          />
        </Space>
      </IonContent>
    </>
  );
};
