import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  CircleRequestCategoriesEnum,
  GetCircleRequestQuery,
  useUpdateCircleRequestTimeMutation,
  useUserCancelCircleRequestMutation,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityConfirmed from '../../assets/images/IconActivityConfirmed';
import { Button } from '../Button/Button';
import { useState } from 'react';
import { AlertWithInput } from '../AlertWithInput/AlertWithInput';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { ActivityCardItemRequestDescription } from '../ActivityCardItemRequestDescription/ActivityCardItemRequestDescription';
import { ActivityCardItemRequestTimeline } from '../ActivityCardItemRequestTimeline/ActivityCardItemRequestTimeline';
import { useHistory } from 'react-router';
import { ActivityCardItemRideInformation } from '../ActivityCardItemRideInformation/ActivityCardItemRideInformation';
import { ActivityCardItemRequestConnectAcceptedBy } from '../ActivityCardItemRequestConnectAcceptedBy/ActivityCardItemRequestConnectAcceptedBy';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { RequestCategoryName } from '../RequestCategoryName/RequestCategoryName';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type CircleRequestQueryResult = GetCircleRequestQuery['getCircleRequest'];

type ConfirmedCustomerActivityCircleRequestProps = {
  circleRequest: CircleRequestQueryResult;
  onCircleRequestCancelled: () => unknown;
  onCircleRequestUpdated: () => unknown;
};

export const ConfirmedCustomerActivityCircleRequest: React.FC<
  ConfirmedCustomerActivityCircleRequestProps
> = ({ circleRequest, onCircleRequestCancelled, onCircleRequestUpdated }) => {
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [newDate, setNewDate] = useState<Date>();
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(circleRequest.recurring);
  const isRide =
    circleRequest.RequestCategory?.id === CircleRequestCategoriesEnum.Ride;
  const history = useHistory();
  const updateCircleRequestTimeErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmedCustomerActivityCircleRequest][UpdateCircleRequestTime]',
  });
  const cancelCircleRequestErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmedCustomerActivityCircleRequest][CancelCircleRequest]',
  });
  const [cancelCircleRequest, { loading: isCancelling }] =
    useUserCancelCircleRequestMutation({
      onError: cancelCircleRequestErrorHandler,
      onCompleted: onCircleRequestCancelled,
    });
  const { directChatMessage } = useDirectChatMessage();
  const [updateCircleRequestTime, { loading: isUpdatingTime }] =
    useUpdateCircleRequestTimeMutation({
      onError: updateCircleRequestTimeErrorHandler,
      onCompleted: onCircleRequestUpdated,
    });

  const loading = isCancelling || isUpdatingTime;

  const handleSubmit = () => {
    if (!newDate) return;
    void updateCircleRequestTime({
      variables: {
        circleRequestId: circleRequest.id,
        newTime: newDate,
      },
    });
  };

  const handleCancel = () => {
    setShowCancelAlert(true);
  };

  const handleCancelDismiss = () => {
    setShowCancelAlert(false);
  };

  const handleCancelConfirmation = (cancellationReason: string) => {
    setShowCancelAlert(false);
    void cancelCircleRequest({
      variables: {
        circleRequestId: circleRequest.id,
        cancellationReason,
      },
    });
  };

  return (
    <>
      <PageWithBottomSheetLayout
        title={
          isRecurringEvent ? (
            <RecurringEventPageTitle
              title={
                <RequestCategoryName
                  category={circleRequest.RequestCategory?.name}
                />
              }
            />
          ) : (
            <RequestCategoryName
              category={circleRequest.RequestCategory?.name}
            />
          )
        }
        background="var(--colors-gradient-green)"
        bottomSheetBackground="var(--colors-gradient-green-sheet)"
        bottomSheetTitle={
          <>
            <IconActivityConfirmed />
            {t('ConfirmedCustomerActivityCircleRequest.SheetTitle')}
          </>
        }
        bottomSheetSubtitle={
          isRecurringEvent
            ? t('ConfirmedCustomerActivityCircleRequest.SheetSubtitle')
            : undefined
        }
        startSlotHeaderAction={
          <HeaderBackButton onClick={() => history.goBack()} />
        }
      >
        <IonGrid>
          <IonRow className="ion-margin-vertical">
            <BodyText>
              {t('ConfirmedCustomerActivityCircleRequest.SheetBody')}
            </BodyText>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            <CardList>
              <ActivityCardItemRequestConnectAcceptedBy
                acceptedBy={circleRequest.AcceptedBy}
                onConnect={() => {
                  if (circleRequest.AcceptedBy?.id) {
                    return directChatMessage(circleRequest.AcceptedBy?.id);
                  }

                  return Promise.resolve();
                }}
              />
              {isRide ? (
                <ActivityCardItemRideInformation ride={circleRequest.ride} />
              ) : (
                <ActivityCardItemAddress address={circleRequest.Address} />
              )}
              <ActivityCardItemRequestScheduledAt
                scheduledAt={circleRequest.scheduledAt}
                onDateSelected={(date) => {
                  setNewDate(date);
                }}
              />
              <ActivityCardItemRequestDescription
                description={circleRequest.description}
              />
              <ActivityCardItemRequestTimeline
                timeline={circleRequest.Timeline}
              />
            </CardList>
          </IonRow>
          <IonRow className="ion-margin-vertical">
            {newDate ? (
              <Button
                color="primary-green"
                onClick={handleSubmit}
                loading={loading}
              >
                {t('ConfirmedCustomerActivityCircleRequest.PrimaryCta')}
              </Button>
            ) : (
              <Button
                color="secondary-green"
                onClick={handleCancel}
                loading={loading}
              >
                {t('ConfirmedCustomerActivityCircleRequest.SecondaryCta')}
              </Button>
            )}
          </IonRow>
        </IonGrid>
      </PageWithBottomSheetLayout>
      <AlertWithInput
        isOpen={showCancelAlert}
        title={t(
          'ConfirmedCustomerActivityCircleRequest.CancelRequestAlertTitle'
        )}
        subtitle={t(
          'ConfirmedCustomerActivityCircleRequest.CancelRequestAlertSubtitle'
        )}
        firstButtonLabel={t(
          'ConfirmedCustomerActivityCircleRequest.CancelRequestAlertFirstButtonLabel'
        )}
        secondButtonLabel={t(
          'ConfirmedCustomerActivityCircleRequest.CancelRequestAlertSecondButtonLabel'
        )}
        onSubmit={handleCancelConfirmation}
        onCancel={handleCancelDismiss}
        placeholder={t(
          'ConfirmedCustomerActivityCircleRequest.CancelRequestAlertPlaceholder'
        )}
      />
    </>
  );
};
