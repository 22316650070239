/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IonInput, IonLabel, IonText } from '@ionic/react';
import React, { ReactNode } from 'react';
import { Container, InputContainer } from './FormInput.styles';
import { HomeIcon } from '../../assets/images/HomeIcon';
import { LocationOutline } from '../../assets/images/LocationOutline';
import { UseFormRegisterReturn } from 'react-hook-form';

export type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local';

interface IFormInput {
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  errors?: any;
  icon?: 'location' | 'home';
  value?: string;
  type?: TextFieldTypes;
  register?: UseFormRegisterReturn<string>;
  closeLabels?: boolean;
}

const FormInput: React.FC<IFormInput> = ({
  label,
  placeholder,
  icon,
  value,
  type,
  register,
  errors = {},
  closeLabels,
  name,
}) => {
  let onBlur, onChange;
  if (register) {
    onBlur = register.onBlur;
    onChange = register.onChange;
  }

  return (
    <Container closeLabels={closeLabels}>
      {typeof label === 'string' ? <IonLabel>{label}</IonLabel> : label}
      <InputContainer
        className="input-container"
        style={
          name &&
          errors[name] && {
            borderColor: '#ea4255',
          }
        }
      >
        {icon === 'home' && <HomeIcon style={{ marginRight: '8px' }} />}
        {icon === 'location' && (
          <LocationOutline style={{ marginRight: '8px' }} />
        )}
        {value ? ( // NOTE: workaround because if we send value={undefined} the input is reset after submit for some reason
          <IonInput
            onIonChange={onChange}
            onIonBlur={onBlur}
            placeholder={placeholder}
            value={value}
            {...register}
            type={type || 'text'}
          />
        ) : (
          <IonInput
            placeholder={placeholder}
            onIonChange={onChange}
            onIonBlur={onBlur}
            type={type || 'text'}
            {...register}
          />
        )}
      </InputContainer>
      {name && errors[name] && (
        <IonText color="primary-red">{errors[name].message as string}</IonText>
      )}
    </Container>
  );
};

export default FormInput;
