import { IonTextarea } from '@ionic/react';
import styled from 'styled-components';

export const Textarea = styled(IonTextarea)`
  border: solid 1px #e0e0e0;
  border-radius: 14px;
  padding: 16px;

  textarea {
    padding: 0 !important;
  }
`;
