import {
  GraphqlServiceRequestPayment,
  useUpdateServiceRequestPaymentMutation,
  useUpdateStripeCustomerDefaultCardMutation,
} from '../../graphql/generated';
import { GET_SERVICE_REQUEST } from '../../graphql/service';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../apollo/errors';

export function useUpdateCardMutations() {
  const [
    updateStripeCustomerDefaultCard,
    { loading: stripeCustomerDefaultCardLoading },
  ] = useUpdateStripeCustomerDefaultCardMutation();
  const [
    updateServiceRequestPaymentMutation,
    { loading: updateServiceRequestPaymentLoading },
  ] = useUpdateServiceRequestPaymentMutation({
    refetchQueries: [GET_SERVICE_REQUEST],
    awaitRefetchQueries: true,
  });

  const handleCardSelect = async (
    card: string,
    serviceRequestPayment: GraphqlServiceRequestPayment | null | undefined
  ) => {
    try {
      await updateStripeCustomerDefaultCard({
        variables: {
          paymentMethodId: card,
        },
      });
    } catch (err) {
      logger.error({
        tag: '[UPDATE_STRIPE_CUSTOMER_DEFAULT_CARD]',
        message: `Fail trying to update stripe customer default card: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
    }
    if (serviceRequestPayment) {
      try {
        await updateServiceRequestPaymentMutation({
          variables: {
            serviceRequestPaymentId: serviceRequestPayment?.id,
            servicePaymentInput: {
              stripePaymentMethodId: card,
            },
          },
        });
      } catch (err) {
        logger.error({
          tag: '[UPDATE_SERVICE_REQUEST_PAYMENT]',
          message: `Fail trying to update service request payment: ${getApolloErrorMessage(
            err
          )}`,
          error: err as Error,
        });
      }
    }
  };

  return {
    handleCardSelect,
    stripeCustomerDefaultCardLoading,
    updateServiceRequestPaymentLoading,
  };
}
