import React from 'react';
import {
  RequestServiceDescription,
  StyledIonCard,
  StyledIonCardHeader,
  StyledIonCardSubtitle,
  StyledIonCardTitle,
} from './ActivityCard.styles';
import { IonSkeletonText } from '@ionic/react';
import { CSSProperties } from 'styled-components';

interface ActivityCardSkeletonProps {
  textStyles?: CSSProperties;
}

const ActivityCardSkeleton: React.FC<ActivityCardSkeletonProps> = ({
  textStyles,
}) => {
  return (
    <>
      <IonSkeletonText
        className="card-status"
        animated
        style={textStyles || { width: '50px', left: '16px' }}
      />
      <StyledIonCard status="loading" style={{ padding: '16px 0' }}>
        <StyledIonCardHeader>
          <StyledIonCardTitle>
            <div className="title-container">
              <IonSkeletonText animated style={{ width: '40%' }} />{' '}
              <IonSkeletonText animated style={{ width: '20px' }} />
            </div>
            <RequestServiceDescription>
              <IonSkeletonText animated style={{ width: '70%' }} />
            </RequestServiceDescription>
          </StyledIonCardTitle>
          <StyledIonCardSubtitle status="loading">
            <div className="request-type-container">
              <IonSkeletonText animated style={{ width: '25px' }} />
            </div>
          </StyledIonCardSubtitle>
        </StyledIonCardHeader>
      </StyledIonCard>
    </>
  );
};

export default ActivityCardSkeleton;
