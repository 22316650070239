function RatingCompleted() {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none">
      <g clipPath="url(#clip0_3921_46438)">
        <path
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"
          fill="#F2D382"
        />
      </g>
      <defs>
        <clipPath id="clip0_3921_46438">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default RatingCompleted;
