/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  IonIcon,
  IonButton,
  IonSelectOption,
  IonAlert,
  useIonLoading,
  IonContent,
  IonFooter,
  IonCard,
  IonItem,
  IonHeader,
  IonModal,
  IonToast,
} from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  CustomIonSelect,
  InputsContainer,
  RowFormFields,
  SearchButtonLabel,
  SelectContainer,
  TagButton,
  CustomIonToggle,
  LocationsHeader,
  PageTitle,
  StyledIonLabel,
} from './address-form.style';
import { chevronBack, homeOutline } from 'ionicons/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
  useCreateAddressMutation,
  useUpdateAddressMutation,
} from '../../graphql/generated';
import { ErrorOption, useForm } from 'react-hook-form';
import CustomInput, {
  InputProps,
} from '../../components/CustomInput/CustomInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from '../../providers/authProvider';
import { states } from '../../utils/states';
import { GET_ADDRESS } from '../../graphql/users';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import { IUserData } from '../../types';
import { useAppContext } from '../../providers/appContextProvider';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import AnimateMotionDiv from '../../components/AnimateMotionDiv/AnimateMotionDiv';
import { logger } from '../../logger';
interface ChildComponentProps {
  addressLine1?: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  addressId?: number;
  isDefault?: boolean;
  place?: string;
  sendNewAddressToParentForm?: (address: AddressesFormProps) => void;
  dismissModals?: (bool: boolean) => void;
  isOpen?: boolean;
}

export interface AddressesFormProps {
  id?: number;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  customerId?: number;
  isDefault?: boolean;
  place?: string;
}

const postSchema = yup.object().shape({
  addressLine1: yup.string().required('Address is required'),
  addressLine2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  zipCode: yup.string().required('Zipcode is required'),
  place: yup.string(),
  // tag: yup.mixed().oneOf(tags) // we might need this one later on.
});

const AddressForm: React.FC<ChildComponentProps> = ({
  isDefault,
  addressId,
  addressLine1,
  addressLine2,
  place,
  city,
  state,
  zipCode,
  sendNewAddressToParentForm,
  isOpen,
  dismissModals,
}) => {
  const i18n = useTranslation();
  const { width, height } = useWindowDimensions();
  const { user } = useContext(AuthContext);
  const [localState, setLocalState] = useState<string>('');
  const [, setFocusedInput] = useState<string>('');
  const [isAlertOpen, setAlertIsOpen] = useState<boolean>(false);
  const [isKeyboardOpen] = useState<boolean>(false);
  const [localDefaultHome, setLocalDefaultHome] = useState<boolean>(false);
  const [modalHomeOpen, setModalHomeOpen] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);

  const [present] = useIonLoading();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [
    createAddressMutation,
    { loading: createAddressLoading, data: createData },
  ] =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    useCreateAddressMutation();

  const [
    updateAddressMutation,
    { loading: updateAddressLoading, data: editData },
  ] = useUpdateAddressMutation();
  const { control, setFocus } = useForm({});
  const isAddMode = !addressId;
  const { globalAlert } = useAppContext();

  const formFields: InputProps[] = [
    ...(place
      ? [
          {
            name: 'place',
            label: i18n.t('place') || '',
            placeholder: 'eg, Walmart',
          },
        ]
      : []),
    {
      name: 'addressLine1',
      label: i18n.t('address') || '',
      placeholder: 'eg, 875 Howard Street, San Francisco',
    },
    {
      name: 'addressLine2',
      label: i18n.t('appSuite') || '',
      placeholder: 'eg, Apartment 201',
    },
    {
      name: 'city',
      label: i18n.t('city') || '',
      placeholder: 'San Francisco',
    },
  ];

  const rowFormFields: InputProps[] = [
    {
      name: 'zipCode',
      label: i18n.t('zipCode') || '',
      placeholder: '94109',
    },
  ];

  const {
    formState: { errors },
    register,
    getValues,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      addressLine1: addressLine1 ?? '',
      addressLine2: addressLine2 ?? '',
      city: city ?? '',
      state: state ?? '',
      zipCode: zipCode ?? '',
      place: place ?? '',
      country: 'US',
    },
    mode: 'onBlur',
    resolver: yupResolver(postSchema),
  });

  const handleModalDismiss = useCallback(() => {
    if (dismissModals) {
      dismissModals(false);
    }
  }, [dismissModals]);

  const onEditAddress = async () => {
    if (user !== null) {
      try {
        const { customerId } = user;
        await updateAddressMutation({
          variables: {
            updateAddressInput: {
              addressLine1: getValues().addressLine1,
              addressLine2: getValues().addressLine2,
              city: getValues().city,
              state: getValues().state,
              zipCode: getValues().zipCode,
              place: getValues().place,
              isDefault: isDefault ? isDefault : localDefaultHome,
            },
            updateAddressId: addressId ?? 0,
          },
          refetchQueries: [
            {
              query: GET_ADDRESS,
              variables: { customerId },
            },
          ],
          awaitRefetchQueries: true,
        });
      } catch (err) {
        logger.error({
          tag: '[UpdateAddress][updateAddressMutation]',
          message: `Fail while calling mutation to update address: ${getApolloErrorMessage(
            err
          )}`,
          error: err as Error,
        });
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(err) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
      }
    }
  };

  const onCraeteAddress = async (values: AddressesFormProps) => {
    if (sendNewAddressToParentForm) {
      sendNewAddressToParentForm(values);
    }
    try {
      if (user && user.customerId !== undefined) {
        const { customerId }: IUserData = user;
        await createAddressMutation({
          variables: { createAddressInput: { ...values, customerId } },
          refetchQueries: [
            {
              query: GET_ADDRESS,
              variables: { customerId },
            },
          ],
          awaitRefetchQueries: true,
        });
      }
    } catch (err) {
      logger.error({
        tag: '[CreateAddress][createAddressMutation]',
        message: `Fail while calling mutation to create address: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  const decideBackAction = useCallback(() => {
    if (
      (addressLine1 !== getValues().addressLine1 && addressLine1 !== null) ||
      (addressLine2 !== getValues().addressLine2 && addressLine2 !== null) ||
      (city !== getValues().city && city !== null) ||
      (state !== getValues().state && state !== null) ||
      (zipCode !== getValues().zipCode && zipCode !== null) ||
      (place !== getValues().place && place !== null)
    ) {
      setAlertIsOpen(true);
    } else {
      handleModalDismiss();
    }
  }, [getValues]);

  const onSubmit = () => {
    const values = getValues();

    if (localState.length > 0) {
      values.state = localState;
    }
    if (isAddMode) {
      void onCraeteAddress(values);
    } else {
      void onEditAddress();
    }
  };

  const customActionSheetOptions = {
    header: '',
    subHeader: i18n.t('selectState'),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const compareWith = (o1: any, o2: any) => {
    return o1 && o2 ? o1 === o2 : o1 === o2;
  };

  useEffect(() => {
    if (createAddressLoading || updateAddressLoading) {
      void present({
        message: i18n.t('loading') ?? 'Loading...',
        duration: 350,
      });
    }
    if (createData || editData) {
      setSuccessToast(true);
    }
    if (getValues('zipCode') === '') {
      setError('zipCode', i18n.t('zipcodeRequired') as ErrorOption);
    }
  }, [createAddressLoading, updateAddressLoading, createData, editData]);

  return (
    <IonModal
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpen}
      onDidDismiss={decideBackAction}
    >
      <IonHeader className="ion-padding">
        <LocationsHeader style={{ marginTop: 24 }}>
          <BackButton onClick={decideBackAction}>
            <IonIcon
              icon={chevronBack}
              color="primary"
              style={{ width: 22, height: 26 }}
            />
            <BackLabel>{i18n.t('back')}</BackLabel>
          </BackButton>
        </LocationsHeader>

        <AnimateMotionDiv>
          <PageTitle>
            {isAddMode ? i18n.t('confirmAddress') : i18n.t('editAddress')}
          </PageTitle>
        </AnimateMotionDiv>
      </IonHeader>
      <IonContent className="ion-padding">
        <form name="addressForm">
          <InputsContainer screenHeight={height} screenWidth={width}>
            {formFields.map((field, index) => {
              return (
                <AnimateMotionDiv key={index}>
                  <CustomInput
                    {...field}
                    control={control}
                    errors={errors}
                    register={register}
                    setFocus={setFocus}
                    setOnFocus={(val: string) => void setFocusedInput(val)}
                  />
                </AnimateMotionDiv>
              );
            })}
            <RowFormFields>
              <SelectContainer>
                <StyledIonLabel>{i18n.t('state')}</StyledIonLabel>
                <AnimateMotionDiv>
                  <div style={{ width: 100 }}>
                    <CustomIonSelect
                      interfaceOptions={customActionSheetOptions}
                      interface="action-sheet"
                      compareWith={compareWith}
                      placeholder={state}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                      onIonChange={(e) => setLocalState(e.detail.value)}
                      style={{
                        alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {states.map((field) => {
                        return (
                          <IonSelectOption
                            value={field.abbrev}
                            key={field.abbrev}
                            aria-selected={
                              state === field.abbrev ? true : false
                            }
                          >
                            {field.abbrev}
                          </IonSelectOption>
                        );
                      })}
                    </CustomIonSelect>
                  </div>
                </AnimateMotionDiv>
              </SelectContainer>
              {rowFormFields.map((field, index) => {
                return (
                  <AnimateMotionDiv key={index}>
                    <CustomInput
                      {...field}
                      control={control}
                      errors={errors}
                      register={register}
                      setFocus={setFocus}
                      setOnFocus={(val: string) => void setFocusedInput(val)}
                    />
                  </AnimateMotionDiv>
                );
              })}
            </RowFormFields>
            {isDefault && (
              <AnimateMotionDiv>
                <TagButton shape={'round'}>
                  <IonIcon slot="start" icon={homeOutline}></IonIcon>
                  <SearchButtonLabel>{i18n.t('home')}</SearchButtonLabel>
                </TagButton>
              </AnimateMotionDiv>
            )}
            {!isAddMode && !isDefault && (
              <AnimateMotionDiv>
                <IonCard>
                  <IonItem>
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    <SearchButtonLabel>{i18n.t('setAsHome')}</SearchButtonLabel>
                    <CustomIonToggle
                      checked={localDefaultHome}
                      slot="end"
                      onIonChange={() => setModalHomeOpen(true)}
                    ></CustomIonToggle>
                  </IonItem>
                </IonCard>
              </AnimateMotionDiv>
            )}
          </InputsContainer>
        </form>
      </IonContent>
      {!isKeyboardOpen && (
        <IonFooter
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
          }}
        >
          <AnimateMotionDiv>
            <IonButton
              style={{
                height: 56,
                borderRadius: 50,
                backgroundColor: 'rgba(35, 54, 75, 1)',
                width: width * 0.9,
              }}
              shape={'round'}
              type="submit"
              onClick={onSubmit}
              disabled={
                createAddressLoading ||
                updateAddressLoading ||
                successToast ||
                Object.keys(errors).length !== 0
              }
            >
              <SearchButtonLabel>{i18n.t('saveAddress')}</SearchButtonLabel>
            </IonButton>
          </AnimateMotionDiv>
        </IonFooter>
      )}
      <IonAlert
        isOpen={isAlertOpen}
        header={i18n.t('unsavedChanges') ?? ''}
        message={i18n.t('continueWithout') + i18n.t('editsMade') ?? ''}
        buttons={[
          {
            text: i18n.t('continue'),
            role: 'confirm',
            handler: () => {
              reset({
                addressLine1: addressLine1 ?? '',
                addressLine2: addressLine2 ?? '',
                city: city ?? '',
                state: state ?? '',
                zipCode: zipCode ?? '',
                place: place ?? '',
                country: 'US',
              });
              setAlertIsOpen(false);
              handleModalDismiss();
            },
          },
          {
            text: i18n.t('cancel'),
            role: 'cancel',
            handler: () => {
              setAlertIsOpen(false);
            },
          },
        ]}
        onDidDismiss={() => setAlertIsOpen(false)}
      ></IonAlert>
      <CustomAlert
        isOpen={modalHomeOpen}
        title={i18n.t('updateHomeAddress')}
        subtitle={i18n.t('thisAddressWillBe')}
        firstButtonLabel={i18n.t('updateHome')}
        secondButtonLabel={i18n.t('cancel')}
        firstButtonAction={() => {
          setLocalDefaultHome(true);
          setModalHomeOpen(false);
        }}
        secondButtonAction={() => setModalHomeOpen(false)}
      />
      <IonToast
        isOpen={successToast}
        message={i18n.t('addressAdded') ?? ''}
        onDidDismiss={() => {
          setSuccessToast(false);
          handleModalDismiss();
        }}
        duration={2000}
        position="top"
        color={'success'}
      ></IonToast>
    </IonModal>
  );
};

export default AddressForm;
