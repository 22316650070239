import { IonButton, IonModal } from '@ionic/react';
import styled from 'styled-components';

export type FontProps = {
  fontSize?: string;
  color?: string;
};

export type ButtonProps = {
  width?: number;
};

export const StyledIonModal = styled(IonModal)`
  --height: auto;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 460px;
`;

export const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 250px;
  width: '100%';
`;

export const TitleEnableLocation = styled.p<FontProps>`
  position: absolute;
  top: 153px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #000000;
  text-align: center;
  font-size: 18px;
`;

export const DescriptionEnableLocation = styled.p<FontProps>`
  position: absolute;
  left: 60px;
  bottom: 200px;
  text-align: center;
  width: 280px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #000000;
`;

export const AllowButton = styled(IonButton)<ButtonProps>`
  position: absolute;
  bottom: 105px;
  height: 58px;
  width: 327px;
  margin-bottom: 10px;
  font-weight: 600;
  --background-activated: #e59b62;
  --border-color: #ea8231;
  --border-radius: 50px;
`;

export const DontAllowButton = styled(IonButton)<ButtonProps>`
  position: absolute;
  bottom: 44px;
  height: 56px;
  width: 327px;
  font-weight: 600;
  --border-color: #ea8231;
  --background: #ffffff;
  --border-radius: 50px;
  --background-activated: #f5f0f0;
`;
