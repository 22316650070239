import React from 'react';
import { StyledIonHeader } from './HeaderWithBackground.styles';
import { IonButton, IonButtons, IonIcon, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';
import { chevronBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';

interface HeaderWithBackgroundProps {
  background: CSSProperties['background'];
}
const HeaderWithBackground: React.FC<HeaderWithBackgroundProps> = ({
  background,
}) => {
  const i18n = useTranslation();

  const router = useHistory();
  return (
    <StyledIonHeader background={background}>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton
            color="#ffffff"
            fill="clear"
            onClick={() => router.goBack()}
          >
            <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
            {i18n.t('back')}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </StyledIonHeader>
  );
};

export default HeaderWithBackground;
