import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Subtitle } from './ask-brya.styles';
import Card from '../../../components/Card/Card';
import { Messenger } from '../../../assets/images/Messenger';
import { Circle } from '../../../assets/images/Circle';
import { Service } from '../../../assets/images/Service';
import { Community } from '../../../assets/images/Community';
import { AuthContext } from '../../../providers/authProvider';
import { ROUTES } from '../../../constants/routes';
import { FeatureNames, RequestType } from '../../../constants/enums';
import { useTalkJsChat } from '../../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../../../components/FullScreenLoader/FullScreenLoader';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { ActivitiesNotSelected } from '../../../assets/images/ActivitiesNotSelected';
import { BulbOutline } from '../../../assets/images/BulbOutline';
import ModalSuggestActivity from '../../../components/ModalSuggestActivity/ModalSuggestActivity';
import { IonContent } from '@ionic/react';

const AskBrya: React.FC = () => {
  const i18n = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { user } = useContext(AuthContext);
  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();
  const [showSuggestActivityModal, setShowSuggestActivityModal] =
    useState(false);

  const cardItems = useMemo(() => {
    return [
      {
        id: 1,
        icon: <Messenger color={'#fff'} width={40} height={40} />,
        title: 'AskBrya',
        requestType: RequestType.Message,
        route: ROUTES.SEND_MESSAGE,
        linearGradient:
          'linear-gradient(to left, rgba(255, 155, 78, 1), rgba(234, 130, 49, 1), rgba(228, 115, 27, 1))',
        onClick: handleGetBryaTeamChat,
      },
      {
        id: 2,
        icon: (
          <BulbOutline pathProps={{ stroke: '#fff' }} width={40} height={40} />
        ),
        title: 'SuggestAnActivity',
        linearGradient:
          'linear-gradient(to left, rgba(177, 121, 245, 1), rgba(146, 84, 222, 1), rgba(122, 51, 209, 1)',
        onClick: () => setShowSuggestActivityModal(!showSuggestActivityModal),
      },
      {
        id: 3,
        icon: <ActivitiesNotSelected color={'#fff'} width={40} height={40} />,
        title: 'AttendAnActivity',
        stateProps: { tab: 'open' },
        route: ROUTES.TABS.ACTIVITIES,
        linearGradient:
          'linear-gradient(to left, rgba(19, 196, 202, 1), rgba(8, 151, 156, 1), rgba(1, 124, 129, 1)',
      },
      {
        id: 4,
        icon: <Circle color={'#fff'} width={40} height={40} />,
        title: 'AskYourCircle',
        requestType: RequestType.Circle,
        route: ROUTES.MEMBERSHIP.WHO_IS_THIS,
        linearGradient:
          'linear-gradient(to left, rgba(177, 121, 245, 1), rgba(146, 84, 222, 1), rgba(122, 51, 209, 1)',
        hide: !isFeatureEnabled(FeatureNames.CircleRequest),
      },
      {
        id: 5,
        icon: <Service color={'#fff'} width={40} height={40} />,
        title: 'HireAService',
        route: ROUTES.MEMBERSHIP.WHO_IS_THIS,
        requestType: RequestType.Service,
        linearGradient:
          'linear-gradient(to left, rgba(19, 196, 202, 1), rgba(8, 151, 156, 1), rgba(1, 124, 129, 1)',
        hide: !isFeatureEnabled(FeatureNames.ServiceRequest),
      },
      {
        id: 6,
        icon: <Community color={'#fff'} width={40} height={40} />,
        title: 'ConnectWithCommunity',
        route: ROUTES.CHAT,
        linearGradient:
          'linear-gradient(to left, rgba(31, 139, 255, 1), rgba(9, 109, 217, 1), rgba(2, 89, 183, 1)',
      },
    ];
  }, [handleGetBryaTeamChat, isFeatureEnabled, showSuggestActivityModal]);

  return (
    <>
      {getBryaTeamChatQueryLoading && <FullScreenLoader />}
      <IonContent className="ion-padding-bottom">
        <Title>
          {i18n.t('hi')} {user?.firstName}
        </Title>
        <Subtitle>{i18n.t('HowCanWeHelp')}</Subtitle>
        {cardItems
          .filter((item) => !item.hide)
          .map((item) => (
            <Card item={item} key={item.id} />
          ))}
        <ModalSuggestActivity
          isOpen={showSuggestActivityModal}
          onClose={() => setShowSuggestActivityModal(false)}
        />
      </IonContent>
    </>
  );
};
export default AskBrya;
