import * as React from 'react';

export const TimeClock = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="3" y="3" width="20" height="20" rx="10" fill="white" />
    <path
      d="M2.86731 13.001C2.86731 7.40508 7.40418 2.86821 13.0001 2.86821C18.5961 2.86821 23.1329 7.40508 23.1329 13.001C23.1329 18.597 18.5961 23.1338 13.0001 23.1338C7.40418 23.1338 2.86731 18.597 2.86731 13.001ZM13.0001 14.587H17.6876C17.901 14.587 18.1057 14.5022 18.2566 14.3513C18.4075 14.2004 18.4923 13.9957 18.4923 13.7823C18.4923 13.5689 18.4075 13.3642 18.2566 13.2133C18.1057 13.0624 17.901 12.9776 17.6876 12.9776H13.8048V6.75102C13.8048 6.53761 13.72 6.33293 13.5691 6.18202C13.4182 6.03111 13.2135 5.94633 13.0001 5.94633C12.7867 5.94633 12.582 6.03111 12.4311 6.18202C12.2802 6.33293 12.1954 6.53761 12.1954 6.75102V13.7823C12.1954 13.9957 12.2802 14.2004 12.4311 14.3513C12.582 14.5022 12.7867 14.587 13.0001 14.587Z"
      fill="#9254DE"
      stroke="white"
      strokeWidth="0.046875"
    />
  </svg>
);
