import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export type InputProps = {
  closeLabels?: boolean;
};

export const Container = styled.div<InputProps>`
  margin-top: ${(props) => (props.closeLabels ? 4 : 16)}px;
  margin-bottom: var(--ion-margin, 16px);
  ion-label {
    font-weight: 500;
    font-size: ${pixelToRem(14)};
    line-height: 20px;
    color: var(--body-text-900);
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 16px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 14px;
  .native-input {
    display: flex;
    align-items: center;
  }
`;
