import React, { useCallback } from 'react';
import {
  ActivityLine,
  CardContainer,
  TabContent,
  TimeLineContainer,
  Upcoming,
} from '../ActivitiesTab.style';
import ActivityCard from '../../ActivityCard/ActivityCard';
import ActivityEmptyStates from '../../ActivityEmptyStates/ActivityEmptyStates';
import { IActivitiesGroupedByDate, IActivityCard } from '../../../types';
import ActivityTimeLine from '../../ActivityTimeLine/ActivityTimeLine';
import { t } from 'i18next';
import dayjs from 'dayjs';

const ScheduledTab: React.FC<{ data: IActivitiesGroupedByDate[] }> = ({
  data,
}) => {
  const handlePastAndFutureDates = useCallback(
    (
      type: 'past' | 'upcoming',
      activitiesDataList: IActivitiesGroupedByDate[]
    ) =>
      activitiesDataList
        ?.map((item) => {
          const firstActivity = item.activities && item.activities[0];
          if (
            type === 'past' &&
            item.activities &&
            firstActivity?.scheduledAt &&
            dayjs(firstActivity?.scheduledAt).isBefore(dayjs())
          ) {
            return {
              id: item.id,
              timeline: item.timeline,
              activities: item.activities?.map((element) => {
                if (
                  item.activities &&
                  firstActivity.scheduledAt &&
                  dayjs(firstActivity?.scheduledAt).isBefore(dayjs())
                ) {
                  return element;
                }
              }),
            };
          }

          if (
            type === 'upcoming' &&
            item.activities &&
            firstActivity?.scheduledAt &&
            dayjs(firstActivity?.scheduledAt).isAfter(dayjs())
          ) {
            return {
              id: item.id,
              timeline: item.timeline,
              activities: item.activities?.map((element) => {
                if (
                  item.activities &&
                  firstActivity.scheduledAt &&
                  dayjs(firstActivity?.scheduledAt).isAfter(dayjs())
                ) {
                  return element;
                }
              }),
            };
          }
          return {
            id: null,
            timeline: null,
            activities: [],
          };
        })
        .filter((item) => item.id),
    []
  );
  return (
    <>
      {data?.length === 0 && <ActivityEmptyStates tab="scheduled" />}
      {handlePastAndFutureDates('past', data)?.map((activity) => (
        <TabContent
          style={{ opacity: 0.7 }}
          key={activity?.id as unknown as number}
          hasTimeLine
        >
          <TimeLineContainer>
            {activity?.timeline && (
              <ActivityTimeLine data={activity?.timeline} />
            )}
            <ActivityLine />
          </TimeLineContainer>
          <CardContainer>
            {activity?.activities?.map(
              (item) =>
                item?.id && (
                  <ActivityCard
                    key={item?.id}
                    data={item as unknown as IActivityCard}
                  />
                )
            )}
          </CardContainer>
        </TabContent>
      ))}
      {handlePastAndFutureDates('past', data)?.length > 0 &&
        handlePastAndFutureDates('upcoming', data)?.length > 0 && (
          <Upcoming>
            <div />
            <h3>{t('Upcoming')}</h3>
            <div />
          </Upcoming>
        )}
      {handlePastAndFutureDates('upcoming', data)?.map((activity) => (
        <TabContent hasTimeLine key={activity?.id as unknown as number}>
          <TimeLineContainer>
            {activity?.timeline && (
              <ActivityTimeLine data={activity?.timeline} />
            )}
            <ActivityLine />
          </TimeLineContainer>
          <CardContainer>
            {activity?.activities?.map(
              (item) =>
                item?.id && (
                  <ActivityCard
                    key={item?.id}
                    data={item as unknown as IActivityCard}
                  />
                )
            )}
          </CardContainer>
        </TabContent>
      ))}
    </>
  );
};

export default ScheduledTab;
