/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IonIcon, IonPage, IonToolbar, IonHeader } from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  BackButton,
  BackLabel,
  AccountHeader,
  ContinueButton,
  PageTitleStep2,
  StyledIonContent,
  SkipButton,
  StyledIonTextArea,
  PageSubTitle,
} from './welcome.style';
import { Variants, motion } from 'framer-motion';
import { chevronBack } from 'ionicons/icons';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AuthContext } from '../../providers/authProvider';
import { WelcomeFlowImage } from '../../assets/images/WelcomeFlowImage';

const parentVariant: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const childrenVariant: Variants = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
};

const WelcomeStep3: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const [, setTextAreaValue] = useState<string>('');
  const { user } = useContext(AuthContext);

  const { handleSubmit } = useForm();

  const goBack = useCallback(() => {
    router.goBack();
  }, []);

  const onSkip = useCallback(() => {
    router.goBack();
  }, []);

  // TO BE finished when we have BE query
  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (formData): Promise<void> => {
      // eslint-disable-next-line no-console
      console.log('formData === ', formData);
    },
    []
  ) as SubmitHandler<FieldValues>;

  return (
    <IonPage>
      <IonHeader className="ion-no-border" style={{ height: 100 }}>
        <IonToolbar className="ion-padding" color={'#fff'}>
          <AccountHeader style={{ marginTop: 24 }}>
            <BackButton onClick={goBack}>
              <IonIcon
                icon={chevronBack}
                color="primary"
                style={{ width: 22, height: 26 }}
              />
              <BackLabel>{t('back')}</BackLabel>
            </BackButton>
            <BackButton onClick={onSubmit}>
              <BackLabel>{t('continue')}</BackLabel>
            </BackButton>
          </AccountHeader>
        </IonToolbar>
      </IonHeader>
      <StyledIonContent>
        <motion.div
          initial="initial"
          animate="animate"
          variants={parentVariant}
        >
          <motion.div variants={childrenVariant}>
            <div
              style={{ marginTop: 12, marginBottom: 24, textAlign: 'center' }}
            >
              <WelcomeFlowImage />
            </div>
          </motion.div>
          <motion.div variants={childrenVariant}>
            <PageTitleStep2>
              <Trans
                i18nKey="welcome.titleStep3"
                values={{
                  userName: user?.firstName,
                }}
              />
            </PageTitleStep2>
          </motion.div>
          <motion.div variants={childrenVariant} style={{ marginTop: 24 }}>
            <PageSubTitle>{t('welcome.subtitleStep3')}</PageSubTitle>
          </motion.div>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 24 }}>
            <StyledIonTextArea
              fill="outline"
              placeholder={t('comments') ?? ''}
              className="custom-padding-textarea"
              onIonChange={(e) => {
                setTextAreaValue(e.target.value ?? '');
              }}
            ></StyledIonTextArea>
            <ContinueButton type="submit">
              <>{t('continue')}</>
            </ContinueButton>
            <SkipButton
              fill={'outline'}
              onClick={() => {
                onSkip();
              }}
            >
              <>{t('welcome.skipForNow')}</>
            </SkipButton>
          </form>
        </motion.div>
      </StyledIonContent>
    </IonPage>
  );
};

export default WelcomeStep3;
