import React, { useCallback, useMemo, useRef } from 'react';
import * as yup from 'yup';
import {
  BackButton,
  Description,
  MainContainer,
  StyledIonModal,
  Title,
  BackLabel,
  ModalHeader,
  InputContainer,
  SummaryLeft,
  SummaryRight,
  RowContainer,
  Divider,
  Wrapper,
  BottomButton,
} from './ModalEnterCost.style';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { t } from 'i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import { DollarIconNoCircle } from '../../assets/images/DollarIconNoCircle';
import {
  GraphqlAppointmentRequestPayment,
  ServicePaymentType,
} from '../../graphql/generated';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatCurrency } from '../../utils/formatCurrency';

export type AppointmentPaymentFormFields = Pick<
  GraphqlAppointmentRequestPayment,
  'amount' | 'type'
>;

interface ModalEnterCostI {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<AppointmentPaymentFormFields>;
  initialState?: AppointmentPaymentFormFields;
  serviceFee?: number | null;
}

const ModalEnterCost: React.FC<ModalEnterCostI> = ({
  isOpen,
  onClose,
  onSubmit,
  initialState,
  serviceFee,
}) => {
  const i18n = useTranslation();
  const { height } = useWindowDimensions();
  const modal = useRef<HTMLIonModalElement>(null);

  const PAYMENT_TYPES_LABELS: Record<ServicePaymentType, string> = useMemo(
    () => ({
      [ServicePaymentType.Hour]: i18n.t('paymentSetupModal.hourly'),
      [ServicePaymentType.Fixed]: i18n.t('paymentSetupModal.setPrice'),
      [ServicePaymentType.Visit]: i18n.t('paymentSetupModal.visitPrice'),
    }),
    []
  );

  const PAYMENT_TYPE_DESCRIPTIONS: Record<ServicePaymentType, string> = useMemo(
    () => ({
      [ServicePaymentType.Hour]: i18n.t('paymentSetupModal.infoHourly'),
      [ServicePaymentType.Fixed]: i18n.t('paymentSetupModal.infoSetPrice'),
      [ServicePaymentType.Visit]: i18n.t('paymentSetupModal.visitPriceInfo'),
    }),
    []
  );

  const PAYMENT_SUMMARY_LABELS: Record<ServicePaymentType, string> = useMemo(
    () => ({
      [ServicePaymentType.Hour]: i18n.t('paymentSetupModal.customerHourly'),
      [ServicePaymentType.Fixed]: i18n.t('paymentSetupModal.customerPrice'),
      [ServicePaymentType.Visit]: i18n.t(
        'paymentSetupModal.customerVisitPrice'
      ),
    }),
    []
  );

  const PAYMENT_TYPES_SELECT_OPTIONS = useMemo(
    () => [
      {
        label: PAYMENT_TYPES_LABELS[ServicePaymentType.Hour],
        value: ServicePaymentType.Hour,
      },
      {
        label: PAYMENT_TYPES_LABELS[ServicePaymentType.Fixed],
        value: ServicePaymentType.Fixed,
      },
      {
        label: PAYMENT_TYPES_LABELS[ServicePaymentType.Visit],
        value: ServicePaymentType.Visit,
      },
    ],
    []
  );

  const formSchema = useMemo(
    () =>
      yup.object().shape({
        type: yup
          .string()
          .required(i18n.t('forms.appointmentPayment.type.errorMessage')),
        amount: yup
          .number()
          .moreThan(0, i18n.t('forms.appointmentPayment.amount.errorMessage'))
          .required(i18n.t('forms.appointmentPayment.amount.errorMessage'))
          .typeError(i18n.t('forms.appointmentPayment.amount.errorMessage')),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
  } = useForm<AppointmentPaymentFormFields>({
    resolver: yupResolver(formSchema),
    defaultValues: initialState,
  });

  const [category, rate] = watch(['type', 'amount']);

  const handleModalDismiss = useCallback(() => {
    onClose();
  }, []);

  return (
    <StyledIonModal
      ref={modal}
      initialBreakpoint={0.9}
      breakpoints={[0, 0.9, 1]}
      isOpen={isOpen}
      onDidDismiss={handleModalDismiss}
    >
      <IonHeader className="ion-padding">
        <ModalHeader>
          <BackButton onClick={onClose}>
            <BackLabel>{t('back')}</BackLabel>
          </BackButton>
          <Title>
            {category
              ? PAYMENT_TYPES_LABELS[category]
              : i18n.t('paymentSetupModal.paymentTypes')}
          </Title>
          <BackButton style={{ width: 60 }} />
        </ModalHeader>
      </IonHeader>
      <IonContent>
        <MainContainer height={height * 0.9}>
          <Description>{i18n.t('paymentSetupModal.subtitle')}</Description>
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <Wrapper hasError={errors.type && true}>
                <AnimateMotionDiv>
                  <IonLabel>
                    {i18n.t('paymentSetupModal.paymentTypes') ?? ''}
                  </IonLabel>
                  <IonSelect
                    {...register('type')}
                    labelPlacement="floating"
                    interface="popover"
                    shape="round"
                    value={category}
                    placeholder={category as string}
                    onIonBlur={() => clearErrors('type')}
                    onIonChange={({ detail }) => {
                      setValue('type', detail.value as ServicePaymentType);
                    }}
                  >
                    {PAYMENT_TYPES_SELECT_OPTIONS.map((paymentType) => (
                      <IonSelectOption
                        key={paymentType.value}
                        value={paymentType.value}
                      >
                        {paymentType.label}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  {errors.type && (
                    <p className="form-error">
                      {errors.type?.message as string}
                    </p>
                  )}
                </AnimateMotionDiv>
              </Wrapper>
              {category && (
                <Wrapper hasError={Boolean(errors.amount)}>
                  <AnimateMotionDiv>
                    <Description>
                      {PAYMENT_TYPE_DESCRIPTIONS[category]}
                    </Description>
                  </AnimateMotionDiv>
                  <AnimateMotionDiv>
                    <IonLabel>
                      {i18n.t('paymentSetupModal.rate') ?? ''}
                    </IonLabel>
                    <InputContainer style={{ marginTop: '8px' }}>
                      <DollarIconNoCircle
                        width={24}
                        height={24}
                        fill="#212121"
                      />
                      <IonInput
                        type="number"
                        step="0.01"
                        {...register('amount')}
                        onIonBlur={() => clearErrors('amount')}
                        onIonInput={(e) => {
                          setValue('amount', Number(e.target.value));
                        }}
                      ></IonInput>
                    </InputContainer>
                    {errors.amount && (
                      <p className="form-error">
                        {errors.amount?.message as string}
                      </p>
                    )}
                  </AnimateMotionDiv>
                </Wrapper>
              )}
              {category && (
                <>
                  <AnimateMotionDiv>
                    <div style={{ marginBottom: 16 }}>
                      <RowContainer>
                        <SummaryLeft>
                          {i18n.t('paymentSetupModal.bryaSafetyFee') ?? ''}
                        </SummaryLeft>
                        <SummaryRight>
                          {rate && serviceFee
                            ? formatCurrency(rate / serviceFee)
                            : null}
                        </SummaryRight>
                      </RowContainer>
                      <Divider />
                      <RowContainer>
                        <SummaryLeft>
                          {PAYMENT_SUMMARY_LABELS[category]}
                        </SummaryLeft>
                        <SummaryRight>
                          {rate && serviceFee
                            ? formatCurrency(rate + rate / serviceFee)
                            : null}
                        </SummaryRight>
                      </RowContainer>
                    </div>
                  </AnimateMotionDiv>
                  <BottomButton slot="primary" type="submit">
                    <>{i18n.t('confirm')}</>
                  </BottomButton>
                </>
              )}
            </>
          </form>
        </MainContainer>
      </IonContent>
    </StyledIonModal>
  );
};

export default ModalEnterCost;
