import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { useCallback } from 'react';

export const usePushNotifications = () => {
  const isPushNotificationsPluginAvailable =
    Capacitor.isPluginAvailable('PushNotifications');

  const requestPushNotificationsPermissions = useCallback(async () => {
    if (!isPushNotificationsPluginAvailable) return false;
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      return false;
    }

    await PushNotifications.register();
    return true;
  }, [isPushNotificationsPluginAvailable]);

  return {
    isPushNotificationsPluginAvailable,
    requestPushNotificationsPermissions,
  };
};
