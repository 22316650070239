import { useContext, useEffect, useRef, useState } from 'react';
import { useGenerateAuthIdMutation } from '../../graphql/generated';
import useInAppBrowser from './useInAppBrowser';
import { AuthContext } from '../../providers/authProvider';
import { AppContext } from '../../providers/appContextProvider';
import { environments } from '../env';
import { getUserLandingRoute } from '../routes/getUserLandingRoute';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../apollo/errors';
import { useHistory } from 'react-router';
import { QUERY_PARAMS } from '../../constants/routes';

export const useLogin = () => {
  const router = useHistory();
  const { openEvent, closeBrowser, closeEvent } = useInAppBrowser();
  const [generateAuthIdMutation] = useGenerateAuthIdMutation();
  const [fetchingAuthId, setFetchingAuthId] = useState(false);
  const { login } = useContext(AuthContext);
  const { env } = useContext(AppContext);
  const intervalRef = useRef<null | NodeJS.Timeout>(null);

  const stopPollingResultIfNeeded = () => {
    if (!intervalRef.current) return;
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  useEffect(() => {
    stopPollingResultIfNeeded();
  }, [env]);

  const generateAuthId = async () => {
    try {
      const { data } = await generateAuthIdMutation();
      return {
        authId: data?.generateAuthId.authId || '',
        secureToken: data?.generateAuthId.secureToken || '',
      };
    } catch (err) {
      logger.error({
        tag: '[useLogin][generateAuthId]',
        message: `Error generating auth id ${getApolloErrorMessage(err)}`,
        error: err as Error,
      });
      return {
        authId: '',
        secureToken: '',
      };
    }
  };

  const getAccessToken = async (authId: string, secureToken: string) => {
    const signedInUser = await login(authId, secureToken);
    if (!signedInUser) return;
    stopPollingResultIfNeeded();
    const currentUrl = new URL(window.location.href);
    const redirect = currentUrl.searchParams.get(QUERY_PARAMS.REDIRECT);
    const landing = getUserLandingRoute(signedInUser);
    router.replace(landing);
    if (redirect) {
      router.push(redirect);
    }
    closeBrowser();
  };

  const startPollingResult = (authId: string, secureToken: string) => {
    intervalRef.current = setInterval(
      () => void getAccessToken(authId, secureToken),
      1000
    );
  };

  const sign = async (loginType: string) => {
    stopPollingResultIfNeeded();
    setFetchingAuthId(true);
    const { authId, secureToken } = await generateAuthId();
    setFetchingAuthId(false);
    openEvent(
      loginType == 'signin'
        ? `${environments[env].signInUrl}?authId=${authId}`
        : `${environments[env].signUpUrl}?authId=${authId}`
    );

    startPollingResult(authId, secureToken);
    closeEvent(stopPollingResultIfNeeded);
  };

  return { sign, fetchingAuthId };
};
