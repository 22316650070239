import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: Date; output: Date };
  /** JsonValue custom scalar type */
  JsonValue: { input: any; output: any };
  /** Plain date in the format YYYY-MM-DD */
  PlainDate: { input: Date; output: Date };
  /** Zoned Plain time in the format 15:30Z+00:00 */
  ZonedPlainTime: { input: Date; output: Date };
};

export type Activities =
  | GraphqlCircleRequest
  | GraphqlCoachVisit
  | GraphqlCustomerSocialEvent
  | GraphqlServiceRequest;

export type ActivitiesFilter = {
  /** Ids of customer to return activities */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by status */
  status?: InputMaybe<ActivitiesStatus>;
  /** Filter by type options */
  type?: InputMaybe<ActivitiesOptions>;
};

export type ActivitiesGroupedByDateFilter = {
  /** Ids of customer to return activities */
  customerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by status */
  status?: InputMaybe<ActivitiesGroupedByDateStatus>;
  /** Filter by type options */
  type?: InputMaybe<ActivitiesGroupedByDateOptions>;
};

export type ActivitiesGroupedByDateMeta = {
  __typename?: 'ActivitiesGroupedByDateMeta';
  /** The date information */
  isLastPage: Scalars['Boolean']['output'];
  lastValue?: Maybe<Scalars['DateTime']['output']>;
};

/** The different options for filtering when getting activities grouped by date */
export enum ActivitiesGroupedByDateOptions {
  ActionNeeded = 'actionNeeded',
  All = 'all',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ThisWeek = 'thisWeek',
  Today = 'today',
}

export type ActivitiesGroupedByDateOutput = {
  __typename?: 'ActivitiesGroupedByDateOutput';
  /** The list of circle, service requests and social events for specific customers in a specific date */
  activities?: Maybe<Array<Activities>>;
  id: Scalars['Int']['output'];
  /** The date information */
  timeline: TimelineOutput;
};

/** The different status to get activities grouped by date */
export enum ActivitiesGroupedByDateStatus {
  History = 'history',
  Scheduled = 'scheduled',
}

/** The different options for filtering when getting activities */
export enum ActivitiesOptions {
  ActionNeeded = 'actionNeeded',
  All = 'all',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Pending = 'pending',
  ThisWeek = 'thisWeek',
  Today = 'today',
}

/** The different status to get activities */
export enum ActivitiesStatus {
  History = 'history',
  Open = 'open',
  Scheduled = 'scheduled',
}

/** The fields for creating an admin user */
export type AdminCreateUserInput = {
  /** The user email */
  email: Scalars['String']['input'];
  /** The emergency contact */
  emergencyContact?: InputMaybe<UserEmergencyContactInput>;
  /** The user first name */
  firstName: Scalars['String']['input'];
  /** The user last name */
  lastName: Scalars['String']['input'];
  /** The user management role */
  managementRole?: InputMaybe<ManagementRole>;
};

/** The fields to be updated for an adming user */
export type AdminUpdateUserInput = {
  /** The user bio */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** Wheather the user is disabled or not */
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The emergency contact */
  emergencyContact?: InputMaybe<UserEmergencyContactInput>;
  /** The user first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  managementRole?: InputMaybe<ManagementRole>;
  /** The user phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum AgentPreferedContactMethodEnum {
  BryaApp = 'bryaApp',
  Email = 'email',
  Phone = 'phone',
  Sms = 'sms',
  Whatsapp = 'whatsapp',
}

export enum AgentRole {
  AdminAgent = 'adminAgent',
  Agent = 'agent',
}

/** The options to filter agents */
export type AgentsFilter = {
  /** Matches company ID */
  companyId?: InputMaybe<Scalars['Float']['input']>;
  /** Retrieve agents with the specified service types */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Matches company name, agent name, phone number or email */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The sorting configuration */
export type AgentsSort = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: AgentsSortableFields;
};

/** The possible fields that agents can be sorted */
export enum AgentsSortableFields {
  CompanyName = 'companyName',
  CompanyRating = 'companyRating',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  Id = 'id',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

/** The roles related to the mobile application */
export enum AppUser {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
}

export type AppointmentActivitiesFilter = {
  /** Filter by activities statuses */
  activityStatuses?: InputMaybe<Array<AppointmentActivitiesStatus>>;
  /** Filter by appointment statuses */
  appointmentStatuses?: InputMaybe<Array<AppointmentRequestStatus>>;
  /** Filter starting from date */
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by tab status */
  status?: InputMaybe<AppointmentRequestActivitiesStatus>;
  /** Filter ending until date */
  untilDate?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The different status that activities might have when getting them for circle members or agents */
export enum AppointmentActivitiesStatus {
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Confirmed = 'confirmed',
  Pending = 'pending',
}

/** The different status to get appointment requests activities */
export enum AppointmentRequestActivitiesStatus {
  Confirmed = 'confirmed',
  Requested = 'requested',
}

export type AppointmentRequestFilter = {
  /** Filter by agentId */
  agentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by category */
  category?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdBy */
  createdBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requires action */
  requiresAction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<AppointmentRequestStatus>>;
  /** Filters by agent, customer, user that requested the service or ID */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type AppointmentRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: AppointmentRequestsSortableFields;
};

/** The status of an appointment request */
export enum AppointmentRequestStatus {
  Accepted = 'accepted',
  Assigned = 'assigned',
  Closed = 'closed',
  Confirmed = 'confirmed',
  Declined = 'declined',
  NotChosen = 'notChosen',
  Open = 'open',
}

export enum AppointmentRequestsSortableFields {
  CircleRequestId = 'circleRequestId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  ScheduledAt = 'scheduledAt',
  ServiceRequestId = 'serviceRequestId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

/** The available options for a user Service Request approval */
export enum ApprovalServiceRequestStatus {
  Accept = 'accept',
  Decline = 'decline',
}

export type AutomaticPaymentMethods = {
  __typename?: 'AutomaticPaymentMethods';
  allow_redirects: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
};

/** The possible chat types that can be used to filtering conversations based on custom data */
export enum ChatTypeOptions {
  Community = 'community',
  Social = 'social',
}

export enum CircleRequestCategoriesEnum {
  Appointment = 'appointment',
  Errands = 'errands',
  Exercise = 'exercise',
  HouseCare = 'house_care',
  Meals = 'meals',
  Other = 'other',
  PetCare = 'pet_care',
  Ride = 'ride',
  Visit = 'visit',
}

export type CircleRequestFilter = {
  /** Filter by category */
  category?: InputMaybe<Array<CircleRequestCategoriesEnum>>;
  /** Filter by customerId */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<ServiceRequestStatus>>;
  /** Filters by customer, user that made or accepted the request or ID */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Filter by updatedAt after date */
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by updatedAt before date */
  updatedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CircleRequestInterpretResponse = {
  __typename?: 'CircleRequestInterpretResponse';
  category: Scalars['String']['output'];
  recurring: Scalars['Boolean']['output'];
};

/** The fields for sorting */
export type CircleRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CircleRequestsSortableFields;
};

export enum CircleRequestsSortableFields {
  AcceptedBy = 'acceptedBy',
  AddressId = 'addressId',
  AskTo = 'askTo',
  Availabilities = 'availabilities',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  Category = 'category',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  IsAvailabilityFlexible = 'isAvailabilityFlexible',
  OtherCategory = 'otherCategory',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  Ride = 'ride',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  TentativeSchedule = 'tentativeSchedule',
  UpdatedAt = 'updatedAt',
}

/** The roles related to the relationship of users in the application */
export enum CircleRoles {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
}

/** Fields to update for a coach */
export type CoachInfoInput = {
  /** Bio to update */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** First Name to update */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last Name to update */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CoachVisitFilter = {
  /** Filter by city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Filter by coach id */
  coachId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by customer id */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by whether or not the coach visit has had any visit summaries sent */
  hasVisitSummary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by whether the meeting should be conducted via phone */
  isPhoneCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<SocialEventStatus>>;
  /** Filter by zipCode */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type CoachVisitSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CoachVisitsSortableFields;
};

export enum CoachVisitsSortableFields {
  AddressId = 'addressId',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

/** The fields to filter companies */
export type CompanyFilter = {
  /** Retrieve companies that has agents with the specified service types */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** The company name or companies with agent name to filter by */
  text?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type CompanySortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: CompanySortableFields;
};

/** The possible fields that companies can be sorted */
export enum CompanySortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

export type ConfirmStripeTransactionOutput = {
  __typename?: 'ConfirmStripeTransactionOutput';
  success: Scalars['Boolean']['output'];
};

export enum ContactRequestStatus {
  Answered = 'answered',
  Pending = 'pending',
}

export type Conversation = {
  __typename?: 'Conversation';
  /** User information that created the conversation */
  CreatedBy?: Maybe<GraphqlUser>;
  /** List of users that are part of the conversation */
  Participants?: Maybe<Array<GraphqlUser>>;
  createdAt: Scalars['Float']['output'];
  custom: ConversationCustom;
  id: Scalars['String']['output'];
  lastMessage?: Maybe<Message>;
  participants: Array<Participant>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  topicId?: Maybe<Scalars['String']['output']>;
  welcomeMessages?: Maybe<Array<Scalars['String']['output']>>;
};

export type ConversationCustom = {
  __typename?: 'ConversationCustom';
  chatType?: Maybe<ChatTypeOptions>;
  city?: Maybe<Scalars['String']['output']>;
  /** User ID that created the conversation */
  createdBy?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  environment: Scalars['String']['output'];
  participants?: Maybe<Scalars['String']['output']>;
  /** The role of participants, used for Community chats */
  role?: Maybe<GlobalRole>;
  serviceRequestId?: Maybe<Scalars['String']['output']>;
  userIds?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** The fields for the custom data of a conversation */
export type ConversationCustomInput = {
  chatType?: InputMaybe<ChatTypeOptions>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Scalars['String']['input']>;
  /** The role of participants, used for Community chats */
  role?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The filters for the custom data of a conversation */
export type ConversationCustomInputFilter = {
  chatTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Scalars['String']['input']>;
  /** The role of participants, used for Community chats */
  role?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The possible fields that conversations can be sorted */
export enum ConversationsSortableFields {
  CreatedAt = 'createdAt',
  LastActivity = 'lastActivity',
}

/** The fields to create an address */
export type CreateAddressInput = {
  /** First address line */
  addressLine1: Scalars['String']['input'];
  /** The additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city: Scalars['String']['input'];
  /** The address country code */
  country: Scalars['String']['input'];
  /** Customer relation ID */
  customerId: Scalars['Int']['input'];
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state: Scalars['String']['input'];
  /** The address zip code */
  zipCode: Scalars['String']['input'];
};

/** The fields for creating an agent */
export type CreateAgentInput = {
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['input'];
  /** The agent's email */
  email: Scalars['String']['input'];
  /** The agent's first name */
  firstName: Scalars['String']['input'];
  /** The agent's internal (hidden) rating */
  internalRating?: InputMaybe<Scalars['Int']['input']>;
  /** The agent's last name */
  lastName: Scalars['String']['input'];
  /** Optional password to be set on agent creation */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The agent's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods?: InputMaybe<Array<AgentPreferedContactMethodEnum>>;
  /** The agent's rating */
  rating?: InputMaybe<Scalars['Float']['input']>;
  /** The agent role (agent admin or agent) */
  role: AgentRole;
  /** The types of services that the agent can provide */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
};

export type CreateAppointmentPaymentInput = {
  /** The amount that is being charged for the service */
  amount: Scalars['Float']['input'];
  appointmentRequestId: Scalars['Int']['input'];
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  /** The payment type */
  type: ServicePaymentType;
};

export type CreateCircleRequestInput = {
  /** Address id related to where the request was made */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The IDs of the user that will be asked for attend this request */
  askTo?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities: Array<ServiceRequestAvailabilityInput>;
  /** The category of this request */
  category: CircleRequestCategoriesEnum;
  /** The customer id related to this request */
  customerId: Scalars['Int']['input'];
  /** Description of the request made by the user */
  description: Scalars['String']['input'];
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['input'];
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks request as recurring */
  recurring: Scalars['Boolean']['input'];
  /** The user who is requesting the circle request (it should be a non management user) */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** The tentative schedule of the request provided by the user */
  tentativeSchedule: ServiceRequestTentativeSchedule;
};

export type CreateCoachReviewInput = {
  coachId: Scalars['Int']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
};

export type CreateCoachVisitInput = {
  /** Address id related to where the visit was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of coach related to the visit */
  coachId?: InputMaybe<Scalars['Int']['input']>;
  /** The id of customer related to the visit */
  customerId: Scalars['Int']['input'];
  /** Description of the visit */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Whether the meeting should be conducted via phone */
  isPhoneCall?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the visit is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the visit is going to occur */
  scheduledAt: Scalars['DateTime']['input'];
};

/** Fields to create a company */
export type CreateCompanyInput = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  hours?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  serviceZipCodes: Array<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type CreateContactRequestInput = {
  bryaInternalNotes?: InputMaybe<Scalars['String']['input']>;
  bryaMessage?: InputMaybe<Scalars['String']['input']>;
  customerActivityId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContactRequestStatus>;
  userMessage?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to create a customer */
export type CreateCustomerInput = {
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** The customer courtesy title (Mr., Ms., etc.) */
  courtesyTitle?: InputMaybe<Scalars['String']['input']>;
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer first name */
  firstName: Scalars['String']['input'];
  /** The customer last name */
  lastName: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
};

/** The fields to create a customer with additional information */
export type CreateCustomerWithAdditionalInformationInput = {
  /** The customer age */
  age?: InputMaybe<Scalars['Int']['input']>;
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** Other important information of the customer */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The customer courtesy title (Mr., Ms., etc.) */
  courtesyTitle?: InputMaybe<Scalars['String']['input']>;
  /** The educational background of the customer */
  educationalBackground?: InputMaybe<Scalars['String']['input']>;
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer first name */
  firstName: Scalars['String']['input'];
  /** The customer gender */
  gender?: InputMaybe<Scalars['String']['input']>;
  /** The hobbies and interests of the customer */
  hobbies?: InputMaybe<Scalars['String']['input']>;
  /** The customer last name */
  lastName: Scalars['String']['input'];
  /** Other important information of the customer */
  otherInformation?: InputMaybe<Scalars['String']['input']>;
  /** The customer phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The professional background of the customer */
  professionalBackground?: InputMaybe<Scalars['String']['input']>;
  /** The relationship status of the customer */
  relationshipStatus?: InputMaybe<Scalars['String']['input']>;
  /** The number of years the customer has been retired */
  retiredTime?: InputMaybe<Scalars['Int']['input']>;
  /** Other important information of the customer */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Other important information of the customer */
  zipCode: Scalars['String']['input'];
};

export type CreateExpiringTokenOutput = {
  __typename?: 'CreateExpiringTokenOutput';
  /** The expiring token to use to login in the mobile app */
  token: Scalars['String']['output'];
};

/** The fields for creating a feature flag */
export type CreateFeatureFlagInput = {
  /** The feature flag human readable description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Whether if the flag is enabled or not */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The feature flag identifier */
  feature: Scalars['String']['input'];
};

export type CreateInternalNoteInput = {
  /** Optional associated coach visit id */
  coachVisitId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional associated service request id */
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  /** Internal note text */
  note: Scalars['String']['input'];
  /** Optional associated service request id */
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateNotificationInput = {
  /** The deeplink related to the notification */
  deeplink?: InputMaybe<Scalars['String']['input']>;
  /** The payload of the action */
  payload?: InputMaybe<Scalars['JsonValue']['input']>;
  /** The text */
  text: Scalars['String']['input'];
  /** The title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** The notification type */
  type: Scalars['String']['input'];
  /** The user id related to this notification */
  userId: Scalars['Int']['input'];
};

export type CreateRequestCategoryInput = {
  circleRequest: Scalars['Boolean']['input'];
  circleRequestOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  serviceRequest: Scalars['Boolean']['input'];
  serviceRequestOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateServicePaymentInput = {
  /** The amount that is being charged for the service */
  amount: Scalars['Float']['input'];
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  serviceRequestId: Scalars['Int']['input'];
  /** Payment method id that is being used to pay for the service */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** The payment type */
  type: ServicePaymentType;
};

export type CreateServiceRequestInput = {
  /** Address id related to where the service was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities: Array<ServiceRequestAvailabilityInput>;
  /** The category of this service request */
  category: ServiceRequestCategoriesEnum;
  /** The customer id related to this service request */
  customerId: Scalars['Int']['input'];
  /** Description of the request made by the user */
  description: Scalars['String']['input'];
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['input'];
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks service request as recurring */
  recurring: Scalars['Boolean']['input'];
  /** The user who is requesting the service (it should be a non management user) */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The ride details of the service request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** The tentative schedule of the service request provided by the user */
  tentativeSchedule: ServiceRequestTentativeSchedule;
};

export type CreateServiceRequestReviewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Whether if the reviewer is marking the agent as favorite or not */
  markAsFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  /** The negative tags for the review */
  negativeTags?: InputMaybe<Array<PositiveTags>>;
  /** The positive tags for the review */
  positiveTags?: InputMaybe<Array<PositiveTags>>;
  rating: Scalars['Int']['input'];
  serviceRequestId: Scalars['Int']['input'];
};

export type CreateSocialEventInput = {
  /** Location address line 1 */
  addressLine1: Scalars['String']['input'];
  /** Location address line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Location address city name */
  city: Scalars['String']['input'];
  /** The ids of coaches related to the event */
  coachesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Location address country name */
  country: Scalars['String']['input'];
  /** Description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The event name */
  eventName: Scalars['String']['input'];
  /** Frequency at which the event recurs */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** The ids of participants related to the event */
  participantsIds: Array<Scalars['Int']['input']>;
  /** Location place name */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Whether the event is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the event is going to occur */
  scheduledAt: Scalars['DateTime']['input'];
  /** Location address state name */
  state: Scalars['String']['input'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['input'];
};

export type CreateStripeSubscriptionOutput = {
  __typename?: 'CreateStripeSubscriptionOutput';
  /** The client secret to allow the user to pay for the subscription, if undefined, it is the free plan */
  clientSecret?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['String']['output'];
};

/** The available fileds to create a timeline entry for a user, a customer and a service request */
export type CreateStripeSubscriptionPrice = {
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  /** The id of the stripe product (in case you are adding a new price to an existing product) */
  productId?: InputMaybe<Scalars['String']['input']>;
  /** The name of the stripe product (in case you are adding a new price with a new product) */
  productName?: InputMaybe<Scalars['String']['input']>;
  /** The recurring pattern for a new price */
  recurring?: InputMaybe<StripeSubscriptionPricesRecurringEnum>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for creating a suggested activity */
export type CreateSuggestedActivityInput = {
  /** The suggested activity description */
  description: Scalars['String']['input'];
  /** The suggested activity name */
  name: Scalars['String']['input'];
  /** The role the user wants to have */
  role: SuggestedActivitiesRoleEnum;
  /** The user that is creating the suggested activity */
  userId: Scalars['Int']['input'];
};

/** The available fileds to create a timeline entry for a user, a customer and a service request */
export type CreateTimelineInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** The fields for creating an user */
export type CreateUserInput = {
  /** The user email */
  email: Scalars['String']['input'];
  /** The user first name */
  firstName: Scalars['String']['input'];
  /** The user last name */
  lastName: Scalars['String']['input'];
  /** The user password */
  password: Scalars['String']['input'];
};

/** The available fileds to create a circle invites for a customer */
export type CreateUserInviteInput = {
  /** The invites to send the invitation */
  invites: Array<UserInviteInput>;
};

export type CreateUserInvitesOutput = {
  __typename?: 'CreateUserInvitesOutput';
  sentVia: Array<SentViaOutput>;
  success: Scalars['Boolean']['output'];
};

/** The available fileds to create a user requests can be to circle or family group */
export type CreateUserRequestInput = {
  /** The customer whom will recieve the request */
  customerId: Scalars['Int']['input'];
  /** The email to sent the request */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The person name to whom the invite was sent */
  name: Scalars['String']['input'];
  /** The phone number to sent the request */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The type of the request, familyPartner or circleMember */
  requestType: UserInvitesType;
  /** The user whom will send the request */
  requestedBy?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateVisitSummaryInput = {
  /** Visit summary body text */
  body: Scalars['String']['input'];
  /** Associated coach visit id */
  coachVisitId?: InputMaybe<Scalars['Int']['input']>;
  /** List of user IDs to whom to send summary */
  sentTo: Array<Scalars['Int']['input']>;
};

/** The possible fields that the customers can be sorted by */
export enum CustomerSortableFields {
  Birthday = 'birthday',
  CreatedAt = 'createdAt',
  FullName = 'fullName',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

/** The mobile tabs that can be the default one that should be opened for an user */
export enum DefaultTab {
  Chat = 'chat',
  Help = 'help',
  Schedule = 'schedule',
}

export type DeleteAccountOutput = {
  __typename?: 'DeleteAccountOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteAddressOutput = {
  __typename?: 'DeleteAddressOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteAgentOutput = {
  __typename?: 'DeleteAgentOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteConversationOutput = {
  __typename?: 'DeleteConversationOutput';
  /** The id of the conversation that was deleted */
  conversationId: Scalars['String']['output'];
  /** If the conversation was deleted successfully */
  success: Scalars['Boolean']['output'];
};

export type DeleteReviewOutput = {
  __typename?: 'DeleteReviewOutput';
  reviewId: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeleteSuggestedActivityOutput = {
  __typename?: 'DeleteSuggestedActivityOutput';
  success: Scalars['Boolean']['output'];
};

export type DeleteUserInvitesOutput = {
  __typename?: 'DeleteUserInvitesOutput';
  success: Scalars['Boolean']['output'];
};

export type ExchangeAuthIdOutput = {
  __typename?: 'ExchangeAuthIdOutput';
  accessToken?: Maybe<Scalars['String']['output']>;
  finished: Scalars['Boolean']['output'];
};

export type ExchangeExpiringTokenOutput = {
  __typename?: 'ExchangeExpiringTokenOutput';
  accessToken: Scalars['String']['output'];
};

/** The possible fields that files can be sorted */
export enum FileSortableFields {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export type FilesFilter = {
  agentId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  uploadBy?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** The sorting configuration */
export type FilesSort = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: FileSortableFields;
};

/** The fields to filter a customer */
export type FiltersInput = {
  /** The filter based on full name */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the customer has a coach assigned */
  hasCoach?: InputMaybe<Scalars['Boolean']['input']>;
  /** The field to sorter by */
  sortField: CustomerSortableFields;
  /** The value to order by */
  sortValue: SortOrder;
  /** Filters customers based on a user that is associated with them */
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type FinishAuthIdOutput = {
  __typename?: 'FinishAuthIdOutput';
  success: Scalars['Boolean']['output'];
};

export type FrontAppChannel = {
  __typename?: 'FrontAppChannel';
  /** The channel chat id */
  channelChatId: Scalars['String']['output'];
  /** The current user hash for this channel */
  userHash: Scalars['String']['output'];
};

export type GenerateAuthIdOutput = {
  __typename?: 'GenerateAuthIdOutput';
  /** Genereated auth id to use to log in in the mobile app */
  authId: Scalars['String']['output'];
  /** Genereated secure token to use to be able to log in in the mobile app */
  secureToken: Scalars['String']['output'];
};

export type GetActivitiesAlertsOutput = {
  __typename?: 'GetActivitiesAlertsOutput';
  id: Scalars['Int']['output'];
  status: ServiceRequestStatus;
};

export type GetActivitiesGroupedByDateOutput = {
  __typename?: 'GetActivitiesGroupedByDateOutput';
  /** The list of circle, service requests and social events for specific customers in a specific date grouped by date */
  meta: ActivitiesGroupedByDateMeta;
  /** The list of circle, service requests and social events for specific customers in a specific date grouped by date */
  requests: Array<ActivitiesGroupedByDateOutput>;
};

export type GetActivitiesOutput = {
  __typename?: 'GetActivitiesOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of circle, service requests and social events for specific customers */
  requests: Array<Activities>;
};

export type GetAgentReviewsOutput = {
  __typename?: 'GetAgentReviewsOutput';
  meta: PaginationMeta;
  reviews: Array<GraphqlServiceRequestReview>;
};

export type GetAgentsByServiceRequestOutput = {
  __typename?: 'GetAgentsByServiceRequestOutput';
  /** List of agents */
  agents: Array<GetTopAgentsOutput>;
};

export type GetAgentsOutput = {
  __typename?: 'GetAgentsOutput';
  /** List of agents */
  agents: Array<GraphqlAgent>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetAppointmentRequestsActivitiesOutput = {
  __typename?: 'GetAppointmentRequestsActivitiesOutput';
  /** The list of activities */
  activities: Array<GraphqlAppointmentRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetAppointmentRequestsOutput = {
  __typename?: 'GetAppointmentRequestsOutput';
  /** The list of appointment requests */
  appointmentRequests: Array<GraphqlAppointmentRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCircleRequestsOutput = {
  __typename?: 'GetCircleRequestsOutput';
  /** The list of requests */
  circleRequests: Array<GraphqlCircleRequest>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCoachProfileOutput = {
  __typename?: 'GetCoachProfileOutput';
  /** The bio description */
  bio?: Maybe<Scalars['String']['output']>;
  /** The first name */
  firstName: Scalars['String']['output'];
  /** The full name */
  fullName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  /** The last name */
  lastName: Scalars['String']['output'];
  /** The profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type GetCoachReviewsOutput = {
  __typename?: 'GetCoachReviewsOutput';
  meta: PaginationMeta;
  reviews: Array<GraphqlCoachReview>;
};

export type GetCoachVisitsOutput = {
  __typename?: 'GetCoachVisitsOutput';
  /** The list of coach visits */
  coachVisits: Array<GraphqlCoachVisit>;
  /** The pagination information */
  meta: PaginationMeta;
};

export type GetCompaniesOutput = {
  __typename?: 'GetCompaniesOutput';
  /** List of companies */
  companies: Array<GraphqlCompany>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetConversationSignatureOutput = {
  __typename?: 'GetConversationSignatureOutput';
  /** Generated embedUrl to create an iframe of a read-only chat history */
  embedUrl: Scalars['String']['output'];
};

/** The options to filter conversations */
export type GetConversationsFilter = {
  /** Retrieves conversation with the specified custom fields */
  custom?: InputMaybe<ConversationCustomInputFilter>;
  /** Retrieves conversations with last message after the specified date */
  lastMessageAfter?: InputMaybe<Scalars['DateTime']['input']>;
  /** Retrieves conversations with last message before the specified date */
  lastMessageBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetConversationsOutput = {
  __typename?: 'GetConversationsOutput';
  /** List of conversations */
  conversations: Array<Conversation>;
};

/** Conversations sorting configuration */
export type GetConversationsSort = {
  /** The direction to order by */
  direction: Scalars['String']['input'];
  /** The field to sort by */
  field: ConversationsSortableFields;
};

export type GetCustomerOutput = {
  __typename?: 'GetCustomerOutput';
  /** The user who create the customer */
  Creator?: Maybe<GraphqlUser>;
  /** More information related to the customer */
  CustomerAdditionalInformation?: Maybe<GraphqlCustomerAdditionalInformation>;
  /** The users and roles associated to the customer */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** The addresses of a customer */
  addresses?: Maybe<Array<GraphqlAddresses>>;
  /** The customer birthday date */
  birthday?: Maybe<Scalars['DateTime']['output']>;
  /** The date the customer has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the user that created the customer */
  createdBy: Scalars['Int']['output'];
  /** The customer email */
  email?: Maybe<Scalars['String']['output']>;
  /** The customer first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The customer full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The customer last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The last date the customer has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The list of users related to the customer */
  users?: Maybe<Array<UserGetCustomerOutput>>;
};

export type GetDefaultTimezone = {
  __typename?: 'GetDefaultTimezone';
  /** The default timezone in the app */
  timezone: Scalars['String']['output'];
};

export type GetFilesOutput = {
  __typename?: 'GetFilesOutput';
  /** List of files */
  files: Array<GraphqlFile>;
  /** Pagination information */
  meta: PaginationMeta;
};

export type GetFrontAppChannels = {
  __typename?: 'GetFrontAppChannels';
  /** Coach channel */
  coachChannel: FrontAppChannel;
  /** Support channel */
  supportChannel: FrontAppChannel;
};

export type GetGooglePlacesApiKeyOutput = {
  __typename?: 'GetGooglePlacesApiKeyOutput';
  /** google api key */
  apiKey?: Maybe<Scalars['String']['output']>;
};

export type GetInternalNotesOutput = {
  __typename?: 'GetInternalNotesOutput';
  meta: PaginationMeta;
  results: Array<GraphqlInternalNote>;
};

export type GetNotificationsByUserIdOutput = {
  __typename?: 'GetNotificationsByUserIdOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of notifications */
  notifications: Array<GraphqlNotification>;
};

export type GetRecentActivityOuput = {
  __typename?: 'GetRecentActivityOuput';
  /** Latest coach reviews */
  coachReviews?: Maybe<Array<GraphqlCoachReview>>;
  /** Latest service requests reviews */
  serviceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** Latest service requests */
  serviceRequests?: Maybe<Array<GraphqlServiceRequest>>;
  /** Latest social events */
  socialEvents?: Maybe<Array<GraphqlSocialEvent>>;
};

export type GetServiceRequestsOutput = {
  __typename?: 'GetServiceRequestsOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of service requests */
  serviceRequests: Array<GraphqlServiceRequest>;
};

export type GetSignatureOutput = {
  __typename?: 'GetSignatureOutput';
  /** Generated signature to use in the chat */
  signature: Scalars['String']['output'];
  talkJsAppId: Scalars['String']['output'];
  talkJsId: Scalars['String']['output'];
};

export type GetSocialEventsOutput = {
  __typename?: 'GetSocialEventsOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of social events */
  socialEvents: Array<GraphqlSocialEvent>;
};

export type GetStripeTransactionOutput = {
  __typename?: 'GetStripeTransactionOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of transactions */
  transactions: Array<GraphqlStripeTransaction>;
};

export type GetTopAgentsOutput = {
  __typename?: 'GetTopAgentsOutput';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** Whether an appointment request has been sent to the agent or not */
  appointmentRequestSent: Scalars['Boolean']['output'];
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** Whether the agent is a favorite of the customer or not */
  customerFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  /** The position of the top list */
  top: Scalars['Int']['output'];
  /** Whether the agent is trusted favorite or not */
  trustedFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the agent is trusted reviewed or not */
  trustedReviewed?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['Int']['output'];
};

/** The fields for filter in the GetUserParticipants query  */
export type GetUserParticipantsFilter = {
  /** The user name to filter by */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The user type to filter by */
  type?: InputMaybe<TrustedUserTypeOptions>;
};

export type GetUserParticipantsOutput = {
  __typename?: 'GetUserParticipantsOutput';
  /** The list of users from the database */
  users: Array<UserWithTrustedFlag>;
  /** The list of users from TalkJs */
  usersFromTalkJs: Array<UserWithTrustedFlag>;
};

export type GetUsersOutput = {
  __typename?: 'GetUsersOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of users */
  users: Array<GraphqlUser>;
};

export type GetVisitSummariesOutput = {
  __typename?: 'GetVisitSummariesOutput';
  meta: PaginationMeta;
  results: Array<GraphqlVisitSummary>;
};

export type GetVisitSummaryByCoachVisitIdOutput = {
  __typename?: 'GetVisitSummaryByCoachVisitIdOutput';
  results: Array<GraphqlVisitSummary>;
};

export type GetZipCodeMatchesBusinessRules = {
  __typename?: 'GetZipCodeMatchesBusinessRules';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** All the roles available in the application */
export enum GlobalRole {
  Admin = 'admin',
  AdminAgent = 'adminAgent',
  Agent = 'agent',
  CircleMember = 'circleMember',
  Coach = 'coach',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
  Support = 'support',
}

export type GraphqlAddresses = {
  __typename?: 'GraphqlAddresses';
  /** The address of the customer */
  addressLine1: Scalars['String']['output'];
  /** The additional address information  */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The customer address city */
  city: Scalars['String']['output'];
  /** The customer address country code */
  country: Scalars['String']['output'];
  /** The date the customer address has been created */
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** Whether the address is the default one or not */
  isDefault: Scalars['Boolean']['output'];
  /** Whether the address was created using Google Maps API */
  isFromAPI: Scalars['Boolean']['output'];
  /** The customer place */
  place?: Maybe<Scalars['String']['output']>;
  /** The customer address state */
  state: Scalars['String']['output'];
  /** The last date the customer address has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The customer address zip code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlAgent = {
  __typename?: 'GraphqlAgent';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  userId: Scalars['Int']['output'];
};

export type GraphqlAppFeaturesSelected = {
  __typename?: 'GraphqlAppFeaturesSelected';
  /** The User information related to the app features selected */
  User: GraphqlUser;
  /** Whether the user includes circle member features */
  circleMember: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Whether the user includes family partner features */
  familyPartner: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** Whether the user includes senior member features */
  seniorMember: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type GraphqlAppointmentRequest = {
  __typename?: 'GraphqlAppointmentRequest';
  /** The payment information related to the appointment request */
  AppointmentRequestPayments?: Maybe<Array<GraphqlAppointmentRequestPayment>>;
  CircleRequest?: Maybe<GraphqlCircleRequest>;
  /** The user who sent the request */
  CreatedBy?: Maybe<GraphqlUser>;
  /** The agent or circle member assigned to the request */
  SentTo?: Maybe<GraphqlUser>;
  ServiceRequest?: Maybe<GraphqlServiceRequest>;
  /** The id of the circle request */
  circleRequestId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** The datetime the recipient has chosen from within availability */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the service request */
  serviceRequestId?: Maybe<Scalars['Int']['output']>;
  status: AppointmentRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type GraphqlAppointmentRequestPayment = {
  __typename?: 'GraphqlAppointmentRequestPayment';
  /** The amount that is being used as base to calculate who much the service will cost */
  amount: Scalars['Float']['output'];
  appointmentRequestId: Scalars['Int']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The estimate hours the service should take to be completed */
  estimateHours?: Maybe<Scalars['Float']['output']>;
  /** The amount that is being charged as a service fee */
  fee: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** The amount that is being charged as state tax */
  tax: Scalars['Float']['output'];
  /** The type used to charge the service */
  type: ServicePaymentType;
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlBlockedUsers = {
  __typename?: 'GraphqlBlockedUsers';
  /** The user information related to the user that was blocked */
  BlockedUser: GraphqlUser;
  /** The user information related to the user that blocked another */
  BlockerUser: GraphqlUser;
  /** The user id of the user that was blocked */
  blockedUserId: Scalars['Int']['output'];
  /** The user id of the user that blocked another user */
  blockerUserId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlCircleRequest = {
  __typename?: 'GraphqlCircleRequest';
  /** The user that accepted the request */
  AcceptedBy?: Maybe<GraphqlUser>;
  /** The address related to where the service was requested */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that accepted the request */
  AskTo: Array<GraphqlUser>;
  /** The user that cancelled the service */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The user that created the request */
  CreatedBy: GraphqlUser;
  /** The customer related to the request */
  Customer: GetCustomerOutput;
  /** The category associated to the request */
  RequestCategory?: Maybe<GraphqlRequestCategory>;
  /** The user that asked for the request */
  RequestedBy: GraphqlUser;
  /** The timeline events related to the requested */
  Timeline?: Maybe<Array<GraphqlTimeline>>;
  /** The date at which someone accepted the request */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user id that accepted the request */
  acceptedBy?: Maybe<Scalars['Int']['output']>;
  /** Address ID related to the request */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The user IDs that are asked to attend the request by the user */
  askTo: Array<Scalars['Int']['output']>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities: Array<ServiceRequestAvailability>;
  /** The category of this request */
  category: CircleRequestCategoriesEnum;
  /** The date the request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the request */
  createdBy: Scalars['Int']['output'];
  /** The customer id related to this request */
  customerId: Scalars['Int']['output'];
  /** Description of the request made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: Maybe<Scalars['String']['output']>;
  /** Marks request as recurring */
  recurring: Scalars['Boolean']['output'];
  /** The type of the service request */
  requestType: ServiceRequestType;
  /** The user id that asked for the request */
  requestedBy: Scalars['Int']['output'];
  /** The ride information for the request */
  ride?: Maybe<Ride>;
  /** The date at which someone will attend the request */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current status of the service request */
  status: ServiceRequestStatus;
  /** Specifies the tentative schedule that the user proposed for the request */
  tentativeSchedule: ServiceRequestTentativeSchedule;
  /** The date the request was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoachReview = {
  __typename?: 'GraphqlCoachReview';
  /** The user that made the review */
  ReviewedBy: GraphqlUser;
  coachId: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
  reviewedBy: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoachVisit = {
  __typename?: 'GraphqlCoachVisit';
  /** The address where the visit is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the visit */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The coach information */
  Coach: GraphqlUser;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The customer information */
  Customer: GraphqlCustomer;
  /** Notes related to the Coach Visit */
  InternalNotes?: Maybe<Array<GraphqlInternalNote>>;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Visit Summaries related to the Coach Visit */
  VisitSummaries?: Maybe<Array<GraphqlVisitSummary>>;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** Description of the visit made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Whether the visit is a recurring visit or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The current status of the event */
  status: SocialEventStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCoaches = {
  __typename?: 'GraphqlCoaches';
  /** The user information of the coach */
  Coach?: Maybe<GraphqlUser>;
  /** The id of the event */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The id of the coach that was invited to the event */
  coachId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlCompany = {
  __typename?: 'GraphqlCompany';
  Agents: Array<GraphqlAgent>;
  StripeConnectAccounts?: Maybe<GraphqlStripeConnectAccount>;
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hours?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  serviceZipCodes?: Maybe<Array<Scalars['String']['output']>>;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type GraphqlContactRequest = {
  __typename?: 'GraphqlContactRequest';
  bryaInternalNotes?: Maybe<Scalars['String']['output']>;
  bryaMessage?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerActivityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  status: ContactRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
  userMessage?: Maybe<Scalars['String']['output']>;
};

export type GraphqlCustomer = {
  __typename?: 'GraphqlCustomer';
  /** The user who create the customer */
  Creator?: Maybe<GraphqlUser>;
  /** More information related to the customer */
  CustomerAdditionalInformation?: Maybe<GraphqlCustomerAdditionalInformation>;
  /** The users and roles associated to the customer */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** The addresses of a customer */
  addresses?: Maybe<Array<GraphqlAddresses>>;
  /** The customer birthday date */
  birthday?: Maybe<Scalars['DateTime']['output']>;
  /** The date the customer has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The id of the user that created the customer */
  createdBy: Scalars['Int']['output'];
  /** The customer email */
  email?: Maybe<Scalars['String']['output']>;
  /** The customer first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The customer full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The customer last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The last date the customer has been updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlCustomerAdditionalInformation = {
  __typename?: 'GraphqlCustomerAdditionalInformation';
  /** The customer age */
  age?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['Int']['output'];
  /** The educational background of the customer */
  educationalBackground?: Maybe<Scalars['String']['output']>;
  /** The customer gender */
  gender?: Maybe<Scalars['String']['output']>;
  /** The hobbies and interests of the customer */
  hobbies?: Maybe<Scalars['String']['output']>;
  /** Other important information of the customer */
  otherInformation?: Maybe<Scalars['String']['output']>;
  /** The professional background of the customer */
  professionalBackground?: Maybe<Scalars['String']['output']>;
  /** The relationship status of the customer */
  relationshipStatus?: Maybe<Scalars['String']['output']>;
  /** The number of years the customer has been retired */
  retiredTime?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type GraphqlCustomerAgent = {
  __typename?: 'GraphqlCustomerAgent';
  /** The company that the agent belongs to */
  Company?: Maybe<GraphqlCompany>;
  /** The user information related to the favorite agent */
  CustomerFavoriteAgents?: Maybe<Array<GraphqlCustomerFavoriteAgent>>;
  /** The service request reviews related to this agent */
  ServiceRequestReviews?: Maybe<Array<GraphqlServiceRequestReview>>;
  /** The User information related to the agent */
  User: GraphqlUser;
  /** The ID of the company that the agent belongs to */
  companyId: Scalars['Int']['output'];
  /** The datetime at which the agent was soft-deleted (if ever) */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Service categories customer marked this agent as favorite */
  favorite: Array<ServiceRequestCategoriesEnum>;
  /** if the customer logged in has marked the agent as favorite */
  hasMarkedAgentAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /** The agent's internal (hidden) rating */
  internalRating?: Maybe<Scalars['Int']['output']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods: Array<AgentPreferedContactMethodEnum>;
  /** The agent's rating */
  rating?: Maybe<Scalars['Float']['output']>;
  /** The agent role */
  role: AgentRole;
  /** The types of services that the agent can provide given in a object structure */
  serviceTypeInfo?: Maybe<Array<ServiceType>>;
  /** The types of services that the agent can provide */
  serviceTypes: Array<ServiceRequestCategoriesEnum>;
  userId: Scalars['Int']['output'];
};

export type GraphqlCustomerFavoriteAgent = {
  __typename?: 'GraphqlCustomerFavoriteAgent';
  /** The Customer information related to the favorite agent */
  Customer?: Maybe<GraphqlCustomer>;
  agentId: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  /** The types of services that the agent can provide */
  serviceType: ServiceRequestCategoriesEnum;
};

export type GraphqlCustomerSocialEvent = {
  __typename?: 'GraphqlCustomerSocialEvent';
  /** The address where the event is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the visit */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The contact requests of the current customer */
  ContactRequests: Array<GraphqlContactRequest>;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The customer information of the participant */
  Customer: GraphqlCustomer;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Location address line 1 */
  addressLine1: Scalars['String']['output'];
  /** Location address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** Location address city name */
  city: Scalars['String']['output'];
  /** Location address country name */
  country: Scalars['String']['output'];
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** The id of the customer activity */
  customerActivityId: Scalars['Int']['output'];
  /** Description of the event made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** The event name */
  eventName: Scalars['String']['output'];
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Location place name */
  place?: Maybe<Scalars['String']['output']>;
  /** Whether the event is a recurring event or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Location address state name */
  state: Scalars['String']['output'];
  /** The status of the participant for the event */
  status: ServiceRequestStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlFeatureFlag = {
  __typename?: 'GraphqlFeatureFlag';
  /** Human readable feature description */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether if the feature is enabled or not */
  enabled: Scalars['Boolean']['output'];
  /** The feature identifier */
  feature: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type GraphqlFile = {
  __typename?: 'GraphqlFile';
  Agent?: Maybe<GraphqlAgent>;
  Customer?: Maybe<GraphqlCustomer>;
  UploadBy?: Maybe<GraphqlUser>;
  User?: Maybe<GraphqlUser>;
  agentId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['Float']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadBy?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type GraphqlFlaggedMessages = {
  __typename?: 'GraphqlFlaggedMessages';
  /** The user information related to the user that blocked another */
  User: GraphqlUser;
  /** The conversation id */
  conversationId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** The text message */
  messageBody: Scalars['String']['output'];
  /** The message id */
  messageId: Scalars['String']['output'];
  /** The user that send the message */
  userId: Scalars['Int']['output'];
};

export type GraphqlInternalNote = {
  __typename?: 'GraphqlInternalNote';
  CoachVisit?: Maybe<GraphqlCoachVisit>;
  Employee?: Maybe<GraphqlUser>;
  ServiceRequest?: Maybe<GraphqlServiceRequest>;
  /** The user that made the note */
  Users: GraphqlUser;
  /** Optional associated coach visit id */
  coachVisitId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Optional associated employee (user) to the note */
  employeeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** Internal note text */
  note: Scalars['String']['output'];
  /** Optional associated service request id */
  serviceRequestId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** User id owner of the note */
  userId: Scalars['Int']['output'];
};

export type GraphqlNotification = {
  __typename?: 'GraphqlNotification';
  /** The user related to this notification */
  User?: Maybe<GraphqlUser>;
  /** The date the notification has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The deeplink of the notification */
  deeplink?: Maybe<Scalars['String']['output']>;
  /** The date when the nofitication was sent via email */
  emailSentAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  /** The payload of the action */
  payload?: Maybe<Scalars['JsonValue']['output']>;
  /** The date when the nofitication was sent via push */
  pushSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date where the notification was read */
  readAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date when the nofitication was sent via sms */
  smsSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** The text */
  text: Scalars['String']['output'];
  /** The title */
  title?: Maybe<Scalars['String']['output']>;
  /** The notification type */
  type: Scalars['String']['output'];
  /** The date the notification has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id related to this notification */
  userId: Scalars['Int']['output'];
};

export type GraphqlNotificationSettings = {
  __typename?: 'GraphqlNotificationSettings';
  /** The date the timeline has been created */
  createdAt: Scalars['DateTime']['output'];
  /** whether notifications will be sent via email or not */
  email: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** the notification type */
  notificationType: Scalars['String']['output'];
  /** whether notifications will be sent via push or not */
  push: Scalars['Boolean']['output'];
  /** whether notifications will be sent via push web or not */
  pushWeb: Scalars['Boolean']['output'];
  /** whether notifications will be sent via sms or not */
  sms: Scalars['Boolean']['output'];
  /** The user id related to settings */
  userId: Scalars['Int']['output'];
};

export type GraphqlParticipants = {
  __typename?: 'GraphqlParticipants';
  /** The contact requests of the current customer */
  ContactRequests: Array<GraphqlContactRequest>;
  /** The customer information of the participant */
  Customer?: Maybe<GraphqlCustomer>;
  /** The date the event was accepted */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The id of the event */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The id of the customer that was invited to the event */
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** The reason the event was rejected by the user */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** The status of the participant for the event */
  status: ServiceRequestStatus;
};

export type GraphqlRequestCategory = {
  __typename?: 'GraphqlRequestCategory';
  /** whether the category is for circle requests or not  */
  circleRequest: Scalars['Boolean']['output'];
  /** the number of the category order when it is listed */
  circleRequestOrder?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** the id of the category */
  id: Scalars['String']['output'];
  /** the name of the category */
  name: Scalars['String']['output'];
  /** whether the category is for service requests or not  */
  serviceRequest: Scalars['Boolean']['output'];
  /** the number of the category order when it is listed */
  serviceRequestOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlRideAddress = {
  __typename?: 'GraphqlRideAddress';
  /** First address line */
  addressLine1: Scalars['String']['output'];
  /** The additional address information  */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The address city */
  city: Scalars['String']['output'];
  /** The address country code */
  country: Scalars['String']['output'];
  /** Whether the address is the default one */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: Maybe<Scalars['Boolean']['output']>;
  /** Alias for the address */
  place?: Maybe<Scalars['String']['output']>;
  /** The address state */
  state: Scalars['String']['output'];
  /** The address zip code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlServiceRequest = {
  __typename?: 'GraphqlServiceRequest';
  /** The address related to where the service was requested */
  Address?: Maybe<GraphqlAddresses>;
  /** The agent assigned to the request */
  Agent?: Maybe<GraphqlAgent>;
  /** Appointment requests associated with this service request */
  AppointmentRequests?: Maybe<Array<GraphqlAppointmentRequest>>;
  /** The user that cancelled the service  */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The user that created the request */
  CreatedBy: GraphqlUser;
  /** The customer related to the request */
  Customer: GraphqlCustomer;
  /** Internal notes associated with this service request */
  InternalNotes?: Maybe<Array<GraphqlInternalNote>>;
  /** The category associated to the request */
  RequestCategory?: Maybe<GraphqlRequestCategory>;
  /** The user that asked for the request */
  RequestedBy: GraphqlUser;
  /** The payment information related to the service */
  ServiceRequestPayments?: Maybe<Array<GraphqlServiceRequestPayment>>;
  /** The timeline events related to the requested */
  Timeline?: Maybe<Array<GraphqlTimeline>>;
  /** The date at which the agent was assigned to the request */
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actionNeeded?: Maybe<Scalars['String']['output']>;
  /** Address id related to where the service was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** The agent id that will attend the request */
  agentId?: Maybe<Scalars['Int']['output']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities: Array<ServiceRequestAvailability>;
  /** The reason the service request was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** The category of this service request */
  category: ServiceRequestCategoriesEnum;
  /** Pro comment that the only brya can see */
  commentForBrya?: Maybe<Scalars['String']['output']>;
  /** Pro comment that the customer can see */
  commentForCustomer?: Maybe<Scalars['String']['output']>;
  /** The date the request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the request */
  createdBy: Scalars['Int']['output'];
  /** The customer id related to this service request */
  customerId: Scalars['Int']['output'];
  /** Description of the request made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** Specifies if the user has flexible availability for scheduling the request */
  isAvailabilityFlexible: Scalars['Boolean']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: Maybe<Scalars['String']['output']>;
  /** Wheter or not is the PVS was sent or not */
  postVisitSummarySent?: Maybe<Scalars['Boolean']['output']>;
  /** Marks service request as recurring */
  recurring: Scalars['Boolean']['output'];
  /** The reason the service request was rejected */
  rejectedReason?: Maybe<Scalars['String']['output']>;
  /** The type of the service request */
  requestType: ServiceRequestType;
  /** The user id that asked for the request */
  requestedBy: Scalars['Int']['output'];
  requiresAction?: Maybe<Scalars['Boolean']['output']>;
  /** The ride information for the service request */
  ride?: Maybe<Ride>;
  /** The date at which the agent will attend the request */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Service fee percentage */
  serviceFee?: Maybe<Scalars['Float']['output']>;
  /** The current status of the service request */
  status: ServiceRequestStatus;
  /** Tax fee percentage */
  taxFee?: Maybe<Scalars['Float']['output']>;
  tentativeSchedule: ServiceRequestTentativeSchedule;
  /** The date the request was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlServiceRequestPayment = {
  __typename?: 'GraphqlServiceRequestPayment';
  StripeTransaction?: Maybe<GraphqlStripeTransaction>;
  /** The amount that is being used as base to calculate who much the service will cost */
  amount: Scalars['Float']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The estimate hours the service should take to be completed */
  estimateHours?: Maybe<Scalars['Float']['output']>;
  /** The amount that is being charged as a service fee */
  fee: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  serviceRequestId: Scalars['Int']['output'];
  stripePaymentMethodId?: Maybe<Scalars['String']['output']>;
  stripeTransactionId?: Maybe<Scalars['String']['output']>;
  /** The amount that is being charged as state tax */
  tax: Scalars['Float']['output'];
  /** The type used to charge the service */
  type: ServicePaymentType;
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlServiceRequestReview = {
  __typename?: 'GraphqlServiceRequestReview';
  /** The user that made the review */
  ReviewedBy: GraphqlUser;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The negative tags of the review */
  negativeTags: Array<PositiveTags>;
  /** The positive tags of the review */
  positiveTags: Array<PositiveTags>;
  rating: Scalars['Int']['output'];
  reviewedBy: Scalars['Int']['output'];
  serviceRequestCategory?: Maybe<Scalars['String']['output']>;
  serviceRequestCategoryId?: Maybe<Scalars['String']['output']>;
  serviceRequestId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlSocialEvent = {
  __typename?: 'GraphqlSocialEvent';
  /** The address where the event is going to occur */
  Address?: Maybe<GraphqlAddresses>;
  /** The user that cancelled the event */
  CancelledBy?: Maybe<GraphqlUser>;
  /** The participants of the event who represent Brya */
  Coaches: Array<GraphqlCoaches>;
  /** The user that created the event */
  CreatedBy: GraphqlUser;
  /** The participants of the event */
  Participants: Array<GraphqlParticipants>;
  /** The user that asked for the event */
  RequestedBy: GraphqlUser;
  /** Address id related to where the event was requested */
  addressId?: Maybe<Scalars['Int']['output']>;
  /** Location address line 1 */
  addressLine1: Scalars['String']['output'];
  /** Location address line 2 */
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** The reason the event was cancelled */
  cancellationReason?: Maybe<Scalars['String']['output']>;
  /** Location address city name */
  city: Scalars['String']['output'];
  /** Location address country name */
  country: Scalars['String']['output'];
  /** The date the event was created */
  createdAt: Scalars['DateTime']['output'];
  /** The user id that created the event */
  createdBy: Scalars['Int']['output'];
  /** Description of the event made by the user */
  description?: Maybe<Scalars['String']['output']>;
  /** The event name */
  eventName: Scalars['String']['output'];
  /** Recurring Frequency */
  frequency?: Maybe<RecurringFrequencyType>;
  id: Scalars['Int']['output'];
  /** The last time the status was updated */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /** Location place name */
  place?: Maybe<Scalars['String']['output']>;
  /** Whether the event is a recurring event or not */
  recurring: Scalars['Boolean']['output'];
  /** The type of the event */
  requestType: ServiceRequestType;
  /** The user id that asked for the event */
  requestedBy: Scalars['Int']['output'];
  /** The date where the event will occur */
  scheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Location address state name */
  state: Scalars['String']['output'];
  /** The current status of the event */
  status: SocialEventStatus;
  /** The date the event was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Location address ZIP code */
  zipCode: Scalars['String']['output'];
};

export type GraphqlStripeCard = {
  __typename?: 'GraphqlStripeCard';
  brand: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  exp_month: Scalars['Int']['output'];
  exp_year: Scalars['Int']['output'];
  funding: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type GraphqlStripeConnectAccount = {
  __typename?: 'GraphqlStripeConnectAccount';
  /** The ID of the company that the stripe account belongs to */
  companyId: Scalars['Int']['output'];
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The account status */
  status: StripeConnectAccountStatus;
  stripeAccountId: Scalars['String']['output'];
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeCustomerAccount = {
  __typename?: 'GraphqlStripeCustomerAccount';
  /** The date the account was created */
  createdAt: Scalars['DateTime']['output'];
  /** The date the account was removed */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  /** The date the account was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The ID of the user that the stripe customer account belongs to */
  userId: Scalars['Int']['output'];
};

export type GraphqlStripeSubscription = {
  __typename?: 'GraphqlStripeSubscription';
  /** The customer information */
  Customer: GraphqlCustomer;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  /** The date the subscription was created */
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  stripeSubscriptionItemId: Scalars['String']['output'];
  stripeSubscriptionPriceId: Scalars['String']['output'];
  /** The date the subscription was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeSubscriptionPrice = {
  __typename?: 'GraphqlStripeSubscriptionPrice';
  active: Scalars['Boolean']['output'];
  /** The date the price price was created */
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  /** Id of price related to a Stripe Subscription product */
  stripePriceId: Scalars['String']['output'];
  stripeProductId: Scalars['String']['output'];
  type: Scalars['String']['output'];
  /** The date the product price was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlStripeTransaction = {
  __typename?: 'GraphqlStripeTransaction';
  /** The Service Request information */
  Activity?: Maybe<GraphqlServiceRequest>;
  /** The stripe payment intent information */
  StripeTransaction?: Maybe<StripePaymentIntent>;
  activityId: Scalars['Int']['output'];
  amount: Scalars['Float']['output'];
  /** The date the transaction was created */
  createdAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  stripeConnectAccountId?: Maybe<Scalars['String']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  stripeTransactionId: Scalars['String']['output'];
  /** The date the transaction was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

export type GraphqlSuggestedActivities = {
  __typename?: 'GraphqlSuggestedActivities';
  /** The user that created the suggested activity */
  User: GraphqlUser;
  /** The date the suggestion was created */
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** Internal notes related to the suggested activity */
  notes?: Maybe<Scalars['String']['output']>;
  /** Whether the user wants to be a host or a participant */
  role: SuggestedActivitiesRoleEnum;
  userId: Scalars['Int']['output'];
};

export type GraphqlTimeline = {
  __typename?: 'GraphqlTimeline';
  /** The customer information related to the timeline event */
  Customer?: Maybe<GraphqlCustomer>;
  /** The user information related to the timeline event */
  User?: Maybe<GraphqlUser>;
  /** The activity id related to this timeline */
  activityId?: Maybe<Scalars['Int']['output']>;
  /** The date the timeline has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id related to this timeline */
  customerId?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** True if needs to be shown in the app */
  isAppVisible: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  /** The user id related to this timeline */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUser = {
  __typename?: 'GraphqlUser';
  /** The last date the user logged in */
  Agent?: Maybe<GraphqlAgent>;
  /** The app features available for this user */
  AppFeaturesSelected?: Maybe<GraphqlAppFeaturesSelected>;
  /** The customer that represents this user */
  OwnCustomer?: Maybe<GraphqlCustomer>;
  /** The current customer selected by the user */
  SelectedCustomer?: Maybe<GraphqlCustomer>;
  /** The roles related for this user */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** User bio */
  bio?: Maybe<Scalars['String']['output']>;
  /** The date the user account has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The default tab that should be opened when the user logs in in the mobile application */
  defaultTab?: Maybe<DefaultTab>;
  /** The user account status, whether it's disabled or not */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The user email address */
  email?: Maybe<Scalars['String']['output']>;
  /** The user email varified status */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** The emergency contact, available for brya employees (coaches and support) */
  emergencyContact?: Maybe<UserEmergencyContact>;
  /** The firebase token associated with this user */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  /** The user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** If the user is marked as a circle member or not */
  isCircleMember?: Maybe<Scalars['Boolean']['output']>;
  /** The user language */
  language?: Maybe<Scalars['String']['output']>;
  /** The last date the user logged in */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer id associated with the user */
  ownCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The user profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** The role of the user */
  role?: Maybe<GlobalRole>;
  /** The user current selected customer */
  selectedCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user talkJs id for the chat */
  talkJsId?: Maybe<Scalars['String']['output']>;
  /** The last date the user account has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Push notification subscription object */
  webPushSubscription?: Maybe<Scalars['String']['output']>;
};

export type GraphqlUserDevice = {
  __typename?: 'GraphqlUserDevice';
  /** The date the user device has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The firebase token */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The date the user device has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id */
  userId: Scalars['Float']['output'];
  /** The user device unique id */
  uuid: Scalars['String']['output'];
};

export type GraphqlUserInvite = {
  __typename?: 'GraphqlUserInvite';
  /** The customer information */
  Customer: GraphqlCustomer;
  /** The date the user invitation has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id */
  customerId: Scalars['Int']['output'];
  /** The person email to whom the invite was sent */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The type of invite, familyPartner or circleMember */
  inviteType: UserInvitesType;
  /** The person who sent the invitation */
  invitedBy?: Maybe<Scalars['Int']['output']>;
  /** The person name to whom the invite was sent */
  name?: Maybe<Scalars['String']['output']>;
  /** The phone number lto whom the invite was sent */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  sentVia: Array<Scalars['String']['output']>;
  status: UserInvitesStatus;
  /** The user id to whom the invite was sent */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUserRequest = {
  __typename?: 'GraphqlUserRequest';
  /** The customer related to the request */
  Customer: GraphqlCustomer;
  /** The date the use request was created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer that received the request */
  customerId?: Maybe<Scalars['Int']['output']>;
  /** The person email to whom the invite was sent */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** The person name to whom the invite was sent */
  name?: Maybe<Scalars['String']['output']>;
  /** The phone number to whom the invite was sent */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The type of invite, familyPartner or circleMember */
  requestType: UserInvitesType;
  /** The person who sent the invitation */
  requestedBy: Scalars['Int']['output'];
  sentVia: Array<Scalars['String']['output']>;
  status: UserInvitesStatus;
  /** The user that received the request */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type GraphqlUserRole = {
  __typename?: 'GraphqlUserRole';
  /** The user associated */
  Users?: Maybe<GraphqlUser>;
  /** The date the user role has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The customer id related to this role */
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** The user role */
  role: Role;
  /** The last date the user role has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The user id related to this role */
  userId: Scalars['Int']['output'];
};

export type GraphqlVisitSummary = {
  __typename?: 'GraphqlVisitSummary';
  /** Associated Coach Visit */
  CoachVisit?: Maybe<GraphqlCoachVisit>;
  /** The user who created the Visit Summary */
  CreatedBy?: Maybe<GraphqlUser>;
  /** The users to whom the summary was sent */
  SentTo?: Maybe<Array<GraphqlUser>>;
  /** The user who most recently modified the Visit Summary */
  UpdatedBy?: Maybe<GraphqlUser>;
  /** Visit summary body text */
  body: Scalars['String']['output'];
  /** Associated coach visit id */
  coachVisitId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['Int']['output'];
};

/** The options to filter internal notes */
export type InternalNotesFilterOptions = {
  /** Matches coach visit */
  coachVisitId?: InputMaybe<Scalars['Float']['input']>;
  /** Matches employee */
  employeeId?: InputMaybe<Scalars['Float']['input']>;
  /** Matches service request */
  serviceRequestId?: InputMaybe<Scalars['Float']['input']>;
};

export type InternalNotesSortOptions = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: InternalNotesSortableFields;
};

/** The possible fields that internal notes can be sorted */
export enum InternalNotesSortableFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type LoginUserOutput = {
  __typename?: 'LoginUserOutput';
  accessToken: Scalars['String']['output'];
};

export type LogoutUserOutput = {
  __typename?: 'LogoutUserOutput';
  success: Scalars['Boolean']['output'];
};

/** The management roles */
export enum ManagementRole {
  Admin = 'admin',
  Coach = 'coach',
  Support = 'support',
}

export type Message = {
  __typename?: 'Message';
  attachment?: Maybe<MessageAttachment>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['Float']['output'];
  editedAt?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Array<Scalars['Float']['output']>>;
  origin: Scalars['String']['output'];
  readBy: Array<Scalars['String']['output']>;
  referencedMessageId?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  size: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a new management user in the application */
  adminCreateUser: GraphqlUser;
  /** Update the details of a specific admin user */
  adminUpdateUser: GraphqlUser;
  /** answer user request (change status) */
  answerRequest: ResponseUserRequestOutput;
  /** Assign a coach to a customer */
  assignCoach: GraphqlUserRole;
  /** Blocks a user */
  blockUser: GraphqlBlockedUsers;
  /** Cancel a specific transaction that is on hold */
  cancelStripeTransaction: GraphqlStripeTransaction;
  /** Confirms a stripe transaction with a payment method. This triggers the transaction to be charged or to authorize the amount and be later captured. It can also be used to retry to pay/hold failed transactions */
  confirmStripeTransaction: ConfirmStripeTransactionOutput;
  /** Cancel any scheduled job for extra charge and change status of service to paymentWaitingForSupport */
  contactSupportForExtraCharge: GraphqlServiceRequest;
  /** Create a new address for a customer */
  createAddress: GraphqlAddresses;
  /** Creates a new agent in the application */
  createAgent: GraphqlAgent;
  createAppointmentRequest: Array<GraphqlAppointmentRequest>;
  /** Create a appointment request payment */
  createAppointmentRequestPayment: GraphqlAppointmentRequestPayment;
  /** Creates a circle request */
  createCircleRequest: GraphqlCircleRequest;
  /** Create a coach visit */
  createCoachVisit: GraphqlCoachVisit;
  /** Create a new company */
  createCompany: GraphqlCompany;
  /** Create and get the stripe link to create a connect account or generate a login link */
  createConnectAccountOrLoginLink: Scalars['String']['output'];
  /** Create a new contact request */
  createContactRequest: GraphqlContactRequest;
  /** Create a new customer in the application */
  createCustomer: GraphqlCustomer;
  /** Creates a customer and the additional information associated with it, if there is something wrong nothing is created */
  createCustomerWithAdditionalInformation: GraphqlCustomer;
  /** Creates a customer and the address associated with it, if there is something wrong nothing is created */
  createCustomerWithAddress: GraphqlCustomer;
  /** Generate an expiring token to be used to login the user in the mobile app */
  createExpiringToken: CreateExpiringTokenOutput;
  /** Creates a new feature flag */
  createFeatureFlag: GraphqlFeatureFlag;
  createInternalNote: GraphqlInternalNote;
  /** Create a new, generic, notification to be sent */
  createNotification: NotificationOperationOutput;
  createRequestCategory: GraphqlRequestCategory;
  /** Create a service request */
  createServiceRequest: GraphqlServiceRequest;
  /** Create a service request payment */
  createServiceRequestPayment: GraphqlServiceRequestPayment;
  /** Allow agent to create the summary of a completed service request */
  createServiceRequestSummary: GraphqlServiceRequest;
  /** Create a social event */
  createSocialEvent: GraphqlSocialEvent;
  /** Create a customer stripe account */
  createStripeCustomerAccount: GraphqlStripeCustomerAccount;
  /** Create a customer stripe account */
  createStripeSubscription: CreateStripeSubscriptionOutput;
  /** Create a customer stripe account */
  createStripeSubscriptionPrice: GraphqlStripeSubscriptionPrice;
  /** Create a new suggested activities for a user */
  createSuggestedActivity: GraphqlSuggestedActivities;
  /** Creates a new timeline event in the application */
  createTimelineEvent: GraphqlTimeline;
  /** Create a new user in the application */
  createUser: GraphqlUser;
  /** Assign a coach to a customer */
  createUserCustomerRoleConnection: GraphqlUserRole;
  /** Creates a new circle invite and send email to the user by email or phone number */
  createUserInvites: CreateUserInvitesOutput;
  /** Creates a new user request and sent it by push notification */
  createUserRequest: CreateUserInvitesOutput;
  createVisitSummary: GraphqlVisitSummary;
  /** Subscribe to web push notifications based on your role */
  createWebPushSubscription: Scalars['Boolean']['output'];
  /** Deletes the user account and all related information */
  deleteAccount: DeleteAccountOutput;
  /** Delete an address */
  deleteAddress: DeleteAddressOutput;
  /** Soft-delete an agent */
  deleteAgent: DeleteAgentOutput;
  deleteCoachReview: DeleteReviewOutput;
  /** Delete a conversation by id */
  deleteConversation: DeleteConversationOutput;
  deleteInternalNote: Scalars['Boolean']['output'];
  /** Delete a notification for a user */
  deleteNotification: NotificationOperationOutput;
  deleteRequestCategory: Scalars['Boolean']['output'];
  /** Delete a suggested activity */
  deleteSuggestedActivity: DeleteSuggestedActivityOutput;
  /** Delete a user invite by id */
  deleteUserInvite: DeleteUserInvitesOutput;
  /** Delete a user request by id */
  deleteUserRequest: ResponseUserRequestOutput;
  deleteVisitSummary: Scalars['Boolean']['output'];
  /** Unsubscribe from web push notifications */
  destroyWebPushSubscription: Scalars['Boolean']['output'];
  /** Disable a specific user account */
  disableUser: GraphqlUser;
  /** Enable a specific user account */
  enableUser: GraphqlUser;
  /** Exchange the authId to an accessToken, to login the user in the mobile app */
  exchangeAuthId: ExchangeAuthIdOutput;
  /** Exchange the expiring token to an access token, and login the user in the mobile app */
  exchangeExpiringToken: ExchangeExpiringTokenOutput;
  /** Marks agent as favorite */
  favoriteAgent: GraphqlCustomerAgent;
  /** Set the generated authId to finished, as the login flow has been completed in the web application */
  finishAuthId: FinishAuthIdOutput;
  /** Send an email to the user to reset the account password */
  forgotPassword: ResetPasswordOutput;
  /** Generate an authId and secureToken to be used in the mobile app to login the user */
  generateAuthId: GenerateAuthIdOutput;
  /** The list of roles of the logged in user */
  getRoles: GraphqlUserRole;
  /** Join a circle */
  joinCircle: GraphqlUserInvite;
  /** Joins the current logged in management user to the conversation specified */
  joinConversation: Conversation;
  /** Joins the specified user to the conversation */
  joinUserToConversation: Conversation;
  /** Leave circle as a member */
  leaveCircle: DeleteUserInvitesOutput;
  /** Leave circle as a family partner */
  leaveCircleAsFamilyPartner: DeleteUserInvitesOutput;
  /** Log the user in the application */
  login: LoginUserOutput;
  /** Logout the user */
  logout: LogoutUserOutput;
  /** Add the email to the list of users that want to be notified when the Brya app is available */
  notifyMe: NotifyMeOutput;
  /** Mark all user notifications as read */
  readAllUserNotifications: NotificationOperationOutput;
  /** Mark notifications as read */
  readNotifications: NotificationOperationOutput;
  /** Returns a new token for the logged in user */
  refreshAccessToken: LoginUserOutput;
  /** Remove members from circle */
  removeMembersFromCircle: DeleteUserInvitesOutput;
  /** Removes a participant from a conversation */
  removeParticipant: Conversation;
  /** Assign a coach to a customer */
  removeUserCustomerRoleConnection: GraphqlUserRole;
  /** Creates a request to join somebody circle if the customer exists */
  requestToJoinCircle: RequestToJoinCircleOutput;
  /** Tries to request to join a family group if there is a existing customer with information provided */
  requestToJoinFamilyGroup: RequestToJoinFamilyGroupOutput;
  /** Resend a user invite */
  resendUserInvite: DeleteUserInvitesOutput;
  /** Send a new verification account email to the user */
  resendVerifyEmail: ResendVerifyEmailOutput;
  /** Update the user account password */
  resetPassword: LoginUserOutput;
  reviewCoach: GraphqlCoachReview;
  reviewServiceRequest: GraphqlServiceRequestReview;
  /** Save user firebase token for a device */
  saveFirebaseToken: GraphqlUserDevice;
  /** Save a notification setting for the user logged in */
  saveUserNotificationSettings: GraphqlNotificationSettings;
  /** Creates a request to a not existing user */
  sendRequestToNotExistingUser: SendRequestToNotExistingUserOutput;
  /** Creates or updates the app features selected for the given user */
  setAppFeaturesSelected: GraphqlAppFeaturesSelected;
  /** Sets the user current selected customer */
  setUserSelectedCustomer: GraphqlUser;
  /** Creates a conversation between the logged in user and users provided */
  startConversation: Conversation;
  /** Switch the role of the logged in user */
  switchUserRole: LoginUserOutput;
  /** Syncs the chat of a specific customer */
  syncCustomerChat: SyncUserChatOutput;
  /** Add or remove a specific user to the list of members a user trusts */
  trustUntrustMembers: TrustUntrustMembersOutput;
  /** Unblocks a user */
  unblockUser: GraphqlBlockedUsers;
  /** Unmarks agent as favorite */
  unfavoriteAgent: GraphqlCustomerAgent;
  /** Mark all user notifications as unread */
  unreadAllUserNotifications: NotificationOperationOutput;
  /** Mark notifications as unread */
  unreadNotifications: NotificationOperationOutput;
  /** Update the details of an address */
  updateAddress: GraphqlAddresses;
  /** Update the details of a specific agent */
  updateAgent: GraphqlAgent;
  updateAppointmentRequest: GraphqlAppointmentRequest;
  /** Update a appointment request payment */
  updateAppointmentRequestPayment: GraphqlAppointmentRequestPayment;
  /** Update request by id */
  updateCircleRequest: GraphqlCircleRequest;
  /** Allow user to update the schedule time of a circle request */
  updateCircleRequestTime: GraphqlCircleRequest;
  /** Change coach detail information  */
  updateCoachProfile: GraphqlUser;
  /** Update a coach visit by id */
  updateCoachVisit: GraphqlCoachVisit;
  /** Update a coach visit status by id */
  updateCoachVisitStatus: GraphqlCoachVisit;
  /** Update company */
  updateCompany: GraphqlCompany;
  /** Update a given contact request */
  updateContactRequest: GraphqlContactRequest;
  /** Update the details of the current user */
  updateCurrentUser: GraphqlUser;
  /** Update the details of a specific customer */
  updateCustomer: GraphqlCustomer;
  updateInternalNote: GraphqlInternalNote;
  updateRequestCategory: GraphqlRequestCategory;
  /** Update service request by id */
  updateServiceRequest: GraphqlServiceRequest;
  /** Update a service request payment */
  updateServiceRequestPayment: GraphqlServiceRequestPayment;
  /** Allow user to update the schedule time of a service request */
  updateServiceRequestTime: GraphqlServiceRequest;
  /** Update a social event by id */
  updateSocialEvent: GraphqlSocialEvent;
  /** Update a social event status by id */
  updateSocialEventStatus: GraphqlSocialEvent;
  /** Update the customer default card in stripe */
  updateStripeCustomerDefaultCard: UpdateStripeCustomerDefaultCardOutput;
  /** Update the details of a suggested activity */
  updateSuggestedActivity: GraphqlSuggestedActivities;
  updateVisitSummary: GraphqlVisitSummary;
  /** Allow user to approve or reject a service request */
  userApprovalServiceRequest: GraphqlServiceRequest;
  /** Allow user to approve or reject a social event */
  userApprovalSocialEvent: GraphqlSocialEvent;
  /** Allow user to cancel a circle request */
  userCancelCircleRequest: GraphqlCircleRequest;
  /** Allow user to cancel a service request */
  userCancelServiceRequest: GraphqlServiceRequest;
  /** Verify the user account email */
  verifyEmail: VerifyEmailOutput;
};

export type MutationAdminCreateUserArgs = {
  adminCreateUserInput: AdminCreateUserInput;
};

export type MutationAdminUpdateUserArgs = {
  adminUpdateUserInput: AdminUpdateUserInput;
  id: Scalars['Int']['input'];
};

export type MutationAnswerRequestArgs = {
  status: UserInvitesStatus;
  userRequestId: Scalars['Int']['input'];
};

export type MutationAssignCoachArgs = {
  customerId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationBlockUserArgs = {
  blockedUserId: Scalars['Int']['input'];
};

export type MutationCancelStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationConfirmStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationContactSupportForExtraChargeArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type MutationCreateAddressArgs = {
  createAddressInput: CreateAddressInput;
};

export type MutationCreateAgentArgs = {
  createAgentInput: CreateAgentInput;
};

export type MutationCreateAppointmentRequestArgs = {
  circleRequestId?: InputMaybe<Scalars['Int']['input']>;
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type MutationCreateAppointmentRequestPaymentArgs = {
  appointmentPaymentInput: CreateAppointmentPaymentInput;
};

export type MutationCreateCircleRequestArgs = {
  data: CreateCircleRequestInput;
};

export type MutationCreateCoachVisitArgs = {
  data: CreateCoachVisitInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateContactRequestArgs = {
  createContactRequestInput: CreateContactRequestInput;
};

export type MutationCreateCustomerArgs = {
  createCustomerInput: CreateCustomerInput;
  role: AppUser;
};

export type MutationCreateCustomerWithAdditionalInformationArgs = {
  customer: CreateCustomerWithAdditionalInformationInput;
  role: AppUser;
};

export type MutationCreateCustomerWithAddressArgs = {
  address: RideAddressInput;
  customer: CreateCustomerInput;
  role: AppUser;
};

export type MutationCreateExpiringTokenArgs = {
  seconds: Scalars['Int']['input'];
};

export type MutationCreateFeatureFlagArgs = {
  createFeatureFlagInput: CreateFeatureFlagInput;
};

export type MutationCreateInternalNoteArgs = {
  input: CreateInternalNoteInput;
};

export type MutationCreateNotificationArgs = {
  notification: CreateNotificationInput;
  sendEmail: Scalars['Boolean']['input'];
  sendPush: Scalars['Boolean']['input'];
  sendSms: Scalars['Boolean']['input'];
  sendWebpush: Scalars['Boolean']['input'];
};

export type MutationCreateRequestCategoryArgs = {
  data: CreateRequestCategoryInput;
};

export type MutationCreateServiceRequestArgs = {
  data: CreateServiceRequestInput;
};

export type MutationCreateServiceRequestPaymentArgs = {
  servicePaymentInput: CreateServicePaymentInput;
};

export type MutationCreateServiceRequestSummaryArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  commentForBrya: Scalars['String']['input'];
  commentForCustomer: Scalars['String']['input'];
  hours?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId: Scalars['Int']['input'];
  visitPaymentType?: InputMaybe<ServiceRequestPaymentSummaryVisitType>;
};

export type MutationCreateSocialEventArgs = {
  data: CreateSocialEventInput;
};

export type MutationCreateStripeSubscriptionArgs = {
  stripeSubscriptionPriceId: Scalars['String']['input'];
};

export type MutationCreateStripeSubscriptionPriceArgs = {
  createSubscriptionPriceInput: CreateStripeSubscriptionPrice;
};

export type MutationCreateSuggestedActivityArgs = {
  createSuggestedActivityInput: CreateSuggestedActivityInput;
};

export type MutationCreateTimelineEventArgs = {
  createTimelineInput: CreateTimelineInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
  inviteToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateUserCustomerRoleConnectionArgs = {
  customerId: Scalars['Int']['input'];
  role: CircleRoles;
  userId: Scalars['Int']['input'];
};

export type MutationCreateUserInvitesArgs = {
  userInvitesInput: CreateUserInviteInput;
};

export type MutationCreateUserRequestArgs = {
  userInvitesInput: CreateUserRequestInput;
};

export type MutationCreateVisitSummaryArgs = {
  input: CreateVisitSummaryInput;
};

export type MutationCreateWebPushSubscriptionArgs = {
  webPushSubscription: WebPushSubscription;
};

export type MutationDeleteAddressArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteAgentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteCoachReviewArgs = {
  reviewId: Scalars['Int']['input'];
};

export type MutationDeleteConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type MutationDeleteInternalNoteArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteNotificationArgs = {
  notificationId: Scalars['Float']['input'];
};

export type MutationDeleteRequestCategoryArgs = {
  name: Scalars['String']['input'];
};

export type MutationDeleteSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Int']['input'];
};

export type MutationDeleteUserInviteArgs = {
  userInviteId: Scalars['Int']['input'];
};

export type MutationDeleteUserRequestArgs = {
  userRequestId: Scalars['Int']['input'];
};

export type MutationDeleteVisitSummaryArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDestroyWebPushSubscriptionArgs = {
  data: UnsubscribeInput;
};

export type MutationDisableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationEnableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationExchangeAuthIdArgs = {
  authId: Scalars['String']['input'];
  secureToken: Scalars['String']['input'];
};

export type MutationExchangeExpiringTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationFavoriteAgentArgs = {
  agentId: Scalars['Int']['input'];
  serviceType: ServiceRequestCategoriesEnum;
};

export type MutationFinishAuthIdArgs = {
  authId: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationJoinCircleArgs = {
  id: Scalars['Int']['input'];
  status: UserInvitesStatus;
};

export type MutationJoinConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type MutationJoinUserToConversationArgs = {
  conversationId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  inviteToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type MutationLogoutArgs = {
  deviceId: Scalars['String']['input'];
};

export type MutationNotifyMeArgs = {
  email: Scalars['String']['input'];
};

export type MutationReadAllUserNotificationsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReadNotificationsArgs = {
  notificationsIds: Array<Scalars['Int']['input']>;
};

export type MutationRemoveMembersFromCircleArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  userIds: Array<Scalars['Int']['input']>;
};

export type MutationRemoveParticipantArgs = {
  conversationId: Scalars['String']['input'];
  talkJsId: Scalars['String']['input'];
};

export type MutationRemoveUserCustomerRoleConnectionArgs = {
  customerId: Scalars['Int']['input'];
  role: CircleRoles;
  userId: Scalars['Int']['input'];
};

export type MutationRequestToJoinCircleArgs = {
  requestToJoinCircleInput: RequestToJoinCircleInput;
};

export type MutationRequestToJoinFamilyGroupArgs = {
  requestToJoinFamilyGroupInput: RequestToJoinFamilyGroupInput;
};

export type MutationResendUserInviteArgs = {
  userInviteData: UserInviteResendInput;
};

export type MutationResendVerifyEmailArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type MutationReviewCoachArgs = {
  data: CreateCoachReviewInput;
};

export type MutationReviewServiceRequestArgs = {
  data: CreateServiceRequestReviewInput;
};

export type MutationSaveFirebaseTokenArgs = {
  firebaseToken: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type MutationSaveUserNotificationSettingsArgs = {
  saveNotificationSettingsInput: SaveNotificationSettingsInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSendRequestToNotExistingUserArgs = {
  requestToNotExistingUserInput: RequestToNotExistingUserInput;
};

export type MutationSetAppFeaturesSelectedArgs = {
  appFeaturesSelected: SelectAppFeaturesInput;
};

export type MutationSetUserSelectedCustomerArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationStartConversationArgs = {
  startConversationInput: StartConversationInput;
};

export type MutationSwitchUserRoleArgs = {
  customerId: Scalars['Int']['input'];
  role: Role;
};

export type MutationSyncCustomerChatArgs = {
  customerId: Scalars['Float']['input'];
};

export type MutationTrustUntrustMembersArgs = {
  action: TrustUntrustActionType;
  trustedMembersIds: Array<Scalars['Int']['input']>;
};

export type MutationUnblockUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUnfavoriteAgentArgs = {
  agentId: Scalars['Int']['input'];
  serviceType: ServiceRequestCategoriesEnum;
};

export type MutationUnreadAllUserNotificationsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUnreadNotificationsArgs = {
  notificationsIds: Array<Scalars['Int']['input']>;
};

export type MutationUpdateAddressArgs = {
  id: Scalars['Int']['input'];
  updateAddressInput: UpdateAddressInput;
};

export type MutationUpdateAgentArgs = {
  id: Scalars['Int']['input'];
  updateAgentInput: UpdateAgentInput;
};

export type MutationUpdateAppointmentRequestArgs = {
  updateAppointmentRequestInput: UpdateAppointmentRequestInput;
};

export type MutationUpdateAppointmentRequestPaymentArgs = {
  appointmentPaymentInput: UpdateAppointmentPaymentInput;
  appointmentRequestPaymentId: Scalars['Int']['input'];
};

export type MutationUpdateCircleRequestArgs = {
  data: UpdateCircleRequestInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateCircleRequestTimeArgs = {
  circleRequestId: Scalars['Int']['input'];
  newTime: Scalars['DateTime']['input'];
  untilTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationUpdateCoachProfileArgs = {
  coachInfo: CoachInfoInput;
};

export type MutationUpdateCoachVisitArgs = {
  coachVisitId: Scalars['Int']['input'];
  data: UpdateCoachVisitInput;
};

export type MutationUpdateCoachVisitStatusArgs = {
  action: SocialEventStatus;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  coachVisitId: Scalars['Int']['input'];
};

export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
  id: Scalars['Float']['input'];
};

export type MutationUpdateContactRequestArgs = {
  contactRequestId: Scalars['Int']['input'];
  updateContactRequestInput: UpdateContactRequestInput;
};

export type MutationUpdateCurrentUserArgs = {
  updateUserInput: UpdateCurrentUserInput;
};

export type MutationUpdateCustomerArgs = {
  customerId: Scalars['Int']['input'];
  updateCustomerInput: UpdateCustomerInput;
};

export type MutationUpdateInternalNoteArgs = {
  id: Scalars['Float']['input'];
  input: CreateInternalNoteInput;
};

export type MutationUpdateRequestCategoryArgs = {
  data: UpdateRequestCategoryInput;
};

export type MutationUpdateServiceRequestArgs = {
  data: UpdateServiceRequestInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdateServiceRequestPaymentArgs = {
  servicePaymentInput: UpdateServicePaymentInput;
  serviceRequestPaymentId: Scalars['Int']['input'];
};

export type MutationUpdateServiceRequestTimeArgs = {
  newTime: Scalars['DateTime']['input'];
  serviceRequestId: Scalars['Int']['input'];
  untilTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationUpdateSocialEventArgs = {
  data: UpdateSocialEventInput;
  socialEventId: Scalars['Int']['input'];
};

export type MutationUpdateSocialEventStatusArgs = {
  action: SocialEventStatus;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  socialEventId: Scalars['Int']['input'];
};

export type MutationUpdateStripeCustomerDefaultCardArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationUpdateSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Int']['input'];
  updateSuggestedActivityInput: UpdateSuggestedActivityInput;
};

export type MutationUpdateVisitSummaryArgs = {
  body: Scalars['String']['input'];
  id: Scalars['Float']['input'];
};

export type MutationUserApprovalServiceRequestArgs = {
  action: ApprovalServiceRequestStatus;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  serviceRequestId: Scalars['Int']['input'];
};

export type MutationUserApprovalSocialEventArgs = {
  action: ApprovalServiceRequestStatus;
  customerId: Scalars['Int']['input'];
  socialEventId: Scalars['Int']['input'];
};

export type MutationUserCancelCircleRequestArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  circleRequestId: Scalars['Int']['input'];
};

export type MutationUserCancelServiceRequestArgs = {
  cancellationReason: Scalars['String']['input'];
  serviceRequestId: Scalars['Int']['input'];
};

export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

/** All notifications categories in the application */
export enum NotificationCategories {
  AppointmentCirclesPrior30MinReminder = 'appointmentCirclesPrior30MinReminder',
  AppointmentServicesPrior30MinReminder = 'appointmentServicesPrior30MinReminder',
  CircleMember = 'circleMember',
  CircleMemberInvite = 'circleMemberInvite',
  CircleMemberInviteAccepted = 'circleMemberInviteAccepted',
  CircleMemberRequest = 'circleMemberRequest',
  CircleRequest = 'circleRequest',
  CircleRequestAccepted = 'circleRequestAccepted',
  CircleRequestAllMembersDeclined = 'circleRequestAllMembersDeclined',
  CircleRequestCancelled = 'circleRequestCancelled',
  CircleRequestConfirmationNeededReminder = 'circleRequestConfirmationNeededReminder',
  CircleRequestMemberUnavailable = 'circleRequestMemberUnavailable',
  CircleRequestNew = 'circleRequestNew',
  CircleRequestTimeChanged = 'circleRequestTimeChanged',
  CoachVisit = 'coachVisit',
  CoachVisitCancelled = 'coachVisitCancelled',
  CoachVisitNoteCreated = 'coachVisitNoteCreated',
  CoachVisitScheduled = 'coachVisitScheduled',
  CoachVisitUpdated = 'coachVisitUpdated',
  FamilyPartnerInvite = 'familyPartnerInvite',
  FamilyPartnerInviteAccepted = 'familyPartnerInviteAccepted',
  FamilyPartnerRequest = 'familyPartnerRequest',
  General = 'general',
  PostVisitSummaryProReminder = 'postVisitSummaryProReminder',
  ServiceRequest = 'serviceRequest',
  ServiceRequestAuthorizationFailed = 'serviceRequestAuthorizationFailed',
  ServiceRequestCancelled = 'serviceRequestCancelled',
  ServiceRequestCancelledByPro = 'serviceRequestCancelledByPro',
  ServiceRequestCancelledPaymentFailed = 'serviceRequestCancelledPaymentFailed',
  ServiceRequestConfirmationNeeded = 'serviceRequestConfirmationNeeded',
  ServiceRequestConfirmationNeededReminder = 'serviceRequestConfirmationNeededReminder',
  ServiceRequestConfirmedBySm = 'serviceRequestConfirmedBySM',
  ServiceRequestExtraCharge = 'serviceRequestExtraCharge',
  ServiceRequestFinished = 'serviceRequestFinished',
  ServiceRequestNewPro = 'serviceRequestNewPro',
  ServiceRequestNewTimeRequested = 'serviceRequestNewTimeRequested',
  ServiceRequestPaymentFailed = 'serviceRequestPaymentFailed',
  ServiceRequestPostVisitSummaryCreated = 'serviceRequestPostVisitSummaryCreated',
  SocialEvent = 'socialEvent',
  SocialEventCancelled = 'socialEventCancelled',
  SocialEventScheduled = 'socialEventScheduled',
  SocialEventUpdated = 'socialEventUpdated',
  StripeConnectAccountRequirements = 'stripeConnectAccountRequirements',
  TalkjsNewMessage = 'talkjsNewMessage',
}

export type NotificationOperationOutput = {
  __typename?: 'NotificationOperationOutput';
  /** Whether if the operation was success or not */
  success: Scalars['Boolean']['output'];
};

/** All notifications types in the application */
export enum NotificationTypes {
  AppointmentsCirclesPrior_30MinReminder = 'APPOINTMENTS_CIRCLES_PRIOR_30_MIN_REMINDER',
  AppointmentsServicesPrior_30MinReminder = 'APPOINTMENTS_SERVICES_PRIOR_30_MIN_REMINDER',
  CircleMember = 'CIRCLE_MEMBER',
  CircleMemberInvite = 'CIRCLE_MEMBER_INVITE',
  CircleMemberInviteAccepted = 'CIRCLE_MEMBER_INVITE_ACCEPTED',
  CircleMemberRequest = 'CIRCLE_MEMBER_REQUEST',
  CircleRequest = 'CIRCLE_REQUEST',
  CircleRequestAccepted = 'CIRCLE_REQUEST_ACCEPTED',
  CircleRequestAllMembersDeclined = 'CIRCLE_REQUEST_ALL_MEMBERS_DECLINED',
  CircleRequestCancelled = 'CIRCLE_REQUEST_CANCELLED',
  CircleRequestCmChangesTime = 'CIRCLE_REQUEST_CM_CHANGES_TIME',
  CircleRequestConfirmationNeededReminder = 'CIRCLE_REQUEST_CONFIRMATION_NEEDED_REMINDER',
  CircleRequestMemberUnavailable = 'CIRCLE_REQUEST_MEMBER_UNAVAILABLE',
  CircleRequestNew = 'CIRCLE_REQUEST_NEW',
  CoachVisit = 'COACH_VISIT',
  CoachVisitCancelled = 'COACH_VISIT_CANCELLED',
  CoachVisitNoteCreated = 'COACH_VISIT_NOTE_CREATED',
  CoachVisitScheduled = 'COACH_VISIT_SCHEDULED',
  CoachVisitUpdated = 'COACH_VISIT_UPDATED',
  FamilyPartnerInvite = 'FAMILY_PARTNER_INVITE',
  FamilyPartnerInviteAccepted = 'FAMILY_PARTNER_INVITE_ACCEPTED',
  FamilyPartnerRequest = 'FAMILY_PARTNER_REQUEST',
  General = 'GENERAL',
  PvsProReminder = 'PVS_PRO_REMINDER',
  ServiceRequest = 'SERVICE_REQUEST',
  ServiceRequestAuthorizationFailed = 'SERVICE_REQUEST_AUTHORIZATION_FAILED',
  ServiceRequestCancelled = 'SERVICE_REQUEST_CANCELLED',
  ServiceRequestCancelledByPro = 'SERVICE_REQUEST_CANCELLED_BY_PRO',
  ServiceRequestCancelledPaymentFailed = 'SERVICE_REQUEST_CANCELLED_PAYMENT_FAILED',
  ServiceRequestConfirmationNeeded = 'SERVICE_REQUEST_CONFIRMATION_NEEDED',
  ServiceRequestConfirmationNeededReminder = 'SERVICE_REQUEST_CONFIRMATION_NEEDED_REMINDER',
  ServiceRequestConfirmedBySm = 'SERVICE_REQUEST_CONFIRMED_BY_SM',
  ServiceRequestExtraCharge = 'SERVICE_REQUEST_EXTRA_CHARGE',
  ServiceRequestFinished = 'SERVICE_REQUEST_FINISHED',
  ServiceRequestNewPro = 'SERVICE_REQUEST_NEW_PRO',
  ServiceRequestNewTimeRequested = 'SERVICE_REQUEST_NEW_TIME_REQUESTED',
  ServiceRequestPaymentFailed = 'SERVICE_REQUEST_PAYMENT_FAILED',
  ServiceRequestPostVisitSummaryCreated = 'SERVICE_REQUEST_POST_VISIT_SUMMARY_CREATED',
  SocialEvent = 'SOCIAL_EVENT',
  SocialEventCancelled = 'SOCIAL_EVENT_CANCELLED',
  SocialEventScheduled = 'SOCIAL_EVENT_SCHEDULED',
  SocialEventUpdated = 'SOCIAL_EVENT_UPDATED',
  StripeConnectAccountRequirements = 'STRIPE_CONNECT_ACCOUNT_REQUIREMENTS',
  TalkjsNewMessage = 'TALKJS_NEW_MESSAGE',
}

export type NotificationTypesEnums = {
  /** All notifications types in the application */
  notificationCategories: NotificationCategories;
  /** All notifications types in the application */
  notificationTypes: NotificationTypes;
};

export type NotificationsFilter = {
  /** Filter by notification type */
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter by unread field */
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotifyMeOutput = {
  __typename?: 'NotifyMeOutput';
  success: Scalars['Boolean']['output'];
};

export type PaginationInput = {
  /** The number of elements per page */
  limit: Scalars['Float']['input'];
  /** The page that should be returned */
  page: Scalars['Float']['input'];
};

export type PaginationMeta = {
  __typename?: 'PaginationMeta';
  /** The page that should be returned */
  currentPage: Scalars['Float']['output'];
  /** Whether the returned page is the first page or not */
  isFirstPage: Scalars['Boolean']['output'];
  /** Whether the returned page is the last page or not */
  isLastPage: Scalars['Boolean']['output'];
  /** The number of the next page */
  nextPage?: Maybe<Scalars['Float']['output']>;
  /** The total number of pages */
  pageCount: Scalars['Float']['output'];
  /** The number of the previous page */
  previousPage?: Maybe<Scalars['Float']['output']>;
  /** The total number of elements */
  totalCount: Scalars['Float']['output'];
};

export type Participant = {
  __typename?: 'Participant';
  access: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notify: Scalars['Boolean']['output'];
};

/** The positive tags of the review */
export enum PositiveTags {
  Cleanliness = 'cleanliness',
  CustomerService = 'customer_service',
  OverallSatisfaction = 'overall_satisfaction',
  Professionalism = 'professionalism',
  Punctuality = 'punctuality',
  QualityOfWork = 'quality_of_work',
}

export type Query = {
  __typename?: 'Query';
  /** Continue the stripe connect account onboarding flow */
  continueConnectAccountOnboarding: Scalars['String']['output'];
  /** Get Circle, Service Requests and Social Events with sorting and filtering for specific customers */
  getActivities: GetActivitiesOutput;
  getActivitiesAlerts: Array<GetActivitiesAlertsOutput>;
  /** Get Circle, Service Requests and Social Events with sorting and filtering for specific customers grouped by date */
  getActivitiesGroupedByDate: GetActivitiesGroupedByDateOutput;
  /** Gets address by ID */
  getAddress: GraphqlAddresses;
  /** The addresses information of a specific customer */
  getAddresses: Array<GraphqlAddresses>;
  /** The information of a specific agent */
  getAgent: GraphqlAgent;
  /** Get service activities with sorting and filtering for a specific agent */
  getAgentActivities: GetAppointmentRequestsActivitiesOutput;
  /** Get a service activity with appointment information */
  getAgentActivityByAppointmentId: GraphqlAppointmentRequest;
  getAgentReviews: GetAgentReviewsOutput;
  /** Retrieves paginated agents */
  getAgents: GetAgentsOutput;
  /** Retrieves agents by trusted members and high reviews and ratings  */
  getAgentsByServiceRequest: GetAgentsByServiceRequestOutput;
  /** Returns all the feature flags */
  getAllFeatureFlags: Array<GraphqlFeatureFlag>;
  getAppointmentRequests: Array<GraphqlAppointmentRequest>;
  /** Get appointment requests with pagination, filtering and sorting */
  getAppointmentRequestsFiltered: GetAppointmentRequestsOutput;
  /** Gets list of users that were blocked by logged user */
  getBlockedUsersByBlockerUserId: Array<GraphqlBlockedUsers>;
  /** Gets list of users that blocked logged user */
  getBlockerUsersByBlockedUserId: Array<GraphqlBlockedUsers>;
  /** Returns a chat conversation between the logged user and coach */
  getBryaPartnerChatId: Conversation;
  /** Returns a chat conversation between the logged user and all support users */
  getBryaSupportChatId: Conversation;
  /** Returns a chat conversation between the logged user and all support and coach users */
  getBryaTeamChatId: Conversation;
  /** Get circle activities with sorting and filtering for a specific circle member */
  getCircleMemberActivities: GetAppointmentRequestsActivitiesOutput;
  /** Get a circle activity with circle member information */
  getCircleMemberActivityByAppointmentId: GraphqlAppointmentRequest;
  /** Get a single circle request */
  getCircleRequest: GraphqlCircleRequest;
  /** Gets requests with pagination, filtering and sorting */
  getCircleRequests: GetCircleRequestsOutput;
  /** The profile of a coach for the authorized user */
  getCoachProfile?: Maybe<GetCoachProfileOutput>;
  getCoachReviews: GetCoachReviewsOutput;
  /** Get a single coach visit */
  getCoachVisit: GraphqlCoachVisit;
  /** Get coach visits with pagination, filtering and sorting */
  getCoachVisits: GetCoachVisitsOutput;
  /** Retrieve paginated companies */
  getCompanies: GetCompaniesOutput;
  /** Retrieve company by id */
  getCompany: GraphqlCompany;
  /** Returns a chat conversation by its ID */
  getConversation: Conversation;
  /** The talkJS signature for the specified conversation */
  getConversationSignature: GetConversationSignatureOutput;
  /** Returns paginated chat conversations */
  getConversations: GetConversationsOutput;
  /** Returns the chat conversations for a specific customer */
  getConversationsByCustomer: Array<Conversation>;
  /** Returns the chat conversations for a specific user */
  getConversationsByUserId: Array<Conversation>;
  /** The information of the logged in user */
  getCurrentUser: GraphqlUser;
  /** The information of a specific customer */
  getCustomer: GetCustomerOutput;
  /** Retrieves an agent for a customer */
  getCustomerAgent: GraphqlCustomerAgent;
  /** Return whether a customer has a subscription plan or not */
  getCustomerHasStripeSubscription?: Maybe<Scalars['Boolean']['output']>;
  getCustomerInternalNotes: GetInternalNotesOutput;
  /** The list of customers in the application */
  getCustomers: Array<GetCustomerOutput>;
  /** Get the default timezone in the app */
  getDefaultTimezone: GetDefaultTimezone;
  getFiles: GetFilesOutput;
  /** Gets list of flagged messages */
  getFlaggedMessages: Array<GraphqlFlaggedMessages>;
  /** The FrontApp channels with the logged in user hash */
  getFrontAppChannels: GetFrontAppChannels;
  /** get google places api key */
  getGooglePlacesApiKey: GetGooglePlacesApiKeyOutput;
  getInternalNotes: GetInternalNotesOutput;
  /** Get a single social event for mobile application users */
  getMobileSocialEvent: GraphqlCustomerSocialEvent;
  /** All notifications related to a user. */
  getNotificationsByUserId: GetNotificationsByUserIdOutput;
  /** All timeline entries for a user or a customer */
  getRecentActivity: GetRecentActivityOuput;
  /** Get a single service request */
  getServiceRequest: GraphqlServiceRequest;
  /** Retrieves all events related to the service request that the user is allowed to see */
  getServiceRequestHistory: Array<GraphqlTimeline>;
  getServiceRequestReview?: Maybe<GraphqlServiceRequestReview>;
  /** Get service request with pagination, filtering and sorting */
  getServiceRequests: GetServiceRequestsOutput;
  /** The talkJS signature of the logged user to use the chat in the application */
  getSignature: GetSignatureOutput;
  /** Get a single social event */
  getSocialEvent: GraphqlSocialEvent;
  /** Get social events with pagination, filtering and sorting */
  getSocialEvents: GetSocialEventsOutput;
  /** Get the Stripe Connect Account information for a company */
  getStripeConnectAccount?: Maybe<GraphqlStripeConnectAccount>;
  /** Get the Stripe Customer Account information for an user */
  getStripeCustomerAccount?: Maybe<GraphqlStripeCustomerAccount>;
  /** Triggers the flow to allow a customer to add a new credit card in their stripe account */
  getStripeCustomerCreditCardSecret: Scalars['String']['output'];
  /** Returns if user has at least one payment method saved or not */
  getStripeCustomerHasSavedPaymentMethod: Scalars['Boolean']['output'];
  /** Returns a list of saved payment methods for a user */
  getStripeCustomerPaymentMethod: StripeCardPaymentMethod;
  /** Returns a list of saved payment methods for the logged-in user */
  getStripeCustomerPaymentMethods: Array<StripeCardPaymentMethod>;
  /** Returns a list of saved payment methods for a specified user */
  getStripePaymentMethodsByUser: Array<StripeCardPaymentMethod>;
  /** Get a specific Stripe subscription for a customer user */
  getStripeSubscription?: Maybe<GraphqlStripeSubscription>;
  /** Get a specific Stripe subscription product from brya */
  getStripeSubscriptionPrice?: Maybe<GraphqlStripeSubscriptionPrice>;
  /** Get the Stripe subscription products from brya */
  getStripeSubscriptionPrices?: Maybe<Array<GraphqlStripeSubscriptionPrice>>;
  /** Get subscriptions information of users */
  getStripeSubscriptions?: Maybe<Array<GraphqlStripeSubscription>>;
  /** Get a specific Stripe transaction */
  getStripeTransaction: GraphqlStripeTransaction;
  /** Get list of transactions */
  getStripeTransactions: GetStripeTransactionOutput;
  /** Get a list of suggested activities */
  getSuggestedActivities: SuggestedActivitiesOutput;
  /** Gets suggested activity by ID */
  getSuggestedActivity: GraphqlSuggestedActivities;
  /** All timeline entries for a user or a customer */
  getTimeline: Array<GraphqlTimeline>;
  /** The information of a specific user */
  getUser: GraphqlUser;
  /** The information of a specific user, searching by chat provider id */
  getUserByChatProviderId: GraphqlUser;
  getUserCoachReviews: GetCoachReviewsOutput;
  /** List of user invites that a user sent */
  getUserInviteById: GraphqlUserInvite;
  /** List of user invites that a user sent */
  getUserInvitesByCustomer: Array<GraphqlUserInvite>;
  /** List of user invites by user id */
  getUserInvitesByUserId: Array<GraphqlUserInvite>;
  /** Get notifications settings for the logged in user */
  getUserNotificationSettings: GraphqlNotificationSettings;
  /** Get notifications settings for a specific user */
  getUserNotificationSettingsByUserId: GraphqlNotificationSettings;
  getUserParticipants: GetUserParticipantsOutput;
  /** The list of customers in the application */
  getUserRelatedCustomers: Array<GraphqlCustomer>;
  /** Return a user request */
  getUserRequestById: GraphqlUserRequest;
  /** List of user requests by an specific customer */
  getUserRequestsByCustomer: Array<GraphqlUserRequest>;
  /** List of user requests by requested by */
  getUserRequestsByRequestedBy: Array<GraphqlUserRequest>;
  /** Get the talkJS signature for an arbitrary user */
  getUserSignature: GetSignatureOutput;
  /** The list of users in the application */
  getUsers: GetUsersOutput;
  /** The list of users related to a customer */
  getUsersByCustomerId: Array<GraphqlUser>;
  /** The list of full names of users */
  getUsersFullNames: Array<UserWithFullName>;
  getVisitSummaries: GetVisitSummariesOutput;
  getVisitSummariesByCoachId: GetVisitSummaryByCoachVisitIdOutput;
  /** Return zip code business rules */
  getZipCodeMatchesBusinessRules: GetZipCodeMatchesBusinessRules;
  /** Returns the completion of the circle request from OpenAI */
  interpretCircleRequest: CircleRequestInterpretResponse;
  /** Returns the completion of the service request from OpenAI */
  interpretServiceRequest: ServiceRequestInterpretResponse;
  listRequestCategories: Array<GraphqlRequestCategory>;
};

export type QueryGetActivitiesArgs = {
  filter: ActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetActivitiesGroupedByDateArgs = {
  filter: ActivitiesGroupedByDateFilter;
  lastValue?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGetAddressArgs = {
  addressId: Scalars['Float']['input'];
};

export type QueryGetAddressesArgs = {
  customerId: Scalars['Float']['input'];
};

export type QueryGetAgentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAgentActivitiesArgs = {
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAgentActivityByAppointmentIdArgs = {
  appointmentRequestId: Scalars['Int']['input'];
};

export type QueryGetAgentReviewsArgs = {
  agentId: Scalars['Int']['input'];
  category?: InputMaybe<ServiceRequestCategoriesEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetAgentsArgs = {
  filter?: InputMaybe<AgentsFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AgentsSort>;
};

export type QueryGetAgentsByServiceRequestArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetAppointmentRequestsArgs = {
  circleRequestId?: InputMaybe<Scalars['Int']['input']>;
  serviceRequestId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAppointmentRequestsFilteredArgs = {
  filter: AppointmentRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AppointmentRequestSortInput>;
};

export type QueryGetCircleMemberActivitiesArgs = {
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetCircleMemberActivityByAppointmentIdArgs = {
  appointmentRequestId: Scalars['Int']['input'];
};

export type QueryGetCircleRequestArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetCircleRequestsArgs = {
  filter: CircleRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CircleRequestSortInput>;
};

export type QueryGetCoachReviewsArgs = {
  coachId: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetCoachVisitArgs = {
  coachVisitId: Scalars['Int']['input'];
};

export type QueryGetCoachVisitsArgs = {
  filter: CoachVisitFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CoachVisitSortInput>;
};

export type QueryGetCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CompanySortInput>;
};

export type QueryGetCompanyArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetConversationArgs = {
  conversationId: Scalars['String']['input'];
};

export type QueryGetConversationSignatureArgs = {
  conversationId: Scalars['String']['input'];
};

export type QueryGetConversationsArgs = {
  filter?: InputMaybe<GetConversationsFilter>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetConversationsSort>;
  startingAfter?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetConversationsByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetConversationsByUserIdArgs = {
  userId: Scalars['Float']['input'];
};

export type QueryGetCustomerArgs = {
  customerId: Scalars['Float']['input'];
};

export type QueryGetCustomerAgentArgs = {
  agentId: Scalars['Int']['input'];
};

export type QueryGetCustomerInternalNotesArgs = {
  customerId: Scalars['Int']['input'];
  pagination: PaginationInput;
};

export type QueryGetCustomersArgs = {
  filters: FiltersInput;
};

export type QueryGetFilesArgs = {
  filter?: InputMaybe<FilesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<FilesSort>;
};

export type QueryGetInternalNotesArgs = {
  filter?: InputMaybe<InternalNotesFilterOptions>;
  pagination: PaginationInput;
  sort?: InputMaybe<InternalNotesSortOptions>;
};

export type QueryGetMobileSocialEventArgs = {
  customerId: Scalars['Int']['input'];
  socialEventId: Scalars['Int']['input'];
};

export type QueryGetNotificationsByUserIdArgs = {
  filter: NotificationsFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetRecentActivityArgs = {
  filters: RecentActivityFilter;
};

export type QueryGetServiceRequestArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetServiceRequestHistoryArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetServiceRequestReviewArgs = {
  serviceRequestId: Scalars['Int']['input'];
};

export type QueryGetServiceRequestsArgs = {
  filter: ServiceRequestFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ServiceRequestSortInput>;
};

export type QueryGetSocialEventArgs = {
  socialEventId: Scalars['Int']['input'];
};

export type QueryGetSocialEventsArgs = {
  filter: SocialEventFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SocialEventSortInput>;
};

export type QueryGetStripeCustomerPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type QueryGetStripePaymentMethodsByUserArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetStripeSubscriptionPriceArgs = {
  stripeSubscriptionPriceId: Scalars['String']['input'];
};

export type QueryGetStripeTransactionArgs = {
  stripeTransactionId: Scalars['String']['input'];
};

export type QueryGetStripeTransactionsArgs = {
  filter: StripeTransactionFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<StripeTransactionFilterSortInput>;
};

export type QueryGetSuggestedActivitiesArgs = {
  filter?: InputMaybe<SuggestedActivitiesFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SuggestedActivitiesSortInput>;
};

export type QueryGetSuggestedActivityArgs = {
  suggestedActivityId: Scalars['Float']['input'];
};

export type QueryGetTimelineArgs = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  orderIn: SortOrder;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetUserArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserByChatProviderIdArgs = {
  chatProviderId: Scalars['String']['input'];
};

export type QueryGetUserCoachReviewsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ReviewSortInput>;
};

export type QueryGetUserInviteByIdArgs = {
  userInviteId: Scalars['Int']['input'];
};

export type QueryGetUserInvitesByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUserInvitesByUserIdArgs = {
  status?: InputMaybe<Array<UserInvitesStatus>>;
  type?: InputMaybe<Array<UserInvitesType>>;
  userId: Scalars['Int']['input'];
};

export type QueryGetUserNotificationSettingsArgs = {
  notificationType: Scalars['String']['input'];
};

export type QueryGetUserNotificationSettingsByUserIdArgs = {
  notificationType: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

export type QueryGetUserParticipantsArgs = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GetUserParticipantsFilter>;
};

export type QueryGetUserRelatedCustomersArgs = {
  roles?: InputMaybe<Array<AppUser>>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetUserRequestByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserRequestsByCustomerArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUserRequestsByRequestedByArgs = {
  requestedBy: Scalars['Int']['input'];
  status?: InputMaybe<Array<UserInvitesStatus>>;
  type?: InputMaybe<Array<UserInvitesType>>;
};

export type QueryGetUserSignatureArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UserSortInput>;
};

export type QueryGetUsersByCustomerIdArgs = {
  customerId: Scalars['Int']['input'];
};

export type QueryGetUsersFullNamesArgs = {
  talkJsIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGetVisitSummariesArgs = {
  filter?: InputMaybe<VisitSummariesFilterOptions>;
  pagination: PaginationInput;
  sort?: InputMaybe<VisitSummariesSortOptions>;
};

export type QueryGetVisitSummariesByCoachIdArgs = {
  coachVisitId: Scalars['Float']['input'];
};

export type QueryGetZipCodeMatchesBusinessRulesArgs = {
  zipCode: Scalars['String']['input'];
};

export type QueryInterpretCircleRequestArgs = {
  text: Scalars['String']['input'];
};

export type QueryInterpretServiceRequestArgs = {
  text: Scalars['String']['input'];
};

export type QueryListRequestCategoriesArgs = {
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecentActivityFilter = {
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** The different types for a recurring event */
export enum RecurringFrequencyType {
  BiWeekly = 'biWeekly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export type RequestToJoinCircleInput = {
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
};

export type RequestToJoinCircleOutput = {
  __typename?: 'RequestToJoinCircleOutput';
  /** Wheter if there is a match or not with the customer info provided */
  match: Scalars['Boolean']['output'];
};

export type RequestToJoinFamilyGroupInput = {
  /** The customer email */
  email: Scalars['String']['input'];
  /** The customer phone number */
  phoneNumber: Scalars['String']['input'];
  /** The relationship between the customer and the request receiver */
  relationship?: InputMaybe<Scalars['String']['input']>;
};

export type RequestToJoinFamilyGroupOutput = {
  __typename?: 'RequestToJoinFamilyGroupOutput';
  /** Wheter if there is a match or not with the customer info provided */
  match: Scalars['Boolean']['output'];
};

export type RequestToNotExistingUserInput = {
  /** The not existing user email */
  email: Scalars['String']['input'];
  /** The not existing user first name */
  firstName: Scalars['String']['input'];
  /** The not existing user last name */
  lastName: Scalars['String']['input'];
  /** The not existing user phone number */
  phoneNumber: Scalars['String']['input'];
  /** The type of the request */
  requestType: UserInvitesType;
};

export type ResendVerifyEmailOutput = {
  __typename?: 'ResendVerifyEmailOutput';
  success: Scalars['Boolean']['output'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  success: Scalars['Boolean']['output'];
};

export type ResponseUserRequestOutput = {
  __typename?: 'ResponseUserRequestOutput';
  success: Scalars['Boolean']['output'];
};

export type ReviewSortInput = {
  direction: SortOrder;
  field: ReviewSortableFields;
};

/** The possible fields that the reviews can be sorted by */
export enum ReviewSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Rating = 'rating',
  UpdatedAt = 'updatedAt',
}

export type Ride = {
  __typename?: 'Ride';
  /** The addresses for the ride */
  dropoffAddress: GraphqlRideAddress;
  /** The addresses for the ride */
  pickupAddress: GraphqlRideAddress;
  /** The addresses for the ride */
  stops?: Maybe<Array<GraphqlRideAddress>>;
  /** The type of the ride */
  type: RideType;
  /** The wait time information provided by the user */
  waitTime?: Maybe<Scalars['String']['output']>;
};

/** The fields to add an address to a Ride Request */
export type RideAddressInput = {
  /** First address line */
  addressLine1: Scalars['String']['input'];
  /** The additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city: Scalars['String']['input'];
  /** The address country code */
  country: Scalars['String']['input'];
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the address was created using the Google Maps API */
  isFromAPI?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state: Scalars['String']['input'];
  /** The address zip code */
  zipCode: Scalars['String']['input'];
};

export type RideInput = {
  /** The addresses for the ride */
  dropoffAddress: RideAddressInput;
  /** The addresses for the ride */
  pickupAddress: RideAddressInput;
  /** The addresses for the ride */
  stops?: InputMaybe<Array<RideAddressInput>>;
  /** The type of the ride */
  type: RideType;
  /** The wait time information provided by the user */
  waitTime?: InputMaybe<Scalars['String']['input']>;
};

/** The different types of rides for a service request of this kind */
export enum RideType {
  OneWay = 'oneWay',
  RoundTrip = 'roundTrip',
}

/** The roles related to application users and customers */
export enum Role {
  CircleMember = 'circleMember',
  Coach = 'coach',
  FamilyPartner = 'familyPartner',
  SeniorMember = 'seniorMember',
  Support = 'support',
}

/** The available fileds to create a notification settings entry for a user */
export type SaveNotificationSettingsInput = {
  /** whether send the notification via email or not */
  email?: InputMaybe<Scalars['Boolean']['input']>;
  notificationType: Scalars['String']['input'];
  /** whether send the notification via push or not */
  push?: InputMaybe<Scalars['Boolean']['input']>;
  /** whether send the notification via pushWeb or not */
  pushWeb?: InputMaybe<Scalars['Boolean']['input']>;
  /** whether send the notification via sms or not */
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The app features selection for a user */
export type SelectAppFeaturesInput = {
  /** Whether the user includes circle member features */
  circleMember?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user includes family partner features */
  familyPartner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the user includes senior member features */
  seniorMember?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the user */
  userId: Scalars['Int']['input'];
};

export type SendRequestToNotExistingUserOutput = {
  __typename?: 'SendRequestToNotExistingUserOutput';
  success: Scalars['Boolean']['output'];
};

export enum SentTypesOptions {
  Email = 'email',
  Push = 'push',
  Sms = 'sms',
}

export type SentViaOutput = {
  __typename?: 'SentViaOutput';
  fullName: Scalars['String']['output'];
  types: Array<SentTypesOptions>;
};

export enum ServicePaymentType {
  Fixed = 'fixed',
  Hour = 'hour',
  Visit = 'visit',
}

export type ServiceRequestAvailability = {
  __typename?: 'ServiceRequestAvailability';
  /** Date in the format YYYY-MM-DD */
  date: Scalars['PlainDate']['output'];
  /** From hours in the format 15:30Z+00:00 */
  from: Scalars['ZonedPlainTime']['output'];
  /** Until hours in the format 15:30Z+00:00 */
  until: Scalars['ZonedPlainTime']['output'];
};

export type ServiceRequestAvailabilityInput = {
  /** Date in the format YYYY-MM-DD */
  date: Scalars['PlainDate']['input'];
  /** From hours in the format 15:30Z+00:00 */
  from: Scalars['ZonedPlainTime']['input'];
  /** Until hours in the format 15:30Z+00:00 */
  until: Scalars['ZonedPlainTime']['input'];
};

export enum ServiceRequestCategoriesEnum {
  ApplianceRepair = 'appliance_repair',
  BeautyHomeCare = 'beauty_home_care',
  Cleaner = 'cleaner',
  Companionship = 'companionship',
  Electrical = 'electrical',
  Errands = 'errands',
  GroceryShopping = 'grocery_shopping',
  Handyman = 'handyman',
  HomeSafety = 'home_safety',
  LaundryService = 'laundry_service',
  MealDelivery = 'meal_delivery',
  NonMedicalHomeCare = 'non_medical_home_care',
  Other = 'other',
  PersonalShopping = 'personal_shopping',
  PersonalTraining = 'personal_training',
  PetCare = 'pet_care',
  Plumbing = 'plumbing',
  Ride = 'ride',
  TechAssistance = 'tech_assistance',
  Yardwork = 'yardwork',
}

export type ServiceRequestFilter = {
  /** Filter by agentId */
  agentId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by category */
  category?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by customerId */
  customerId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Whether the service request has been sent to at least one agent */
  hasAppointmentRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the service request's appointment requests are awaiting attention */
  hasWaitingAppointmentRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the service request needs assignment */
  needsAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by requested after date */
  requestedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requested before date */
  requestedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requires action */
  requiresAction?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<ServiceRequestStatus>>;
  /** Filters by agent, customer, user that requested the service or ID */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Whether the service request is upcoming and needs confirmation */
  upcomingUnconfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by updatedAt after date */
  updatedAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by updatedAt before date */
  updatedAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceRequestInterpretResponse = {
  __typename?: 'ServiceRequestInterpretResponse';
  category: Scalars['String']['output'];
  recurring: Scalars['Boolean']['output'];
};

/** The available options for updating the price when chargingby visit  */
export enum ServiceRequestPaymentSummaryVisitType {
  AddExtraCharge = 'addExtraCharge',
  KeepCost = 'keepCost',
  Refund = 'refund',
}

/** The fields for sorting */
export type ServiceRequestSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: ServiceRequestsSortableFields;
};

/** The different status for a service request */
export enum ServiceRequestStatus {
  AttentionNeeded = 'attentionNeeded',
  Cancelled = 'cancelled',
  Completed = 'completed',
  ConfirmationNeeded = 'confirmationNeeded',
  Confirmed = 'confirmed',
  Inactive = 'inactive',
  PaymentAuthorizationFailed = 'paymentAuthorizationFailed',
  PaymentFailed = 'paymentFailed',
  PaymentWaitingForSupport = 'paymentWaitingForSupport',
  Pending = 'pending',
  UserUnavailable = 'userUnavailable',
}

/** The tentative schedule for a service request provided by the user */
export enum ServiceRequestTentativeSchedule {
  AsSoonAsPossible = 'asSoonAsPossible',
  ChooseTimeAndDate = 'chooseTimeAndDate',
  WithinDays = 'withinDays',
  WithinWeeks = 'withinWeeks',
}

/** The type of service request */
export enum ServiceRequestType {
  Circle = 'circle',
  CoachVisit = 'coachVisit',
  Service = 'service',
  Social = 'social',
}

export enum ServiceRequestsSortableFields {
  AddressId = 'addressId',
  AgentId = 'agentId',
  Availabilities = 'availabilities',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  Category = 'category',
  CommentForBrya = 'commentForBrya',
  CommentForCustomer = 'commentForCustomer',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  Frequency = 'frequency',
  Id = 'id',
  IsAvailabilityFlexible = 'isAvailabilityFlexible',
  OtherCategory = 'otherCategory',
  PostVisitSummarySent = 'postVisitSummarySent',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  Ride = 'ride',
  ScheduledAt = 'scheduledAt',
  Status = 'status',
  TentativeSchedule = 'tentativeSchedule',
  UpdatedAt = 'updatedAt',
}

export type ServiceType = {
  __typename?: 'ServiceType';
  id: ServiceRequestCategoriesEnum;
  name: Scalars['String']['output'];
};

export type SocialEventFilter = {
  /** Filter by city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Filter by coach id */
  coachId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filters by event name */
  eventName?: InputMaybe<Scalars['String']['input']>;
  /** Filter by whether the social event has pending contact requests */
  hasPendingContactRequests?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by participantId */
  participantId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by requestedBy */
  requestedBy?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by scheduledAt after date */
  scheduledAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by scheduledAt before date */
  scheduledAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<SocialEventStatus>>;
  /** Filter by zipCode */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type SocialEventSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: SocialEventSortableFields;
};

export enum SocialEventSortableFields {
  AddressId = 'addressId',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  CancellationReason = 'cancellationReason',
  CancelledBy = 'cancelledBy',
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Description = 'description',
  EventName = 'eventName',
  Frequency = 'frequency',
  Id = 'id',
  Place = 'place',
  Recurring = 'recurring',
  RequestType = 'requestType',
  RequestedBy = 'requestedBy',
  ScheduledAt = 'scheduledAt',
  State = 'state',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  ZipCode = 'zipCode',
}

/** The different status for a social event */
export enum SocialEventStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Confirmed = 'confirmed',
}

/** The possible order records can be sorted by */
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** The fields for starting a conversation */
export type StartConversationInput = {
  /** Custom data for the conversation */
  custom?: InputMaybe<ConversationCustomInput>;
  /** Subject for the conversation */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Chat Provider IDs of the conversation participants to create */
  talkJsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** User IDs for starting a new conversation, used when not all users have a Chat Provider ID */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type StipePaymentMethod = {
  __typename?: 'StipePaymentMethod';
  id: Scalars['String']['output'];
};

export type StripeAmountDetails = {
  __typename?: 'StripeAmountDetails';
  tip?: Maybe<StripeTip>;
};

export type StripeBillingDetails = {
  __typename?: 'StripeBillingDetails';
  name?: Maybe<Scalars['String']['output']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  capture_method?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  request_three_d_secure?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix_kana?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix_kanji?: Maybe<Scalars['String']['output']>;
};

export type StripeCardPaymentMethod = {
  __typename?: 'StripeCardPaymentMethod';
  billing_details: StripeBillingDetails;
  card: GraphqlStripeCard;
  created: Scalars['Int']['output'];
  default?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  livemode: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum StripeConnectAccountStatus {
  Complete = 'complete',
  MissingRequirements = 'missingRequirements',
  RequirementsPastDue = 'requirementsPastDue',
}

export type StripeLastPaymentError = {
  __typename?: 'StripeLastPaymentError';
  message?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<StipePaymentMethod>;
  type: Scalars['String']['output'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Float']['output'];
  amount_capturable: Scalars['Int']['output'];
  amount_details?: Maybe<StripeAmountDetails>;
  amount_received: Scalars['Int']['output'];
  application?: Maybe<Scalars['String']['output']>;
  application_fee_amount?: Maybe<Scalars['String']['output']>;
  automatic_payment_methods?: Maybe<AutomaticPaymentMethods>;
  canceled_at?: Maybe<Scalars['Int']['output']>;
  cancellation_reason?: Maybe<Scalars['String']['output']>;
  capture_method: Scalars['String']['output'];
  client_secret?: Maybe<Scalars['String']['output']>;
  confirmation_method: Scalars['String']['output'];
  created: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  customer?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice?: Maybe<Scalars['String']['output']>;
  last_payment_error?: Maybe<StripeLastPaymentError>;
  latest_charge?: Maybe<Scalars['String']['output']>;
  livemode: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  on_behalf_of?: Maybe<Scalars['String']['output']>;
  payment_method?: Maybe<Scalars['String']['output']>;
  payment_method_options?: Maybe<StripePaymentMethodOptions>;
  payment_method_types: Array<Scalars['String']['output']>;
  receipt_email?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  setup_future_usage?: Maybe<Scalars['String']['output']>;
  statement_descriptor?: Maybe<Scalars['String']['output']>;
  statement_descriptor_suffix?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transfer_data?: Maybe<StripeTransferData>;
  transfer_group?: Maybe<Scalars['String']['output']>;
};

export type StripePaymentMethodOptions = {
  __typename?: 'StripePaymentMethodOptions';
  card?: Maybe<StripeCard>;
};

export enum StripeSubscriptionPricesRecurringEnum {
  Month = 'month',
  Year = 'year',
}

export type StripeTip = {
  __typename?: 'StripeTip';
  amount?: Maybe<Scalars['Float']['output']>;
};

export type StripeTransactionFilter = {
  /** Filter by activity id */
  activityId?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filter by createdAt after date */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by createdAt before date */
  createdAtUntil?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter by status */
  status?: InputMaybe<Array<StripeTransactionStatus>>;
  /** Filters by stripe connect account id */
  stripeAccountId?: InputMaybe<Scalars['String']['input']>;
  /** Filters by stripe customer id */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Filters by transaction id */
  stripeTransactionId?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for sorting */
export type StripeTransactionFilterSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: StripeTransactionSortableFields;
};

export enum StripeTransactionSortableFields {
  ActivityId = 'activityId',
  Amount = 'amount',
  AmountCaptured = 'amountCaptured',
  CreatedAt = 'createdAt',
  Status = 'status',
  StripeConnectAccountId = 'stripeConnectAccountId',
  StripeCustomerId = 'stripeCustomerId',
  StripeTransactionId = 'stripeTransactionId',
  UpdatedAt = 'updatedAt',
}

export enum StripeTransactionStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requiresAction',
  RequiresCapture = 'requiresCapture',
  RequiresConfirmation = 'requiresConfirmation',
  RequiresPaymentMethod = 'requiresPaymentMethod',
  Succeeded = 'succeeded',
}

export type StripeTransferData = {
  __typename?: 'StripeTransferData';
  amount: Scalars['Int']['output'];
  destination: Scalars['String']['output'];
};

export type SubscriptionKeys = {
  /** auth key */
  auth: Scalars['String']['input'];
  /** p256dh key */
  p256dh: Scalars['String']['input'];
};

/** The fields for filter suggested activities */
export type SuggestedActivitiesFilter = {
  /** The suggested activity name to filter by */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The suggested activities user roles to filter by */
  roles?: InputMaybe<Array<SuggestedActivitiesRoleEnum>>;
  /** The user ids to filter by */
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Filters by user name */
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type SuggestedActivitiesOutput = {
  __typename?: 'SuggestedActivitiesOutput';
  /** The pagination information */
  meta: PaginationMeta;
  /** The list of suggested activities */
  suggestedActivities: Array<GraphqlSuggestedActivities>;
};

/** The available roles for when creating a suggested activity */
export enum SuggestedActivitiesRoleEnum {
  Host = 'host',
  Participant = 'participant',
}

/** The fields for sorting */
export type SuggestedActivitiesSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: SuggestedActivitiesSortableFields;
};

/** The possible fields that the suggested activities can be sorted by */
export enum SuggestedActivitiesSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UserId = 'userId',
  UserRole = 'userRole',
}

export type SyncUserChatOutput = {
  __typename?: 'SyncUserChatOutput';
  /** If the sync was completed successfully */
  success: Scalars['Boolean']['output'];
};

export type TimelineOutput = {
  __typename?: 'TimelineOutput';
  /** The day the activity is schedule to happen */
  day: Scalars['String']['output'];
  /** The month the activity is schedule to happen */
  month: Scalars['String']['output'];
  /** The week day the activity is schedule to happen */
  weekDay: Scalars['String']['output'];
};

/** The possible actions the user can perform when trusting a member */
export enum TrustUntrustActionType {
  Trust = 'trust',
  Untrust = 'untrust',
}

export type TrustUntrustMembersOutput = {
  __typename?: 'TrustUntrustMembersOutput';
  /** The action to be performed */
  action: TrustUntrustActionType;
  /** Whether the action was successfull or not */
  success: Scalars['Boolean']['output'];
};

/** The possible types that can be filted by when getting the User Participants */
export enum TrustedUserTypeOptions {
  All = 'all',
  Trusted = 'trusted',
}

export type UnsubscribeInput = {
  /** Must have at least one field for a mutation */
  destroy: Scalars['Boolean']['input'];
};

/** The fields to update an address */
export type UpdateAddressInput = {
  /** First address line */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Additional address information */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** The address city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** The address country code */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Whether the address is the default one */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the address */
  place?: InputMaybe<Scalars['String']['input']>;
  /** The address state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** The address zip code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for updating an agent */
export type UpdateAgentInput = {
  /** The agent's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The agent's internal (hidden) rating */
  internalRating?: InputMaybe<Scalars['Int']['input']>;
  /** The agent's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Optional password to be set for the agent */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The agent's phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The prefered contact methods of the agent for being reach by Brya */
  preferedContactMethods?: InputMaybe<Array<AgentPreferedContactMethodEnum>>;
  /** The types of services that the agent can provide */
  serviceTypes?: InputMaybe<Array<ServiceRequestCategoriesEnum>>;
};

export type UpdateAppointmentPaymentInput = {
  /** The amount that is being charged for the service */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** How many hours it should take to complete the service */
  estimateHours?: InputMaybe<Scalars['Float']['input']>;
  /** The payment type */
  type?: InputMaybe<ServicePaymentType>;
};

/** The fields to update an appointment request */
export type UpdateAppointmentRequestInput = {
  /** The appointment request ID */
  id: Scalars['Int']['input'];
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** New status */
  status?: InputMaybe<AppointmentRequestStatus>;
};

export type UpdateCircleRequestInput = {
  /** Address id related to where the request was made */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the request can be scheduled */
  availabilities?: InputMaybe<Array<ServiceRequestAvailabilityInput>>;
  /** The category of this request */
  category?: InputMaybe<CircleRequestCategoriesEnum>;
  /** Description of the request made by the user */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks request as recurring */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Circle Qequest */
  status?: InputMaybe<ServiceRequestStatus>;
};

export type UpdateCoachVisitInput = {
  /** Address id related to where the visit was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** Description of the visit */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** Whether the visit is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the visit is going to occur */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Coach Visit */
  status?: InputMaybe<SocialEventStatus>;
};

/** Fields that can be updated, fields with a set value will be updated */
export type UpdateCompanyInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  serviceZipCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactRequestInput = {
  bryaInternalNotes?: InputMaybe<Scalars['String']['input']>;
  bryaMessage?: InputMaybe<Scalars['String']['input']>;
  customerActivityId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContactRequestStatus>;
  userMessage?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to be updated for the current user */
export type UpdateCurrentUserInput = {
  /** The user mobile default tab */
  defaultTab?: InputMaybe<DefaultTab>;
  /** the firebase token */
  firebaseToken?: InputMaybe<Scalars['String']['input']>;
  /** The user first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** The user last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The user phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** The fields to updaate a customer */
export type UpdateCustomerInput = {
  /** The customer birthday */
  birthday?: InputMaybe<Scalars['DateTime']['input']>;
  /** The customer email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The customer first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The customer last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The customer phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRequestCategoryInput = {
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
  circleRequestOrder?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  newId?: InputMaybe<Scalars['String']['input']>;
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
  serviceRequestOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateServicePaymentInput = {
  /** Payment method id that is being used to pay for the service */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateServiceRequestInput = {
  /** Address id related to where the service was requested */
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** The availabilities set by the user on when the service can be scheduled */
  availabilities?: InputMaybe<Array<ServiceRequestAvailabilityInput>>;
  /** The category of this service request */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Description of the request made by the user */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Recurring Frequency */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** If 'other' specified in 'category', this is the user's input on the category */
  otherCategory?: InputMaybe<Scalars['String']['input']>;
  /** Marks service request as recurring */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ride details of the request provided by the user */
  ride?: InputMaybe<RideInput>;
  /** New scheduledAt datetime */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The status of the service request */
  status?: InputMaybe<ServiceRequestStatus>;
};

export type UpdateSocialEventInput = {
  /** Field for all of the nested Social Event -specific fields */
  SocialEvent?: InputMaybe<UpdateSocialEventInputNestedSocialEvent>;
  /** The ids of coaches related to the event */
  coachesIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Description of the event */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The name of the event */
  eventName?: InputMaybe<Scalars['String']['input']>;
  /** Frequency at which the event recurs */
  frequency?: InputMaybe<RecurringFrequencyType>;
  /** The ids of coaches related to the event */
  participantsIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Whether the event is recurring or not */
  recurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** When the event is going to occur */
  scheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Status of the Circle Qequest */
  status?: InputMaybe<SocialEventStatus>;
};

export type UpdateSocialEventInputNestedSocialEvent = {
  /** The update object inside of this nested Social Event object */
  update?: InputMaybe<UpdateSocialEventInputNestedSocialEventUpdate>;
};

export type UpdateSocialEventInputNestedSocialEventUpdate = {
  /** Location address line 1 */
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Location address line 2 */
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Location address city name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Location address country name */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Location place name */
  place?: InputMaybe<Scalars['String']['input']>;
  /** Location address state name */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Location address ZIP code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStripeCustomerDefaultCardOutput = {
  __typename?: 'UpdateStripeCustomerDefaultCardOutput';
  success: Scalars['Boolean']['output'];
};

/** The fields for updating a suggested activity */
export type UpdateSuggestedActivityInput = {
  /** The suggested activity description */
  description: Scalars['String']['input'];
  /** The suggested activity name */
  name: Scalars['String']['input'];
  /** The internal notes of the suggested activity */
  notes: Scalars['String']['input'];
  /** The role the user wants to have */
  role: SuggestedActivitiesRoleEnum;
};

export type UserEmergencyContact = {
  __typename?: 'UserEmergencyContact';
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  relation: Scalars['String']['output'];
};

export type UserEmergencyContactInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
};

/** The fields for filter users */
export type UserFilter = {
  /** The user email to filter by */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The user name to filter by */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Whether we want to filter users with no role or not. By default we always return all users if no filter is specified */
  noRole?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user roles to filter by */
  roles?: InputMaybe<Array<GlobalRole>>;
  /** Filters by text fields in OR mode: fullName, email */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UserGetCustomerOutput = {
  __typename?: 'UserGetCustomerOutput';
  /** The user role */
  role: Scalars['String']['output'];
  /** The details of the user */
  user?: Maybe<GraphqlUser>;
};

/** The available fileds to create a circle invites for a customer */
export type UserInviteInput = {
  /** The customer that will join the invitation receiver */
  customerId?: InputMaybe<Scalars['Int']['input']>;
  /** The email to sent the invitation */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The person name to whom the invite was sent */
  firstName: Scalars['String']['input'];
  /** The type of invite, familyPartner or circleMember */
  inviteType: UserInvitesType;
  /** The user id who sent the invite */
  invitedBy?: InputMaybe<Scalars['Int']['input']>;
  /** The person name to whom the invite was sent */
  lastName: Scalars['String']['input'];
  /** The phone number to sent the invitation */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The relationship between the customer and the invitation receiver */
  relationship?: InputMaybe<Scalars['String']['input']>;
};

/** The available fileds to create a circle invites for a customer */
export type UserInviteResendInput = {
  /** the options to resend the invitation */
  sentVia: Array<SentTypesOptions>;
  /** the user invite id */
  userInviteId: Scalars['Int']['input'];
};

export enum UserInvitesStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Sent = 'sent',
}

export enum UserInvitesType {
  CircleMember = 'circleMember',
  FamilyPartner = 'familyPartner',
}

/** The fields for sorting */
export type UserSortInput = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: UserSortableFields;
};

/** The possible fields that the users can be sorted by */
export enum UserSortableFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type UserWithFullName = {
  __typename?: 'UserWithFullName';
  fullName: Scalars['String']['output'];
  talkJsId: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type UserWithTrustedFlag = {
  __typename?: 'UserWithTrustedFlag';
  /** The last date the user logged in */
  Agent?: Maybe<GraphqlAgent>;
  /** The app features available for this user */
  AppFeaturesSelected?: Maybe<GraphqlAppFeaturesSelected>;
  /** The customer that represents this user */
  OwnCustomer?: Maybe<GraphqlCustomer>;
  /** The current customer selected by the user */
  SelectedCustomer?: Maybe<GraphqlCustomer>;
  /** The roles related for this user */
  UserCustomerRoles?: Maybe<Array<GraphqlUserRole>>;
  /** User bio */
  bio?: Maybe<Scalars['String']['output']>;
  /** The date the user account has been created */
  createdAt: Scalars['DateTime']['output'];
  /** The default tab that should be opened when the user logs in in the mobile application */
  defaultTab?: Maybe<DefaultTab>;
  /** The user account status, whether it's disabled or not */
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** The user email address */
  email?: Maybe<Scalars['String']['output']>;
  /** The user email varified status */
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  /** The emergency contact, available for brya employees (coaches and support) */
  emergencyContact?: Maybe<UserEmergencyContact>;
  /** The firebase token associated with this user */
  firebaseToken?: Maybe<Scalars['String']['output']>;
  /** The user first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user full name */
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** If the user is marked as a circle member or not */
  isCircleMember?: Maybe<Scalars['Boolean']['output']>;
  /** The user language */
  language?: Maybe<Scalars['String']['output']>;
  /** The last date the user logged in */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** The user last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The customer id associated with the user */
  ownCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The user profile picture */
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** The role of the user */
  role?: Maybe<GlobalRole>;
  /** The user current selected customer */
  selectedCustomerId?: Maybe<Scalars['Int']['output']>;
  /** The user talkJs id for the chat */
  talkJsId?: Maybe<Scalars['String']['output']>;
  /** Whether the user is a trusted member or not */
  trustedMember?: Maybe<Scalars['Boolean']['output']>;
  /** The last date the user account has been updated */
  updatedAt: Scalars['DateTime']['output'];
  /** Push notification subscription object */
  webPushSubscription?: Maybe<Scalars['String']['output']>;
};

export type VerifyEmailOutput = {
  __typename?: 'VerifyEmailOutput';
  accessToken: Scalars['String']['output'];
};

/** The options to filter visit summaries */
export type VisitSummariesFilterOptions = {
  /** Matches coach visit */
  coachVisitId?: InputMaybe<Scalars['Float']['input']>;
};

export type VisitSummariesSortOptions = {
  /** The direction to order by */
  direction: SortOrder;
  /** The fields to sort by */
  field: VisitSummariesSortableFields;
};

/** The possible fields that visit summaries can be sorted */
export enum VisitSummariesSortableFields {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

/** The webpush-expected object to create a subscription */
export type WebPushSubscription = {
  /** FCM endpoint to which to send web pushes */
  endpoint: Scalars['String']['input'];
  /** Whether the subscription expires, and when */
  expirationTime?: InputMaybe<Scalars['String']['input']>;
  /** subscription keys */
  keys: SubscriptionKeys;
};

export type GetUserInvitesByCustomerQueryVariables = Exact<{
  customerId: Scalars['Int']['input'];
}>;

export type GetUserInvitesByCustomerQuery = {
  __typename?: 'Query';
  getUserInvitesByCustomer: Array<{
    __typename?: 'GraphqlUserInvite';
    id: number;
    name?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    userId?: number | null;
    inviteType: UserInvitesType;
    customerId: number;
    invitedBy?: number | null;
    status: UserInvitesStatus;
    sentVia: Array<string>;
    createdAt: Date;
  }>;
};

export type ResendUserInviteMutationVariables = Exact<{
  userInviteData: UserInviteResendInput;
}>;

export type ResendUserInviteMutation = {
  __typename?: 'Mutation';
  resendUserInvite: {
    __typename?: 'DeleteUserInvitesOutput';
    success: boolean;
  };
};

export type DeleteUserInviteMutationVariables = Exact<{
  userInviteId: Scalars['Int']['input'];
}>;

export type DeleteUserInviteMutation = {
  __typename?: 'Mutation';
  deleteUserInvite: {
    __typename?: 'DeleteUserInvitesOutput';
    success: boolean;
  };
};

export type LeaveCircleMutationVariables = Exact<{ [key: string]: never }>;

export type LeaveCircleMutation = {
  __typename?: 'Mutation';
  leaveCircle: { __typename?: 'DeleteUserInvitesOutput'; success: boolean };
};

export type LeaveCircleAsFamilyPartnerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type LeaveCircleAsFamilyPartnerMutation = {
  __typename?: 'Mutation';
  leaveCircleAsFamilyPartner: {
    __typename?: 'DeleteUserInvitesOutput';
    success: boolean;
  };
};

export type GetActivitiesQueryVariables = Exact<{
  filter: ActivitiesFilter;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  getActivities: {
    __typename?: 'GetActivitiesOutput';
    requests: Array<
      | {
          __typename?: 'GraphqlCircleRequest';
          id: number;
          status: ServiceRequestStatus;
          requestType: ServiceRequestType;
          recurring: boolean;
          scheduledAt?: Date | null;
          acceptedAt?: Date | null;
          createdAt: Date;
          circleCategory: CircleRequestCategoriesEnum;
          RequestedBy: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
          };
          RequestCategory?: {
            __typename?: 'GraphqlRequestCategory';
            id: string;
            name: string;
          } | null;
          ride?: {
            __typename?: 'Ride';
            type: RideType;
            waitTime?: string | null;
            pickupAddress: {
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              country: string;
              isDefault?: boolean | null;
            };
            dropoffAddress: {
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              country: string;
              isDefault?: boolean | null;
            };
            stops?: Array<{
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              country: string;
              isDefault?: boolean | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: 'GraphqlCoachVisit';
          id: number;
          recurring: boolean;
          scheduledAt?: Date | null;
          requestType: ServiceRequestType;
          createdAt: Date;
          statusCoachVisit: SocialEventStatus;
          RequestedBy: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
          };
        }
      | {
          __typename?: 'GraphqlCustomerSocialEvent';
          id: number;
          eventName: string;
          recurring: boolean;
          status: ServiceRequestStatus;
          scheduledAt?: Date | null;
          requestType: ServiceRequestType;
          createdAt: Date;
          updatedAt: Date;
          RequestedBy: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
          };
          Address?: {
            __typename?: 'GraphqlAddresses';
            addressLine1: string;
          } | null;
          Customer: {
            __typename?: 'GraphqlCustomer';
            id: number;
            fullName?: string | null;
          };
        }
      | {
          __typename?: 'GraphqlServiceRequest';
          id: number;
          status: ServiceRequestStatus;
          requestType: ServiceRequestType;
          recurring: boolean;
          category: ServiceRequestCategoriesEnum;
          scheduledAt?: Date | null;
          acceptedAt?: Date | null;
          createdAt: Date;
          RequestedBy: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
          };
          RequestCategory?: {
            __typename?: 'GraphqlRequestCategory';
            id: string;
            name: string;
          } | null;
          CreatedBy: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
          };
          Customer: {
            __typename?: 'GraphqlCustomer';
            id: number;
            fullName?: string | null;
          };
          ride?: {
            __typename?: 'Ride';
            type: RideType;
            waitTime?: string | null;
            pickupAddress: {
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              zipCode: string;
              country: string;
              isDefault?: boolean | null;
            };
            dropoffAddress: {
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              isDefault?: boolean | null;
            };
            stops?: Array<{
              __typename?: 'GraphqlRideAddress';
              addressLine1: string;
              addressLine2?: string | null;
              city: string;
              state: string;
              isDefault?: boolean | null;
            }> | null;
          } | null;
        }
    >;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      previousPage?: number | null;
      nextPage?: number | null;
      pageCount: number;
      totalCount: number;
    };
  };
};

export type GetActivitiesGroupedByDateQueryVariables = Exact<{
  filter: ActivitiesGroupedByDateFilter;
  lastValue?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetActivitiesGroupedByDateQuery = {
  __typename?: 'Query';
  getActivitiesGroupedByDate: {
    __typename?: 'GetActivitiesGroupedByDateOutput';
    requests: Array<{
      __typename?: 'ActivitiesGroupedByDateOutput';
      id: number;
      timeline: {
        __typename?: 'TimelineOutput';
        day: string;
        month: string;
        weekDay: string;
      };
      activities?: Array<
        | {
            __typename?: 'GraphqlCircleRequest';
            id: number;
            status: ServiceRequestStatus;
            requestType: ServiceRequestType;
            recurring: boolean;
            scheduledAt?: Date | null;
            acceptedAt?: Date | null;
            createdAt: Date;
            circleCategory: CircleRequestCategoriesEnum;
            RequestedBy: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            };
            CancelledBy?: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            } | null;
            RequestCategory?: {
              __typename?: 'GraphqlRequestCategory';
              id: string;
              name: string;
            } | null;
            ride?: {
              __typename?: 'Ride';
              type: RideType;
              waitTime?: string | null;
              pickupAddress: {
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                country: string;
                isDefault?: boolean | null;
              };
              dropoffAddress: {
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                country: string;
                isDefault?: boolean | null;
              };
              stops?: Array<{
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                country: string;
                isDefault?: boolean | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'GraphqlCoachVisit';
            id: number;
            recurring: boolean;
            scheduledAt?: Date | null;
            requestType: ServiceRequestType;
            createdAt: Date;
            statusCoachVisit: SocialEventStatus;
            RequestedBy: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            };
            CancelledBy?: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            } | null;
          }
        | {
            __typename?: 'GraphqlCustomerSocialEvent';
            id: number;
            eventName: string;
            recurring: boolean;
            status: ServiceRequestStatus;
            scheduledAt?: Date | null;
            requestType: ServiceRequestType;
            createdAt: Date;
            updatedAt: Date;
            RequestedBy: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            };
            CancelledBy?: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            } | null;
            Address?: {
              __typename?: 'GraphqlAddresses';
              addressLine1: string;
            } | null;
            Customer: {
              __typename?: 'GraphqlCustomer';
              id: number;
              fullName?: string | null;
            };
          }
        | {
            __typename?: 'GraphqlServiceRequest';
            id: number;
            status: ServiceRequestStatus;
            requestType: ServiceRequestType;
            recurring: boolean;
            category: ServiceRequestCategoriesEnum;
            scheduledAt?: Date | null;
            acceptedAt?: Date | null;
            createdAt: Date;
            RequestedBy: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            };
            CancelledBy?: {
              __typename?: 'GraphqlUser';
              id: number;
              fullName?: string | null;
            } | null;
            RequestCategory?: {
              __typename?: 'GraphqlRequestCategory';
              id: string;
              name: string;
            } | null;
            ride?: {
              __typename?: 'Ride';
              type: RideType;
              waitTime?: string | null;
              pickupAddress: {
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                zipCode: string;
                country: string;
                isDefault?: boolean | null;
              };
              dropoffAddress: {
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                isDefault?: boolean | null;
              };
              stops?: Array<{
                __typename?: 'GraphqlRideAddress';
                addressLine1: string;
                addressLine2?: string | null;
                city: string;
                state: string;
                isDefault?: boolean | null;
              }> | null;
            } | null;
          }
      > | null;
    }>;
    meta: {
      __typename?: 'ActivitiesGroupedByDateMeta';
      lastValue?: Date | null;
      isLastPage: boolean;
    };
  };
};

export type GetCustomerAddressesQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;

export type GetCustomerAddressesQuery = {
  __typename?: 'Query';
  getAddresses: Array<{
    __typename?: 'GraphqlAddresses';
    id: number;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isDefault: boolean;
  }>;
};

export type CreateServiceRequestMutationVariables = Exact<{
  data: CreateServiceRequestInput;
}>;

export type CreateServiceRequestMutation = {
  __typename?: 'Mutation';
  createServiceRequest: { __typename?: 'GraphqlServiceRequest'; id: number };
};

export type CreateCircleRequestMutationVariables = Exact<{
  data: CreateCircleRequestInput;
}>;

export type CreateCircleRequestMutation = {
  __typename?: 'Mutation';
  createCircleRequest: { __typename?: 'GraphqlCircleRequest'; id: number };
};

export type GetCircleMemberActivitiesQueryVariables = Exact<{
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCircleMemberActivitiesQuery = {
  __typename?: 'Query';
  getCircleMemberActivities: {
    __typename?: 'GetAppointmentRequestsActivitiesOutput';
    activities: Array<{
      __typename?: 'GraphqlAppointmentRequest';
      id: number;
      userId: number;
      serviceRequestId?: number | null;
      circleRequestId?: number | null;
      status: AppointmentRequestStatus;
      CircleRequest?: {
        __typename?: 'GraphqlCircleRequest';
        id: number;
        customerId: number;
        category: CircleRequestCategoriesEnum;
        otherCategory?: string | null;
        recurring: boolean;
        frequency?: RecurringFrequencyType | null;
        description?: string | null;
        addressId?: number | null;
        status: ServiceRequestStatus;
        requestedBy: number;
        scheduledAt?: Date | null;
        acceptedAt?: Date | null;
        requestType: ServiceRequestType;
        tentativeSchedule: ServiceRequestTentativeSchedule;
        isAvailabilityFlexible: boolean;
        createdAt: Date;
        updatedAt: Date;
        acceptedBy?: number | null;
        askTo: Array<number>;
        RequestCategory?: {
          __typename?: 'GraphqlRequestCategory';
          id: string;
          name: string;
        } | null;
      } | null;
    }>;
  };
};

export type GetAgentActivitiesQueryVariables = Exact<{
  filter: AppointmentActivitiesFilter;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAgentActivitiesQuery = {
  __typename?: 'Query';
  getAgentActivities: {
    __typename?: 'GetAppointmentRequestsActivitiesOutput';
    activities: Array<{
      __typename?: 'GraphqlAppointmentRequest';
      id: number;
      status: AppointmentRequestStatus;
      AppointmentRequestPayments?: Array<{
        __typename?: 'GraphqlAppointmentRequestPayment';
        id: number;
      }> | null;
      ServiceRequest?: {
        __typename?: 'GraphqlServiceRequest';
        id: number;
        customerId: number;
        category: ServiceRequestCategoriesEnum;
        otherCategory?: string | null;
        recurring: boolean;
        frequency?: RecurringFrequencyType | null;
        description?: string | null;
        addressId?: number | null;
        status: ServiceRequestStatus;
        requestedBy: number;
        scheduledAt?: Date | null;
        acceptedAt?: Date | null;
        requestType: ServiceRequestType;
        tentativeSchedule: ServiceRequestTentativeSchedule;
        isAvailabilityFlexible: boolean;
        createdAt: Date;
        updatedAt: Date;
        RequestCategory?: {
          __typename?: 'GraphqlRequestCategory';
          id: string;
          name: string;
        } | null;
        Address?: {
          __typename?: 'GraphqlAddresses';
          id: number;
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        } | null;
        ride?: {
          __typename?: 'Ride';
          type: RideType;
          waitTime?: string | null;
          pickupAddress: {
            __typename?: 'GraphqlRideAddress';
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            country: string;
            state: string;
            zipCode: string;
            place?: string | null;
          };
          dropoffAddress: {
            __typename?: 'GraphqlRideAddress';
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            country: string;
            state: string;
            zipCode: string;
            place?: string | null;
          };
          stops?: Array<{
            __typename?: 'GraphqlRideAddress';
            addressLine1: string;
            addressLine2?: string | null;
            city: string;
            country: string;
            state: string;
            zipCode: string;
            place?: string | null;
          }> | null;
        } | null;
      } | null;
    }>;
  };
};

export type GetCircleMemberActivityByAppointmentIdQueryVariables = Exact<{
  appointmentRequestId: Scalars['Int']['input'];
}>;

export type GetCircleMemberActivityByAppointmentIdQuery = {
  __typename?: 'Query';
  getCircleMemberActivityByAppointmentId: {
    __typename?: 'GraphqlAppointmentRequest';
    id: number;
    userId: number;
    serviceRequestId?: number | null;
    circleRequestId?: number | null;
    status: AppointmentRequestStatus;
    createdAt: Date;
    updatedAt: Date;
    scheduledAt?: Date | null;
    SentTo?: {
      __typename?: 'GraphqlUser';
      fullName?: string | null;
      profilePicture?: string | null;
      id: number;
      talkJsId?: string | null;
    } | null;
    CircleRequest?: {
      __typename?: 'GraphqlCircleRequest';
      id: number;
      customerId: number;
      category: CircleRequestCategoriesEnum;
      otherCategory?: string | null;
      recurring: boolean;
      frequency?: RecurringFrequencyType | null;
      description?: string | null;
      addressId?: number | null;
      status: ServiceRequestStatus;
      lastStatusUpdate?: Date | null;
      requestedBy: number;
      scheduledAt?: Date | null;
      acceptedAt?: Date | null;
      requestType: ServiceRequestType;
      tentativeSchedule: ServiceRequestTentativeSchedule;
      isAvailabilityFlexible: boolean;
      createdAt: Date;
      updatedAt: Date;
      acceptedBy?: number | null;
      askTo: Array<number>;
      availabilities: Array<{
        __typename?: 'ServiceRequestAvailability';
        date: Date;
        from: Date;
        until: Date;
      }>;
      ride?: {
        __typename?: 'Ride';
        type: RideType;
        waitTime?: string | null;
        pickupAddress: {
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        };
        dropoffAddress: {
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        };
        stops?: Array<{
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        }> | null;
      } | null;
      RequestedBy: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePicture?: string | null;
      };
      RequestCategory?: {
        __typename?: 'GraphqlRequestCategory';
        id: string;
        name: string;
      } | null;
      AcceptedBy?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
      } | null;
      AskTo: Array<{
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
      }>;
      Address?: {
        __typename?: 'GraphqlAddresses';
        id: number;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        state: string;
        zipCode: string;
        place?: string | null;
      } | null;
      Customer: {
        __typename?: 'GetCustomerOutput';
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        UserCustomerRoles?: Array<{
          __typename?: 'GraphqlUserRole';
          userId: number;
          role: Role;
          Users?: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
            profilePicture?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      };
      Timeline?: Array<{
        __typename?: 'GraphqlTimeline';
        id: number;
        title: string;
        description: string;
        createdAt: Date;
        User?: {
          __typename?: 'GraphqlUser';
          id: number;
          fullName?: string | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type GetAgentActivityByAppointmentIdQueryVariables = Exact<{
  appointmentRequestId: Scalars['Int']['input'];
}>;

export type GetAgentActivityByAppointmentIdQuery = {
  __typename?: 'Query';
  getAgentActivityByAppointmentId: {
    __typename?: 'GraphqlAppointmentRequest';
    id: number;
    userId: number;
    serviceRequestId?: number | null;
    circleRequestId?: number | null;
    status: AppointmentRequestStatus;
    createdAt: Date;
    updatedAt: Date;
    scheduledAt?: Date | null;
    SentTo?: {
      __typename?: 'GraphqlUser';
      fullName?: string | null;
      profilePicture?: string | null;
      id: number;
      talkJsId?: string | null;
    } | null;
    AppointmentRequestPayments?: Array<{
      __typename?: 'GraphqlAppointmentRequestPayment';
      id: number;
      amount: number;
      appointmentRequestId: number;
      createdAt: Date;
      estimateHours?: number | null;
      type: ServicePaymentType;
      updatedAt: Date;
    }> | null;
    ServiceRequest?: {
      __typename?: 'GraphqlServiceRequest';
      id: number;
      customerId: number;
      category: ServiceRequestCategoriesEnum;
      otherCategory?: string | null;
      recurring: boolean;
      frequency?: RecurringFrequencyType | null;
      description?: string | null;
      addressId?: number | null;
      status: ServiceRequestStatus;
      lastStatusUpdate?: Date | null;
      requestedBy: number;
      scheduledAt?: Date | null;
      acceptedAt?: Date | null;
      requestType: ServiceRequestType;
      tentativeSchedule: ServiceRequestTentativeSchedule;
      isAvailabilityFlexible: boolean;
      createdAt: Date;
      updatedAt: Date;
      serviceFee?: number | null;
      taxFee?: number | null;
      availabilities: Array<{
        __typename?: 'ServiceRequestAvailability';
        date: Date;
        from: Date;
        until: Date;
      }>;
      ride?: {
        __typename?: 'Ride';
        type: RideType;
        waitTime?: string | null;
        pickupAddress: {
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        };
        dropoffAddress: {
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        };
        stops?: Array<{
          __typename?: 'GraphqlRideAddress';
          addressLine1: string;
          addressLine2?: string | null;
          city: string;
          country: string;
          state: string;
          zipCode: string;
          place?: string | null;
        }> | null;
      } | null;
      RequestedBy: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        profilePicture?: string | null;
      };
      RequestCategory?: {
        __typename?: 'GraphqlRequestCategory';
        id: string;
        name: string;
      } | null;
      Customer: {
        __typename?: 'GraphqlCustomer';
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        UserCustomerRoles?: Array<{
          __typename?: 'GraphqlUserRole';
          userId: number;
          role: Role;
          Users?: {
            __typename?: 'GraphqlUser';
            id: number;
            fullName?: string | null;
            profilePicture?: string | null;
            firstName?: string | null;
            lastName?: string | null;
          } | null;
        }> | null;
      };
      Address?: {
        __typename?: 'GraphqlAddresses';
        id: number;
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        state: string;
        zipCode: string;
        place?: string | null;
      } | null;
      Timeline?: Array<{
        __typename?: 'GraphqlTimeline';
        id: number;
        title: string;
        description: string;
        createdAt: Date;
        User?: {
          __typename?: 'GraphqlUser';
          id: number;
          fullName?: string | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type UpdateAppointmentRequestMutationVariables = Exact<{
  updateAppointmentRequestInput: UpdateAppointmentRequestInput;
}>;

export type UpdateAppointmentRequestMutation = {
  __typename?: 'Mutation';
  updateAppointmentRequest: {
    __typename?: 'GraphqlAppointmentRequest';
    id: number;
    CircleRequest?: { __typename?: 'GraphqlCircleRequest'; id: number } | null;
    ServiceRequest?: {
      __typename?: 'GraphqlServiceRequest';
      id: number;
    } | null;
  };
};

export type GetActivitiesAlertsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivitiesAlertsQuery = {
  __typename?: 'Query';
  getActivitiesAlerts: Array<{
    __typename?: 'GetActivitiesAlertsOutput';
    status: ServiceRequestStatus;
    id: number;
  }>;
};

export type CreateSuggestedActivityMutationVariables = Exact<{
  createSuggestedActivityInput: CreateSuggestedActivityInput;
}>;

export type CreateSuggestedActivityMutation = {
  __typename?: 'Mutation';
  createSuggestedActivity: {
    __typename?: 'GraphqlSuggestedActivities';
    id: number;
  };
};

export type GenerateAuthIdMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateAuthIdMutation = {
  __typename?: 'Mutation';
  generateAuthId: {
    __typename?: 'GenerateAuthIdOutput';
    authId: string;
    secureToken: string;
  };
};

export type ExchangeAuthIdMutationVariables = Exact<{
  secureToken: Scalars['String']['input'];
  authId: Scalars['String']['input'];
}>;

export type ExchangeAuthIdMutation = {
  __typename?: 'Mutation';
  exchangeAuthId: {
    __typename?: 'ExchangeAuthIdOutput';
    accessToken?: string | null;
    finished: boolean;
  };
};

export type JoinCircleMutationVariables = Exact<{
  joinCircleId: Scalars['Int']['input'];
  status: UserInvitesStatus;
}>;

export type JoinCircleMutation = {
  __typename?: 'Mutation';
  joinCircle: {
    __typename?: 'GraphqlUserInvite';
    id: number;
    name?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    inviteType: UserInvitesType;
    userId?: number | null;
    customerId: number;
    invitedBy?: number | null;
    status: UserInvitesStatus;
    sentVia: Array<string>;
    createdAt: Date;
    Customer: {
      __typename?: 'GraphqlCustomer';
      firstName?: string | null;
      lastName?: string | null;
    };
  };
};

export type RefreshAccessTokenMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RefreshAccessTokenMutation = {
  __typename?: 'Mutation';
  refreshAccessToken: { __typename?: 'LoginUserOutput'; accessToken: string };
};

export type ResendVerifyEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ResendVerifyEmailMutation = {
  __typename?: 'Mutation';
  resendVerifyEmail: {
    __typename?: 'ResendVerifyEmailOutput';
    success: boolean;
  };
};

export type LogoutMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: { __typename?: 'LogoutUserOutput'; success: boolean };
};

export type GetSignatureQueryVariables = Exact<{ [key: string]: never }>;

export type GetSignatureQuery = {
  __typename?: 'Query';
  getSignature: {
    __typename?: 'GetSignatureOutput';
    signature: string;
    talkJsId: string;
    talkJsAppId: string;
  };
};

export type GetUserParticipantsQueryVariables = Exact<{
  filter?: InputMaybe<GetUserParticipantsFilter>;
  conversationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserParticipantsQuery = {
  __typename?: 'Query';
  getUserParticipants: {
    __typename?: 'GetUserParticipantsOutput';
    users: Array<{
      __typename?: 'UserWithTrustedFlag';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      fullName?: string | null;
      phoneNumber?: string | null;
      createdAt: Date;
      updatedAt: Date;
      defaultTab?: DefaultTab | null;
      talkJsId?: string | null;
      language?: string | null;
      disabled?: boolean | null;
      emailVerified?: boolean | null;
      role?: GlobalRole | null;
      lastLogin?: Date | null;
      webPushSubscription?: string | null;
      trustedMember?: boolean | null;
    }>;
    usersFromTalkJs: Array<{
      __typename?: 'UserWithTrustedFlag';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      fullName?: string | null;
      phoneNumber?: string | null;
      createdAt: Date;
      updatedAt: Date;
      defaultTab?: DefaultTab | null;
      talkJsId?: string | null;
      language?: string | null;
      disabled?: boolean | null;
      emailVerified?: boolean | null;
      role?: GlobalRole | null;
      lastLogin?: Date | null;
      webPushSubscription?: string | null;
      trustedMember?: boolean | null;
    }>;
  };
};

export type TrustUntrustMembersMutationVariables = Exact<{
  action: TrustUntrustActionType;
  trustedMembersIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type TrustUntrustMembersMutation = {
  __typename?: 'Mutation';
  trustUntrustMembers: {
    __typename?: 'TrustUntrustMembersOutput';
    success: boolean;
    action: TrustUntrustActionType;
  };
};

export type StartConversationMutationVariables = Exact<{
  startConversationInput: StartConversationInput;
}>;

export type StartConversationMutation = {
  __typename?: 'Mutation';
  startConversation: {
    __typename?: 'Conversation';
    id: string;
    subject?: string | null;
    topicId?: string | null;
    photoUrl?: string | null;
    welcomeMessages?: Array<string> | null;
    createdAt: number;
    custom: {
      __typename?: 'ConversationCustom';
      customerId?: string | null;
      participants?: string | null;
      userIds?: string | null;
    };
    lastMessage?: {
      __typename?: 'Message';
      conversationId: string;
      createdAt: number;
      editedAt?: number | null;
      id: string;
      location?: Array<number> | null;
      origin: string;
      readBy: Array<string>;
      senderId?: string | null;
      text: string;
      type: string;
      referencedMessageId?: string | null;
      attachment?: {
        __typename?: 'MessageAttachment';
        url: string;
        size: number;
      } | null;
    } | null;
    participants: Array<{
      __typename?: 'Participant';
      id: string;
      access: string;
      notify: boolean;
    }>;
  };
};

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'Conversation';
    id: string;
    subject?: string | null;
    topicId?: string | null;
    photoUrl?: string | null;
    welcomeMessages?: Array<string> | null;
  };
};

export type GetBryaPartnerChatIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBryaPartnerChatIdQuery = {
  __typename?: 'Query';
  getBryaPartnerChatId: { __typename?: 'Conversation'; id: string };
};

export type GetBryaSupportChatIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBryaSupportChatIdQuery = {
  __typename?: 'Query';
  getBryaSupportChatId: { __typename?: 'Conversation'; id: string };
};

export type GetBryaTeamChatIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetBryaTeamChatIdQuery = {
  __typename?: 'Query';
  getBryaTeamChatId: { __typename?: 'Conversation'; id: string };
};

export type BlockUserMutationVariables = Exact<{
  blockedUserId: Scalars['Int']['input'];
}>;

export type BlockUserMutation = {
  __typename?: 'Mutation';
  blockUser: { __typename?: 'GraphqlBlockedUsers'; id: number };
};

export type UnblockUserMutationVariables = Exact<{
  unblockUserId: Scalars['Int']['input'];
}>;

export type UnblockUserMutation = {
  __typename?: 'Mutation';
  unblockUser: { __typename?: 'GraphqlBlockedUsers'; id: number };
};

export type GetBlockedUsersByBlockerUserIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBlockedUsersByBlockerUserIdQuery = {
  __typename?: 'Query';
  getBlockedUsersByBlockerUserId: Array<{
    __typename?: 'GraphqlBlockedUsers';
    id: number;
    blockedUserId: number;
    blockerUserId: number;
  }>;
};

export type GetBlockerUsersByBlockedUserIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBlockerUsersByBlockedUserIdQuery = {
  __typename?: 'Query';
  getBlockerUsersByBlockedUserId: Array<{
    __typename?: 'GraphqlBlockedUsers';
    id: number;
    blockedUserId: number;
    blockerUserId: number;
  }>;
};

export type GetCoachProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoachProfileQuery = {
  __typename?: 'Query';
  getCoachProfile?: {
    __typename?: 'GetCoachProfileOutput';
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    bio?: string | null;
    profilePicture?: string | null;
  } | null;
};

export type GetUserCoachReviewsQueryVariables = Exact<{
  sort?: InputMaybe<ReviewSortInput>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUserCoachReviewsQuery = {
  __typename?: 'Query';
  getUserCoachReviews: {
    __typename?: 'GetCoachReviewsOutput';
    reviews: Array<{
      __typename?: 'GraphqlCoachReview';
      id: number;
      comment?: string | null;
      rating: number;
      coachId: number;
      reviewedBy: number;
      createdAt: Date;
      updatedAt: Date;
      ReviewedBy: {
        __typename?: 'GraphqlUser';
        id: number;
        firstName?: string | null;
        lastName?: string | null;
        createdAt: Date;
        updatedAt: Date;
      };
    }>;
    meta: {
      __typename?: 'PaginationMeta';
      currentPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      previousPage?: number | null;
      nextPage?: number | null;
      pageCount: number;
      totalCount: number;
    };
  };
};

export type ReviewCoachMutationVariables = Exact<{
  data: CreateCoachReviewInput;
}>;

export type ReviewCoachMutation = {
  __typename?: 'Mutation';
  reviewCoach: {
    __typename?: 'GraphqlCoachReview';
    comment?: string | null;
    rating: number;
    coachId: number;
    reviewedBy: number;
    createdAt: Date;
    updatedAt: Date;
    ReviewedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      createdAt: Date;
    };
  };
};

export type GetCoachVisitQueryVariables = Exact<{
  coachVisitId: Scalars['Int']['input'];
}>;

export type GetCoachVisitQuery = {
  __typename?: 'Query';
  getCoachVisit: {
    __typename?: 'GraphqlCoachVisit';
    id: number;
    description?: string | null;
    requestType: ServiceRequestType;
    scheduledAt?: Date | null;
    status: SocialEventStatus;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    Address?: {
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      country: string;
      state: string;
      place?: string | null;
    } | null;
    Coach: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      profilePicture?: string | null;
      email?: string | null;
    };
    Customer: {
      __typename?: 'GraphqlCustomer';
      fullName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
    RequestedBy: {
      __typename?: 'GraphqlUser';
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      profilePicture?: string | null;
    };
  };
};

export type CreateContactRequestMutationVariables = Exact<{
  createContactRequestInput: CreateContactRequestInput;
}>;

export type CreateContactRequestMutation = {
  __typename?: 'Mutation';
  createContactRequest: {
    __typename?: 'GraphqlContactRequest';
    id: number;
    status: ContactRequestStatus;
  };
};

export type UpdateContactRequestMutationVariables = Exact<{
  contactRequestId: Scalars['Int']['input'];
  updateContactRequestInput: UpdateContactRequestInput;
}>;

export type UpdateContactRequestMutation = {
  __typename?: 'Mutation';
  updateContactRequest: {
    __typename?: 'GraphqlContactRequest';
    id: number;
    status: ContactRequestStatus;
  };
};

export type CreateCustomerMutationVariables = Exact<{
  role: AppUser;
  createCustomerInput: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: 'Mutation';
  createCustomer: { __typename?: 'GraphqlCustomer'; id: number };
};

export type CreateCustomerWithAddressMutationVariables = Exact<{
  address: RideAddressInput;
  role: AppUser;
  customer: CreateCustomerInput;
}>;

export type CreateCustomerWithAddressMutation = {
  __typename?: 'Mutation';
  createCustomerWithAddress: { __typename?: 'GraphqlCustomer'; id: number };
};

export type CreateCustomerWithAdditionalInformationMutationVariables = Exact<{
  role: AppUser;
  customer: CreateCustomerWithAdditionalInformationInput;
}>;

export type CreateCustomerWithAdditionalInformationMutation = {
  __typename?: 'Mutation';
  createCustomerWithAdditionalInformation: {
    __typename?: 'GraphqlCustomer';
    id: number;
  };
};

export type RequestToJoinCircleMutationVariables = Exact<{
  requestToJoinCircleInput: RequestToJoinCircleInput;
}>;

export type RequestToJoinCircleMutation = {
  __typename?: 'Mutation';
  requestToJoinCircle: {
    __typename?: 'RequestToJoinCircleOutput';
    match: boolean;
  };
};

export type RequestToJoinFamilyGroupMutationVariables = Exact<{
  requestToJoinFamilyGroupInput: RequestToJoinFamilyGroupInput;
}>;

export type RequestToJoinFamilyGroupMutation = {
  __typename?: 'Mutation';
  requestToJoinFamilyGroup: {
    __typename?: 'RequestToJoinFamilyGroupOutput';
    match: boolean;
  };
};

export type GetGooglePlacesApiKeyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetGooglePlacesApiKeyQuery = {
  __typename?: 'Query';
  getGooglePlacesApiKey: {
    __typename?: 'GetGooglePlacesApiKeyOutput';
    apiKey?: string | null;
  };
};

export type GetAllFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllFeatureFlagsQuery = {
  __typename?: 'Query';
  getAllFeatureFlags: Array<{
    __typename?: 'GraphqlFeatureFlag';
    id: number;
    feature: string;
    description?: string | null;
    enabled: boolean;
  }>;
};

export type CreateUserInvitesMutationVariables = Exact<{
  userInvitesInput: CreateUserInviteInput;
}>;

export type CreateUserInvitesMutation = {
  __typename?: 'Mutation';
  createUserInvites: {
    __typename?: 'CreateUserInvitesOutput';
    success: boolean;
    sentVia: Array<{
      __typename?: 'SentViaOutput';
      fullName: string;
      types: Array<SentTypesOptions>;
    }>;
  };
};

export type SendRequestToNotExistingUserMutationVariables = Exact<{
  requestToNotExistingUserInput: RequestToNotExistingUserInput;
}>;

export type SendRequestToNotExistingUserMutation = {
  __typename?: 'Mutation';
  sendRequestToNotExistingUser: {
    __typename?: 'SendRequestToNotExistingUserOutput';
    success: boolean;
  };
};

export type GetNotificationsByUserIdQueryVariables = Exact<{
  filter: NotificationsFilter;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetNotificationsByUserIdQuery = {
  __typename?: 'Query';
  getNotificationsByUserId: {
    __typename?: 'GetNotificationsByUserIdOutput';
    meta: {
      __typename?: 'PaginationMeta';
      pageCount: number;
      isLastPage: boolean;
      totalCount: number;
    };
    notifications: Array<{
      __typename?: 'GraphqlNotification';
      id: number;
      userId: number;
      type: string;
      title?: string | null;
      text: string;
      readAt?: Date | null;
      pushSentAt?: Date | null;
      smsSentAt?: Date | null;
      emailSentAt?: Date | null;
      updatedAt: Date;
      createdAt: Date;
      payload?: any | null;
      deeplink?: string | null;
      User?: { __typename?: 'GraphqlUser'; fullName?: string | null } | null;
    }>;
  };
};

export type UnreadNotificationsMutationVariables = Exact<{
  notificationsIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type UnreadNotificationsMutation = {
  __typename?: 'Mutation';
  unreadNotifications: {
    __typename?: 'NotificationOperationOutput';
    success: boolean;
  };
};

export type ReadNotificationsMutationVariables = Exact<{
  notificationsIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type ReadNotificationsMutation = {
  __typename?: 'Mutation';
  readNotifications: {
    __typename?: 'NotificationOperationOutput';
    success: boolean;
  };
};

export type ReadAllUserNotificationsMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ReadAllUserNotificationsMutation = {
  __typename?: 'Mutation';
  readAllUserNotifications: {
    __typename?: 'NotificationOperationOutput';
    success: boolean;
  };
};

export type DeleteNotificationMutationVariables = Exact<{
  notificationId: Scalars['Float']['input'];
}>;

export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  deleteNotification: {
    __typename?: 'NotificationOperationOutput';
    success: boolean;
  };
};

export type AnswerRequestMutationVariables = Exact<{
  status: UserInvitesStatus;
  userRequestId: Scalars['Int']['input'];
}>;

export type AnswerRequestMutation = {
  __typename?: 'Mutation';
  answerRequest: { __typename?: 'ResponseUserRequestOutput'; success: boolean };
};

export type GetUserNotificationSettingsQueryVariables = Exact<{
  notificationType: Scalars['String']['input'];
}>;

export type GetUserNotificationSettingsQuery = {
  __typename?: 'Query';
  getUserNotificationSettings: {
    __typename?: 'GraphqlNotificationSettings';
    id: number;
    userId: number;
    notificationType: string;
    email: boolean;
    sms: boolean;
    push: boolean;
    pushWeb: boolean;
    createdAt: Date;
  };
};

export type SaveUserNotificationSettingsMutationVariables = Exact<{
  saveNotificationSettingsInput: SaveNotificationSettingsInput;
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SaveUserNotificationSettingsMutation = {
  __typename?: 'Mutation';
  saveUserNotificationSettings: {
    __typename?: 'GraphqlNotificationSettings';
    id: number;
    userId: number;
    notificationType: string;
    email: boolean;
    sms: boolean;
    push: boolean;
    pushWeb: boolean;
    createdAt: Date;
  };
};

export type CreateServiceRequestSummaryMutationVariables = Exact<{
  commentForBrya: Scalars['String']['input'];
  commentForCustomer: Scalars['String']['input'];
  serviceRequestId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  hours?: InputMaybe<Scalars['Float']['input']>;
  visitPaymentType?: InputMaybe<ServiceRequestPaymentSummaryVisitType>;
}>;

export type CreateServiceRequestSummaryMutation = {
  __typename?: 'Mutation';
  createServiceRequestSummary: {
    __typename?: 'GraphqlServiceRequest';
    id: number;
    customerId: number;
    category: ServiceRequestCategoriesEnum;
    otherCategory?: string | null;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    description?: string | null;
    addressId?: number | null;
    status: ServiceRequestStatus;
    rejectedReason?: string | null;
    cancellationReason?: string | null;
    requestedBy: number;
    createdBy: number;
    agentId?: number | null;
    scheduledAt?: Date | null;
    lastStatusUpdate?: Date | null;
    acceptedAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    requestType: ServiceRequestType;
    tentativeSchedule: ServiceRequestTentativeSchedule;
    isAvailabilityFlexible: boolean;
    commentForCustomer?: string | null;
    commentForBrya?: string | null;
    requiresAction?: boolean | null;
    actionNeeded?: string | null;
    availabilities: Array<{
      __typename?: 'ServiceRequestAvailability';
      date: Date;
      from: Date;
      until: Date;
    }>;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    };
    CreatedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      firstName?: string | null;
      email?: string | null;
      lastName?: string | null;
    };
    RequestCategory?: {
      __typename?: 'GraphqlRequestCategory';
      id: string;
      name: string;
      circleRequest: boolean;
      serviceRequest: boolean;
      createdAt: Date;
      updatedAt: Date;
    } | null;
    ServiceRequestPayments?: Array<{
      __typename?: 'GraphqlServiceRequestPayment';
      id: number;
      type: ServicePaymentType;
      amount: number;
      fee: number;
      tax: number;
      estimateHours?: number | null;
      createdAt: Date;
      updatedAt: Date;
      serviceRequestId: number;
      stripePaymentMethodId?: string | null;
      stripeTransactionId?: string | null;
      notes?: string | null;
    }> | null;
  };
};

export type GetStripeCustomerCreditCardSecretQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStripeCustomerCreditCardSecretQuery = {
  __typename?: 'Query';
  getStripeCustomerCreditCardSecret: string;
};

export type GetStripeCustomerPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetStripeCustomerPaymentMethodsQuery = {
  __typename?: 'Query';
  getStripeCustomerPaymentMethods: Array<{
    __typename?: 'StripeCardPaymentMethod';
    id: string;
    object: string;
    created: number;
    livemode: boolean;
    type: string;
    default?: boolean | null;
    card: {
      __typename?: 'GraphqlStripeCard';
      brand: string;
      country?: string | null;
      funding: string;
      last4: string;
      exp_month: number;
      exp_year: number;
    };
    billing_details: {
      __typename?: 'StripeBillingDetails';
      name?: string | null;
    };
  }>;
};

export type CreateAppointmentRequestPaymentMutationVariables = Exact<{
  appointmentPaymentInput: CreateAppointmentPaymentInput;
}>;

export type CreateAppointmentRequestPaymentMutation = {
  __typename?: 'Mutation';
  createAppointmentRequestPayment: {
    __typename?: 'GraphqlAppointmentRequestPayment';
    id: number;
    type: ServicePaymentType;
    amount: number;
    estimateHours?: number | null;
    createdAt: Date;
    appointmentRequestId: number;
    updatedAt: Date;
  };
};

export type GetStripeCustomerPaymentMethodQueryVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;

export type GetStripeCustomerPaymentMethodQuery = {
  __typename?: 'Query';
  getStripeCustomerPaymentMethod: {
    __typename?: 'StripeCardPaymentMethod';
    id: string;
    object: string;
    created: number;
    livemode: boolean;
    type: string;
    default?: boolean | null;
    card: {
      __typename?: 'GraphqlStripeCard';
      brand: string;
      country?: string | null;
      exp_month: number;
      exp_year: number;
      funding: string;
      last4: string;
    };
    billing_details: {
      __typename?: 'StripeBillingDetails';
      name?: string | null;
    };
  };
};

export type UpdateAppointmentRequestPaymentMutationVariables = Exact<{
  appointmentRequestPaymentId: Scalars['Int']['input'];
  appointmentPaymentInput: UpdateAppointmentPaymentInput;
}>;

export type UpdateAppointmentRequestPaymentMutation = {
  __typename?: 'Mutation';
  updateAppointmentRequestPayment: {
    __typename?: 'GraphqlAppointmentRequestPayment';
    id: number;
    type: ServicePaymentType;
    amount: number;
    estimateHours?: number | null;
    createdAt: Date;
    updatedAt: Date;
    appointmentRequestId: number;
  };
};

export type UpdateServiceRequestPaymentMutationVariables = Exact<{
  serviceRequestPaymentId: Scalars['Int']['input'];
  servicePaymentInput: UpdateServicePaymentInput;
}>;

export type UpdateServiceRequestPaymentMutation = {
  __typename?: 'Mutation';
  updateServiceRequestPayment: {
    __typename?: 'GraphqlServiceRequestPayment';
    id: number;
    type: ServicePaymentType;
    amount: number;
    estimateHours?: number | null;
    createdAt: Date;
    updatedAt: Date;
    serviceRequestId: number;
    fee: number;
    tax: number;
    stripePaymentMethodId?: string | null;
    stripeTransactionId?: string | null;
    notes?: string | null;
  };
};

export type UpdateStripeCustomerDefaultCardMutationVariables = Exact<{
  paymentMethodId: Scalars['String']['input'];
}>;

export type UpdateStripeCustomerDefaultCardMutation = {
  __typename?: 'Mutation';
  updateStripeCustomerDefaultCard: {
    __typename?: 'UpdateStripeCustomerDefaultCardOutput';
    success: boolean;
  };
};

export type ConfirmStripeTransactionMutationVariables = Exact<{
  stripeTransactionId: Scalars['String']['input'];
}>;

export type ConfirmStripeTransactionMutation = {
  __typename?: 'Mutation';
  confirmStripeTransaction: {
    __typename?: 'ConfirmStripeTransactionOutput';
    success: boolean;
  };
};

export type ContactSupportForExtraChargeMutationVariables = Exact<{
  stripeTransactionId: Scalars['String']['input'];
}>;

export type ContactSupportForExtraChargeMutation = {
  __typename?: 'Mutation';
  contactSupportForExtraCharge: {
    __typename?: 'GraphqlServiceRequest';
    id: number;
  };
};

export type GetCustomerAgentQueryVariables = Exact<{
  agentId: Scalars['Int']['input'];
}>;

export type GetCustomerAgentQuery = {
  __typename?: 'Query';
  getCustomerAgent: {
    __typename?: 'GraphqlCustomerAgent';
    userId: number;
    rating?: number | null;
    serviceTypes: Array<ServiceRequestCategoriesEnum>;
    favorite: Array<ServiceRequestCategoriesEnum>;
    serviceTypeInfo?: Array<{
      __typename?: 'ServiceType';
      id: ServiceRequestCategoriesEnum;
      name: string;
    }> | null;
    Company?: {
      __typename?: 'GraphqlCompany';
      name: string;
      website?: string | null;
      city: string;
      state: string;
    } | null;
    User: {
      __typename?: 'GraphqlUser';
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      bio?: string | null;
      profilePicture?: string | null;
    };
    CustomerFavoriteAgents?: Array<{
      __typename?: 'GraphqlCustomerFavoriteAgent';
      customerId: number;
      Customer?: {
        __typename?: 'GraphqlCustomer';
        fullName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }> | null;
  };
};

export type ReviewsQueryVariables = Exact<{
  agentId: Scalars['Int']['input'];
  category?: InputMaybe<ServiceRequestCategoriesEnum>;
}>;

export type ReviewsQuery = {
  __typename?: 'Query';
  getAgentReviews: {
    __typename?: 'GetAgentReviewsOutput';
    reviews: Array<{
      __typename?: 'GraphqlServiceRequestReview';
      comment?: string | null;
      rating: number;
      serviceRequestCategory?: string | null;
      ReviewedBy: {
        __typename?: 'GraphqlUser';
        firstName?: string | null;
        createdAt: Date;
      };
    }>;
  };
};

export type FavoriteAgentMutationVariables = Exact<{
  serviceType: ServiceRequestCategoriesEnum;
  agentId: Scalars['Int']['input'];
}>;

export type FavoriteAgentMutation = {
  __typename?: 'Mutation';
  favoriteAgent: { __typename?: 'GraphqlCustomerAgent'; userId: number };
};

export type UnfavoriteAgentMutationVariables = Exact<{
  serviceType: ServiceRequestCategoriesEnum;
  agentId: Scalars['Int']['input'];
}>;

export type UnfavoriteAgentMutation = {
  __typename?: 'Mutation';
  unfavoriteAgent: { __typename?: 'GraphqlCustomerAgent'; userId: number };
};

export type InterpretServiceRequestQueryVariables = Exact<{
  text: Scalars['String']['input'];
}>;

export type InterpretServiceRequestQuery = {
  __typename?: 'Query';
  interpretServiceRequest: {
    __typename?: 'ServiceRequestInterpretResponse';
    category: string;
    recurring: boolean;
  };
};

export type InterpretCircleRequestQueryVariables = Exact<{
  text: Scalars['String']['input'];
}>;

export type InterpretCircleRequestQuery = {
  __typename?: 'Query';
  interpretCircleRequest: {
    __typename?: 'CircleRequestInterpretResponse';
    category: string;
    recurring: boolean;
  };
};

export type UpdateServiceRequestMutationVariables = Exact<{
  data: UpdateServiceRequestInput;
  updateServiceRequestId: Scalars['Int']['input'];
}>;

export type UpdateServiceRequestMutation = {
  __typename?: 'Mutation';
  updateServiceRequest: {
    __typename?: 'GraphqlServiceRequest';
    id: number;
    status: ServiceRequestStatus;
  };
};

export type GetServiceRequestQueryVariables = Exact<{
  getServiceRequestId: Scalars['Float']['input'];
}>;

export type GetServiceRequestQuery = {
  __typename?: 'Query';
  getServiceRequest: {
    __typename?: 'GraphqlServiceRequest';
    id: number;
    customerId: number;
    category: ServiceRequestCategoriesEnum;
    otherCategory?: string | null;
    recurring: boolean;
    description?: string | null;
    frequency?: RecurringFrequencyType | null;
    addressId?: number | null;
    status: ServiceRequestStatus;
    rejectedReason?: string | null;
    cancellationReason?: string | null;
    requestedBy: number;
    agentId?: number | null;
    scheduledAt?: Date | null;
    acceptedAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
    requestType: ServiceRequestType;
    tentativeSchedule: ServiceRequestTentativeSchedule;
    isAvailabilityFlexible: boolean;
    requiresAction?: boolean | null;
    actionNeeded?: string | null;
    commentForCustomer?: string | null;
    availabilities: Array<{
      __typename?: 'ServiceRequestAvailability';
      date: Date;
      from: Date;
      until: Date;
    }>;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
    };
    CreatedBy: {
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
    };
    Customer: {
      __typename?: 'GraphqlCustomer';
      id: number;
      fullName?: string | null;
    };
    ServiceRequestPayments?: Array<{
      __typename?: 'GraphqlServiceRequestPayment';
      id: number;
      type: ServicePaymentType;
      amount: number;
      fee: number;
      tax: number;
      estimateHours?: number | null;
      createdAt: Date;
      updatedAt: Date;
      serviceRequestId: number;
      stripePaymentMethodId?: string | null;
      stripeTransactionId?: string | null;
      notes?: string | null;
      StripeTransaction?: {
        __typename?: 'GraphqlStripeTransaction';
        status: string;
        activityId: number;
        amount: number;
        createdAt: Date;
        stripeCustomerId: string;
      } | null;
    }> | null;
    RequestCategory?: {
      __typename?: 'GraphqlRequestCategory';
      id: string;
      name: string;
    } | null;
    Address?: {
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      id: number;
      place?: string | null;
      state: string;
      zipCode: string;
      country: string;
      city: string;
      customerId: number;
    } | null;
    ride?: {
      __typename?: 'Ride';
      type: RideType;
      waitTime?: string | null;
      dropoffAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine2?: string | null;
        addressLine1: string;
        city: string;
        country: string;
        place?: string | null;
        state: string;
      };
      pickupAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        place?: string | null;
        state: string;
      };
      stops?: Array<{
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        place?: string | null;
        state: string;
      }> | null;
    } | null;
    Timeline?: Array<{
      __typename?: 'GraphqlTimeline';
      id: number;
      userId?: number | null;
      customerId?: number | null;
      title: string;
      description: string;
      createdAt: Date;
      isAppVisible: boolean;
      User?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        createdAt: Date;
        updatedAt: Date;
      } | null;
    }> | null;
    Agent?: {
      __typename?: 'GraphqlAgent';
      rating?: number | null;
      serviceTypes: Array<ServiceRequestCategoriesEnum>;
      Company?: {
        __typename?: 'GraphqlCompany';
        rating?: number | null;
        state: string;
        updatedAt: Date;
        website?: string | null;
        name: string;
        phoneNumber?: string | null;
        id: number;
        hours?: string | null;
        createdAt: Date;
      } | null;
      User: {
        __typename?: 'GraphqlUser';
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        profilePicture?: string | null;
      };
    } | null;
  };
};

export type UserApprovalServiceRequestMutationVariables = Exact<{
  action: ApprovalServiceRequestStatus;
  serviceRequestId: Scalars['Int']['input'];
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserApprovalServiceRequestMutation = {
  __typename?: 'Mutation';
  userApprovalServiceRequest: {
    __typename?: 'GraphqlServiceRequest';
    Agent?: { __typename?: 'GraphqlAgent'; userId: number } | null;
  };
};

export type UpdateServiceRequestTimeMutationVariables = Exact<{
  newTime: Scalars['DateTime']['input'];
  serviceRequestId: Scalars['Int']['input'];
}>;

export type UpdateServiceRequestTimeMutation = {
  __typename?: 'Mutation';
  updateServiceRequestTime: {
    __typename?: 'GraphqlServiceRequest';
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
  };
};

export type GetMobileSocialEventQueryVariables = Exact<{
  socialEventId: Scalars['Int']['input'];
  customerId: Scalars['Int']['input'];
}>;

export type GetMobileSocialEventQuery = {
  __typename?: 'Query';
  getMobileSocialEvent: {
    __typename?: 'GraphqlCustomerSocialEvent';
    id: number;
    customerActivityId: number;
    cancellationReason?: string | null;
    createdAt: Date;
    description?: string | null;
    eventName: string;
    frequency?: RecurringFrequencyType | null;
    recurring: boolean;
    requestType: ServiceRequestType;
    requestedBy: number;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    updatedAt: Date;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    country: string;
    place?: string | null;
    state: string;
    zipCode: string;
    ContactRequests: Array<{
      __typename?: 'GraphqlContactRequest';
      id: number;
      createdAt: Date;
      updatedAt: Date;
      userId: number;
      status: ContactRequestStatus;
    }>;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      profilePicture?: string | null;
      role?: GlobalRole | null;
      email?: string | null;
    };
    Customer: {
      __typename?: 'GraphqlCustomer';
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      createdAt: Date;
      id: number;
    };
  };
};

export type UserCancelServiceRequestMutationVariables = Exact<{
  cancellationReason: Scalars['String']['input'];
  serviceRequestId: Scalars['Int']['input'];
}>;

export type UserCancelServiceRequestMutation = {
  __typename?: 'Mutation';
  userCancelServiceRequest: {
    __typename?: 'GraphqlServiceRequest';
    requestType: ServiceRequestType;
    requestedBy: number;
    requiresAction?: boolean | null;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    rejectedReason?: string | null;
    otherCategory?: string | null;
    description?: string | null;
    customerId: number;
    cancellationReason?: string | null;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      firstName?: string | null;
      fullName?: string | null;
      id: number;
      email?: string | null;
      role?: GlobalRole | null;
      updatedAt: Date;
      lastName?: string | null;
    };
    Address?: {
      __typename?: 'GraphqlAddresses';
      place?: string | null;
      state: string;
      zipCode: string;
      addressLine2?: string | null;
      addressLine1: string;
      city: string;
      country: string;
    } | null;
    InternalNotes?: Array<{
      __typename?: 'GraphqlInternalNote';
      note: string;
      serviceRequestId?: number | null;
    }> | null;
  };
};

export type UserApprovalSocialEventMutationVariables = Exact<{
  action: ApprovalServiceRequestStatus;
  customerId: Scalars['Int']['input'];
  socialEventId: Scalars['Int']['input'];
}>;

export type UserApprovalSocialEventMutation = {
  __typename?: 'Mutation';
  userApprovalSocialEvent: {
    __typename?: 'GraphqlSocialEvent';
    cancellationReason?: string | null;
    createdAt: Date;
    description?: string | null;
    eventName: string;
    requestType: ServiceRequestType;
    requestedBy: number;
    scheduledAt?: Date | null;
    status: SocialEventStatus;
    Address?: {
      __typename?: 'GraphqlAddresses';
      state: string;
      place?: string | null;
      country: string;
      city: string;
      addressLine2?: string | null;
      addressLine1: string;
    } | null;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      firstName?: string | null;
      fullName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      role?: GlobalRole | null;
      profilePicture?: string | null;
      email?: string | null;
    };
  };
};

export type GetCircleRequestQueryVariables = Exact<{
  getCircleRequestId: Scalars['Float']['input'];
}>;

export type GetCircleRequestQuery = {
  __typename?: 'Query';
  getCircleRequest: {
    __typename?: 'GraphqlCircleRequest';
    id: number;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    description?: string | null;
    recurring: boolean;
    frequency?: RecurringFrequencyType | null;
    AcceptedBy?: {
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      profilePicture?: string | null;
      role?: GlobalRole | null;
      email?: string | null;
    } | null;
    Timeline?: Array<{
      __typename?: 'GraphqlTimeline';
      id: number;
      userId?: number | null;
      customerId?: number | null;
      title: string;
      description: string;
      createdAt: Date;
      isAppVisible: boolean;
      User?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        createdAt: Date;
        updatedAt: Date;
      } | null;
    }> | null;
    Address?: {
      __typename?: 'GraphqlAddresses';
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      country: string;
      place?: string | null;
      state: string;
      zipCode: string;
    } | null;
    ride?: {
      __typename?: 'Ride';
      type: RideType;
      waitTime?: string | null;
      dropoffAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        place?: string | null;
        state: string;
      };
      pickupAddress: {
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        country: string;
        city: string;
        place?: string | null;
        state: string;
        zipCode: string;
      };
      stops?: Array<{
        __typename?: 'GraphqlRideAddress';
        addressLine1: string;
        addressLine2?: string | null;
        city: string;
        country: string;
        place?: string | null;
        state: string;
        zipCode: string;
      }> | null;
    } | null;
    AskTo: Array<{
      __typename?: 'GraphqlUser';
      id: number;
      fullName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      role?: GlobalRole | null;
      email?: string | null;
    }>;
    RequestedBy: {
      __typename?: 'GraphqlUser';
      fullName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      role?: GlobalRole | null;
      profilePicture?: string | null;
      email?: string | null;
    };
    RequestCategory?: {
      __typename?: 'GraphqlRequestCategory';
      id: string;
      name: string;
    } | null;
  };
};

export type UserCancelCircleRequestMutationVariables = Exact<{
  circleRequestId: Scalars['Int']['input'];
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
}>;

export type UserCancelCircleRequestMutation = {
  __typename?: 'Mutation';
  userCancelCircleRequest: {
    __typename?: 'GraphqlCircleRequest';
    id: number;
    requestType: ServiceRequestType;
    requestedBy: number;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    updatedAt: Date;
    description?: string | null;
  };
};

export type UpdateCircleRequestTimeMutationVariables = Exact<{
  newTime: Scalars['DateTime']['input'];
  circleRequestId: Scalars['Int']['input'];
  untilTime?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type UpdateCircleRequestTimeMutation = {
  __typename?: 'Mutation';
  updateCircleRequestTime: {
    __typename?: 'GraphqlCircleRequest';
    id: number;
    requestedBy: number;
    requestType: ServiceRequestType;
    scheduledAt?: Date | null;
    status: ServiceRequestStatus;
    updatedAt: Date;
  };
};

export type ListRequestCategoriesQueryVariables = Exact<{
  circleRequest?: InputMaybe<Scalars['Boolean']['input']>;
  serviceRequest?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ListRequestCategoriesQuery = {
  __typename?: 'Query';
  listRequestCategories: Array<{
    __typename?: 'GraphqlRequestCategory';
    id: string;
    name: string;
    serviceRequest: boolean;
    circleRequest: boolean;
    createdAt: Date;
    updatedAt: Date;
  }>;
};

export type ReviewServiceRequestMutationVariables = Exact<{
  data: CreateServiceRequestReviewInput;
}>;

export type ReviewServiceRequestMutation = {
  __typename?: 'Mutation';
  reviewServiceRequest: {
    __typename?: 'GraphqlServiceRequestReview';
    serviceRequestId: number;
    updatedAt: Date;
    createdAt: Date;
  };
};

export type GetZipCodeMatchesBusinessRulesQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;

export type GetZipCodeMatchesBusinessRulesQuery = {
  __typename?: 'Query';
  getZipCodeMatchesBusinessRules: {
    __typename?: 'GetZipCodeMatchesBusinessRules';
    success: boolean;
    message: string;
  };
};

export type GetStripeTransactionsQueryVariables = Exact<{
  filter: StripeTransactionFilter;
}>;

export type GetStripeTransactionsQuery = {
  __typename?: 'Query';
  getStripeTransactions: {
    __typename?: 'GetStripeTransactionOutput';
    transactions: Array<{
      __typename?: 'GraphqlStripeTransaction';
      status: string;
      amount: number;
      activityId: number;
      stripeTransactionId: string;
    }>;
  };
};

export type GetServiceRequestReviewQueryVariables = Exact<{
  serviceRequestId: Scalars['Int']['input'];
}>;

export type GetServiceRequestReviewQuery = {
  __typename?: 'Query';
  getServiceRequestReview?: {
    __typename?: 'GraphqlServiceRequestReview';
    rating: number;
  } | null;
};

export type GetDefaultTimezoneQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultTimezoneQuery = {
  __typename?: 'Query';
  getDefaultTimezone: { __typename?: 'GetDefaultTimezone'; timezone: string };
};

export type CreateAddressMutationVariables = Exact<{
  createAddressInput: CreateAddressInput;
}>;

export type CreateAddressMutation = {
  __typename?: 'Mutation';
  createAddress: { __typename?: 'GraphqlAddresses'; id: number };
};

export type UpdateAddressMutationVariables = Exact<{
  updateAddressInput: UpdateAddressInput;
  updateAddressId: Scalars['Int']['input'];
}>;

export type UpdateAddressMutation = {
  __typename?: 'Mutation';
  updateAddress: { __typename?: 'GraphqlAddresses'; id: number };
};

export type DeleteAddressMutationVariables = Exact<{
  deleteAddressId: Scalars['Int']['input'];
}>;

export type DeleteAddressMutation = {
  __typename?: 'Mutation';
  deleteAddress: { __typename?: 'DeleteAddressOutput'; success: boolean };
};

export type GetAddressesQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;

export type GetAddressesQuery = {
  __typename?: 'Query';
  getAddresses: Array<{
    __typename?: 'GraphqlAddresses';
    id: number;
    customerId: number;
    addressLine1: string;
    addressLine2?: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isDefault: boolean;
    createdAt: Date;
    updatedAt: Date;
    place?: string | null;
  }>;
};

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['Float']['input'];
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'GetCustomerOutput';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    createdBy: number;
    createdAt: Date;
    updatedAt: Date;
    addresses?: Array<{
      __typename?: 'GraphqlAddresses';
      id: number;
      addressLine1: string;
      addressLine2?: string | null;
      city: string;
      customerId: number;
      isDefault: boolean;
      state: string;
      zipCode: string;
      place?: string | null;
    }> | null;
    users?: Array<{
      __typename?: 'UserGetCustomerOutput';
      role: string;
      user?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
        createdAt: Date;
      } | null;
    }> | null;
    UserCustomerRoles?: Array<{
      __typename?: 'GraphqlUserRole';
      role: Role;
      Users?: {
        __typename?: 'GraphqlUser';
        id: number;
        fullName?: string | null;
      } | null;
    }> | null;
  };
};

export type SetAppFeaturesSelectedMutationVariables = Exact<{
  appFeaturesSelected: SelectAppFeaturesInput;
}>;

export type SetAppFeaturesSelectedMutation = {
  __typename?: 'Mutation';
  setAppFeaturesSelected: {
    __typename?: 'GraphqlAppFeaturesSelected';
    id: number;
    userId: number;
    seniorMember: boolean;
    familyPartner: boolean;
    circleMember: boolean;
    createdAt: Date;
    updatedAt: Date;
    User: { __typename?: 'GraphqlUser'; id: number; email?: string | null };
  };
};

export type UpdateCurrentUserMutationVariables = Exact<{
  updateUserInput: UpdateCurrentUserInput;
}>;

export type UpdateCurrentUserMutation = {
  __typename?: 'Mutation';
  updateCurrentUser: {
    __typename?: 'GraphqlUser';
    firebaseToken?: string | null;
    id: number;
    email?: string | null;
  };
};

export type GetUserRelatedCustomersQueryVariables = Exact<{
  roles?: InputMaybe<Array<AppUser> | AppUser>;
  userId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetUserRelatedCustomersQuery = {
  __typename?: 'Query';
  getUserRelatedCustomers: Array<{
    __typename?: 'GraphqlCustomer';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
    email?: string | null;
    createdAt: Date;
    updatedAt: Date;
    createdBy: number;
    UserCustomerRoles?: Array<{
      __typename?: 'GraphqlUserRole';
      id: number;
      userId: number;
      customerId?: number | null;
      role: Role;
      createdAt: Date;
      updatedAt: Date;
      Users?: {
        __typename?: 'GraphqlUser';
        id: number;
        createdAt: Date;
        updatedAt: Date;
        fullName?: string | null;
        lastName?: string | null;
        firstName?: string | null;
      } | null;
    }> | null;
  }>;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteAccount: { __typename?: 'DeleteAccountOutput'; success: boolean };
};

export type SaveFirebaseTokenMutationVariables = Exact<{
  firebaseToken: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
}>;

export type SaveFirebaseTokenMutation = {
  __typename?: 'Mutation';
  saveFirebaseToken: {
    __typename?: 'GraphqlUserDevice';
    id: number;
    uuid: string;
  };
};

export type GetVisitSummariesByCoachIdQueryVariables = Exact<{
  coachVisitId: Scalars['Float']['input'];
}>;

export type GetVisitSummariesByCoachIdQuery = {
  __typename?: 'Query';
  getVisitSummariesByCoachId: {
    __typename?: 'GetVisitSummaryByCoachVisitIdOutput';
    results: Array<{
      __typename?: 'GraphqlVisitSummary';
      body: string;
      createdBy: number;
      createdAt: Date;
      CoachVisit?: {
        __typename?: 'GraphqlCoachVisit';
        Coach: {
          __typename?: 'GraphqlUser';
          firstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
        };
      } | null;
    }>;
  };
};

export const GetUserInvitesByCustomerDocument = gql`
  query GetUserInvitesByCustomer($customerId: Int!) {
    getUserInvitesByCustomer(customerId: $customerId) {
      id
      name
      email
      phoneNumber
      userId
      inviteType
      customerId
      invitedBy
      status
      sentVia
      createdAt
    }
  }
`;

/**
 * __useGetUserInvitesByCustomerQuery__
 *
 * To run a query within a React component, call `useGetUserInvitesByCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInvitesByCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInvitesByCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetUserInvitesByCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserInvitesByCustomerQuery,
    GetUserInvitesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserInvitesByCustomerQuery,
    GetUserInvitesByCustomerQueryVariables
  >(GetUserInvitesByCustomerDocument, options);
}
export function useGetUserInvitesByCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInvitesByCustomerQuery,
    GetUserInvitesByCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserInvitesByCustomerQuery,
    GetUserInvitesByCustomerQueryVariables
  >(GetUserInvitesByCustomerDocument, options);
}
export type GetUserInvitesByCustomerQueryHookResult = ReturnType<
  typeof useGetUserInvitesByCustomerQuery
>;
export type GetUserInvitesByCustomerLazyQueryHookResult = ReturnType<
  typeof useGetUserInvitesByCustomerLazyQuery
>;
export type GetUserInvitesByCustomerQueryResult = Apollo.QueryResult<
  GetUserInvitesByCustomerQuery,
  GetUserInvitesByCustomerQueryVariables
>;
export const ResendUserInviteDocument = gql`
  mutation ResendUserInvite($userInviteData: UserInviteResendInput!) {
    resendUserInvite(userInviteData: $userInviteData) {
      success
    }
  }
`;
export type ResendUserInviteMutationFn = Apollo.MutationFunction<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>;

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      userInviteData: // value for 'userInviteData'
 *   },
 * });
 */
export function useResendUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendUserInviteMutation,
    ResendUserInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendUserInviteMutation,
    ResendUserInviteMutationVariables
  >(ResendUserInviteDocument, options);
}
export type ResendUserInviteMutationHookResult = ReturnType<
  typeof useResendUserInviteMutation
>;
export type ResendUserInviteMutationResult =
  Apollo.MutationResult<ResendUserInviteMutation>;
export type ResendUserInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables
>;
export const DeleteUserInviteDocument = gql`
  mutation DeleteUserInvite($userInviteId: Int!) {
    deleteUserInvite(userInviteId: $userInviteId) {
      success
    }
  }
`;
export type DeleteUserInviteMutationFn = Apollo.MutationFunction<
  DeleteUserInviteMutation,
  DeleteUserInviteMutationVariables
>;

/**
 * __useDeleteUserInviteMutation__
 *
 * To run a mutation, you first call `useDeleteUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserInviteMutation, { data, loading, error }] = useDeleteUserInviteMutation({
 *   variables: {
 *      userInviteId: // value for 'userInviteId'
 *   },
 * });
 */
export function useDeleteUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserInviteMutation,
    DeleteUserInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserInviteMutation,
    DeleteUserInviteMutationVariables
  >(DeleteUserInviteDocument, options);
}
export type DeleteUserInviteMutationHookResult = ReturnType<
  typeof useDeleteUserInviteMutation
>;
export type DeleteUserInviteMutationResult =
  Apollo.MutationResult<DeleteUserInviteMutation>;
export type DeleteUserInviteMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserInviteMutation,
  DeleteUserInviteMutationVariables
>;
export const LeaveCircleDocument = gql`
  mutation LeaveCircle {
    leaveCircle {
      success
    }
  }
`;
export type LeaveCircleMutationFn = Apollo.MutationFunction<
  LeaveCircleMutation,
  LeaveCircleMutationVariables
>;

/**
 * __useLeaveCircleMutation__
 *
 * To run a mutation, you first call `useLeaveCircleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCircleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCircleMutation, { data, loading, error }] = useLeaveCircleMutation({
 *   variables: {
 *   },
 * });
 */
export function useLeaveCircleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveCircleMutation,
    LeaveCircleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveCircleMutation, LeaveCircleMutationVariables>(
    LeaveCircleDocument,
    options
  );
}
export type LeaveCircleMutationHookResult = ReturnType<
  typeof useLeaveCircleMutation
>;
export type LeaveCircleMutationResult =
  Apollo.MutationResult<LeaveCircleMutation>;
export type LeaveCircleMutationOptions = Apollo.BaseMutationOptions<
  LeaveCircleMutation,
  LeaveCircleMutationVariables
>;
export const LeaveCircleAsFamilyPartnerDocument = gql`
  mutation LeaveCircleAsFamilyPartner {
    leaveCircleAsFamilyPartner {
      success
    }
  }
`;
export type LeaveCircleAsFamilyPartnerMutationFn = Apollo.MutationFunction<
  LeaveCircleAsFamilyPartnerMutation,
  LeaveCircleAsFamilyPartnerMutationVariables
>;

/**
 * __useLeaveCircleAsFamilyPartnerMutation__
 *
 * To run a mutation, you first call `useLeaveCircleAsFamilyPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCircleAsFamilyPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCircleAsFamilyPartnerMutation, { data, loading, error }] = useLeaveCircleAsFamilyPartnerMutation({
 *   variables: {
 *   },
 * });
 */
export function useLeaveCircleAsFamilyPartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveCircleAsFamilyPartnerMutation,
    LeaveCircleAsFamilyPartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveCircleAsFamilyPartnerMutation,
    LeaveCircleAsFamilyPartnerMutationVariables
  >(LeaveCircleAsFamilyPartnerDocument, options);
}
export type LeaveCircleAsFamilyPartnerMutationHookResult = ReturnType<
  typeof useLeaveCircleAsFamilyPartnerMutation
>;
export type LeaveCircleAsFamilyPartnerMutationResult =
  Apollo.MutationResult<LeaveCircleAsFamilyPartnerMutation>;
export type LeaveCircleAsFamilyPartnerMutationOptions =
  Apollo.BaseMutationOptions<
    LeaveCircleAsFamilyPartnerMutation,
    LeaveCircleAsFamilyPartnerMutationVariables
  >;
export const GetActivitiesDocument = gql`
  query GetActivities($filter: ActivitiesFilter!, $pageSize: Int, $page: Int) {
    getActivities(filter: $filter, pageSize: $pageSize, page: $page) {
      requests {
        ... on GraphqlServiceRequest {
          id
          status
          requestType
          recurring
          category
          scheduledAt
          acceptedAt
          createdAt
          RequestedBy {
            id
            fullName
          }
          RequestCategory {
            id
            name
          }
          RequestedBy {
            id
            fullName
          }
          CreatedBy {
            id
            fullName
          }
          Customer {
            id
            fullName
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              state
              zipCode
              country
              isDefault
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              state
              isDefault
            }
            stops {
              addressLine1
              addressLine2
              city
              state
              isDefault
            }
          }
        }
        ... on GraphqlCircleRequest {
          id
          status
          requestType
          recurring
          circleCategory: category
          scheduledAt
          acceptedAt
          createdAt
          RequestedBy {
            id
            fullName
          }
          RequestCategory {
            id
            name
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
            stops {
              addressLine1
              addressLine2
              city
              state
              country
              isDefault
            }
          }
        }
        ... on GraphqlCustomerSocialEvent {
          id
          eventName
          recurring
          status
          scheduledAt
          requestType
          createdAt
          updatedAt
          RequestedBy {
            id
            fullName
          }
          Address {
            addressLine1
          }
          Customer {
            id
            fullName
          }
        }
        ... on GraphqlCoachVisit {
          id
          recurring
          statusCoachVisit: status
          scheduledAt
          requestType
          createdAt
          RequestedBy {
            id
            fullName
          }
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
    }
  }
`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export type GetActivitiesQueryHookResult = ReturnType<
  typeof useGetActivitiesQuery
>;
export type GetActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesLazyQuery
>;
export type GetActivitiesQueryResult = Apollo.QueryResult<
  GetActivitiesQuery,
  GetActivitiesQueryVariables
>;
export const GetActivitiesGroupedByDateDocument = gql`
  query GetActivitiesGroupedByDate(
    $filter: ActivitiesGroupedByDateFilter!
    $lastValue: DateTime
  ) {
    getActivitiesGroupedByDate(filter: $filter, lastValue: $lastValue) {
      requests {
        id
        timeline {
          day
          month
          weekDay
        }
        activities {
          ... on GraphqlServiceRequest {
            id
            status
            requestType
            recurring
            category
            scheduledAt
            acceptedAt
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            RequestCategory {
              id
              name
            }
            ride {
              type
              waitTime
              pickupAddress {
                addressLine1
                addressLine2
                city
                state
                zipCode
                country
                isDefault
              }
              dropoffAddress {
                addressLine1
                addressLine2
                city
                state
                isDefault
              }
              stops {
                addressLine1
                addressLine2
                city
                state
                isDefault
              }
            }
          }
          ... on GraphqlCircleRequest {
            id
            status
            requestType
            recurring
            circleCategory: category
            scheduledAt
            acceptedAt
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            RequestCategory {
              id
              name
            }
            ride {
              type
              waitTime
              pickupAddress {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
              dropoffAddress {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
              stops {
                addressLine1
                addressLine2
                city
                state
                country
                isDefault
              }
            }
          }
          ... on GraphqlCustomerSocialEvent {
            id
            eventName
            recurring
            status
            scheduledAt
            requestType
            createdAt
            updatedAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
            Address {
              addressLine1
            }
            Customer {
              id
              fullName
            }
          }
          ... on GraphqlCoachVisit {
            id
            recurring
            statusCoachVisit: status
            scheduledAt
            requestType
            createdAt
            RequestedBy {
              id
              fullName
            }
            CancelledBy {
              id
              fullName
            }
          }
        }
      }
      meta {
        lastValue
        isLastPage
      }
    }
  }
`;

/**
 * __useGetActivitiesGroupedByDateQuery__
 *
 * To run a query within a React component, call `useGetActivitiesGroupedByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesGroupedByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesGroupedByDateQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      lastValue: // value for 'lastValue'
 *   },
 * });
 */
export function useGetActivitiesGroupedByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitiesGroupedByDateQuery,
    GetActivitiesGroupedByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivitiesGroupedByDateQuery,
    GetActivitiesGroupedByDateQueryVariables
  >(GetActivitiesGroupedByDateDocument, options);
}
export function useGetActivitiesGroupedByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesGroupedByDateQuery,
    GetActivitiesGroupedByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivitiesGroupedByDateQuery,
    GetActivitiesGroupedByDateQueryVariables
  >(GetActivitiesGroupedByDateDocument, options);
}
export type GetActivitiesGroupedByDateQueryHookResult = ReturnType<
  typeof useGetActivitiesGroupedByDateQuery
>;
export type GetActivitiesGroupedByDateLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesGroupedByDateLazyQuery
>;
export type GetActivitiesGroupedByDateQueryResult = Apollo.QueryResult<
  GetActivitiesGroupedByDateQuery,
  GetActivitiesGroupedByDateQueryVariables
>;
export const GetCustomerAddressesDocument = gql`
  query GetCustomerAddresses($customerId: Float!) {
    getAddresses(customerId: $customerId) {
      id
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
      isDefault
    }
  }
`;

/**
 * __useGetCustomerAddressesQuery__
 *
 * To run a query within a React component, call `useGetCustomerAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAddressesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >(GetCustomerAddressesDocument, options);
}
export function useGetCustomerAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerAddressesQuery,
    GetCustomerAddressesQueryVariables
  >(GetCustomerAddressesDocument, options);
}
export type GetCustomerAddressesQueryHookResult = ReturnType<
  typeof useGetCustomerAddressesQuery
>;
export type GetCustomerAddressesLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAddressesLazyQuery
>;
export type GetCustomerAddressesQueryResult = Apollo.QueryResult<
  GetCustomerAddressesQuery,
  GetCustomerAddressesQueryVariables
>;
export const CreateServiceRequestDocument = gql`
  mutation CreateServiceRequest($data: CreateServiceRequestInput!) {
    createServiceRequest(data: $data) {
      id
    }
  }
`;
export type CreateServiceRequestMutationFn = Apollo.MutationFunction<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceRequestMutation,
    CreateServiceRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceRequestMutation,
    CreateServiceRequestMutationVariables
  >(CreateServiceRequestDocument, options);
}
export type CreateServiceRequestMutationHookResult = ReturnType<
  typeof useCreateServiceRequestMutation
>;
export type CreateServiceRequestMutationResult =
  Apollo.MutationResult<CreateServiceRequestMutation>;
export type CreateServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceRequestMutation,
  CreateServiceRequestMutationVariables
>;
export const CreateCircleRequestDocument = gql`
  mutation CreateCircleRequest($data: CreateCircleRequestInput!) {
    createCircleRequest(data: $data) {
      id
    }
  }
`;
export type CreateCircleRequestMutationFn = Apollo.MutationFunction<
  CreateCircleRequestMutation,
  CreateCircleRequestMutationVariables
>;

/**
 * __useCreateCircleRequestMutation__
 *
 * To run a mutation, you first call `useCreateCircleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCircleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCircleRequestMutation, { data, loading, error }] = useCreateCircleRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCircleRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCircleRequestMutation,
    CreateCircleRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCircleRequestMutation,
    CreateCircleRequestMutationVariables
  >(CreateCircleRequestDocument, options);
}
export type CreateCircleRequestMutationHookResult = ReturnType<
  typeof useCreateCircleRequestMutation
>;
export type CreateCircleRequestMutationResult =
  Apollo.MutationResult<CreateCircleRequestMutation>;
export type CreateCircleRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateCircleRequestMutation,
  CreateCircleRequestMutationVariables
>;
export const GetCircleMemberActivitiesDocument = gql`
  query getCircleMemberActivities(
    $filter: AppointmentActivitiesFilter!
    $page: Int
    $pageSize: Int
  ) {
    getCircleMemberActivities(
      filter: $filter
      page: $page
      pageSize: $pageSize
    ) {
      activities {
        id
        userId
        serviceRequestId
        circleRequestId
        status
        CircleRequest {
          RequestCategory {
            id
            name
          }
          id
          customerId
          category
          otherCategory
          recurring
          frequency
          description
          addressId
          status
          requestedBy
          scheduledAt
          acceptedAt
          requestType
          tentativeSchedule
          isAvailabilityFlexible
          createdAt
          updatedAt
          acceptedBy
          askTo
        }
      }
    }
  }
`;

/**
 * __useGetCircleMemberActivitiesQuery__
 *
 * To run a query within a React component, call `useGetCircleMemberActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCircleMemberActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCircleMemberActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCircleMemberActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCircleMemberActivitiesQuery,
    GetCircleMemberActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCircleMemberActivitiesQuery,
    GetCircleMemberActivitiesQueryVariables
  >(GetCircleMemberActivitiesDocument, options);
}
export function useGetCircleMemberActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCircleMemberActivitiesQuery,
    GetCircleMemberActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCircleMemberActivitiesQuery,
    GetCircleMemberActivitiesQueryVariables
  >(GetCircleMemberActivitiesDocument, options);
}
export type GetCircleMemberActivitiesQueryHookResult = ReturnType<
  typeof useGetCircleMemberActivitiesQuery
>;
export type GetCircleMemberActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetCircleMemberActivitiesLazyQuery
>;
export type GetCircleMemberActivitiesQueryResult = Apollo.QueryResult<
  GetCircleMemberActivitiesQuery,
  GetCircleMemberActivitiesQueryVariables
>;
export const GetAgentActivitiesDocument = gql`
  query GetAgentActivities(
    $filter: AppointmentActivitiesFilter!
    $page: Int
    $pageSize: Int
  ) {
    getAgentActivities(filter: $filter, page: $page, pageSize: $pageSize) {
      activities {
        id
        status
        AppointmentRequestPayments {
          id
        }
        ServiceRequest {
          id
          customerId
          category
          otherCategory
          recurring
          frequency
          description
          addressId
          status
          requestedBy
          scheduledAt
          acceptedAt
          requestType
          tentativeSchedule
          isAvailabilityFlexible
          createdAt
          updatedAt
          RequestCategory {
            id
            name
          }
          Address {
            id
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          ride {
            type
            waitTime
            pickupAddress {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
            dropoffAddress {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
            stops {
              addressLine1
              addressLine2
              city
              country
              state
              zipCode
              place
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAgentActivitiesQuery__
 *
 * To run a query within a React component, call `useGetAgentActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAgentActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgentActivitiesQuery,
    GetAgentActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAgentActivitiesQuery,
    GetAgentActivitiesQueryVariables
  >(GetAgentActivitiesDocument, options);
}
export function useGetAgentActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentActivitiesQuery,
    GetAgentActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAgentActivitiesQuery,
    GetAgentActivitiesQueryVariables
  >(GetAgentActivitiesDocument, options);
}
export type GetAgentActivitiesQueryHookResult = ReturnType<
  typeof useGetAgentActivitiesQuery
>;
export type GetAgentActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetAgentActivitiesLazyQuery
>;
export type GetAgentActivitiesQueryResult = Apollo.QueryResult<
  GetAgentActivitiesQuery,
  GetAgentActivitiesQueryVariables
>;
export const GetCircleMemberActivityByAppointmentIdDocument = gql`
  query GetCircleMemberActivityByAppointmentId($appointmentRequestId: Int!) {
    getCircleMemberActivityByAppointmentId(
      appointmentRequestId: $appointmentRequestId
    ) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      SentTo {
        fullName
        profilePicture
        id
        talkJsId
      }
      CircleRequest {
        id
        customerId
        category
        otherCategory
        recurring
        frequency
        description
        addressId
        status
        lastStatusUpdate
        requestedBy
        scheduledAt
        acceptedAt
        requestType
        tentativeSchedule
        isAvailabilityFlexible
        createdAt
        updatedAt
        acceptedBy
        askTo
        availabilities {
          date
          from
          until
        }
        ride {
          type
          waitTime
          pickupAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          dropoffAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          stops {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
        }
        RequestedBy {
          id
          fullName
          firstName
          lastName
          profilePicture
        }
        RequestCategory {
          id
          name
        }
        AcceptedBy {
          id
          fullName
        }
        AskTo {
          id
          fullName
        }
        Address {
          id
          addressLine1
          addressLine2
          city
          country
          state
          zipCode
          place
        }
        Customer {
          firstName
          lastName
          fullName
          UserCustomerRoles {
            userId
            role
            Users {
              id
              fullName
              profilePicture
              firstName
              lastName
            }
          }
        }
        Timeline {
          id
          title
          description
          createdAt
          User {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetCircleMemberActivityByAppointmentIdQuery__
 *
 * To run a query within a React component, call `useGetCircleMemberActivityByAppointmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCircleMemberActivityByAppointmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCircleMemberActivityByAppointmentIdQuery({
 *   variables: {
 *      appointmentRequestId: // value for 'appointmentRequestId'
 *   },
 * });
 */
export function useGetCircleMemberActivityByAppointmentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCircleMemberActivityByAppointmentIdQuery,
    GetCircleMemberActivityByAppointmentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCircleMemberActivityByAppointmentIdQuery,
    GetCircleMemberActivityByAppointmentIdQueryVariables
  >(GetCircleMemberActivityByAppointmentIdDocument, options);
}
export function useGetCircleMemberActivityByAppointmentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCircleMemberActivityByAppointmentIdQuery,
    GetCircleMemberActivityByAppointmentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCircleMemberActivityByAppointmentIdQuery,
    GetCircleMemberActivityByAppointmentIdQueryVariables
  >(GetCircleMemberActivityByAppointmentIdDocument, options);
}
export type GetCircleMemberActivityByAppointmentIdQueryHookResult = ReturnType<
  typeof useGetCircleMemberActivityByAppointmentIdQuery
>;
export type GetCircleMemberActivityByAppointmentIdLazyQueryHookResult =
  ReturnType<typeof useGetCircleMemberActivityByAppointmentIdLazyQuery>;
export type GetCircleMemberActivityByAppointmentIdQueryResult =
  Apollo.QueryResult<
    GetCircleMemberActivityByAppointmentIdQuery,
    GetCircleMemberActivityByAppointmentIdQueryVariables
  >;
export const GetAgentActivityByAppointmentIdDocument = gql`
  query GetAgentActivityByAppointmentId($appointmentRequestId: Int!) {
    getAgentActivityByAppointmentId(
      appointmentRequestId: $appointmentRequestId
    ) {
      id
      userId
      serviceRequestId
      circleRequestId
      status
      createdAt
      updatedAt
      scheduledAt
      SentTo {
        fullName
        profilePicture
        id
        talkJsId
      }
      AppointmentRequestPayments {
        id
        amount
        appointmentRequestId
        createdAt
        estimateHours
        type
        updatedAt
      }
      ServiceRequest {
        id
        customerId
        category
        otherCategory
        recurring
        frequency
        description
        addressId
        status
        lastStatusUpdate
        requestedBy
        scheduledAt
        acceptedAt
        requestType
        tentativeSchedule
        isAvailabilityFlexible
        createdAt
        updatedAt
        serviceFee
        taxFee
        availabilities {
          date
          from
          until
        }
        ride {
          type
          waitTime
          pickupAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          dropoffAddress {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
          stops {
            addressLine1
            addressLine2
            city
            country
            state
            zipCode
            place
          }
        }
        RequestedBy {
          id
          fullName
          firstName
          lastName
          profilePicture
        }
        RequestCategory {
          id
          name
        }
        Customer {
          firstName
          lastName
          fullName
          UserCustomerRoles {
            userId
            role
            Users {
              id
              fullName
              profilePicture
              firstName
              lastName
            }
          }
        }
        Address {
          id
          addressLine1
          addressLine2
          city
          country
          state
          zipCode
          place
        }
        Timeline {
          id
          title
          description
          createdAt
          User {
            id
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetAgentActivityByAppointmentIdQuery__
 *
 * To run a query within a React component, call `useGetAgentActivityByAppointmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgentActivityByAppointmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgentActivityByAppointmentIdQuery({
 *   variables: {
 *      appointmentRequestId: // value for 'appointmentRequestId'
 *   },
 * });
 */
export function useGetAgentActivityByAppointmentIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAgentActivityByAppointmentIdQuery,
    GetAgentActivityByAppointmentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAgentActivityByAppointmentIdQuery,
    GetAgentActivityByAppointmentIdQueryVariables
  >(GetAgentActivityByAppointmentIdDocument, options);
}
export function useGetAgentActivityByAppointmentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAgentActivityByAppointmentIdQuery,
    GetAgentActivityByAppointmentIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAgentActivityByAppointmentIdQuery,
    GetAgentActivityByAppointmentIdQueryVariables
  >(GetAgentActivityByAppointmentIdDocument, options);
}
export type GetAgentActivityByAppointmentIdQueryHookResult = ReturnType<
  typeof useGetAgentActivityByAppointmentIdQuery
>;
export type GetAgentActivityByAppointmentIdLazyQueryHookResult = ReturnType<
  typeof useGetAgentActivityByAppointmentIdLazyQuery
>;
export type GetAgentActivityByAppointmentIdQueryResult = Apollo.QueryResult<
  GetAgentActivityByAppointmentIdQuery,
  GetAgentActivityByAppointmentIdQueryVariables
>;
export const UpdateAppointmentRequestDocument = gql`
  mutation UpdateAppointmentRequest(
    $updateAppointmentRequestInput: UpdateAppointmentRequestInput!
  ) {
    updateAppointmentRequest(
      updateAppointmentRequestInput: $updateAppointmentRequestInput
    ) {
      id
      CircleRequest {
        id
      }
      ServiceRequest {
        id
      }
    }
  }
`;
export type UpdateAppointmentRequestMutationFn = Apollo.MutationFunction<
  UpdateAppointmentRequestMutation,
  UpdateAppointmentRequestMutationVariables
>;

/**
 * __useUpdateAppointmentRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentRequestMutation, { data, loading, error }] = useUpdateAppointmentRequestMutation({
 *   variables: {
 *      updateAppointmentRequestInput: // value for 'updateAppointmentRequestInput'
 *   },
 * });
 */
export function useUpdateAppointmentRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentRequestMutation,
    UpdateAppointmentRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentRequestMutation,
    UpdateAppointmentRequestMutationVariables
  >(UpdateAppointmentRequestDocument, options);
}
export type UpdateAppointmentRequestMutationHookResult = ReturnType<
  typeof useUpdateAppointmentRequestMutation
>;
export type UpdateAppointmentRequestMutationResult =
  Apollo.MutationResult<UpdateAppointmentRequestMutation>;
export type UpdateAppointmentRequestMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAppointmentRequestMutation,
    UpdateAppointmentRequestMutationVariables
  >;
export const GetActivitiesAlertsDocument = gql`
  query GetActivitiesAlerts {
    getActivitiesAlerts {
      status
      id
    }
  }
`;

/**
 * __useGetActivitiesAlertsQuery__
 *
 * To run a query within a React component, call `useGetActivitiesAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivitiesAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivitiesAlertsQuery,
    GetActivitiesAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivitiesAlertsQuery,
    GetActivitiesAlertsQueryVariables
  >(GetActivitiesAlertsDocument, options);
}
export function useGetActivitiesAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesAlertsQuery,
    GetActivitiesAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivitiesAlertsQuery,
    GetActivitiesAlertsQueryVariables
  >(GetActivitiesAlertsDocument, options);
}
export type GetActivitiesAlertsQueryHookResult = ReturnType<
  typeof useGetActivitiesAlertsQuery
>;
export type GetActivitiesAlertsLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesAlertsLazyQuery
>;
export type GetActivitiesAlertsQueryResult = Apollo.QueryResult<
  GetActivitiesAlertsQuery,
  GetActivitiesAlertsQueryVariables
>;
export const CreateSuggestedActivityDocument = gql`
  mutation CreateSuggestedActivity(
    $createSuggestedActivityInput: CreateSuggestedActivityInput!
  ) {
    createSuggestedActivity(
      createSuggestedActivityInput: $createSuggestedActivityInput
    ) {
      id
    }
  }
`;
export type CreateSuggestedActivityMutationFn = Apollo.MutationFunction<
  CreateSuggestedActivityMutation,
  CreateSuggestedActivityMutationVariables
>;

/**
 * __useCreateSuggestedActivityMutation__
 *
 * To run a mutation, you first call `useCreateSuggestedActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuggestedActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuggestedActivityMutation, { data, loading, error }] = useCreateSuggestedActivityMutation({
 *   variables: {
 *      createSuggestedActivityInput: // value for 'createSuggestedActivityInput'
 *   },
 * });
 */
export function useCreateSuggestedActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSuggestedActivityMutation,
    CreateSuggestedActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSuggestedActivityMutation,
    CreateSuggestedActivityMutationVariables
  >(CreateSuggestedActivityDocument, options);
}
export type CreateSuggestedActivityMutationHookResult = ReturnType<
  typeof useCreateSuggestedActivityMutation
>;
export type CreateSuggestedActivityMutationResult =
  Apollo.MutationResult<CreateSuggestedActivityMutation>;
export type CreateSuggestedActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateSuggestedActivityMutation,
  CreateSuggestedActivityMutationVariables
>;
export const GenerateAuthIdDocument = gql`
  mutation GenerateAuthId {
    generateAuthId {
      authId
      secureToken
    }
  }
`;
export type GenerateAuthIdMutationFn = Apollo.MutationFunction<
  GenerateAuthIdMutation,
  GenerateAuthIdMutationVariables
>;

/**
 * __useGenerateAuthIdMutation__
 *
 * To run a mutation, you first call `useGenerateAuthIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAuthIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAuthIdMutation, { data, loading, error }] = useGenerateAuthIdMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateAuthIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAuthIdMutation,
    GenerateAuthIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAuthIdMutation,
    GenerateAuthIdMutationVariables
  >(GenerateAuthIdDocument, options);
}
export type GenerateAuthIdMutationHookResult = ReturnType<
  typeof useGenerateAuthIdMutation
>;
export type GenerateAuthIdMutationResult =
  Apollo.MutationResult<GenerateAuthIdMutation>;
export type GenerateAuthIdMutationOptions = Apollo.BaseMutationOptions<
  GenerateAuthIdMutation,
  GenerateAuthIdMutationVariables
>;
export const ExchangeAuthIdDocument = gql`
  mutation ExchangeAuthId($secureToken: String!, $authId: String!) {
    exchangeAuthId(secureToken: $secureToken, authId: $authId) {
      accessToken
      finished
    }
  }
`;
export type ExchangeAuthIdMutationFn = Apollo.MutationFunction<
  ExchangeAuthIdMutation,
  ExchangeAuthIdMutationVariables
>;

/**
 * __useExchangeAuthIdMutation__
 *
 * To run a mutation, you first call `useExchangeAuthIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeAuthIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeAuthIdMutation, { data, loading, error }] = useExchangeAuthIdMutation({
 *   variables: {
 *      secureToken: // value for 'secureToken'
 *      authId: // value for 'authId'
 *   },
 * });
 */
export function useExchangeAuthIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangeAuthIdMutation,
    ExchangeAuthIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExchangeAuthIdMutation,
    ExchangeAuthIdMutationVariables
  >(ExchangeAuthIdDocument, options);
}
export type ExchangeAuthIdMutationHookResult = ReturnType<
  typeof useExchangeAuthIdMutation
>;
export type ExchangeAuthIdMutationResult =
  Apollo.MutationResult<ExchangeAuthIdMutation>;
export type ExchangeAuthIdMutationOptions = Apollo.BaseMutationOptions<
  ExchangeAuthIdMutation,
  ExchangeAuthIdMutationVariables
>;
export const JoinCircleDocument = gql`
  mutation JoinCircle($joinCircleId: Int!, $status: UserInvitesStatus!) {
    joinCircle(id: $joinCircleId, status: $status) {
      Customer {
        firstName
        lastName
      }
      id
      name
      email
      phoneNumber
      inviteType
      userId
      customerId
      invitedBy
      status
      sentVia
      createdAt
    }
  }
`;
export type JoinCircleMutationFn = Apollo.MutationFunction<
  JoinCircleMutation,
  JoinCircleMutationVariables
>;

/**
 * __useJoinCircleMutation__
 *
 * To run a mutation, you first call `useJoinCircleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCircleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCircleMutation, { data, loading, error }] = useJoinCircleMutation({
 *   variables: {
 *      joinCircleId: // value for 'joinCircleId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useJoinCircleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinCircleMutation,
    JoinCircleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinCircleMutation, JoinCircleMutationVariables>(
    JoinCircleDocument,
    options
  );
}
export type JoinCircleMutationHookResult = ReturnType<
  typeof useJoinCircleMutation
>;
export type JoinCircleMutationResult =
  Apollo.MutationResult<JoinCircleMutation>;
export type JoinCircleMutationOptions = Apollo.BaseMutationOptions<
  JoinCircleMutation,
  JoinCircleMutationVariables
>;
export const RefreshAccessTokenDocument = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      accessToken
    }
  }
`;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >(RefreshAccessTokenDocument, options);
}
export type RefreshAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshAccessTokenMutation
>;
export type RefreshAccessTokenMutationResult =
  Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;
export const ResendVerifyEmailDocument = gql`
  mutation ResendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email) {
      success
    }
  }
`;
export type ResendVerifyEmailMutationFn = Apollo.MutationFunction<
  ResendVerifyEmailMutation,
  ResendVerifyEmailMutationVariables
>;

/**
 * __useResendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailMutation, { data, loading, error }] = useResendVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerifyEmailMutation,
    ResendVerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendVerifyEmailMutation,
    ResendVerifyEmailMutationVariables
  >(ResendVerifyEmailDocument, options);
}
export type ResendVerifyEmailMutationHookResult = ReturnType<
  typeof useResendVerifyEmailMutation
>;
export type ResendVerifyEmailMutationResult =
  Apollo.MutationResult<ResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendVerifyEmailMutation,
  ResendVerifyEmailMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout($deviceId: String!) {
    logout(deviceId: $deviceId) {
      success
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const GetSignatureDocument = gql`
  query GetSignature {
    getSignature {
      signature
      talkJsId
      talkJsAppId
    }
  }
`;

/**
 * __useGetSignatureQuery__
 *
 * To run a query within a React component, call `useGetSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSignatureQuery,
    GetSignatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSignatureQuery, GetSignatureQueryVariables>(
    GetSignatureDocument,
    options
  );
}
export function useGetSignatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSignatureQuery,
    GetSignatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSignatureQuery, GetSignatureQueryVariables>(
    GetSignatureDocument,
    options
  );
}
export type GetSignatureQueryHookResult = ReturnType<
  typeof useGetSignatureQuery
>;
export type GetSignatureLazyQueryHookResult = ReturnType<
  typeof useGetSignatureLazyQuery
>;
export type GetSignatureQueryResult = Apollo.QueryResult<
  GetSignatureQuery,
  GetSignatureQueryVariables
>;
export const GetUserParticipantsDocument = gql`
  query GetUserParticipants(
    $filter: GetUserParticipantsFilter
    $conversationId: String
  ) {
    getUserParticipants(filter: $filter, conversationId: $conversationId) {
      users {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        trustedMember
      }
      usersFromTalkJs {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        trustedMember
      }
    }
  }
`;

/**
 * __useGetUserParticipantsQuery__
 *
 * To run a query within a React component, call `useGetUserParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserParticipantsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetUserParticipantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserParticipantsQuery,
    GetUserParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserParticipantsQuery,
    GetUserParticipantsQueryVariables
  >(GetUserParticipantsDocument, options);
}
export function useGetUserParticipantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserParticipantsQuery,
    GetUserParticipantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserParticipantsQuery,
    GetUserParticipantsQueryVariables
  >(GetUserParticipantsDocument, options);
}
export type GetUserParticipantsQueryHookResult = ReturnType<
  typeof useGetUserParticipantsQuery
>;
export type GetUserParticipantsLazyQueryHookResult = ReturnType<
  typeof useGetUserParticipantsLazyQuery
>;
export type GetUserParticipantsQueryResult = Apollo.QueryResult<
  GetUserParticipantsQuery,
  GetUserParticipantsQueryVariables
>;
export const TrustUntrustMembersDocument = gql`
  mutation TrustUntrustMembers(
    $action: TrustUntrustActionType!
    $trustedMembersIds: [Int!]!
  ) {
    trustUntrustMembers(
      action: $action
      trustedMembersIds: $trustedMembersIds
    ) {
      success
      action
    }
  }
`;
export type TrustUntrustMembersMutationFn = Apollo.MutationFunction<
  TrustUntrustMembersMutation,
  TrustUntrustMembersMutationVariables
>;

/**
 * __useTrustUntrustMembersMutation__
 *
 * To run a mutation, you first call `useTrustUntrustMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrustUntrustMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trustUntrustMembersMutation, { data, loading, error }] = useTrustUntrustMembersMutation({
 *   variables: {
 *      action: // value for 'action'
 *      trustedMembersIds: // value for 'trustedMembersIds'
 *   },
 * });
 */
export function useTrustUntrustMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrustUntrustMembersMutation,
    TrustUntrustMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TrustUntrustMembersMutation,
    TrustUntrustMembersMutationVariables
  >(TrustUntrustMembersDocument, options);
}
export type TrustUntrustMembersMutationHookResult = ReturnType<
  typeof useTrustUntrustMembersMutation
>;
export type TrustUntrustMembersMutationResult =
  Apollo.MutationResult<TrustUntrustMembersMutation>;
export type TrustUntrustMembersMutationOptions = Apollo.BaseMutationOptions<
  TrustUntrustMembersMutation,
  TrustUntrustMembersMutationVariables
>;
export const StartConversationDocument = gql`
  mutation StartConversation($startConversationInput: StartConversationInput!) {
    startConversation(startConversationInput: $startConversationInput) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
      custom {
        customerId
        participants
        userIds
      }
      lastMessage {
        attachment {
          url
          size
        }
        conversationId
        createdAt
        editedAt
        id
        location
        origin
        readBy
        senderId
        text
        type
        referencedMessageId
      }
      participants {
        id
        access
        notify
      }
      createdAt
    }
  }
`;
export type StartConversationMutationFn = Apollo.MutationFunction<
  StartConversationMutation,
  StartConversationMutationVariables
>;

/**
 * __useStartConversationMutation__
 *
 * To run a mutation, you first call `useStartConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConversationMutation, { data, loading, error }] = useStartConversationMutation({
 *   variables: {
 *      startConversationInput: // value for 'startConversationInput'
 *   },
 * });
 */
export function useStartConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartConversationMutation,
    StartConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartConversationMutation,
    StartConversationMutationVariables
  >(StartConversationDocument, options);
}
export type StartConversationMutationHookResult = ReturnType<
  typeof useStartConversationMutation
>;
export type StartConversationMutationResult =
  Apollo.MutationResult<StartConversationMutation>;
export type StartConversationMutationOptions = Apollo.BaseMutationOptions<
  StartConversationMutation,
  StartConversationMutationVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      id
      subject
      topicId
      photoUrl
      welcomeMessages
    }
  }
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConversationQuery,
    GetConversationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GetConversationDocument, options);
}
export type GetConversationQueryHookResult = ReturnType<
  typeof useGetConversationQuery
>;
export type GetConversationLazyQueryHookResult = ReturnType<
  typeof useGetConversationLazyQuery
>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const GetBryaPartnerChatIdDocument = gql`
  query GetBryaPartnerChatId {
    getBryaPartnerChatId {
      id
    }
  }
`;

/**
 * __useGetBryaPartnerChatIdQuery__
 *
 * To run a query within a React component, call `useGetBryaPartnerChatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBryaPartnerChatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBryaPartnerChatIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBryaPartnerChatIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBryaPartnerChatIdQuery,
    GetBryaPartnerChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBryaPartnerChatIdQuery,
    GetBryaPartnerChatIdQueryVariables
  >(GetBryaPartnerChatIdDocument, options);
}
export function useGetBryaPartnerChatIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBryaPartnerChatIdQuery,
    GetBryaPartnerChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBryaPartnerChatIdQuery,
    GetBryaPartnerChatIdQueryVariables
  >(GetBryaPartnerChatIdDocument, options);
}
export type GetBryaPartnerChatIdQueryHookResult = ReturnType<
  typeof useGetBryaPartnerChatIdQuery
>;
export type GetBryaPartnerChatIdLazyQueryHookResult = ReturnType<
  typeof useGetBryaPartnerChatIdLazyQuery
>;
export type GetBryaPartnerChatIdQueryResult = Apollo.QueryResult<
  GetBryaPartnerChatIdQuery,
  GetBryaPartnerChatIdQueryVariables
>;
export const GetBryaSupportChatIdDocument = gql`
  query GetBryaSupportChatId {
    getBryaSupportChatId {
      id
    }
  }
`;

/**
 * __useGetBryaSupportChatIdQuery__
 *
 * To run a query within a React component, call `useGetBryaSupportChatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBryaSupportChatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBryaSupportChatIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBryaSupportChatIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBryaSupportChatIdQuery,
    GetBryaSupportChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBryaSupportChatIdQuery,
    GetBryaSupportChatIdQueryVariables
  >(GetBryaSupportChatIdDocument, options);
}
export function useGetBryaSupportChatIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBryaSupportChatIdQuery,
    GetBryaSupportChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBryaSupportChatIdQuery,
    GetBryaSupportChatIdQueryVariables
  >(GetBryaSupportChatIdDocument, options);
}
export type GetBryaSupportChatIdQueryHookResult = ReturnType<
  typeof useGetBryaSupportChatIdQuery
>;
export type GetBryaSupportChatIdLazyQueryHookResult = ReturnType<
  typeof useGetBryaSupportChatIdLazyQuery
>;
export type GetBryaSupportChatIdQueryResult = Apollo.QueryResult<
  GetBryaSupportChatIdQuery,
  GetBryaSupportChatIdQueryVariables
>;
export const GetBryaTeamChatIdDocument = gql`
  query GetBryaTeamChatId {
    getBryaTeamChatId {
      id
    }
  }
`;

/**
 * __useGetBryaTeamChatIdQuery__
 *
 * To run a query within a React component, call `useGetBryaTeamChatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBryaTeamChatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBryaTeamChatIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBryaTeamChatIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBryaTeamChatIdQuery,
    GetBryaTeamChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBryaTeamChatIdQuery,
    GetBryaTeamChatIdQueryVariables
  >(GetBryaTeamChatIdDocument, options);
}
export function useGetBryaTeamChatIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBryaTeamChatIdQuery,
    GetBryaTeamChatIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBryaTeamChatIdQuery,
    GetBryaTeamChatIdQueryVariables
  >(GetBryaTeamChatIdDocument, options);
}
export type GetBryaTeamChatIdQueryHookResult = ReturnType<
  typeof useGetBryaTeamChatIdQuery
>;
export type GetBryaTeamChatIdLazyQueryHookResult = ReturnType<
  typeof useGetBryaTeamChatIdLazyQuery
>;
export type GetBryaTeamChatIdQueryResult = Apollo.QueryResult<
  GetBryaTeamChatIdQuery,
  GetBryaTeamChatIdQueryVariables
>;
export const BlockUserDocument = gql`
  mutation BlockUser($blockedUserId: Int!) {
    blockUser(blockedUserId: $blockedUserId) {
      id
    }
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      blockedUserId: // value for 'blockedUserId'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlockUserMutation,
    BlockUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    options
  );
}
export type BlockUserMutationHookResult = ReturnType<
  typeof useBlockUserMutation
>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>;
export const UnblockUserDocument = gql`
  mutation UnblockUser($unblockUserId: Int!) {
    unblockUser(id: $unblockUserId) {
      id
    }
  }
`;
export type UnblockUserMutationFn = Apollo.MutationFunction<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      unblockUserId: // value for 'unblockUserId'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnblockUserMutation,
    UnblockUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(
    UnblockUserDocument,
    options
  );
}
export type UnblockUserMutationHookResult = ReturnType<
  typeof useUnblockUserMutation
>;
export type UnblockUserMutationResult =
  Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;
export const GetBlockedUsersByBlockerUserIdDocument = gql`
  query GetBlockedUsersByBlockerUserId {
    getBlockedUsersByBlockerUserId {
      id
      blockedUserId
      blockerUserId
    }
  }
`;

/**
 * __useGetBlockedUsersByBlockerUserIdQuery__
 *
 * To run a query within a React component, call `useGetBlockedUsersByBlockerUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockedUsersByBlockerUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockedUsersByBlockerUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlockedUsersByBlockerUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlockedUsersByBlockerUserIdQuery,
    GetBlockedUsersByBlockerUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlockedUsersByBlockerUserIdQuery,
    GetBlockedUsersByBlockerUserIdQueryVariables
  >(GetBlockedUsersByBlockerUserIdDocument, options);
}
export function useGetBlockedUsersByBlockerUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlockedUsersByBlockerUserIdQuery,
    GetBlockedUsersByBlockerUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlockedUsersByBlockerUserIdQuery,
    GetBlockedUsersByBlockerUserIdQueryVariables
  >(GetBlockedUsersByBlockerUserIdDocument, options);
}
export type GetBlockedUsersByBlockerUserIdQueryHookResult = ReturnType<
  typeof useGetBlockedUsersByBlockerUserIdQuery
>;
export type GetBlockedUsersByBlockerUserIdLazyQueryHookResult = ReturnType<
  typeof useGetBlockedUsersByBlockerUserIdLazyQuery
>;
export type GetBlockedUsersByBlockerUserIdQueryResult = Apollo.QueryResult<
  GetBlockedUsersByBlockerUserIdQuery,
  GetBlockedUsersByBlockerUserIdQueryVariables
>;
export const GetBlockerUsersByBlockedUserIdDocument = gql`
  query GetBlockerUsersByBlockedUserId {
    getBlockerUsersByBlockedUserId {
      id
      blockedUserId
      blockerUserId
    }
  }
`;

/**
 * __useGetBlockerUsersByBlockedUserIdQuery__
 *
 * To run a query within a React component, call `useGetBlockerUsersByBlockedUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockerUsersByBlockedUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockerUsersByBlockedUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBlockerUsersByBlockedUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlockerUsersByBlockedUserIdQuery,
    GetBlockerUsersByBlockedUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBlockerUsersByBlockedUserIdQuery,
    GetBlockerUsersByBlockedUserIdQueryVariables
  >(GetBlockerUsersByBlockedUserIdDocument, options);
}
export function useGetBlockerUsersByBlockedUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlockerUsersByBlockedUserIdQuery,
    GetBlockerUsersByBlockedUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBlockerUsersByBlockedUserIdQuery,
    GetBlockerUsersByBlockedUserIdQueryVariables
  >(GetBlockerUsersByBlockedUserIdDocument, options);
}
export type GetBlockerUsersByBlockedUserIdQueryHookResult = ReturnType<
  typeof useGetBlockerUsersByBlockedUserIdQuery
>;
export type GetBlockerUsersByBlockedUserIdLazyQueryHookResult = ReturnType<
  typeof useGetBlockerUsersByBlockedUserIdLazyQuery
>;
export type GetBlockerUsersByBlockedUserIdQueryResult = Apollo.QueryResult<
  GetBlockerUsersByBlockedUserIdQuery,
  GetBlockerUsersByBlockedUserIdQueryVariables
>;
export const GetCoachProfileDocument = gql`
  query GetCoachProfile {
    getCoachProfile {
      id
      firstName
      lastName
      fullName
      bio
      profilePicture
    }
  }
`;

/**
 * __useGetCoachProfileQuery__
 *
 * To run a query within a React component, call `useGetCoachProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoachProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoachProfileQuery,
    GetCoachProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachProfileQuery, GetCoachProfileQueryVariables>(
    GetCoachProfileDocument,
    options
  );
}
export function useGetCoachProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachProfileQuery,
    GetCoachProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoachProfileQuery,
    GetCoachProfileQueryVariables
  >(GetCoachProfileDocument, options);
}
export type GetCoachProfileQueryHookResult = ReturnType<
  typeof useGetCoachProfileQuery
>;
export type GetCoachProfileLazyQueryHookResult = ReturnType<
  typeof useGetCoachProfileLazyQuery
>;
export type GetCoachProfileQueryResult = Apollo.QueryResult<
  GetCoachProfileQuery,
  GetCoachProfileQueryVariables
>;
export const GetUserCoachReviewsDocument = gql`
  query GetUserCoachReviews(
    $sort: ReviewSortInput
    $pageSize: Int
    $page: Int
  ) {
    getUserCoachReviews(sort: $sort, pageSize: $pageSize, page: $page) {
      reviews {
        id
        comment
        rating
        coachId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          id
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
    }
  }
`;

/**
 * __useGetUserCoachReviewsQuery__
 *
 * To run a query within a React component, call `useGetUserCoachReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCoachReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCoachReviewsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetUserCoachReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserCoachReviewsQuery,
    GetUserCoachReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCoachReviewsQuery,
    GetUserCoachReviewsQueryVariables
  >(GetUserCoachReviewsDocument, options);
}
export function useGetUserCoachReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCoachReviewsQuery,
    GetUserCoachReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCoachReviewsQuery,
    GetUserCoachReviewsQueryVariables
  >(GetUserCoachReviewsDocument, options);
}
export type GetUserCoachReviewsQueryHookResult = ReturnType<
  typeof useGetUserCoachReviewsQuery
>;
export type GetUserCoachReviewsLazyQueryHookResult = ReturnType<
  typeof useGetUserCoachReviewsLazyQuery
>;
export type GetUserCoachReviewsQueryResult = Apollo.QueryResult<
  GetUserCoachReviewsQuery,
  GetUserCoachReviewsQueryVariables
>;
export const ReviewCoachDocument = gql`
  mutation ReviewCoach($data: CreateCoachReviewInput!) {
    reviewCoach(data: $data) {
      comment
      rating
      coachId
      reviewedBy
      createdAt
      updatedAt
      ReviewedBy {
        id
        firstName
        lastName
        createdAt
      }
    }
  }
`;
export type ReviewCoachMutationFn = Apollo.MutationFunction<
  ReviewCoachMutation,
  ReviewCoachMutationVariables
>;

/**
 * __useReviewCoachMutation__
 *
 * To run a mutation, you first call `useReviewCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewCoachMutation, { data, loading, error }] = useReviewCoachMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReviewCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewCoachMutation,
    ReviewCoachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReviewCoachMutation, ReviewCoachMutationVariables>(
    ReviewCoachDocument,
    options
  );
}
export type ReviewCoachMutationHookResult = ReturnType<
  typeof useReviewCoachMutation
>;
export type ReviewCoachMutationResult =
  Apollo.MutationResult<ReviewCoachMutation>;
export type ReviewCoachMutationOptions = Apollo.BaseMutationOptions<
  ReviewCoachMutation,
  ReviewCoachMutationVariables
>;
export const GetCoachVisitDocument = gql`
  query GetCoachVisit($coachVisitId: Int!) {
    getCoachVisit(coachVisitId: $coachVisitId) {
      id
      Address {
        addressLine1
        addressLine2
        city
        country
        state
        place
      }
      Coach {
        id
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
        email
      }
      Customer {
        fullName
        firstName
        lastName
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
      }
      description
      requestType
      scheduledAt
      status
      recurring
      frequency
    }
  }
`;

/**
 * __useGetCoachVisitQuery__
 *
 * To run a query within a React component, call `useGetCoachVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachVisitQuery({
 *   variables: {
 *      coachVisitId: // value for 'coachVisitId'
 *   },
 * });
 */
export function useGetCoachVisitQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoachVisitQuery,
    GetCoachVisitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachVisitQuery, GetCoachVisitQueryVariables>(
    GetCoachVisitDocument,
    options
  );
}
export function useGetCoachVisitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoachVisitQuery,
    GetCoachVisitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoachVisitQuery, GetCoachVisitQueryVariables>(
    GetCoachVisitDocument,
    options
  );
}
export type GetCoachVisitQueryHookResult = ReturnType<
  typeof useGetCoachVisitQuery
>;
export type GetCoachVisitLazyQueryHookResult = ReturnType<
  typeof useGetCoachVisitLazyQuery
>;
export type GetCoachVisitQueryResult = Apollo.QueryResult<
  GetCoachVisitQuery,
  GetCoachVisitQueryVariables
>;
export const CreateContactRequestDocument = gql`
  mutation CreateContactRequest(
    $createContactRequestInput: CreateContactRequestInput!
  ) {
    createContactRequest(
      createContactRequestInput: $createContactRequestInput
    ) {
      id
      status
    }
  }
`;
export type CreateContactRequestMutationFn = Apollo.MutationFunction<
  CreateContactRequestMutation,
  CreateContactRequestMutationVariables
>;

/**
 * __useCreateContactRequestMutation__
 *
 * To run a mutation, you first call `useCreateContactRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactRequestMutation, { data, loading, error }] = useCreateContactRequestMutation({
 *   variables: {
 *      createContactRequestInput: // value for 'createContactRequestInput'
 *   },
 * });
 */
export function useCreateContactRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactRequestMutation,
    CreateContactRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactRequestMutation,
    CreateContactRequestMutationVariables
  >(CreateContactRequestDocument, options);
}
export type CreateContactRequestMutationHookResult = ReturnType<
  typeof useCreateContactRequestMutation
>;
export type CreateContactRequestMutationResult =
  Apollo.MutationResult<CreateContactRequestMutation>;
export type CreateContactRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateContactRequestMutation,
  CreateContactRequestMutationVariables
>;
export const UpdateContactRequestDocument = gql`
  mutation UpdateContactRequest(
    $contactRequestId: Int!
    $updateContactRequestInput: UpdateContactRequestInput!
  ) {
    updateContactRequest(
      contactRequestId: $contactRequestId
      updateContactRequestInput: $updateContactRequestInput
    ) {
      id
      status
    }
  }
`;
export type UpdateContactRequestMutationFn = Apollo.MutationFunction<
  UpdateContactRequestMutation,
  UpdateContactRequestMutationVariables
>;

/**
 * __useUpdateContactRequestMutation__
 *
 * To run a mutation, you first call `useUpdateContactRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactRequestMutation, { data, loading, error }] = useUpdateContactRequestMutation({
 *   variables: {
 *      contactRequestId: // value for 'contactRequestId'
 *      updateContactRequestInput: // value for 'updateContactRequestInput'
 *   },
 * });
 */
export function useUpdateContactRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactRequestMutation,
    UpdateContactRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactRequestMutation,
    UpdateContactRequestMutationVariables
  >(UpdateContactRequestDocument, options);
}
export type UpdateContactRequestMutationHookResult = ReturnType<
  typeof useUpdateContactRequestMutation
>;
export type UpdateContactRequestMutationResult =
  Apollo.MutationResult<UpdateContactRequestMutation>;
export type UpdateContactRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactRequestMutation,
  UpdateContactRequestMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation CreateCustomer(
    $role: AppUser!
    $createCustomerInput: CreateCustomerInput!
  ) {
    createCustomer(role: $role, createCustomerInput: $createCustomerInput) {
      id
    }
  }
`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      role: // value for 'role'
 *      createCustomerInput: // value for 'createCustomerInput'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult =
  Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CreateCustomerWithAddressDocument = gql`
  mutation CreateCustomerWithAddress(
    $address: RideAddressInput!
    $role: AppUser!
    $customer: CreateCustomerInput!
  ) {
    createCustomerWithAddress(
      address: $address
      role: $role
      customer: $customer
    ) {
      id
    }
  }
`;
export type CreateCustomerWithAddressMutationFn = Apollo.MutationFunction<
  CreateCustomerWithAddressMutation,
  CreateCustomerWithAddressMutationVariables
>;

/**
 * __useCreateCustomerWithAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWithAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWithAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWithAddressMutation, { data, loading, error }] = useCreateCustomerWithAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *      role: // value for 'role'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateCustomerWithAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerWithAddressMutation,
    CreateCustomerWithAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerWithAddressMutation,
    CreateCustomerWithAddressMutationVariables
  >(CreateCustomerWithAddressDocument, options);
}
export type CreateCustomerWithAddressMutationHookResult = ReturnType<
  typeof useCreateCustomerWithAddressMutation
>;
export type CreateCustomerWithAddressMutationResult =
  Apollo.MutationResult<CreateCustomerWithAddressMutation>;
export type CreateCustomerWithAddressMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCustomerWithAddressMutation,
    CreateCustomerWithAddressMutationVariables
  >;
export const CreateCustomerWithAdditionalInformationDocument = gql`
  mutation CreateCustomerWithAdditionalInformation(
    $role: AppUser!
    $customer: CreateCustomerWithAdditionalInformationInput!
  ) {
    createCustomerWithAdditionalInformation(role: $role, customer: $customer) {
      id
    }
  }
`;
export type CreateCustomerWithAdditionalInformationMutationFn =
  Apollo.MutationFunction<
    CreateCustomerWithAdditionalInformationMutation,
    CreateCustomerWithAdditionalInformationMutationVariables
  >;

/**
 * __useCreateCustomerWithAdditionalInformationMutation__
 *
 * To run a mutation, you first call `useCreateCustomerWithAdditionalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerWithAdditionalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerWithAdditionalInformationMutation, { data, loading, error }] = useCreateCustomerWithAdditionalInformationMutation({
 *   variables: {
 *      role: // value for 'role'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateCustomerWithAdditionalInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomerWithAdditionalInformationMutation,
    CreateCustomerWithAdditionalInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomerWithAdditionalInformationMutation,
    CreateCustomerWithAdditionalInformationMutationVariables
  >(CreateCustomerWithAdditionalInformationDocument, options);
}
export type CreateCustomerWithAdditionalInformationMutationHookResult =
  ReturnType<typeof useCreateCustomerWithAdditionalInformationMutation>;
export type CreateCustomerWithAdditionalInformationMutationResult =
  Apollo.MutationResult<CreateCustomerWithAdditionalInformationMutation>;
export type CreateCustomerWithAdditionalInformationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateCustomerWithAdditionalInformationMutation,
    CreateCustomerWithAdditionalInformationMutationVariables
  >;
export const RequestToJoinCircleDocument = gql`
  mutation RequestToJoinCircle(
    $requestToJoinCircleInput: RequestToJoinCircleInput!
  ) {
    requestToJoinCircle(requestToJoinCircleInput: $requestToJoinCircleInput) {
      match
    }
  }
`;
export type RequestToJoinCircleMutationFn = Apollo.MutationFunction<
  RequestToJoinCircleMutation,
  RequestToJoinCircleMutationVariables
>;

/**
 * __useRequestToJoinCircleMutation__
 *
 * To run a mutation, you first call `useRequestToJoinCircleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinCircleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinCircleMutation, { data, loading, error }] = useRequestToJoinCircleMutation({
 *   variables: {
 *      requestToJoinCircleInput: // value for 'requestToJoinCircleInput'
 *   },
 * });
 */
export function useRequestToJoinCircleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestToJoinCircleMutation,
    RequestToJoinCircleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestToJoinCircleMutation,
    RequestToJoinCircleMutationVariables
  >(RequestToJoinCircleDocument, options);
}
export type RequestToJoinCircleMutationHookResult = ReturnType<
  typeof useRequestToJoinCircleMutation
>;
export type RequestToJoinCircleMutationResult =
  Apollo.MutationResult<RequestToJoinCircleMutation>;
export type RequestToJoinCircleMutationOptions = Apollo.BaseMutationOptions<
  RequestToJoinCircleMutation,
  RequestToJoinCircleMutationVariables
>;
export const RequestToJoinFamilyGroupDocument = gql`
  mutation RequestToJoinFamilyGroup(
    $requestToJoinFamilyGroupInput: RequestToJoinFamilyGroupInput!
  ) {
    requestToJoinFamilyGroup(
      requestToJoinFamilyGroupInput: $requestToJoinFamilyGroupInput
    ) {
      match
    }
  }
`;
export type RequestToJoinFamilyGroupMutationFn = Apollo.MutationFunction<
  RequestToJoinFamilyGroupMutation,
  RequestToJoinFamilyGroupMutationVariables
>;

/**
 * __useRequestToJoinFamilyGroupMutation__
 *
 * To run a mutation, you first call `useRequestToJoinFamilyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinFamilyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinFamilyGroupMutation, { data, loading, error }] = useRequestToJoinFamilyGroupMutation({
 *   variables: {
 *      requestToJoinFamilyGroupInput: // value for 'requestToJoinFamilyGroupInput'
 *   },
 * });
 */
export function useRequestToJoinFamilyGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestToJoinFamilyGroupMutation,
    RequestToJoinFamilyGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestToJoinFamilyGroupMutation,
    RequestToJoinFamilyGroupMutationVariables
  >(RequestToJoinFamilyGroupDocument, options);
}
export type RequestToJoinFamilyGroupMutationHookResult = ReturnType<
  typeof useRequestToJoinFamilyGroupMutation
>;
export type RequestToJoinFamilyGroupMutationResult =
  Apollo.MutationResult<RequestToJoinFamilyGroupMutation>;
export type RequestToJoinFamilyGroupMutationOptions =
  Apollo.BaseMutationOptions<
    RequestToJoinFamilyGroupMutation,
    RequestToJoinFamilyGroupMutationVariables
  >;
export const GetGooglePlacesApiKeyDocument = gql`
  query GetGooglePlacesApiKey {
    getGooglePlacesApiKey {
      apiKey
    }
  }
`;

/**
 * __useGetGooglePlacesApiKeyQuery__
 *
 * To run a query within a React component, call `useGetGooglePlacesApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGooglePlacesApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGooglePlacesApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGooglePlacesApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGooglePlacesApiKeyQuery,
    GetGooglePlacesApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGooglePlacesApiKeyQuery,
    GetGooglePlacesApiKeyQueryVariables
  >(GetGooglePlacesApiKeyDocument, options);
}
export function useGetGooglePlacesApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGooglePlacesApiKeyQuery,
    GetGooglePlacesApiKeyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGooglePlacesApiKeyQuery,
    GetGooglePlacesApiKeyQueryVariables
  >(GetGooglePlacesApiKeyDocument, options);
}
export type GetGooglePlacesApiKeyQueryHookResult = ReturnType<
  typeof useGetGooglePlacesApiKeyQuery
>;
export type GetGooglePlacesApiKeyLazyQueryHookResult = ReturnType<
  typeof useGetGooglePlacesApiKeyLazyQuery
>;
export type GetGooglePlacesApiKeyQueryResult = Apollo.QueryResult<
  GetGooglePlacesApiKeyQuery,
  GetGooglePlacesApiKeyQueryVariables
>;
export const GetAllFeatureFlagsDocument = gql`
  query GetAllFeatureFlags {
    getAllFeatureFlags {
      id
      feature
      description
      enabled
    }
  }
`;

/**
 * __useGetAllFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetAllFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllFeatureFlagsQuery,
    GetAllFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllFeatureFlagsQuery,
    GetAllFeatureFlagsQueryVariables
  >(GetAllFeatureFlagsDocument, options);
}
export function useGetAllFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFeatureFlagsQuery,
    GetAllFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllFeatureFlagsQuery,
    GetAllFeatureFlagsQueryVariables
  >(GetAllFeatureFlagsDocument, options);
}
export type GetAllFeatureFlagsQueryHookResult = ReturnType<
  typeof useGetAllFeatureFlagsQuery
>;
export type GetAllFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useGetAllFeatureFlagsLazyQuery
>;
export type GetAllFeatureFlagsQueryResult = Apollo.QueryResult<
  GetAllFeatureFlagsQuery,
  GetAllFeatureFlagsQueryVariables
>;
export const CreateUserInvitesDocument = gql`
  mutation CreateUserInvites($userInvitesInput: CreateUserInviteInput!) {
    createUserInvites(userInvitesInput: $userInvitesInput) {
      success
      sentVia {
        fullName
        types
      }
    }
  }
`;
export type CreateUserInvitesMutationFn = Apollo.MutationFunction<
  CreateUserInvitesMutation,
  CreateUserInvitesMutationVariables
>;

/**
 * __useCreateUserInvitesMutation__
 *
 * To run a mutation, you first call `useCreateUserInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserInvitesMutation, { data, loading, error }] = useCreateUserInvitesMutation({
 *   variables: {
 *      userInvitesInput: // value for 'userInvitesInput'
 *   },
 * });
 */
export function useCreateUserInvitesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserInvitesMutation,
    CreateUserInvitesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserInvitesMutation,
    CreateUserInvitesMutationVariables
  >(CreateUserInvitesDocument, options);
}
export type CreateUserInvitesMutationHookResult = ReturnType<
  typeof useCreateUserInvitesMutation
>;
export type CreateUserInvitesMutationResult =
  Apollo.MutationResult<CreateUserInvitesMutation>;
export type CreateUserInvitesMutationOptions = Apollo.BaseMutationOptions<
  CreateUserInvitesMutation,
  CreateUserInvitesMutationVariables
>;
export const SendRequestToNotExistingUserDocument = gql`
  mutation SendRequestToNotExistingUser(
    $requestToNotExistingUserInput: RequestToNotExistingUserInput!
  ) {
    sendRequestToNotExistingUser(
      requestToNotExistingUserInput: $requestToNotExistingUserInput
    ) {
      success
    }
  }
`;
export type SendRequestToNotExistingUserMutationFn = Apollo.MutationFunction<
  SendRequestToNotExistingUserMutation,
  SendRequestToNotExistingUserMutationVariables
>;

/**
 * __useSendRequestToNotExistingUserMutation__
 *
 * To run a mutation, you first call `useSendRequestToNotExistingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestToNotExistingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestToNotExistingUserMutation, { data, loading, error }] = useSendRequestToNotExistingUserMutation({
 *   variables: {
 *      requestToNotExistingUserInput: // value for 'requestToNotExistingUserInput'
 *   },
 * });
 */
export function useSendRequestToNotExistingUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRequestToNotExistingUserMutation,
    SendRequestToNotExistingUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendRequestToNotExistingUserMutation,
    SendRequestToNotExistingUserMutationVariables
  >(SendRequestToNotExistingUserDocument, options);
}
export type SendRequestToNotExistingUserMutationHookResult = ReturnType<
  typeof useSendRequestToNotExistingUserMutation
>;
export type SendRequestToNotExistingUserMutationResult =
  Apollo.MutationResult<SendRequestToNotExistingUserMutation>;
export type SendRequestToNotExistingUserMutationOptions =
  Apollo.BaseMutationOptions<
    SendRequestToNotExistingUserMutation,
    SendRequestToNotExistingUserMutationVariables
  >;
export const GetNotificationsByUserIdDocument = gql`
  query GetNotificationsByUserId(
    $filter: NotificationsFilter!
    $pageSize: Int
    $page: Int
  ) {
    getNotificationsByUserId(
      filter: $filter
      pageSize: $pageSize
      page: $page
    ) {
      meta {
        pageCount
        isLastPage
        totalCount
      }
      notifications {
        id
        userId
        User {
          fullName
        }
        type
        title
        text
        readAt
        pushSentAt
        smsSentAt
        emailSentAt
        updatedAt
        createdAt
        payload
        deeplink
      }
    }
  }
`;

/**
 * __useGetNotificationsByUserIdQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByUserIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetNotificationsByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >(GetNotificationsByUserIdDocument, options);
}
export function useGetNotificationsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNotificationsByUserIdQuery,
    GetNotificationsByUserIdQueryVariables
  >(GetNotificationsByUserIdDocument, options);
}
export type GetNotificationsByUserIdQueryHookResult = ReturnType<
  typeof useGetNotificationsByUserIdQuery
>;
export type GetNotificationsByUserIdLazyQueryHookResult = ReturnType<
  typeof useGetNotificationsByUserIdLazyQuery
>;
export type GetNotificationsByUserIdQueryResult = Apollo.QueryResult<
  GetNotificationsByUserIdQuery,
  GetNotificationsByUserIdQueryVariables
>;
export const UnreadNotificationsDocument = gql`
  mutation UnreadNotifications($notificationsIds: [Int!]!) {
    unreadNotifications(notificationsIds: $notificationsIds) {
      success
    }
  }
`;
export type UnreadNotificationsMutationFn = Apollo.MutationFunction<
  UnreadNotificationsMutation,
  UnreadNotificationsMutationVariables
>;

/**
 * __useUnreadNotificationsMutation__
 *
 * To run a mutation, you first call `useUnreadNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unreadNotificationsMutation, { data, loading, error }] = useUnreadNotificationsMutation({
 *   variables: {
 *      notificationsIds: // value for 'notificationsIds'
 *   },
 * });
 */
export function useUnreadNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnreadNotificationsMutation,
    UnreadNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnreadNotificationsMutation,
    UnreadNotificationsMutationVariables
  >(UnreadNotificationsDocument, options);
}
export type UnreadNotificationsMutationHookResult = ReturnType<
  typeof useUnreadNotificationsMutation
>;
export type UnreadNotificationsMutationResult =
  Apollo.MutationResult<UnreadNotificationsMutation>;
export type UnreadNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UnreadNotificationsMutation,
  UnreadNotificationsMutationVariables
>;
export const ReadNotificationsDocument = gql`
  mutation ReadNotifications($notificationsIds: [Int!]!) {
    readNotifications(notificationsIds: $notificationsIds) {
      success
    }
  }
`;
export type ReadNotificationsMutationFn = Apollo.MutationFunction<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
>;

/**
 * __useReadNotificationsMutation__
 *
 * To run a mutation, you first call `useReadNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationsMutation, { data, loading, error }] = useReadNotificationsMutation({
 *   variables: {
 *      notificationsIds: // value for 'notificationsIds'
 *   },
 * });
 */
export function useReadNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >(ReadNotificationsDocument, options);
}
export type ReadNotificationsMutationHookResult = ReturnType<
  typeof useReadNotificationsMutation
>;
export type ReadNotificationsMutationResult =
  Apollo.MutationResult<ReadNotificationsMutation>;
export type ReadNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
>;
export const ReadAllUserNotificationsDocument = gql`
  mutation ReadAllUserNotifications($userId: Int) {
    readAllUserNotifications(userId: $userId) {
      success
    }
  }
`;
export type ReadAllUserNotificationsMutationFn = Apollo.MutationFunction<
  ReadAllUserNotificationsMutation,
  ReadAllUserNotificationsMutationVariables
>;

/**
 * __useReadAllUserNotificationsMutation__
 *
 * To run a mutation, you first call `useReadAllUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllUserNotificationsMutation, { data, loading, error }] = useReadAllUserNotificationsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadAllUserNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadAllUserNotificationsMutation,
    ReadAllUserNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadAllUserNotificationsMutation,
    ReadAllUserNotificationsMutationVariables
  >(ReadAllUserNotificationsDocument, options);
}
export type ReadAllUserNotificationsMutationHookResult = ReturnType<
  typeof useReadAllUserNotificationsMutation
>;
export type ReadAllUserNotificationsMutationResult =
  Apollo.MutationResult<ReadAllUserNotificationsMutation>;
export type ReadAllUserNotificationsMutationOptions =
  Apollo.BaseMutationOptions<
    ReadAllUserNotificationsMutation,
    ReadAllUserNotificationsMutationVariables
  >;
export const DeleteNotificationDocument = gql`
  mutation DeleteNotification($notificationId: Float!) {
    deleteNotification(notificationId: $notificationId) {
      success
    }
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >(DeleteNotificationDocument, options);
}
export type DeleteNotificationMutationHookResult = ReturnType<
  typeof useDeleteNotificationMutation
>;
export type DeleteNotificationMutationResult =
  Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const AnswerRequestDocument = gql`
  mutation AnswerRequest($status: UserInvitesStatus!, $userRequestId: Int!) {
    answerRequest(status: $status, userRequestId: $userRequestId) {
      success
    }
  }
`;
export type AnswerRequestMutationFn = Apollo.MutationFunction<
  AnswerRequestMutation,
  AnswerRequestMutationVariables
>;

/**
 * __useAnswerRequestMutation__
 *
 * To run a mutation, you first call `useAnswerRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerRequestMutation, { data, loading, error }] = useAnswerRequestMutation({
 *   variables: {
 *      status: // value for 'status'
 *      userRequestId: // value for 'userRequestId'
 *   },
 * });
 */
export function useAnswerRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerRequestMutation,
    AnswerRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerRequestMutation,
    AnswerRequestMutationVariables
  >(AnswerRequestDocument, options);
}
export type AnswerRequestMutationHookResult = ReturnType<
  typeof useAnswerRequestMutation
>;
export type AnswerRequestMutationResult =
  Apollo.MutationResult<AnswerRequestMutation>;
export type AnswerRequestMutationOptions = Apollo.BaseMutationOptions<
  AnswerRequestMutation,
  AnswerRequestMutationVariables
>;
export const GetUserNotificationSettingsDocument = gql`
  query GetUserNotificationSettings($notificationType: String!) {
    getUserNotificationSettings(notificationType: $notificationType) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;

/**
 * __useGetUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationSettingsQuery({
 *   variables: {
 *      notificationType: // value for 'notificationType'
 *   },
 * });
 */
export function useGetUserNotificationSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserNotificationSettingsQuery,
    GetUserNotificationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserNotificationSettingsQuery,
    GetUserNotificationSettingsQueryVariables
  >(GetUserNotificationSettingsDocument, options);
}
export function useGetUserNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNotificationSettingsQuery,
    GetUserNotificationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserNotificationSettingsQuery,
    GetUserNotificationSettingsQueryVariables
  >(GetUserNotificationSettingsDocument, options);
}
export type GetUserNotificationSettingsQueryHookResult = ReturnType<
  typeof useGetUserNotificationSettingsQuery
>;
export type GetUserNotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useGetUserNotificationSettingsLazyQuery
>;
export type GetUserNotificationSettingsQueryResult = Apollo.QueryResult<
  GetUserNotificationSettingsQuery,
  GetUserNotificationSettingsQueryVariables
>;
export const SaveUserNotificationSettingsDocument = gql`
  mutation SaveUserNotificationSettings(
    $saveNotificationSettingsInput: SaveNotificationSettingsInput!
    $userId: Int
  ) {
    saveUserNotificationSettings(
      saveNotificationSettingsInput: $saveNotificationSettingsInput
      userId: $userId
    ) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;
export type SaveUserNotificationSettingsMutationFn = Apollo.MutationFunction<
  SaveUserNotificationSettingsMutation,
  SaveUserNotificationSettingsMutationVariables
>;

/**
 * __useSaveUserNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useSaveUserNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserNotificationSettingsMutation, { data, loading, error }] = useSaveUserNotificationSettingsMutation({
 *   variables: {
 *      saveNotificationSettingsInput: // value for 'saveNotificationSettingsInput'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSaveUserNotificationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserNotificationSettingsMutation,
    SaveUserNotificationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserNotificationSettingsMutation,
    SaveUserNotificationSettingsMutationVariables
  >(SaveUserNotificationSettingsDocument, options);
}
export type SaveUserNotificationSettingsMutationHookResult = ReturnType<
  typeof useSaveUserNotificationSettingsMutation
>;
export type SaveUserNotificationSettingsMutationResult =
  Apollo.MutationResult<SaveUserNotificationSettingsMutation>;
export type SaveUserNotificationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    SaveUserNotificationSettingsMutation,
    SaveUserNotificationSettingsMutationVariables
  >;
export const CreateServiceRequestSummaryDocument = gql`
  mutation CreateServiceRequestSummary(
    $commentForBrya: String!
    $commentForCustomer: String!
    $serviceRequestId: Int!
    $notes: String
    $amount: Float
    $hours: Float
    $visitPaymentType: ServiceRequestPaymentSummaryVisitType
  ) {
    createServiceRequestSummary(
      commentForBrya: $commentForBrya
      commentForCustomer: $commentForCustomer
      serviceRequestId: $serviceRequestId
      notes: $notes
      amount: $amount
      hours: $hours
      visitPaymentType: $visitPaymentType
    ) {
      id
      customerId
      category
      otherCategory
      recurring
      frequency
      description
      addressId
      status
      rejectedReason
      cancellationReason
      availabilities {
        date
        from
        until
      }
      requestedBy
      createdBy
      agentId
      scheduledAt
      lastStatusUpdate
      acceptedAt
      createdAt
      updatedAt
      requestType
      tentativeSchedule
      isAvailabilityFlexible
      commentForCustomer
      commentForBrya
      RequestedBy {
        id
        firstName
        lastName
        email
      }
      CreatedBy {
        id
        firstName
        email
        lastName
      }
      RequestCategory {
        id
        name
        circleRequest
        serviceRequest
        createdAt
        updatedAt
      }
      ServiceRequestPayments {
        id
        type
        amount
        fee
        tax
        estimateHours
        createdAt
        updatedAt
        serviceRequestId
        stripePaymentMethodId
        stripeTransactionId
        notes
      }
      requiresAction
      actionNeeded
    }
  }
`;
export type CreateServiceRequestSummaryMutationFn = Apollo.MutationFunction<
  CreateServiceRequestSummaryMutation,
  CreateServiceRequestSummaryMutationVariables
>;

/**
 * __useCreateServiceRequestSummaryMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestSummaryMutation, { data, loading, error }] = useCreateServiceRequestSummaryMutation({
 *   variables: {
 *      commentForBrya: // value for 'commentForBrya'
 *      commentForCustomer: // value for 'commentForCustomer'
 *      serviceRequestId: // value for 'serviceRequestId'
 *      notes: // value for 'notes'
 *      amount: // value for 'amount'
 *      hours: // value for 'hours'
 *      visitPaymentType: // value for 'visitPaymentType'
 *   },
 * });
 */
export function useCreateServiceRequestSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceRequestSummaryMutation,
    CreateServiceRequestSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceRequestSummaryMutation,
    CreateServiceRequestSummaryMutationVariables
  >(CreateServiceRequestSummaryDocument, options);
}
export type CreateServiceRequestSummaryMutationHookResult = ReturnType<
  typeof useCreateServiceRequestSummaryMutation
>;
export type CreateServiceRequestSummaryMutationResult =
  Apollo.MutationResult<CreateServiceRequestSummaryMutation>;
export type CreateServiceRequestSummaryMutationOptions =
  Apollo.BaseMutationOptions<
    CreateServiceRequestSummaryMutation,
    CreateServiceRequestSummaryMutationVariables
  >;
export const GetStripeCustomerCreditCardSecretDocument = gql`
  query GetStripeCustomerCreditCardSecret {
    getStripeCustomerCreditCardSecret
  }
`;

/**
 * __useGetStripeCustomerCreditCardSecretQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerCreditCardSecretQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerCreditCardSecretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerCreditCardSecretQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeCustomerCreditCardSecretQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStripeCustomerCreditCardSecretQuery,
    GetStripeCustomerCreditCardSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripeCustomerCreditCardSecretQuery,
    GetStripeCustomerCreditCardSecretQueryVariables
  >(GetStripeCustomerCreditCardSecretDocument, options);
}
export function useGetStripeCustomerCreditCardSecretLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeCustomerCreditCardSecretQuery,
    GetStripeCustomerCreditCardSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripeCustomerCreditCardSecretQuery,
    GetStripeCustomerCreditCardSecretQueryVariables
  >(GetStripeCustomerCreditCardSecretDocument, options);
}
export type GetStripeCustomerCreditCardSecretQueryHookResult = ReturnType<
  typeof useGetStripeCustomerCreditCardSecretQuery
>;
export type GetStripeCustomerCreditCardSecretLazyQueryHookResult = ReturnType<
  typeof useGetStripeCustomerCreditCardSecretLazyQuery
>;
export type GetStripeCustomerCreditCardSecretQueryResult = Apollo.QueryResult<
  GetStripeCustomerCreditCardSecretQuery,
  GetStripeCustomerCreditCardSecretQueryVariables
>;
export const GetStripeCustomerPaymentMethodsDocument = gql`
  query GetStripeCustomerPaymentMethods {
    getStripeCustomerPaymentMethods {
      id
      object
      created
      livemode
      type
      card {
        brand
        country
        funding
        last4
        exp_month
        exp_year
      }
      default
      billing_details {
        name
      }
    }
  }
`;

/**
 * __useGetStripeCustomerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeCustomerPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStripeCustomerPaymentMethodsQuery,
    GetStripeCustomerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripeCustomerPaymentMethodsQuery,
    GetStripeCustomerPaymentMethodsQueryVariables
  >(GetStripeCustomerPaymentMethodsDocument, options);
}
export function useGetStripeCustomerPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeCustomerPaymentMethodsQuery,
    GetStripeCustomerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripeCustomerPaymentMethodsQuery,
    GetStripeCustomerPaymentMethodsQueryVariables
  >(GetStripeCustomerPaymentMethodsDocument, options);
}
export type GetStripeCustomerPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetStripeCustomerPaymentMethodsQuery
>;
export type GetStripeCustomerPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetStripeCustomerPaymentMethodsLazyQuery
>;
export type GetStripeCustomerPaymentMethodsQueryResult = Apollo.QueryResult<
  GetStripeCustomerPaymentMethodsQuery,
  GetStripeCustomerPaymentMethodsQueryVariables
>;
export const CreateAppointmentRequestPaymentDocument = gql`
  mutation CreateAppointmentRequestPayment(
    $appointmentPaymentInput: CreateAppointmentPaymentInput!
  ) {
    createAppointmentRequestPayment(
      appointmentPaymentInput: $appointmentPaymentInput
    ) {
      id
      type
      amount
      estimateHours
      createdAt
      appointmentRequestId
      updatedAt
    }
  }
`;
export type CreateAppointmentRequestPaymentMutationFn = Apollo.MutationFunction<
  CreateAppointmentRequestPaymentMutation,
  CreateAppointmentRequestPaymentMutationVariables
>;

/**
 * __useCreateAppointmentRequestPaymentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentRequestPaymentMutation, { data, loading, error }] = useCreateAppointmentRequestPaymentMutation({
 *   variables: {
 *      appointmentPaymentInput: // value for 'appointmentPaymentInput'
 *   },
 * });
 */
export function useCreateAppointmentRequestPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentRequestPaymentMutation,
    CreateAppointmentRequestPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentRequestPaymentMutation,
    CreateAppointmentRequestPaymentMutationVariables
  >(CreateAppointmentRequestPaymentDocument, options);
}
export type CreateAppointmentRequestPaymentMutationHookResult = ReturnType<
  typeof useCreateAppointmentRequestPaymentMutation
>;
export type CreateAppointmentRequestPaymentMutationResult =
  Apollo.MutationResult<CreateAppointmentRequestPaymentMutation>;
export type CreateAppointmentRequestPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAppointmentRequestPaymentMutation,
    CreateAppointmentRequestPaymentMutationVariables
  >;
export const GetStripeCustomerPaymentMethodDocument = gql`
  query GetStripeCustomerPaymentMethod($paymentMethodId: String!) {
    getStripeCustomerPaymentMethod(paymentMethodId: $paymentMethodId) {
      id
      object
      created
      livemode
      type
      card {
        brand
        country
        exp_month
        exp_year
        funding
        last4
      }
      billing_details {
        name
      }
      default
    }
  }
`;

/**
 * __useGetStripeCustomerPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetStripeCustomerPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeCustomerPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeCustomerPaymentMethodQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetStripeCustomerPaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripeCustomerPaymentMethodQuery,
    GetStripeCustomerPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripeCustomerPaymentMethodQuery,
    GetStripeCustomerPaymentMethodQueryVariables
  >(GetStripeCustomerPaymentMethodDocument, options);
}
export function useGetStripeCustomerPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeCustomerPaymentMethodQuery,
    GetStripeCustomerPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripeCustomerPaymentMethodQuery,
    GetStripeCustomerPaymentMethodQueryVariables
  >(GetStripeCustomerPaymentMethodDocument, options);
}
export type GetStripeCustomerPaymentMethodQueryHookResult = ReturnType<
  typeof useGetStripeCustomerPaymentMethodQuery
>;
export type GetStripeCustomerPaymentMethodLazyQueryHookResult = ReturnType<
  typeof useGetStripeCustomerPaymentMethodLazyQuery
>;
export type GetStripeCustomerPaymentMethodQueryResult = Apollo.QueryResult<
  GetStripeCustomerPaymentMethodQuery,
  GetStripeCustomerPaymentMethodQueryVariables
>;
export const UpdateAppointmentRequestPaymentDocument = gql`
  mutation UpdateAppointmentRequestPayment(
    $appointmentRequestPaymentId: Int!
    $appointmentPaymentInput: UpdateAppointmentPaymentInput!
  ) {
    updateAppointmentRequestPayment(
      appointmentRequestPaymentId: $appointmentRequestPaymentId
      appointmentPaymentInput: $appointmentPaymentInput
    ) {
      id
      type
      amount
      estimateHours
      createdAt
      updatedAt
      appointmentRequestId
    }
  }
`;
export type UpdateAppointmentRequestPaymentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentRequestPaymentMutation,
  UpdateAppointmentRequestPaymentMutationVariables
>;

/**
 * __useUpdateAppointmentRequestPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentRequestPaymentMutation, { data, loading, error }] = useUpdateAppointmentRequestPaymentMutation({
 *   variables: {
 *      appointmentRequestPaymentId: // value for 'appointmentRequestPaymentId'
 *      appointmentPaymentInput: // value for 'appointmentPaymentInput'
 *   },
 * });
 */
export function useUpdateAppointmentRequestPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentRequestPaymentMutation,
    UpdateAppointmentRequestPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentRequestPaymentMutation,
    UpdateAppointmentRequestPaymentMutationVariables
  >(UpdateAppointmentRequestPaymentDocument, options);
}
export type UpdateAppointmentRequestPaymentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentRequestPaymentMutation
>;
export type UpdateAppointmentRequestPaymentMutationResult =
  Apollo.MutationResult<UpdateAppointmentRequestPaymentMutation>;
export type UpdateAppointmentRequestPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAppointmentRequestPaymentMutation,
    UpdateAppointmentRequestPaymentMutationVariables
  >;
export const UpdateServiceRequestPaymentDocument = gql`
  mutation UpdateServiceRequestPayment(
    $serviceRequestPaymentId: Int!
    $servicePaymentInput: UpdateServicePaymentInput!
  ) {
    updateServiceRequestPayment(
      serviceRequestPaymentId: $serviceRequestPaymentId
      servicePaymentInput: $servicePaymentInput
    ) {
      id
      type
      amount
      estimateHours
      createdAt
      updatedAt
      serviceRequestId
      fee
      tax
      estimateHours
      createdAt
      updatedAt
      serviceRequestId
      stripePaymentMethodId
      stripeTransactionId
      notes
    }
  }
`;
export type UpdateServiceRequestPaymentMutationFn = Apollo.MutationFunction<
  UpdateServiceRequestPaymentMutation,
  UpdateServiceRequestPaymentMutationVariables
>;

/**
 * __useUpdateServiceRequestPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateServiceRequestPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceRequestPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceRequestPaymentMutation, { data, loading, error }] = useUpdateServiceRequestPaymentMutation({
 *   variables: {
 *      serviceRequestPaymentId: // value for 'serviceRequestPaymentId'
 *      servicePaymentInput: // value for 'servicePaymentInput'
 *   },
 * });
 */
export function useUpdateServiceRequestPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceRequestPaymentMutation,
    UpdateServiceRequestPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceRequestPaymentMutation,
    UpdateServiceRequestPaymentMutationVariables
  >(UpdateServiceRequestPaymentDocument, options);
}
export type UpdateServiceRequestPaymentMutationHookResult = ReturnType<
  typeof useUpdateServiceRequestPaymentMutation
>;
export type UpdateServiceRequestPaymentMutationResult =
  Apollo.MutationResult<UpdateServiceRequestPaymentMutation>;
export type UpdateServiceRequestPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateServiceRequestPaymentMutation,
    UpdateServiceRequestPaymentMutationVariables
  >;
export const UpdateStripeCustomerDefaultCardDocument = gql`
  mutation UpdateStripeCustomerDefaultCard($paymentMethodId: String!) {
    updateStripeCustomerDefaultCard(paymentMethodId: $paymentMethodId) {
      success
    }
  }
`;
export type UpdateStripeCustomerDefaultCardMutationFn = Apollo.MutationFunction<
  UpdateStripeCustomerDefaultCardMutation,
  UpdateStripeCustomerDefaultCardMutationVariables
>;

/**
 * __useUpdateStripeCustomerDefaultCardMutation__
 *
 * To run a mutation, you first call `useUpdateStripeCustomerDefaultCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeCustomerDefaultCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeCustomerDefaultCardMutation, { data, loading, error }] = useUpdateStripeCustomerDefaultCardMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateStripeCustomerDefaultCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeCustomerDefaultCardMutation,
    UpdateStripeCustomerDefaultCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStripeCustomerDefaultCardMutation,
    UpdateStripeCustomerDefaultCardMutationVariables
  >(UpdateStripeCustomerDefaultCardDocument, options);
}
export type UpdateStripeCustomerDefaultCardMutationHookResult = ReturnType<
  typeof useUpdateStripeCustomerDefaultCardMutation
>;
export type UpdateStripeCustomerDefaultCardMutationResult =
  Apollo.MutationResult<UpdateStripeCustomerDefaultCardMutation>;
export type UpdateStripeCustomerDefaultCardMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateStripeCustomerDefaultCardMutation,
    UpdateStripeCustomerDefaultCardMutationVariables
  >;
export const ConfirmStripeTransactionDocument = gql`
  mutation ConfirmStripeTransaction($stripeTransactionId: String!) {
    confirmStripeTransaction(stripeTransactionId: $stripeTransactionId) {
      success
    }
  }
`;
export type ConfirmStripeTransactionMutationFn = Apollo.MutationFunction<
  ConfirmStripeTransactionMutation,
  ConfirmStripeTransactionMutationVariables
>;

/**
 * __useConfirmStripeTransactionMutation__
 *
 * To run a mutation, you first call `useConfirmStripeTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmStripeTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmStripeTransactionMutation, { data, loading, error }] = useConfirmStripeTransactionMutation({
 *   variables: {
 *      stripeTransactionId: // value for 'stripeTransactionId'
 *   },
 * });
 */
export function useConfirmStripeTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmStripeTransactionMutation,
    ConfirmStripeTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmStripeTransactionMutation,
    ConfirmStripeTransactionMutationVariables
  >(ConfirmStripeTransactionDocument, options);
}
export type ConfirmStripeTransactionMutationHookResult = ReturnType<
  typeof useConfirmStripeTransactionMutation
>;
export type ConfirmStripeTransactionMutationResult =
  Apollo.MutationResult<ConfirmStripeTransactionMutation>;
export type ConfirmStripeTransactionMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmStripeTransactionMutation,
    ConfirmStripeTransactionMutationVariables
  >;
export const ContactSupportForExtraChargeDocument = gql`
  mutation ContactSupportForExtraCharge($stripeTransactionId: String!) {
    contactSupportForExtraCharge(stripeTransactionId: $stripeTransactionId) {
      id
    }
  }
`;
export type ContactSupportForExtraChargeMutationFn = Apollo.MutationFunction<
  ContactSupportForExtraChargeMutation,
  ContactSupportForExtraChargeMutationVariables
>;

/**
 * __useContactSupportForExtraChargeMutation__
 *
 * To run a mutation, you first call `useContactSupportForExtraChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactSupportForExtraChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactSupportForExtraChargeMutation, { data, loading, error }] = useContactSupportForExtraChargeMutation({
 *   variables: {
 *      stripeTransactionId: // value for 'stripeTransactionId'
 *   },
 * });
 */
export function useContactSupportForExtraChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactSupportForExtraChargeMutation,
    ContactSupportForExtraChargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ContactSupportForExtraChargeMutation,
    ContactSupportForExtraChargeMutationVariables
  >(ContactSupportForExtraChargeDocument, options);
}
export type ContactSupportForExtraChargeMutationHookResult = ReturnType<
  typeof useContactSupportForExtraChargeMutation
>;
export type ContactSupportForExtraChargeMutationResult =
  Apollo.MutationResult<ContactSupportForExtraChargeMutation>;
export type ContactSupportForExtraChargeMutationOptions =
  Apollo.BaseMutationOptions<
    ContactSupportForExtraChargeMutation,
    ContactSupportForExtraChargeMutationVariables
  >;
export const GetCustomerAgentDocument = gql`
  query GetCustomerAgent($agentId: Int!) {
    getCustomerAgent(agentId: $agentId) {
      userId
      rating
      serviceTypes
      serviceTypeInfo {
        id
        name
      }
      favorite
      Company {
        name
        website
        city
        state
      }
      User {
        firstName
        lastName
        fullName
        bio
        profilePicture
      }
      CustomerFavoriteAgents {
        customerId
        Customer {
          fullName
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetCustomerAgentQuery__
 *
 * To run a query within a React component, call `useGetCustomerAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAgentQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useGetCustomerAgentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerAgentQuery,
    GetCustomerAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerAgentQuery, GetCustomerAgentQueryVariables>(
    GetCustomerAgentDocument,
    options
  );
}
export function useGetCustomerAgentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerAgentQuery,
    GetCustomerAgentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerAgentQuery,
    GetCustomerAgentQueryVariables
  >(GetCustomerAgentDocument, options);
}
export type GetCustomerAgentQueryHookResult = ReturnType<
  typeof useGetCustomerAgentQuery
>;
export type GetCustomerAgentLazyQueryHookResult = ReturnType<
  typeof useGetCustomerAgentLazyQuery
>;
export type GetCustomerAgentQueryResult = Apollo.QueryResult<
  GetCustomerAgentQuery,
  GetCustomerAgentQueryVariables
>;
export const ReviewsDocument = gql`
  query Reviews($agentId: Int!, $category: ServiceRequestCategoriesEnum) {
    getAgentReviews(agentId: $agentId, category: $category) {
      reviews {
        comment
        rating
        ReviewedBy {
          firstName
          createdAt
        }
        serviceRequestCategory
      }
    }
  }
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    options
  );
}
export function useReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(
    ReviewsDocument,
    options
  );
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<
  ReviewsQuery,
  ReviewsQueryVariables
>;
export const FavoriteAgentDocument = gql`
  mutation FavoriteAgent(
    $serviceType: ServiceRequestCategoriesEnum!
    $agentId: Int!
  ) {
    favoriteAgent(serviceType: $serviceType, agentId: $agentId) {
      userId
    }
  }
`;
export type FavoriteAgentMutationFn = Apollo.MutationFunction<
  FavoriteAgentMutation,
  FavoriteAgentMutationVariables
>;

/**
 * __useFavoriteAgentMutation__
 *
 * To run a mutation, you first call `useFavoriteAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteAgentMutation, { data, loading, error }] = useFavoriteAgentMutation({
 *   variables: {
 *      serviceType: // value for 'serviceType'
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useFavoriteAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FavoriteAgentMutation,
    FavoriteAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FavoriteAgentMutation,
    FavoriteAgentMutationVariables
  >(FavoriteAgentDocument, options);
}
export type FavoriteAgentMutationHookResult = ReturnType<
  typeof useFavoriteAgentMutation
>;
export type FavoriteAgentMutationResult =
  Apollo.MutationResult<FavoriteAgentMutation>;
export type FavoriteAgentMutationOptions = Apollo.BaseMutationOptions<
  FavoriteAgentMutation,
  FavoriteAgentMutationVariables
>;
export const UnfavoriteAgentDocument = gql`
  mutation UnfavoriteAgent(
    $serviceType: ServiceRequestCategoriesEnum!
    $agentId: Int!
  ) {
    unfavoriteAgent(serviceType: $serviceType, agentId: $agentId) {
      userId
    }
  }
`;
export type UnfavoriteAgentMutationFn = Apollo.MutationFunction<
  UnfavoriteAgentMutation,
  UnfavoriteAgentMutationVariables
>;

/**
 * __useUnfavoriteAgentMutation__
 *
 * To run a mutation, you first call `useUnfavoriteAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavoriteAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavoriteAgentMutation, { data, loading, error }] = useUnfavoriteAgentMutation({
 *   variables: {
 *      serviceType: // value for 'serviceType'
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useUnfavoriteAgentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfavoriteAgentMutation,
    UnfavoriteAgentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnfavoriteAgentMutation,
    UnfavoriteAgentMutationVariables
  >(UnfavoriteAgentDocument, options);
}
export type UnfavoriteAgentMutationHookResult = ReturnType<
  typeof useUnfavoriteAgentMutation
>;
export type UnfavoriteAgentMutationResult =
  Apollo.MutationResult<UnfavoriteAgentMutation>;
export type UnfavoriteAgentMutationOptions = Apollo.BaseMutationOptions<
  UnfavoriteAgentMutation,
  UnfavoriteAgentMutationVariables
>;
export const InterpretServiceRequestDocument = gql`
  query InterpretServiceRequest($text: String!) {
    interpretServiceRequest(text: $text) {
      category
      recurring
    }
  }
`;

/**
 * __useInterpretServiceRequestQuery__
 *
 * To run a query within a React component, call `useInterpretServiceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterpretServiceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterpretServiceRequestQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInterpretServiceRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    InterpretServiceRequestQuery,
    InterpretServiceRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InterpretServiceRequestQuery,
    InterpretServiceRequestQueryVariables
  >(InterpretServiceRequestDocument, options);
}
export function useInterpretServiceRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterpretServiceRequestQuery,
    InterpretServiceRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InterpretServiceRequestQuery,
    InterpretServiceRequestQueryVariables
  >(InterpretServiceRequestDocument, options);
}
export type InterpretServiceRequestQueryHookResult = ReturnType<
  typeof useInterpretServiceRequestQuery
>;
export type InterpretServiceRequestLazyQueryHookResult = ReturnType<
  typeof useInterpretServiceRequestLazyQuery
>;
export type InterpretServiceRequestQueryResult = Apollo.QueryResult<
  InterpretServiceRequestQuery,
  InterpretServiceRequestQueryVariables
>;
export const InterpretCircleRequestDocument = gql`
  query InterpretCircleRequest($text: String!) {
    interpretCircleRequest(text: $text) {
      category
      recurring
    }
  }
`;

/**
 * __useInterpretCircleRequestQuery__
 *
 * To run a query within a React component, call `useInterpretCircleRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterpretCircleRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterpretCircleRequestQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useInterpretCircleRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    InterpretCircleRequestQuery,
    InterpretCircleRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InterpretCircleRequestQuery,
    InterpretCircleRequestQueryVariables
  >(InterpretCircleRequestDocument, options);
}
export function useInterpretCircleRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InterpretCircleRequestQuery,
    InterpretCircleRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InterpretCircleRequestQuery,
    InterpretCircleRequestQueryVariables
  >(InterpretCircleRequestDocument, options);
}
export type InterpretCircleRequestQueryHookResult = ReturnType<
  typeof useInterpretCircleRequestQuery
>;
export type InterpretCircleRequestLazyQueryHookResult = ReturnType<
  typeof useInterpretCircleRequestLazyQuery
>;
export type InterpretCircleRequestQueryResult = Apollo.QueryResult<
  InterpretCircleRequestQuery,
  InterpretCircleRequestQueryVariables
>;
export const UpdateServiceRequestDocument = gql`
  mutation UpdateServiceRequest(
    $data: UpdateServiceRequestInput!
    $updateServiceRequestId: Int!
  ) {
    updateServiceRequest(data: $data, id: $updateServiceRequestId) {
      id
      status
    }
  }
`;
export type UpdateServiceRequestMutationFn = Apollo.MutationFunction<
  UpdateServiceRequestMutation,
  UpdateServiceRequestMutationVariables
>;

/**
 * __useUpdateServiceRequestMutation__
 *
 * To run a mutation, you first call `useUpdateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceRequestMutation, { data, loading, error }] = useUpdateServiceRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *      updateServiceRequestId: // value for 'updateServiceRequestId'
 *   },
 * });
 */
export function useUpdateServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceRequestMutation,
    UpdateServiceRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceRequestMutation,
    UpdateServiceRequestMutationVariables
  >(UpdateServiceRequestDocument, options);
}
export type UpdateServiceRequestMutationHookResult = ReturnType<
  typeof useUpdateServiceRequestMutation
>;
export type UpdateServiceRequestMutationResult =
  Apollo.MutationResult<UpdateServiceRequestMutation>;
export type UpdateServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceRequestMutation,
  UpdateServiceRequestMutationVariables
>;
export const GetServiceRequestDocument = gql`
  query GetServiceRequest($getServiceRequestId: Float!) {
    getServiceRequest(id: $getServiceRequestId) {
      id
      customerId
      category
      otherCategory
      recurring
      description
      frequency
      addressId
      status
      rejectedReason
      cancellationReason
      requestedBy
      agentId
      scheduledAt
      acceptedAt
      createdAt
      updatedAt
      requestType
      tentativeSchedule
      isAvailabilityFlexible
      requiresAction
      actionNeeded
      commentForCustomer
      availabilities {
        date
        from
        until
      }
      RequestedBy {
        id
        fullName
      }
      CreatedBy {
        id
        fullName
      }
      Customer {
        id
        fullName
      }
      ServiceRequestPayments {
        id
        type
        amount
        fee
        tax
        estimateHours
        createdAt
        updatedAt
        serviceRequestId
        stripePaymentMethodId
        stripeTransactionId
        notes
        StripeTransaction {
          status
          activityId
          amount
          createdAt
          stripeCustomerId
        }
      }
      RequestCategory {
        id
        name
      }
      Address {
        addressLine1
        addressLine2
        id
        place
        state
        zipCode
        country
        city
        customerId
      }
      ride {
        dropoffAddress {
          addressLine2
          addressLine1
          city
          country
          place
          state
        }
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        type
        waitTime
      }
      Timeline {
        id
        User {
          id
          fullName
          createdAt
          updatedAt
        }
        userId
        customerId
        title
        description
        createdAt
        isAppVisible
      }
      Agent {
        Company {
          rating
          state
          updatedAt
          website
          name
          phoneNumber
          id
          hours
          createdAt
        }
        rating
        serviceTypes
        User {
          firstName
          lastName
          fullName
          profilePicture
        }
      }
    }
  }
`;

/**
 * __useGetServiceRequestQuery__
 *
 * To run a query within a React component, call `useGetServiceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceRequestQuery({
 *   variables: {
 *      getServiceRequestId: // value for 'getServiceRequestId'
 *   },
 * });
 */
export function useGetServiceRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceRequestQuery,
    GetServiceRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceRequestQuery,
    GetServiceRequestQueryVariables
  >(GetServiceRequestDocument, options);
}
export function useGetServiceRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceRequestQuery,
    GetServiceRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceRequestQuery,
    GetServiceRequestQueryVariables
  >(GetServiceRequestDocument, options);
}
export type GetServiceRequestQueryHookResult = ReturnType<
  typeof useGetServiceRequestQuery
>;
export type GetServiceRequestLazyQueryHookResult = ReturnType<
  typeof useGetServiceRequestLazyQuery
>;
export type GetServiceRequestQueryResult = Apollo.QueryResult<
  GetServiceRequestQuery,
  GetServiceRequestQueryVariables
>;
export const UserApprovalServiceRequestDocument = gql`
  mutation UserApprovalServiceRequest(
    $action: ApprovalServiceRequestStatus!
    $serviceRequestId: Int!
    $rejectedReason: String
  ) {
    userApprovalServiceRequest(
      action: $action
      serviceRequestId: $serviceRequestId
      rejectedReason: $rejectedReason
    ) {
      Agent {
        userId
      }
    }
  }
`;
export type UserApprovalServiceRequestMutationFn = Apollo.MutationFunction<
  UserApprovalServiceRequestMutation,
  UserApprovalServiceRequestMutationVariables
>;

/**
 * __useUserApprovalServiceRequestMutation__
 *
 * To run a mutation, you first call `useUserApprovalServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApprovalServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApprovalServiceRequestMutation, { data, loading, error }] = useUserApprovalServiceRequestMutation({
 *   variables: {
 *      action: // value for 'action'
 *      serviceRequestId: // value for 'serviceRequestId'
 *      rejectedReason: // value for 'rejectedReason'
 *   },
 * });
 */
export function useUserApprovalServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserApprovalServiceRequestMutation,
    UserApprovalServiceRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserApprovalServiceRequestMutation,
    UserApprovalServiceRequestMutationVariables
  >(UserApprovalServiceRequestDocument, options);
}
export type UserApprovalServiceRequestMutationHookResult = ReturnType<
  typeof useUserApprovalServiceRequestMutation
>;
export type UserApprovalServiceRequestMutationResult =
  Apollo.MutationResult<UserApprovalServiceRequestMutation>;
export type UserApprovalServiceRequestMutationOptions =
  Apollo.BaseMutationOptions<
    UserApprovalServiceRequestMutation,
    UserApprovalServiceRequestMutationVariables
  >;
export const UpdateServiceRequestTimeDocument = gql`
  mutation UpdateServiceRequestTime(
    $newTime: DateTime!
    $serviceRequestId: Int!
  ) {
    updateServiceRequestTime(
      newTime: $newTime
      serviceRequestId: $serviceRequestId
    ) {
      scheduledAt
      status
    }
  }
`;
export type UpdateServiceRequestTimeMutationFn = Apollo.MutationFunction<
  UpdateServiceRequestTimeMutation,
  UpdateServiceRequestTimeMutationVariables
>;

/**
 * __useUpdateServiceRequestTimeMutation__
 *
 * To run a mutation, you first call `useUpdateServiceRequestTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceRequestTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceRequestTimeMutation, { data, loading, error }] = useUpdateServiceRequestTimeMutation({
 *   variables: {
 *      newTime: // value for 'newTime'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useUpdateServiceRequestTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceRequestTimeMutation,
    UpdateServiceRequestTimeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceRequestTimeMutation,
    UpdateServiceRequestTimeMutationVariables
  >(UpdateServiceRequestTimeDocument, options);
}
export type UpdateServiceRequestTimeMutationHookResult = ReturnType<
  typeof useUpdateServiceRequestTimeMutation
>;
export type UpdateServiceRequestTimeMutationResult =
  Apollo.MutationResult<UpdateServiceRequestTimeMutation>;
export type UpdateServiceRequestTimeMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateServiceRequestTimeMutation,
    UpdateServiceRequestTimeMutationVariables
  >;
export const GetMobileSocialEventDocument = gql`
  query GetMobileSocialEvent($socialEventId: Int!, $customerId: Int!) {
    getMobileSocialEvent(
      socialEventId: $socialEventId
      customerId: $customerId
    ) {
      id
      customerActivityId
      cancellationReason
      createdAt
      description
      eventName
      frequency
      recurring
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      ContactRequests {
        id
        createdAt
        updatedAt
        userId
        status
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
        role
        email
      }
      Customer {
        firstName
        fullName
        lastName
        createdAt
        id
      }
      addressLine1
      addressLine2
      city
      country
      place
      state
      zipCode
    }
  }
`;

/**
 * __useGetMobileSocialEventQuery__
 *
 * To run a query within a React component, call `useGetMobileSocialEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileSocialEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileSocialEventQuery({
 *   variables: {
 *      socialEventId: // value for 'socialEventId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetMobileSocialEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMobileSocialEventQuery,
    GetMobileSocialEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMobileSocialEventQuery,
    GetMobileSocialEventQueryVariables
  >(GetMobileSocialEventDocument, options);
}
export function useGetMobileSocialEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMobileSocialEventQuery,
    GetMobileSocialEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMobileSocialEventQuery,
    GetMobileSocialEventQueryVariables
  >(GetMobileSocialEventDocument, options);
}
export type GetMobileSocialEventQueryHookResult = ReturnType<
  typeof useGetMobileSocialEventQuery
>;
export type GetMobileSocialEventLazyQueryHookResult = ReturnType<
  typeof useGetMobileSocialEventLazyQuery
>;
export type GetMobileSocialEventQueryResult = Apollo.QueryResult<
  GetMobileSocialEventQuery,
  GetMobileSocialEventQueryVariables
>;
export const UserCancelServiceRequestDocument = gql`
  mutation UserCancelServiceRequest(
    $cancellationReason: String!
    $serviceRequestId: Int!
  ) {
    userCancelServiceRequest(
      cancellationReason: $cancellationReason
      serviceRequestId: $serviceRequestId
    ) {
      requestType
      requestedBy
      requiresAction
      scheduledAt
      status
      rejectedReason
      otherCategory
      description
      customerId
      RequestedBy {
        firstName
        fullName
        id
        email
        role
        updatedAt
        lastName
      }
      Address {
        place
        state
        zipCode
        addressLine2
        addressLine1
        city
        country
      }
      InternalNotes {
        note
        serviceRequestId
      }
      cancellationReason
    }
  }
`;
export type UserCancelServiceRequestMutationFn = Apollo.MutationFunction<
  UserCancelServiceRequestMutation,
  UserCancelServiceRequestMutationVariables
>;

/**
 * __useUserCancelServiceRequestMutation__
 *
 * To run a mutation, you first call `useUserCancelServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCancelServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCancelServiceRequestMutation, { data, loading, error }] = useUserCancelServiceRequestMutation({
 *   variables: {
 *      cancellationReason: // value for 'cancellationReason'
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useUserCancelServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCancelServiceRequestMutation,
    UserCancelServiceRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserCancelServiceRequestMutation,
    UserCancelServiceRequestMutationVariables
  >(UserCancelServiceRequestDocument, options);
}
export type UserCancelServiceRequestMutationHookResult = ReturnType<
  typeof useUserCancelServiceRequestMutation
>;
export type UserCancelServiceRequestMutationResult =
  Apollo.MutationResult<UserCancelServiceRequestMutation>;
export type UserCancelServiceRequestMutationOptions =
  Apollo.BaseMutationOptions<
    UserCancelServiceRequestMutation,
    UserCancelServiceRequestMutationVariables
  >;
export const UserApprovalSocialEventDocument = gql`
  mutation UserApprovalSocialEvent(
    $action: ApprovalServiceRequestStatus!
    $customerId: Int!
    $socialEventId: Int!
  ) {
    userApprovalSocialEvent(
      action: $action
      customerId: $customerId
      socialEventId: $socialEventId
    ) {
      Address {
        state
        place
        country
        city
        addressLine2
        addressLine1
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        role
        profilePicture
        email
      }
      cancellationReason
      createdAt
      description
      eventName
      requestType
      requestedBy
      scheduledAt
      status
    }
  }
`;
export type UserApprovalSocialEventMutationFn = Apollo.MutationFunction<
  UserApprovalSocialEventMutation,
  UserApprovalSocialEventMutationVariables
>;

/**
 * __useUserApprovalSocialEventMutation__
 *
 * To run a mutation, you first call `useUserApprovalSocialEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApprovalSocialEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApprovalSocialEventMutation, { data, loading, error }] = useUserApprovalSocialEventMutation({
 *   variables: {
 *      action: // value for 'action'
 *      customerId: // value for 'customerId'
 *      socialEventId: // value for 'socialEventId'
 *   },
 * });
 */
export function useUserApprovalSocialEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserApprovalSocialEventMutation,
    UserApprovalSocialEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserApprovalSocialEventMutation,
    UserApprovalSocialEventMutationVariables
  >(UserApprovalSocialEventDocument, options);
}
export type UserApprovalSocialEventMutationHookResult = ReturnType<
  typeof useUserApprovalSocialEventMutation
>;
export type UserApprovalSocialEventMutationResult =
  Apollo.MutationResult<UserApprovalSocialEventMutation>;
export type UserApprovalSocialEventMutationOptions = Apollo.BaseMutationOptions<
  UserApprovalSocialEventMutation,
  UserApprovalSocialEventMutationVariables
>;
export const GetCircleRequestDocument = gql`
  query GetCircleRequest($getCircleRequestId: Float!) {
    getCircleRequest(id: $getCircleRequestId) {
      AcceptedBy {
        id
        fullName
        firstName
        lastName
        phoneNumber
        profilePicture
        role
        email
      }
      Timeline {
        id
        User {
          id
          fullName
          createdAt
          updatedAt
        }
        userId
        customerId
        title
        description
        createdAt
        isAppVisible
      }
      Address {
        addressLine1
        addressLine2
        city
        country
        place
        state
        zipCode
      }
      id
      ride {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          place
          state
        }
        pickupAddress {
          addressLine1
          addressLine2
          country
          city
          place
          state
          zipCode
        }
        stops {
          addressLine1
          addressLine2
          city
          country
          place
          state
          zipCode
        }
        type
        waitTime
      }
      scheduledAt
      status
      AskTo {
        id
        fullName
        firstName
        lastName
        phoneNumber
        role
        email
      }
      RequestedBy {
        fullName
        firstName
        lastName
        phoneNumber
        role
        profilePicture
        email
      }
      RequestCategory {
        id
        name
      }
      description
      recurring
      frequency
    }
  }
`;

/**
 * __useGetCircleRequestQuery__
 *
 * To run a query within a React component, call `useGetCircleRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCircleRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCircleRequestQuery({
 *   variables: {
 *      getCircleRequestId: // value for 'getCircleRequestId'
 *   },
 * });
 */
export function useGetCircleRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCircleRequestQuery,
    GetCircleRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCircleRequestQuery, GetCircleRequestQueryVariables>(
    GetCircleRequestDocument,
    options
  );
}
export function useGetCircleRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCircleRequestQuery,
    GetCircleRequestQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCircleRequestQuery,
    GetCircleRequestQueryVariables
  >(GetCircleRequestDocument, options);
}
export type GetCircleRequestQueryHookResult = ReturnType<
  typeof useGetCircleRequestQuery
>;
export type GetCircleRequestLazyQueryHookResult = ReturnType<
  typeof useGetCircleRequestLazyQuery
>;
export type GetCircleRequestQueryResult = Apollo.QueryResult<
  GetCircleRequestQuery,
  GetCircleRequestQueryVariables
>;
export const UserCancelCircleRequestDocument = gql`
  mutation UserCancelCircleRequest(
    $circleRequestId: Int!
    $cancellationReason: String
  ) {
    userCancelCircleRequest(
      circleRequestId: $circleRequestId
      cancellationReason: $cancellationReason
    ) {
      id
      requestType
      requestedBy
      scheduledAt
      status
      updatedAt
      description
    }
  }
`;
export type UserCancelCircleRequestMutationFn = Apollo.MutationFunction<
  UserCancelCircleRequestMutation,
  UserCancelCircleRequestMutationVariables
>;

/**
 * __useUserCancelCircleRequestMutation__
 *
 * To run a mutation, you first call `useUserCancelCircleRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCancelCircleRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCancelCircleRequestMutation, { data, loading, error }] = useUserCancelCircleRequestMutation({
 *   variables: {
 *      circleRequestId: // value for 'circleRequestId'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useUserCancelCircleRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCancelCircleRequestMutation,
    UserCancelCircleRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserCancelCircleRequestMutation,
    UserCancelCircleRequestMutationVariables
  >(UserCancelCircleRequestDocument, options);
}
export type UserCancelCircleRequestMutationHookResult = ReturnType<
  typeof useUserCancelCircleRequestMutation
>;
export type UserCancelCircleRequestMutationResult =
  Apollo.MutationResult<UserCancelCircleRequestMutation>;
export type UserCancelCircleRequestMutationOptions = Apollo.BaseMutationOptions<
  UserCancelCircleRequestMutation,
  UserCancelCircleRequestMutationVariables
>;
export const UpdateCircleRequestTimeDocument = gql`
  mutation UpdateCircleRequestTime(
    $newTime: DateTime!
    $circleRequestId: Int!
    $untilTime: DateTime
  ) {
    updateCircleRequestTime(
      newTime: $newTime
      circleRequestId: $circleRequestId
      untilTime: $untilTime
    ) {
      id
      requestedBy
      requestType
      scheduledAt
      status
      updatedAt
    }
  }
`;
export type UpdateCircleRequestTimeMutationFn = Apollo.MutationFunction<
  UpdateCircleRequestTimeMutation,
  UpdateCircleRequestTimeMutationVariables
>;

/**
 * __useUpdateCircleRequestTimeMutation__
 *
 * To run a mutation, you first call `useUpdateCircleRequestTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCircleRequestTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCircleRequestTimeMutation, { data, loading, error }] = useUpdateCircleRequestTimeMutation({
 *   variables: {
 *      newTime: // value for 'newTime'
 *      circleRequestId: // value for 'circleRequestId'
 *      untilTime: // value for 'untilTime'
 *   },
 * });
 */
export function useUpdateCircleRequestTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCircleRequestTimeMutation,
    UpdateCircleRequestTimeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCircleRequestTimeMutation,
    UpdateCircleRequestTimeMutationVariables
  >(UpdateCircleRequestTimeDocument, options);
}
export type UpdateCircleRequestTimeMutationHookResult = ReturnType<
  typeof useUpdateCircleRequestTimeMutation
>;
export type UpdateCircleRequestTimeMutationResult =
  Apollo.MutationResult<UpdateCircleRequestTimeMutation>;
export type UpdateCircleRequestTimeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCircleRequestTimeMutation,
  UpdateCircleRequestTimeMutationVariables
>;
export const ListRequestCategoriesDocument = gql`
  query ListRequestCategories(
    $circleRequest: Boolean
    $serviceRequest: Boolean
  ) {
    listRequestCategories(
      circleRequest: $circleRequest
      serviceRequest: $serviceRequest
    ) {
      id
      name
      serviceRequest
      circleRequest
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useListRequestCategoriesQuery__
 *
 * To run a query within a React component, call `useListRequestCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRequestCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRequestCategoriesQuery({
 *   variables: {
 *      circleRequest: // value for 'circleRequest'
 *      serviceRequest: // value for 'serviceRequest'
 *   },
 * });
 */
export function useListRequestCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >(ListRequestCategoriesDocument, options);
}
export function useListRequestCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListRequestCategoriesQuery,
    ListRequestCategoriesQueryVariables
  >(ListRequestCategoriesDocument, options);
}
export type ListRequestCategoriesQueryHookResult = ReturnType<
  typeof useListRequestCategoriesQuery
>;
export type ListRequestCategoriesLazyQueryHookResult = ReturnType<
  typeof useListRequestCategoriesLazyQuery
>;
export type ListRequestCategoriesQueryResult = Apollo.QueryResult<
  ListRequestCategoriesQuery,
  ListRequestCategoriesQueryVariables
>;
export const ReviewServiceRequestDocument = gql`
  mutation ReviewServiceRequest($data: CreateServiceRequestReviewInput!) {
    reviewServiceRequest(data: $data) {
      serviceRequestId
      updatedAt
      createdAt
    }
  }
`;
export type ReviewServiceRequestMutationFn = Apollo.MutationFunction<
  ReviewServiceRequestMutation,
  ReviewServiceRequestMutationVariables
>;

/**
 * __useReviewServiceRequestMutation__
 *
 * To run a mutation, you first call `useReviewServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewServiceRequestMutation, { data, loading, error }] = useReviewServiceRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReviewServiceRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewServiceRequestMutation,
    ReviewServiceRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReviewServiceRequestMutation,
    ReviewServiceRequestMutationVariables
  >(ReviewServiceRequestDocument, options);
}
export type ReviewServiceRequestMutationHookResult = ReturnType<
  typeof useReviewServiceRequestMutation
>;
export type ReviewServiceRequestMutationResult =
  Apollo.MutationResult<ReviewServiceRequestMutation>;
export type ReviewServiceRequestMutationOptions = Apollo.BaseMutationOptions<
  ReviewServiceRequestMutation,
  ReviewServiceRequestMutationVariables
>;
export const GetZipCodeMatchesBusinessRulesDocument = gql`
  query GetZipCodeMatchesBusinessRules($zipCode: String!) {
    getZipCodeMatchesBusinessRules(zipCode: $zipCode) {
      success
      message
    }
  }
`;

/**
 * __useGetZipCodeMatchesBusinessRulesQuery__
 *
 * To run a query within a React component, call `useGetZipCodeMatchesBusinessRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZipCodeMatchesBusinessRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZipCodeMatchesBusinessRulesQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useGetZipCodeMatchesBusinessRulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetZipCodeMatchesBusinessRulesQuery,
    GetZipCodeMatchesBusinessRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetZipCodeMatchesBusinessRulesQuery,
    GetZipCodeMatchesBusinessRulesQueryVariables
  >(GetZipCodeMatchesBusinessRulesDocument, options);
}
export function useGetZipCodeMatchesBusinessRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetZipCodeMatchesBusinessRulesQuery,
    GetZipCodeMatchesBusinessRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetZipCodeMatchesBusinessRulesQuery,
    GetZipCodeMatchesBusinessRulesQueryVariables
  >(GetZipCodeMatchesBusinessRulesDocument, options);
}
export type GetZipCodeMatchesBusinessRulesQueryHookResult = ReturnType<
  typeof useGetZipCodeMatchesBusinessRulesQuery
>;
export type GetZipCodeMatchesBusinessRulesLazyQueryHookResult = ReturnType<
  typeof useGetZipCodeMatchesBusinessRulesLazyQuery
>;
export type GetZipCodeMatchesBusinessRulesQueryResult = Apollo.QueryResult<
  GetZipCodeMatchesBusinessRulesQuery,
  GetZipCodeMatchesBusinessRulesQueryVariables
>;
export const GetStripeTransactionsDocument = gql`
  query GetStripeTransactions($filter: StripeTransactionFilter!) {
    getStripeTransactions(filter: $filter) {
      transactions {
        status
        amount
        activityId
        stripeTransactionId
      }
    }
  }
`;

/**
 * __useGetStripeTransactionsQuery__
 *
 * To run a query within a React component, call `useGetStripeTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStripeTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >(GetStripeTransactionsDocument, options);
}
export function useGetStripeTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStripeTransactionsQuery,
    GetStripeTransactionsQueryVariables
  >(GetStripeTransactionsDocument, options);
}
export type GetStripeTransactionsQueryHookResult = ReturnType<
  typeof useGetStripeTransactionsQuery
>;
export type GetStripeTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetStripeTransactionsLazyQuery
>;
export type GetStripeTransactionsQueryResult = Apollo.QueryResult<
  GetStripeTransactionsQuery,
  GetStripeTransactionsQueryVariables
>;
export const GetServiceRequestReviewDocument = gql`
  query getServiceRequestReview($serviceRequestId: Int!) {
    getServiceRequestReview(serviceRequestId: $serviceRequestId) {
      rating
    }
  }
`;

/**
 * __useGetServiceRequestReviewQuery__
 *
 * To run a query within a React component, call `useGetServiceRequestReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceRequestReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceRequestReviewQuery({
 *   variables: {
 *      serviceRequestId: // value for 'serviceRequestId'
 *   },
 * });
 */
export function useGetServiceRequestReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceRequestReviewQuery,
    GetServiceRequestReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceRequestReviewQuery,
    GetServiceRequestReviewQueryVariables
  >(GetServiceRequestReviewDocument, options);
}
export function useGetServiceRequestReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceRequestReviewQuery,
    GetServiceRequestReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceRequestReviewQuery,
    GetServiceRequestReviewQueryVariables
  >(GetServiceRequestReviewDocument, options);
}
export type GetServiceRequestReviewQueryHookResult = ReturnType<
  typeof useGetServiceRequestReviewQuery
>;
export type GetServiceRequestReviewLazyQueryHookResult = ReturnType<
  typeof useGetServiceRequestReviewLazyQuery
>;
export type GetServiceRequestReviewQueryResult = Apollo.QueryResult<
  GetServiceRequestReviewQuery,
  GetServiceRequestReviewQueryVariables
>;
export const GetDefaultTimezoneDocument = gql`
  query GetDefaultTimezone {
    getDefaultTimezone {
      timezone
    }
  }
`;

/**
 * __useGetDefaultTimezoneQuery__
 *
 * To run a query within a React component, call `useGetDefaultTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultTimezoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDefaultTimezoneQuery,
    GetDefaultTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDefaultTimezoneQuery,
    GetDefaultTimezoneQueryVariables
  >(GetDefaultTimezoneDocument, options);
}
export function useGetDefaultTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultTimezoneQuery,
    GetDefaultTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDefaultTimezoneQuery,
    GetDefaultTimezoneQueryVariables
  >(GetDefaultTimezoneDocument, options);
}
export type GetDefaultTimezoneQueryHookResult = ReturnType<
  typeof useGetDefaultTimezoneQuery
>;
export type GetDefaultTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetDefaultTimezoneLazyQuery
>;
export type GetDefaultTimezoneQueryResult = Apollo.QueryResult<
  GetDefaultTimezoneQuery,
  GetDefaultTimezoneQueryVariables
>;
export const CreateAddressDocument = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
    }
  }
`;
export type CreateAddressMutationFn = Apollo.MutationFunction<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      createAddressInput: // value for 'createAddressInput'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >(CreateAddressDocument, options);
}
export type CreateAddressMutationHookResult = ReturnType<
  typeof useCreateAddressMutation
>;
export type CreateAddressMutationResult =
  Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress(
    $updateAddressInput: UpdateAddressInput!
    $updateAddressId: Int!
  ) {
    updateAddress(
      updateAddressInput: $updateAddressInput
      id: $updateAddressId
    ) {
      id
    }
  }
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      updateAddressInput: // value for 'updateAddressInput'
 *      updateAddressId: // value for 'updateAddressId'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation DeleteAddress($deleteAddressId: Int!) {
    deleteAddress(id: $deleteAddressId) {
      success
    }
  }
`;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      deleteAddressId: // value for 'deleteAddressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >(DeleteAddressDocument, options);
}
export type DeleteAddressMutationHookResult = ReturnType<
  typeof useDeleteAddressMutation
>;
export type DeleteAddressMutationResult =
  Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const GetAddressesDocument = gql`
  query GetAddresses($customerId: Float!) {
    getAddresses(customerId: $customerId) {
      id
      customerId
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
      isDefault
      createdAt
      updatedAt
      place
    }
  }
`;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetAddressesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAddressesQuery,
    GetAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressesQuery, GetAddressesQueryVariables>(
    GetAddressesDocument,
    options
  );
}
export function useGetAddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressesQuery,
    GetAddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressesQuery, GetAddressesQueryVariables>(
    GetAddressesDocument,
    options
  );
}
export type GetAddressesQueryHookResult = ReturnType<
  typeof useGetAddressesQuery
>;
export type GetAddressesLazyQueryHookResult = ReturnType<
  typeof useGetAddressesLazyQuery
>;
export type GetAddressesQueryResult = Apollo.QueryResult<
  GetAddressesQuery,
  GetAddressesQueryVariables
>;
export const GetCustomerDocument = gql`
  query GetCustomer($customerId: Float!) {
    getCustomer(customerId: $customerId) {
      id
      firstName
      lastName
      fullName
      addresses {
        id
        addressLine1
        addressLine2
        city
        customerId
        isDefault
        state
        zipCode
        place
      }
      users {
        role
        user {
          id
          fullName
          createdAt
        }
      }
      createdBy
      createdAt
      updatedAt
      UserCustomerRoles {
        role
        Users {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const SetAppFeaturesSelectedDocument = gql`
  mutation SetAppFeaturesSelected(
    $appFeaturesSelected: SelectAppFeaturesInput!
  ) {
    setAppFeaturesSelected(appFeaturesSelected: $appFeaturesSelected) {
      id
      userId
      seniorMember
      familyPartner
      circleMember
      createdAt
      updatedAt
      User {
        id
        email
      }
    }
  }
`;
export type SetAppFeaturesSelectedMutationFn = Apollo.MutationFunction<
  SetAppFeaturesSelectedMutation,
  SetAppFeaturesSelectedMutationVariables
>;

/**
 * __useSetAppFeaturesSelectedMutation__
 *
 * To run a mutation, you first call `useSetAppFeaturesSelectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAppFeaturesSelectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAppFeaturesSelectedMutation, { data, loading, error }] = useSetAppFeaturesSelectedMutation({
 *   variables: {
 *      appFeaturesSelected: // value for 'appFeaturesSelected'
 *   },
 * });
 */
export function useSetAppFeaturesSelectedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAppFeaturesSelectedMutation,
    SetAppFeaturesSelectedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetAppFeaturesSelectedMutation,
    SetAppFeaturesSelectedMutationVariables
  >(SetAppFeaturesSelectedDocument, options);
}
export type SetAppFeaturesSelectedMutationHookResult = ReturnType<
  typeof useSetAppFeaturesSelectedMutation
>;
export type SetAppFeaturesSelectedMutationResult =
  Apollo.MutationResult<SetAppFeaturesSelectedMutation>;
export type SetAppFeaturesSelectedMutationOptions = Apollo.BaseMutationOptions<
  SetAppFeaturesSelectedMutation,
  SetAppFeaturesSelectedMutationVariables
>;
export const UpdateCurrentUserDocument = gql`
  mutation UpdateCurrentUser($updateUserInput: UpdateCurrentUserInput!) {
    updateCurrentUser(updateUserInput: $updateUserInput) {
      firebaseToken
      id
      email
    }
  }
`;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >(UpdateCurrentUserDocument, options);
}
export type UpdateCurrentUserMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserMutation
>;
export type UpdateCurrentUserMutationResult =
  Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables
>;
export const GetUserRelatedCustomersDocument = gql`
  query GetUserRelatedCustomers($roles: [AppUser!], $userId: Int) {
    getUserRelatedCustomers(roles: $roles, userId: $userId) {
      id
      firstName
      lastName
      fullName
      email
      createdAt
      updatedAt
      createdBy
      UserCustomerRoles {
        id
        userId
        customerId
        role
        createdAt
        updatedAt
        Users {
          id
          createdAt
          updatedAt
          fullName
          lastName
          firstName
        }
      }
    }
  }
`;

/**
 * __useGetUserRelatedCustomersQuery__
 *
 * To run a query within a React component, call `useGetUserRelatedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRelatedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRelatedCustomersQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserRelatedCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserRelatedCustomersQuery,
    GetUserRelatedCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserRelatedCustomersQuery,
    GetUserRelatedCustomersQueryVariables
  >(GetUserRelatedCustomersDocument, options);
}
export function useGetUserRelatedCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserRelatedCustomersQuery,
    GetUserRelatedCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserRelatedCustomersQuery,
    GetUserRelatedCustomersQueryVariables
  >(GetUserRelatedCustomersDocument, options);
}
export type GetUserRelatedCustomersQueryHookResult = ReturnType<
  typeof useGetUserRelatedCustomersQuery
>;
export type GetUserRelatedCustomersLazyQueryHookResult = ReturnType<
  typeof useGetUserRelatedCustomersLazyQuery
>;
export type GetUserRelatedCustomersQueryResult = Apollo.QueryResult<
  GetUserRelatedCustomersQuery,
  GetUserRelatedCustomersQueryVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount {
    deleteAccount {
      success
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const SaveFirebaseTokenDocument = gql`
  mutation SaveFirebaseToken($firebaseToken: String!, $uuid: String!) {
    saveFirebaseToken(firebaseToken: $firebaseToken, uuid: $uuid) {
      id
      uuid
    }
  }
`;
export type SaveFirebaseTokenMutationFn = Apollo.MutationFunction<
  SaveFirebaseTokenMutation,
  SaveFirebaseTokenMutationVariables
>;

/**
 * __useSaveFirebaseTokenMutation__
 *
 * To run a mutation, you first call `useSaveFirebaseTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFirebaseTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFirebaseTokenMutation, { data, loading, error }] = useSaveFirebaseTokenMutation({
 *   variables: {
 *      firebaseToken: // value for 'firebaseToken'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSaveFirebaseTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveFirebaseTokenMutation,
    SaveFirebaseTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveFirebaseTokenMutation,
    SaveFirebaseTokenMutationVariables
  >(SaveFirebaseTokenDocument, options);
}
export type SaveFirebaseTokenMutationHookResult = ReturnType<
  typeof useSaveFirebaseTokenMutation
>;
export type SaveFirebaseTokenMutationResult =
  Apollo.MutationResult<SaveFirebaseTokenMutation>;
export type SaveFirebaseTokenMutationOptions = Apollo.BaseMutationOptions<
  SaveFirebaseTokenMutation,
  SaveFirebaseTokenMutationVariables
>;
export const GetVisitSummariesByCoachIdDocument = gql`
  query GetVisitSummariesByCoachId($coachVisitId: Float!) {
    getVisitSummariesByCoachId(coachVisitId: $coachVisitId) {
      results {
        body
        createdBy
        createdAt
        CoachVisit {
          Coach {
            firstName
            lastName
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useGetVisitSummariesByCoachIdQuery__
 *
 * To run a query within a React component, call `useGetVisitSummariesByCoachIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisitSummariesByCoachIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisitSummariesByCoachIdQuery({
 *   variables: {
 *      coachVisitId: // value for 'coachVisitId'
 *   },
 * });
 */
export function useGetVisitSummariesByCoachIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVisitSummariesByCoachIdQuery,
    GetVisitSummariesByCoachIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVisitSummariesByCoachIdQuery,
    GetVisitSummariesByCoachIdQueryVariables
  >(GetVisitSummariesByCoachIdDocument, options);
}
export function useGetVisitSummariesByCoachIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisitSummariesByCoachIdQuery,
    GetVisitSummariesByCoachIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVisitSummariesByCoachIdQuery,
    GetVisitSummariesByCoachIdQueryVariables
  >(GetVisitSummariesByCoachIdDocument, options);
}
export type GetVisitSummariesByCoachIdQueryHookResult = ReturnType<
  typeof useGetVisitSummariesByCoachIdQuery
>;
export type GetVisitSummariesByCoachIdLazyQueryHookResult = ReturnType<
  typeof useGetVisitSummariesByCoachIdLazyQuery
>;
export type GetVisitSummariesByCoachIdQueryResult = Apollo.QueryResult<
  GetVisitSummariesByCoachIdQuery,
  GetVisitSummariesByCoachIdQueryVariables
>;
