import * as React from 'react';

export const ChatTabIconSelected = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25 21.2532H6.2504C6.54261 21.2529 6.82553 21.1505 7.05018 20.9637L7.05068 20.9633L11.2002 17.5032L18.625 17.5032L18.6256 17.5032C19.6528 17.502 20.6376 17.0935 21.3639 16.3671C22.0903 15.6408 22.4989 14.6559 22.5 13.6287V13.6282V4.62817V4.62762C22.4989 3.6004 22.0903 2.61558 21.3639 1.88923C20.6376 1.16288 19.6528 0.754314 18.6256 0.753174H18.625L4.375 0.753174L4.37445 0.753174C3.34723 0.754314 2.36241 1.16288 1.63606 1.88923C0.909705 2.61558 0.50114 3.6004 0.5 4.62762V4.62817L0.5 13.6282L0.5 13.6287C0.50114 14.6559 0.909705 15.6408 1.63606 16.3671C2.36241 17.0935 3.34723 17.502 4.37445 17.5032H4.375H5V20.0032C5 20.3347 5.1317 20.6526 5.36612 20.8871C5.60054 21.1215 5.91848 21.2532 6.25 21.2532Z"
      fill="#EA8231"
      stroke="white"
    />
  </svg>
);
