import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonAvatar, IonButton, IonContent, IonRow } from '@ionic/react';

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
};

export const MainContainer = styled(IonContent)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  ion-icon {
    font-size: 24px;
  }
  ion-card {
    margin-inline: 0px;
  }
  --padding-bottom: 100px;
`;

export const PageTitle = styled.span`
  font-size: ${pixelToRem(24)};
  color: #000000;
  font-weight: 700;
  line-height: 28px;
  margin-top: 24px;
`;

export const AccountHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardText = styled.div`
  color: #23364b;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  font-weight: 700;
  margin-left: 24px;
`;

export const StyledAvatar = styled(IonAvatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #ea8231;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;

  font-weight: 500;
  font-size: ${pixelToRem(22)};
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #ea8231;
`;

export const UserInfoRow = styled(IonRow)`
  align-items: center;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const LeaveButton = styled(IonButton)`
  height: 32px;
  width: 68px;
  font-weight: 500;
  text-transform: none;
  font-size: ${pixelToRem(12)};
  color: var(--ion-color-primary-red);
  --background: transparent;
  --background-activated: transparent;
  --border-radius: 50px;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-primary-red);
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const LogOutContainer = styled.div`
  margin-top: 4rem;
  ion-label {
    color: var(--interaction-error, #ea4255);
    font-size: 1rem;
    font-weight: 700;
    line-height: 140%;
  }
  span {
    font-size: 0.875rem;
    font-weight: 400;
  }
  padding: 0 1.5rem;
`;
