/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import LocationsPage from '../pages/locations/locations';
import LoginPage from '../pages/login/login';
import ServiceRequestedSuccessfully from '../pages/ServiceRequestedSuccessfully/ServiceRequestedSuccessfully';
import ConfirmAddressPage from '../pages/address-form/address-form';
import SavedPlacesPage from '../pages/saved-places/saved-places';
import RecentSearchesPage from '../pages/recent-searches/recent-searches';
import NotificationCenter from '../pages/notification-center/notification-center';
import ActivityStatusPage from '../pages/activity-status/activity-status';
import ServiceForm from '../pages/service-form/service-form';
import CircleForm from '../pages/circle-form/circle-form';
import ChatInbox from '../pages/tabs/chat-inbox/chat-inbox';
import CoachProfile from '../pages/CoachProfile/CoachProfile';
import ProProfile from '../pages/pro-profile/pro-profile';
import InvitesSuccessPage from '../pages/invites-success/invites-success';
import WelcomeStep1 from '../pages/welcome-steps/welcome-step1';
import WelcomeStep2Yourself from '../pages/welcome-steps/welcome-step2-yourself';
import WelcomeStep2Lovedone from '../pages/welcome-steps/welcome-step2-lovedone';
import WelcomeStep3 from '../pages/welcome-steps/welcome-step3';
import { ROUTES } from '../constants/routes';
import FeatureSelectionPage from '../pages/feature-selection/FeatureSelectionPage';
import MembershipAboutYouPage from '../pages/membership/about-you/MembershipAboutYouPage';
import MembershipAboutFriend from '../pages/membership/about-friend/MembershipAboutFriendPage';
import MembershipMatchFriendPage from '../pages/membership/match-friend/MembershipMatchFriendPage';
import MembershipNotMatchFriendPage from '../pages/membership/not-match-friend/MembershipNotMatchFriendPage';
import MembershipMatchLovedOnePage from '../pages/membership/match-loved-one/MembershipMatchLovedOnePage';
import MembershipNotMatchLovedOnePage from '../pages/membership/not-match-loved-one/MembershipNotMatchLovedOnePage';
import MembershipAboutLovedOnePage from '../pages/membership/about-loved-one/MembershipAboutLovedOnePage';
import Tabs from '../pages/tabs/tabs';
import ChatboxPage from '../pages/chatbox/chatbox';
import MembershipOutroPage from '../pages/membership/outro/MembershipOutroPage';
import VerifyEmail from '../pages/verify-email/verify-email';
import WhoIsThisFor from '../pages/WhoIsThisFor/WhoIsThisFor';

import { useUnmountContext } from '../providers/unmountHiddenCompProvider';
import { useAuthContext } from '../providers/authProvider';
import { getUserLandingRoute } from '../utils/routes/getUserLandingRoute';
import BryaUserPage from '../pages/brya-user/BryaUserPage';
import NotificationSettingsPage from '../pages/notification-settings/notification-settings';
import { NotificationsPolling } from '../providers/notificationsPolling';
import MyCirclePage from '../pages/my-circle/my-circle';
import MyFamilyPage from '../pages/my-family/my-family';
import AccountDeletedPage from '../pages/account-deleted/AccountDeletedPage';
import MyProfilePage from '../pages/MyProfile/MyProfile';
import PreferencesPage from '../pages/Account/Preferences/PreferencesPage';
import AppUrlListener from '../utils/AppUrlListener';
import InviteFamilyMemberPage from '../pages/invite-family-member/InviteFamilyMemberPage.tsx';
import InviteCircleMemberPage from '../pages/invite-circle-member/InviteCircleMemberPage.tsx';
import ServiceRequestPage from '../pages/ServiceRequestPage/ServiceRequestPage';
import { NewServiceRequestChatPage } from '../pages/NewServiceRequestChatPage/NewServiceRequestChatPage';
import { NewCircleRequestChatPage } from '../pages/NewCircleRequestChatPage/NewCircleRequestChatPage';
import AgentActivityDetailPage from '../pages/AgentActivityDetailPage/AgentActivityDetailPage';
import CircleMemberActivityDetailPage from '../pages/CircleMemberActivityDetailPage/CircleMemberActivityDetailPage';
import { CustomerActivityCircleRequestPage } from '../pages/CustomerActivityCircleRequestPage/CustomerActivityCircleRequestPage';
import { CustomerActivitySocialEventPage } from '../pages/CustomerActivitySocialEventPage/CustomerActivitySocialEventPage';
import { CustomerActivityCoachVisitPage } from '../pages/CustomerActivityCoachVisitPage/CustomerActivityCoachVisitPage';

const LandingRoute: React.FC = () => {
  const { user, authLoaded } = useAuthContext();
  if (!authLoaded) return null;
  return <Redirect exact from="/" to={getUserLandingRoute(user)} />;
};

const Routes: React.FC = () => {
  const { uniqueKey } = useUnmountContext();
  return (
    <IonReactRouter key={uniqueKey}>
      <NotificationsPolling />
      <AppUrlListener />
      <IonRouterOutlet animated={true}>
        <Route path="/tabs" component={Tabs} />
        <Route path="/locations" component={LocationsPage} />
        <Route path="/login" component={LoginPage} />
        <Route path={ROUTES.ACCOUNT_DELETED} component={AccountDeletedPage} />
        <Route
          path={ROUTES.SERVICE_SUBMIT_SUCCESS}
          component={ServiceRequestedSuccessfully}
        />
        <Route path="/confirm-address" component={ConfirmAddressPage} />
        <Route path="/saved-places" component={SavedPlacesPage} />
        <Route path="/recent-searches" component={RecentSearchesPage} />
        <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
        <Route
          path={ROUTES.NOTIFICATION_SETTINGS}
          component={NotificationSettingsPage}
        />
        <Route path="/notification-center" component={NotificationCenter} />
        <Route
          path={ROUTES.NEW_SERVICE_REQUEST_CHAT}
          component={NewServiceRequestChatPage}
        />
        <Route
          path={ROUTES.NEW_CIRCLE_REQUEST_CHAT}
          component={NewCircleRequestChatPage}
        />
        <Route path={ROUTES.CHAT} component={ChatInbox} />
        <Route path="/activityStatus" component={ActivityStatusPage} />
        <Route
          path={ROUTES.CUSTOMERS.CIRCLE_REQUEST_DETAIL}
          component={CustomerActivityCircleRequestPage}
        />
        <Route
          path={ROUTES.CUSTOMERS.SOCIAL_EVENT_DETAIL}
          component={CustomerActivitySocialEventPage}
        />
        <Route
          path={ROUTES.CUSTOMERS.COACH_VISIT_DETAIL}
          component={CustomerActivityCoachVisitPage}
        />

        <Route path={ROUTES.SERVICE_FORM} component={ServiceForm} />
        <Route path={ROUTES.SERVICE_REQUEST} component={ServiceRequestPage} />
        <Route path={ROUTES.CIRCLE_FORM} component={CircleForm} />
        <Route path={ROUTES.CHATBOX} component={ChatboxPage} />
        <Route
          path={ROUTES.ACCOUNT.MY_BRYA_PARTNER}
          component={CoachProfile}
          exact
        />
        <Route path="/pro-profile" component={ProProfile} />
        <Route path={ROUTES.ACCOUNT.MY_CIRCLE} component={MyCirclePage} />
        <Route path={ROUTES.ACCOUNT.MY_FAMILY} component={MyFamilyPage} />
        <Route path={ROUTES.ACCOUNT.MY_PROFILE} component={MyProfilePage} />
        <Route
          path={ROUTES.ACCOUNT.PREFERENCES}
          component={PreferencesPage}
          exact
        />
        <Route
          path={ROUTES.INVITES.INVITE_FAMILY_MEMBER}
          component={InviteFamilyMemberPage}
        />
        <Route
          path={ROUTES.INVITES.INVITE_CIRCLE_MEMBER}
          component={InviteCircleMemberPage}
        />
        <Route path={ROUTES.INVITES.SUCCESS} component={InvitesSuccessPage} />
        <Route path="/welcome-step1" component={WelcomeStep1} />
        <Route
          path="/welcome-step2-yourself"
          component={WelcomeStep2Yourself}
        />
        <Route path={ROUTES.MEMBERSHIP.WHO_IS_THIS} component={WhoIsThisFor} />
        <Route
          path="/welcome-step2-lovedone"
          component={WelcomeStep2Lovedone}
        />
        <Route path="/welcome-step3" component={WelcomeStep3} />
        <Route
          path={ROUTES.ACTIVITY_DETAIL}
          component={CircleMemberActivityDetailPage}
        />
        <Route
          path={ROUTES.ACTIVITY_SERVICE_REQUEST_DETAIL}
          component={AgentActivityDetailPage}
        />
        <Route
          path={ROUTES.FEATURE_SELECTION}
          component={FeatureSelectionPage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.ABOUT_YOU}
          component={MembershipAboutYouPage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE}
          component={MembershipAboutLovedOnePage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.ABOUT_FRIEND}
          component={MembershipAboutFriend}
        />
        <Route
          path={ROUTES.MEMBERSHIP.MATCH_LOVED_ONE}
          component={MembershipMatchLovedOnePage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.NOT_MATCH_LOVED_ONE}
          component={MembershipNotMatchLovedOnePage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.MATCH_FRIEND}
          component={MembershipMatchFriendPage}
        />
        <Route
          path={ROUTES.MEMBERSHIP.NOT_MATCH_FRIEND}
          component={MembershipNotMatchFriendPage}
        />
        <Route path={ROUTES.MEMBERSHIP.OUTRO} component={MembershipOutroPage} />
        <Route path={ROUTES.BRYA_USER} component={BryaUserPage} />

        {/* NOTE: Temporary routes to make all deeplinks work until we refactor /activityStatus */}
        <Route
          path={ROUTES.CIRCLE_REQUEST_REQUESTOR_DETAIL}
          component={LandingRoute}
        />
        <Route
          path={ROUTES.SERVICE_REQUEST_REQUESTOR_DETAIL}
          component={LandingRoute}
        />
        <Route path={ROUTES.SOCIAL_EVENT_DETAIL} component={LandingRoute} />
        <Route path={ROUTES.COACH_VISIT_DETAIL} component={LandingRoute} />
        {/* End NOTE */}

        <Route exact path="/" component={LandingRoute} />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Routes;
