import { gql } from '@apollo/client';

export const GET_COACH_PROFILE = gql`
  query GetCoachProfile {
    getCoachProfile {
      id
      firstName
      lastName
      fullName
      bio
      profilePicture
    }
  }
`;

export const GET_COACH_REVIEWS = gql`
  query GetUserCoachReviews(
    $sort: ReviewSortInput
    $pageSize: Int
    $page: Int
  ) {
    getUserCoachReviews(sort: $sort, pageSize: $pageSize, page: $page) {
      reviews {
        id
        comment
        rating
        coachId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          id
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
    }
  }
`;

export const REVIEW_COACH_MUTATION = gql`
  mutation ReviewCoach($data: CreateCoachReviewInput!) {
    reviewCoach(data: $data) {
      comment
      rating
      coachId
      reviewedBy
      createdAt
      updatedAt
      ReviewedBy {
        id
        firstName
        lastName
        createdAt
      }
    }
  }
`;

export const GET_COACH_REQUEST = gql`
  query GetCoachVisit($coachVisitId: Int!) {
    getCoachVisit(coachVisitId: $coachVisitId) {
      id
      Address {
        addressLine1
        addressLine2
        city
        country
        state
        place
      }
      Coach {
        id
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
        email
      }
      Customer {
        fullName
        firstName
        lastName
      }
      RequestedBy {
        firstName
        fullName
        lastName
        phoneNumber
        profilePicture
      }
      description
      requestType
      scheduledAt
      status
      recurring
      frequency
    }
  }
`;
