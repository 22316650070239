import { CreateAddressInput } from '../graphql/generated';

// NOTE: adapted from
// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
export const buildAddressFromPlaceResult = (
  placeResult: google.maps.places.PlaceResult | null
) => {
  const address: Partial<CreateAddressInput> = {
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
  };

  if (!placeResult?.address_components) return address;

  for (const component of placeResult.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number':
        address.addressLine1 = `${component.long_name} ${address.addressLine1}`;
        break;
      case 'route':
        address.addressLine1 += component.short_name;
        break;
      case 'postal_code':
        address.zipCode = `${component.long_name}${address.zipCode}`;
        break;
      case 'postal_code_suffix':
        address.zipCode = `${address.zipCode}-${component.long_name}`;
        break;
      case 'administrative_area_level_1':
        address.state = component.short_name;
        break;
      case 'administrative_area_level_2':
        address.city = component.short_name;
        break;
      case 'country':
        address.country = component.long_name;
        break;
      case 'subpremise':
        address.addressLine2 = component.short_name;
        break;
    }
  }
  return address;
};

export const getCityFromGooglePlaces = (
  placeDetails: google.maps.places.PlaceResult
) => {
  const cityFullName =
    placeDetails?.address_components &&
    placeDetails?.address_components[2]?.types.includes('locality')
      ? placeDetails?.address_components &&
        placeDetails?.address_components[2]?.long_name
      : (placeDetails?.address_components &&
          placeDetails?.address_components[3]?.long_name) ??
        '';

  return cityFullName;
};

export const getAddressLineFromGooglePlaces = (
  placeDetails: google.maps.places.PlaceResult
) => {
  const addressLine =
    placeDetails?.address_components &&
    placeDetails?.address_components[0]?.long_name +
      ' ' +
      placeDetails?.address_components[1]?.long_name;

  return addressLine;
};

export const getZipCodeFromGooglePlaces = (
  placeDetails: google.maps.places.PlaceResult
) => {
  const zipCode =
    (placeDetails?.address_components &&
      placeDetails?.address_components.find((x) =>
        x.types.includes('postal_code')
      )?.short_name) ??
    '';
  return zipCode;
};

export const getStateFromGooglePlaces = (
  placeDetails: google.maps.places.PlaceResult
) => {
  const state =
    (placeDetails?.address_components &&
      placeDetails?.address_components.find((x) =>
        x.types.includes('administrative_area_level_1')
      )?.short_name) ??
    '';
  return state;
};

export const getPlaceFromGooglePlaces = (
  placeDetails: google.maps.places.PlaceResult
) => {
  const place =
    placeDetails?.name && placeDetails?.opening_hours
      ? placeDetails?.name
      : null ?? '';
  return place;
};
