import styled from 'styled-components';

export const StyledCircleAvatar = styled.div`
  width: 34px;
  height: 34px;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  border: 1px solid var(--colors-primary-orange);
  color: var(--colors-primary-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(8px);
`;

export const StyledCardTitle = styled.div`
  color: #212121;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700;
  line-height: 22.4px;
  word-wrap: break-word;
`;

export const StyledCardSubtitle = styled.div`
  color: ${(props) => props.color || '#939191'};
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.6px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledContainer = styled.div`
  padding-top: 25px;
  padding-bottom: 4px;
  padding-left: 17px;
  padding-right: 18px;
`;
