import { IonButton, IonCard, IonCheckbox, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Toolbar = styled(IonToolbar)`
  --background: auto;
  --color: white;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-end: 16px;
  --padding-start: 16px;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
`;

export const LogoutButton = styled(IonButton)`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  background-color: unset;
  letter-spacing: -0.4000000059604645px;
  color: var(--colors-primary-orange);
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;

  ::part(native) {
    padding: 0;
  }
`;

export const Card = styled(IonCard)`
  margin: 0;
  padding: 12px;
`;

export const CardTitle = styled.span`
  font-size: ${() => pixelToRem(18)};
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.012em;
  color: var(--colors-primary-blue);
`;

export const Checkbox = styled(IonCheckbox)`
  --checkbox-background-checked: #1890ff;
  --border-color-checked: #1890ff;
  --border-radius: 2px;
  --size: 16px;
`;
