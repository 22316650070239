import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div<{
  hasPaymentAuthorizationFailed?: boolean;
  hasPaymentFailed?: boolean;
}>`
  .react-tabs {
    font-weight: 500;
    font-size: ${pixelToRem(12)};
    line-height: 15px;
    color: #666666;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 26.72%,
      #f3f3f3 42.12%
    );
    &__tab {
      position: unset;
      width: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__tab-list {
      display: flex;
      justify-content: space-between;
      border: none;

      /* scheduled-tab - dot for hasPaymentAuthorizationFailed */
      li:nth-child(2) {
        position: relative;
        &:after {
          ${({ hasPaymentAuthorizationFailed }) =>
            !hasPaymentAuthorizationFailed && 'opacity: 0;'}
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          width: 0.4375rem;
          height: 0.4375rem;
          border-radius: 50%;
          background: #ea8231;
          transition: opacity 1s;
        }
      }

      /* scheduled-tab - dot for hasPaymentFailed */
      li:nth-child(3) {
        position: relative;
        &:after {
          ${({ hasPaymentFailed }) => !hasPaymentFailed && 'opacity: 0;'}
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: auto;
          width: 0.4375rem;
          height: 0.4375rem;
          border-radius: 50%;
          background: #ea8231;
          transition: opacity 1s;
        }
      }
    }
    &__tab--selected {
      color: #ea8231;
      border: none;
      border-bottom: 2px solid #ea8231;
    }
    &:after {
      content: '';
      position: absolute;
      height: 80px;
      width: 100vw;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(246, 246, 246, 0) 0%,
        rgba(246, 246, 246, 0.8) 48.96%,
        #f7f7f7 86.25%
      );
    }
  }
`;

export const StyledIonContent = styled(IonContent)`
  height: calc(100vh - 251px);
  width: 100%;
  --padding-bottom: 300px;
  --padding-top: 32px;
`;

export const TimeLineContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabContent = styled.div<{ hasTimeLine?: boolean }>`
  display: grid;
  grid-template-columns: ${({ hasTimeLine }) =>
    hasTimeLine ? '30px 1fr' : '1fr'};
  align-items: center;
  height: fit-content;
  gap: 16px;
`;
export const ActivityLine = styled.div`
  width: 1px;
  height: 100%;
  background: #d9d9d9;
  margin-top: 8px;
`;

export const CardContainer = styled.div`
  width: 100%;
`;

export const Upcoming = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  width: 100%;
  margin: 16px 0;
  padding: 0 24px 0 72px;
  height: 40px;
  h3 {
    font-weight: 400;
    font-size: ${pixelToRem(14)};
    line-height: 140%;
    margin: 0;
  }
  div {
    border: 1px solid #9e9e9e;
    width: 100%;
  }
  div:nth-child(1) {
    margin-right: 8px;
  }
  div:last-child {
    margin-left: 8px;
  }
`;
