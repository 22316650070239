import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  ApprovalServiceRequestStatus,
  GetMobileSocialEventQuery,
  useUserApprovalSocialEventMutation,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import { locationOutline } from 'ionicons/icons';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { useHistory } from 'react-router';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { ActivityCardItemOrganizedBy } from '../ActivityCardItemOrganizedBy/ActivityCardItemOrganizedBy';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';
import { useAppContext } from '../../providers/appContextProvider';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';

type SocialEventQueryResult = GetMobileSocialEventQuery['getMobileSocialEvent'];

type UserUnavailableCustomerActivitySocialEventProps = {
  customerId: number;
  socialEvent: SocialEventQueryResult;
  onSocialEventAccepted: () => unknown;
};

export const UserUnavailableCustomerActivitySocialEvent: React.FC<
  UserUnavailableCustomerActivitySocialEventProps
> = ({ customerId, socialEvent, onSocialEventAccepted }) => {
  const { t } = useTranslation();
  const isRecurringEvent = Boolean(socialEvent.recurring);
  const history = useHistory();
  const { globalAlert } = useAppContext();
  const acceptSocialEventErrorHandler = useDefaultMutationErrorHandler({
    tag: '[UserUnavailableCustomerActivitySocialEvent][AcceptSocialEvent]',
  });
  const [acceptSocialEvent, { loading: isAccepting }] =
    useUserApprovalSocialEventMutation({
      onError: acceptSocialEventErrorHandler,
      onCompleted: onSocialEventAccepted,
    });

  const loading = isAccepting;
  const address = {
    addressLine1: socialEvent.addressLine1,
    addressLine2: socialEvent.addressLine2,
    zipCode: socialEvent.zipCode,
    city: socialEvent.city,
    state: socialEvent.state,
    country: socialEvent.country,
    place: socialEvent.place,
  };

  const handleEditResponse = () => {
    globalAlert({
      title: t(
        'UserUnavailableCustomerActivitySocialEvent.EditResponseAlertTitle'
      ),
      subtitle: t(
        'UserUnavailableCustomerActivitySocialEvent.EditResponseAlertSubtitle'
      ),
      firstButtonLabel: t(
        'UserUnavailableCustomerActivitySocialEvent.EditResponseAlertFirstButtonLabel'
      ),
      firstButtonAction: () => {
        void acceptSocialEvent({
          variables: {
            action: ApprovalServiceRequestStatus.Accept,
            socialEventId: socialEvent.id,
            customerId,
          },
        });
      },
      secondButtonLabel: t(
        'UserUnavailableCustomerActivitySocialEvent.EditResponseAlertSecondButtonLabel'
      ),
    });
  };

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle title={socialEvent.eventName} />
        ) : (
          socialEvent.eventName
        )
      }
      background="var(--colors-gradient-red)"
      bottomSheetBackground="var(--colors-gradient-red-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityCancelled />
          {t('UserUnavailableCustomerActivitySocialEvent.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('UserUnavailableCustomerActivitySocialEvent.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <BodyText>{socialEvent.description}</BodyText>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <CardList>
            <ActivityCardItemAddress icon={locationOutline} address={address} />
            <ActivityCardItemOrganizedBy organizer={socialEvent.RequestedBy} />
            <ActivityCardItemRequestScheduledAt
              scheduledAt={socialEvent.scheduledAt}
            />
            {/* TODO: display timeline when provided by the BE */}
          </CardList>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <Button
            color="primary-red"
            onClick={handleEditResponse}
            loading={loading}
          >
            {t('UserUnavailableCustomerActivitySocialEvent.PrimaryCta')}
          </Button>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
