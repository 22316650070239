import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonInput } from '@ionic/react';

export type FontProps = {
  fontSize?: string;
  color?: string;
};

export type ButtonProps = {
  screenHeight?: number;
  screenWidth?: number;
  isContentHidden?: boolean;
};

export const MainContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  height: ${(props) => props.screenHeight && props.screenHeight * 0.9}px;
`;

export const TitleEnableLocation = styled.p<FontProps>`
  font-size: ${pixelToRem(20)};
  color: #000000;
  font-weight: 700;
  line-height: 28px;
  z-index: 9999;
  margin-top: 50px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ccc;
`;

// export const CircleContainer = styled.div`
//   width: 44px;
//   height: 44px;
//   border-radius: 22px;
//   display: flex;
//   background-color: white;
//   justify-content: center;
//   align-items: center;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
// `;

export const GoToMapButtonLabel = styled.span`
  font-weight: 500;
  font-size: ${pixelToRem(16)};
  text-transform: capitalize;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputsContainer = styled.div<ButtonProps>`
  width: ${(props) => props.screenWidth && props.screenWidth}px;
  margin-left: -16px;
  margin-top: ${(props) => (props.isContentHidden ? 0 : 24)}px;
`;

export const ModalHeader = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 9999;
`;

export const CustomIonInput = styled(IonInput)`
  height: 48px;
  background: #ffffff;
  border: 1px solid #212121;
  border-radius: 14px;
  margin-bottom: 16px;
  margin-top: 8px;
  --placeholder-color: rgba(158, 158, 158, 1);
  color: rgba(33, 33, 33, 1);
  padding-inline-start: 40px !important;
`;

export const NoMatchesFoundContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: center;
`;

export const NoMatchesFoundLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: rgba(234, 66, 85, 1);
`;

export const HomeItemTitle = styled.p`
  font-size: ${pixelToRem(16)};
  color: #212121;
  line-height: 20px;
`;

export const HomeItemSubtitle = styled.span`
  font-size: ${pixelToRem(12)};
  color: #595959;
`;
