import * as React from 'react';

export const RecentSearches = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1812_84604)">
      <path
        d="M16.678 15.202h0a.21.21 0 01.046.3l-.766 1.044a.213.213 0 01-.3.046l-4.427-3.233h0a.212.212 0 01-.088-.174V5.81c0-.118.096-.214.213-.214h1.289c.117 0 .214.096.214.214v6.63h0l3.82 2.761zm4.733-10.297l-1.6 1.253a9.456 9.456 0 00-7.468-3.643 9.456 9.456 0 00-6.7 2.778A9.412 9.412 0 003.61 8.307a9.4 9.4 0 00-.745 3.691 9.468 9.468 0 00.74 3.689A9.5 9.5 0 005.636 18.7a9.456 9.456 0 006.702 2.777 9.434 9.434 0 006.703-2.777 9.411 9.411 0 002.194-3.418.214.214 0 01.27-.129l1.518.523c.112.04.171.165.131.277-1.612 4.412-5.844 7.562-10.813 7.562-6.318 0-11.447-5.092-11.512-11.4A11.496 11.496 0 0112.337.481a11.486 11.486 0 019.074 4.424zm.009.01l1.584 2.025-.007 1.49c0 .137-.13.238-.264.206l-4.2-1.026a.213.213 0 01-.08-.376h-.001 0l2.968-2.318zm1.584 2.025L21.42 4.915l1.254-.979a.212.212 0 01.344.169l-.014 2.835z"
        fill="#000"
        fillOpacity={0.85}
        stroke="#23364B"
        strokeWidth={0.00111607}
      />
    </g>
    <defs>
      <clipPath id="clip0_1812_84604">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);
