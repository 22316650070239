import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3a3a3a;
  margin-top: 40px;

  h2 {
    font-weight: 700;
    font-size: ${pixelToRem(24)};
    line-height: 120%;
    text-align: center;
    margin-top: 32px;
  }

  p {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    text-align: center;
    max-width: 279px;
  }
`;
