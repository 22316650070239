import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonLabel } from '@ionic/react';

export const Container = styled.div`
  ion-content {
    --background: linear-gradient(
      180deg,
      #ffffff 0%,
      #ffffff 26.72%,
      #f3f3f3 42.12%
    );
  }
  .pro-toolbar {
    --padding-end: 2rem;
    --padding-start: 1.5rem;
  }
`;
export const HeaderContent = styled.div`
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(
    --bottom-gradient-transparent,
    linear-gradient(
      180deg,
      rgba(246, 246, 246, 0) 0%,
      rgba(246, 246, 246, 0.8) 48.96%,
      #f6f6f6 86.25%
    )
  );
`;

export const WrapperLink = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0.5rem;
  ion-icon {
    margin-right: 0.5rem;
    color: var(--colors-primary-blue);
  }
  ion-label {
    font-size: ${pixelToRem(18)};
    font-weight: 600;
    line-height: 140%;
    color: var(--color-deep-blue) !important;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserName = styled(IonLabel)`
  color: var(--color-text, rgba(0, 0, 0, 0.87));
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 120%;
`;

export const UserEmail = styled(IonLabel)`
  color: var(--color-text, rgba(0, 0, 0, 0.87));
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  width: 100%;
  position: absolute;
  bottom: 0;

  ion-button {
    font-size: 1rem;
    &::part(native) {
      padding: 0;
    }
    margin: 0;
  }
`;

export const Version = styled(IonLabel)`
  color: var(--primary-blue, #23364b) !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 1rem;
`;

export const NotificationCounter = styled.div`
  position: absolute;
  top: 0;
  left: 1rem;
  display: flex;
  padding: 0.2rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 0.3125rem;
  background: var(--primary-orange, #ea8231);

  color: var(--sunset-orange-1, #fff7e6);
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 700;
`;

export const NotificationContainer = styled.div<{
  hasUnreadNotification?: boolean;
}>`
  position: relative;

  ${({ hasUnreadNotification }) =>
    hasUnreadNotification &&
    `
      border-radius: 3.125rem;
      background: var(--sunset-orange-1, #fff7e6);
      display: flex;
      width: 2.26088rem;
      height: 2.36363rem;
      padding: 0.25rem;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
  `}
`;
