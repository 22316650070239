import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText, BodyTextMedium } from '../Typography/Body/Body';
import { Trans, useTranslation } from 'react-i18next';
import { GetCircleRequestQuery } from '../../graphql/generated';
import { Accordion } from '../Accordion/Accordion';
import {
  CardItem,
  CardItemTitle,
} from './ActivityCardItemRideInformation.styles';
import { homeOutline } from 'ionicons/icons';
import { formatAddress } from '../../utils/address/formatAddress';

type Ride = GetCircleRequestQuery['getCircleRequest']['ride'];

type ActivityCardItemRideInformationProps = {
  ride: Ride;
};
export const ActivityCardItemRideInformation: React.FC<
  ActivityCardItemRideInformationProps
> = ({ ride }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      header={
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="ion-align-self-center">
              <IonIcon
                icon={homeOutline}
                color="var(--colors-primary-blue)"
                style={{
                  width: '24px',
                  height: '24px',
                  marginInlineEnd: '8px',
                }}
              />
            </IonCol>
            <IonCol className="ion-align-self-center">
              <BodyText color="var(--colors-primary-blue)">
                {formatAddress(ride?.pickupAddress)}
              </BodyText>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
      content={
        <IonGrid>
          {ride?.stops?.map((stop, idx) => (
            <IonRow
              className="ion-margin-vertical"
              key={String(stop.addressLine1) + String(idx)}
            >
              <IonCol>
                <CardItem className="ion-padding">
                  <CardItemTitle>
                    <Trans
                      i18nKey={`ActivityCardItemRideInformation.StopNumber`}
                      values={{ number: idx + 1 }}
                    />
                  </CardItemTitle>
                  <BodyTextMedium>{formatAddress(stop)}</BodyTextMedium>
                </CardItem>
              </IonCol>
            </IonRow>
          ))}
          <IonRow className="ion-margin-vertical">
            <IonCol>
              <CardItem className="ion-padding">
                <CardItemTitle>
                  {t(`ActivityCardItemRideInformation.DropOff`)}
                </CardItemTitle>
                <BodyTextMedium>
                  {formatAddress(ride?.dropoffAddress)}
                </BodyTextMedium>
              </CardItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />
  );
};
