import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonChip } from '@ionic/react';

interface ChipProps {
  selected: boolean;
}
export const Chip = styled(IonChip)<ChipProps>`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  text-align: center;

  --background: ${(props) =>
    props.selected ? 'var(--colors-primary-orange)' : 'var(--body-text-200)'};
  --color: ${(props) => (props.selected ? 'white' : 'var(--body-text-900)')};
`;
