import { isValidPhoneNumber } from 'libphonenumber-js';

export const getPhoneNumberInputYupValidator = (errMsg: string) => {
  return [
    'phone-number',
    errMsg,
    function isValid(phoneNumber: string | undefined) {
      return phoneNumber
        ? Boolean(isValidPhoneNumber(phoneNumber, 'US'))
        : true;
    },
  ] as const;
};
