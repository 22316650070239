import styled from 'styled-components';
import { Space } from '../../../../components/Space/Space';
import { IonIcon } from '@ionic/react';
import { checkmarkOutline, timeOutline } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { pixelToRem } from '../../../../utils/helper';
import { AppointmentRequestActivitiesStatus } from '../../../../graphql/generated';

interface PillProps {
  color: string;
  backgroundColor: string;
  borderColor: string;
}
const Pill = styled.div<PillProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 16px;
  border: 1px solid ${({ borderColor }) => borderColor};
  width: fit-content;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};

  font-size: ${() => pixelToRem(14)};
  font-weight: 500;
  line-height: 140%;
`;

interface ActivitiesStatusFiltersProps {
  selected: AppointmentRequestActivitiesStatus;
  onFilterChange: (filter: AppointmentRequestActivitiesStatus) => void;
}
export const ActivitiesStatusFilters: React.FC<
  ActivitiesStatusFiltersProps
> = ({ selected, onFilterChange }) => {
  return (
    <Space size="16px" align="center" direction="row">
      <Pill
        backgroundColor={
          selected === AppointmentRequestActivitiesStatus.Requested
            ? 'var(--colors-primary-blue)'
            : 'unset'
        }
        color={
          selected === AppointmentRequestActivitiesStatus.Requested
            ? 'white'
            : 'var(--colors-primary-blue)'
        }
        borderColor="var(--colors-primary-blue)"
        onClick={() =>
          onFilterChange(AppointmentRequestActivitiesStatus.Requested)
        }
      >
        <IonIcon src={timeOutline} style={{ width: 18, height: 18 }} />
        <Trans i18nKey="activities.filters.requested" />
      </Pill>
      <Pill
        backgroundColor={
          selected === AppointmentRequestActivitiesStatus.Confirmed
            ? 'var(--interaction-success)'
            : 'unset'
        }
        color={
          selected === AppointmentRequestActivitiesStatus.Confirmed
            ? 'white'
            : 'var(--interaction-success)'
        }
        borderColor="var(--interaction-success)"
        onClick={() =>
          onFilterChange(AppointmentRequestActivitiesStatus.Confirmed)
        }
      >
        <IonIcon src={checkmarkOutline} style={{ width: 18, height: 18 }} />
        <Trans i18nKey="activities.filters.confirmed" />
      </Pill>
    </Space>
  );
};
