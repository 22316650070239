import styled from 'styled-components';
import { AppointmentRequestListItem } from './AppointmentRequestListItem';
import { Fragment } from 'react';

import { Space } from '../../../../components/Space/Space';
import { GraphqlAppointmentRequest } from '../../../../graphql/generated';
import ActivityCardSkeleton from '../../../../components/ActivityCard/ActivityCard.Skeleton';
import dayjs from 'dayjs';
import { useDateWithTimezone } from '../../../../utils/hooks/useDateWithTimezone';

const getScheduleDate = (appointmentRequest: GraphqlAppointmentRequest) => {
  if (appointmentRequest?.CircleRequest) {
    return appointmentRequest.CircleRequest.scheduledAt;
  }

  if (appointmentRequest?.ServiceRequest) {
    return appointmentRequest.ServiceRequest.scheduledAt;
  }

  throw new Error('Appointment not associated with Service or Circle Request');
};

const DateDivider = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 0;
`;

const Divider = styled.hr`
  flex-grow: 1;
  height: 1px;
  background: #9e9e9e;
`;

const DateDividerText = styled.span`
  color: var(--body-text-600);
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

interface AppointmentRequestsListProps {
  withDateDayDivider: boolean;
  appointmentRequests: GraphqlAppointmentRequest[];
}
export function AppointmentRequestsList({
  appointmentRequests,
  withDateDayDivider,
}: AppointmentRequestsListProps) {
  const { formatDateDividerInTimezone } = useDateWithTimezone();

  return (
    <Space direction="column" size="medium">
      {appointmentRequests?.map((appointmentRequest, idx) => {
        const scheduledAt = getScheduleDate(appointmentRequest);
        if (
          withDateDayDivider &&
          (idx === 0 ||
            dayjs(scheduledAt).diff(
              dayjs(getScheduleDate(appointmentRequests[idx - 1])),
              'days'
            ))
        ) {
          return (
            <Fragment key={appointmentRequest.id}>
              <DateDivider>
                <Divider />
                <DateDividerText>
                  {formatDateDividerInTimezone(scheduledAt)}
                </DateDividerText>
                <Divider />
              </DateDivider>
              <AppointmentRequestListItem
                appointmentRequest={appointmentRequest}
              />
            </Fragment>
          );
        } else {
          return (
            <AppointmentRequestListItem
              key={appointmentRequest.id}
              appointmentRequest={appointmentRequest}
            />
          );
        }
      })}
    </Space>
  );
}

export const AppointmentRequestsListSkeleton: React.FC = () => {
  return (
    <>
      <ActivityCardSkeleton textStyles={{ width: '100%' }} />
      <ActivityCardSkeleton textStyles={{ display: 'none' }} />
      <ActivityCardSkeleton textStyles={{ display: 'none' }} />
    </>
  );
};
