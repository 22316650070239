/* eslint-disable import/no-named-as-default-member */
import React, { useRef, useState } from 'react';
import { CustomIonModal } from './modalProvider.style';

// eslint-disable-next-line import/no-named-as-default-member
export const ModalContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openModal: (content: React.ReactNode, params?: unknown) => {},
  closeModal: () => {},
});

interface IModalProvider {
  children: JSX.Element;
}

interface ModalContentProps {
  modalParams: {
    joinCircleId: number;
  };
}

export const ModalProvider: React.FC<IModalProvider> = ({ children }) => {
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalParams, setModalParams] = useState<unknown>(null);
  const modal = useRef<HTMLIonModalElement>(null);

  const openModal = (content: React.ReactNode, params?: unknown) => {
    setModalContent(content);
    setModalParams(params);
  };

  const closeModal = () => {
    setModalContent(null);
    setModalParams(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalContent && (
        <CustomIonModal
          ref={modal}
          initialBreakpoint={1}
          breakpoints={[0, 1]}
          isOpen={true}
        >
          {React.isValidElement(modalContent) &&
            React.cloneElement(modalContent, {
              modalParams,
            } as ModalContentProps)}
        </CustomIonModal>
      )}
    </ModalContext.Provider>
  );
};
