import { IonButton, IonContent } from '@ionic/react';
import styled from 'styled-components';

export const SignInButton = styled(IonButton)`
  margin-top: var(--ion-margin, 16px);
  --border-radius: 50px;
  --background: var(--ion-color-primary);
  --background-activated: #e59b62;
`;

export const SignUpButton = styled(IonButton)`
  font-weight: 700;
  margin-top: var(--ion-margin, 16px);
  --color: var(--ion-color-primary);
  --border-width: 1px;
  --border-radius: 50px;
  --border-color: var(--ion-color-primary);
  --background-activated: #e59b62;
`;

export const StyledIonContent = styled(IonContent)`
  --padding-start: var(--ion-padding, 16px);
  --padding-end: var(--ion-padding, 16px);
  --padding-top: var(--ion-padding, 16px);
  --padding-bottom: var(--ion-padding, 16px);
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
  &.md {
    .swiper {
      height: 75vh;
    }
  }
`;
