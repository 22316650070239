import * as React from 'react';

interface BulbOutlineProps extends React.SVGProps<SVGSVGElement> {
  pathProps?: JSX.IntrinsicAttributes & React.SVGProps<SVGPathElement>;
}

export const BulbOutline = ({ pathProps, ...props }: BulbOutlineProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_388_4869)">
      <path
        d="M12 17.9983V11.9983M14.25 17.9983V16.8733C14.25 15.5139 15.7285 14.2282 16.6875 13.3108C18.0394 12.0185 18.75 10.2822 18.75 8.24832C18.75 4.49832 15.7627 1.49832 12 1.49832C11.1129 1.49584 10.2341 1.66875 9.41399 2.00709C8.59392 2.34543 7.84883 2.84254 7.22154 3.46983C6.59425 4.09712 6.09714 4.84221 5.7588 5.66228C5.42046 6.48234 5.24755 7.3612 5.25003 8.24832C5.25003 10.2096 5.99112 12.0635 7.31253 13.3108C8.26643 14.2113 9.75003 15.4999 9.75003 16.8733V17.9983H14.25ZM10.5 22.4983H13.5H10.5ZM9.75003 20.2483H14.25H9.75003Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
      <path
        d="M13.7813 11.2474C13.7813 11.2474 12.773 11.9974 12 11.9974C11.227 11.9974 10.2188 11.2474 10.2188 11.2474"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...pathProps}
      />
    </g>
    <defs>
      <clipPath id="clip0_388_4869">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 -0.00390625)"
        />
      </clipPath>
    </defs>
  </svg>
);
