import * as React from 'react';

function IconActivityNeeded(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={83}
      height={100}
      viewBox="0 0 83 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.931 66.6L45.223 30c-1.397-2.595-5.118-2.595-6.516 0L19 66.6a3.7 3.7 0 003.253 5.454h39.42a3.7 3.7 0 003.258-5.455z"
        fill="#fff"
      />
      <path
        d="M68.453 64.703h0L48.745 28.104s0 0 0 0c-2.908-5.401-10.652-5.398-13.56-.001h0l-19.707 36.6h0a7.7 7.7 0 006.769 11.35h39.425s0 0 0 0a7.7 7.7 0 006.78-11.35z"
        stroke="#EA8231"
        strokeOpacity={0.5}
        strokeWidth={8}
      />
      <path
        d="M64.873 66.545L45.165 29.946c-1.397-2.595-5.118-2.595-6.516 0l-19.707 36.6A3.7 3.7 0 0022.195 72h39.42a3.7 3.7 0 003.258-5.455z"
        fill="#fff"
      />
      <path
        d="M71.917 62.753h0L52.21 26.154s0 0 0 0c-4.419-8.208-16.185-8.202-20.603-.002v.001l-19.708 36.6h0A11.7 11.7 0 0022.184 80h39.43s0 0 0 0a11.701 11.701 0 0010.303-17.247z"
        stroke="#EA8231"
        strokeOpacity={0.25}
        strokeWidth={16}
      />
      <path
        d="M64.873 66.545L45.165 29.946c-1.397-2.595-5.118-2.595-6.516 0l-19.707 36.6A3.7 3.7 0 0022.195 72h39.42a3.7 3.7 0 003.258-5.455zm-22.965-.211a2.313 2.313 0 110-4.626 2.313 2.313 0 010 4.626zm2.511-23.26l-.663 14.107a1.85 1.85 0 11-3.7 0l-.664-14.102a2.513 2.513 0 012.486-2.624h.024a2.514 2.514 0 012.513 2.625l.004-.007z"
        fill="#EA8231"
      />
    </svg>
  );
}

export default IconActivityNeeded;
