import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
} from '@ionic/react';
import { Trans } from 'react-i18next';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { Button } from '../../components/Button/Button';
import { GreenCheckMark } from '../../assets/images/GreenCheckMark';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';
import { BodyText } from '../../components/Typography/Body/Body';

const InvitesSuccessPage: React.FC = () => {
  const router = useHistory();

  const onInviteAnother = useCallback(() => {
    router.push(ROUTES.TABS.CONNECT);
  }, []);

  const onDone = useCallback(() => {
    router.push(ROUTES.TABS.ASK_BRYA);
  }, []);

  return (
    <>
      <IonPage>
        <IonContent className="ion-padding">
          <IonGrid style={{ marginTop: '64px' }}>
            <IonRow className="ion-padding-vertical ion-justify-content-center">
              <IonCol size="auto">
                <GreenCheckMark />
              </IonCol>
            </IonRow>

            <IonRow
              className="ion-justify-content-center ion-text-center"
              style={{ maxWidth: '325px', margin: 'auto' }}
            >
              <IonCol size="auto">
                <SecondaryTitle>
                  <Trans i18nKey="myAccount.inviteSuccess" />
                </SecondaryTitle>
              </IonCol>
            </IonRow>

            <IonRow className="ion-padding-vertical">
              <IonCol>
                <BodyText>
                  <Trans i18nKey="myAccount.thankYou" />
                </BodyText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter className="ion-padding">
          <Button color="primary-orange" onClick={onDone}>
            <Trans i18nKey="done" />
          </Button>
          <Button color="secondary-orange" onClick={onInviteAnother}>
            <Trans i18nKey="myAccount.inviteAnother" />
          </Button>
        </IonFooter>
      </IonPage>
    </>
  );
};

export default InvitesSuccessPage;
