/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface RideStop {
  id: number;
  title: string;
  subtitle: string;
}

export const buildRideStops = (data: any): RideStop[] => {
  const stops = data?.ride?.stops || [];
  const dropoffAddress = data?.ride?.dropoffAddress;

  const formattedStops: RideStop[] = stops
    .slice(0, 2)
    .map((stop: any, index: any) => ({
      id: index,
      title: `Stop ${index + 1}`,
      subtitle: `${stop.addressLine1}, ${stop.city}`,
    }));

  if (dropoffAddress) {
    formattedStops.push({
      id: 3,
      title: 'Drop-off location',
      subtitle: `${dropoffAddress.addressLine1}, ${dropoffAddress.city}`,
    });
  }

  return formattedStops;
};
