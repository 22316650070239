/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

export const getAddressInformationCircle = (data: any) => {
  if (data?.getCircleRequest.Address) {
    return `${data?.getCircleRequest.Address?.addressLine1}, ${data?.getCircleRequest.Address?.city}`;
  }
  if (data?.getCircleRequest.ride) {
    return `${data?.getCircleRequest.ride?.pickupAddress.addressLine1}${data?.getCircleRequest.ride?.pickupAddress.city}`;
  }
  return '';
};
