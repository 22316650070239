import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ServiceRequestAvailability } from '../graphql/generated';

export type DayjsDateInput =
  | string
  | number
  | Date
  | dayjs.Dayjs
  | null
  | undefined;
export type TimeSlot = { value: string; label: string; fullDate?: string };

export const generateRange = (
  start: DayjsDateInput,
  end: DayjsDateInput,
  stepValue: number,
  stepUnity: dayjs.ManipulateType,
  granularity: dayjs.OpUnitType = 'day',
  limit: '()' | '[]' | '[)' | '(]' = '[]'
): dayjs.Dayjs[] => {
  const ranges: dayjs.Dayjs[] = [];
  const startOfRange = dayjs.min(dayjs(start), dayjs(end));
  const endOfRange = dayjs.max(dayjs(start), dayjs(end));

  if (!startOfRange || !endOfRange) return ranges;

  let currentDate = startOfRange;
  while (currentDate.isBetween(startOfRange, endOfRange, granularity, limit)) {
    ranges.push(currentDate);
    currentDate = currentDate.add(stepValue, stepUnity);
  }

  return ranges;
};

extend(utc);

export const parseDateWithTime = (
  dateStr: string,
  timeStr: string
): dayjs.Dayjs => {
  const [hour, minute] = timeStr.split('Z')[0].split(':');
  return dayjs.utc(`${dateStr}T${hour}:${minute}:00Z`);
};

export const parseFromAvailability = (
  availability: ServiceRequestAvailability
): dayjs.Dayjs => {
  return parseDateWithTime(
    String(availability.date),
    String(availability.from)
  );
};

export const parseUntilAvailability = (
  availability: ServiceRequestAvailability
): dayjs.Dayjs => {
  // NOTE: because of how we are storing availabilities
  // it is possible to receive an object with the until time smaller than the from time
  // {"{"date": "2023-09-21", "from": "22:45Z+00:00", "until": "03:45Z+00:00"}"}
  // That means we changed to the next day.
  const [fromHours] = String(availability.from).split(':');
  const [untilHours] = String(availability.until).split(':');
  let date = String(availability.date);
  if (Number(untilHours) < Number(fromHours)) {
    date = dayjs(availability.date).add(1, 'day').format('YYYY-MM-DD');
  }

  return parseDateWithTime(date, String(availability.until));
};

export const formatCalendarNotificationDate = (date: DayjsDateInput) => {
  const now = dayjs();
  const diffInDays = now.diff(dayjs(date), 'day');

  const timezoneString = dayjs.tz(date).format('z').replace(/[-\d]/g, '');

  if (diffInDays === 1) {
    return `Yesterday at ${dayjs(date).format('h:mm A')} ${timezoneString}`;
  }

  if (diffInDays > 1 && diffInDays <= 7) {
    const dayOfTheWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ][dayjs(date).day()];
    return `Last ${dayOfTheWeek} at ${dayjs(date).format(
      'h:mm A'
    )} ${timezoneString}`;
  }

  return dayjs(date).calendar(now, {
    sameDay: `[Today at] h:mm A [${timezoneString}]`,
    sameElse: `MMMM Do [at] h:mm A [${timezoneString}]`,
  });
};

export const compareDates = (date: DayjsDateInput): boolean => {
  return dayjs().isBefore(date);
};

export const formatDayOfWeekInTimezone = (scheduledAt: Date | string) => {
  const date = dayjs(scheduledAt);
  const dayOfWeek = date.format('dddd');
  return dayOfWeek;
};
