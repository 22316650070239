/**
 * Different UI statuses that an Agent sees for a Service Request
 */
export enum AgentServiceRequestVisibleStatus {
  Confirmed = 'confirmed',
  Pending = 'pending',
  Declined = 'declined',
  Open = 'open',
}
export enum BryaLinks {
  PrivacyPolicy = 'https://www.brya.com/privacy-policy',
  TermsConditions = 'https://www.brya.com/about-4',
}

export enum PaymentCardType {
  Visa = 'visa',
  MasterCard = 'mastercard',
  Amex = 'amex',
}

export enum RequestType {
  Message = 'message',
  Circle = 'circle',
  Service = 'service',
  InviteFamilyMember = 'inviteFamilyMember',
  InviteCircleMember = 'inviteCircleMember',
}

export enum GenericEnum {
  NewAgentNeeded = 'newAgentNeeded',
  NewTimeNeeded = 'newTimeNeeded',
  NewProfessionalRequested = 'New professional requested',
  NewProposedTime = 'New date and time proposed',
}

export enum CircleFormEnum {
  MyCircle = 'myCircle',
  Ride = 'ride',
  Other = 'other',
  AddAvailability = 'addAvailability',
  ChooseTimeAndDate = 'chooseTimeAndDate',
  Flexible = 'flexible',
}

export const FeatureNames = {
  CreateAgentVerifyEmail: 'create-agent-verify-email',
  FacebookSSO: 'facebook-sso',
  GoogleSSO: 'google-sso',
  MembershipFlow: 'membership-flow',
  ConnectTabContactsButton: 'connect-tab-contacts-button',
  InviteOthersMembershipFlow: 'invite-others-membership-flow',
  WhoIsThisForAddIndividualButton: 'who-is-this-for-add-individual-button',
  NotificationSettingsEntryPoint: 'notification-settings-entry-point',
  AddAddressForRequestEntryPoint: 'add-address-for-request-entry-point',
  RequestRecurringInput: 'request-recurring-input',
  ProfilePageLink: 'profile-page-link',
  CustomerActivityCircleRequestPageV2:
    'customer-activity-circle-request-page-v2',
  CustomerActivitySocialEventPageV2: 'customer-activity-social-event-page-v2',
  CustomerActivityCoachVisitPageV2: 'customer-activity-coach-visit-page-v2',
  ServiceRequest: 'service-request',
  ActivitiesFilter: 'activities-filter',
  CircleRequest: 'circle-request',
  ConnectTab: 'connect-tab',
};

export enum PushPermissionsEnums {
  Granted = 'granted',
  Denied = 'denied',
  Prompt = 'prompt',
  PromptWithRationale = 'prompt-with-rationale',
}

export enum TalkJsCustomActions {
  ToggleFavorite = 'toggle_favorite',
  FilterFavoriteMessages = 'favorite_messages',
  FilterAllMessages = 'all_messages',
}
