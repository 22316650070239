import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { BodyText } from '../../../components/Typography/Body/Body';
import { Button } from '../../../components/Button/Button';
import { GreenCheckMark } from '../../../assets/images/GreenCheckMark';
import { ActionableCard } from '../components/ActionableCard';
import { useHistory, useLocation } from 'react-router';
import { useAuthContext } from '../../../providers/authProvider';
import { IUserData } from '../../../types';
import { getUserLandingRoute } from '../../../utils/routes/getUserLandingRoute';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames, RequestType } from '../../../constants/enums';
import { mailOpenOutline } from 'ionicons/icons';
import { ROUTES } from '../../../constants/routes';
import {
  RequestToNotExistingUserInput,
  UserInvitesType,
  useSendRequestToNotExistingUserMutation,
} from '../../../graphql/generated';
import { useAppContext } from '../../../providers/appContextProvider';
import { logger } from '../../../logger';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';

type LovedOneLocationState = Pick<
  RequestToNotExistingUserInput,
  'firstName' | 'lastName' | 'email' | 'phoneNumber'
>;

const InviteSentModalTitle = () => (
  <IonGrid>
    <IonRow className="ion-justify-content-center">
      <IonCol size="auto">
        <GreenCheckMark />
      </IonCol>
    </IonRow>
    <IonRow className="ion-padding-top ion-justify-content-center">
      <IonCol size="auto">
        <SecondaryTitle>
          <Trans i18nKey="membership.notMatchLovedOne.inviteSentModal.title" />
        </SecondaryTitle>
      </IonCol>
    </IonRow>
  </IonGrid>
);

const MembershipNotMatchLovedOnePage: React.FC = () => {
  const { user } = useAuthContext() as { user: IUserData };
  const history = useHistory();
  const { state: lovedOneFields } = useLocation<LovedOneLocationState>();
  const { isFeatureEnabled } = useFeatureFlags();
  const isInviteButtonEnable = isFeatureEnabled(
    FeatureNames.InviteOthersMembershipFlow
  );
  const { globalAlert } = useAppContext();
  const [sendRequestToNotExistingUser, { loading: isSendingRequest }] =
    useSendRequestToNotExistingUserMutation();

  const handleAddFamilyPartner = () => {
    history.push(getUserLandingRoute(user));
    history.push(ROUTES.MEMBERSHIP.WHO_IS_THIS, {
      requestType: RequestType.InviteFamilyMember,
    });
  };

  const handleAddLovedOne = () => {
    history.push(ROUTES.MEMBERSHIP.ABOUT_LOVED_ONE);
  };

  const handleContinue = () => {
    history.push(getUserLandingRoute(user));
  };

  const handleInviteLovedOne = async () => {
    try {
      await sendRequestToNotExistingUser({
        variables: {
          requestToNotExistingUserInput: {
            ...lovedOneFields,
            requestType: UserInvitesType.FamilyPartner,
          },
        },
      });
      globalAlert({
        title: <InviteSentModalTitle />,
        subtitle: (
          <Trans i18nKey="membership.notMatchLovedOne.inviteSentModal.subtitle" />
        ),
        firstButtonLabel: (
          <Trans i18nKey="membership.notMatchLovedOne.inviteSentModal.firstButtonLabel" />
        ),
        firstButtonAction: () => {
          handleAddLovedOne();
        },
        secondButtonLabel: (
          <Trans i18nKey="membership.notMatchLovedOne.inviteSentModal.secondButtonLabel" />
        ),
        secondButtonAction: () => {
          history.push(getUserLandingRoute(user));
        },
      });
    } catch (err) {
      logger.error({
        tag: '[NOT_MATCH_LOVED_ONE]',
        message: `Fail trying inviting loved one: ${getApolloErrorMessage(
          err
        )}`,
        error: err as Error,
      });
      globalAlert({
        title: <Trans i18nKey="genericError.title" />,
        subtitle: getApolloErrorMessage(err) || (
          <Trans i18nKey="genericError.subtitle" />
        ),
        firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
      });
    }
  };

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonCol size="auto">
              <GreenCheckMark />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <SecondaryTitle>
                <Trans i18nKey="membership.notMatchLovedOne.title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText style={{ whiteSpace: 'pre-line' }}>
                {lovedOneFields ? (
                  <Trans
                    i18nKey="membership.notMatchLovedOne.body"
                    values={lovedOneFields}
                  />
                ) : (
                  <Trans i18nKey="membership.notMatchLovedOne.fallbackBody" />
                )}
              </BodyText>
            </IonCol>
          </IonRow>

          {isInviteButtonEnable && lovedOneFields ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  iconSrc={mailOpenOutline}
                  onAction={handleInviteLovedOne}
                  actionText={
                    <Trans
                      i18nKey="membership.notMatchLovedOne.cardActions.inviteLovedOne"
                      values={lovedOneFields}
                    />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          {isInviteButtonEnable ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  onAction={handleAddFamilyPartner}
                  actionText={
                    <Trans i18nKey="membership.notMatchLovedOne.cardActions.addFamilyPartner" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          {isInviteButtonEnable ? (
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <ActionableCard
                  onAction={handleAddLovedOne}
                  actionText={
                    <Trans i18nKey="membership.notMatchLovedOne.cardActions.addLovedOne" />
                  }
                />
              </IonCol>
            </IonRow>
          ) : null}

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button
                color="primary-orange"
                onClick={handleContinue}
                loading={isSendingRequest}
              >
                <Trans i18nKey="membership.notMatchLovedOne.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MembershipNotMatchLovedOnePage;
