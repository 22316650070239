import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonHeader } from '@ionic/react';

export type LabelProps = {
  width: number;
};

export type BackProps = {
  marginTop?: number;
};

export type IosProps = {
  top?: number;
  margin?: number;
  marginBottom?: number;
};

export const MainContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
export const DoneButtonContent = styled.div`
  display: flex;
  align-items: right;
  margin-bottom: 20px;
`;

export const DoneButton = styled.button<IosProps>`
  position: relative;
  left: 80%;
  background-color: transparent;
  @media only screen and (max-width: 420px) {
    margin-top: 20px;
    top: 8px;
    margin-bottom: ${(props) => props.margin}px;
  }
  @media only screen and (min-width: 420px) {
    margin-top: 10px;
    top: 24px;
  }
`;
export const BackButton = styled.button<BackProps>`
  justify-content: center;
  display: flex;
  background-color: transparent;
  margin-bottom: 30px;
  margin-top: ${(props) => props.marginTop}px;
  @media only screen and (min-width: 420px) {
    margin-bottom: 42px;
  }
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ffffff;
  margin-top: 5px;
`;

export const Header = styled(IonHeader)`
  position: absolute;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 150;
  @media only screen and (min-width: 420px) {
    margin-bottom: 4px;
  }
`;

export const TitleHeader = styled.h2<IosProps>`
  font-size: ${pixelToRem(24)};
  color: #ffffff;
  justify-content: space-between;
  z-index: 200;
  margin-bottom: ${(props) => props.marginBottom}px;
  margin-left: 10px;
  @media only screen and (min-width: 420px) {
    position: absolute;
    left: 0;
    margin-top: 30px;
    top: 55px;
    margin-bottom: 20px;
  }
`;

export const BgContainer = styled.div`
  position: relative;
  top: 0;
  transform: translateY(-28px);
  height: 100%;
  width: 100%;
  right: 16px;
  bottom: 30%;
  @media only screen and (max-width: 420px) {
    bottom: 55%;
  }
  @media only screen and (min-width: 420px) {
    bottom: 40%;
  }
`;

export const RecurringIcon = styled.span`
  padding-left: 8px;
  padding-top: 6px;
`;
