import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
  CardText,
  PageTitle,
  StyledIonContent,
  Wrapper,
  RadioInfo,
} from './invites.style';
import { addCircleOutline } from 'ionicons/icons';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RightArrow } from '../../assets/images/RightArrow';
import { UserInvitesType } from '../../graphql/generated';
import { CardContent } from '../MyProfile/MyProfile.style';
import { ROUTES } from '../../constants/routes';
import { RequestType } from '../../constants/enums';

const InvitesTab: React.FC = () => {
  const router = useHistory();
  const { t } = useTranslation();
  const [memberType, setMemberType] = useState<UserInvitesType>(
    UserInvitesType.CircleMember
  );

  const handleInvite = useCallback(() => {
    if (memberType === UserInvitesType.CircleMember) {
      router.push(ROUTES.MEMBERSHIP.WHO_IS_THIS, {
        requestType: RequestType.InviteCircleMember,
      });
      return;
    }

    if (memberType === UserInvitesType.FamilyPartner) {
      router.push(ROUTES.MEMBERSHIP.WHO_IS_THIS, {
        requestType: RequestType.InviteFamilyMember,
      });
    }
  }, [memberType]);

  const handleRadioChange = useCallback(
    (event: { detail: { value: string } }) => {
      setMemberType(event.detail.value as UserInvitesType);
    },
    []
  );

  return (
    <StyledIonContent className="ion-padding">
      <PageTitle>{t('myAccount.inviteTitle')}</PageTitle>
      <Wrapper>
        <IonRadioGroup onIonChange={handleRadioChange} value={memberType}>
          <IonRadio
            labelPlacement="end"
            value={UserInvitesType.FamilyPartner}
            aria-label="Custom checkbox that is checked"
          >
            {t('myAccount.familyMember')}
          </IonRadio>
          <RadioInfo>{t('myAccount.familyInfo')}</RadioInfo>
          <br />

          <IonRadio
            labelPlacement="end"
            value={UserInvitesType.CircleMember}
            aria-label="Custom checkbox"
          >
            {t('myAccount.circleMember')}
          </IonRadio>
          <RadioInfo>{t('myAccount.circleInfo')}</RadioInfo>
        </IonRadioGroup>
      </Wrapper>
      <div style={{ marginTop: 24 }}>
        <IonCard
          onClick={handleInvite}
          style={{
            margin: '0px',
          }}
        >
          <IonCardHeader
            style={{
              padding: '16px',
            }}
          >
            <CardContent>
              <CardContent>
                <IonIcon icon={addCircleOutline} color="#23364b"></IonIcon>
                <CardText>{t('myAccount.invite')}</CardText>
              </CardContent>
              <RightArrow color="#23364b" />
            </CardContent>
          </IonCardHeader>
        </IonCard>
      </div>
    </StyledIonContent>
  );
};

export default InvitesTab;
