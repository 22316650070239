import React, { useCallback } from 'react';
import { IconMapDeviceLocation } from '../../assets/images/IconMapDeviceLocation';
import {
  AllowButton,
  DescriptionEnableLocation,
  DontAllowButton,
  MainContainer,
  MapContainer,
  TitleEnableLocation,
  StyledIonModal,
} from './ModalDeviceLocation.style';
import { Geolocation } from '@capacitor/geolocation';
import { MapDeviceLocation } from '../../assets/images/MapDeviceLocation';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

interface ModalDeviceLocationI {
  isOpenModal: boolean;
  setOpenModal: (isOpenModal: boolean) => void;
}

const ModalDeviceLocation: React.FC<ModalDeviceLocationI> = ({
  isOpenModal,
  setOpenModal,
}) => {
  const i18n = useTranslation();
  const { width } = useWindowDimensions();

  const allowPermission = async () => {
    setOpenModal(false);
    const { coarseLocation, location } = await Geolocation.requestPermissions();
    if (coarseLocation === 'granted' && location === 'granted') {
      //TODO: Save coords
      //const { coords } = await Geolocation.getCurrentPosition();
    }
  };

  const handleModalDismiss = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <StyledIonModal
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      isOpen={isOpenModal}
      onIonModalDidDismiss={handleModalDismiss}
    >
      <MainContainer>
        <MapContainer>
          <IconMapDeviceLocation
            style={{ position: 'absolute', zIndex: '1', bottom: '328px' }}
          />
          <MapDeviceLocation
            style={{
              position: 'relative',
              top: '30px',
              zIndex: '0',
              opacity: 0.6,
            }}
            width={width}
          ></MapDeviceLocation>
          <div
            style={{
              position: 'absolute',
              background: 'white',
              top: '45%',
              height: '50%',
              width: '100%',
              opacity: 0.9,
            }}
          ></div>
        </MapContainer>
        <TitleEnableLocation>{i18n.t('titleLocation')}</TitleEnableLocation>
        <DescriptionEnableLocation>
          {i18n.t('descriptionLocation')}
        </DescriptionEnableLocation>
        <>
          <AllowButton slot={'primary'} onClick={() => void allowPermission()}>
            <>{i18n.t('allowLocation')}</>
          </AllowButton>
          <DontAllowButton
            fill={'outline'}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <>{i18n.t('dontAllowLocation')}</>
          </DontAllowButton>
        </>
      </MainContainer>
    </StyledIonModal>
  );
};

export default ModalDeviceLocation;
