import { IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { ROUTES } from '../../constants/routes';

export type IonLabelProps = {
  isSelected: boolean;
  tab?: string;
  askBryaButton?: boolean;
  hasUnreadServiceNotification: boolean;
};

export const IonLabelStyled = styled(IonLabel)<IonLabelProps>`
  --ion-color: #23364b !important;
  text-transform: uppercase;
  font-weight: ${(props) => (props.isSelected ? '500' : '400')};
  font-size: ${({ askBryaButton }) =>
    askBryaButton ? pixelToRem(12) : pixelToRem(10)};
  line-height: ${({ askBryaButton }) =>
    askBryaButton ? '0.875rem' : '0.75rem'};
  margin-top: ${(props) =>
    props.tab === `${ROUTES.TABS.ASK_BRYA}` && props.isSelected ? -4 : 6}px;
  color: ${(props) =>
    props.hasUnreadServiceNotification === true
      ? 'var(--character-warning, #faad14)'
      : 'inherit'};
`;

export const IonButtonContent = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AskBryaContainer = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--neutral-1, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
`;
