import React, { useEffect } from 'react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useDeeplinkHandler } from './hooks/useDeeplinkHandler';

const AppUrlListener: React.FC = () => {
  const handleDeeplink = useDeeplinkHandler();
  useEffect(() => {
    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      handleDeeplink(event.url);
    });
  }, []);

  return null;
};

export default AppUrlListener;
