/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import {
  ActionContainer,
  Container,
  SelectContainer,
  SlotsWrapper,
  StyledIonToggle,
} from './AvailabilitySlot.styles';
import { IonSelect, IonSelectOption, IonIcon } from '@ionic/react';
import { addCircleOutline, trashOutline } from 'ionicons/icons';
import AvailabilityCustomTime from '../AvailabilityCustomTime/AvailabilityCustomTime';
import { motion, AnimatePresence } from 'framer-motion';
import { IAvailabilitySlot, IAvailabilityTime } from '../../types';

const SLOT_TIME = {
  ALL_DAY: '9AM - 6M',
  MORNING: '9AM - 12AM',
  AFTERNOON: '13PM - 18PM',
  CUSTOM: 'Custom',
};
let nextId = 0;

interface ICustomTime {
  id?: string;
  label: string;
}

const AvailabilitySlot: React.FC<{
  data: IAvailabilitySlot;
  availabilityData: IAvailabilitySlot[];
  selectedAvailability: IAvailabilityTime[];
  setAvailabilityData: (param: any) => void;
  setSelectedAvailability: (param: any) => void;
}> = ({
  data,
  availabilityData,
  setAvailabilityData,
  selectedAvailability,
  setSelectedAvailability,
}) => {
  const [openCustomTimer, setOpenCustomTimer] = useState(false);
  const [selectedCustomTimer, setSelectedCustomTimer] = useState('');
  const [selectedCustomTimerId, setSelectedCustomTimerId] = useState<string>();
  const [customSlotLabel, setCustomSlotLabel] = useState<ICustomTime[]>([]);
  const currentTimeSlot = availabilityData.find(
    (obj: { id: string }) => obj.id == data?.id
  );
  const currentTimeSlotIndex = availabilityData.findIndex(
    (obj: { id: string }) => obj.id == data?.id
  );

  const updateIsDaySelected = () => {
    setAvailabilityData((current: IAvailabilitySlot[]) =>
      current.map((obj: { id: string }) => {
        if (obj.id === currentTimeSlot?.id) {
          if (availabilityData[currentTimeSlotIndex].isDaySelected) {
            return { ...obj, isDaySelected: false };
          } else {
            return { ...obj, isDaySelected: true };
          }
        }
        return obj;
      })
    );
  };
  const updateTimePeriod = () => {
    updateIsDaySelected();
  };

  const addAvailabilityDateTime = (selectedDate: IAvailabilityTime) => {
    selectedDate.date = data?.fullDate as unknown as Date;
    if (selectedAvailability?.find((value) => value.id === selectedDate?.id)) {
      setSelectedAvailability((current: IAvailabilityTime[]) =>
        current.map((obj) => {
          if (obj.id === selectedDate.id) {
            return {
              ...obj,
              from: selectedDate.from,
              until: selectedDate.until,
            };
          }
          return obj;
        })
      );
    } else {
      setSelectedAvailability([...selectedAvailability, selectedDate]);
    }
  };

  const updateCustomTimeLabelOnAdditionalSlot = (customTime: ICustomTime) => {
    if (customSlotLabel?.find((value) => value.id === customTime?.id)) {
      setCustomSlotLabel((current: ICustomTime[]) =>
        current.map((obj: ICustomTime) => {
          if (obj.id === customTime.id) {
            return {
              ...obj,
              label: customTime.label,
            };
          }
          return obj;
        })
      );
    } else {
      setCustomSlotLabel([...customSlotLabel, customTime]);
    }
  };

  const handleCustomTime = (
    selectedTimes: IAvailabilityTime,
    startEndTime: string,
    slotId?: string
  ) => {
    addAvailabilityDateTime(selectedTimes);
    setSelectedCustomTimer(startEndTime);
    updateCustomTimeLabelOnAdditionalSlot({ id: slotId, label: startEndTime });
  };

  const handleSlotTimes = (selectedTime: string, additionalSlotId?: string) => {
    switch (selectedTime) {
      case SLOT_TIME.ALL_DAY:
        return addAvailabilityDateTime({
          id: additionalSlotId
            ? `${currentTimeSlotIndex + additionalSlotId}`
            : currentTimeSlotIndex.toString(),
          from: `09:00`,
          until: `18:00`,
        });
      case SLOT_TIME.MORNING:
        return addAvailabilityDateTime({
          id: additionalSlotId
            ? `${currentTimeSlotIndex + additionalSlotId}`
            : currentTimeSlotIndex.toString(),
          from: `09:00`,
          until: `12:00`,
        });
      case SLOT_TIME.AFTERNOON:
        return addAvailabilityDateTime({
          id: additionalSlotId
            ? `${currentTimeSlotIndex + additionalSlotId}`
            : currentTimeSlotIndex.toString(),
          from: `13:00`,
          until: `18:00`,
        });
      case SLOT_TIME.CUSTOM:
        return setOpenCustomTimer(true);
      default:
        return 'All day';
    }
  };

  const addTimeSlot = () => {
    setAvailabilityData((current: IAvailabilitySlot[]) =>
      current.map((obj: { id: string; additionalTimeSlots: [] }) => {
        if (obj.id === currentTimeSlot?.id) {
          return {
            ...obj,
            additionalTimeSlots: [
              ...obj.additionalTimeSlots,
              { id: nextId++ - 1 },
            ],
          };
        }
        return obj;
      })
    );
  };

  const removeCustomTime = useCallback(
    (index?: number | string) => {
      setCustomSlotLabel((current) =>
        current.filter((customSlot: any) => {
          return customSlot.id !== `${currentTimeSlotIndex}${index}`;
        })
      );
    },
    [customSlotLabel, currentTimeSlot?.id]
  );

  const removeTimeSlot = useCallback(
    (slotId: string, index?: number) => {
      currentTimeSlot?.additionalTimeSlots.pop();
      setAvailabilityData([...availabilityData, currentTimeSlot]);
      removeCustomTime(index);
      setSelectedAvailability((current: IAvailabilityTime[]) =>
        current.filter((availabilityTime) => {
          return availabilityTime.id !== slotId;
        })
      );
    },
    [availabilityData]
  );

  const renderCustomTime = (i: number) => {
    return (customSlotLabel as { label: string }[]).find(
      (value: any) => value.id === `${currentTimeSlotIndex}${i}`
    )?.label;
  };
  const TimeSlotComponent = (slot: { id: number }, i: number) => (
    <SelectContainer key={i}>
      {i >= 0 && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <IonIcon
              icon={trashOutline}
              className="action-icon"
              style={{ color: '#666666' }}
              onClick={() => removeTimeSlot(`${currentTimeSlotIndex}${i}`, i)}
            />
          </motion.div>
        </AnimatePresence>
      )}
      <IonSelect
        aria-label="Time"
        placeholder="Select Time"
        interface="popover"
        labelPlacement="floating"
        onIonChange={({ detail }) => {
          setSelectedCustomTimerId(`${currentTimeSlotIndex}${i}`);
          handleSlotTimes(
            detail.value as string,
            `${currentTimeSlotIndex + i}`
          );
        }}
      >
        <IonSelectOption
          className="time-slot-select-option"
          value={SLOT_TIME.ALL_DAY}
        >
          All Day (9AM - 6PM)
        </IonSelectOption>
        <IonSelectOption
          className="time-slot-select-option"
          value={SLOT_TIME.MORNING}
        >
          9:00AM - 12:00PM
        </IonSelectOption>
        <IonSelectOption
          className="time-slot-select-option"
          value={SLOT_TIME.AFTERNOON}
        >
          1:00PM - 6:00PM
        </IonSelectOption>
        <IonSelectOption
          className="time-slot-select-option"
          value={SLOT_TIME.CUSTOM}
        >
          {!renderCustomTime(i) && 'Custom Time'}
          {renderCustomTime(i) && renderCustomTime(i)}
        </IonSelectOption>
      </IonSelect>
    </SelectContainer>
  );
  return (
    <>
      <Container>
        <StyledIonToggle
          onIonChange={updateIsDaySelected}
          checked={data.isDaySelected}
          labelPlacement="end"
        >
          {data?.weekDay}
        </StyledIonToggle>
        <SlotsWrapper>
          <SelectContainer>
            <IonSelect
              aria-label="Time"
              placeholder="Select Time"
              interface="popover"
              labelPlacement="floating"
              onIonChange={({ detail }) => {
                handleSlotTimes(detail.value as string);
                if (!currentTimeSlot?.isDaySelected) updateTimePeriod();
              }}
            >
              <IonSelectOption
                className="time-slot-select-option"
                value={SLOT_TIME.ALL_DAY}
              >
                All Day (9AM - 6PM)
              </IonSelectOption>
              <IonSelectOption
                className="time-slot-select-option"
                value={SLOT_TIME.MORNING}
              >
                9:00AM - 12:00PM
              </IonSelectOption>
              <IonSelectOption
                className="time-slot-select-option"
                value={SLOT_TIME.AFTERNOON}
              >
                1:00PM - 6:00PM
              </IonSelectOption>
              <IonSelectOption
                className="time-slot-select-option"
                value={SLOT_TIME.CUSTOM}
              >
                {selectedCustomTimer.length === 0 && 'Custom Time'}
                {selectedCustomTimer.length > 0 && selectedCustomTimer}
              </IonSelectOption>
            </IonSelect>
          </SelectContainer>
          {data?.additionalTimeSlots.map((slot, i) => (
            <React.Fragment key={i}>
              {TimeSlotComponent(slot, i)}
            </React.Fragment>
          ))}
        </SlotsWrapper>
        {data?.additionalTimeSlots.length < 2 && (
          <ActionContainer>
            <IonIcon
              icon={addCircleOutline}
              onClick={addTimeSlot}
              color="primary"
              className="action-icon"
            />
          </ActionContainer>
        )}
      </Container>
      <AvailabilityCustomTime
        openCustomTimer={openCustomTimer}
        setOpenCustomTimer={setOpenCustomTimer}
        handleCustomTime={handleCustomTime}
        slotId={
          selectedCustomTimerId
            ? selectedCustomTimerId.toString()
            : currentTimeSlotIndex.toString()
        }
      />
    </>
  );
};

export default AvailabilitySlot;
