import { IonContent, IonModal } from '@ionic/react';
import { FC, useRef, useState } from 'react';
import { RateProfessionalSkeleton } from '../RateProfessionalModalSheet/RateProfessionalSkeleton';

import { ServiceInformation } from './ServiceInformation';
import { ProcessPayment } from './ProcessPayment';
import { PaymentComplete } from './PaymentComplete';
import {
  GetServiceRequestQuery,
  GetServiceRequestReviewQuery,
  useGetServiceRequestQuery,
  useGetServiceRequestReviewQuery,
} from '../../graphql/generated';
import { RateProfessionalModalSheet } from '../RateProfessionalModalSheet/RateProfessionalModalSheet';

export enum Steps {
  ServiceInformationStep = 'SERVICE_INFORMATION',
  ProcessPaymentStep = 'PROCESS_PAYMENT',
  PaymentCompleteStep = 'PAYMENT_COMPLETE',
  RateProfessionalStep = 'RATE_PROFESSIONAL',
}

const SERVICE_SUMMARY_STEPS = (
  setNewStep: (Steps: Steps) => void,
  serviceRequestId: number,
  onClose: () => void,
  serviceSummaryData?: GetServiceRequestQuery,
  serviceReqReviewData?: GetServiceRequestReviewQuery
) => ({
  [Steps.ServiceInformationStep]: (
    <ServiceInformation
      setNewStep={setNewStep}
      serviceSummaryData={serviceSummaryData}
    />
  ),
  [Steps.ProcessPaymentStep]: (
    <ProcessPayment
      setNewStep={setNewStep}
      serviceSummaryData={serviceSummaryData}
      serviceReqReviewData={serviceReqReviewData}
      onClose={onClose}
    />
  ),
  [Steps.PaymentCompleteStep]: (
    <PaymentComplete
      setNewStep={setNewStep}
      serviceSummaryData={serviceSummaryData}
      serviceReqReviewData={serviceReqReviewData}
      onClose={onClose}
    />
  ),
  [Steps.RateProfessionalStep]: (
    <RateProfessionalModalSheet
      serviceRequestId={serviceRequestId}
      isOpen
      onClose={onClose}
    />
  ),
});

type ServiceSummaryProps = {
  serviceRequestId?: number;
  onClose?: () => void;
};

export const ServiceSummary: FC<ServiceSummaryProps> = ({
  serviceRequestId,
  onClose = () => {},
}) => {
  const [currentStep, setCurrentStep] = useState(Steps.ServiceInformationStep);
  const modal = useRef<HTMLIonModalElement>(null);

  const { data, loading: loadingServiceSummary } = useGetServiceRequestQuery({
    variables: { getServiceRequestId: Number(serviceRequestId) },
  });

  const { data: serviceReqReviewData, loading: loadingServiceReqReview } =
    useGetServiceRequestReviewQuery({
      variables: { serviceRequestId: Number(serviceRequestId) },
    });

  const setNewStep = (Step: Steps) => {
    if (Step !== Steps.ServiceInformationStep) {
      void modal.current?.setCurrentBreakpoint(1);
    }
    setCurrentStep(Step);
  };

  const loading = loadingServiceSummary || loadingServiceReqReview;

  return (
    <IonModal
      ref={modal}
      onDidDismiss={onClose}
      isOpen
      initialBreakpoint={0.55}
      breakpoints={[0, 0.55, 1]}
      onIonBreakpointDidChange={(event) => {
        if (event.detail.breakpoint === 0) {
          onClose();
        }
      }}
    >
      {loading ? (
        <RateProfessionalSkeleton />
      ) : (
        <IonContent className="ion-padding">
          {
            SERVICE_SUMMARY_STEPS(
              setNewStep,
              Number(serviceRequestId),
              onClose,
              data,
              serviceReqReviewData
            )[currentStep]
          }
        </IonContent>
      )}
    </IonModal>
  );
};
