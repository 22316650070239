import * as React from 'react';

function IconActivityCompleted(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={77} height={100} viewBox="0 0 77 100" fill="none" {...props}>
      <rect
        x={0.5}
        y={12}
        width={76}
        height={76}
        rx={38}
        fill={props.color || '#3F51B5'}
        fillOpacity={0.25}
      />
      <rect
        x={8.5}
        y={20}
        width={60}
        height={60}
        rx={30}
        fill={props.color || '#3F51B5'}
        fillOpacity={0.5}
      />
      <rect
        x={16.5}
        y={28}
        width={44}
        height={44}
        rx={22}
        fill={props.color || '#3F51B5'}
      />
      <path
        d="M49.75 41L34 59l-6.75-6.75"
        stroke="#fff"
        strokeWidth={3}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconActivityCompleted;
