import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { Trans } from 'react-i18next';
import { useAuthContext } from '../../providers/authProvider';
import { useHistory } from 'react-router';
import { MainTitle } from '../../components/Typography/Headings/Headings';
import { BodyText } from '../../components/Typography/Body/Body';

const BryaUserPage: React.FC = () => {
  const { user, logout } = useAuthContext();
  const history = useHistory();
  return (
    <IonPage>
      <HeaderWithAction
        actionText={<Trans i18nKey="bryaUser.backCta" />}
        onAction={async () => {
          try {
            await logout();
          } finally {
            history.replace('/login');
          }
        }}
      />
      <IonContent className="ion-padding">
        <div className="ion-margin-top ion-margin-bottom">
          <MainTitle>
            <Trans i18nKey="bryaUser.title" value={{ role: user?.role }} />
          </MainTitle>
        </div>
        <BodyText>
          <Trans i18nKey="bryaUser.body" />
        </BodyText>
      </IonContent>
    </IonPage>
  );
};

export default BryaUserPage;
