import { IonContent, IonPage, useIonViewDidLeave } from '@ionic/react';
import React from 'react';
import HeaderWithTitle from '../../../components/HeaderWithTitle/HeaderWithTitle';
import AccountLink from '../../../components/AccountLink/AccountLink';
import { NotificationOutline } from '../../../assets/images/NotificationOutline';
import { ShieldOutline } from '../../../assets/images/ShieldOutline';
import { ListOutline } from '../../../assets/images/ListOutline';
import { LinksContainer } from './PreferencesPage.styles';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import useInAppBrowser from '../../../utils/hooks/useInAppBrowser';
import { BryaLinks } from '../../../constants/enums';
import { useTranslation } from 'react-i18next';

const PreferencesPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openEvent, closeBrowser } = useInAppBrowser();

  useIonViewDidLeave(() => {
    closeBrowser();
  });

  return (
    <IonPage>
      <HeaderWithTitle title={t('accountPage.preferences')} />
      <IonContent>
        <LinksContainer>
          <AccountLink
            label={t('preferences.notifications')}
            icon={<NotificationOutline />}
            action={() => history.push(ROUTES.NOTIFICATION_SETTINGS)}
          />
          <AccountLink
            label={t('preferences.privacyPolicy')}
            icon={<ShieldOutline />}
            action={() => openEvent(BryaLinks.PrivacyPolicy)}
          />
          <AccountLink
            label={t('preferences.terms&Conditions')}
            icon={<ListOutline />}
            action={() => openEvent(BryaLinks.TermsConditions)}
          />
        </LinksContainer>
      </IonContent>
    </IonPage>
  );
};

export default PreferencesPage;
