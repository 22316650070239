import * as yup from 'yup';

type ValidatorParams = {
  errorMessage: string;
  otherFieldsNames: string[];
};

type YupValidator = [
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  yup.Message<any>,
  yup.TestFunction<string | undefined>
];

export const getAtLeastOneIsRequiredYupValidator = ({
  errorMessage,
  otherFieldsNames,
}: ValidatorParams): YupValidator => {
  return [
    'one-of-these-fields-is-required',
    errorMessage,
    function isValid(currentFieldValue: string | undefined) {
      return (
        !!currentFieldValue ||
        otherFieldsNames.some((fieldName) => !!this.parent[fieldName])
      );
    },
  ];
};
