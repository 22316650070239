import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const TimelineTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimelineEventMonth = styled.div`
  font-size: ${() => pixelToRem(12)};
  font-weight: 500;
  line-height: 13px;
  color: var(--colors-primary-blue);
  text-transform: uppercase;
`;

export const TimelineEventDayNumber = styled.div`
  font-size: ${() => pixelToRem(20)};
  font-weight: 500;
  line-height: 28px;
  color: var(--colors-primary-blue);
`;

export const TimelineEventDayName = styled.div`
  font-size: ${() => pixelToRem(12)};
  font-weight: 600;
  line-height: 15px;
  color: var(--colors-primary-blue);
  text-transform: uppercase;
`;

export const TimelineEventLine = styled.div`
  border-left: 1px solid #d9d9d9;
  height: 17px;
`;

export const CardItem = styled.div`
  background: radial-gradient(
    194.31% 83.79% at 11.62% 50%,
    #fff 0%,
    #f6f6f6 57.29%,
    #fff 100%
  );
  border: 1px solid #bdbdbd;
  border-radius: 10px;
`;

export const CardItemTitle = styled.div`
  font-size: ${() => pixelToRem(16)};
  font-weight: 600;
  line-height: 22px;
  color: var(--colors-primary-blue);
  margin-bottom: 4px;
`;
