import { IonGrid, IonRow } from '@ionic/react';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import {
  ApprovalServiceRequestStatus,
  ContactRequestStatus,
  GetMobileSocialEventQuery,
  useCreateContactRequestMutation,
  useUserApprovalSocialEventMutation,
} from '../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { BodyText } from '../../components/Typography/Body/Body';
import IconActivityCompleted from '../../assets/images/IconActivityCompleted';
import { locationOutline } from 'ionicons/icons';
import { Button } from '../Button/Button';
import { CardList } from '../CardList/CardList';
import { ActivityCardItemAddress } from '../ActivityCardItemAddress/ActivityCardItemAddress';
import { useHistory } from 'react-router';
import { ActivityCardItemRequestScheduledAt } from '../ActivityCardItemRequestScheduledAt/ActivityCardItemRequestScheduledAt';
import { useDefaultMutationErrorHandler } from '../../utils/hooks/useDefaultMutationErrorHandler';
import { ActivityCardItemOrganizedBy } from '../ActivityCardItemOrganizedBy/ActivityCardItemOrganizedBy';
import { RecurringEventPageTitle } from '../RecurringEventPageTitle/RecurringEventPageTitle';
import { useAppContext } from '../../providers/appContextProvider';
import { useAuthContext } from '../../providers/authProvider';

type SocialEventQueryResult = GetMobileSocialEventQuery['getMobileSocialEvent'];

type ConfirmationNeededCustomerActivitySocialEventProps = {
  customerId: number;
  socialEvent: SocialEventQueryResult;
  onSocialEventDeclined: () => unknown;
  onSocialEventAccepted: () => unknown;
  onContactRequestCreated: () => unknown;
};

export const ConfirmationNeededCustomerActivitySocialEvent: React.FC<
  ConfirmationNeededCustomerActivitySocialEventProps
> = ({
  customerId,
  socialEvent,
  onSocialEventDeclined,
  onSocialEventAccepted,
  onContactRequestCreated,
}) => {
  const { t } = useTranslation();
  const { globalAlert } = useAppContext();
  const { user } = useAuthContext();
  const isRecurringEvent = Boolean(socialEvent.recurring);
  const history = useHistory();
  const approvalUpdateErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmationNeededCustomerActivitySocialEvent][ApprovalUpdate]',
  });
  const createContactRequestErrorHandler = useDefaultMutationErrorHandler({
    tag: '[ConfirmationNeededCustomerActivitySocialEvent][ContactRequestCreation]',
  });
  const [createContactRequest, { loading: isCreatingContactRequest }] =
    useCreateContactRequestMutation({
      onError: createContactRequestErrorHandler,
      onCompleted: onContactRequestCreated,
    });
  const [approvalUpdate, { loading: isUpdating }] =
    useUserApprovalSocialEventMutation({
      onError: approvalUpdateErrorHandler,
    });

  const loading = isUpdating || isCreatingContactRequest;
  const address = {
    addressLine1: socialEvent.addressLine1,
    addressLine2: socialEvent.addressLine2,
    zipCode: socialEvent.zipCode,
    city: socialEvent.city,
    state: socialEvent.state,
    country: socialEvent.country,
    place: socialEvent.place,
  };
  const hasRequestedContactBefore = Boolean(
    socialEvent.ContactRequests.find(
      (contactRequest) => contactRequest.userId === user?.id
    )
  );

  const handleDecline = () => {
    void approvalUpdate({
      variables: {
        action: ApprovalServiceRequestStatus.Decline,
        socialEventId: socialEvent.id,
        customerId,
      },
      onCompleted: onSocialEventDeclined,
    });
  };

  const handleContactMe = () => {
    globalAlert({
      title: t(
        'ConfirmationNeededCustomerActivitySocialEvent.ContactMeRequestAlertTitle'
      ),
      subtitle: t(
        'ConfirmationNeededCustomerActivitySocialEvent.ContactMeRequestAlertSubtitle'
      ),
      firstButtonLabel: t(
        'ConfirmationNeededCustomerActivitySocialEvent.ContactMeRequestAlertFirstButtonLabel'
      ),
      firstButtonAction: async () => {
        return createContactRequest({
          variables: {
            createContactRequestInput: {
              status: ContactRequestStatus.Pending,
              customerActivityId: socialEvent.customerActivityId,
              // TODO: hardcoded for now
              userMessage: 'Tell me more about this event',
            },
          },
        });
      },
      secondButtonLabel: t(
        'ConfirmationNeededCustomerActivitySocialEvent.ContactMeRequestAlertSecondButtonLabel'
      ),
    });
  };

  const handleAccept = () => {
    void approvalUpdate({
      variables: {
        action: ApprovalServiceRequestStatus.Accept,
        socialEventId: socialEvent.id,
        customerId,
      },
      onCompleted: onSocialEventAccepted,
    });
  };

  return (
    <PageWithBottomSheetLayout
      title={
        isRecurringEvent ? (
          <RecurringEventPageTitle title={socialEvent.eventName} />
        ) : (
          socialEvent.eventName
        )
      }
      background="var(--colors-gradient-orange)"
      bottomSheetBackground="var(--colors-gradient-orange-sheet)"
      bottomSheetTitle={
        <>
          <IconActivityCompleted color="var(--ion-color-primary-orange)" />
          {t('ConfirmationNeededCustomerActivitySocialEvent.SheetTitle')}
        </>
      }
      bottomSheetSubtitle={
        isRecurringEvent
          ? t('ConfirmationNeededCustomerActivitySocialEvent.SheetSubtitle')
          : undefined
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => history.goBack()} />
      }
    >
      <IonGrid>
        <IonRow className="ion-margin-vertical">
          <BodyText>{socialEvent.description}</BodyText>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <CardList>
            <ActivityCardItemAddress icon={locationOutline} address={address} />
            <ActivityCardItemOrganizedBy organizer={socialEvent.RequestedBy} />
            <ActivityCardItemRequestScheduledAt
              scheduledAt={socialEvent.scheduledAt}
            />
          </CardList>
        </IonRow>
        <IonRow className="ion-margin-vertical">
          <Button
            color="primary-orange"
            onClick={handleAccept}
            loading={loading}
          >
            {t('ConfirmationNeededCustomerActivitySocialEvent.PrimaryCta')}
          </Button>
        </IonRow>
        {hasRequestedContactBefore ? null : (
          <IonRow className="ion-margin-vertical">
            <Button
              color="secondary-orange"
              onClick={handleContactMe}
              loading={loading}
            >
              {t('ConfirmationNeededCustomerActivitySocialEvent.SecondaryCta')}
            </Button>
          </IonRow>
        )}
        <IonRow className="ion-margin-vertical">
          <Button
            color="secondary-orange"
            onClick={handleDecline}
            loading={loading}
          >
            {t('ConfirmationNeededCustomerActivitySocialEvent.TertiaryCta')}
          </Button>
        </IonRow>
      </IonGrid>
    </PageWithBottomSheetLayout>
  );
};
