import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonButton, IonSpinner } from '@ionic/react';
import React, { CSSProperties } from 'react';

interface ButtonProps {
  loading?: boolean;
  background?: CSSProperties['background'];
  color: Omit<React.ComponentProps<typeof IonButton>['color'], 'undefined'>;
  borderColor?: CSSProperties['borderColor'];
  size?: string;
}

const StyledButton = styled(IonButton)<ButtonProps>`
  width: 100%;
  --padding-top: 16px;
  --padding-end: 20px;
  --padding-bottom: 16px;
  --padding-start: 20px;
  --border-radius: 50px;
  --border-style: solid;
  --border-width: 1px;
  font-size: ${() => pixelToRem(16)};
  font-weight: 600;
  line-height: 22px;

  ::part(native) {
    min-height: ${(props) => (props.size === 'small' ? 40 : 56)}px;
  }
`;

type IonButtonProps = React.ComponentProps<typeof IonButton>;
export const Button: React.FC<ButtonProps & IonButtonProps> = ({
  children,
  disabled,
  loading,
  size,
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      disabled={disabled || loading}
      size={size}
      className="ion-no-margin"
    >
      {loading ? (
        <IonSpinner
          color={
            // TODO: a better way to achieve this? secondary colors
            // shows spinner in white because it uses --ion-color-base
            typeof rest.color === 'string' && rest.color.includes('secondary')
              ? rest.color.replace('secondary', 'primary')
              : undefined
          }
          name="dots"
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};
