import React, { ReactNode, createContext, useContext, useMemo } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { environments } from '../utils/env';
import { AppContext } from './appContextProvider';

const StripeContext = createContext<Promise<Stripe | null>>(
  Promise.resolve(null)
);

type StripeProviderType = {
  children: ReactNode;
};

export const useStripe = () => {
  return useContext(StripeContext);
};

export const StripeProvider: React.FC<StripeProviderType> = ({ children }) => {
  const { env } = useContext(AppContext);

  const stripePromise = useMemo(
    () => loadStripe(environments[env].stripePublishableKey),
    [env]
  );

  return (
    <StripeContext.Provider value={stripePromise}>
      {children}
    </StripeContext.Provider>
  );
};
