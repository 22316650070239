import { IonGrid, IonIcon, IonRow } from '@ionic/react';
import { repeatOutline } from 'ionicons/icons';
import React, { ReactNode } from 'react';

type RecurringEventPageTitleProps = {
  title: ReactNode;
};

export const RecurringEventPageTitle: React.FC<
  RecurringEventPageTitleProps
> = ({ title }) => {
  return (
    <IonGrid style={{ width: 'fit-content' }}>
      <IonRow>
        <span>
          {title}
          <IonIcon
            size="auto"
            className="ion-align-self-center"
            icon={repeatOutline}
            style={{ width: 20, height: 20, marginInlineStart: '10px' }}
          />
        </span>
      </IonRow>
    </IonGrid>
  );
};
