import { IonButton, IonCard, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Toolbar = styled(IonToolbar)`
  --background: auto;
  --border-width: 0;
  --border-color: unset;
  --border-style: none;
  --min-height: 60px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
`;

export const BackButton = styled(IonButton)`
  font-size: ${() => pixelToRem(16)};
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4000000059604645px;
  color: var(--colors-primary-orange);
  background: none;

  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;

  ::part(native) {
    padding: 0;
  }
`;

export const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const HeaderTitle = styled.h1`
  font-size: ${() => pixelToRem(24)};
  font-weight: 700;
  line-height: 29px;
  color: var(--body-text-900);
`;

export const RateTitle = styled.h2`
  font-size: ${() => pixelToRem(20)};
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.012em;
  text-align: center;
  color: var(--body-text-900);
`;

export const RateInstructions = styled.span`
  font-size: ${() => pixelToRem(18)};
  font-weight: 500;
  line-height: 25px;
  color: var(--body-text-900);
`;

export const RateValue = styled.span`
  font-size: ${() => pixelToRem(16)};
  font-weight: 700;
  line-height: 22px;
  color: var(--body-text-900);
`;

export const RateQuestion = styled.span`
  font-size: ${() => pixelToRem(18)};
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  width: 100%;

  b {
    color: var(--colors-primary-orange);
    font-weight: 500;
  }
`;

export const Card = styled(IonCard)`
  margin: 0;
  padding: 18px 16px;
  font-size: ${() => pixelToRem(16)};
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.012em;
  color: var(--colors-primary-blue);
`;
