import { IonPage } from '@ionic/react';
import { useParams } from 'react-router';
import {
  ServiceRequestStatus,
  useGetCircleRequestQuery,
} from '../../graphql/generated';
import { ActivityDetailSkeletonPage } from '../../components/ActivityDetailSkeletonPage/ActivityDetailSkeletonPage';
import { logger } from '../../logger';
import { PendingCustomerActivityCircleRequest } from '../../components/PendingCustomerActivityCircleRequest/PendingCustomerActivityCircleRequest';
import { CancelledCustomerActivityCircleRequest } from '../../components/CancelledCustomerActivityCircleRequest/CancelledCustomerActivityCircleRequest';
import { CompletedCustomerActivityCircleRequest } from '../../components/CompletedCustomerActivityCircleRequest/CompletedCustomerActivityCircleRequest';
import { ConfirmedCustomerActivityCircleRequest } from '../../components/ConfirmedCustomerActivityCircleRequest/ConfirmedCustomerActivityCircleRequest';

type PageParams = {
  activityId: string;
};
export const CustomerActivityCircleRequestPage: React.FC = () => {
  const { activityId } = useParams<PageParams>();
  // TODO: handle error with generic error page?
  const { data, loading, refetch } = useGetCircleRequestQuery({
    variables: {
      getCircleRequestId: Number(activityId),
    },
  });

  const circleRequest = data?.getCircleRequest;

  const getComponent = () => {
    if (!circleRequest) return null;
    switch (circleRequest.status) {
      // NOTE: Circle Request shouldn't have a different enum?
      case ServiceRequestStatus.Pending:
        return (
          <PendingCustomerActivityCircleRequest
            circleRequest={circleRequest}
            onCircleRequestCancelled={refetch}
          />
        );
      case ServiceRequestStatus.Confirmed:
        return (
          <ConfirmedCustomerActivityCircleRequest
            circleRequest={circleRequest}
            onCircleRequestCancelled={refetch}
            onCircleRequestUpdated={refetch}
          />
        );
      case ServiceRequestStatus.Cancelled:
        return (
          <CancelledCustomerActivityCircleRequest
            circleRequest={circleRequest}
          />
        );
      case ServiceRequestStatus.Completed:
        return (
          <CompletedCustomerActivityCircleRequest
            circleRequest={circleRequest}
          />
        );
      default:
        logger.error({
          tag: '[CustomerActivityCircleRequestPage][UnhandledCircleRequestStatus]',
          message: `Unhandled circle request status: ${circleRequest.status}, for circle request with ID: ${circleRequest.id}`,
        });
        // TODO: show error page
        return null;
    }
  };

  return (
    <IonPage>
      {loading || !circleRequest ? (
        <ActivityDetailSkeletonPage />
      ) : (
        getComponent()
      )}
    </IonPage>
  );
};
