import { IonButton, IonModal } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--body-text-900);
  border-radius: 14px;
  padding: 2px 16px;
  margin-bottom: 20px;
  ion-input {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    --color: var(--body-text-900);
    --placeholder-color: var(--body-text-900);
    --placeholder-font-weight: 400;
    --padding-start: 4px;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(24)};
  line-height: 120%;
  color: var(--ion-color-warning-contrast);
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(18)};
  line-height: 120%;
  color: var(--ion-color-warning-contrast);
`;

export const Description = styled.p`
  width: 280px;
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: var(--body-text-70);
`;

export const BottomButton = styled(IonButton)`
  height: 58px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
  --background-activated: #e59b62;
  --border-color: var(--ion-color-primary);
  --border-radius: 50px;
`;

export const DontAllowButton = styled(IonButton)`
  position: absolute;
  bottom: 44px;
  height: 56px;
  width: 327px;
  font-weight: 600;
  text-transform: none;
  --border-color: var(--ion-color-primary);
  --background: var(--ion-color-primary-contrast);
  --border-radius: 50px;
  --background-activated: #f5f0f0;
`;

export const StyledIonModal = styled(IonModal)`
  ion-title {
    width: inherit;
  }
  .brya-spinner {
    width: 240px;
    height: 240px;
  }
`;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 8px;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: var(--ion-color-primary);
`;

export const ModalHeader = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const RowContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin: 0;
`;

export const SummaryLeft = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: ${pixelToRem(15)};
  color: var(--ion-color-warning-contrast);
`;

export const SummaryRight = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(15)};
  color: var(--ion-color-warning-contrast);
`;

export const StripeLoadingContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 100px;
`;
