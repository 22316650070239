import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

// TODO: revisit this once our page layout component is implemented.
// This layout component will include the big header with animation,
// the content with top right and top left borders, among other things.
export const IonContentStyled = styled(IonContent)`
  --background: linear-gradient(
    267.19deg,
    #084282 -0.42%,
    #00356f 41.24%,
    #00244a 100.84%
  );
  .header-collapse-condense ion-toolbar {
    --color: white;
    --background: linear-gradient(
      267.19deg,
      #084282 -0.42%,
      #00356f 41.24%,
      #00244a 100.84%
    );
  }
`;

export const FormContainer = styled.div`
  background: white;
  border-top-left-radius: ${pixelToRem(24)};
  border-top-right-radius: ${pixelToRem(24)};
  padding-top: 32px;
  min-height: 100vh;
`;

export const FormLabel = styled.h2`
  font-weight: 700;
  font-size: ${pixelToRem(20)};
  line-height: 120%;
  color: #212121;
  margin-bottom: 16px;
  &.first-item {
    margin-bottom: 32px;
  }
`;
