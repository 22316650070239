import * as React from 'react';

export const Circle = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <g clipPath="url(#clip0_1851_22547)">
        <mask
          id="mask0_1851_22547"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="39"
          height="39"
        >
          <path d="M39 0H0V39H39V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1851_22547)">
          <path
            d="M28.2909 27.2567C26.5609 29.1388 24.3409 30.5016 21.8797 31.1925C21.4647 30.6021 20.9137 30.1204 20.2733 29.7877C19.6329 29.4551 18.9219 29.2815 18.2003 29.2815C17.4786 29.2815 16.7676 29.4551 16.1272 29.7877C15.4868 30.1204 14.9358 30.6021 14.5208 31.1925C12.0597 30.5024 9.83976 29.1399 8.11049 27.2576C7.92566 27.0559 7.70288 26.8925 7.4549 26.7767C7.20691 26.6611 6.93858 26.5953 6.66519 26.5833C6.39181 26.5714 6.11876 26.6135 5.8616 26.7069C5.60446 26.8005 5.36824 26.9437 5.16646 27.1287C4.96468 27.3134 4.80128 27.5363 4.6856 27.7843C4.56992 28.0323 4.50421 28.3005 4.49223 28.5739C4.48026 28.8474 4.52223 29.1204 4.61579 29.3775C4.70934 29.6346 4.85262 29.8709 5.03746 30.0726C7.42832 32.6745 10.5348 34.5116 13.9666 35.3534C14.2797 36.2252 14.8543 36.9791 15.612 37.5121C16.3697 38.045 17.2734 38.3311 18.1999 38.3311C19.1262 38.3311 20.0299 38.045 20.7876 37.5121C21.5452 36.9791 22.1199 36.2252 22.433 35.3534C25.8655 34.512 28.9726 32.6749 31.364 30.0726C31.5521 29.8716 31.6986 29.635 31.7949 29.3769C31.891 29.1187 31.9351 28.8441 31.9244 28.5688C31.9137 28.2936 31.8485 28.0231 31.7326 27.7732C31.6167 27.5234 31.4523 27.2989 31.2491 27.1129C31.0458 26.9271 30.8077 26.7832 30.5485 26.69C30.2893 26.5966 30.0142 26.5558 29.739 26.5695C29.4639 26.5833 29.1942 26.6515 28.9457 26.7703C28.697 26.889 28.4746 27.0558 28.2909 27.2611V27.2567Z"
            fill="white"
          />
          <path
            d="M35.1864 12.6178C35.6331 11.9365 35.8868 11.1471 35.921 10.3332C35.9551 9.51929 35.7682 8.71133 35.3802 7.99506C34.9922 7.27879 34.4176 6.68092 33.7172 6.2649C33.0168 5.84886 32.2169 5.63022 31.4024 5.63213C31.279 5.63213 31.163 5.65873 31.0414 5.66848C28.6015 3.11383 25.4549 1.34525 22.0042 0.589023C21.7356 0.527172 21.4574 0.519231 21.1858 0.565662C20.9142 0.612093 20.6544 0.711972 20.4217 0.859511C20.1889 1.00705 19.9877 1.19932 19.8298 1.42516C19.672 1.65101 19.5603 1.90596 19.5017 2.17522C19.4431 2.44448 19.4384 2.72272 19.4881 2.99378C19.5377 3.26486 19.6406 3.52337 19.791 3.75436C19.9412 3.98536 20.1359 4.18422 20.3636 4.33944C20.5913 4.49466 20.8476 4.60315 21.1175 4.65862C23.4903 5.17966 25.6798 6.32786 27.4577 7.98345C27.0825 8.64596 26.8829 9.39341 26.8778 10.1548C26.8797 11.3535 27.3567 12.5025 28.2044 13.3501C29.0519 14.1977 30.201 14.6747 31.3997 14.6766C31.42 14.6766 31.4387 14.6704 31.4582 14.6704C31.7419 15.7674 31.8864 16.8958 31.8881 18.0288C31.8885 19.4432 31.6692 20.8489 31.2383 22.196C31.1541 22.4569 31.1221 22.7318 31.1444 23.0051C31.1667 23.2784 31.2427 23.5445 31.3681 23.7883C31.4934 24.0321 31.6657 24.2488 31.8749 24.4258C32.0842 24.6029 32.3264 24.737 32.5876 24.8202C32.8489 24.9034 33.1239 24.9344 33.397 24.9111C33.6701 24.8878 33.936 24.8108 34.1794 24.6845C34.4227 24.5584 34.6387 24.3853 34.8151 24.1754C34.9914 23.9654 35.1244 23.7227 35.2067 23.4612C35.7669 21.7061 36.0518 19.8747 36.0517 18.0324C36.0478 16.1934 35.7558 14.3664 35.1864 12.6178Z"
            fill="white"
          />
          <path
            d="M5.15748 22.1933C4.72629 20.8464 4.50675 19.4406 4.50671 18.0263C4.50796 16.8806 4.65513 15.7398 4.94469 14.6314C5.66218 14.5701 6.35434 14.3369 6.96251 13.9512C7.57068 13.5656 8.07695 13.0392 8.43842 12.4164C8.79989 11.7936 9.00591 11.0928 9.03902 10.3735C9.07211 9.65411 8.93134 8.93739 8.6286 8.28402C10.4566 6.46953 12.7628 5.21218 15.2783 4.65862C15.5482 4.60315 15.8044 4.49466 16.0322 4.33944C16.2599 4.18422 16.4545 3.98536 16.6049 3.75436C16.7551 3.52337 16.8581 3.26486 16.9077 2.99378C16.9573 2.72272 16.9528 2.44448 16.894 2.17522C16.8354 1.90596 16.7239 1.65101 16.566 1.42516C16.408 1.19932 16.2069 1.00705 15.9741 0.859511C15.7413 0.711972 15.4817 0.612093 15.21 0.565662C14.9383 0.519231 14.6602 0.527172 14.3917 0.589023C10.921 1.35013 7.75846 3.13464 5.31263 5.71193C5.05191 5.66189 4.78724 5.6352 4.52177 5.63213C3.32296 5.63354 2.17364 6.11039 1.32595 6.95808C0.47826 7.80577 0.00140789 8.95509 0 10.1539C0.00427531 11.2162 0.385738 12.2424 1.07636 13.0496C0.594216 14.6662 0.347272 16.3437 0.343122 18.0307C0.343238 19.8728 0.62887 21.7038 1.18985 23.4586C1.32408 23.8795 1.58864 24.2469 1.94531 24.5075C2.30198 24.7683 2.7323 24.909 3.1741 24.9091C3.50165 24.9086 3.82446 24.831 4.11637 24.6824C4.40828 24.5337 4.66106 24.3184 4.85424 24.054C5.04742 23.7895 5.17554 23.4832 5.22823 23.1599C5.28091 22.8366 5.25668 22.5055 5.15748 22.1933Z"
            fill="white"
          />
          <path
            d="M5.50362 15.5199C8.52944 15.5199 10.9823 13.067 10.9823 10.0412C10.9823 7.01541 8.52944 4.5625 5.50362 4.5625C2.47781 4.5625 0.0249023 7.01541 0.0249023 10.0412C0.0249023 13.067 2.47781 15.5199 5.50362 15.5199Z"
            fill="white"
          />
          <path
            d="M32.1165 15.5199C35.1424 15.5199 37.5953 13.067 37.5953 10.0412C37.5953 7.01541 35.1424 4.5625 32.1165 4.5625C29.0907 4.5625 26.6378 7.01541 26.6378 10.0412C26.6378 13.067 29.0907 15.5199 32.1165 15.5199Z"
            fill="white"
          />
          <path
            d="M18.0274 39.0002C21.0532 39.0002 23.5061 36.5473 23.5061 33.5214C23.5061 30.4956 21.0532 28.0427 18.0274 28.0427C15.0016 28.0427 12.5487 30.4956 12.5487 33.5214C12.5487 36.5473 15.0016 39.0002 18.0274 39.0002Z"
            fill="white"
          />
          <path
            d="M5.50504 13.9547C7.66634 13.9547 9.41841 12.2026 9.41841 10.0413C9.41841 7.88 7.66634 6.12793 5.50504 6.12793C3.34375 6.12793 1.59167 7.88 1.59167 10.0413C1.59167 12.2026 3.34375 13.9547 5.50504 13.9547Z"
            fill="#8A49DA"
          />
          <path
            d="M32.1166 13.9547C34.2779 13.9547 36.03 12.2026 36.03 10.0413C36.03 7.88 34.2779 6.12793 32.1166 6.12793C29.9553 6.12793 28.2032 7.88 28.2032 10.0413C28.2032 12.2026 29.9553 13.9547 32.1166 13.9547Z"
            fill="#8A49DA"
          />
          <path
            d="M18.0262 37.4346C20.1875 37.4346 21.9395 35.6826 21.9395 33.5213C21.9395 31.36 20.1875 29.6079 18.0262 29.6079C15.8649 29.6079 14.1128 31.36 14.1128 33.5213C14.1128 35.6826 15.8649 37.4346 18.0262 37.4346Z"
            fill="#8A49DA"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1851_22547">
        <rect width="39" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
