/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

// language files
import translationEN from './en.json';
import translationES from './es.json';

interface Country {
  SE: {
    langs: { sv: 'sv'; fi: 'fi'; en: 'en' };
  };
  US: { langs: { us: 'us'; ca: 'ca'; es: 'es' } };
}

type Code<T> = T extends keyof Country
  ? keyof Country[T]['langs'] extends string
    ? `${keyof Country[T]['langs']}-${T}`
    : never
  : never;

export type Locale = Code<keyof Country>;

export const defaultNS = 'translations';

export const SUPPORTED_LANGUAGES = {
  en: {
    translation: translationEN,
    code: 'en-US',
  },
  es: {
    translation: translationES,
    code: 'es',
  },
} as const;

// TODO: At some point, add functionality for switching languages
export const getLocale = (): Locale => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
  );
};

void use(initReactI18next).init({
  debug: true,
  resources: SUPPORTED_LANGUAGES,
  react: {
    useSuspense: false,
  },
  fallbackLng: 'en',

  // See: https://github.com/i18next/i18next/issues/1259
  interpolation: {
    format(value, format, lng) {
      if (
        format === 'list' &&
        lng?.split('-')[0] === 'en' &&
        Array.isArray(value)
      ) {
        const [lastItem, ...rest] = value.reverse();

        // TODO: support conjunctions other than 'and'
        if (rest.length) {
          return `${rest.join(', ')} and ${lastItem}`;
        } else {
          return String(lastItem);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value;
    },
  },
});
export default i18next;
