import { call } from 'ionicons/icons';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { useTranslation } from 'react-i18next';

export const ActivityCardItemPhoneCall: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <IonRow>
        <IonCol size="auto" className="ion-align-self-center">
          <IonIcon
            icon={call}
            color="var(--colors-primary-blue)"
            style={{ width: '24px', height: '24px', marginInlineEnd: '8px' }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {t('ActivityCardItemPhoneCall.Label')}
          </BodyText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
