import { IonCard, IonCardHeader } from '@ionic/react';
import { CardContent, CardMiddleContent, CardText } from './Card.style';
import { RightArrow } from '../../assets/images/RightArrow';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

interface Props {
  item: {
    id: number;
    icon: JSX.Element;
    title?: string | null;
    requestType?: string | null;
    stateProps?: Record<string, unknown>;
    route?: string;
    linearGradient?: string;
    onClick?: () => void;
  };
}

const Card: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onCardClick = useCallback((route?: string) => {
    if (route) {
      history.push({
        pathname: route,
        state: {
          requestType: item.requestType,
          props: item.stateProps,
        },
      });
    }
  }, []);

  return (
    <IonCard
      onClick={item.onClick ? item.onClick : () => onCardClick(item.route)}
      style={{
        backgroundImage: item.linearGradient,
        margin: '8px 0',
      }}
    >
      <IonCardHeader style={{ padding: '16px' }}>
        <CardContent>
          <CardContent title={item.title as string}>
            {item.icon}
            <CardMiddleContent>
              <CardText>{t(item.title as string)}</CardText>
            </CardMiddleContent>
          </CardContent>
          <RightArrow color="#fff" />
        </CardContent>
      </IonCardHeader>
    </IonCard>
  );
};

export default Card;
