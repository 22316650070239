import {
  CircleRequestCategoriesEnum,
  GraphqlAddresses,
  GraphqlAppointmentRequestPayment,
  GraphqlCircleRequest,
  GraphqlCustomer,
  GraphqlRideAddress,
  GraphqlServiceRequest,
  GraphqlTimeline,
  GraphqlUser,
  GraphqlUserRole,
  Role,
  ServicePaymentType,
  ServiceRequestCategoriesEnum,
} from '../../graphql/generated';
import {
  AccordionContent,
  AccordionIcon,
  ActivityDetailItemDescription,
  ActivityDetailItemDivider,
  ActivityDetailsCard,
  CardItem,
  CardItemDescription,
  CardItemTitle,
  ConnectButton,
  ConnectText,
  TimelineEventDayName,
  TimelineEventDayNumber,
  TimelineEventLine,
  TimelineEventMonth,
} from './ActivitiesDetailCard.styles';
import { Space } from '../Space/Space';
import { Trans, useTranslation } from 'react-i18next';
import { IonIcon, IonLabel, IonSkeletonText } from '@ionic/react';
import { HistoryIcon } from '../../assets/images/HistoryIcon';
import {
  calendarClearOutline,
  cardOutline,
  homeOutline,
  informationOutline,
} from 'ionicons/icons';
import { formatAddress } from '../../utils/address/formatAddress';
import { UserAvatar, UserAvatars } from '../UserAvatar/UserAvatar';
import { CircleRequestIcon } from '../../assets/images/ServiceCategory/CircleRequestIcon';
import { ReactNode, useContext, useMemo, useState } from 'react';
import { ArrowRight } from '../../assets/images/ArrowRight';
import { AuthContext } from '../../providers/authProvider';
import { IUserData } from '../../types';
import { AgentServiceRequestVisibleStatus } from '../../constants/enums';
import dayjs from 'dayjs';
import { DollarIconNoCircle } from '../../assets/images/DollarIconNoCircle';
import { StarIcon } from '../../assets/images/StarIcon';
import { formatCurrency } from '../../utils/formatCurrency';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

const AVATAR_SIZE = 40;

type PartialGraphqlUser = Pick<
  GraphqlUser,
  'id' | 'fullName' | 'firstName' | 'lastName'
>;

type PartialGraphqlUserCustomerRole = Pick<
  GraphqlUserRole,
  'userId' | 'role'
> & {
  Users?: PartialGraphqlUser | null;
};

type PartialGraphqlCustomer = Pick<
  GraphqlCustomer,
  'firstName' | 'lastName' | 'fullName'
> & {
  UserCustomerRoles?: PartialGraphqlUserCustomerRole[] | null;
};

type PartialGraphqlTimeline = Pick<GraphqlTimeline, 'createdAt' | 'title'> & {
  User?: PartialGraphqlUser | null;
};
interface TimelineProps {
  timeline: PartialGraphqlTimeline[];
}
const Timeline: React.FC<TimelineProps> = ({ timeline }) => {
  const { user } = useContext(AuthContext) as { user: IUserData };
  const i18n = useTranslation();

  const sortedTimeline = timeline
    ? [...timeline].sort((a, b) => {
        return dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1;
      })
    : [];

  return (
    <Space direction="column" size="small">
      {sortedTimeline.map((t, idx) => (
        <Space
          direction="row"
          size={'medium'}
          key={String(t.createdAt) + String(idx)}
        >
          <Space direction="column" size="0" align="center">
            <TimelineEventMonth>
              {dayjs(t.createdAt).format('MMM')}
            </TimelineEventMonth>
            <TimelineEventDayNumber>
              {dayjs(t.createdAt).format('DD')}
            </TimelineEventDayNumber>
            <TimelineEventDayName>
              {dayjs(t.createdAt).format('ddd')}
            </TimelineEventDayName>
            <TimelineEventLine />
          </Space>
          <CardItem>
            <Space direction="column" size="4px">
              <CardItemTitle>{t.title}</CardItemTitle>
              <CardItemDescription>
                <Trans
                  i18nKey="activities.timelineEventDetail"
                  values={{
                    user:
                      t?.User?.id === user?.id
                        ? i18n.t('you')
                        : String(t.User?.fullName),
                    time: dayjs(t.createdAt).format('h:mm A'),
                  }}
                />
              </CardItemDescription>
            </Space>
          </CardItem>
        </Space>
      ))}
    </Space>
  );
};

type PartialGraphqlAddresses =
  | Partial<GraphqlAddresses | GraphqlRideAddress>
  | null
  | undefined;
interface ConnectCustomerDetailRowProps {
  requestor: PartialGraphqlUser;
  customer: PartialGraphqlCustomer;
  customerUserId?: number;
  customerText: ReactNode; // TODO: if CM and Pro Connect row has more differences, we can split them into two components
  onConnectBoth: (userIds: number[]) => void;
}
const ConnectCustomerDetailRow: React.FC<ConnectCustomerDetailRowProps> = ({
  requestor,
  customer,
  customerUserId,
  customerText,
  onConnectBoth,
}) => {
  const customerAndRequestorIsTheSamePerson = requestor.id === customerUserId;

  return (
    <Space direction="column" size="medium">
      <Space direction="row" size="small" align="center">
        <UserAvatar size={AVATAR_SIZE} user={customer} />
        <Space direction="column" size={4}>
          <ConnectText>{customerText}</ConnectText>
          {customerUserId ? (
            <ConnectButton
              onClick={() => {
                if (customerUserId) {
                  void onConnectBoth([customerUserId]);
                }
              }}
            >
              <Trans
                i18nKey="activities.chatWith"
                values={{ firstNames: [customer.firstName] }}
              />
            </ConnectButton>
          ) : null}
          {/* TODO: Implement "Call" button when backend provides temporal number */}
        </Space>
      </Space>

      {customerAndRequestorIsTheSamePerson ? null : (
        <>
          <Space direction="row" size="small" align="center">
            <UserAvatar size={AVATAR_SIZE} user={requestor} />
            <Space direction="column" size={4}>
              <ConnectText>
                <Trans
                  i18nKey="activities.requestedBy"
                  values={{ fullName: requestor.fullName }}
                />
              </ConnectText>
              <ConnectButton
                onClick={() => {
                  void onConnectBoth([requestor.id]);
                }}
              >
                <Trans
                  i18nKey="activities.chatWith"
                  values={{ firstNames: [requestor.firstName] }}
                />
              </ConnectButton>
            </Space>
          </Space>
          {customerUserId ? (
            <Space direction="row" size="small" align="center">
              <UserAvatars users={[customer, requestor]} size={AVATAR_SIZE} />

              <ConnectButton
                onClick={() => {
                  if (customerUserId) {
                    void onConnectBoth([customerUserId, requestor.id]);
                  }
                }}
              >
                <Trans
                  i18nKey="activities.chatWith"
                  values={{
                    firstNames: [requestor.firstName, customer.firstName],
                  }}
                />
              </ConnectButton>
            </Space>
          ) : null}
        </>
      )}
    </Space>
  );
};

interface CollapseProps {
  children: ReactNode;
  defaultExpanded: boolean;
}

interface ActionableDetail {
  icon?: ReactNode;
  onAction: () => void;
}

interface ActivityDetailRowProps {
  icon: ReactNode;
  secondaryIcon?: ReactNode;
  description: ReactNode;
  action?: ActionableDetail;
  collapse?: CollapseProps;
  hide?: boolean;
  ratingInfo?: string;
}
export const ActivityDetailRow: React.FC<ActivityDetailRowProps> = ({
  icon,
  secondaryIcon,
  description,
  action,
  collapse,
  hide,
  ratingInfo = '',
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    Boolean(collapse?.defaultExpanded)
  );

  if (hide) return null;
  return (
    <Space direction="column" size="0" style={{ overflow: 'hidden' }}>
      <Space
        direction="row"
        size="small"
        align="center"
        onClick={() => {
          if (action) {
            action.onAction();
          } else {
            setIsExpanded((prev) => !prev);
          }
        }}
      >
        {icon}
        <ActivityDetailItemDescription>
          {/* TODO: should control overflow? */}
          {description} {secondaryIcon}
        </ActivityDetailItemDescription>
        {ratingInfo && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '35%',
            }}
          >
            <IonLabel
              style={{
                fontSize: '18px',
                color: '#212121',
              }}
            >
              {ratingInfo}
            </IonLabel>
            <StarIcon />
          </div>
        )}
        {collapse && !action ? <AccordionIcon expanded={isExpanded} /> : null}
        {action
          ? action?.icon || (
              <ArrowRight style={{ minWidth: '24px', height: '24px' }} />
            )
          : null}
      </Space>
      {collapse ? (
        <AccordionContent expanded={isExpanded}>
          {collapse.children}
        </AccordionContent>
      ) : null}
    </Space>
  );
};

export type PartialGraphqlCircleRequest = Pick<
  GraphqlCircleRequest,
  'category' | 'scheduledAt' | 'ride'
> & {
  RequestedBy: PartialGraphqlUser;
  Customer: PartialGraphqlCustomer;
  AskTo: PartialGraphqlUser[];
  Address?: PartialGraphqlAddresses;
  Timeline?: PartialGraphqlTimeline[] | null;
};

type ActionableField = {
  name: keyof PartialGraphqlCircleRequest;
  onAction: () => void;
  icon?: ReactNode;
};
interface CircleRequestDetailCardProps {
  activity: PartialGraphqlCircleRequest;
  onConnect: (userIds: number[]) => void;
  actionableFields?: ActionableField[];
  hiddenFields?: (keyof PartialGraphqlCircleRequest)[];
  actionable?: boolean;
}

const RideCircleRequestDetailCard: React.FC<CircleRequestDetailCardProps> = ({
  activity,
  onConnect,
  actionable,
  actionableFields,
  hiddenFields,
}) => {
  const { user } = useContext(AuthContext) as { user: IUserData };
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const i18n = useTranslation();

  const seniorMemberUser = activity.Customer.UserCustomerRoles?.find(
    (u) => u?.role === Role.SeniorMember
  )?.Users;
  return (
    <ActivityDetailsCard>
      <Space direction="column" size="18px">
        <ConnectCustomerDetailRow
          customerText={
            <Trans
              i18nKey="activities.for"
              values={{ fullName: activity.Customer.fullName }}
            />
          }
          customer={activity.Customer}
          customerUserId={seniorMemberUser?.id}
          requestor={activity.RequestedBy}
          onConnectBoth={onConnect}
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={
            <IonIcon
              src={homeOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={formatAddress(activity.ride?.pickupAddress)}
          collapse={{
            defaultExpanded: true,
            children: (
              <Space direction="column" size="small">
                {activity?.ride?.stops?.map((stop, idx) => (
                  <Space
                    direction="row"
                    size={'medium'}
                    key={'stop-' + String(idx)}
                  >
                    <CardItem>
                      <Space direction="column" size="small">
                        <CardItemTitle>
                          <Trans
                            i18nKey={`activities.ride.stopNumber`}
                            values={{ number: idx + 1 }}
                          />
                        </CardItemTitle>
                        <CardItemDescription>
                          {formatAddress(stop)}
                        </CardItemDescription>
                      </Space>
                    </CardItem>
                  </Space>
                ))}
                <Space direction="row" size={'medium'}>
                  <CardItem>
                    <Space direction="column" size="4px">
                      <CardItemTitle>
                        <Trans i18nKey="activities.ride.dropOff" />
                      </CardItemTitle>
                      <CardItemDescription>
                        {formatAddress(activity?.ride?.dropoffAddress)}
                      </CardItemDescription>
                    </Space>
                  </CardItem>
                </Space>
              </Space>
            ),
          }}
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={
            <CircleRequestIcon
              color="var(--colors-primary-blue)"
              style={{ minWidth: '24px', height: '24px' }}
            />
          }
          description={
            <Trans
              i18nKey={`circleMemberActivityDetail.requestedTo`}
              values={{
                askTo: activity.AskTo.map((u) =>
                  u.id === user?.id ? i18n.t('you') : u.fullName
                ),
              }}
            />
          }
        />

        {hiddenFields?.includes('scheduledAt') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('scheduledAt')}
          icon={
            <IonIcon
              src={calendarClearOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            activity.scheduledAt ? (
              formatDateAndTimeInTimezone(activity.scheduledAt)
            ) : (
              <Trans
                i18nKey={`activities.chooseScheduleFromAvailability`}
                values={{
                  name: activity.RequestedBy.fullName,
                }}
              />
            )
          }
          action={
            actionable
              ? actionableFields?.find(
                  (action) => action.name === 'scheduledAt'
                )
              : undefined
          }
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={<HistoryIcon style={{ width: '24px', height: '24px' }} />}
          description={<Trans i18nKey="activities.requestHistory" />}
          collapse={{
            defaultExpanded: false,
            children: <Timeline timeline={activity?.Timeline || []} />,
          }}
        />
      </Space>
    </ActivityDetailsCard>
  );
};

const DefaultCircleRequestDetailCard: React.FC<
  CircleRequestDetailCardProps
> = ({ activity, onConnect, actionable, actionableFields, hiddenFields }) => {
  const { user } = useContext(AuthContext) as { user: IUserData };
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const i18n = useTranslation();

  const seniorMemberUser = activity.Customer.UserCustomerRoles?.find(
    (u) => u?.role === Role.SeniorMember
  )?.Users;
  return (
    <ActivityDetailsCard>
      <Space direction="column" size="18px">
        <ConnectCustomerDetailRow
          customerText={
            <Trans
              i18nKey="activities.for"
              values={{ fullName: activity.Customer.fullName }}
            />
          }
          customer={activity.Customer}
          customerUserId={seniorMemberUser?.id}
          requestor={activity.RequestedBy}
          onConnectBoth={onConnect}
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={
            <IonIcon
              src={homeOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={formatAddress(activity.Address)}
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={
            <CircleRequestIcon
              color="var(--colors-primary-blue)"
              style={{ minWidth: '24px', height: '24px' }}
            />
          }
          description={
            <Trans
              i18nKey={`circleMemberActivityDetail.requestedTo`}
              values={{
                askTo: activity.AskTo.map((u) =>
                  u.id === user?.id ? i18n.t('you') : u.fullName
                ),
              }}
            />
          }
        />

        {hiddenFields?.includes('scheduledAt') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('scheduledAt')}
          icon={
            <IonIcon
              src={calendarClearOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            activity.scheduledAt ? (
              formatDateAndTimeInTimezone(activity.scheduledAt)
            ) : (
              <Trans
                i18nKey={`activities.chooseScheduleFromAvailability`}
                values={{
                  name: activity.Customer.fullName,
                }}
              />
            )
          }
          action={
            actionable
              ? actionableFields?.find(
                  (action) => action.name === 'scheduledAt'
                )
              : undefined
          }
        />

        <ActivityDetailItemDivider />

        <ActivityDetailRow
          icon={<HistoryIcon style={{ width: '24px', height: '24px' }} />}
          description={<Trans i18nKey="activities.requestHistory" />}
          collapse={{
            defaultExpanded: false,
            children: <Timeline timeline={activity?.Timeline || []} />,
          }}
        />
      </Space>
    </ActivityDetailsCard>
  );
};

// NOTE: similar we can create CoachVisitDetailCard, etc.
// and reuse components from here
export const CircleRequestDetailCard: React.FC<CircleRequestDetailCardProps> = (
  props
) => {
  if (props.activity.category === CircleRequestCategoriesEnum.Ride) {
    return <RideCircleRequestDetailCard {...props} />;
  }

  return <DefaultCircleRequestDetailCard {...props} />;
};

export type PartialGraphqlServiceRequest = Pick<
  GraphqlServiceRequest,
  'category' | 'scheduledAt' | 'ride' | 'description'
> & {
  RequestedBy: PartialGraphqlUser;
  Customer: PartialGraphqlCustomer;
  Address?: PartialGraphqlAddresses;
  Timeline?: PartialGraphqlTimeline[] | null;
  payment: Pick<GraphqlAppointmentRequestPayment, 'amount' | 'type'> | null;
};

type ServiceRequestActionableField = {
  name: keyof PartialGraphqlServiceRequest;
  onAction: () => void;
  icon?: ReactNode;
};

interface ServiceRequestDetailCardProps {
  activity: PartialGraphqlServiceRequest;
  onConnect: (userId: number | number[]) => void;
  actionableFields?: ServiceRequestActionableField[];
  hiddenFields?: (keyof PartialGraphqlServiceRequest)[];
  actionable?: boolean;
  status: AgentServiceRequestVisibleStatus;
}

const RideServiceRequestDetailCard: React.FC<ServiceRequestDetailCardProps> = ({
  activity,
  onConnect,
  actionable,
  actionableFields,
  hiddenFields,
  status,
}) => {
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const { t } = useTranslation();

  const seniorMemberCustomer = activity.Customer.UserCustomerRoles?.find(
    (user) => user?.role === Role.SeniorMember
  )?.Users;

  const showCustomerName =
    status === AgentServiceRequestVisibleStatus.Confirmed;

  const PAYMENT_TYPES_LABELS: Record<ServicePaymentType, string> = useMemo(
    () => ({
      [ServicePaymentType.Hour]: t('paymentSetupModal.hourly'),
      [ServicePaymentType.Fixed]: t('paymentSetupModal.setPrice'),
      [ServicePaymentType.Visit]: t('paymentSetupModal.visitPrice'),
    }),
    []
  );

  return (
    <ActivityDetailsCard>
      <Space direction="column" size="18px">
        {status === AgentServiceRequestVisibleStatus.Confirmed ? (
          <>
            <ConnectCustomerDetailRow
              customerText={
                <Trans
                  i18nKey="activities.customer"
                  values={{ fullName: activity.Customer.fullName }}
                />
              }
              customerUserId={seniorMemberCustomer?.id}
              customer={activity.Customer}
              requestor={activity.RequestedBy}
              onConnectBoth={onConnect}
            />
            <ActivityDetailItemDivider />
          </>
        ) : null}

        <ActivityDetailRow
          icon={
            <IonIcon
              src={homeOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            status === AgentServiceRequestVisibleStatus.Confirmed ? (
              formatAddress(activity.ride?.pickupAddress)
            ) : (
              <Trans
                i18nKey="activities.zipCode"
                values={{
                  zipCode: activity.ride?.pickupAddress?.zipCode,
                }}
              />
            )
          }
          collapse={{
            defaultExpanded: true,
            children: (
              <Space direction="column" size="small">
                {activity?.ride?.stops?.map((stop, idx) => (
                  <Space
                    direction="row"
                    size={'medium'}
                    key={'stop-' + String(idx)}
                  >
                    <CardItem>
                      <Space direction="column" size="small">
                        <CardItemTitle>
                          <Trans
                            i18nKey={`activities.ride.stopNumber`}
                            values={{ number: idx + 1 }}
                          />
                        </CardItemTitle>
                        <CardItemDescription>
                          {formatAddress(stop)}
                        </CardItemDescription>
                      </Space>
                    </CardItem>
                  </Space>
                ))}
                <Space direction="row" size={'medium'}>
                  <CardItem>
                    <Space direction="column" size="4px">
                      <CardItemTitle>
                        <Trans i18nKey="activities.ride.dropOff" />
                      </CardItemTitle>
                      <CardItemDescription>
                        {formatAddress(activity?.ride?.dropoffAddress)}
                      </CardItemDescription>
                    </Space>
                  </CardItem>
                </Space>
              </Space>
            ),
          }}
        />

        {hiddenFields?.includes('scheduledAt') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('scheduledAt')}
          icon={
            <IonIcon
              src={calendarClearOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            activity.scheduledAt ? (
              formatDateAndTimeInTimezone(activity.scheduledAt)
            ) : (
              <Trans
                i18nKey={`activities.chooseScheduleFromAvailability`}
                values={{
                  name: showCustomerName
                    ? activity.RequestedBy.fullName
                    : t('activities.customerLowercase'),
                }}
              />
            )
          }
          action={
            actionable
              ? actionableFields?.find(
                  (action) => action.name === 'scheduledAt'
                )
              : undefined
          }
        />

        {hiddenFields?.includes('payment') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('payment')}
          icon={
            <IonIcon
              src={cardOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            activity.payment ? (
              `${PAYMENT_TYPES_LABELS[activity.payment.type]}: ${formatCurrency(
                activity.payment.amount
              )}`
            ) : (
              <Trans i18nKey={`activities.visitCost`} />
            )
          }
          action={
            actionable
              ? actionableFields?.find((action) => action.name === 'payment')
              : undefined
          }
        />

        {status !== AgentServiceRequestVisibleStatus.Open ? (
          <>
            <ActivityDetailItemDivider />

            <ActivityDetailRow
              icon={
                <IonIcon
                  src={informationOutline}
                  style={{
                    fontSize: '24px',
                    minWidth: '24px',
                    color: 'var(--colors-primary-blue)',
                  }}
                />
              }
              description={<Trans i18nKey="activities.requestDetails" />}
              collapse={{
                defaultExpanded: false,
                children: (
                  <CardItem>
                    <CardItemDescription>
                      {activity.description}
                    </CardItemDescription>
                  </CardItem>
                ),
              }}
            />
          </>
        ) : null}

        {status === AgentServiceRequestVisibleStatus.Confirmed ? (
          <>
            <ActivityDetailItemDivider />

            <ActivityDetailRow
              icon={<HistoryIcon style={{ width: '24px', height: '24px' }} />}
              description={<Trans i18nKey="activities.requestHistory" />}
              collapse={{
                defaultExpanded: false,
                children: <Timeline timeline={activity?.Timeline || []} />,
              }}
            />
          </>
        ) : null}
      </Space>
    </ActivityDetailsCard>
  );
};

// TODO: refactor these components to use CardList
const DefaultServiceRequestDetailCard: React.FC<
  ServiceRequestDetailCardProps
> = ({
  activity,
  onConnect,
  actionable,
  actionableFields,
  hiddenFields,
  status,
}) => {
  const { formatDateAndTimeInTimezone } = useDateWithTimezone();
  const { t } = useTranslation();

  const seniorMemberUser = activity.Customer.UserCustomerRoles?.find(
    (user) => user?.role === Role.SeniorMember
  )?.Users;

  const showCustomerName =
    status === AgentServiceRequestVisibleStatus.Confirmed;

  const PAYMENT_TYPES_LABELS: Record<ServicePaymentType, string> = useMemo(
    () => ({
      [ServicePaymentType.Hour]: t('paymentSetupModal.hourly'),
      [ServicePaymentType.Fixed]: t('paymentSetupModal.setPrice'),
      [ServicePaymentType.Visit]: t('paymentSetupModal.visitPrice'),
    }),
    []
  );

  return (
    <ActivityDetailsCard>
      <Space direction="column" size="18px">
        {status === AgentServiceRequestVisibleStatus.Confirmed ? (
          <>
            <ConnectCustomerDetailRow
              customerText={
                <Trans
                  i18nKey="activities.customer"
                  values={{ fullName: activity.Customer.fullName }}
                />
              }
              customer={activity.Customer}
              customerUserId={seniorMemberUser?.id}
              requestor={activity.RequestedBy}
              onConnectBoth={onConnect}
            />
            <ActivityDetailItemDivider />
          </>
        ) : null}

        <ActivityDetailRow
          icon={
            <IonIcon
              src={homeOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            status === AgentServiceRequestVisibleStatus.Confirmed ? (
              formatAddress(activity.Address)
            ) : (
              <Trans
                i18nKey="activities.zipCode"
                values={{
                  zipCode: activity.Address?.zipCode,
                }}
              />
            )
          }
          collapse={
            status === AgentServiceRequestVisibleStatus.Confirmed
              ? undefined
              : {
                  defaultExpanded: false,
                  children: (
                    <CardItem>
                      <CardItemDescription>
                        <Trans i18nKey="activities.fullAddressDisclaimer" />
                      </CardItemDescription>
                    </CardItem>
                  ),
                }
          }
        />

        {hiddenFields?.includes('scheduledAt') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('scheduledAt')}
          icon={
            <IonIcon
              src={calendarClearOutline}
              style={{
                fontSize: '24px',
                minWidth: '24px',
                color: 'var(--colors-primary-blue)',
              }}
            />
          }
          description={
            activity.scheduledAt ? (
              formatDateAndTimeInTimezone(activity.scheduledAt)
            ) : (
              <Trans
                i18nKey={`activities.chooseScheduleFromAvailability`}
                values={{
                  name: showCustomerName
                    ? activity.RequestedBy.fullName
                    : t('activities.customerLowercase'),
                }}
              />
            )
          }
          action={
            actionable
              ? actionableFields?.find(
                  (action) => action.name === 'scheduledAt'
                )
              : undefined
          }
        />

        {hiddenFields?.includes('payment') ? null : (
          <ActivityDetailItemDivider />
        )}

        <ActivityDetailRow
          hide={hiddenFields?.includes('payment')}
          icon={<DollarIconNoCircle width={24} height={24} fill="#212121" />}
          description={
            activity.payment ? (
              `${PAYMENT_TYPES_LABELS[activity.payment.type]}: ${formatCurrency(
                activity.payment.amount
              )}`
            ) : (
              <Trans i18nKey={`activities.visitCost`} />
            )
          }
          action={
            actionable
              ? actionableFields?.find((action) => action.name === 'payment')
              : undefined
          }
        />

        {status !== AgentServiceRequestVisibleStatus.Open ? (
          <>
            <ActivityDetailItemDivider />

            <ActivityDetailRow
              icon={
                <IonIcon
                  src={informationOutline}
                  style={{
                    fontSize: '24px',
                    minWidth: '24px',
                    color: 'var(--colors-primary-blue)',
                  }}
                />
              }
              description={<Trans i18nKey="activities.requestDetails" />}
              collapse={{
                defaultExpanded: false,
                children: (
                  <CardItem>
                    <CardItemDescription>
                      {activity.description}
                    </CardItemDescription>
                  </CardItem>
                ),
              }}
            />
          </>
        ) : null}

        {status === AgentServiceRequestVisibleStatus.Confirmed ? (
          <>
            <ActivityDetailItemDivider />

            <ActivityDetailRow
              icon={<HistoryIcon style={{ width: '24px', height: '24px' }} />}
              description={<Trans i18nKey="activities.requestHistory" />}
              collapse={{
                defaultExpanded: false,
                children: <Timeline timeline={activity?.Timeline || []} />,
              }}
            />
          </>
        ) : null}
      </Space>
    </ActivityDetailsCard>
  );
};

export const ServiceRequestDetailCard: React.FC<
  ServiceRequestDetailCardProps
> = (props) => {
  if (props.activity.category === ServiceRequestCategoriesEnum.Ride) {
    return <RideServiceRequestDetailCard {...props} />;
  }

  return <DefaultServiceRequestDetailCard {...props} />;
};

export const SkeletonActivityDetailCard: React.FC = () => {
  return (
    <ActivityDetailsCard>
      <Space direction="column" size="18px">
        <Space direction="row" size="small">
          <IonSkeletonText
            animated
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
          <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
        </Space>

        <ActivityDetailItemDivider />

        <Space direction="row" size="small">
          <IonSkeletonText
            animated
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
          <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
        </Space>

        <ActivityDetailItemDivider />

        <Space direction="row" size="small">
          <IonSkeletonText
            animated
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
          <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
        </Space>

        <ActivityDetailItemDivider />

        <Space direction="row" size="small">
          <IonSkeletonText
            animated
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
          <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
        </Space>

        <ActivityDetailItemDivider />

        <Space direction="row" size="small">
          <IonSkeletonText
            animated
            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
          />
          <IonSkeletonText animated style={{ width: '100%', height: '40px' }} />
        </Space>
      </Space>
    </ActivityDetailsCard>
  );
};
