import * as React from 'react';

function ActivitiesStatusCompleted(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg width={900} height={190} viewBox="0 0 900 396" {...props}>
      <defs>
        <linearGradient
          id="d"
          gradientUnits="userSpaceOnUse"
          x1={368.119}
          y1={-76.6072}
          x2={-25.3421}
          y2={-32.7165}
          gradientTransform="matrix(2.4 0 0 2.4 0 -24)"
        >
          <stop offset={0} stopColor="#6073dc" />
          <stop offset={0.369792} stopColor="#4c5dbd" />
          <stop offset={1} stopColor="#3f51b5" />
          <stop offset={1} stopColor="#101e6d" />
        </linearGradient>
        <filter
          id="a"
          filterUnits="objectBoundingBox"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
        >
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
        </filter>
        <mask id="f">
          <g filter="url(#a)">
            <path fillOpacity={0.2} d="M0 0H900V372H0z" />
          </g>
        </mask>
        <clipPath id="b">
          <path d="M0 0H900V372H0z" />
        </clipPath>
        <g id="e" clipPath="url(#b)">
          <path
            d="M61.2 79.2c104.05 0 188.402-81.665 188.402-182.4H-127.2c0 100.735 84.347 182.4 188.398 182.4zm0 0"
            fill="#fff"
          />
        </g>
        <mask id="h">
          <g filter="url(#a)">
            <path fillOpacity={0.301961} d="M0 0H900V372H0z" />
          </g>
        </mask>
        <clipPath id="c">
          <path d="M0 0H900V372H0z" />
        </clipPath>
        <g id="g" clipPath="url(#c)">
          <path
            d="M824.922 185.293c-47.328 81.973-21.188 185.668 58.39 231.61l171.383-296.848c-79.574-45.942-182.449-16.735-229.773 65.238zm0 0"
            fill="#fff"
          />
        </g>
      </defs>
      <path fill="url(#d)" d="M0 0H900V372H0z" />
      <use xlinkHref="#e" mask="url(#f)" />
      <use xlinkHref="#g" mask="url(#h)" />
    </svg>
  );
}

export default ActivitiesStatusCompleted;
