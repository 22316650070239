import { IonIcon, IonPage, useIonRouter } from '@ionic/react';
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import {
  AppointmentRequestStatus,
  GraphqlAppointmentRequest,
  GraphqlServiceRequest,
  ServiceRequestStatus,
  useCreateAppointmentRequestPaymentMutation,
  useGetAgentActivityByAppointmentIdQuery,
  useUpdateAppointmentRequestMutation,
  useUpdateAppointmentRequestPaymentMutation,
} from '../../graphql/generated';
import { Space } from '../../components/Space/Space';
import { Button } from '../../components/Button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import { GET_AGENT_ACTIVITIES } from '../../graphql/activities';
import { CustomAlertV2 } from '../../components/CustomAlert/CustomAlert';
import {
  getApolloErrorCodes,
  getApolloErrorMessage,
} from '../../utils/apollo/errors';
import { useDirectChatMessage } from '../../utils/hooks/useDirectChatMessage';
import IconActivityConfirmed from '../../assets/images/IconActivityConfirmed';
import IconActivityPending from '../../assets/images/IconActivityPending';
import IconActivityCancelled from '../../assets/images/IconActivityCancelled';
import {
  PartialGraphqlServiceRequest,
  ServiceRequestDetailCard,
} from '../../components/ActivitiesDetailCard/ActivitiesDetailCard';
import { AgentServiceRequestVisibleStatus } from '../../constants/enums';
import ModalPostVisitInfo from '../../components/ModalPostVisitInfo/ModalPostVisitInfo';
import ModalEnterCost, {
  AppointmentPaymentFormFields,
} from '../../components/ModalEnterCost/ModalEnterCost';
import dayjs from 'dayjs';
import { logger } from '../../logger';
import { useAppContext } from '../../providers/appContextProvider';
import { PageWithBottomSheetLayout } from '../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { repeatOutline } from 'ionicons/icons';
import { ActivityDetailSkeletonPage } from '../../components/ActivityDetailSkeletonPage/ActivityDetailSkeletonPage';
import { BodyText } from '../../components/Typography/Body/Body';
import { SelectDateAndTimeModalSheet } from '../../components/SelectDateAndTimeModalSheet/SelectDateAndTimeModalSheet';
import { HeaderDoneButton } from '../../components/HeaderDoneButton/HeaderDoneButton';
import { HeaderBackButton } from '../../components/HeaderBackButton/HeaderBackButton';
import { RequestCategoryName } from '../../components/RequestCategoryName/RequestCategoryName';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

interface PageStatusVariables {
  background: CSSProperties['background'];
  ticketBackground: CSSProperties['background'];
  ticketTitle: ReactNode;
  ticketTitleIcon: ReactNode;
  agentVisibleStatus: AgentServiceRequestVisibleStatus;
  primaryColor: string;
  secondaryColor: string;
}
const getPageVariablesByStatus = (
  appointmentRequest: Pick<GraphqlAppointmentRequest, 'status'>,
  serviceRequest: Pick<GraphqlServiceRequest, 'status'>
): PageStatusVariables => {
  if (appointmentRequest.status === AppointmentRequestStatus.Open) {
    return {
      background: `linear-gradient(
        267.19deg,
        #084282 -0.42%,
        #00356f 41.24%,
        #00244a 100.84%
      )`,
      ticketBackground: `linear-gradient(180deg, #dbe0fc 0%, #ffffff 98.45%);`,
      ticketTitle: (
        <Trans i18nKey="serviceRequestActivityDetail.ticketTitles.open" />
      ),
      ticketTitleIcon: null,
      agentVisibleStatus: AgentServiceRequestVisibleStatus.Open,
      primaryColor: 'primary-orange',
      secondaryColor: 'secondary-orange',
    };
  }

  if (appointmentRequest.status === AppointmentRequestStatus.Declined) {
    return {
      background: 'var(--interaction-error)',
      ticketBackground: `linear-gradient(180deg, #ffe4e2 0%, #ffffff 98.45%);`,
      ticketTitle: (
        <Trans i18nKey="serviceRequestActivityDetail.ticketTitles.declined" />
      ),
      ticketTitleIcon: <IconActivityCancelled />,
      agentVisibleStatus: AgentServiceRequestVisibleStatus.Declined,
      primaryColor: 'primary-red',
      secondaryColor: 'secondary-red',
    };
  }

  if (
    (appointmentRequest.status === AppointmentRequestStatus.Accepted ||
      appointmentRequest.status === AppointmentRequestStatus.Assigned) &&
    serviceRequest.status !== ServiceRequestStatus.Confirmed
  ) {
    return {
      background:
        'linear-gradient(267.19deg, #B179F5 -0.42%, #9254DE 57.59%, #7A33D1 100.84%);',
      ticketBackground: 'linear-gradient(180deg, #F0E3FF 0%, #FFFFFF 98.45%);',
      ticketTitle: (
        <Trans i18nKey="serviceRequestActivityDetail.ticketTitles.pending" />
      ),
      ticketTitleIcon: <IconActivityPending />,
      agentVisibleStatus: AgentServiceRequestVisibleStatus.Pending,
      primaryColor: 'primary-purple',
      secondaryColor: 'secondary-purple',
    };
  }

  if (serviceRequest.status === ServiceRequestStatus.Confirmed) {
    return {
      background: `linear-gradient(
        267.19deg,
        #13c4ca -0.42%,
        #08979c 57.59%,
        #017c81 100.84%
      );`,
      ticketBackground: `linear-gradient(180deg, #dbf9fc 0%, #ffffff 98.45%);`,
      ticketTitle: (
        <Trans i18nKey="serviceRequestActivityDetail.ticketTitles.confirmed" />
      ),
      ticketTitleIcon: <IconActivityConfirmed />,
      agentVisibleStatus: AgentServiceRequestVisibleStatus.Confirmed,
      primaryColor: 'primary-green',
      secondaryColor: 'secondary-green',
    };
  }

  logger.warn({
    tag: '[ActivityDetail][getPageVariablesByStatus]',
    message: `Unknown colors for appointment status ${appointmentRequest.status} and service request status ${serviceRequest.status}`,
  });

  return {
    background: '',
    ticketBackground: '',
    ticketTitle: '',
    ticketTitleIcon: null,
    agentVisibleStatus: AgentServiceRequestVisibleStatus.Open,
    primaryColor: '',
    secondaryColor: '',
  };
};

export interface AlertProps {
  title: string;
  subtitle?: string;
  firstButtonLabel: string;
  secondButtonLabel?: string;
  firstButtonAction: () => void | Promise<void>;
  secondButtonAction?: () => void | Promise<void>;
  onTextAreaChange?: (value: string) => void;
  showTextArea?: boolean;
}

const CONFIRMED_APPOINTMENT_EDITABLE_THRESHOLD_IN_HOURS = 48;

const AgentActivityDetailPage: React.FC = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const i18n = useTranslation();
  const history = useIonRouter();
  const { directChatMessage: goToChat, loading: creatingConversation } =
    useDirectChatMessage();
  const { globalAlert } = useAppContext();
  const { transformDateToTimezone } = useDateWithTimezone();

  // TODO: handle error
  const { data, loading, refetch } = useGetAgentActivityByAppointmentIdQuery({
    variables: { appointmentRequestId: Number(appointmentId) },
  });

  const [updateAppointmentRequest, { loading: isUpdating }] =
    useUpdateAppointmentRequestMutation({
      refetchQueries: [GET_AGENT_ACTIVITIES],
    });
  // NOTE: this two mutations should be inside in updateAppointmentRequest in the future
  const [
    createAppointmentRequestPayment,
    { loading: isCreatingAppointmentPayment },
  ] = useCreateAppointmentRequestPaymentMutation();
  const [
    updateAppointmentRequestPayment,
    { loading: isUpdatingAppointmentPayment },
  ] = useUpdateAppointmentRequestPaymentMutation();

  const [isSelectingDateAndTime, setIsSelectingDateAndTime] =
    useState<boolean>(false);
  const [isSettingCost, setIsSettingCost] = useState<boolean>(false);
  const [appointmentUpdated, setAppointmentUpdated] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslation();
  const [selectedDateAndTime, setSelectedDateAndTime] = useState<string>(''); // YYYY-MM-DDTHH:mm
  const [appointmentPayment, setAppointmentPayment] =
    useState<AppointmentPaymentFormFields | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const [showPostVisitModal, setShowPostVisitModal] = useState(false);
  const appointment = data?.getAgentActivityByAppointmentId;
  const resetForm = useCallback(() => {
    setSelectedDateAndTime('');
    setAppointmentPayment(null);
    setIsEditing(false);
  }, []);

  const hasPassedActivityScheduledAt = useCallback(() => {
    return appointment?.scheduledAt && new Date() > appointment.scheduledAt;
  }, [appointment]);

  const currentAppointmentPayment = useMemo(() => {
    if (appointment?.AppointmentRequestPayments?.length)
      return appointment?.AppointmentRequestPayments[0];
  }, [appointment]);

  const handleAccept = useCallback(async () => {
    if (
      !selectedDateAndTime ||
      !appointmentPayment?.amount ||
      !appointmentPayment?.type
    ) {
      setAlert({
        title: i18n.t('serviceRequestActivityDetail.alerts.invalidForm.title'),
        subtitle: String(
          i18n.t('serviceRequestActivityDetail.alerts.invalidForm.subtitle')
        ),
        firstButtonLabel: i18n.t(
          'serviceRequestActivityDetail.alerts.invalidForm.primaryLabel'
        ),
        firstButtonAction() {
          setAlert(null);
        },
      });
      return;
    }

    try {
      await createAppointmentRequestPayment({
        variables: {
          appointmentPaymentInput: {
            appointmentRequestId: Number(appointmentId),
            amount: appointmentPayment.amount,
            type: appointmentPayment.type,
          },
        },
      });
      await updateAppointmentRequest({
        variables: {
          updateAppointmentRequestInput: {
            id: Number(appointmentId),
            status: AppointmentRequestStatus.Accepted,
            scheduledAt: transformDateToTimezone(
              parseISOLocal(selectedDateAndTime)
            ),
          },
        },
      });
      resetForm();
      await refetch();
      setAppointmentUpdated(true);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle:
          getApolloErrorMessage(err) || String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          if (
            getApolloErrorCodes(err).includes(
              'appointment-request-already-accepted'
            )
          ) {
            history.goBack();
          } else {
            setAlert(null);
          }
        },
      });
    }
  }, [selectedDateAndTime, appointmentPayment]);

  const handleReject = useCallback(() => {
    setAlert({
      title: i18n.t('serviceRequestActivityDetail.alerts.decline.title', {
        requestor: appointment?.ServiceRequest?.RequestedBy.fullName,
      }),
      firstButtonLabel: i18n.t(
        'serviceRequestActivityDetail.alerts.decline.primaryLabel'
      ),
      firstButtonAction: async () => {
        try {
          setAlert(null);
          await updateAppointmentRequest({
            variables: {
              updateAppointmentRequestInput: {
                id: Number(appointmentId),
                status: AppointmentRequestStatus.Declined,
              },
            },
          });
          resetForm();
          await refetch();
          setAppointmentUpdated(true);
        } catch (err) {
          setAlert({
            title: i18n.t('genericError.title'),
            subtitle:
              getApolloErrorMessage(err) ||
              String(i18n.t('genericError.subtitle')),
            firstButtonLabel: i18n.t('genericError.primaryLabel'),
            firstButtonAction() {
              setAlert(null);
            },
          });
        }
      },
      secondButtonLabel: String(
        i18n.t('serviceRequestActivityDetail.alerts.decline.secondaryLabel')
      ),
      secondButtonAction() {
        setAlert(null);
      },
    });
  }, [appointment?.ServiceRequest?.RequestedBy.fullName]);

  const handleCancelAppointment = useCallback(() => {
    setAlert({
      title: appointment?.ServiceRequest?.recurring
        ? i18n.t(
            'serviceRequestActivityDetail.alerts.cancelAppointment.titleRecurring'
          )
        : i18n.t('serviceRequestActivityDetail.alerts.cancelAppointment.title'),
      subtitle: appointment?.ServiceRequest?.recurring
        ? i18n.t(
            'serviceRequestActivityDetail.alerts.cancelAppointment.subtitleRecurring'
          )
        : String(
            i18n.t(
              'serviceRequestActivityDetail.alerts.cancelAppointment.subtitle'
            )
          ),
      firstButtonLabel: i18n.t(
        'serviceRequestActivityDetail.alerts.cancelAppointment.primaryLabel'
      ),
      firstButtonAction: async () => {
        try {
          setAlert(null);
          await updateAppointmentRequest({
            variables: {
              updateAppointmentRequestInput: {
                id: Number(appointmentId),
                status: AppointmentRequestStatus.Declined,
              },
            },
          });
          resetForm();
          await refetch();
          setAppointmentUpdated(true);
        } catch (err) {
          setAlert({
            title: i18n.t('genericError.title'),
            subtitle:
              getApolloErrorMessage(err) ||
              String(i18n.t('genericError.subtitle')),
            firstButtonLabel: i18n.t('genericError.primaryLabel'),
            firstButtonAction() {
              setAlert(null);
            },
          });
        }
      },
      secondButtonLabel: String(
        i18n.t(
          'serviceRequestActivityDetail.alerts.cancelAppointment.secondaryLabel'
        )
      ),
      secondButtonAction() {
        setAlert(null);
      },
    });
  }, [appointment?.ServiceRequest?.recurring]);

  const handleUpdate = useCallback(async () => {
    try {
      if (currentAppointmentPayment) {
        // NOTE: Confirmed appointments price can not be edited
        // so we only trigger this mutation if there were changes
        if (
          (appointmentPayment?.amount &&
            appointmentPayment.amount !== currentAppointmentPayment.amount) ||
          (appointmentPayment?.type &&
            appointmentPayment.type !== currentAppointmentPayment.type)
        ) {
          await updateAppointmentRequestPayment({
            variables: {
              appointmentRequestPaymentId: currentAppointmentPayment.id,
              appointmentPaymentInput: {
                amount:
                  appointmentPayment?.amount ||
                  currentAppointmentPayment.amount,
                type:
                  appointmentPayment?.type || currentAppointmentPayment.type,
              },
            },
          });
        }
        await updateAppointmentRequest({
          variables: {
            updateAppointmentRequestInput: {
              id: Number(appointmentId),
              status: AppointmentRequestStatus.Accepted,
              scheduledAt: selectedDateAndTime
                ? transformDateToTimezone(parseISOLocal(selectedDateAndTime))
                : appointment?.scheduledAt,
            },
          },
        });
      } else {
        if (!appointmentPayment?.amount || !appointmentPayment?.type) {
          setAlert({
            title: i18n.t(
              'serviceRequestActivityDetail.alerts.invalidForm.title'
            ),
            subtitle: String(
              i18n.t('serviceRequestActivityDetail.alerts.invalidForm.subtitle')
            ),
            firstButtonLabel: i18n.t(
              'serviceRequestActivityDetail.alerts.invalidForm.primaryLabel'
            ),
            firstButtonAction() {
              setAlert(null);
            },
          });
          return;
        }
        await createAppointmentRequestPayment({
          variables: {
            appointmentPaymentInput: {
              appointmentRequestId: Number(appointmentId),
              amount: appointmentPayment.amount,
              type: appointmentPayment.type,
            },
          },
        });
        await updateAppointmentRequest({
          variables: {
            updateAppointmentRequestInput: {
              id: Number(appointmentId),
              status: AppointmentRequestStatus.Accepted,
              scheduledAt: selectedDateAndTime
                ? transformDateToTimezone(parseISOLocal(selectedDateAndTime))
                : appointment?.scheduledAt,
            },
          },
        });
      }
      resetForm();
      await refetch();
      setAppointmentUpdated(true);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle:
          getApolloErrorMessage(err) || String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          setAlert(null);
        },
      });
    }
  }, [selectedDateAndTime, appointmentPayment, currentAppointmentPayment]);

  const handleConnect = useCallback(async (userId: number | number[]) => {
    try {
      await goToChat(userId);
    } catch (err) {
      setAlert({
        title: i18n.t('genericError.title'),
        subtitle: String(i18n.t('genericError.subtitle')),
        firstButtonLabel: i18n.t('genericError.primaryLabel'),
        firstButtonAction() {
          setAlert(null);
        },
      });
    }
  }, []);

  const handleGoBack = () => {
    if (isEditing || selectedDateAndTime || appointmentPayment) {
      setAlert({
        title: i18n.t(
          'serviceRequestActivityDetail.alerts.unsavedChanges.title'
        ),
        subtitle: String(
          i18n.t('serviceRequestActivityDetail.alerts.unsavedChanges.subtitle')
        ),
        firstButtonLabel: i18n.t(
          'serviceRequestActivityDetail.alerts.unsavedChanges.primaryLabel'
        ),
        firstButtonAction() {
          setAlert(null);
          history.goBack();
        },
        secondButtonLabel: String(
          i18n.t(
            'serviceRequestActivityDetail.alerts.unsavedChanges.secondaryLabel'
          )
        ),
        secondButtonAction() {
          setAlert(null);
        },
      });
    } else {
      history.goBack();
    }
  };

  const overrirdeServiceRequestFields = useCallback(
    (
      serviceRequest: Omit<PartialGraphqlServiceRequest, 'payment'>,
      appointmentRequestScheduleDate: Date | null | undefined,
      currentPayment: AppointmentPaymentFormFields | null | undefined
    ): PartialGraphqlServiceRequest => {
      return {
        ...serviceRequest,
        scheduledAt:
          (selectedDateAndTime &&
            transformDateToTimezone(parseISOLocal(selectedDateAndTime))) ||
          appointmentRequestScheduleDate ||
          serviceRequest.scheduledAt,
        payment: appointmentPayment || currentPayment || null,
      };
    },
    [selectedDateAndTime, appointmentPayment]
  );

  const isAnyMutationLoading =
    isUpdating ||
    creatingConversation ||
    isUpdatingAppointmentPayment ||
    isCreatingAppointmentPayment;

  if (
    loading ||
    !appointment ||
    isAnyMutationLoading ||
    !appointment.ServiceRequest
  )
    return (
      <IonPage>
        <ActivityDetailSkeletonPage />
      </IonPage>
    );

  const isFormEditable =
    appointment.status === AppointmentRequestStatus.Open || isEditing;

  const pageVariables = getPageVariablesByStatus(
    appointment,
    appointment.ServiceRequest
  );
  const isConfirmedAppointmentTooClose =
    pageVariables.agentVisibleStatus ===
      AgentServiceRequestVisibleStatus.Confirmed &&
    dayjs(appointment.scheduledAt).diff(dayjs(), 'hours') <
      CONFIRMED_APPOINTMENT_EDITABLE_THRESHOLD_IN_HOURS;
  const getHiddenFields: () => (keyof PartialGraphqlServiceRequest)[] = () => {
    if (
      !isEditing &&
      [AgentServiceRequestVisibleStatus.Declined].includes(
        pageVariables.agentVisibleStatus
      )
    ) {
      return ['scheduledAt', 'payment'];
    } else {
      return [];
    }
  };

  return (
    <IonPage>
      <PageWithBottomSheetLayout
        title={
          <RequestCategoryName
            category={appointment.ServiceRequest.RequestCategory?.name}
          />
        }
        subtitle={
          appointment.ServiceRequest.recurring ? (
            <IonIcon icon={repeatOutline} style={{ width: 20, height: 20 }} />
          ) : undefined
        }
        bottomSheetTitle={
          <>
            {pageVariables.ticketTitleIcon}
            {pageVariables.ticketTitle}
          </>
        }
        startSlotHeaderAction={
          appointmentUpdated ? null : (
            <HeaderBackButton
              onClick={handleGoBack}
              disabled={isAnyMutationLoading}
            />
          )
        }
        endSlotHeaderAction={
          appointmentUpdated ? (
            <HeaderDoneButton
              onClick={() => history.goBack()}
              disabled={isAnyMutationLoading}
            />
          ) : null
        }
        background={pageVariables.background}
        bottomSheetBackground={pageVariables.ticketBackground}
        bottomSheetSubtitle={
          appointment.ServiceRequest.recurring
            ? i18n.t('serviceForm.recurringEvent')
            : undefined
        }
      >
        <Space size="large" direction="column">
          <BodyText color="var(--body-text-700)">
            {pageVariables.agentVisibleStatus ===
            AgentServiceRequestVisibleStatus.Open ? (
              appointment.ServiceRequest.description
            ) : (
              <Trans
                i18nKey={`serviceRequestActivityDetail.ticketDescriptions.${pageVariables.agentVisibleStatus}`}
              />
            )}
          </BodyText>
          <ServiceRequestDetailCard
            status={pageVariables.agentVisibleStatus}
            activity={overrirdeServiceRequestFields(
              appointment.ServiceRequest,
              appointment.scheduledAt,
              currentAppointmentPayment
            )}
            onConnect={(userId) => {
              void handleConnect(userId);
            }}
            actionableFields={[
              {
                name: 'scheduledAt',
                onAction: () => {
                  if (isFormEditable && !isAnyMutationLoading) {
                    setIsSelectingDateAndTime(true);
                  }
                },
              },
              {
                name: 'payment',
                onAction: () => {
                  if (isFormEditable && !isAnyMutationLoading) {
                    if (
                      pageVariables.agentVisibleStatus ===
                      AgentServiceRequestVisibleStatus.Confirmed
                    ) {
                      globalAlert({
                        title: t(
                          'serviceRequestActivityDetail.alerts.updatePriceNotPossible.title'
                        ),
                        subtitle: t(
                          'serviceRequestActivityDetail.alerts.updatePriceNotPossible.subtitle'
                        ),
                        firstButtonLabel: t(
                          'serviceRequestActivityDetail.alerts.updatePriceNotPossible.primaryLabel'
                        ),
                      });
                    } else {
                      setIsSettingCost(true);
                    }
                  }
                },
              },
            ]}
            actionable={isFormEditable}
            hiddenFields={getHiddenFields()}
          />
          <Space direction="column" size="small">
            {/* NOTE: Open request are editable by default */}
            {pageVariables.agentVisibleStatus !==
              AgentServiceRequestVisibleStatus.Open &&
            !hasPassedActivityScheduledAt() &&
            !isConfirmedAppointmentTooClose &&
            !isEditing ? (
              <Button
                onClick={() => setIsEditing(true)}
                disabled={isAnyMutationLoading}
                color={pageVariables.primaryColor}
              >
                <Trans i18nKey="serviceRequestActivityDetail.cta.edit" />
              </Button>
            ) : null}

            {appointment.ServiceRequest.status ===
              ServiceRequestStatus.Confirmed &&
              hasPassedActivityScheduledAt() && (
                <Button
                  onClick={() => setShowPostVisitModal(true)}
                  disabled={isAnyMutationLoading}
                  color={pageVariables.primaryColor}
                >
                  <Trans i18nKey="serviceRequestActivityDetail.cta.triggerPostVisit" />
                </Button>
              )}

            {pageVariables.agentVisibleStatus ===
              AgentServiceRequestVisibleStatus.Open && !isEditing ? (
              <Space size="small" direction="column">
                <Button
                  onClick={() => {
                    void handleAccept();
                  }}
                  disabled={isAnyMutationLoading}
                  color={pageVariables.primaryColor}
                >
                  <Trans i18nKey="serviceRequestActivityDetail.cta.accept" />
                </Button>
                <Button
                  onClick={handleReject}
                  disabled={isAnyMutationLoading}
                  color={pageVariables.secondaryColor}
                >
                  <Trans i18nKey="serviceRequestActivityDetail.cta.decline" />
                </Button>
              </Space>
            ) : null}

            {isEditing ? (
              <Space size="small" direction="column">
                <Button
                  color={pageVariables.primaryColor}
                  onClick={() => {
                    void handleUpdate();
                  }}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="serviceRequestActivityDetail.cta.save" />
                </Button>
                {pageVariables.agentVisibleStatus !==
                AgentServiceRequestVisibleStatus.Declined ? (
                  <Button
                    color={pageVariables.secondaryColor}
                    onClick={
                      pageVariables.agentVisibleStatus ===
                      AgentServiceRequestVisibleStatus.Confirmed
                        ? handleCancelAppointment
                        : handleReject
                    }
                    disabled={isAnyMutationLoading}
                  >
                    <Trans i18nKey="serviceRequestActivityDetail.cta.decline" />
                  </Button>
                ) : null}
                <Button
                  color={pageVariables.secondaryColor}
                  onClick={resetForm}
                  disabled={isAnyMutationLoading}
                >
                  <Trans i18nKey="serviceRequestActivityDetail.cta.cancel" />
                </Button>
              </Space>
            ) : null}
          </Space>
        </Space>
      </PageWithBottomSheetLayout>
      {appointment.ServiceRequest.status === ServiceRequestStatus.Confirmed && (
        <ModalPostVisitInfo
          isOpenModal={showPostVisitModal}
          onOpenModal={setShowPostVisitModal}
          appointment={appointment}
          refetch={refetch}
        />
      )}
      {isSelectingDateAndTime && (
        <SelectDateAndTimeModalSheet
          isOpen
          createdAt={appointment.ServiceRequest.createdAt}
          availabilities={appointment.ServiceRequest.availabilities}
          tentativeSchedule={appointment.ServiceRequest.tentativeSchedule}
          recurring={appointment.ServiceRequest.recurring}
          frequency={appointment.ServiceRequest.frequency ?? ''}
          onSubmit={(date, time) => {
            setSelectedDateAndTime(`${date}T${time}`);
            setIsSelectingDateAndTime(false);
          }}
          onClose={() => {
            setIsSelectingDateAndTime(false);
          }}
        />
      )}
      {appointment && isSettingCost ? (
        <ModalEnterCost
          isOpen={isSettingCost}
          onSubmit={(newAppointmentPayment) => {
            setAppointmentPayment(newAppointmentPayment);
            setIsSettingCost(false);
          }}
          onClose={() => {
            setIsSettingCost(false);
          }}
          initialState={appointmentPayment || currentAppointmentPayment}
          serviceFee={appointment.ServiceRequest.serviceFee}
        />
      ) : null}
      {alert ? <CustomAlertV2 isOpen {...alert} /> : null}
    </IonPage>
  );
};

export default AgentActivityDetailPage;
