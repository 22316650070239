import { LogParams, logger } from '../../logger';
import { useAppContext } from '../../providers/appContextProvider';
import { useTranslation } from 'react-i18next';
import { getApolloErrorMessage } from '../apollo/errors';
import { ApolloError } from '@apollo/client';

type UseMutationDefaultErrorHandlerProps = {
  tag: LogParams['tag'];
};
export const useDefaultMutationErrorHandler = ({
  tag,
}: UseMutationDefaultErrorHandlerProps) => {
  const { globalAlert } = useAppContext();
  const i18n = useTranslation();
  return (error: ApolloError) => {
    logger.error({
      tag,
      message: `Fail to execute mutation: ${getApolloErrorMessage(error)}`,
      error,
    });
    globalAlert({
      title: i18n.t('DefaultErrorAlert.Title'),
      subtitle:
        getApolloErrorMessage(error) || i18n.t('DefaultErrorAlert.Subtitle'),
      firstButtonLabel: i18n.t('DefaultErrorAlert.PrimaryLabel'),
    });
  };
};
