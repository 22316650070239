import { GetCoachVisitQuery } from '../../graphql/generated';
import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { ConnectButton } from './ActivityCardItemCoach.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { heartCircleOutline } from 'ionicons/icons';

type Coach = GetCoachVisitQuery['getCoachVisit']['Coach'];

type ActivityCardItemCoachProps = {
  coach: Coach;
  onConnect?: () => Promise<void>;
};
export const ActivityCardItemCoach: React.FC<ActivityCardItemCoachProps> = ({
  coach,
  onConnect,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="auto"
          className="ion-align-self-center"
          style={{ marginInlineEnd: '8px' }}
        >
          <IonIcon
            icon={heartCircleOutline}
            color="var(--colors-primary-blue)"
            style={{ width: '24px', height: '24px' }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {coach?.fullName}
          </BodyText>
          {onConnect ? (
            <ConnectButton
              className="ion-no-padding ion-no-margin"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                void onConnect().finally(() => setLoading(false));
              }}
            >
              {loading ? (
                <IonSpinner
                  color="primary-orange"
                  name="dots"
                  style={{ maxHeight: '19px' }}
                />
              ) : (
                t('ActivityCardItemCoach.Connect')
              )}
            </ConnectButton>
          ) : null}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
