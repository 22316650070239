import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonLabel } from '@ionic/react';

export const FormLabel = styled(IonLabel)`
  && {
    font-weight: 500;
    display: block;
    font-size: ${pixelToRem(18)};
    margin-bottom: 8px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
