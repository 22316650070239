/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Trans, useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import ActivitiesStatusConfirmed from '../../../assets/images/ActivitiesStatusConfirmed';
import {
  BodyContainer,
  CancelButton,
  ContainerButtons,
  IconContainer,
  RecurringText,
  Subtitle,
  Title,
  ViewEditRequestButton,
} from '../ActivityStatus.styles';
import IconActivityConfirmed from '../../../assets/images/IconActivityConfirmed';
import ActivitiesSections from '../../ActivitiesSections/ActivitiesSections';
import {
  personOutline,
  homeOutline,
  call,
  calendarClearOutline,
  chevronDown,
  createOutline,
  checkmarkOutline,
  locationOutline,
  informationOutline,
  heartCircleOutline,
  repeatOutline,
} from 'ionicons/icons';
import { DollarIcon } from '../../../assets/images/DollarIcon';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import {
  ApprovalServiceRequestStatus,
  GetCircleRequestQueryHookResult,
  GetCoachVisitQueryHookResult,
  GraphqlServiceRequest,
  GraphqlServiceRequestPayment,
  useGetCircleRequestQuery,
  useGetCoachVisitQuery,
  useGetMobileSocialEventQuery,
  useGetServiceRequestQuery,
  useUpdateCircleRequestTimeMutation,
  useUpdateServiceRequestTimeMutation,
  useUserApprovalSocialEventMutation,
  useUserCancelCircleRequestMutation,
  useUserCancelServiceRequestMutation,
} from '../../../graphql/generated';
import { useHistory, useLocation } from 'react-router';
import CustomAlert from '../../CustomAlert/CustomAlert';
import { useEffect, useMemo, useState } from 'react';
import { ActivityStatusEnum } from '../../../pages/activity-status/activity-statusEnum';
import { ActivityStatus } from '../StatusComponent.interface';
import { buildRideStops } from '../../../utils/buildRideObject';
import ActivityStatusSkeleton from '../StatusContentSkeleton';
import { formatDayOfWeekInTimezone } from '../../../utils/date';
import { parseISOLocal } from '../../../utils/date/parseISOLocal';
import { getAddressInformationCircle } from '../../../utils/getAddressInformationCircle';
import { useTalkJsChat } from '../../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../../FullScreenLoader/FullScreenLoader';
import { useDirectChatMessage } from '../../../utils/hooks/useDirectChatMessage';
import { formatAddress } from '../../../utils/address/formatAddress';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface ConfirmedStatusProps {
  title?: string;
  serviceRequestId?: number;
  requestType?: string;
  handleRecurring?: (recurring: boolean) => void;
  customerId?: number;
}

const ConfirmedStatus: React.FC<ConfirmedStatusProps> = ({
  requestType,
  serviceRequestId,
  handleRecurring,
  customerId: initialCustomerId,
}) => {
  const { directChatMessage: goToChat } = useDirectChatMessage();
  const [showSubmitChange, setShowSubmitChange] = useState<boolean>(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const history = useHistory();
  const i18n = useTranslation();
  const { width } = useWindowDimensions();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const location = useLocation<ActivityStatus>();
  const [userCancelServiceRequestMutation] =
    useUserCancelServiceRequestMutation();
  const confirmedOptions = [];
  let reason = '';
  const [updateServiceRequestTimeMutation] =
    useUpdateServiceRequestTimeMutation();
  const [newDate, setNewDate] = useState<string>(''); // Format YYYY-MM-DDTHH:mm:ss
  const [cancelCircleRequestMutation] = useUserCancelCircleRequestMutation();
  const [updateCircleRequestTimeMutation] =
    useUpdateCircleRequestTimeMutation();
  const [userApprovalSocialRequestMutation] =
    useUserApprovalSocialEventMutation();
  const {
    formatDateAndTimeInTimezone,
    formatIonDateTimeValueInTimezone,
    formatTimeInTimezone,
    transformDateToTimezone,
  } = useDateWithTimezone();

  const { data: getServiceRequestData } = useGetServiceRequestQuery({
    variables: {
      getServiceRequestId: serviceRequestId ? serviceRequestId : 0,
    },
    skip: requestType !== 'service',
    onCompleted: () => setIsDataReady(true),
  });

  const { data: getSocialRequestData } = useGetMobileSocialEventQuery({
    variables: {
      socialEventId: serviceRequestId ? serviceRequestId : 0,
      customerId: initialCustomerId as number,
    },
    skip: requestType !== 'social' || !initialCustomerId,
    onCompleted: () => setIsDataReady(true),
  });

  const { data: getCircleRequestData }: GetCircleRequestQueryHookResult =
    useGetCircleRequestQuery({
      variables: {
        getCircleRequestId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'circle',
      onCompleted: () => setIsDataReady(true),
    });

  const { data: getCoachRequestData }: GetCoachVisitQueryHookResult =
    useGetCoachVisitQuery({
      variables: {
        coachVisitId: serviceRequestId ? serviceRequestId : 0,
      },
      skip: requestType !== 'coachVisit',
      onCompleted: () => setIsDataReady(true),
    });

  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();

  const addressInf = `${getServiceRequestData?.getServiceRequest.Address?.addressLine1}, ${getServiceRequestData?.getServiceRequest.Address?.city}`;
  const ratingInf =
    getServiceRequestData?.getServiceRequest.Agent?.rating?.toFixed() as
      | number
      | '';
  const userFullName = getServiceRequestData?.getServiceRequest.Agent?.User
    .fullName as string;

  const scheduledAt: Date | null | undefined =
    getServiceRequestData?.getServiceRequest.scheduledAt;
  const dateScheduled = scheduledAt
    ? formatDateAndTimeInTimezone(scheduledAt)
    : '';

  const requestByName = `Organized by ${
    getSocialRequestData?.getMobileSocialEvent.RequestedBy.fullName as string
  }`;
  const addressInfEvent = `${getSocialRequestData?.getMobileSocialEvent.addressLine1}, ${getSocialRequestData?.getMobileSocialEvent?.city}`;
  const eventScheduledAt: Date | null | undefined =
    getSocialRequestData?.getMobileSocialEvent.scheduledAt;
  const eventDateScheduled = eventScheduledAt
    ? formatDateAndTimeInTimezone(eventScheduledAt)
    : '';

  const addressCircle = `${getCircleRequestData?.getCircleRequest.Address?.addressLine1}, ${getCircleRequestData?.getCircleRequest.Address?.city}`;
  const circleScheduledAt: Date | null | undefined =
    getCircleRequestData?.getCircleRequest.scheduledAt;
  const circleDateScheduled = circleScheduledAt
    ? formatDateAndTimeInTimezone(circleScheduledAt)
    : '';

  const addressCoach = getCoachRequestData?.getCoachVisit.Address
    ? formatAddress(
        getCoachRequestData?.getCoachVisit.Address,
        Boolean(getCoachRequestData?.getCoachVisit.Address?.place)
      )
    : 'Phone Call';

  const requestedToCircle = getCircleRequestData?.getCircleRequest?.AcceptedBy
    ?.fullName as string;

  const rideStops = useMemo(() => {
    let data;

    if (requestType === 'service') {
      data = getServiceRequestData?.getServiceRequest;
    } else if (requestType === 'circle') {
      data = getCircleRequestData?.getCircleRequest;
    }

    return buildRideStops(data);
  }, [getServiceRequestData, getCircleRequestData, requestType]);

  const abbrevAvatar = useMemo(() => {
    return {
      firstName:
        getCircleRequestData?.getCircleRequest.AcceptedBy?.firstName?.charAt(
          0
        ) || '',
      lastName:
        getCircleRequestData?.getCircleRequest.AcceptedBy?.lastName?.charAt(
          0
        ) || '',
    };
  }, [getCircleRequestData]);

  const coachScheduledAt: Date | null | undefined =
    getCoachRequestData?.getCoachVisit.scheduledAt;
  const coachDateScheduled = coachScheduledAt
    ? formatDateAndTimeInTimezone(coachScheduledAt)
    : '';
  const coachFullName = getCoachRequestData?.getCoachVisit.Coach.fullName as
    | string;
  const customerId =
    getSocialRequestData?.getMobileSocialEvent?.Customer?.id || 0;

  if (requestType === 'social') {
    confirmedOptions.push(
      {
        iconStartName: checkmarkOutline,
        iconEndName: '',
        customIcon: false,
        title: requestByName,
        readOnly: true,
      },
      {
        iconStartName: locationOutline,
        iconEndName: '',
        title: addressInfEvent,
        ratingValue: '',
        readOnly: true,
      }
    );
    if (getSocialRequestData?.getMobileSocialEvent.scheduledAt) {
      confirmedOptions.push({
        iconStartName: calendarClearOutline,
        iconEndName: '',
        title: eventDateScheduled,
        ratingValue: '',
        readOnly: false,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getSocialRequestData?.getMobileSocialEvent.scheduledAt
            )
          : '',
      });
    }
  } else if (requestType === 'circle') {
    confirmedOptions.push(
      {
        iconStartName: '',
        iconEndName: '',
        customIcon: false,
        title: requestedToCircle as string | JSX.Element,
        ratingValue: '',
        readOnly: true,
        customClass: false,
        subTitle: 'Connect',
        circleAvatarAbbrev: abbrevAvatar,
        onSubtitleClick: () => {
          const circleAcceptorId =
            getCircleRequestData?.getCircleRequest?.AcceptedBy?.id;
          if (circleAcceptorId) {
            return goToChat(circleAcceptorId);
          }

          return Promise.resolve();
        },
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getCircleRequestData?.getCircleRequest?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
    if (getCircleRequestData?.getCircleRequest.Address) {
      confirmedOptions.unshift({
        iconStartName: homeOutline,
        iconEndName: rideStops.length > 0 ? chevronDown : '',
        title: addressCircle as string | JSX.Element,
        ratingValue: '',
        readOnly: rideStops.length > 0 ? false : true,
      });
    }
    if (getCircleRequestData?.getCircleRequest.ride) {
      confirmedOptions.unshift({
        iconStartName: homeOutline,
        iconEndName: rideStops.length > 0 ? chevronDown : '',
        title: getAddressInformationCircle(getCoachRequestData),
        ratingValue: '',
        readOnly: rideStops.length > 0 ? false : true,
      });
    }
    if (getCircleRequestData?.getCircleRequest.scheduledAt) {
      const newCalendarOption = {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: circleDateScheduled,
        ratingValue: '',
        readOnly: false,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getCircleRequestData?.getCircleRequest.scheduledAt
            )
          : '',
      };

      confirmedOptions.splice(1, 0, newCalendarOption);
    }
    if (getCircleRequestData?.getCircleRequest.recurring) {
      confirmedOptions.splice(1, 0, {
        iconStartName: repeatOutline,
        iconEndName: '',
        title: (
          <Trans
            i18nKey="serviceForm.frequencyInfo"
            values={{
              frequency:
                getCircleRequestData?.getCircleRequest.frequency?.toLowerCase(),
              dayOfWeek: formatDayOfWeekInTimezone(
                getCircleRequestData?.getCircleRequest.scheduledAt ?? ''
              ),
              time: formatTimeInTimezone(
                getCircleRequestData?.getCircleRequest.scheduledAt
              ),
            }}
          />
        ),
        ratingValue: '',
        readOnly: true,
      });
    }
  } else if (requestType === 'coachVisit') {
    confirmedOptions.push(
      {
        iconStartName: heartCircleOutline,
        iconEndName: '',
        customIcon: false,
        title: coachFullName as string | JSX.Element,
        ratingValue: '',
        readOnly: false,
        subTitle: 'Start chat',
        handler: async () => {
          await handleGetBryaTeamChat();
        },
      },
      {
        iconStartName: getCoachRequestData?.getCoachVisit.Address
          ? homeOutline
          : call,
        iconEndName: '',
        title: addressCoach as string | JSX.Element,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: coachDateScheduled as string | JSX.Element,
        ratingValue: '',
        readOnly: true,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getCoachRequestData?.getCoachVisit?.scheduledAt
            )
          : '',
      }
    );
    if (getCoachRequestData?.getCoachVisit.recurring) {
      confirmedOptions.splice(1, 0, {
        iconStartName: repeatOutline,
        iconEndName: '',
        title: (
          <Trans
            i18nKey="serviceForm.frequencyInfo"
            values={{
              frequency:
                getCoachRequestData?.getCoachVisit.frequency?.toLowerCase(),
              dayOfWeek: formatDayOfWeekInTimezone(
                getCoachRequestData?.getCoachVisit.scheduledAt ?? ''
              ),
              time: formatTimeInTimezone(coachDateScheduled),
            }}
          />
        ),
        ratingValue: '',
        readOnly: true,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(eventScheduledAt)
          : '',
      });
    }
  } else {
    confirmedOptions.push(
      {
        iconStartName: homeOutline,
        iconEndName: '',
        title: addressInf as string | JSX.Element,
        ratingValue: '',
        readOnly: true,
      },
      {
        iconStartName: DollarIcon,
        iconEndName: chevronDown,
        title: 'Payment',
        ratingValue: '',
        readOnly: false,
      },
      {
        iconStartName: HistoryIcon,
        iconEndName: chevronDown,
        title: 'Request history',
        historyTimeline: getServiceRequestData?.getServiceRequest
          ?.Timeline as [],
        ratingValue: '',
        readOnly: false,
      }
    );
    if (getServiceRequestData?.getServiceRequest.recurring) {
      confirmedOptions.splice(1, 0, {
        iconStartName: repeatOutline,
        iconEndName: '',
        title: (
          <Trans
            i18nKey="serviceForm.frequencyInfo"
            values={{
              frequency:
                getServiceRequestData?.getServiceRequest.frequency?.toLowerCase(),
              dayOfWeek: formatDayOfWeekInTimezone(
                getServiceRequestData?.getServiceRequest.scheduledAt ?? ''
              ),
              time: formatTimeInTimezone(eventScheduledAt),
            }}
          />
        ),
        ratingValue: '',
        readOnly: true,
      });
    }
    if (userFullName) {
      confirmedOptions.unshift({
        iconStartName: personOutline,
        iconEndName: '',
        customIcon: ratingInf ? true : false,
        title: userFullName,
        ratingValue: ratingInf,
        readOnly: false,
        subTitle: 'see profile',
      });
    }
    if (getServiceRequestData?.getServiceRequest.scheduledAt) {
      const newConfirmedOption = {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: dateScheduled,
        ratingValue: '',
        readOnly: false,
        calendarValue: isDataReady
          ? formatIonDateTimeValueInTimezone(
              getServiceRequestData?.getServiceRequest.scheduledAt
            )
          : '',
      };

      confirmedOptions.splice(1, 0, newConfirmedOption);
    }
  }

  const handleTextAreaChange = (value: string) => {
    reason = value;
  };

  const goToPendingWithNewDate = () => {
    const newDateProposed = newDate
      ? formatDateAndTimeInTimezone(
          transformDateToTimezone(parseISOLocal(newDate))
        )
      : '';
    history.replace('/activityStatus', {
      title: location.state?.title,
      status: ActivityStatusEnum.Pending,
      requestType,
      serviceRequestId,
      customerId: initialCustomerId,
      typeChange: {
        iconStartName: calendarClearOutline,
        iconEndName: createOutline,
        title: newDateProposed,
        ratingValue: '',
        readOnly: true,
        subTitle: 'New date and time proposed',
      },
    });
  };

  const goToCancelledStatus = () =>
    history.replace('/activityStatus', {
      title: location.state?.title,
      status: ActivityStatusEnum.Cancelled,
      requestType,
      serviceRequestId,
      customerId: initialCustomerId,
      typeChange: {
        iconStartName: informationOutline,
        iconEndName: chevronDown,
        title: 'Reason',
        ratingValue: '',
        readOnly: false,
        reason,
      },
    });

  const getTypeRequestTitle = () => {
    switch (requestType) {
      case 'social':
        return getSocialRequestData?.getMobileSocialEvent.recurring
          ? i18n.t('cancelEventBtnTitleRecurring')
          : i18n.t('cancelEventBtnTitle');
      case 'coachVisit':
        return getCoachRequestData?.getCoachVisit.recurring
          ? i18n.t('cancelRequestBtnTitleRecurring')
          : i18n.t('cancelCoachVisitBtnTitle');
      default:
        return getServiceRequestData?.getServiceRequest.recurring
          ? i18n.t('cancelRequestBtnTitleRecurring')
          : i18n.t('cancelRequestBtnTitle');
    }
  };

  useEffect(() => {
    if (handleRecurring && getServiceRequestData?.getServiceRequest.recurring) {
      handleRecurring(getServiceRequestData?.getServiceRequest.recurring);
    }
    if (handleRecurring && getCircleRequestData?.getCircleRequest.recurring) {
      handleRecurring(getCircleRequestData?.getCircleRequest.recurring);
    }
    if (handleRecurring && getCoachRequestData?.getCoachVisit.recurring) {
      handleRecurring(getCoachRequestData?.getCoachVisit.recurring);
    }
  }, [getServiceRequestData, getCircleRequestData, getCoachRequestData]);

  return (
    <>
      {getBryaTeamChatQueryLoading && <FullScreenLoader />}
      <ActivitiesStatusConfirmed width={width} />
      <BodyContainer width={width} className="confirmed">
        <IconContainer>
          <IconActivityConfirmed />
        </IconContainer>
        <Title data-testid="confirmed-title">
          {requestType === 'social' && i18n.t('confirmed')}
          {requestType === 'service' && i18n.t('requestConfirmed')}
          {requestType === 'circle' && i18n.t('circleConfirmed')}
          {requestType === 'coachVisit' && i18n.t('coachVisitConfirmed')}
        </Title>
        {getServiceRequestData?.getServiceRequest.recurring && (
          <RecurringText>{i18n.t('serviceForm.recurringEvent')}</RecurringText>
        )}
        <Subtitle
          data-testid="confirmed-subtitle"
          className="confirmedSubTitle"
        >
          {requestType === 'social' &&
            getSocialRequestData?.getMobileSocialEvent.description}
          {requestType === 'service' && i18n.t('confirmedSubtitleComp')}
          {requestType === 'circle' &&
            getCircleRequestData?.getCircleRequest.description}
          {requestType === 'coachVisit' &&
            getCoachRequestData?.getCoachVisit.description}
          {requestType === 'coachVisit' &&
            !getCoachRequestData?.getCoachVisit.description &&
            i18n.t('coachVisitSubtitle')}
        </Subtitle>
        {!isDataReady && <ActivityStatusSkeleton />}
        {isDataReady && (
          <ActivitiesSections
            serviceRequestId={serviceRequestId}
            requestType={requestType}
            itemLists={confirmedOptions}
            showSubmitChange={setShowSubmitChange}
            onCallbackChangeDate={setNewDate}
            rideOptions={rideStops}
            status={'confirmed'}
            category={getServiceRequestData?.getServiceRequest.category}
            agentId={
              getServiceRequestData?.getServiceRequest?.agentId as number
            }
            serviceRequestPayment={
              getServiceRequestData?.getServiceRequest.ServiceRequestPayments
                ? (getServiceRequestData?.getServiceRequest
                    .ServiceRequestPayments[0] as GraphqlServiceRequestPayment)
                : null
            }
            serviceRequest={
              getServiceRequestData?.getServiceRequest as GraphqlServiceRequest
            }
          />
        )}
        <ContainerButtons>
          {showSubmitChange && (
            <ViewEditRequestButton
              slot={'primary'}
              className="confirmed"
              onClick={() => {
                if (requestType === 'service') {
                  updateServiceRequestTimeMutation({
                    variables: {
                      newTime: transformDateToTimezone(parseISOLocal(newDate)),
                      serviceRequestId: serviceRequestId ? serviceRequestId : 0,
                    },
                  })
                    .then(() => {
                      goToPendingWithNewDate();
                    })
                    .catch(() => {
                      throw new Error('Update Service Request Time Failed');
                    });
                }

                if (requestType === 'circle') {
                  updateCircleRequestTimeMutation({
                    variables: {
                      newTime: transformDateToTimezone(parseISOLocal(newDate)),
                      circleRequestId: serviceRequestId ? serviceRequestId : 0,
                    },
                  })
                    .then(() => {
                      goToPendingWithNewDate();
                    })
                    .catch(() => {
                      throw new Error('Update Circle Request Time Failed');
                    });
                }
              }}
            >
              <>{i18n.t('submitChange')}</>
            </ViewEditRequestButton>
          )}
          {requestType === 'social' ||
            requestType === 'circle' ||
            (requestType === 'coachVisit' && (
              <CancelButton
                data-testid="confirmed-button"
                className="confirmed"
                fill={'outline'}
                onClick={() => setModalOpen(true)}
              >
                {requestType === 'coachVisit' && (
                  <>{i18n.t('cancelCoachVisit')}</>
                )}
                {requestType !== 'coachVisit' && <>{i18n.t('cancelRequest')}</>}
              </CancelButton>
            ))}
          {!showSubmitChange && requestType === 'social' && (
            <CancelButton
              data-testid="confirmed-button"
              className="confirmed"
              fill={'outline'}
              onClick={() => {
                userApprovalSocialRequestMutation({
                  variables: {
                    action: ApprovalServiceRequestStatus.Decline,
                    socialEventId: serviceRequestId ? serviceRequestId : 0,
                    customerId,
                  },
                })
                  .then(() => {
                    history.replace('/activityStatus', {
                      title: location.state?.title,
                      requestType,
                      serviceRequestId,
                      status: ActivityStatusEnum.Cancelled,
                      customerId,
                      typeChange: {
                        iconStartName: informationOutline,
                        iconEndName: chevronDown,
                        title: 'Reason',
                        ratingValue: '',
                        readOnly: false,
                        reason,
                      },
                    });
                  })
                  .catch(() => {
                    throw new Error('Decline Event Failed');
                  });
              }}
            >
              <>{i18n.t('ImUnavailable')}</>
            </CancelButton>
          )}
          {!showSubmitChange &&
            (requestType === 'service' || requestType === 'circle') && (
              <CancelButton
                className="confirmed"
                data-testid="cancel-button"
                fill={'outline'}
                onClick={() => setModalOpen(true)}
              >
                <>{i18n.t('cancelVisit')}</>
              </CancelButton>
            )}
        </ContainerButtons>
        <CustomAlert
          showTextArea={requestType === 'coachVisit' ? false : true}
          isOpen={modalOpen}
          onDidDismiss={() => setModalOpen(false)}
          title={getTypeRequestTitle()}
          subtitle={
            requestType === 'coachVisit'
              ? i18n.t('cancelCoachVisitBtnSubtitle')
              : i18n.t('cancelRequestBtnSubtitle')
          }
          firstButtonLabel={
            requestType === 'coachVisit'
              ? i18n.t('connectCoach')
              : i18n.t('yesCancelButton')
          }
          secondButtonLabel={
            requestType === 'coachVisit'
              ? i18n.t('cancelCoach')
              : i18n.t('noCancelButton')
          }
          firstButtonAction={async () => {
            if (requestType === 'service') {
              userCancelServiceRequestMutation({
                variables: {
                  cancellationReason: reason,
                  serviceRequestId: serviceRequestId ? serviceRequestId : 0,
                },
              })
                .then(() => {
                  setModalOpen(false);
                  goToCancelledStatus();
                })
                .catch(() => {
                  throw new Error('Update Service Request Cancel Failed');
                });
            }

            if (requestType === 'circle') {
              cancelCircleRequestMutation({
                variables: {
                  circleRequestId: serviceRequestId ? serviceRequestId : 0,
                  cancellationReason: reason,
                },
              })
                .then(() => {
                  setModalOpen(false);
                  goToCancelledStatus();
                })
                .catch(() => {
                  throw new Error('Cancel Circle Request Failed');
                });
            }

            if (requestType === 'coachVisit') {
              await handleGetBryaTeamChat();
              setModalOpen(false);
            }
          }}
          onTextAreaChange={handleTextAreaChange}
          secondButtonAction={() => setModalOpen(false)}
        />
      </BodyContainer>
    </>
  );
};

export default ConfirmedStatus;
