import { createAnimation } from '@ionic/react';
import { MutableRefObject, useEffect } from 'react';

interface UseMountAnimatedProps {
  ref: MutableRefObject<HTMLElement | null>;
  duration?: number;
  hide?: boolean | undefined;
}
export const useMountAnimated = ({
  ref,
  duration,
  hide,
}: UseMountAnimatedProps) => {
  useEffect(() => {
    if (ref.current !== null && !hide) {
      const animation = createAnimation()
        .addElement(ref.current)
        .duration(duration || 400)
        .fromTo('transform', 'translateX(-100%)', 'translateX(0)')
        .fromTo('opacity', '0', '1');
      void animation.play();
    }
  }, [hide]);
};
