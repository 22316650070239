import React, { useContext } from 'react';
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
} from '@ionic/react';

import { Route, useLocation } from 'react-router-dom';
import ActivitiesTab from '../tabs/activities/activities';
import {
  AskBryaContainer,
  IonButtonContent,
  IonLabelStyled,
} from './tabs.style';
import AskBryaTab from './ask-brya/ask-brya';
import { ActivitiesSelected } from '../../assets/images/ActivitiesSelected';
import { CommunitySelected } from '../../assets/images/CommunitySelected';
import { ConnectSelected } from '../../assets/images/ConnectSelected';
import { ChatTabIconSelected } from '../../assets/images/ChatTabIconSelected';
import { ChatTabIconNotSelected } from '../../assets/images/ChatTabIconNotSelected';
import { ActivitiesNotSelected } from '../../assets/images/ActivitiesNotSelected';
import { CommunityNotSelected } from '../../assets/images/CommunityNotSelected';
import { ConnectNotSelected } from '../../assets/images/ConnectNotSelected';
import LayoutWithHeader from '../../components/Layout/LayoutWithHeader';
import { AuthContext } from '../../providers/authProvider';
import CircleMemberActivitiesTab from './activities/circle-member-activities/circle-member-activities';
import { AccountNotSelected } from '../../assets/images/AccountNotSelected';
import { AccountSelected } from '../../assets/images/AccountSelected';
import { ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { GlobalRole, useGetCoachProfileQuery } from '../../graphql/generated';
import { OfferIcon } from '../../assets/images/OfferIcon';
import { OfferIconSelected } from '../../assets/images/OfferIconSelected';
import InvitesTab from '../invites/invites';
import { useNotifications } from '../../providers/notificationsProvider';
import AccountTab from '../Account/Account';
import ChatInboxTab from './chat-inbox/chat-inbox';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';

interface TabButtonProps {
  route: string;
  iconSelected: JSX.Element;
  iconNotSelected: JSX.Element;
  label: string;
}

const TabButtonContent: React.FC<TabButtonProps> = ({
  route,
  iconSelected,
  iconNotSelected,
  label,
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const selected = location.pathname === route;
  const { hasCircleRequestNotification, hasServiceRequestNotification } =
    useNotifications();

  const tabLabelHightLight = (tabLabel: string): boolean => {
    if (tabLabel === t('tabs.activities') && hasServiceRequestNotification) {
      return true;
    }
    if (tabLabel === t('tabs.offer') && hasCircleRequestNotification) {
      return true;
    }
    return false;
  };

  return (
    <IonButtonContent>
      {selected ? iconSelected : iconNotSelected}
      <IonLabelStyled
        askBryaButton={label === t('tabs.Community') ? true : false}
        isSelected={selected}
        tab={route}
        hasUnreadServiceNotification={tabLabelHightLight(label)}
      >
        {label}
      </IonLabelStyled>
    </IonButtonContent>
  );
};

const Tabs: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { hasCircleRequestNotification, hasServiceRequestNotification } =
    useNotifications();
  const { data: coachProfile } = useGetCoachProfileQuery({
    skip:
      !user ||
      user?.role === GlobalRole.Agent ||
      user?.role === GlobalRole.AdminAgent,
  });
  const isPro =
    user &&
    (user.role === GlobalRole.AdminAgent || user.role === GlobalRole.Agent);
  const showActivitiesTab = true;
  const showCircleTab = isFeatureEnabled(FeatureNames.CircleRequest) && !isPro;
  const showAskBryaTab = !isPro;
  const showConnectTab = isFeatureEnabled(FeatureNames.ConnectTab) && !isPro;
  const showAccountTab = !isPro;
  const showChatTab = isPro;

  return (
    <IonTabs>
      <AccountNotSelected />
      <IonRouterOutlet>
        <LayoutWithHeader scrollY={false}>
          <Route
            path={ROUTES.TABS.ACTIVITIES}
            component={ActivitiesTab}
            exact
          />
          <Route path={ROUTES.TABS.CONNECT} component={InvitesTab} exact />
          <Route path={ROUTES.TABS.ASK_BRYA} component={AskBryaTab} exact />
          <Route
            path={ROUTES.TABS.CIRCLE}
            component={CircleMemberActivitiesTab}
            exact
          />
          <Route
            path={ROUTES.TABS.ACCOUNT}
            render={() => (
              <AccountTab
                hasCoach={coachProfile?.getCoachProfile?.id ? true : false}
              />
            )}
            exact
          />
          <Route path={ROUTES.TABS.CHAT} component={ChatInboxTab} exact />
        </LayoutWithHeader>
      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        {showActivitiesTab && (
          <IonTabButton
            tab={ROUTES.TABS.ACTIVITIES}
            href={ROUTES.TABS.ACTIVITIES}
          >
            <TabButtonContent
              route={ROUTES.TABS.ACTIVITIES}
              iconNotSelected={
                <ActivitiesNotSelected
                  hasUnreadServiceNotification={hasServiceRequestNotification}
                />
              }
              iconSelected={
                <ActivitiesSelected
                  hasUnreadServiceNotification={hasServiceRequestNotification}
                />
              }
              label={t('tabs.activities')}
            />
          </IonTabButton>
        )}
        {showCircleTab && (
          <IonTabButton tab={ROUTES.TABS.CIRCLE} href={ROUTES.TABS.CIRCLE}>
            <TabButtonContent
              route={ROUTES.TABS.CIRCLE}
              iconNotSelected={
                <OfferIcon
                  hasUnreadServiceNotification={hasCircleRequestNotification}
                />
              }
              iconSelected={
                <OfferIconSelected
                  hasUnreadServiceNotification={hasCircleRequestNotification}
                />
              }
              label={t('tabs.offer')}
            />
          </IonTabButton>
        )}
        {showAskBryaTab && (
          <IonTabButton tab={ROUTES.TABS.ASK_BRYA} href={ROUTES.TABS.ASK_BRYA}>
            <TabButtonContent
              route={ROUTES.TABS.ASK_BRYA}
              iconNotSelected={
                <AskBryaContainer>
                  <CommunityNotSelected />
                </AskBryaContainer>
              }
              iconSelected={<CommunitySelected />}
              label={t('tabs.Community')}
            />
          </IonTabButton>
        )}
        {showConnectTab && (
          <IonTabButton tab={ROUTES.TABS.CONNECT} href={ROUTES.TABS.CONNECT}>
            <TabButtonContent
              route={ROUTES.TABS.CONNECT}
              iconNotSelected={<ConnectNotSelected />}
              iconSelected={<ConnectSelected />}
              label={t('tabs.invite')}
            />
          </IonTabButton>
        )}
        {showChatTab && (
          <IonTabButton tab={ROUTES.TABS.CHAT} href={ROUTES.TABS.CHAT}>
            <TabButtonContent
              route={ROUTES.TABS.CHAT}
              iconNotSelected={<ChatTabIconNotSelected />}
              iconSelected={<ChatTabIconSelected />}
              label={t('tabs.connect')}
            />
          </IonTabButton>
        )}
        {showAccountTab && (
          <IonTabButton tab={ROUTES.TABS.ACCOUNT} href={ROUTES.TABS.ACCOUNT}>
            <TabButtonContent
              route={ROUTES.TABS.ACCOUNT}
              iconNotSelected={<AccountNotSelected />}
              iconSelected={<AccountSelected />}
              label={t('tabs.account')}
            />
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  );
};
export default Tabs;
