import { IonModal } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(24)};
  line-height: 120%;
  color: var(--ion-color-warning-contrast);
`;

export const StyledIonModal = styled(IonModal)`
  ion-title {
    width: inherit;
  }
`;
