import { ReactNode, FC } from 'react';
import styled from 'styled-components';

const Box = styled.div<{ borderColor?: string }>`
  width: 100%;
  padding: 12px;
  background: white;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px ${(props) => props.borderColor || 'transparent'} solid;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
`;

type CostBoxProps = {
  children: ReactNode;
  borderColor?: string;
};

const CostBox: FC<CostBoxProps> = ({ children, borderColor }) => (
  <Box borderColor={borderColor}>{children}</Box>
);

export default CostBox;
