import * as React from 'react';

export const NotificationOutline = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="32"
    viewBox="0 0 31 32"
    fill="none"
    {...props}
  >
    <path
      d="M25.8943 21.7765C24.3395 19.8735 23.2418 18.9048 23.2418 13.6584C23.2418 8.85401 20.7884 7.14235 18.7692 6.31104C18.5009 6.20084 18.2485 5.94776 18.1667 5.67227C17.8125 4.46678 16.8196 3.40479 15.4996 3.40479C14.1797 3.40479 13.1861 4.46738 12.8356 5.67348C12.7538 5.95199 12.5013 6.20084 12.2331 6.31104C10.2115 7.14356 7.76053 8.84916 7.76053 13.6584C7.7575 18.9048 6.65978 19.8735 5.10494 21.7765C4.46072 22.5649 5.02502 23.7485 6.1518 23.7485H24.8535C25.9742 23.7485 26.5349 22.5612 25.8943 21.7765Z"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3756 23.7485V24.7174C19.3756 25.7453 18.9673 26.731 18.2405 27.4579C17.5137 28.1847 16.5279 28.593 15.5001 28.593C14.4722 28.593 13.4864 28.1847 12.7596 27.4579C12.0328 26.731 11.6245 25.7453 11.6245 24.7174V23.7485"
      stroke="#23364B"
      strokeWidth="1.00189"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
