import { gql } from '@apollo/client';

export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress(
    $updateAddressInput: UpdateAddressInput!
    $updateAddressId: Int!
  ) {
    updateAddress(
      updateAddressInput: $updateAddressInput
      id: $updateAddressId
    ) {
      id
    }
  }
`;

export const DELETE_ADDRESS_MUTATION = gql`
  mutation DeleteAddress($deleteAddressId: Int!) {
    deleteAddress(id: $deleteAddressId) {
      success
    }
  }
`;

export const GET_ADDRESS = gql`
  query GetAddresses($customerId: Float!) {
    getAddresses(customerId: $customerId) {
      id
      customerId
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
      isDefault
      createdAt
      updatedAt
      place
    }
  }
`;

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer($customerId: Float!) {
    getCustomer(customerId: $customerId) {
      id
      firstName
      lastName
      fullName
      addresses {
        id
        addressLine1
        addressLine2
        city
        customerId
        isDefault
        state
        zipCode
        place
      }
      users {
        role
        user {
          id
          fullName
          createdAt
        }
      }
      createdBy
      createdAt
      updatedAt
      UserCustomerRoles {
        role
        Users {
          id
          fullName
        }
      }
    }
  }
`;

export const SET_APP_FEATURES_SELECTED = gql`
  mutation SetAppFeaturesSelected(
    $appFeaturesSelected: SelectAppFeaturesInput!
  ) {
    setAppFeaturesSelected(appFeaturesSelected: $appFeaturesSelected) {
      id
      userId
      seniorMember
      familyPartner
      circleMember
      createdAt
      updatedAt
      User {
        id
        email
      }
    }
  }
`;
export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($updateUserInput: UpdateCurrentUserInput!) {
    updateCurrentUser(updateUserInput: $updateUserInput) {
      firebaseToken
      id
      email
    }
  }
`;

export const GET_USER_RELATED_CUSTOMERS = gql`
  query GetUserRelatedCustomers($roles: [AppUser!], $userId: Int) {
    getUserRelatedCustomers(roles: $roles, userId: $userId) {
      id
      firstName
      lastName
      fullName
      email
      createdAt
      updatedAt
      createdBy
      UserCustomerRoles {
        id
        userId
        customerId
        role
        createdAt
        updatedAt
        Users {
          id
          createdAt
          updatedAt
          fullName
          lastName
          firstName
        }
      }
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount {
    deleteAccount {
      success
    }
  }
`;

export const SAVE_FIREBASE_TOKEN = gql`
  mutation SaveFirebaseToken($firebaseToken: String!, $uuid: String!) {
    saveFirebaseToken(firebaseToken: $firebaseToken, uuid: $uuid) {
      id
      uuid
    }
  }
`;
