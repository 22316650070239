import ConfirmedStatus from '../ConfirmedStatus/ConfirmedStatus';
import NeededStatus from '../NeededStatus/NeededStatus';
import PendingStatus from '../PendingStatus/PendingStatus';
import CancelledStatus from '../CancelledStatus/CancelledStatus';
import { ActivityStatus } from '../StatusComponent.interface';
import CompletedStatus from '../CompletedStatus/CompletedStatus';
import { logger } from '../../../logger';
import { ServiceRequestStatus } from '../../../graphql/generated';
import PaymentIssue from '../../../pages/status-pages/PaymentIssue/PaymentIssue';

const StatusComponent: React.FC<ActivityStatus> = ({
  status,
  typeChange,
  title,
  serviceRequestId,
  requestType,
  customerId,
  onRecurringActivity,
}) => {
  switch (status) {
    case ServiceRequestStatus.Confirmed:
      return (
        <ConfirmedStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          handleRecurring={onRecurringActivity}
          customerId={customerId}
        />
      );
    case ServiceRequestStatus.ConfirmationNeeded:
      return (
        <NeededStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          customerId={customerId}
        />
      );
    case ServiceRequestStatus.Pending:
      return (
        <PendingStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          title={title}
          typeChange={typeChange}
        />
      );
    case ServiceRequestStatus.Cancelled:
      return (
        <CancelledStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          title={title}
          typeChange={typeChange}
          customerId={customerId}
        />
      );
    case ServiceRequestStatus.UserUnavailable:
      return (
        <CancelledStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          title={title}
          typeChange={typeChange}
          customerId={customerId}
        />
      );
    case ServiceRequestStatus.Completed:
      return (
        <CompletedStatus
          requestType={requestType}
          serviceRequestId={serviceRequestId}
          title={title}
          customerId={customerId}
        />
      );
    case ServiceRequestStatus.PaymentAuthorizationFailed:
    case ServiceRequestStatus.PaymentFailed:
      return <PaymentIssue serviceRequestId={serviceRequestId} />;
    default:
      logger.info({
        tag: '[StatusComponent]',
        message: `Receiving an unknown status: ${status || 'undefined'}`,
      });
      return null;
  }
};

export default StatusComponent;
