import dayjs, { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import {
  ServiceRequestAvailability,
  ServiceRequestAvailabilityInput,
  ServiceRequestTentativeSchedule,
  useGetDefaultTimezoneQuery,
} from '../../graphql/generated';
import { DayjsDateInput, TimeSlot, generateRange } from '../date';

extend(utc);
extend(tz);

export const useDateWithTimezone = () => {
  const { data, previousData } = useGetDefaultTimezoneQuery();
  const defaultTimezone =
    (previousData || data)?.getDefaultTimezone.timezone || 'America/New_York';

  // IMPORTANT: Use these functions or create similar for dates that are timezone sensitives
  // (scheduledAt, availabilities, etc.). Remove timezone default once the backend is ready
  // to send the timezone information.
  // Once the backend is ready, removing the default will make the project fail to compile, so
  // we need to pass the correct timezone where these functions are being used.
  // NOTE: America/Chicago is IANA timezone identifier for Dallas, TX.
  const formatDateDividerInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ): string => {
    return dayjs(date).tz(timezone).format('MMM D, YYYY');
  };

  const formatTimeInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ): string => {
    return dayjs(date).tz(timezone).format('h:mm A z');
  };

  const formatAvailabilityInTimezone = (
    availability: ServiceRequestAvailability,
    timezone = defaultTimezone
  ): string[] => {
    const formatDateWithTimezone = (
      dateStr: string,
      timeStr: string
    ): dayjs.Dayjs => {
      const [hour, minute] = timeStr.split('Z')[0].split(':');
      return dayjs.utc(`${dateStr}T${hour}:${minute}:00Z`).tz(timezone);
    };

    const dateFrom = formatDateWithTimezone(
      String(availability.date),
      String(availability.from)
    );
    const dateUntil = formatDateWithTimezone(
      String(availability.date),
      String(availability.until)
    );

    return [dateFrom.format('YYYY-MM-DD'), dateUntil.format('YYYY-MM-DD')];
  };

  const formatDateAndTimeInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone,
    format = 'ddd, MMM D - h:mm A z',
    keepLocalTime?: boolean
  ): string => {
    return dayjs(date).tz(timezone, keepLocalTime).format(format);
  };

  const formatMonthDateInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ): string => {
    return dayjs(date).tz(timezone).format('MMM Do');
  };

  const formatIonDateTimeValueInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ): string => {
    return dayjs(date).tz(timezone).format('YYYY-MM-DDTHH:mm:ss');
  };

  const KEEP_LOCAL_TIME_WHEN_CONVERTING_TO_OTHER_TIMEZONE = true;
  /**
   *
   * @param date Date to use for reference when converting to another timezone
   * @param timezone Standard TZ identifier
   * @returns {Date} date transformed in the timezone provided
   * @example if current timezone is GMT+01:00
   * transformDateToTimezone(new Date("2020-01-01T09:00:00.000Z"), 'America/Toronto')
   *  -> 2023-08-07T14:00:00.000Z if America/Toronto is in GMT-04:00
   */
  const transformDateToTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ) => {
    return dayjs(date)
      .tz(timezone, KEEP_LOCAL_TIME_WHEN_CONVERTING_TO_OTHER_TIMEZONE)
      .toDate();
  };

  const transformAvailabilityToTimezone = (
    availability: ServiceRequestAvailabilityInput,
    timezone = defaultTimezone
  ) => {
    const dateFrom = transformDateToTimezone(
      `${availability.date as unknown as string} ${
        availability.from as unknown as string
      }`,
      timezone
    );
    const dateUntil = transformDateToTimezone(
      `${availability.date as unknown as string} ${
        availability.until as unknown as string
      }`,
      timezone
    );
    const dateFormatted = dayjs(dateFrom).tz('UTC').format('YYYY-MM-DD');
    const fromFormatted = dayjs(dateFrom).tz('UTC').format('HH:mm[Z]Z');
    const untilFormatted = dayjs(dateUntil).tz('UTC').format('HH:mm[Z]Z');
    return { date: dateFormatted, from: fromFormatted, until: untilFormatted };
  };

  const getTimeSlotInTimezone = (
    date: DayjsDateInput,
    timezone = defaultTimezone
  ): TimeSlot => {
    return {
      value: dayjs(date).tz(timezone).format('HH:mm'),
      label: dayjs(date).tz(timezone).format('h:mm A'),
      fullDate: dayjs(date).tz(timezone).format('YYYY-MM-DD HH:mm'),
    };
  };

  const getAvailabilityDaysByTentativeScheduleInTimezone = (
    tentativeSchedule: ServiceRequestTentativeSchedule,
    createdAt: DayjsDateInput,
    timezone = defaultTimezone
  ): Set<string> => {
    switch (tentativeSchedule) {
      case ServiceRequestTentativeSchedule.AsSoonAsPossible:
        return new Set(
          generateRange(
            dayjs(createdAt).tz(timezone).startOf('day'),
            dayjs(createdAt).tz(timezone).add(1, 'day').endOf('day'),
            1,
            'day'
          ).map((date) => date.format('YYYY-MM-DD'))
        );

      case ServiceRequestTentativeSchedule.WithinDays:
        return new Set(
          generateRange(
            dayjs(createdAt).tz(timezone).startOf('day'),
            dayjs(createdAt).tz(timezone).add(3, 'day').endOf('day'),
            1,
            'day'
          ).map((date) => date.format('YYYY-MM-DD'))
        );

      case ServiceRequestTentativeSchedule.WithinWeeks:
        return new Set(
          generateRange(
            dayjs(createdAt).tz(timezone).startOf('day'),
            dayjs(createdAt).tz(timezone).add(7, 'day').endOf('day'),
            1,
            'day'
          ).map((date) => date.format('YYYY-MM-DD'))
        );
      default:
        return new Set();
    }
  };

  return {
    formatDateDividerInTimezone,
    formatTimeInTimezone,
    formatAvailabilityInTimezone,
    formatDateAndTimeInTimezone,
    formatMonthDateInTimezone,
    formatIonDateTimeValueInTimezone,
    transformAvailabilityToTimezone,
    transformDateToTimezone,
    getTimeSlotInTimezone,
    getAvailabilityDaysByTentativeScheduleInTimezone,
  };
};
