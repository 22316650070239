import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';

export const CardItem = styled.div`
  background: radial-gradient(
    194.31% 83.79% at 11.62% 50%,
    #fff 0%,
    #f6f6f6 57.29%,
    #fff 100%
  );
  border: 1px solid #bdbdbd;
  border-radius: 10px;
`;

export const CardItemTitle = styled.div`
  font-size: ${() => pixelToRem(16)};
  font-weight: 600;
  line-height: 22px;
  color: var(--colors-primary-blue);
  margin-bottom: 4px;
`;
