import { IonCard, IonIcon, IonItem } from '@ionic/react';
import styled from 'styled-components';

export const BryaCardToggleIcon = styled(IonIcon)`
  font-size: 24px;
`;

export const IonItemStyled = styled(IonItem)`
  --inner-padding-end: 0;
  --min-height: 0;
  ::part(native) {
    height: 24px;
  }
`;

export const IonCardStyled = styled(IonCard)`
  margin-inline: 0;
`;
