import { IonButton, IonCard, IonContent, IonModal } from '@ionic/react';
import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import ChevronDown from '../../assets/chevron-down-outline.svg';

export type FontProps = {
  fontSize?: string;
  color?: string;
};

export type ButtonProps = {
  width?: number;
};

export type MainProps = {
  height?: number;
};

export const MainContainer = styled(IonContent)`
  form {
    ion-button {
      --color: #fff;
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 22px;
      letter-spacing: -0.4px;
    }

    ion-select::part(icon) {
      content: url(${ChevronDown});
    }
    ion-select::text {
      font-size: ${pixelToRem(14)};
    }
    ion-select-option {
      font-size: ${pixelToRem(14)};
    }
    ion-select {
      --placeholder-color: #212121;
      width: 100%;
      border: 1px solid #212121;
      border-radius: 14px;
      padding: 0 16px;
      min-height: 48px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    ion-label {
      font-style: normal;
      font-weight: 600;
      color: #000000;
      font-size: ${pixelToRem(14)};
      margin-left: 4px;
    }

    ion-textarea {
      background: #ffffff;
      border: 1px solid #212121;
      border-radius: 14px;
      padding: 8px 12px;
      min-height: 148px;
      font-weight: 400;
      font-size: ${pixelToRem(16)};
      line-height: 140%;
      color: #212121;
      margin-top: 4px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #212121;
  border-radius: 14px;
  padding: 2px 16px;
  margin-bottom: 20px;
  ion-input {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 140%;
    --color: #212121;
    --placeholder-color: #212121;
    --placeholder-font-weight: 400;
    --padding-start: 4px;
  }
`;

export const Title = styled.p<FontProps>`
  top: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(22)};
  line-height: 120%;
  text-align: center;
  color: #000000;
`;

export const Subtitle = styled.p<FontProps>`
  font-style: normal;
  font-weight: 700;
  font-size: ${pixelToRem(18)};
  line-height: 120%;
  color: #000000;
`;

export const Description = styled.p<FontProps>`
  width: '100%';
  padding-left: 16px;
  padding-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: #616161;
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const DescriptionNotCentered = styled.p<FontProps>`
  width: 95%;
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(14)};
  color: #616161;
`;

export const BottomButton = styled(IonButton)<ButtonProps>`
  height: 58px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
  --background-activated: #e59b62;
  --border-color: #ea8231;
  --border-radius: 50px;
`;

export const StyledIonModal = styled(IonModal)``;

export const BackButton = styled.button`
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: transparent;
`;

export const BackLabel = styled.span`
  font-size: ${pixelToRem(16)};
  color: #ea8231;
`;

export const ModalHeader = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

export const ErrorContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

export const ModalHeaderStep2 = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const ModalHeaderStep3 = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: center;
`;

export const RowContainer = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 28px;
`;

export const Divider = styled.div`
  height: 1px;
  background: #d9d9d9;
  width: 100%;
  margin: 0;
`;

export const SummaryLeft = styled.p<FontProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${pixelToRem(15)};
  color: #000;
`;

export const SummaryLeftSmall = styled.p<FontProps>`
  font-style: normal;
  font-weight: 600;
  font-size: ${pixelToRem(12)};
  color: #000;
`;

export const SummaryRight = styled.p<FontProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(15)};
  color: #000;
`;

export const ProvideInfoLabel = styled.p<FontProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${pixelToRem(12)};
  color: #616161;
`;

export const StyledIonCard = styled(IonCard)`
  width: 100%;
  background: #fff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0 0 16px;
  padding: 8px;
`;
