import * as React from 'react';

export const PaymentIssueAlert = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    className="card-status"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M12.5 2.53711C7.12391 2.53711 2.75 6.91102 2.75 12.2871C2.75 17.6632 7.12391 22.0371 12.5 22.0371C17.8761 22.0371 22.25 17.6632 22.25 12.2871C22.25 6.91102 17.8761 2.53711 12.5 2.53711ZM13.4375 17.5329H11.5625V15.6579H13.4375V17.5329ZM13.25 14.5371H11.75L11.4688 7.03711H13.5313L13.25 14.5371Z"
      fill="#EA4255"
    />
  </svg>
);
