import React, { ReactNode } from 'react';
import { Card, Divider } from './CardList.styles';

type CardListProps = {
  children: ReactNode[] | ReactNode;
};

export const CardList: React.FC<CardListProps> = ({ children }) => {
  return (
    <Card className="ion-padding">
      {Array.isArray(children)
        ? children.map((item, index) => {
            if (index === children.length - 1)
              return <React.Fragment key={index}>{item}</React.Fragment>;

            return (
              <React.Fragment key={index}>
                {item}
                <Divider className="ion-margin-vertical" />
              </React.Fragment>
            );
          })
        : children}
    </Card>
  );
};
