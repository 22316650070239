import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  GraphqlFeatureFlag,
  useGetAllFeatureFlagsQuery,
} from '../graphql/generated';
import { logger } from '../logger';
import { getApolloErrorMessage } from '../utils/apollo/errors';

interface IFeatureFlagsContext {
  isFeatureEnabled: (featureName: string) => boolean;
}
const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  isFeatureEnabled: () => false,
});

export const FeatureFlagsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [flags, setFlags] = useState<GraphqlFeatureFlag[]>([]);
  const { data, error } = useGetAllFeatureFlagsQuery();

  useEffect(() => {
    if (data) {
      setFlags(data.getAllFeatureFlags || []);
    } else {
      setFlags([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      logger.error({
        tag: '[FeatureFlagsProvider][fetchFlags]',
        message: `Fail while fetching feature flags: ${getApolloErrorMessage(
          error
        )}`,
        error: error as Error,
      });
    }
  }, [error]);

  const isFeatureEnabled = useCallback(
    (featureName: string) => {
      const featureFlag = flags.find((flag) => flag.feature === featureName);

      if (!featureFlag) {
        logger.warn({
          tag: '[FeatureFlagsProvider][isFeatureEnabled]',
          message: `Feature flag not found: ${featureName}`,
        });
      }
      return featureFlag?.enabled || false;
    },
    [flags]
  );

  return (
    <FeatureFlagsContext.Provider value={{ isFeatureEnabled }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
