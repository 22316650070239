import * as React from 'react';

function CardVisa(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={34}
      height={22}
      viewBox="0 0 34 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.056.853c.92 0 1.668.748 1.668 1.669v17.126a1.67 1.67 0 01-1.668 1.668H2.058a1.67 1.67 0 01-1.669-1.668V2.522c0-.92.749-1.669 1.669-1.669h29.998zm0-.332H2.058a2 2 0 00-2 2v17.127a2 2 0 002 2h29.998a2 2 0 002-2V2.522a2 2 0 00-2-2.001z"
        fill="#005098"
      />
      <path
        d="M12.675 15.323l1.438-8.439h2.301l-1.44 8.44h-2.299zM23.318 7.092a5.937 5.937 0 00-2.062-.354c-2.273 0-3.874 1.144-3.888 2.785-.013 1.212 1.143 1.889 2.016 2.293.895.413 1.196.677 1.193 1.046-.007.566-.716.824-1.377.824-.92 0-1.41-.127-2.165-.443l-.296-.133-.323 1.889c.537.235 1.53.44 2.562.45 2.418 0 3.987-1.132 4.005-2.884.01-.96-.603-1.69-1.93-2.292-.805-.391-1.297-.651-1.292-1.047 0-.35.417-.726 1.318-.726a4.23 4.23 0 011.72.324l.208.097.311-1.829zM29.216 6.892H27.44c-.551 0-.963.15-1.205.7l-3.416 7.734h2.416s.394-1.04.484-1.268l2.946.004c.068.295.28 1.264.28 1.264h2.134l-1.862-8.434zm-2.836 5.44c.19-.486.916-2.36.916-2.36-.013.023.19-.488.305-.805l.156.728.532 2.437h-1.91zM10.746 6.89l-2.252 5.756-.24-1.17c-.419-1.348-1.725-2.81-3.186-3.54l2.06 7.38 2.433-.002 3.622-8.424h-2.437z"
        fill="#005098"
      />
      <path
        d="M6.405 6.885h-3.71l-.028.176c2.885.698 4.795 2.387 5.586 4.415l-.805-3.878c-.14-.535-.543-.694-1.043-.713z"
        fill="#F6A500"
      />
    </svg>
  );
}

export default CardVisa;
