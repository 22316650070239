import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  IonModal,
  IonContent,
  IonIcon,
  IonItem,
  IonButton,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonList,
  isPlatform,
} from '@ionic/react';
import {
  GoToMapButtonLabel,
  MainContainer,
  TitleEnableLocation,
  ContentContainer,
  InputsContainer,
  ModalHeader,
  CustomIonInput,
  NoMatchesFoundLabel,
  NoMatchesFoundContainer,
  HomeItemSubtitle,
  HomeItemTitle,
} from './ModalAddAddress.style';
import { useTranslation } from 'react-i18next';
import {
  chevronForwardOutline,
  homeOutline,
  locationOutline,
  searchOutline,
  starOutline,
} from 'ionicons/icons';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { Geolocation } from '@capacitor/geolocation';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { DoneButton, DoneLabel } from '../../pages/locations/locations.styles';
import { useGetAddressesQuery } from '../../graphql/generated';
import { AuthContext } from '../../providers/authProvider';
import { RecentSearches } from '../../assets/images/RecentSearches';
import { Keyboard } from '@capacitor/keyboard';
import { StorageKey, getStorage, setStorage } from '../../utils/storage';
import AddressForm, {
  AddressesFormProps,
} from '../../pages/address-form/address-form';
import SavedPlacesPage from '../../pages/saved-places/saved-places';
import RecentSearchesPage from '../../pages/recent-searches/recent-searches';
import {
  getAddressLineFromGooglePlaces,
  getCityFromGooglePlaces,
  getPlaceFromGooglePlaces,
  getStateFromGooglePlaces,
  getZipCodeFromGooglePlaces,
} from '../../utils/googlePlaces';

type ModalAddressProps = {
  isOpenModalAddress: boolean;
  setModalToParent: (setModalIsOpen: boolean) => void;
  apiKey: string;
  getNewAddress?: (newaddress: AddressesFormProps) => void;
};

type MenuStaticItemsProps = {
  title: string;
  icon: string | null;
  customIcon: JSX.Element | null;
  route: string;
};

const ModalAddAddress: React.FC<ModalAddressProps> = ({
  setModalToParent,
  apiKey,
  isOpenModalAddress,
  getNewAddress,
}) => {
  const i18n = useTranslation();
  const { width, height } = useWindowDimensions();
  const [, setPermission] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [placeDetails, savePlaceDetailsToState] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [selectedAddressItem, setSelectedAddressItem] = useState<string>('');
  const [hiddenValues, setHiddenValues] = useState<boolean>(true);
  const [hiddenContent, setHiddenContent] = useState<boolean>(false);
  const [savedPlacesModalOpen, setSavedPlacesModalOpen] =
    useState<boolean>(false);
  const [recentSearchesModalOpen, setRecentSearchesModalOpen] =
    useState<boolean>(false);
  const [openConfirmAddressModal, setOpenConfirmAddressModal] =
    useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey,
    });
  const { data: getAddressQuery } = useGetAddressesQuery({
    variables: { customerId: user?.customerId ?? 0 },
  });

  if (isPlatform('hybrid')) {
    void Keyboard.addListener('keyboardWillShow', () => {
      setHiddenContent(true);
    });

    void Keyboard.addListener('keyboardWillHide', () => {
      setHiddenContent(false);
    });
  }

  const menuStaticItems: MenuStaticItemsProps[] = [
    {
      title: i18n.t('savedPlaces') ?? '',
      icon: starOutline,
      customIcon: null,
      route: '/saved-places',
    },
    {
      title: i18n.t('recentSearches') ?? '',
      icon: null,
      customIcon: <RecentSearches width={22} height={22} />,
      route: '/recent-searches',
    },
  ];
  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const checkedPermission = await Geolocation.checkPermissions();
        if (checkedPermission.coarseLocation === 'granted') {
          setPermission(true);
          setLoading(false);
        } else if (
          checkedPermission.coarseLocation === 'denied' ||
          checkedPermission.coarseLocation === 'prompt'
        ) {
          setPermission(false);
          setLoading(false);
        }
      } catch (error) {
        throw new Error('error');
      }
    };
    void fetchPermission().catch((e: string) => new Error(e));
  }, []);

  const goToConfirm = useCallback(() => {
    if (placeDetails && placeDetails?.address_components) {
      setOpenConfirmAddressModal(true);
    }
  }, [placeDetails]);

  const closeModal = useCallback(() => {
    setModalToParent(false);
  }, []);

  if (loading) {
    return (
      <IonContent className="ion-padding">
        <p>loading...</p>
      </IonContent>
    );
  }

  const addToRecentSearches = async () => {
    try {
      const { value } = await getStorage(StorageKey.RecentSearches);
      let addressLine1;
      let recentSearches = [];
      if (value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        recentSearches = JSON.parse(value);
        if (placeDetails && placeDetails?.address_components) {
          addressLine1 =
            placeDetails?.address_components[0]?.long_name +
            ' ' +
            placeDetails?.address_components[1]?.long_name +
            ', ' +
            placeDetails?.address_components[3]?.long_name;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        recentSearches.push(addressLine1);
        await setStorage(
          StorageKey.RecentSearches,
          JSON.stringify(recentSearches)
        );
      } else {
        if (placeDetails && placeDetails?.address_components) {
          addressLine1 =
            placeDetails?.address_components[0]?.long_name +
            ' ' +
            placeDetails?.address_components[1]?.long_name +
            ', ' +
            placeDetails?.address_components[3]?.long_name;
        }
        recentSearches.push(addressLine1);
        await setStorage(
          StorageKey.RecentSearches,
          JSON.stringify(recentSearches)
        );
      }
    } catch (error) {
      throw new Error('error');
    }
  };

  const renderItem = (
    item: google.maps.places.AutocompletePrediction,
    index: number
  ) => {
    return (
      <IonItem
        key={item.place_id}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          paddingTop: 14,
        }}
        button
        type="button"
        onClick={() => {
          placesService?.getDetails(
            {
              placeId: placePredictions[index].place_id,
            },
            (placeDetailsArg) => savePlaceDetailsToState(placeDetailsArg)
          );
          void setSelectedAddressItem(item.description);
          void setHiddenValues(true);
          void addToRecentSearches();
        }}
      >
        <IonIcon
          icon={locationOutline}
          style={{
            width: 20,
            height: 24,
            position: 'absolute',
          }}
        />
        <span style={{ marginLeft: 24 }}>{item.description}</span>
      </IonItem>
    );
  };

  const handleNewAddressToParentForm = (newAddress: AddressesFormProps) => {
    if (getNewAddress) {
      getNewAddress(newAddress);
    }
  };

  const decideOpeningModal = (route: string) => {
    if (route === '/saved-places') {
      setSavedPlacesModalOpen(true);
    } else {
      setRecentSearchesModalOpen(true);
    }
  };

  const dismissModals = () => {
    setOpenConfirmAddressModal(false);
    setModalToParent(false);
  };

  return (
    <IonContent className="ion-padding">
      <IonModal
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        isOpen={isOpenModalAddress}
        onDidDismiss={() => setModalToParent(false)}
      >
        {placeDetails?.address_components && openConfirmAddressModal && (
          <AddressForm
            addressLine1={getAddressLineFromGooglePlaces(placeDetails)}
            city={getCityFromGooglePlaces(placeDetails)}
            state={getStateFromGooglePlaces(placeDetails)}
            zipCode={getZipCodeFromGooglePlaces(placeDetails)}
            place={getPlaceFromGooglePlaces(placeDetails)}
            sendNewAddressToParentForm={handleNewAddressToParentForm}
            isOpen={openConfirmAddressModal}
            dismissModals={dismissModals}
          />
        )}
        {!openConfirmAddressModal && (
          <>
            <ModalHeader style={{ marginTop: 24 }}>
              <DoneButton onClick={closeModal}>
                <DoneLabel>{i18n.t('done')}</DoneLabel>
              </DoneButton>
            </ModalHeader>
            <MainContainer screenHeight={height}>
              <TitleEnableLocation>
                {getAddressQuery ? i18n.t('addOrChoose') : i18n.t('addAddress')}
              </TitleEnableLocation>
              <ContentContainer>
                {!hiddenContent && (
                  <div>
                    <IonItem
                      style={{
                        width: width * 0.95,
                        marginLeft: -16,
                        marginTop: -16,
                      }}
                    >
                      <IonIcon
                        icon={homeOutline}
                        slot="start"
                        style={{ position: 'absolute' }}
                        color="rgba(35, 54, 75, 1)"
                      ></IonIcon>
                      <IonLabel style={{ marginLeft: 40 }}>
                        <HomeItemTitle>
                          {getAddressQuery?.getAddresses[0]?.addressLine1}
                        </HomeItemTitle>
                        <HomeItemSubtitle>{i18n.t('home')}</HomeItemSubtitle>
                      </IonLabel>
                      <IonRadioGroup
                        value={'custom'}
                        slot="end"
                        style={{ marginRight: 6 }}
                      >
                        <IonRadio
                          value="custom"
                          aria-label="Custom checkbox"
                        ></IonRadio>
                      </IonRadioGroup>
                    </IonItem>
                    {menuStaticItems.map((field, index) => {
                      return (
                        <IonItem
                          key={index}
                          onClick={() => void decideOpeningModal(field.route)}
                          style={{ width: width * 0.95, marginLeft: -16 }}
                        >
                          {field.icon && (
                            <IonIcon
                              icon={field.icon}
                              slot="start"
                              style={{ position: 'absolute' }}
                              color="rgba(35, 54, 75, 1)"
                            ></IonIcon>
                          )}
                          {field.customIcon && (
                            <div style={{ position: 'absolute' }}>
                              {field.customIcon}
                            </div>
                          )}
                          <IonLabel style={{ marginLeft: 40 }}>
                            {field.title}
                          </IonLabel>
                          <IonIcon
                            icon={chevronForwardOutline}
                            slot="end"
                          ></IonIcon>
                        </IonItem>
                      );
                    })}
                  </div>
                )}
                <InputsContainer
                  screenHeight={height}
                  screenWidth={width}
                  isContentHidden={hiddenContent}
                >
                  <IonLabel
                    style={{
                      color: 'rgba(33, 33, 33, 1)',
                      marginBottom: 16,
                      marginLeft: 20,
                    }}
                  >
                    {i18n.t('address') ?? ''}
                  </IonLabel>
                  <IonItem lines="none">
                    <CustomIonInput
                      clearInput={true}
                      placeholder={i18n.t('searchAddress') ?? ''}
                      onFocus={() => void setHiddenValues(false)}
                      value={selectedAddressItem}
                      onIonInput={(evt) => {
                        getPlacePredictions({
                          input: evt.target.value as string,
                        });
                        void setSelectedAddressItem(evt.target.value as string);
                      }}
                    />
                    <IonIcon
                      slot="start"
                      icon={searchOutline}
                      style={{
                        position: 'absolute',
                        top: 12,
                        left: 30,
                        zIndex: 999,
                      }}
                    ></IonIcon>
                  </IonItem>
                  {!hiddenValues && (
                    <IonList
                      style={{
                        overflowY: 'scroll',
                      }}
                    >
                      {placePredictions.map((item, index) =>
                        renderItem(item, index)
                      )}
                    </IonList>
                  )}
                  {!hiddenValues &&
                    placePredictions.length == 0 &&
                    selectedAddressItem.length > 0 && (
                      <NoMatchesFoundContainer>
                        <NoMatchesFoundLabel>
                          {i18n.t('NoMatchesFound')}
                        </NoMatchesFoundLabel>
                      </NoMatchesFoundContainer>
                    )}
                </InputsContainer>

                <IonButton
                  style={{
                    height: 56,
                    borderRadius: 50,
                    marginTop: 16,
                  }}
                  shape={'round'}
                  onClick={goToConfirm}
                >
                  <GoToMapButtonLabel>{i18n.t('confirm')}</GoToMapButtonLabel>
                </IonButton>
              </ContentContainer>
            </MainContainer>
          </>
        )}
      </IonModal>
      <SavedPlacesPage
        isOpenSavedPlacesModal={savedPlacesModalOpen}
        openSavedPlacesModal={setSavedPlacesModalOpen}
      />
      <RecentSearchesPage
        isOpenRecentSearchessModal={recentSearchesModalOpen}
        openRecentSearchesModal={setRecentSearchesModalOpen}
      />
    </IonContent>
  );
};

export default ModalAddAddress;
