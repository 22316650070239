import React, { useContext, useEffect, useState } from 'react';
import { Header } from './activities.styles';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  useIonViewWillEnter,
} from '@ionic/react';
import ActivityPeriodSelector from '../../../components/ActivityPeriodSelector/ActivityPeriodSelector';
import ActivitiesTab from '../../../components/ActivitiesTab/ActivitiesTab';
import {
  useGetCustomerQuery,
  GetCustomerQuery,
  GlobalRole,
  useGetActivitiesAlertsQuery,
  ActivitiesStatus,
  ServiceRequestStatus,
  ActivitiesGroupedByDateOutput,
} from '../../../graphql/generated';
import { IUserData } from '../../../types';
import { useQuery } from '@apollo/client';
import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_GROUPED_BY_DATE,
} from '../../../graphql/activities';
import CircleMemberActivities from './circle-member-activities/circle-member-activities';
import AgentActivities from './agent-activities/AgentActivities';
import { AuthContext, useAuthContext } from '../../../providers/authProvider';
import PaymentAlertActionRequired from '../../../components/PaymentAlertActionRequired/PaymentAlertActionRequired';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../providers/featureFlagsProvider';
import { FeatureNames } from '../../../constants/enums';
import { useLocation } from 'react-router';
import { BodyText } from '../../../components/Typography/Body/Body';
import ModalSuggestActivity from '../../../components/ModalSuggestActivity/ModalSuggestActivity';

export interface ActivitiesProps {
  userData: IUserData | null;
}

type StateProps = {
  props?: {
    tab?: string;
  };
};

const NonCircleMemberActivities: React.FC<ActivitiesProps> = ({ userData }) => {
  const savedTab = localStorage.getItem('selectedTab');
  const location = useLocation();

  const openTab = (location.state as StateProps)?.props?.tab;

  const [customerName, setCustomerName] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(
    openTab || savedTab || 'scheduled'
  );
  const [filterType, setFilterType] = useState<string | null>(null);
  const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false);
  const [isSuggestActivityAlertOpen, setIsSuggestActivityAlertOpen] =
    useState<boolean>(false);
  const { user } = useAuthContext();
  const { isFeatureEnabled } = useFeatureFlags();
  const { t } = useTranslation();
  const { data: getActivitiesAlert, refetch: activitiesAlert } =
    useGetActivitiesAlertsQuery({
      skip: !user,
    });
  const { data, loading: loadingOpenActivities } = useQuery(GET_ACTIVITIES, {
    variables: {
      filter: { status: selectedTab, type: filterType },
      page: 1,
      pageSize: 100,
    },
    skip:
      selectedTab === ActivitiesStatus.Scheduled ||
      selectedTab === ActivitiesStatus.History,
  });
  const { data: dataWithTimeline, loading: loadGroupedActivities } = useQuery(
    GET_ACTIVITIES_GROUPED_BY_DATE,
    {
      variables: {
        filter: { status: selectedTab, type: filterType },
      },
      skip: selectedTab === ActivitiesStatus.Open,
    }
  );

  useIonViewWillEnter(() => {
    void activitiesAlert();
  });

  const hasActivityWithPaymentIssue = () => {
    (
      dataWithTimeline?.getActivitiesGroupedByDate
        ?.requests as ActivitiesGroupedByDateOutput[]
    )?.forEach((element) => {
      element?.activities?.forEach((item) => {
        if (item?.status === ServiceRequestStatus.PaymentAuthorizationFailed) {
          setHasPendingPayment(true);
          return;
        }
      });
    });
  };

  useEffect(() => {
    if (
      selectedTab === ActivitiesStatus.Scheduled ||
      selectedTab === ActivitiesStatus.History
    ) {
      hasActivityWithPaymentIssue();
    }
  }, [selectedTab, dataWithTimeline]);

  const { data: getCustomerQuery } = useGetCustomerQuery({
    variables: {
      customerId: userData?.customerId ?? 0,
    },
    skip: !userData?.customerId && !customerName,
  }) as { data: GetCustomerQuery };

  useEffect(() => {
    if (getCustomerQuery && !customerName) {
      const {
        getCustomer: { firstName },
      } = getCustomerQuery;
      setCustomerName(firstName as string);
    }
  }, [getCustomerQuery]);

  useEffect(() => {
    localStorage.setItem('selectedTab', selectedTab);
  }, []);

  const setSelectedTabWithLocalStorage = (value: string) => {
    localStorage.setItem('selectedTab', value);
    setSelectedTab(value);
  };

  return (
    <>
      <Header
        className={
          isFeatureEnabled(FeatureNames.ActivitiesFilter)
            ? undefined
            : 'ion-justify-content-center'
        }
      >
        <IonText>
          <h1>{t('ActivitiesTab.Title')}</h1>
        </IonText>
        {isFeatureEnabled(FeatureNames.ActivitiesFilter) && (
          <ActivityPeriodSelector
            hasPendingPayment={hasPendingPayment}
            selectedTab={selectedTab}
            filterType={filterType}
            setFilterType={setFilterType}
          />
        )}
      </Header>
      <IonGrid className="ion-margin-vertical">
        <IonRow className="ion-justify-content-center">
          <IonCol size="auto">
            <BodyText color="black" className="ion-text-center">
              {t('ActivitiesTab.SuggestActivityLabel')}
            </BodyText>
            <BodyText
              onClick={() => setIsSuggestActivityAlertOpen(true)}
              className="ion-no-margin ion-no-padding ion-text-center"
              color="var(--colors-primary-orange)"
            >
              {t('ActivitiesTab.SuggestActivityAction')}
            </BodyText>
          </IonCol>
        </IonRow>
      </IonGrid>
      {getActivitiesAlert?.getActivitiesAlerts &&
        getActivitiesAlert?.getActivitiesAlerts.length > 0 && (
          <PaymentAlertActionRequired
            serviceId={
              getActivitiesAlert.getActivitiesAlerts[
                getActivitiesAlert.getActivitiesAlerts.length - 1
              ].id
            }
            serviceStatus={
              getActivitiesAlert.getActivitiesAlerts[
                getActivitiesAlert.getActivitiesAlerts.length - 1
              ].status
            }
          />
        )}
      <ActivitiesTab
        isLoadingOpenActivities={loadingOpenActivities}
        isLoadingGroupedActivities={loadGroupedActivities}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTabWithLocalStorage}
        data={data}
        dataWithTimeline={dataWithTimeline}
      />
      <ModalSuggestActivity
        isOpen={isSuggestActivityAlertOpen}
        onClose={() => setIsSuggestActivityAlertOpen(false)}
      />
    </>
  );
};

const Activities: React.FC = () => {
  const { user } = useContext(AuthContext);

  if (!user) return null;

  if (user?.role === GlobalRole.CircleMember) {
    return <CircleMemberActivities />;
  }

  if (user?.role === GlobalRole.Agent || user?.role === GlobalRole.AdminAgent) {
    return <AgentActivities />;
  }

  return <NonCircleMemberActivities userData={user} />;
};
export default Activities;
