import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Space } from '../../../components/Space/Space';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActivityDetailItemDivider,
  ActivityDetailsCard,
} from '../../../components/ActivitiesDetailCard/ActivitiesDetailCard.styles';
import { ActivityDetailRow } from '../../../components/ActivitiesDetailCard/ActivitiesDetailCard';
import { HistoryIcon } from '../../../assets/images/HistoryIcon';
import ActivitiesHistory from '../../../components/ActivitiesHistory/ActivitiesHistory';
import { Button } from '../../../components/Button/Button';
import { useHistory } from 'react-router';
import IconActivityCancelled from '../../../assets/images/IconActivityCancelled';
import {
  GraphqlServiceRequestPayment,
  ServicePaymentType,
  ServiceRequestStatus,
  useConfirmStripeTransactionMutation,
  useGetServiceRequestQuery,
  useUpdateServiceRequestPaymentMutation,
  useUserCancelServiceRequestMutation,
} from '../../../graphql/generated';

import { formatNumber } from '../../../utils/formatNumber';
import {
  IonContent,
  IonIcon,
  IonModal,
  IonSkeletonText,
  IonSpinner,
} from '@ionic/react';
import {
  alertCircle,
  calendarClearOutline,
  chevronDown,
  informationCircleOutline,
  informationOutline,
  personOutline,
} from 'ionicons/icons';

import { DollarIcon } from '../../../assets/images/DollarIcon';
import PaymentDetails from '../../../components/ActivityStatus/PaymentDetails/PaymentDetails';
import { HomeOutline } from '../../../assets/images/HomeOutline';
import { StarIcon } from '../../../assets/images/StarIcon';
import { AuthContext } from '../../../providers/authProvider';
import { logger } from 'workbox-core/_private';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import CustomAlert from '../../../components/CustomAlert/CustomAlert';
import IconActivityConfirmed from '../../../assets/images/IconActivityConfirmed';
import { RequestType } from '../../../constants/enums';
import {
  StripeTransactionStatus,
  useGetOutstandingBalanceStatus,
} from '../../../utils/hooks/useGetOutstandingBalanceStatus';
import Badge from '../../../components/Badge/Badge';
import { StorageKey, getStorage, removeStorage } from '../../../utils/storage';
import { useAppContext } from '../../../providers/appContextProvider';
import { useTalkJsChat } from '../../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../../../components/FullScreenLoader/FullScreenLoader';
import { ProcessPayment } from '../../../components/ServiceSummary/ProcessPayment';
import { Subtitle, SummaryContainer } from './PaymentIssue.styles';
import { PageWithBottomSheetLayout } from '../../../components/PageWithBottomSheetLayout/PageWithBottomSheetLayout';
import { BodyText } from '../../../components/Typography/Body/Body';
import { HeaderBackButton } from '../../../components/HeaderBackButton/HeaderBackButton';
import { useDateWithTimezone } from '../../../utils/hooks/useDateWithTimezone';

interface IPaymentIssueProps {
  serviceRequestId?: number;
}
const PaymentIssue: React.FC<IPaymentIssueProps> = ({ serviceRequestId }) => {
  const router = useHistory();
  const i18n = useTranslation();
  const { globalAlert } = useAppContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [paymentUpdateModalParams, setPaymentUpdateModalParams] = useState<{
    icon: React.ReactNode | null;
    text: string;
    btnText: string;
    action: () => void;
  }>({
    icon: null,
    text: '',
    btnText: '',
    action: () => {},
  });
  const [disableUpdatePaymentButton, setDisableUpdatePaymentButton] =
    useState<boolean>(true);
  const [showProcessPaymentModal, setShowProcessPaymentModal] =
    useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [
    updateServiceRequestPaymentMutation,
    { loading: updateServiceRequestPaymentLoading },
  ] = useUpdateServiceRequestPaymentMutation();
  const showBadgeOutstandingBalance =
    useGetOutstandingBalanceStatus(serviceRequestId);

  const { formatDateAndTimeInTimezone } = useDateWithTimezone();

  const [confirmStripeTransactionMutation] =
    useConfirmStripeTransactionMutation();
  const [userCancelServiceRequestMutation] =
    useUserCancelServiceRequestMutation();

  const { data: getServiceRequestData, loading } = useGetServiceRequestQuery({
    variables: {
      getServiceRequestId: Number(serviceRequestId),
    },
  });

  const [paymentData, setPaymentData] = useState(
    getServiceRequestData?.getServiceRequest.ServiceRequestPayments
      ? getServiceRequestData?.getServiceRequest.ServiceRequestPayments[0]
      : null
  );
  useEffect(() => {
    if (getServiceRequestData?.getServiceRequest.ServiceRequestPayments) {
      setPaymentData(
        getServiceRequestData?.getServiceRequest.ServiceRequestPayments[0]
      );
    }

    return () => {};
  }, [getServiceRequestData?.getServiceRequest.ServiceRequestPayments]);

  useEffect(() => {
    const handleUpdatePaymentButton = async () => {
      const { value: lastSavedPayment } = await getStorage(
        StorageKey.LastSavedPaymentMethod
      );
      const { value: currentPayment } = await getStorage(
        StorageKey.CurrentSavedPaymentMethod
      );

      if (
        (lastSavedPayment &&
          currentPayment &&
          lastSavedPayment !== currentPayment) ||
        getServiceRequestData?.getServiceRequest.status ===
          ServiceRequestStatus.PaymentFailed
      ) {
        setDisableUpdatePaymentButton(false);
      }
    };
    void handleUpdatePaymentButton();
  }, [paymentData?.stripePaymentMethodId]);

  const decideSelectedPaymentType = useCallback(() => {
    if (showBadgeOutstandingBalance) {
      return i18n.t('StatusPage.paymentIssue.totalCustomerCost');
    }
    switch (paymentData?.type) {
      case ServicePaymentType.Hour:
        return i18n.t('paymentSetupModal.hourly');
      case ServicePaymentType.Fixed:
        return i18n.t('paymentSetupModal.setPrice');
      case ServicePaymentType.Visit:
        return i18n.t('paymentSetupModal.visitPrice');
      default:
        return i18n.t('activitySection.paymentMethod');
    }
  }, [paymentData]);

  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();

  const titleFormat = {
    loading: (
      <IonSkeletonText animated style={{ width: '100px', height: '29px' }} />
    ),
    content: getServiceRequestData?.getServiceRequest?.RequestCategory?.name,
  };

  const subtitleFormat = {
    loading: <IonSkeletonText animated style={{ width: '200px' }} />,
    content: `Requested by ${
      getServiceRequestData?.getServiceRequest?.RequestedBy?.fullName as string
    } for ${
      getServiceRequestData?.getServiceRequest?.Customer?.fullName as string
    }`,
  };

  const totalCustomerCost =
    getServiceRequestData?.getServiceRequest.ServiceRequestPayments?.reduce(
      (acc, payment) => {
        const totalAmount = payment.amount + payment.fee + payment.tax;
        acc = acc + totalAmount;
        return acc;
      },
      0
    );

  const extraPaymentData =
    getServiceRequestData?.getServiceRequest.ServiceRequestPayments &&
    getServiceRequestData?.getServiceRequest.ServiceRequestPayments?.length > 1
      ? (getServiceRequestData?.getServiceRequest
          .ServiceRequestPayments[1] as GraphqlServiceRequestPayment)
      : null;

  const showErrorPaymentCardIcon =
    extraPaymentData?.StripeTransaction?.status ===
      StripeTransactionStatus.RequiresPaymentMethod &&
    extraPaymentData?.stripePaymentMethodId &&
    extraPaymentData?.stripePaymentMethodId !==
      paymentData?.stripePaymentMethodId;

  const isARequesterUser = () => {
    return (
      getServiceRequestData?.getServiceRequest?.RequestedBy?.id === user?.id
    );
  };
  const updatePayment = async () => {
    if (
      getServiceRequestData?.getServiceRequest.status ===
      ServiceRequestStatus.PaymentFailed
    ) {
      setShowProcessPaymentModal(true);
    } else {
      try {
        await updateServiceRequestPaymentMutation({
          variables: {
            serviceRequestPaymentId: paymentData?.id as number,
            servicePaymentInput: {
              stripePaymentMethodId: paymentData?.stripePaymentMethodId,
            },
          },
        });
        setPaymentUpdateModalParams({
          icon: <IconActivityConfirmed />,
          text: i18n.t('payment.paymentUpdateSuccess'),
          btnText: i18n.t('cta.continue'),
          action: () =>
            void confirmStripeTransactionMutation({
              variables: {
                stripeTransactionId: paymentData?.stripeTransactionId as string,
              },
            })
              .then(() => {
                router.replace('/activityStatus', {
                  status: ServiceRequestStatus.Confirmed,
                  serviceRequestId: getServiceRequestData?.getServiceRequest
                    ?.id as number,
                  requestType: RequestType.Service,
                  title:
                    getServiceRequestData?.getServiceRequest?.RequestCategory
                      ?.name,
                });
              })
              .catch((err) => {
                globalAlert({
                  title: <Trans i18nKey="genericError.title" />,
                  subtitle: getApolloErrorMessage(err) || (
                    <Trans i18nKey="genericError.subtitle" />
                  ),
                  firstButtonLabel: (
                    <Trans i18nKey="genericError.primaryLabel" />
                  ),
                });
                logger.error({
                  tag: '[CONFIRM_STRIPE_TRANSACTION]',
                  message: 'Error on confirmStripeTransactionMutation',
                  error: err as Error,
                });
                setPaymentModal(false);
              }),
        });
        setPaymentModal(true);
      } catch (err) {
        setPaymentUpdateModalParams({
          icon: <IconActivityCancelled />,
          text: i18n.t('payment.paymentUpdateFail'),
          btnText: i18n.t('cta.tryAgain'),
          action: () => {
            setDisableUpdatePaymentButton(true);
            setPaymentModal(false);
          },
        });
        setPaymentModal(true);
        void removeStorage(StorageKey.CurrentSavedPaymentMethod);
        logger.error({
          tag: '[UPDATE_SERVICE_REQUEST_PAYMENT]',
          message: `Fail trying to update service request payment: ${getApolloErrorMessage(
            err
          )}`,
          error: err as Error,
        });
      }
    }
  };

  return (
    <PageWithBottomSheetLayout
      title={loading ? titleFormat.loading : titleFormat.content}
      subtitle={loading ? subtitleFormat.loading : subtitleFormat.content}
      bottomSheetTitle={
        <>
          <IconActivityCancelled />
          <Trans i18nKey="StatusPage.paymentIssue.title" />
        </>
      }
      startSlotHeaderAction={
        <HeaderBackButton onClick={() => router.goBack()} />
      }
      background={
        'var(--linear-error, linear-gradient(267deg, #FF5E70 -0.42%, #EA4255 57.59%, #D21329 100.84%));'
      }
      bottomSheetBackground={`linear-gradient(180deg, #FFE4E2 0%, #FFF 98.45%);`}
    >
      {getBryaTeamChatQueryLoading && <FullScreenLoader />}
      <Space size="large" direction="column">
        <BodyText color="var(--body-text-700)">
          <Trans i18nKey={`StatusPage.paymentIssue.description`} />
        </BodyText>
        <ActivityDetailsCard>
          <Space direction="column" size="18px">
            <ActivityDetailRow
              icon={
                <IonIcon
                  icon={personOutline}
                  style={{
                    color: '#23364B',
                    width: 24,
                    height: 24,
                  }}
                />
              }
              secondaryIcon={
                loading ? (
                  <IonSkeletonText
                    animated
                    style={{ width: '10%', marginLeft: 8 }}
                  />
                ) : (
                  <StarIcon />
                )
              }
              description={
                loading ? (
                  <IonSkeletonText animated style={{ width: '100%' }} />
                ) : (
                  `${
                    getServiceRequestData?.getServiceRequest?.Agent?.User
                      ?.fullName as string
                  } ${
                    getServiceRequestData?.getServiceRequest?.Agent
                      ?.rating as number
                  }`
                )
              }
            />
            <ActivityDetailItemDivider />
            <ActivityDetailRow
              icon={<HomeOutline width={24} height={24} />}
              description={
                loading ? (
                  <IonSkeletonText animated style={{ width: '100%' }} />
                ) : (
                  `${
                    getServiceRequestData?.getServiceRequest?.Address
                      ?.addressLine1 as string
                  }, ${
                    getServiceRequestData?.getServiceRequest?.Address
                      ?.city as string
                  }`
                )
              }
            />
            <ActivityDetailItemDivider />
            <ActivityDetailRow
              icon={
                <IonIcon
                  icon={calendarClearOutline}
                  style={{
                    color: '#23364B',
                    width: 24,
                    height: 24,
                  }}
                />
              }
              description={
                loading ? (
                  <IonSkeletonText animated style={{ width: '100%' }} />
                ) : (
                  formatDateAndTimeInTimezone(
                    getServiceRequestData?.getServiceRequest.scheduledAt
                  )
                )
              }
            />
            <ActivityDetailItemDivider />
            <ActivityDetailRow
              icon={<DollarIcon />}
              secondaryIcon={
                loading ? (
                  <IonSkeletonText
                    animated
                    style={{ width: '10%', marginLeft: 8 }}
                  />
                ) : showErrorPaymentCardIcon ? (
                  <IonIcon
                    icon={alertCircle}
                    style={{
                      color: '#EA4255',
                      width: 24,
                      height: 24,
                    }}
                  />
                ) : null
              }
              description={
                loading ? (
                  <IonSkeletonText animated style={{ width: '100%' }} />
                ) : (
                  <div>
                    {decideSelectedPaymentType()}: $
                    {formatNumber(totalCustomerCost as number)}
                    {showBadgeOutstandingBalance && (
                      <Badge icon="!" variant="warning">
                        {i18n.t('StatusPage.paymentIssue.outstandingBalance')}
                      </Badge>
                    )}
                  </div>
                )
              }
              collapse={{
                defaultExpanded: false,
                children: !loading && (
                  <PaymentDetails
                    setDisableUpdatePaymentButton={
                      setDisableUpdatePaymentButton
                    }
                    disableUpdatePaymentButton={disableUpdatePaymentButton}
                    isARequesterUser={isARequesterUser}
                    paymentData={paymentData as GraphqlServiceRequestPayment}
                    extraPaymentData={
                      getServiceRequestData?.getServiceRequest
                        .ServiceRequestPayments &&
                      getServiceRequestData?.getServiceRequest
                        .ServiceRequestPayments?.length > 1
                        ? (getServiceRequestData?.getServiceRequest
                            .ServiceRequestPayments[1] as GraphqlServiceRequestPayment)
                        : null
                    }
                    cardHolderName={
                      getServiceRequestData?.getServiceRequest?.RequestedBy
                        ?.fullName as string
                    }
                  />
                ),
              }}
            />

            {getServiceRequestData?.getServiceRequest?.commentForCustomer && (
              <>
                <ActivityDetailItemDivider />
                <ActivityDetailRow
                  icon={
                    <IonIcon
                      icon={informationCircleOutline}
                      style={{ fontSize: 28 }}
                    />
                  }
                  description={'Summary'}
                  collapse={{
                    defaultExpanded: false,
                    children: (
                      <SummaryContainer>
                        <Subtitle>
                          {
                            getServiceRequestData?.getServiceRequest
                              ?.commentForCustomer
                          }
                        </Subtitle>
                      </SummaryContainer>
                    ),
                  }}
                />
              </>
            )}

            <ActivityDetailItemDivider />

            <ActivityDetailRow
              icon={<HistoryIcon />}
              description={'Request History'}
              collapse={{
                defaultExpanded: false,
                children: (
                  <ActivitiesHistory
                    user={user}
                    timeline={
                      getServiceRequestData?.getServiceRequest?.Timeline as []
                    }
                  />
                ),
              }}
            />
          </Space>
        </ActivityDetailsCard>

        <Space direction="column" size="medium">
          {isARequesterUser() && (
            <Button
              color="primary-red"
              onClick={updatePayment}
              disabled={disableUpdatePaymentButton}
            >
              <Trans i18nKey="StatusPage.paymentIssue.actions.updatePayment" />
              {updateServiceRequestPaymentLoading && (
                <IonSpinner name="lines-small" />
              )}
            </Button>
          )}

          {getServiceRequestData?.getServiceRequest.status ===
            ServiceRequestStatus.PaymentAuthorizationFailed && (
            <Button
              color={'secondary-red'}
              onClick={() => setModalOpen(true)}
              disabled={false}
            >
              <Trans i18nKey="cancelRequest" />
            </Button>
          )}
          <Button
            color={'secondary-red'}
            onClick={handleGetBryaTeamChat}
            disabled={false}
          >
            <Trans i18nKey="StatusPage.paymentIssue.actions.contactSupport" />
          </Button>
        </Space>
      </Space>
      <CustomAlert
        icon={paymentUpdateModalParams?.icon}
        isDisabled={false}
        isOpen={paymentModal}
        onDidDismiss={() => setPaymentModal(false)}
        title={paymentUpdateModalParams?.text}
        firstButtonLabel={paymentUpdateModalParams?.btnText}
        firstButtonAction={paymentUpdateModalParams?.action}
      />
      <CustomAlert
        showTextArea={true}
        isOpen={modalOpen}
        onDidDismiss={() => setModalOpen(false)}
        title={i18n.t('cancelRequestBtnTitle')}
        subtitle={i18n.t('cancelRequestBtnSubtitle')}
        firstButtonLabel={i18n.t('yesCancelButton')}
        secondButtonLabel={i18n.t('noCancelButton')}
        firstButtonAction={() => {
          void userCancelServiceRequestMutation({
            variables: {
              cancellationReason: cancelReason,
              serviceRequestId: getServiceRequestData?.getServiceRequest
                ?.id as number,
            },
          }).then(() => {
            setModalOpen(false);
            router.replace('/activityStatus', {
              title:
                getServiceRequestData?.getServiceRequest?.RequestCategory?.name,
              requestType: RequestType.Service,
              serviceRequestId: getServiceRequestData?.getServiceRequest
                ?.id as number,
              status: ServiceRequestStatus.Cancelled,
              typeChange: {
                iconStartName: informationOutline,
                iconEndName: chevronDown,
                title: 'Reason',
                ratingValue: '',
                readOnly: false,
                reason: cancelReason,
              },
            });
          });
        }}
        onTextAreaChange={(value) => setCancelReason(value)}
        secondButtonAction={() => setModalOpen(false)}
      />

      {showProcessPaymentModal && (
        <IonModal
          initialBreakpoint={0.7}
          breakpoints={[0, 0.7, 1]}
          isOpen={showProcessPaymentModal}
          onDidDismiss={() => {
            setShowProcessPaymentModal(false);
          }}
        >
          <IonContent className="ion-padding">
            <ProcessPayment
              setNewStep={() => {
                setShowProcessPaymentModal(false);
                router.replace('/activityStatus', {
                  status: ServiceRequestStatus.Completed,
                  serviceRequestId: getServiceRequestData?.getServiceRequest
                    ?.id as number,
                  requestType: RequestType.Service,
                  title:
                    getServiceRequestData?.getServiceRequest?.RequestCategory
                      ?.name,
                });
              }}
              serviceSummaryData={getServiceRequestData}
              onClose={() => {
                setShowProcessPaymentModal(false);
              }}
            />
          </IonContent>
        </IonModal>
      )}
    </PageWithBottomSheetLayout>
  );
};

export default PaymentIssue;
