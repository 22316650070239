/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Variants, motion } from 'framer-motion';

import {
  MainContainer,
  PageHelp,
  ContinueButton,
  NeedHelp,
  ContactOur,
  SupportLink,
  CustomToast,
} from './verify-email.style';

import { useResendVerifyEmailMutation } from '../../graphql/generated';
import { useAuthContext } from '../../providers/authProvider';
import { MainTitle } from '../../components/Typography/Headings/Headings';
import { BodyText } from '../../components/Typography/Body/Body';
import { HeaderWithAction } from '../../components/Layout/HeaderWithAction';
import { ROUTES } from '../../constants/routes';

const VerifyEmail: React.FC = () => {
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    type: 'success',
  });
  const [counter, setCounter] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const router = useHistory();
  const { t } = useTranslation();
  const [resendVerifyEmail, { loading }] = useResendVerifyEmailMutation();
  const { user, logout, refreshToken } = useAuthContext();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [counter]);

  useEffect(() => {
    if (user?.isEmailVerified) {
      router.replace('/');
    }
  }, [user, router]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [counter]);

  useEffect(() => {
    if (user?.isEmailVerified) {
      router.replace('/');
    }
  }, [user, router]);

  useEffect(() => {
    const intervalId = setInterval(refreshToken, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const goBackToLogin = async () => {
    try {
      await logout();
    } finally {
      router.replace(ROUTES.LOGIN);
    }
  };

  const handleOnResend = useCallback(async () => {
    try {
      if (user?.email) {
        await resendVerifyEmail({ variables: { email: user?.email } });
        setToast({
          isOpen: true,
          type: 'success',
          message: t('VerifyEmail.successMessage'),
        });
        setIsButtonDisabled(true);
        setCounter(120);
      }
    } catch (error) {
      setToast({
        isOpen: true,
        type: 'danger',
        message: t('VerifyEmail.errorMessage'),
      });
    }
  }, []);

  const parentVariant: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const childrenVariant: Variants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <IonPage>
      <HeaderWithAction
        actionText={<Trans i18nKey="VerifyEmail.backCta" />}
        onAction={goBackToLogin}
      />
      <IonContent>
        <motion.div
          initial="initial"
          animate="animate"
          variants={parentVariant}
        >
          <MainContainer className="ion-padding">
            <motion.div
              variants={childrenVariant}
              style={{ marginTop: 48, padding: 3 }}
            >
              <MainTitle>{t('VerifyEmail.verifyYourEmail')}</MainTitle>
            </motion.div>
            <motion.div
              variants={childrenVariant}
              style={{ marginTop: 24, padding: 3 }}
            >
              <BodyText>{t('VerifyEmail.verifyEmailMessage')}</BodyText>
            </motion.div>
            <div style={{ marginTop: 24 }}>
              <motion.div variants={childrenVariant}>
                <PageHelp>{t('VerifyEmail.didNotGetEmail')}</PageHelp>
              </motion.div>
            </div>
            <ContinueButton
              onClick={handleOnResend}
              disabled={loading || isButtonDisabled}
            >
              {t('VerifyEmail.resendEmail')}{' '}
              {isButtonDisabled && `in ${counter}s`}
            </ContinueButton>
            <motion.div variants={childrenVariant} style={{ marginTop: 24 }}>
              <NeedHelp>{t('VerifyEmail.needHelp')}</NeedHelp>
              <ContactOur>{t('VerifyEmail.contactOur')}</ContactOur>
              <SupportLink href="mailto:support@brya.com">
                support@brya.com
              </SupportLink>
            </motion.div>
            <CustomToast
              isOpen={toast.isOpen}
              message={t('VerifyEmail.successMessage')}
              onDidDismiss={() => {
                setToast({ isOpen: false, message: '', type: '' });
              }}
              duration={2000}
              position="top"
              color={toast.type}
            ></CustomToast>
          </MainContainer>
        </motion.div>
      </IonContent>
    </IonPage>
  );
};

export default VerifyEmail;
