import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import { HeaderWithAction } from '../../../components/Layout/HeaderWithAction';
import { SecondaryTitle } from '../../../components/Typography/Headings/Headings';
import { Trans } from 'react-i18next';
import { Variants, motion } from 'framer-motion';
import { useRequestToJoinCircleMutation } from '../../../graphql/generated';
import { useAppContext } from '../../../providers/appContextProvider';
import { logger } from '../../../logger';
import { getApolloErrorMessage } from '../../../utils/apollo/errors';
import { BodyText } from '../../../components/Typography/Body/Body';
import { AboutFriendForm } from '../../../components/Forms/AboutFriendForm';
import { ROUTES } from '../../../constants/routes';
import { useHistory } from 'react-router';
import { useFormId } from '../../../utils/hooks/useFormId';

const animation: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const MembershipAboutFriend: React.FC = () => {
  const [requestToJoinCircle] = useRequestToJoinCircleMutation();
  const { globalAlert } = useAppContext();
  const history = useHistory();
  const ionRouter = useIonRouter();
  const { formId } = useFormId();

  return (
    <IonPage>
      <HeaderWithAction />
      <IonContent className="ion-padding">
        <motion.div initial="initial" animate="animate" variants={animation}>
          <IonGrid>
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <SecondaryTitle>
                  <Trans i18nKey="membership.aboutFriend.title" />
                </SecondaryTitle>
              </IonCol>
            </IonRow>
            <IonRow className="ion-padding-vertical">
              <IonCol>
                <BodyText>
                  <Trans i18nKey="membership.aboutFriend.subtitle" />
                </BodyText>
              </IonCol>
            </IonRow>
            <AboutFriendForm
              key={formId}
              onSubmit={async (data) => {
                try {
                  const joinCircleResponse = await requestToJoinCircle({
                    variables: {
                      requestToJoinCircleInput: {
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                      },
                    },
                  });

                  if (joinCircleResponse.data?.requestToJoinCircle.match) {
                    // NOTE: this ensures form is clean upon navigation
                    ionRouter.push(
                      ROUTES.MEMBERSHIP.MATCH_FRIEND,
                      'forward',
                      'push',
                      { unmount: true }
                    );
                  } else {
                    history.push(ROUTES.MEMBERSHIP.NOT_MATCH_FRIEND, {
                      firstName: data.firstName,
                      lastName: data.lastName,
                      email: data.email,
                      phoneNumber: data.phoneNumber,
                    });
                  }
                } catch (err) {
                  logger.error({
                    tag: '[ABOUT_FRIEND_FORM_SUBMIT]',
                    message: `Fail trying submitting about friend form: ${getApolloErrorMessage(
                      err
                    )}`,
                    error: err as Error,
                  });
                  globalAlert({
                    title: <Trans i18nKey="genericError.title" />,
                    subtitle: getApolloErrorMessage(err) || (
                      <Trans i18nKey="genericError.subtitle" />
                    ),
                    firstButtonLabel: (
                      <Trans i18nKey="genericError.primaryLabel" />
                    ),
                  });
                }
              }}
            />
          </IonGrid>
        </motion.div>
      </IonContent>
    </IonPage>
  );
};

export default MembershipAboutFriend;
