import * as React from 'react';

export const GreenCheckMark = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="77"
    height="76"
    viewBox="0 0 77 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      width="76"
      height="76"
      rx="38"
      fill="#3A868C"
      fillOpacity="0.25"
    />
    <rect
      x="8.5"
      y="8"
      width="60"
      height="60"
      rx="30"
      fill="#3A868C"
      fillOpacity="0.5"
    />
    <rect x="16.5" y="16" width="44" height="44" rx="22" fill="#3A868C" />
    <path
      d="M49.75 29.0007L34 47.0007L27.25 40.2507"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
