import * as React from 'react';

export const HomeIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.12506 8.28259V17.5013C3.12506 17.6671 3.19091 17.8261 3.30812 17.9433C3.42533 18.0605 3.5843 18.1263 3.75006 18.1263H7.50006V12.8138C7.50006 12.5652 7.59883 12.3267 7.77465 12.1509C7.95046 11.9751 8.18892 11.8763 8.43756 11.8763H11.5626C11.8112 11.8763 12.0497 11.9751 12.2255 12.1509C12.4013 12.3267 12.5001 12.5652 12.5001 12.8138V18.1263H16.2501C16.4158 18.1263 16.5748 18.0605 16.692 17.9433C16.8092 17.8261 16.8751 17.6671 16.8751 17.5013V8.28259"
      stroke="url(#paint0_linear_2620_18659)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.75 10.0029L10.4254 2.03411C10.2301 1.82786 9.77344 1.82551 9.57461 2.03411L1.25 10.0029M15.625 6.99504V2.50286H13.75V5.19817"
      stroke="url(#paint1_linear_2620_18659)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2620_18659"
        x1="16.6228"
        y1="3.71228"
        x2="2.08467"
        y2="4.709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#084282" />
        <stop offset="0.411458" stopColor="#00356F" />
        <stop offset="1" stopColor="#00244A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2620_18659"
        x1="18.4289"
        y1="-1.89346"
        x2="0.0443842"
        y2="0.0502215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#084282" />
        <stop offset="0.411458" stopColor="#00356F" />
        <stop offset="1" stopColor="#00244A" />
      </linearGradient>
    </defs>
  </svg>
);
