import {
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { BryaLogo } from '../../assets/images/BryaLogo';
import {
  Container,
  NotificationBellContainer,
  NotificationBellCounter,
} from './BryaHeader.styles';
import { NotificationBell } from '../../assets/images/NotificaitionBell';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useNotifications } from '../../providers/notificationsProvider';
import { Trans } from 'react-i18next';
import { useAuthContext } from '../../providers/authProvider';
import { UserAvatar } from '../UserAvatar/UserAvatar';
import { GraphqlUser } from '../../graphql/generated';

const BryaHeader: React.FC = () => {
  const { user } = useAuthContext();
  const history = useHistory();
  const menuRef = useRef<HTMLIonMenuElement>(null);
  const { unReadNotificationsCount } = useNotifications();

  useEffect(() => {
    return () => {
      void menuRef?.current?.close();
    };
  }, [history?.location.pathname]);
  return (
    <Container>
      <IonHeader id="menu-id" className="ion-no-border" translucent={true}>
        <IonToolbar color="#ffffff">
          <IonButtons slot="start">
            <IonButton>
              <UserAvatar user={user as unknown as GraphqlUser} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <BryaLogo width={84} height={32} />
          </IonTitle>
          <IonButtons slot="end">
            <NotificationBellContainer
              hasUnreadNotification={
                unReadNotificationsCount > 0 ? true : false
              }
              onClick={() => history.push('/notification-center')}
            >
              {unReadNotificationsCount > 0 && (
                <NotificationBellCounter>
                  {unReadNotificationsCount}{' '}
                  <Trans i18nKey="Notifications.new" />
                </NotificationBellCounter>
              )}
              <NotificationBell />
            </NotificationBellContainer>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </Container>
  );
};

export default BryaHeader;
