import { IonList, IonSearchbar } from '@ionic/react';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: relative;
`;

const INPUT_HEIGHT = `48px`;

export const SearchList = styled(IonList)`
  position: absolute;
  width: 100%;
  top: ${INPUT_HEIGHT};
  z-index: 1000;
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
`;

export const StyledSearchbar = styled(IonSearchbar)`
  .searchbar-input-container {
    height: 48px;
  }
`;
