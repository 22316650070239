import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import { IonContent } from '@ionic/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  background: #ffffff;
  border-radius: 14px;
  justify-content: space-between;
  padding: 0 12px;
  margin: 16px 24px 0;
  background: #f4f6f6;
  border: 1px solid #e0e0e0;
  border-radius: 14px;
`;

export const SlotContent = styled.div`
  margin-bottom: 8px;
`;
export const ShowCalendarContainer = styled.div`
  text-align: center;

  .show-calendar {
    font-weight: 400;
    font-size: ${pixelToRem(16)};
    line-height: 22px;
    letter-spacing: -0.4px;
    --color: #23364b !important;
    margin: 0;
  }
`;
export const StyledIonContent = styled(IonContent)`
  height: 100vh;
  --padding-bottom: 300px;
`;

export const CalendarContainer = styled.div`
  ion-datetime {
    --background-color: red;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    max-width: 100%;
    margin: 0 24px 30px;

    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.87);
  }
`;
