/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IonLabel,
  IonDatetime,
  isPlatform,
  IonSpinner,
  IonToast,
} from '@ionic/react';
import {
  IonAccordContainer,
  MainContainer,
  RoundedButton,
  StarLabelContainer,
  StyledIcon,
  StyledIonAccordion,
  StyledItem,
} from './ActivitiesSections.styles';
import {
  calendarClearOutline,
  checkmarkCircle,
  heartCircleOutline,
  homeOutline,
  informationOutline,
  personOutline,
  clipboardOutline,
} from 'ionicons/icons';
import React, { useMemo, useRef, useState } from 'react';
import ActivitiesHistory from '../ActivitiesHistory/ActivitiesHistory';
import {
  ActivityCard,
  Subtitle,
} from '../ActivitiesHistory/ActivitiesHistory.styles';
import { DollarIcon } from '../../assets/images/DollarIcon';
import { HistoryIcon } from '../../assets/images/HistoryIcon';
import { StarIcon } from '../../assets/images/StarIcon';
import { useHistory, useLocation } from 'react-router';
import { ActivityStatusEnum } from '../../pages/activity-status/activity-statusEnum';
import CustomAlert from '../CustomAlert/CustomAlert';
import { useTranslation } from 'react-i18next';
import { ActivityStatus } from '../ActivityStatus/StatusComponent.interface';
import {
  ApprovalServiceRequestStatus,
  GraphqlServiceRequest,
  GraphqlServiceRequestPayment,
  GraphqlTimeline,
  ServiceRequestStatus,
  useUserApprovalServiceRequestMutation,
} from '../../graphql/generated';
import ActivitiesRideRequest from '../ActivitiesRideRequest/ActivitiesRideRequest';
import { StyledCircleAvatar } from '../ActivityStatus/ActivityStatus.styles';
import { parseISOLocal } from '../../utils/date/parseISOLocal';
import ModalPostVisitCosts from '../ModalPostVisitCosts/ModalPostVisitCosts';
import { GenericEnum } from '../../constants/enums';
import PaymentDetails from '../ActivityStatus/PaymentDetails/PaymentDetails';
import { useAuthContext } from '../../providers/authProvider';
import { BryaPartnerVisitNotesModal } from '../BryaPartnerVisitNotesModal/BryaPartnerVisitNotesModal';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

interface ActivitiesSectionsProps {
  itemLists: {
    iconStartName: any;
    iconEndName: any;
    title?: string | JSX.Element;
    boldTitle?: string | null;
    subTitle?: string;
    ratingValue?: string | number;
    readOnly?: boolean;
    customIcon?: boolean;
    customClass?: boolean;
    disabled?: boolean;
    reason?: string;
    isDateSelected?: boolean;
    withBadge?: boolean;
    historyTimeline?: GraphqlTimeline[];
    circleAvatarAbbrev?: {
      firstName: string;
      lastName: string;
    };
    calendarValue?: string | null;
    handler?: () => void;
    onSubtitleClick?: () => Promise<void>;
  }[];
  rideOptions?: {
    id: number;
    title: string;
    subtitle: string;
  }[];
  onCallbackChangeDate?: (value: string) => void;
  serviceRequestId?: number;
  requestType?: string;
  showSubmitChange?: (value: boolean) => void;
  agentId?: number;
  category?: string;
  status?: string;
  serviceRequestPayment?: GraphqlServiceRequestPayment | null;
  serviceRequest?: GraphqlServiceRequest | null;
  cancelledReason?: string | null;
}

const ActivitiesSections: React.FC<ActivitiesSectionsProps> = ({
  itemLists: initialItems,
  onCallbackChangeDate = () => {},
  showSubmitChange = () => {},
  serviceRequestId = 0,
  requestType,
  rideOptions,
  agentId,
  category,
  status,
  serviceRequestPayment,
  serviceRequest,
  cancelledReason,
}) => {
  const { formatDateAndTimeInTimezone, transformDateToTimezone } =
    useDateWithTimezone();
  const itemListsRef = useRef(initialItems);
  const [userApprovalServiceRequestMutation] =
    useUserApprovalServiceRequestMutation();
  const i18n = useTranslation();
  const [selectedDate, setSelectedDate] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [openPostVisitModal, setOpenPostVisitModal] = useState(false);
  const [openBryaPartnerVisitNotesModal, setOpenBryaPartnerVisitNotesModal] =
    useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [isSubtitleActionLoading, setIsSubtitleActionLoading] =
    useState<boolean>(false);
  const location = useLocation<ActivityStatus>();
  const { user } = useAuthContext();
  const accordContainerClass = isPlatform('ios')
    ? ''
    : 'accordion-group-expand-inset';
  const history = useHistory();
  const formattedDate = useMemo(
    () =>
      selectedDate
        ? formatDateAndTimeInTimezone(
            // NOTE: first transform to match date and time selected by the user
            // in the target timezone
            transformDateToTimezone(parseISOLocal(selectedDate))
          )
        : '',
    [selectedDate, formatDateAndTimeInTimezone, transformDateToTimezone]
  );

  const isDollarIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName === DollarIcon;
  const isHistoryIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName === HistoryIcon;
  const isDefaultIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName !== HistoryIcon &&
    item.iconStartName !== DollarIcon &&
    !item.circleAvatarAbbrev &&
    item.iconStartName !== heartCircleOutline;
  const isCoachIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName !== HistoryIcon &&
    item.iconStartName !== DollarIcon &&
    !item.circleAvatarAbbrev &&
    item.iconStartName === heartCircleOutline;
  const isCalendarIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName === calendarClearOutline;
  const isInformationIcon = (
    item: ActivitiesSectionsProps['itemLists'][number]
  ) => item.iconStartName === informationOutline;
  const isHomeIcon = (item: ActivitiesSectionsProps['itemLists'][number]) =>
    item.iconStartName === homeOutline;
  const hasSeeProfileButton = (
    option: ActivitiesSectionsProps['itemLists'][number]
  ) =>
    requestType !== 'coachVisit' &&
    option.subTitle &&
    requestType !== 'circle' &&
    option.iconStartName === personOutline;

  const getChanges = (
    e: React.MouseEvent<HTMLIonAccordionElement>,
    option: ActivitiesSectionsProps['itemLists'][number]
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (option.onSubtitleClick && !isSubtitleActionLoading) {
      setIsSubtitleActionLoading(true);
      void option
        .onSubtitleClick()
        .finally(() => setIsSubtitleActionLoading(false));
      return;
    }

    const shouldOpenModal =
      option.iconStartName === calendarClearOutline &&
      requestType === 'coachVisit';

    if (option.subTitle && option.iconStartName === personOutline) {
      const parentElement = e.currentTarget.parentElement;
      if (parentElement) {
        const accordionElement = parentElement.querySelector('ion-accordion');
        if (accordionElement) accordionElement.style.height = '89px';
      }
    }

    if (status === 'completed' && option.iconStartName === DollarIcon) {
      setOpenPostVisitModal(true);
    }

    if (status === 'completed' && option.iconStartName === clipboardOutline) {
      setOpenBryaPartnerVisitNotesModal(true);
    }

    if (shouldOpenModal) {
      setModalOpen(true);
    }
  };

  const changeDate = (
    value: string,
    option: ActivitiesSectionsProps['itemLists'][number]
  ) => {
    setSelectedDate(() => {
      const updatedItemLists = itemListsRef.current.map((item) => {
        if (
          item === option &&
          itemListsRef.current[0].iconStartName !== homeOutline
        ) {
          itemListsRef.current[0].disabled = true;
          return {
            ...item,
            subTitle: 'New date and time proposed',
          };
        }
        return item;
      });

      onCallbackChangeDate(value);
      itemListsRef.current = updatedItemLists;

      return value;
    });

    showSubmitChange(true);
  };

  return (
    <MainContainer>
      <IonAccordContainer class={accordContainerClass}>
        {itemListsRef.current.map((option, index) => (
          <StyledIonAccordion
            value={`accordion-${index}`}
            disabled={option.disabled}
            onClick={(e) => void getChanges(e, option)}
            key={index}
            readonly={option.readOnly}
            toggleIcon={option.iconEndName}
            toggleIconSlot="end"
          >
            {option.title && option.title !== 'undefined, undefined' && (
              <StyledItem slot="header">
                {isDefaultIcon(option) &&
                  option.iconStartName !== personOutline && (
                    <StyledIcon
                      color="#23364B"
                      icon={option.iconStartName}
                      slot="start"
                    />
                  )}
                {isCoachIcon(option) && (
                  <StyledIcon
                    color="#23364B"
                    icon={option.iconStartName}
                    style={option.subTitle ? { marginBottom: '50px' } : {}}
                    slot="start"
                  />
                )}
                {option.iconStartName === personOutline && (
                  <StyledIcon
                    color="#23364B"
                    icon={option.iconStartName}
                    style={{
                      marginBottom:
                        option.title !== GenericEnum.NewProfessionalRequested &&
                        '63px',
                      marginTop: '10px',
                      height: '23px',
                    }}
                    slot="start"
                  />
                )}
                {option.circleAvatarAbbrev && (
                  <StyledCircleAvatar>
                    {option.circleAvatarAbbrev.firstName}
                    {option.circleAvatarAbbrev.lastName}
                  </StyledCircleAvatar>
                )}
                {isHistoryIcon(option) && (
                  <HistoryIcon style={{ marginRight: '10px' }} />
                )}
                {isDollarIcon(option) && (
                  <DollarIcon style={{ marginRight: '10px' }} />
                )}
                <div
                  style={
                    option.iconStartName === homeOutline
                      ? { position: 'absolute', width: '100%' }
                      : {}
                  }
                >
                  {!isCalendarIcon(option) && (
                    <IonLabel
                      style={{
                        marginRight: '20px',
                        fontColor: '#23364B',
                        color: option.customClass ? '#ea8231' : '',
                        whiteSpace: 'normal',
                      }}
                    >
                      {option.boldTitle &&
                        option.boldTitle !== 'null, ' && // thats how some data comes from BE
                        option.boldTitle !== ', ' && ( // thats how some data comes from BE
                          <span style={{ fontWeight: 600 }}>
                            {option.boldTitle}
                          </span>
                        )}
                      {option.title !== 'undefined, undefined' ? (
                        option.title
                      ) : (
                        <IonSpinner name="dots"></IonSpinner>
                      )}
                    </IonLabel>
                  )}
                  {isCalendarIcon(option) && (
                    <IonLabel
                      style={{
                        marginRight: '20px',
                        fontColor: '#23364B',
                      }}
                    >
                      {formattedDate ? formattedDate : option.title}
                    </IonLabel>
                  )}
                  {hasSeeProfileButton(option) && (
                    <div
                      style={{
                        display: 'flex',
                        height: '53px',
                        whiteSpace: 'normal',
                        marginTop: '3px',
                        marginRight: '13px',
                      }}
                    >
                      <RoundedButton
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          history.push('/pro-profile', {
                            agentId,
                            category,
                          });
                        }}
                      >
                        <IonLabel style={{ whiteSpace: 'normal' }}>
                          {i18n.t('seeProfile')}
                        </IonLabel>
                      </RoundedButton>
                      {status === 'needed' && (
                        <RoundedButton
                          style={{ whiteSpace: 'normal' }}
                          onClick={(e) => {
                            const buttonElement = e.currentTarget;
                            const accordionElement =
                              buttonElement.closest('ion-accordion');
                            if (accordionElement) {
                              accordionElement.style.height = '90px';
                            }
                            setModalOpen(true);
                          }}
                        >
                          <IonLabel style={{ whiteSpace: 'normal' }}>
                            {option.subTitle}
                          </IonLabel>
                        </RoundedButton>
                      )}
                    </div>
                  )}
                  {requestType === 'coachVisit' &&
                    option.subTitle &&
                    status !== 'completed' && (
                      <RoundedButton
                        onClick={option?.handler}
                        style={{
                          height: '38px',
                          width: '116px',
                        }}
                      >
                        <IonLabel
                          style={{
                            color: '#ea8231',
                            fontSize: '14px',
                            marginTop: '5px',
                            marginBottom: '5px',
                            marginLeft: '5px',
                          }}
                        >
                          {option.subTitle}
                        </IonLabel>
                      </RoundedButton>
                    )}
                  {!hasSeeProfileButton(option) &&
                    requestType !== 'coachVisit' && (
                      <IonLabel
                        style={{
                          color: '#ea8231',
                          fontSize: '14px',
                          marginTop: '5px',
                          fontWeight: '600',
                        }}
                      >
                        {option.subTitle}
                      </IonLabel>
                    )}
                </div>
                {option.ratingValue !== '' && (
                  <StarLabelContainer>
                    <IonLabel
                      style={{
                        fontSize: '18px',
                        color: '#212121',
                        position: 'relative',
                        left: '130px',
                      }}
                    >
                      {option.ratingValue}
                    </IonLabel>
                    {option.customIcon && (
                      <StarIcon
                        style={{
                          position: 'relative',
                          bottom: '-3px',
                          left: '135px',
                        }}
                      />
                    )}
                  </StarLabelContainer>
                )}
              </StyledItem>
            )}
            <div className="ion-padding" slot="content">
              {isCalendarIcon(option) && requestType !== 'coachVisit' ? (
                <IonDatetime
                  locale="en-US"
                  onIonChange={(e) =>
                    changeDate(e.detail.value as string, option)
                  }
                  minuteValues="0,15,30,45"
                  value={selectedDate ? selectedDate : option.calendarValue}
                />
              ) : null}
              {isHistoryIcon(option) ? (
                <ActivitiesHistory
                  user={user}
                  timeline={option.historyTimeline as GraphqlTimeline[]}
                />
              ) : null}
              {isInformationIcon(option) ? (
                <ActivityCard style={{ marginTop: '2px' }}>
                  <Subtitle>
                    {cancelledReason ?? 'Assistance no longer needed.'}
                  </Subtitle>
                </ActivityCard>
              ) : null}
              {isHomeIcon(option) && requestType !== 'social' ? (
                <ActivitiesRideRequest rideOptions={rideOptions} />
              ) : null}
              {isDollarIcon(option) &&
                serviceRequestPayment &&
                serviceRequest && (
                  <PaymentDetails
                    isARequesterUser={() =>
                      serviceRequest?.RequestedBy.id === user?.id
                    }
                    paymentData={serviceRequestPayment}
                    cardHolderName={
                      serviceRequest?.RequestedBy?.fullName as string
                    }
                    hideChangeCardButton={
                      status === ServiceRequestStatus.Completed ||
                      status === ServiceRequestStatus.Confirmed
                    }
                  />
                )}
            </div>
          </StyledIonAccordion>
        ))}
      </IonAccordContainer>
      <CustomAlert
        isOpen={modalOpen}
        onDidDismiss={() => setModalOpen(false)}
        title={
          requestType === 'coachVisit'
            ? i18n.t('cancelCoachVisitBtnTitle')
            : i18n.t('modalChangeProfessionalTitle')
        }
        subtitle={
          requestType === 'coachVisit'
            ? i18n.t('cancelCoachVisitBtnSubtitle')
            : i18n.t('modalChangeProfessionalSubTitle')
        }
        firstButtonLabel={
          requestType === 'coachVisit'
            ? i18n.t('connectCoach')
            : i18n.t('modalChangeProfessionalButtonConfirm')
        }
        secondButtonLabel={
          requestType === 'coachVisit'
            ? i18n.t('cancelCoach')
            : i18n.t('modalChangeProfessionalButtonCancel')
        }
        firstButtonAction={() => {
          setModalOpen(false);
          if (requestType === 'coachVisit') {
            const startChat = itemListsRef.current.find(
              (item) => item.subTitle === 'Start chat'
            );
            if (startChat && startChat?.handler) {
              startChat?.handler();
            }
          } else {
            userApprovalServiceRequestMutation({
              variables: {
                action: ApprovalServiceRequestStatus.Decline,
                serviceRequestId,
                rejectedReason: GenericEnum.NewAgentNeeded,
              },
            })
              .then(() => {
                history.replace('/activityStatus', {
                  title: location.state?.title,
                  status: ActivityStatusEnum.Pending,
                  requestType,
                  serviceRequestId,
                  typeChange: {
                    iconStartName: personOutline,
                    iconEndName: '',
                    customIcon: false,
                    title: 'New professional requested',
                    ratingValue: '',
                    readOnly: true,
                    customClass: true,
                  },
                });
              })
              .catch(() => {
                throw new Error('Change Agent Request Failed');
              });
          }
        }}
        secondButtonAction={() => setModalOpen(false)}
      />
      <CustomAlert
        isDisabled={false}
        isOpen={infoModal}
        onDidDismiss={() => setInfoModal(false)}
        title={i18n.t('activitySection.safetyFeeNoDots')}
        subtitle1={i18n.t('activitySection.infoModal')}
        firstButtonLabel={i18n.t('postVisitModal.ok')}
        firstButtonAction={() => {
          setInfoModal(false);
        }}
      />

      <BryaPartnerVisitNotesModal
        coachVisitId={serviceRequestId}
        onClose={() => setOpenBryaPartnerVisitNotesModal(false)}
        isOpen={openBryaPartnerVisitNotesModal}
      />

      {openPostVisitModal && (
        <ModalPostVisitCosts
          isOpenModal
          onDidDismiss={() => setOpenPostVisitModal(false)}
          serviceRequestId={serviceRequest?.id}
        />
      )}

      <IonToast
        isOpen={openToast}
        message={i18n.t('activitySection.defaultCardChanged')}
        duration={2500}
        icon={checkmarkCircle}
        position="top"
        color={'success'}
        onDidDismiss={() => setOpenToast(false)}
      ></IonToast>
    </MainContainer>
  );
};

export default ActivitiesSections;
