import React, { useCallback, useState, useMemo } from 'react';
import { PaymentCardType } from '../../../constants/enums';
import CardVisa from '../../../assets/images/CardVisa';
import CardMastercard from '../../../assets/images/CardMastercard';
import CardAmex from '../../../assets/images/CardAmex';
import { IonContent, IonIcon, IonPopover, IonSkeletonText } from '@ionic/react';
import {
  alertCircle,
  cardOutline,
  informationCircleOutline,
} from 'ionicons/icons';
import {
  GraphqlServiceRequestPayment,
  ServicePaymentType,
  useGetStripeCustomerPaymentMethodQuery,
} from '../../../graphql/generated';
import { useTranslation } from 'react-i18next';
import { useUpdateCardMutations } from '../../../utils/hooks/useUpdateCardMutations';
import {
  CardContainer,
  CardIcon,
  CardInfoColumn,
  PaymentContainer,
  PaymentInfoDescription,
  PaymentInfoText,
  PaymentInfoTitle,
  RadioInfo,
} from './PaymentDetails.styles';
import { formatNumber } from '../../../utils/formatNumber';
import {
  formatExpiration,
  formatPartialCardNumber,
} from '../../../utils/formatters/payment';
import { Button } from '../../Button/Button';
import { payment } from '../../../constants/payment';
import CustomAlert from '../../CustomAlert/CustomAlert';
import ModalAddCard from '../../ModalAddCard/ModalAddCard';
import { ActivityDetailItemDivider } from '../../ActivitiesDetailCard/ActivitiesDetailCard.styles';
import { StorageKey, setStorage } from '../../../utils/storage';
import { StripeTransactionStatus } from '../../../utils/hooks/useGetOutstandingBalanceStatus';
import Badge from '../../Badge/Badge';
import { TertiaryTitle } from '../../Typography/Headings/Headings';
import { t } from 'i18next';

interface IPaymentDetailsProps {
  paymentData?: GraphqlServiceRequestPayment | null;
  extraPaymentData?: GraphqlServiceRequestPayment | null;
  setHasPaymentIssue?: (hasPaymentIssue: boolean) => void;
  setDisableUpdatePaymentButton?: (disableUpdatePaymentButton: boolean) => void;
  isARequesterUser?: () => boolean;
  disableUpdatePaymentButton?: boolean;
  cardHolderName?: string;
  hideChangeCardButton?: boolean;
}
const CHANGE_PAYMENT_METHOD = 'change-payment-method';
const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  paymentData,
  extraPaymentData,
  disableUpdatePaymentButton,
  setDisableUpdatePaymentButton,
  isARequesterUser,
  cardHolderName,
  hideChangeCardButton,
}) => {
  const i18n = useTranslation();
  const [showListCardsModal, setShowListCardsModal] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const isRequester = useMemo(() => {
    if (!isARequesterUser) return false;
    return isARequesterUser();
  }, [isARequesterUser]);
  const { data: paymentMethod, loading: isLoadingCustomerPaymentMethod } =
    useGetStripeCustomerPaymentMethodQuery({
      skip: !paymentData?.stripePaymentMethodId || !isRequester,
      variables: {
        paymentMethodId: paymentData?.stripePaymentMethodId as string,
      },
    });

  const {
    data: extraPaymentMethod,
    loading: isLoadingCustomerExtraPaymentMethod,
  } = useGetStripeCustomerPaymentMethodQuery({
    skip: !extraPaymentData?.stripePaymentMethodId || !isRequester,
    variables: {
      paymentMethodId: extraPaymentData?.stripePaymentMethodId as string,
    },
  });

  const {
    handleCardSelect,
    updateServiceRequestPaymentLoading,
    stripeCustomerDefaultCardLoading,
  } = useUpdateCardMutations();

  const handleCardSelectLocal = async (paymentMethodId: string) => {
    try {
      await setStorage(
        StorageKey.LastSavedPaymentMethod,
        paymentData?.stripePaymentMethodId as string
      );
      await handleCardSelect(paymentMethodId, paymentData);
      if (setDisableUpdatePaymentButton) {
        setDisableUpdatePaymentButton(false);
        await setStorage(StorageKey.CurrentSavedPaymentMethod, paymentMethodId);
      }
      setShowListCardsModal(false);
    } catch (error) {
      alert('Error updating payment method');
    }
  };

  const detectCardIconType = (type: string) => {
    switch (type) {
      case PaymentCardType.Visa:
        return <CardVisa width={32} height={32} />;
      case PaymentCardType.MasterCard:
        return <CardMastercard width={32} height={32} />;
      case PaymentCardType.Amex:
        return <CardAmex width={32} height={32} />;
      default:
        return (
          <IonIcon
            icon={cardOutline}
            style={{
              color: 'var(--colors-primary-blue)',
              width: 24,
              height: 24,
            }}
          />
        );
    }
  };
  const decideSelectedPaymentType = useCallback(
    (selectedPayment: GraphqlServiceRequestPayment | null | undefined) => {
      switch (selectedPayment?.type) {
        case ServicePaymentType.Hour:
          return i18n.t('paymentSetupModal.hourly');
        case ServicePaymentType.Fixed:
          return i18n.t('paymentSetupModal.setPrice');
        case ServicePaymentType.Visit:
          return i18n.t('paymentSetupModal.visitPrice');
        default:
          return i18n.t('activitySection.paymentMethod');
      }
    },
    [paymentData]
  );

  const isLoadingPaymentSection =
    isLoadingCustomerPaymentMethod ||
    updateServiceRequestPaymentLoading ||
    stripeCustomerDefaultCardLoading;

  const showPaymentSection = isRequester && !isLoadingPaymentSection;

  const totalCost =
    (paymentData && paymentData.amount + paymentData.fee + paymentData.tax) ||
    0;

  const totalExtraCost =
    (extraPaymentData &&
      extraPaymentData.amount + extraPaymentData.fee + extraPaymentData.tax) ||
    0;

  const paymentInfoDescription = useMemo(() => {
    if (!paymentData) return null;
    return (
      {
        [ServicePaymentType.Visit]: t('activitySection.infoVisitPrice'),
        [ServicePaymentType.Hour]: t('activitySection.infoHourly'),
        [ServicePaymentType.Fixed]: t('activitySection.infoSetPrice'),
      }[paymentData.type] || null
    );
  }, [paymentData]);

  return (
    <PaymentContainer>
      <PaymentInfoTitle>
        {i18n.t('activitySection.paymentDetails')} :{' '}
      </PaymentInfoTitle>

      {extraPaymentData ? (
        <TertiaryTitle>
          {i18n.t('modalPostVisitCost.initialCosts')}{' '}
          <Badge icon="✓" variant="success">
            {i18n.t('modalPostVisitCost.paid')}
          </Badge>
        </TertiaryTitle>
      ) : null}

      <PaymentInfoText>
        {i18n.t('activitySection.paymentType', {
          paymentTypeSelected: decideSelectedPaymentType(paymentData),
        })}
      </PaymentInfoText>
      <PaymentInfoText>
        {i18n.t('activitySection.rate', {
          amount: paymentData && formatNumber(paymentData?.amount),
        })}
      </PaymentInfoText>
      <PaymentInfoDescription>{paymentInfoDescription}</PaymentInfoDescription>
      <PaymentInfoTitle>
        {(paymentData?.type === ServicePaymentType.Hour ||
          paymentData?.type === ServicePaymentType.Visit) &&
          i18n.t('activitySection.initialCustomerCost', {
            amount: formatNumber(totalCost),
          })}
        {paymentData?.type === ServicePaymentType.Fixed &&
          i18n.t('activitySection.totalCustomerCost', {
            amount: formatNumber(totalCost),
          })}
      </PaymentInfoTitle>
      <PaymentInfoText>
        {(paymentData?.type === ServicePaymentType.Hour ||
          paymentData?.type === ServicePaymentType.Visit) &&
          i18n.t('activitySection.serviceCharge', {
            amount: paymentData && formatNumber(paymentData?.amount),
          })}
        {paymentData?.type === ServicePaymentType.Fixed &&
          i18n.t('activitySection.totalServiceCharge', {
            amount: paymentData && formatNumber(paymentData?.amount),
          })}
      </PaymentInfoText>
      <PaymentInfoText
        onClick={() => setInfoModal(true)}
        style={{ alignItems: 'center', display: 'flex' }}
      >
        {i18n.t('activitySection.safetyFee', {
          safetyFee:
            paymentData &&
            formatNumber(paymentData?.amount * payment.SAFETY_FEE),
        })}

        <IonIcon
          icon={informationCircleOutline}
          style={{ width: 18, height: 18, marginLeft: 8 }}
        />
      </PaymentInfoText>
      {paymentData && paymentData?.tax ? (
        <PaymentInfoText style={{ alignItems: 'center', display: 'flex' }}>
          {i18n.t('activitySection.taxFee', {
            taxFee: paymentData && formatNumber(paymentData?.tax),
          })}
        </PaymentInfoText>
      ) : null}
      <ActivityDetailItemDivider style={{ margin: '1rem 0' }} />
      <PaymentInfoTitle>
        {i18n.t('activitySection.paymentMethod')} :
      </PaymentInfoTitle>
      {!showPaymentSection && (
        <PaymentInfoDescription>
          {i18n.t('payment.PaymentForThisAppointmentIsHandledBy')}{' '}
          <b>{cardHolderName}</b>.
        </PaymentInfoDescription>
      )}
      {isRequester && isLoadingPaymentSection && (
        <div
          style={{
            margin: '1rem 0',
            display: 'flex',
          }}
        >
          <IonSkeletonText
            animated={true}
            style={{ width: '40px', marginRight: '1rem' }}
          />
          <div>
            <IonSkeletonText animated={true} style={{ width: '80px' }} />
            <IonSkeletonText animated={true} style={{ width: '100px' }} />
          </div>
        </div>
      )}
      {showPaymentSection && (
        <>
          {paymentMethod && (
            <CardContainer>
              {paymentMethod.getStripeCustomerPaymentMethod.card && (
                <>
                  <CardIcon>
                    {detectCardIconType(
                      paymentMethod.getStripeCustomerPaymentMethod.card.brand
                    )}
                  </CardIcon>
                  <CardInfoColumn>
                    {paymentMethod.getStripeCustomerPaymentMethod &&
                      formatPartialCardNumber(
                        paymentMethod.getStripeCustomerPaymentMethod.card
                      )}
                    <RadioInfo>
                      {i18n.t('activitySection.expiration')}
                      {paymentMethod.getStripeCustomerPaymentMethod.card &&
                        formatExpiration(
                          paymentMethod.getStripeCustomerPaymentMethod.card
                        )}
                    </RadioInfo>
                  </CardInfoColumn>
                  {disableUpdatePaymentButton && !extraPaymentData && (
                    <IonIcon
                      id={CHANGE_PAYMENT_METHOD}
                      icon={alertCircle}
                      style={{
                        color: '#EA4255',
                        width: 24,
                        height: 24,
                        marginLeft: '1rem',
                      }}
                    />
                  )}
                  <IonPopover
                    trigger={CHANGE_PAYMENT_METHOD}
                    triggerAction="click"
                  >
                    <IonContent class="ion-padding">
                      {i18n.t('payment.updatePaymentMethod')}
                    </IonContent>
                  </IonPopover>
                </>
              )}
            </CardContainer>
          )}

          {hideChangeCardButton !== true && !extraPaymentData && (
            <Button
              color="secondary-orange"
              size="small"
              onClick={() => {
                setShowListCardsModal(true);
              }}
              style={{
                marginBottom: 12,
              }}
            >
              <>
                {i18n.t('StatusPage.paymentIssue.actions.changePaymentMethod')}
              </>
            </Button>
          )}
        </>
      )}

      {extraPaymentMethod && (
        <>
          <PaymentInfoTitle>
            {i18n.t('postVisitModal.extraCharge')}{' '}
            {
              <Badge icon="!" variant="warning">
                {i18n.t('modalPostVisitCost.due')}
              </Badge>
            }
          </PaymentInfoTitle>
          <PaymentInfoText>
            {i18n.t('activitySection.paymentType', {
              paymentTypeSelected: decideSelectedPaymentType(extraPaymentData),
            })}
          </PaymentInfoText>
          <PaymentInfoText>
            {i18n.t('activitySection.rate', {
              amount:
                extraPaymentData && formatNumber(extraPaymentData?.amount),
            })}
          </PaymentInfoText>
          <PaymentInfoTitle>
            {i18n.t('activitySection.totalCustomerCost', {
              amount: formatNumber(totalExtraCost),
            })}
          </PaymentInfoTitle>
          <PaymentInfoText>
            {(extraPaymentData?.type === ServicePaymentType.Hour ||
              extraPaymentData?.type === ServicePaymentType.Visit) &&
              i18n.t('activitySection.serviceCharge', {
                amount:
                  extraPaymentData && formatNumber(extraPaymentData?.amount),
              })}
            {extraPaymentData?.type === ServicePaymentType.Fixed &&
              i18n.t('activitySection.totalServiceCharge', {
                amount:
                  extraPaymentData && formatNumber(extraPaymentData?.amount),
              })}
          </PaymentInfoText>
          <PaymentInfoText
            onClick={() => setInfoModal(true)}
            style={{ alignItems: 'center', display: 'flex' }}
          >
            {i18n.t('activitySection.safetyFee', {
              safetyFee:
                extraPaymentData &&
                formatNumber(extraPaymentData?.amount * payment.SAFETY_FEE),
            })}

            <IonIcon
              icon={informationCircleOutline}
              style={{ width: 18, height: 18, marginLeft: 8 }}
            />
          </PaymentInfoText>
          {extraPaymentData && extraPaymentData?.tax ? (
            <PaymentInfoText style={{ alignItems: 'center', display: 'flex' }}>
              {i18n.t('activitySection.taxFee', {
                taxFee: extraPaymentData && formatNumber(extraPaymentData?.tax),
              })}
            </PaymentInfoText>
          ) : null}
          <ActivityDetailItemDivider style={{ margin: '1rem 0' }} />
          <PaymentInfoTitle>
            {i18n.t('activitySection.paymentMethod')} :
          </PaymentInfoTitle>
          {!showPaymentSection && (
            <PaymentInfoDescription>
              {i18n.t('payment.PaymentForThisAppointmentIsHandledBy')}{' '}
              <b>{cardHolderName}</b>.
            </PaymentInfoDescription>
          )}
          {isRequester && isLoadingCustomerExtraPaymentMethod && (
            <div
              style={{
                margin: '1rem 0',
                display: 'flex',
              }}
            >
              <IonSkeletonText
                animated={true}
                style={{ width: '40px', marginRight: '1rem' }}
              />
              <div>
                <IonSkeletonText animated={true} style={{ width: '80px' }} />
                <IonSkeletonText animated={true} style={{ width: '100px' }} />
              </div>
            </div>
          )}

          {showPaymentSection && (
            <>
              {extraPaymentMethod && (
                <CardContainer>
                  {extraPaymentMethod.getStripeCustomerPaymentMethod.card && (
                    <>
                      <CardIcon>
                        {detectCardIconType(
                          extraPaymentMethod.getStripeCustomerPaymentMethod.card
                            .brand
                        )}
                      </CardIcon>
                      <CardInfoColumn>
                        {extraPaymentMethod.getStripeCustomerPaymentMethod &&
                          formatPartialCardNumber(
                            extraPaymentMethod.getStripeCustomerPaymentMethod
                              .card
                          )}
                        <RadioInfo>
                          {i18n.t('activitySection.expiration')}
                          {extraPaymentMethod.getStripeCustomerPaymentMethod
                            .card &&
                            formatExpiration(
                              extraPaymentMethod.getStripeCustomerPaymentMethod
                                .card
                            )}
                        </RadioInfo>
                      </CardInfoColumn>
                      {extraPaymentData?.StripeTransaction?.status ===
                        StripeTransactionStatus.RequiresPaymentMethod &&
                        extraPaymentData.stripePaymentMethodId &&
                        extraPaymentData.stripePaymentMethodId !==
                          paymentData?.stripePaymentMethodId && (
                          <IonIcon
                            id={CHANGE_PAYMENT_METHOD}
                            icon={alertCircle}
                            style={{
                              color: '#EA4255',
                              width: 24,
                              height: 24,
                              marginLeft: '1rem',
                            }}
                          />
                        )}
                      <IonPopover
                        trigger={CHANGE_PAYMENT_METHOD}
                        triggerAction="click"
                      >
                        <IonContent class="ion-padding">
                          {i18n.t('payment.updatePaymentMethod')}
                        </IonContent>
                      </IonPopover>
                    </>
                  )}
                </CardContainer>
              )}
            </>
          )}
        </>
      )}
      <CustomAlert
        isDisabled={false}
        isOpen={infoModal}
        onDidDismiss={() => setInfoModal(false)}
        title={i18n.t('activitySection.safetyFeeNoDots')}
        subtitle1={i18n.t('activitySection.infoModal')}
        firstButtonLabel={i18n.t('postVisitModal.ok')}
        firstButtonAction={() => {
          setInfoModal(false);
        }}
      />
      <ModalAddCard
        isOpenModal={showListCardsModal}
        onCardSelect={handleCardSelectLocal}
        onDidDismiss={() => setShowListCardsModal(false)}
      />
    </PaymentContainer>
  );
};

export default PaymentDetails;
