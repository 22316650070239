import * as React from 'react';

function IconActivityCancelled(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width={77}
      height={100}
      viewBox="0 0 77 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={12}
        width={76}
        height={76}
        rx={38}
        fill="#FF4133"
        fillOpacity={0.25}
      />
      <rect
        x={8.5}
        y={20}
        width={60}
        height={60}
        rx={30}
        fill="#FF4133"
        fillOpacity={0.5}
      />
      <rect x={16.5} y={28} width={44} height={44} rx={22} fill="#fff" />
      <path
        d="M38.5 28c-12.13 0-22 9.87-22 22s9.87 22 22 22 22-9.87 22-22-9.87-22-22-22zm7.965 27.573a1.693 1.693 0 11-2.392 2.392L38.5 52.395l-5.573 5.572a1.692 1.692 0 01-2.392-2.393L36.105 50l-5.572-5.573a1.692 1.692 0 012.393-2.392l5.573 5.571 5.573-5.572a1.692 1.692 0 012.392 2.393L40.895 50l5.572 5.573z"
        fill="#EA4255"
      />
    </svg>
  );
}

export default IconActivityCancelled;
