import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container, StyledIonContent } from './ActivitiesTab.style';
import { useTranslation } from 'react-i18next';
import {
  Activities,
  ActivitiesGroupedByDateOutput,
  Scalars,
  ServiceRequestStatus,
  TimelineOutput,
} from '../../graphql/generated';
import { Maybe } from 'graphql/jsutils/Maybe';
import ActivitiesTabSkeleton from './ActivitiesTab.skeleton';
import OpenTab from './OpenTab/openTab';
import ScheduledTab from './ScheduledTab/ScheduledTab';
import HistoryTab from './HistoryTab/historyTab';

type ActivitiesTabProps = {
  setSelectedTab: (value: string) => void;
  selectedTab: string;
  isLoadingOpenActivities: boolean;
  isLoadingGroupedActivities: boolean;
  data: {
    getActivities: {
      requests: Activities[];
    };
  };
  dataWithTimeline: {
    getActivitiesGroupedByDate: {
      requests: {
        activities?: Maybe<Array<Activities>>;
      };
      id: Scalars['Int'];
      timeline: TimelineOutput;
    };
  };
};

const selectedTabLabelMapper: {
  [key: string]: string;
} = {
  0: 'open',
  1: 'scheduled',
  2: 'history',
};

const selectedTabIndexMapper: {
  [key: string]: number;
} = {
  open: 0,
  scheduled: 1,
  history: 2,
};

const ActivitiesTab: React.FC<ActivitiesTabProps> = ({
  setSelectedTab,
  isLoadingOpenActivities,
  isLoadingGroupedActivities,
  selectedTab,
  data,
  dataWithTimeline,
}) => {
  const { t } = useTranslation();
  const [hasPaymentAuthorizationFailed, setHasPaymentAuthorizationFailed] =
    useState(false);
  const [hasPaymentFailed, setHasPaymentFailed] = useState(false);

  useEffect(() => {
    setHasPaymentAuthorizationFailed(
      (
        (dataWithTimeline?.getActivitiesGroupedByDate
          ?.requests as ActivitiesGroupedByDateOutput[]) || []
      ).some((element) => {
        return element.activities?.some(
          (item) =>
            item.status === ServiceRequestStatus.PaymentAuthorizationFailed
        );
      })
    );

    setHasPaymentFailed(
      (
        (dataWithTimeline?.getActivitiesGroupedByDate
          ?.requests as ActivitiesGroupedByDateOutput[]) || []
      ).some((element) => {
        return element.activities?.some(
          (item) => item.status === ServiceRequestStatus.PaymentFailed
        );
      })
    );
  }, [dataWithTimeline?.getActivitiesGroupedByDate?.requests]);

  const tabs = [
    {
      title: 'open',
      content: <OpenTab data={data?.getActivities?.requests as []} />,
    },
    {
      title: 'scheduled',
      content: (
        <ScheduledTab
          data={dataWithTimeline?.getActivitiesGroupedByDate?.requests as []}
        />
      ),
    },
    {
      title: 'history',
      content: (
        <HistoryTab
          data={dataWithTimeline?.getActivitiesGroupedByDate?.requests as []}
        />
      ),
    },
  ];

  return (
    <Container
      hasPaymentAuthorizationFailed={hasPaymentAuthorizationFailed}
      hasPaymentFailed={hasPaymentFailed}
    >
      <Tabs
        defaultIndex={selectedTabIndexMapper[selectedTab]}
        onSelect={(index) => {
          setSelectedTab(selectedTabLabelMapper[index]);
        }}
      >
        <TabList>
          <Tab>{t('OPEN')}</Tab>
          <Tab>{t('SCHEDULED')}</Tab>
          <Tab>{t('HISTORY')}</Tab>
        </TabList>
        {tabs.map(({ title, content }) => (
          <TabPanel key={title}>
            <StyledIonContent>
              {isLoadingOpenActivities || isLoadingGroupedActivities ? (
                <ActivitiesTabSkeleton />
              ) : (
                <>{content}</>
              )}
            </StyledIonContent>
          </TabPanel>
        ))}
      </Tabs>
    </Container>
  );
};

export default ActivitiesTab;
