import { FC } from 'react';
import styled from 'styled-components';
import { PaymentCardType } from '../../constants/enums';
import CardVisa from '../../assets/images/CardVisa';
import CardMastercard from '../../assets/images/CardMastercard';
import CardAmex from '../../assets/images/CardAmex';
import { IonIcon } from '@ionic/react';
import { cartOutline } from 'ionicons/icons';
import { useGetStripeCustomerPaymentMethodQuery } from '../../graphql/generated';

type CardIconProps = {
  type: string;
};

function capitalizeFirstLetter(string?: string) {
  return string && string?.charAt(0).toUpperCase() + string?.slice(1);
}

const CardIcon: FC<CardIconProps> = ({ type }) => {
  switch (type) {
    case PaymentCardType.Visa:
      return <CardVisa width={32} height={32} />;
    case PaymentCardType.MasterCard:
      return <CardMastercard width={32} height={32} />;
    case PaymentCardType.Amex:
      return <CardAmex width={32} height={32} />;
    default:
      return (
        <IonIcon
          icon={cartOutline}
          style={{
            color: 'var(--colors-primary-blue)',
            width: 24,
            height: 24,
          }}
        />
      );
  }
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Line = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
`;

const CardInfo = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  color: #212121;
  font-weight: bold;
`;

const Expiration = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  color: #212121;
`;

type PaymentMethodProps = {
  stripePaymentMethodId: string;
};

const PaymentMethod: FC<PaymentMethodProps> = ({ stripePaymentMethodId }) => {
  const { data: paymentMethod } = useGetStripeCustomerPaymentMethodQuery({
    variables: {
      paymentMethodId: stripePaymentMethodId,
    },
    skip: !stripePaymentMethodId,
  });

  const card = paymentMethod?.getStripeCustomerPaymentMethod?.card;

  const cardType = card?.brand;
  const cardInfo = `${capitalizeFirstLetter(card?.brand)} **** ${card?.last4}`;
  const expiration = `${String(card?.exp_month)?.padStart(2, '0')}/${
    card?.exp_year
  }`;

  if (!cardType) {
    return null;
  }

  return (
    <RowContainer>
      <CardIcon type={cardType ?? 'visa'} />
      <ColumnContainer>
        <Line>
          <CardInfo>{cardInfo}</CardInfo>
          <Expiration>Expiration: {expiration}</Expiration>
        </Line>
      </ColumnContainer>
    </RowContainer>
  );
};

export default PaymentMethod;
