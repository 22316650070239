import styled from 'styled-components';

export const DescriptionBox = styled.div`
  flex-grow: 1;
  background: radial-gradient(
    194.31% 83.79% at 11.62% 50%,
    #fff 0%,
    #f6f6f6 57.29%,
    #fff 100%
  );
  border: 1px solid var(--body-text-400, #bdbdbd);
  border-radius: 10px;
  display: flex;
  align-items: center;
`;
