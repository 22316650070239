import { GetCircleRequestQuery } from '../../graphql/generated';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { BodyText } from '../Typography/Body/Body';
import { useTranslation } from 'react-i18next';
import { CircleRequestIcon } from '../../assets/images/ServiceCategory/CircleRequestIcon';

type RequestReceivers = GetCircleRequestQuery['getCircleRequest']['AskTo'];

type ActivityCardItemRequestReceiversProps = {
  receivers: RequestReceivers;
};
export const ActivityCardItemRequestReceivers: React.FC<
  ActivityCardItemRequestReceiversProps
> = ({ receivers }) => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size="auto"
          className="ion-align-self-center"
          style={{ height: '24px' }}
        >
          <CircleRequestIcon
            color="var(--colors-primary-blue)"
            style={{ width: '24px', height: '24px', marginInlineEnd: '8px' }}
          />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <BodyText color="var(--colors-primary-blue)">
            {t('ActivityCardItemRequestReceivers.Label', {
              receivers: receivers.map((receiver) => receiver.firstName),
            })}
          </BodyText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
