import styled from 'styled-components';
import { pixelToRem } from '../../utils/helper';
import {
  IonIcon,
  IonItem,
  IonAccordionGroup,
  IonButton,
  IonAccordion,
} from '@ionic/react';

export type DivProps = {
  expanded?: boolean;
};

export const MainContainer = styled.div<DivProps>`
  display: flex;
  position: relative;
  left: 6%;
  flex-direction: column;
  width: 88%;
`;

export const StyledItem = styled(IonItem)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  --background: transparent;
  --background-activated: transparent;
  --highlight-background: transparent;
  --ripple-color: transparent;
`;

export const StyledIcon = styled(IonIcon)`
  margin-right: ${pixelToRem(7)};
`;

export const StarLabelContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 70%;
  align-items: end;
`;

export const IonAccordContainer = styled(IonAccordionGroup)`
  width: 100%;
  margin: 0;

  ion-accordion {
    margin: 0 auto;

    ion-icon.ion-accordion-toggle-icon {
      transform: none !important;
    }
  }

  ion-accordion.accordion-expanding,
  ion-accordion.accordion-expanded {
    width: 100%;
    margin: 0;
  }
`;

export const RoundedButton = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--ion-color-primary);
  margin: 8px 0;

  color: var(--primary-orange, #ea8231);
  text-align: center;
  font-size: ${pixelToRem(11)};
  font-weight: 600;
  padding: 8px 16px;
`;

export const PaymentContainer = styled.div`
  background-color: #fafafa;
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding: 16px;
`;

export const PaymentInfoTitle = styled.p`
  color: var(--colors-primary-blue);
  font-size: ${pixelToRem(16)};
  font-weight: 600;
`;

export const PaymentInfoText = styled.p`
  color: var(--body-text-900);
  font-size: ${pixelToRem(14)};
  font-weight: 400;
`;

export const PaymentInfoDescription = styled.p`
  color: var(--body-text-700);
  font-size: ${pixelToRem(14)};
  font-weight: 400;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioInfo = styled.span`
  font-weight: 400;
  font-size: ${pixelToRem(16)};
  line-height: 20px;
  color: var(--body-text-700);
`;

export const EditButton = styled(IonButton)`
  height: 48px;
  width: 32%;
  font-weight: 600;
  border: 1px solid;
  margin-left: 12px;
  text-transform: none;
  font-size: ${pixelToRem(16)};
  --border-radius: 50px;
  --background-activated: #fff;

  &.needed {
    --background: #fafafa;
    --border-color: var(--ion-color-primary);
    border: 1px solid;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    color: var(--ion-color-primary);
  }
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const CardInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledIonAccordion = styled(IonAccordion)`
  ion-icon.ion-accordion-toggle-icon {
    ${({ toggleIcon }) => !toggleIcon && 'display: none;'}
  }
`;
