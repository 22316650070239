import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import { Trans } from 'react-i18next';
import { SecondaryTitle } from '../../components/Typography/Headings/Headings';
import { BodyText } from '../../components/Typography/Body/Body';
import { Button } from '../../components/Button/Button';
import { GreenCheckMark } from '../../assets/images/GreenCheckMark';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';

const AccountDeletedPage: React.FC = () => {
  const history = useHistory();

  const handleContinue = () => {
    history.replace(ROUTES.LOGIN);
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <IonCol size="auto">
              <GreenCheckMark />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <SecondaryTitle>
                <Trans i18nKey="accountDeleted.title" />
              </SecondaryTitle>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <BodyText>
                <Trans i18nKey="accountDeleted.body" />
              </BodyText>
            </IonCol>
          </IonRow>

          <IonRow className="ion-padding-vertical">
            <IonCol>
              <Button color="primary-orange" onClick={handleContinue}>
                <Trans i18nKey="accountDeleted.cta" />
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AccountDeletedPage;
