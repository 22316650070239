import * as React from 'react';
export interface ActivitiesSelectedProps extends React.SVGProps<SVGSVGElement> {
  hasUnreadServiceNotification?: boolean;
}
export const ActivitiesNotSelected = (props: ActivitiesSelectedProps) => (
  <>
    {!props.hasUnreadServiceNotification ? (
      <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.833 3.748h-15a2.25 2.25 0 00-2.25 2.25v13.5a2.25 2.25 0 002.25 2.25h15a2.25 2.25 0 002.25-2.25v-13.5a2.25 2.25 0 00-2.25-2.25z"
          stroke={props.color || '#23364B'}
          strokeWidth={1.5}
          strokeLinejoin="round"
        />
        <path
          d="M22.083 7.497h-19.5m3.75-5.25v1.5-1.5zm12 0v1.5-1.5z"
          stroke={props.color || '#23364B'}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M20.334 3.75006H5.33398C4.09134 3.75006 3.08398 4.75742 3.08398 6.00006V19.5001C3.08398 20.7427 4.09134 21.7501 5.33398 21.7501H20.334C21.5766 21.7501 22.584 20.7427 22.584 19.5001V6.00006C22.584 4.75742 21.5766 3.75006 20.334 3.75006Z"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinejoin="round"
        />
        <path
          d="M6.83301 2.24939V3.76288"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.834 2.24948V3.76298"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.584 7.50036H3.08398"
          stroke="#FAAD14"
          strokeWidth="1.00189"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="20" cy="3.5" r="3.5" fill="#FAAD14" />
      </svg>
    )}
  </>
);
