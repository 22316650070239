import React, { useCallback } from 'react';
import { Container } from './ActivityEmptyStates.styles';
import { EmptyOpenActivity } from '../../assets/images/EmptyOpenActivity';
import { EmptyScheduledActivity } from '../../assets/images/EmptyScheduledActivity';
import { EmptyHistoryActivity } from '../../assets/images/EmptyHistoryActivity';
import { useTranslation } from 'react-i18next';
import { ActivitiesStatus } from '../../graphql/generated';

type ActivityEmptyStatesProps = {
  tab: ActivitiesStatus | string;
};

const ActivityEmptyStates: React.FC<ActivityEmptyStatesProps> = ({ tab }) => {
  const { t } = useTranslation();

  const openState = useCallback(() => {
    return (
      <>
        <EmptyOpenActivity />
        <h2 style={{ marginTop: 0 }}>{t('EmptyOpenActivityTitle')}</h2>
        <p>{t('EmptyOpenActivityDescriptionMessage')}</p>
      </>
    );
  }, []);
  const scheduleState = useCallback(() => {
    return (
      <>
        <EmptyScheduledActivity />
        <h2>
          {t('EmptyScheduledActivityTitle')}
          <br /> {t('EmptyScheduledActivityTitle2')}
        </h2>
        <p>{t('EmptyScheduledActivityDescription')}</p>
      </>
    );
  }, []);
  const historyState = useCallback(() => {
    return (
      <>
        <EmptyHistoryActivity />
        <h2>{t('EmptyHistoryActivityTitle')}</h2>
        <p>{t('EmptyHistoryActivityDescription')}</p>
      </>
    );
  }, []);
  return (
    <Container>
      {tab === 'open' && openState()}
      {tab === 'scheduled' && scheduleState()}
      {tab === 'history' && historyState()}
    </Container>
  );
};

export default ActivityEmptyStates;
