import { useCallback } from 'react';
import { useStartConversationMutation } from '../../graphql/generated';
import { useHistory } from 'react-router';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';
import { useDefaultMutationErrorHandler } from './useDefaultMutationErrorHandler';
import { ApolloError } from '@apollo/client';

type UseDirectChatMessageProps = {
  onError?: (error: ApolloError) => unknown;
  onCompleted?: () => unknown;
};
/**
 * Creates a (o uses existing) conversation with the user specified
 * and navigates to the chat page for that conversation
 * @returns Async Function
 */
export const useDirectChatMessage = ({
  onCompleted,
  onError,
}: UseDirectChatMessageProps = {}) => {
  const startConversationErrorHandler = useDefaultMutationErrorHandler({
    tag: '[useDirectChatMessage]',
  });
  const [startConversation, { loading }] = useStartConversationMutation({
    onError: onError || startConversationErrorHandler,
    onCompleted,
  });
  const history = useHistory();
  const directChatMessage = useCallback(
    async (userIds: number | number[], subject?: string) => {
      const { data } = await startConversation({
        variables: {
          startConversationInput: {
            userIds: Array.isArray(userIds) ? userIds : [userIds],
            subject,
          },
        },
      });
      if (data) {
        history.push(
          ROUTES.CHATBOX.replace(
            PATH_PARAMS.CONVERSATION_ID,
            data.startConversation.id
          )
        );
      } else {
        throw new Error('missing data in start conversation');
      }
    },
    []
  );

  return {
    directChatMessage,
    loading,
  };
};
