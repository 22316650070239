import {
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  RefresherEventDetail,
  useIonViewDidLeave,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  chevronBack,
  chevronDownCircleOutline,
} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import {
  EmptyNotificationContainer,
  FilterContainer,
  IonHeaderContainer,
  MarkAllReadButton,
} from './notification-center.styles';
import NotificationCell from '../../components/NotificationCell/NotificationCell';
import { NotificationContent } from '../../components/NotificationCell/NotificationCell.styles';
import { EmptyNotification } from '../../assets/images/EmptyNotificaition';
import ToastHandler from '../../components/ToastHandler/ToastHandler';
import {
  GraphqlNotification,
  useDeleteNotificationMutation,
  useGetNotificationsByUserIdQuery,
  useReadAllUserNotificationsMutation,
  useReadNotificationsMutation,
  useUnreadNotificationsMutation,
} from '../../graphql/generated';
import { AuthContext } from '../../providers/authProvider';
import { GET_NOTIFICATIONS_BY_USER_ID } from '../../graphql/notifications';
import NotificationCellSkeleton from '../../components/NotificationCell/NotificationCell.Skeleton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useNotifications } from '../../providers/notificationsProvider';

const FILTER_STATUS = {
  ALL: 'all',
  READ: 'read',
  UNREAD: 'unread',
  PENDING_INVITES: 'pending_invites',
  PENDING_REQUESTS: 'pending_requests',
};

const NotificationCenter: React.FC = () => {
  const router = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');
  const { user } = useContext(AuthContext);
  const i18n = useTranslation();
  const { setUnReadNotificationsCount } = useNotifications();
  const refetchNotifications = {
    refetchQueries: [
      GET_NOTIFICATIONS_BY_USER_ID, // DocumentNode object parsed with gql
      'GetNotificationsByUserId', // Query name
    ],
  };
  const { data, error, loading, refetch } = useGetNotificationsByUserIdQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {},
      page: 1,
      pageSize: 100,
    },
  });
  const [readNotification] = useReadNotificationsMutation(refetchNotifications);

  const [unreadNotification] =
    useUnreadNotificationsMutation(refetchNotifications);

  const [readAllNotification] =
    useReadAllUserNotificationsMutation(refetchNotifications);

  const [deleteNotification] =
    useDeleteNotificationMutation(refetchNotifications);

  useIonViewDidLeave(() => {
    void refetch().then(
      ({
        data: {
          getNotificationsByUserId: { notifications },
        },
      }) => {
        setUnReadNotificationsCount(
          notifications.filter((n) => n.readAt === null).length
        );
      }
    );
  });

  const MarkAllAsRead = async () => {
    try {
      await readAllNotification({
        variables: {
          userId: user?.id,
        },
      });
      setShowToast(true);
      setToastText('All notifications marked as read');
    } catch (err) {
      alert('Error while marking all notifications as read');
    }
  };

  const filterHandler = (filter: string) => {
    switch (filter) {
      case FILTER_STATUS.ALL: {
        return void refetch({
          filter: {},
        });
      }
      case FILTER_STATUS.READ: {
        return void refetch({
          filter: {
            unread: false,
          },
        });
      }
      case FILTER_STATUS.UNREAD: {
        return void refetch({
          filter: {
            unread: true,
          },
        });
      }
      case FILTER_STATUS.PENDING_INVITES: {
        return void refetch({
          filter: {
            types: ['circleMemberInvite', 'familyPartnerInvite'],
          },
        });
      }
      case FILTER_STATUS.PENDING_REQUESTS: {
        return void refetch({
          filter: {
            types: ['circleMemberRequest', 'familyPartnerRequest'],
          },
        });
      }
      default: {
        return void refetch({
          filter: {},
        }); // refetch all notifications
      }
    }
  };

  const handleReadNotification = async (notificationsIds: number) => {
    try {
      await readNotification({
        variables: {
          notificationsIds,
        },
      });
      setToastText('Notification marked as read');
      setShowToast(true);
    } catch (err) {
      alert('Error while marking notification as read');
    }
  };

  const handleUnReadNotification = async (notificationsIds: number) => {
    try {
      await unreadNotification({
        variables: {
          notificationsIds,
        },
      });
      setToastText('Notification marked as Unread');
      setShowToast(true);
    } catch (err) {
      alert('Error while marking notification as unread');
    }
  };

  const deleteNotificationById = async (id: number, displayToast: boolean) => {
    try {
      await deleteNotification({
        variables: {
          notificationId: id,
        },
      });
      if (displayToast) {
        setToastText('Notification deleted');
        setShowToast(true);
      }
    } catch (err) {
      alert('Error while deleting notification');
    }
  };

  if (error) {
    return (
      <IonPage>
        <IonHeaderContainer>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton fill="clear" onClick={() => router.goBack()}>
                <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
                Back
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <h1>Notifications</h1>
        </IonHeaderContainer>
        <NotificationContent>
          <EmptyNotificationContainer>
            <EmptyNotification />
            <h2>Error loading Notifications</h2>
            <p>Please try again Later.</p>
          </EmptyNotificationContainer>
        </NotificationContent>
      </IonPage>
    );
  }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    try {
      filterHandler(selectedFilter);
    } catch (err) {
      alert('Error while refreshing notifications');
    }
    event.detail.complete();
  };

  return (
    <IonPage>
      <IonHeaderContainer>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={() => router.goBack()}>
              <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
              {i18n.t('back')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <h1>{i18n.t('Notifications.title')}</h1>
        <FilterContainer>
          <IonSelect
            label="Notifications"
            placeholder="All"
            interface="popover"
            onIonChange={({ detail }) => {
              filterHandler(detail.value as string);
              setSelectedFilter(detail.value as string);
            }}
          >
            <IonSelectOption value={FILTER_STATUS.ALL}>
              {i18n.t('Notifications.filter.all')}
            </IonSelectOption>
            <IonSelectOption value={FILTER_STATUS.PENDING_INVITES}>
              {i18n.t('Notifications.filter.pending_invites')}
            </IonSelectOption>
            <IonSelectOption value={FILTER_STATUS.PENDING_REQUESTS}>
              {i18n.t('Notifications.filter.pending_requests')}
            </IonSelectOption>
            <IonSelectOption value={FILTER_STATUS.READ}>
              {i18n.t('Notifications.filter.read')}
            </IonSelectOption>
            <IonSelectOption value={FILTER_STATUS.UNREAD}>
              {i18n.t('Notifications.filter.unread')}
            </IonSelectOption>
          </IonSelect>
          <MarkAllReadButton fill="clear" onClick={MarkAllAsRead}>
            {i18n.t('Notifications.markAllAsRead')}
            <IonIcon slot="end" icon={checkmarkCircleOutline}></IonIcon>
          </MarkAllReadButton>
        </FilterContainer>
      </IonHeaderContainer>

      <NotificationContent className="ion-padding">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>
        {!loading &&
          data?.getNotificationsByUserId?.notifications.map((item) => (
            <NotificationCell
              key={item?.id}
              data={item as GraphqlNotification}
              deleteNotification={deleteNotificationById}
              readNotification={handleReadNotification}
              unreadNotification={handleUnReadNotification}
            />
          ))}

        {loading &&
          [...(Array(4) as [])].map(() => (
            <NotificationCellSkeleton key={Math.random()} />
          ))}
        {data?.getNotificationsByUserId?.notifications?.length === 0 &&
          !loading && (
            <EmptyNotificationContainer>
              <EmptyNotification />
              <h2>{i18n.t('Notifications.emptyState.title')}</h2>
              <p>{i18n.t('Notifications.emptyState.description')}</p>
            </EmptyNotificationContainer>
          )}
      </NotificationContent>
      <ToastHandler
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastText}
        position={'bottom'}
      />
    </IonPage>
  );
};

export default NotificationCenter;
