import React from 'react';
import ActivityCardSkeleton from '../ActivityCard/ActivityCard.Skeleton';
import { CardContainer, TabContent } from './ActivitiesTab.style';
import { Container } from '../ActivityCard/ActivityCard.styles';

const ActivitiesTabSkeleton: React.FC = () => {
  return (
    <>
      <TabContent>
        <CardContainer>
          <Container style={{ position: 'relative', width: '100%' }}>
            <ActivityCardSkeleton />
          </Container>
        </CardContainer>
      </TabContent>
      <TabContent>
        <CardContainer>
          <Container style={{ position: 'relative', width: '100%' }}>
            <ActivityCardSkeleton />
          </Container>
        </CardContainer>
      </TabContent>
      <TabContent>
        <CardContainer>
          <Container style={{ position: 'relative', width: '100%' }}>
            <ActivityCardSkeleton />
          </Container>
        </CardContainer>
      </TabContent>
    </>
  );
};

export default ActivitiesTabSkeleton;
