import styled from 'styled-components';
import VerticalDots from '../../assets/VerticalDots.svg';
export const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  ion-select::part(icon) {
    width: 20px;
    height: 20px;
    content: url(${VerticalDots});
  }
  ion-select::part(text) {
    display: none;
  }
`;
