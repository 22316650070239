import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SignInButton, SignUpButton, StyledIonContent } from './login.style';
import WelcomeSlider from '../../components/WelcomeSlider/WelcomeSlider';
import { useLogin } from '../../utils/hooks/useLogin';
import { useState } from 'react';
import { cogOutline } from 'ionicons/icons';
import ModalDebugSettings from '../../components/ModalDebugSettings/ModalDebugSettings';
import usePreventBackButton from '../../utils/hooks/usePreventBackButton';

const LoginPage: React.FC = () => {
  const i18n = useTranslation();
  const { sign, fetchingAuthId } = useLogin();
  const [showDebugSettings, setShowDebugSettings] = useState(false);
  usePreventBackButton();

  return (
    <IonPage>
      <StyledIonContent>
        <WelcomeSlider />
        <SignInButton
          expand="block"
          disabled={fetchingAuthId}
          onClick={() => {
            void sign('signin');
          }}
        >
          <>{i18n.t('login')}</>
        </SignInButton>
        <SignUpButton
          expand="block"
          disabled={fetchingAuthId}
          onClick={() => {
            void sign('signup');
          }}
          fill="outline"
        >
          <>{i18n.t('joinNow')}</>
        </SignUpButton>
        <ModalDebugSettings
          isOpen={showDebugSettings}
          onClose={() => setShowDebugSettings(false)}
        />
      </StyledIonContent>
      {/* TODO: Should we update this condition? If so, maybe 
      we should implement public feature flags and consume a flag here */}
      <IonFooter>
        <IonToolbar>
          <IonTitle>{process.env.REACT_APP_BUILD_VERSION || '0.0.0'}</IonTitle>
          <IonButtons slot="end">
            <IonButton color="dark" onClick={() => setShowDebugSettings(true)}>
              <IonIcon icon={cogOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default LoginPage;
