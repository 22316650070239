import React, { useState } from 'react';
import { IonSkeletonText, IonSpinner, IonToggle } from '@ionic/react';
import {
  Container,
  FavoriteContainer,
  FavoriteTextContainer,
  ProContainer,
  ProDescription,
  ProLabel,
} from './ProDetails.styles';
import { GraphqlCustomerAgent } from '../../graphql/generated';
import {
  FAVORITE_AGENT,
  GET_AGENT,
  UNFAVORITE_AGENT,
} from '../../graphql/proProfile';
import { useMutation } from '@apollo/client';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import ToastHandler from '../ToastHandler/ToastHandler';

interface IProDetails {
  data?: GraphqlCustomerAgent;
  customerId: number;
  loading: boolean;
  agentServiceType: string;
  isFavorite?: boolean;
}

const ProDetails: React.FC<IProDetails> = ({
  data,
  loading,
  customerId,
  agentServiceType,
  isFavorite,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [addFavorite, { loading: loadingFavorite }] = useMutation(
    FAVORITE_AGENT,
    {
      variables: {
        customerId,
        serviceType: agentServiceType,
        agentId: data?.userId,
      },
      refetchQueries: [GET_AGENT, 'GetCustomerAgent'],
    }
  );
  const [removeFavorite, { loading: loadingUnFavorite }] = useMutation(
    UNFAVORITE_AGENT,
    {
      variables: {
        serviceType: agentServiceType,
        agentId: data?.userId,
      },
      refetchQueries: [GET_AGENT, 'GetCustomerAgent'],
    }
  );
  const toggleFavorite = async (): Promise<void> => {
    if (isFavorite) {
      try {
        await removeFavorite();
        setToastText('Removed from favorites');
        setShowToast(true);
      } catch (e) {
        alert('Error removing favorite try again later');
      }
    } else {
      try {
        await addFavorite();
        setToastText('Added to favorites');
        setShowToast(true);
      } catch (e) {
        alert('Error adding favorite try again later');
      }
    }
  };

  return (
    <Container>
      <ProContainer>
        <ProLabel>Name</ProLabel>
        <ProDescription>
          {loading ? (
            <IonSkeletonText animated style={{ width: '80%' }} />
          ) : (
            data?.User?.fullName
          )}
        </ProDescription>
      </ProContainer>
      <ProContainer>
        <ProLabel>Company</ProLabel>
        <ProDescription>
          {loading ? (
            <IonSkeletonText animated style={{ width: '80%' }} />
          ) : (
            data?.Company?.name
          )}
        </ProDescription>
      </ProContainer>
      <ProContainer>
        <ProLabel>Services Provided</ProLabel>
        <ProDescription>
          {loading ? (
            <IonSkeletonText animated style={{ width: '80%' }} />
          ) : (
            data?.serviceTypeInfo?.map((serviceType, i: number) => (
              <React.Fragment key={i}>
                {serviceType?.name}
                {i !== data?.serviceTypes?.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))
          )}
        </ProDescription>
      </ProContainer>
      <ProContainer>
        <ProLabel>Location</ProLabel>
        <ProDescription>
          {loading ? (
            <IonSkeletonText animated style={{ width: '80%' }} />
          ) : (
            <>
              {data?.Company?.city}
              {', '}
            </>
          )}

          {data?.Company?.state}
        </ProDescription>
      </ProContainer>
      {data?.Company?.website && (
        <ProContainer>
          <ProLabel>Website</ProLabel>
          <ProDescription>
            {loading ? (
              <IonSkeletonText animated style={{ width: '80%' }} />
            ) : (
              data?.Company?.website
            )}
          </ProDescription>
        </ProContainer>
      )}
      <FavoriteContainer>
        <FavoriteTextContainer>
          {(loadingFavorite || loadingUnFavorite) && (
            <AnimateMotionDiv>
              <div style={{ position: 'absolute', left: 150, top: 16 }}>
                <IonSpinner
                  color="primary"
                  style={{ width: 18, marginLeft: 8 }}
                />
              </div>
            </AnimateMotionDiv>
          )}
          <h3>Mark as favorite </h3>
        </FavoriteTextContainer>
        <IonToggle
          checked={isFavorite}
          onIonChange={() => void toggleFavorite()}
        />
      </FavoriteContainer>

      <ToastHandler
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastText}
        position={'bottom'}
      />
    </Container>
  );
};

export default ProDetails;
