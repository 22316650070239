/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonInput,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonToggle,
  IonTextarea,
  IonSpinner,
  IonPage,
} from '@ionic/react';
import {
  AddNewAddressErrorZipCode,
  AddNewAddressText,
  AddNewAddressTextOrange,
  CardActionContainer,
  FrequencyInfo,
  FrequencyTitle,
  InputContainer,
  StyledIonAlert,
  StyledIonAlertGoBack,
  StyledIonContent,
  StyledIonHeader,
  Wrapper,
} from './service-form.styles';
import {
  arrowForwardOutline,
  calendarClearOutline,
  checkmarkCircle,
  chevronBack,
  repeatOutline,
} from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import { HomeIcon } from '../../assets/images/HomeIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router';
import AvailabilityModal from '../../components/AvailabilityModal/AvailabilityModal';
import AnimateMotionDiv from '../../components/AnimateMotionDiv/AnimateMotionDiv';
import { CREATE_SERVICE_REQUEST_MUTATION } from '../../graphql/activities';
import { useMutation } from '@apollo/client';
import {
  GraphqlAddresses,
  ServiceRequestAvailabilityInput,
  useGetAddressesQuery,
  useListRequestCategoriesQuery,
  useGetGooglePlacesApiKeyQuery,
  useGetZipCodeMatchesBusinessRulesQuery,
  ServiceRequestType,
} from '../../graphql/generated';
import RideForm from '../../components/RideForm/RideForm';
import { AuthContext } from '../../providers/authProvider';
import { getWhoIsThisId } from '../../utils/whoIsThisHelper';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../providers/featureFlagsProvider';
import { FeatureNames } from '../../constants/enums';
import { logger } from '../../logger';
import { getApolloErrorMessage } from '../../utils/apollo/errors';
import { useAppContext } from '../../providers/appContextProvider';
import { ModalAddressForm } from '../../components/ModalAddressForm/ModalAddressForm';
import { PATH_PARAMS, ROUTES } from '../../constants/routes';
import { useDateWithTimezone } from '../../utils/hooks/useDateWithTimezone';

type LocationProps = {
  state: {
    text: string;
    category: string;
    recurring: boolean;
  };
};

const ServiceForm: React.FC = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalAlert } = useAppContext();
  const history = useHistory();
  const location = useLocation() as unknown as LocationProps;
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>();
  const { user } = useContext(AuthContext);
  const { data: apiKeyData } = useGetGooglePlacesApiKeyQuery();
  const googlePlaceApiKey =
    apiKeyData?.getGooglePlacesApiKey.apiKey ||
    'AIzaSyBuBLG_5YjAoQqWlroik6tvWDMdXKIonNI';
  const { transformAvailabilityToTimezone } = useDateWithTimezone();

  const [dateType, setDateType] = useState<string>('flexible');
  const [categoryValue, setCategoryValue] = useState<string>(
    location?.state?.category
  );
  const [availabilitySaved, setAvailabilitySaved] = useState<boolean>(false);
  const [showGoBackAlert, setShowGoBackAlert] = useState<boolean>(false);
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [isOpenAddressModal, setOpenAddressModal] = useState<boolean>(false);
  const [showAlertError, setShowAlertError] = useState<boolean>(false);
  const [showOtherCategory, setShowOtherCategory] = useState<boolean>(false);
  const [showRideForm, setShowRideForm] = useState<boolean>(false);
  const [showTimeDate, setShowTimeDate] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [addressId, setAddressId] = useState<number | null>(null);
  const i18n = useTranslation();

  const [userDefaultAddress, setUserDefaultAddress] =
    useState<GraphqlAddresses>();
  const [showAvailabilityModal, setShowAvailabilityModal] =
    useState<boolean>(false);

  const {
    data,
    loading: loadingAddress,
    refetch: refetchAddresses,
  } = useGetAddressesQuery({
    skip: !selectedCustomerId,
    variables: {
      customerId: Number(selectedCustomerId),
    },
  });
  const [createServiceRequest, { loading }] = useMutation(
    CREATE_SERVICE_REQUEST_MUTATION
  );

  const { data: zipCodeCheck } = useGetZipCodeMatchesBusinessRulesQuery({
    variables: {
      zipCode: zipCode ? zipCode : userDefaultAddress?.zipCode ?? '',
    },
    skip: !userDefaultAddress,
  });

  const { data: getListRequestCategories } = useListRequestCategoriesQuery({
    variables: {
      serviceRequest: true,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    resetField,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm();

  const [tentativeScheduleValue] = watch(['tentativeSchedule']);

  const goBack = useCallback(() => {
    const FormValues = getValues([
      'description',
      'category',
      'tentativeSchedule',
    ]);
    if (FormValues.length > 0) {
      setShowGoBackAlert(true);
    } else {
      history.goBack();
    }
  }, []);

  const clearForm = useCallback(() => {
    resetField('description');
    resetField('category');
    setCategoryValue('');
    resetField('tentativeSchedule');
    setIsRecurring(false);
    setDateType('flexible');
    setShowOtherCategory(false);
    setShowRideForm(false);
    setShowTimeDate(false);
  }, []);

  const errorText = {
    description: {
      type: 'manual',
      name: 'description',
      message: 'You must add a description',
    },
    category: {
      type: 'manual',
      name: 'description',
      message: 'You must select a Category',
    },
    tentativeSchedule: {
      type: 'manual',
      name: 'description',
      message: 'You must select an option',
    },
    frequency: {
      type: 'manual',
      name: 'description',
      message: 'You must add a frequency',
    },
    ride: {
      dropoffAddress: {
        addressLine1: {
          type: 'manual',
          name: 'description',
          message: 'You must add a Drop-off Location',
        },
      },
    },
  };

  const handleFormErrors = ({
    description,
    category,
    tentativeSchedule,
    frequency,
    ride,
  }: any) => {
    if (description === '') {
      setError('description', errorText.description);
    }
    if (!category) {
      setError('category', errorText.category);
    }
    if (!tentativeSchedule) {
      setError('tentativeSchedule', errorText.tentativeSchedule);
    }
    if (frequency === '') {
      setError('frequency', errorText.frequency);
    }

    if (ride && !ride?.dropoffAddress?.addressLine1) {
      setError('dropoffAddress', errorText.ride?.dropoffAddress.addressLine1);
    }
    if (
      dateType === 'addAvailability' &&
      getValues('availability')?.length === 0
    ) {
      alert('Please add availability');
      return;
    }
    if (description === '' || !category || !tentativeSchedule) {
      return setShowAlertError(true);
    }
    if (description !== '' && category && tentativeSchedule) return true;
  };

  const alertErrorMessage = (): string =>
    `
      ${errors.description ? `・${errors.description?.message as string}` : ''}
      ${
        errors.category?.message
          ? `・${errors.category?.message as string}`
          : ''
      }
      ${
        errors.tentativeSchedule?.message
          ? `・${errors.tentativeSchedule?.message as string}`
          : ''
      }
  `;
  const onSubmit = useCallback(
    async (formData: any): Promise<void> => {
      try {
        if (formData.category !== 'ride') {
          formData.frequency = formData.frequency?.toLowerCase();
          formData.addressId = addressId
            ? Number(addressId)
            : userDefaultAddress?.id;
          delete formData.ride;
          delete formData.home;
        }
        if (formData.category === 'ride') {
          delete formData.home;
          formData.ride.pickupAddress.addressLine1 =
            userDefaultAddress?.addressLine1;
          formData.ride.pickupAddress.addressLine2 =
            userDefaultAddress?.addressLine2;
          formData.ride.pickupAddress.city = userDefaultAddress?.city;
          formData.ride.pickupAddress.state = userDefaultAddress?.state;
          formData.ride.pickupAddress.country = userDefaultAddress?.country;
          formData.ride.pickupAddress.zipCode = userDefaultAddress?.zipCode;

          if (!formData.ride?.dropoffAddress?.addressLine1) {
            formData.ride.dropoffAddress = {};
            formData.ride.dropoffAddress.addressLine1 = '';
            formData.ride.dropoffAddress.addressLine2 = '';
            formData.ride.dropoffAddress.city = '';
            formData.ride.dropoffAddress.state = '';
            formData.ride.dropoffAddress.country = '';
            formData.ride.dropoffAddress.zipCode = '';
          }
        }
        if (handleFormErrors(formData)) {
          if (formData?.availabilities?.length > 0) {
            await createServiceRequest({
              variables: {
                data: {
                  ...formData,
                  availabilities: formData.availabilities.map(
                    (availability: ServiceRequestAvailabilityInput) =>
                      transformAvailabilityToTimezone(availability)
                  ),
                },
              },
            });
          } else {
            await createServiceRequest({ variables: { data: formData } });
          }
          history.push(
            ROUTES.SERVICE_SUBMIT_SUCCESS.replace(
              PATH_PARAMS.REQUEST_TYPE,
              ServiceRequestType.Service
            )
          );
        }
      } catch (err) {
        logger.error({
          tag: '[SERVICE_FORM_SUBMISSION_ERROR]',
          message: `Something went wrong trying to submit the form: ${
            getApolloErrorMessage(err) || err?.toString()
          }`,
          error: err as Error,
        });
        globalAlert({
          title: <Trans i18nKey="genericError.title" />,
          subtitle: getApolloErrorMessage(err) || (
            <Trans i18nKey="genericError.subtitle" />
          ),
          firstButtonLabel: <Trans i18nKey="genericError.primaryLabel" />,
        });
      }
    },
    [data, userDefaultAddress, createServiceRequest, handleFormErrors]
  );

  const frequencyOptions = [
    {
      name: i18n.t('serviceForm.weekly'),
    },
    {
      name: i18n.t('serviceForm.biWeekly'),
    },
    {
      name: i18n.t('serviceForm.monthly'),
    },
  ];

  const handleRecurringChange = useCallback(
    (value: string) => {
      setValue('recurring', true);
      setValue('frequency', value.toLowerCase());
    },
    [setValue]
  );

  const handleUserTextRequest = useCallback((text: string) => {
    setValue('category', text);
    setCategoryValue(text);
  }, []);

  const handleCategory = useCallback((category: string) => {
    setValue('category', category);
    setCategoryValue(category);
    if (category === 'other') {
      setShowOtherCategory(true);
    } else {
      setShowOtherCategory(false);
    }
    if (category === 'ride') {
      setShowRideForm(true);
    } else {
      setShowRideForm(false);
    }
  }, []);

  const handleTimeAndDate = useCallback((value: string) => {
    setShowTimeDate(true);
    if (value === 'chooseTimeAndDate') {
      setDateType('addAvailability');
      setAvailabilitySaved(false);
      setValue('isAvailabilityFlexible', false);
    } else {
      setDateType('flexible');
      setValue('isAvailabilityFlexible', true);
    }
  }, []);

  useEffect(() => {
    if (data?.getAddresses) {
      setUserDefaultAddress(
        data?.getAddresses.filter(
          (item) => item.isDefault
        )[0] as GraphqlAddresses
      );
    }
  }, [data, loadingAddress]);

  useEffect(() => {
    setValue('requestedBy', user?.id);
    setValue('availabilities', []);
    setValue('description', location?.state?.text);
    setValue('recurring', false);
    setValue('isAvailabilityFlexible', false);
    setValue(
      'home',
      (userDefaultAddress?.addressLine1 ?? '') +
        ', ' +
        (userDefaultAddress?.city ?? '')
    );
    if (getValues('category') === 'ride') {
      setShowRideForm(true);
      setValue('ride.pickupAddress.addressLine1', 'Home');
    }
    getWhoIsThisId()
      .then((res) => {
        setSelectedCustomerId(res);
        setValue('customerId', res);
      })
      .catch((err) => alert(err));
  }, [userDefaultAddress]);

  const handleHome = useCallback((value: string) => {
    const parts = value.split(',');
    const beforeCommaZipCode = parts[0].trim();
    const afterCommaAddressLine = parts[1].trim();
    const lastAddressId = parts[2].trim();
    setValue('home', afterCommaAddressLine + ', ' + beforeCommaZipCode);
    setZipCode(beforeCommaZipCode);
    setAddressId(Number(lastAddressId));
  }, []);

  const handleOnSaveAddress = async () => {
    setOpenAddressModal(false);
    await refetchAddresses();
  };

  return (
    <IonPage>
      <StyledIonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton fill="clear" onClick={goBack}>
              <IonIcon slot="icon-only" icon={chevronBack}></IonIcon>
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <h1>Service request</h1>
        <div className="border" />
      </StyledIonHeader>

      <StyledIonContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper hasError={errors.description && true}>
            <h2 className="fist-item">Does this look right?</h2>
            <IonTextarea
              counter={true}
              autoGrow
              placeholder="Please describe how we can help"
              {...register('description')}
              onIonBlur={() => clearErrors('description')}
              onIonChange={({ detail }) => {
                handleUserTextRequest(detail.value as string);
              }}
            ></IonTextarea>
            {errors.description && (
              <p className="form-error">
                {errors.description?.message as string}
              </p>
            )}
          </Wrapper>
          <Wrapper hasError={errors.category && true}>
            <h2>Category</h2>
            <IonSelect
              {...register('category')}
              labelPlacement="floating"
              interface="popover"
              shape="round"
              placeholder="Select"
              value={categoryValue}
              onIonBlur={() => clearErrors('category')}
              onIonChange={({ detail }) => {
                handleCategory(detail.value as string);
              }}
            >
              {getListRequestCategories?.listRequestCategories.map(
                (serviceType) => (
                  <IonSelectOption
                    key={serviceType.name}
                    value={serviceType.id}
                  >
                    {serviceType.name}
                  </IonSelectOption>
                )
              )}
            </IonSelect>
            {errors.category && (
              <p className="form-error">{errors.category?.message as string}</p>
            )}
            {showRideForm && (
              <AnimateMotionDiv>
                <RideForm
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                  userDefaultAddress={userDefaultAddress}
                  userAddresses={data}
                  onAddressModal={() => setOpenAddressModal(true)}
                  getAddressId={(id: number) => setAddressId(id)}
                  getValues={getValues}
                />
              </AnimateMotionDiv>
            )}
            {showOtherCategory && (
              <AnimateMotionDiv>
                <InputContainer style={{ marginTop: '16px' }}>
                  <IonInput
                    aria-label="Enter category"
                    placeholder="Enter category"
                    {...register('otherCategory')}
                  ></IonInput>
                </InputContainer>
              </AnimateMotionDiv>
            )}
          </Wrapper>

          {!showRideForm && data?.getAddresses.length === 1 && (
            <AnimateMotionDiv>
              <Wrapper
                hasError={!zipCodeCheck?.getZipCodeMatchesBusinessRules.success}
              >
                <h2>Confirm your location</h2>
                <InputContainer className="input-container">
                  <HomeIcon style={{ marginRight: '8px' }} />
                  <IonInput
                    aria-label="Custom input"
                    placeholder="Home"
                    value={'Home'}
                  ></IonInput>
                </InputContainer>
                {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                  <AddNewAddressErrorZipCode>
                    {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                  </AddNewAddressErrorZipCode>
                )}
                {isFeatureEnabled(
                  FeatureNames.AddAddressForRequestEntryPoint
                ) ? (
                  <AddNewAddressText>
                    {i18n.t('serviceRequest.lookingFor')}
                    <AddNewAddressTextOrange
                      onClick={() => setOpenAddressModal(true)}
                    >
                      {i18n.t('serviceRequest.addANew')}
                    </AddNewAddressTextOrange>
                  </AddNewAddressText>
                ) : null}
              </Wrapper>
            </AnimateMotionDiv>
          )}

          {!showRideForm &&
            data?.getAddresses &&
            data?.getAddresses.length > 1 && (
              <AnimateMotionDiv>
                <Wrapper
                  hasError={
                    !zipCodeCheck?.getZipCodeMatchesBusinessRules.success
                  }
                >
                  <h2>Confirm your location</h2>
                  <div style={{ position: 'relative', top: 15, left: 16 }}>
                    <HomeIcon style={{ position: 'absolute' }} />
                  </div>
                  <IonSelect
                    {...register('home')}
                    labelPlacement="floating"
                    interface="popover"
                    shape="round"
                    placeholder={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    value={
                      getValues('home')
                        ? getValues('home')
                        : (userDefaultAddress?.addressLine1 ?? '') +
                          ', ' +
                          (userDefaultAddress?.city ?? '')
                    }
                    onIonBlur={() => clearErrors('home')}
                    className="address-select"
                    onIonChange={({ detail }) => {
                      handleHome(detail.value as string);
                    }}
                  >
                    {data?.getAddresses.map((address) => (
                      <IonSelectOption
                        key={address.id}
                        value={
                          address.zipCode +
                          ',' +
                          address.addressLine1 +
                          ',' +
                          address.id.toString()
                        }
                      >
                        {address.addressLine1}, {address.city}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  {!zipCodeCheck?.getZipCodeMatchesBusinessRules.success && (
                    <AddNewAddressErrorZipCode>
                      {zipCodeCheck?.getZipCodeMatchesBusinessRules.message}
                    </AddNewAddressErrorZipCode>
                  )}
                  {isFeatureEnabled(
                    FeatureNames.AddAddressForRequestEntryPoint
                  ) ? (
                    <AddNewAddressText>
                      {i18n.t('serviceRequest.lookingFor')}
                      <AddNewAddressTextOrange
                        onClick={() => setOpenAddressModal(true)}
                      >
                        {i18n.t('serviceRequest.addANew')}
                      </AddNewAddressTextOrange>
                    </AddNewAddressText>
                  ) : null}
                </Wrapper>
              </AnimateMotionDiv>
            )}

          <Wrapper hasError={errors.tentativeSchedule && true}>
            <h2>When can we make this happen for you? </h2>
            <IonSelect
              placeholder={getValues('tentativeSchedule') || 'Select'}
              {...register('tentativeSchedule')}
              onIonBlur={() => clearErrors('tentativeSchedule')}
              labelPlacement="floating"
              interface="popover"
              onIonChange={({ detail }) => {
                handleTimeAndDate(detail.value as string);
              }}
            >
              <IonSelectOption value="asSoonAsPossible">
                As soon as possible
              </IonSelectOption>
              <IonSelectOption value="withinDays">
                Within 3 days
              </IonSelectOption>
              <IonSelectOption value="withinWeeks">
                Within a week
              </IonSelectOption>
              <IonSelectOption value="chooseTimeAndDate">
                Choose time & date
              </IonSelectOption>
            </IonSelect>

            {errors.tentativeSchedule && (
              <p className="form-error">
                {errors.tentativeSchedule?.message as string}
              </p>
            )}
          </Wrapper>

          {showTimeDate && (
            <AnimateMotionDiv>
              <Wrapper>
                <IonRadioGroup
                  value={dateType}
                  onIonChange={({ detail }) => {
                    setDateType(detail.value as string);
                    setValue(
                      'isAvailabilityFlexible',
                      detail.value === 'flexible' ? true : false
                    );
                  }}
                >
                  <IonRadio
                    labelPlacement="end"
                    value="flexible"
                    aria-label="Custom checkbox"
                  >
                    Flexible
                  </IonRadio>
                  <br />
                  <IonRadio
                    labelPlacement="end"
                    value="addAvailability"
                    aria-label="Custom checkbox that is checked"
                  >
                    Add availability
                  </IonRadio>
                </IonRadioGroup>
              </Wrapper>
            </AnimateMotionDiv>
          )}
          {dateType === 'addAvailability' && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Wrapper>
                  <CardActionContainer
                    borderColor={availabilitySaved ? '#EA8231' : ''}
                    onClick={() => setShowAvailabilityModal(true)}
                  >
                    {isRecurring && (
                      <div>
                        <IonIcon
                          icon={repeatOutline}
                          style={{ width: 24, height: 24 }}
                        />
                        <span>{i18n.t('serviceForm.recurringService')}</span>
                      </div>
                    )}
                    <div>
                      <IonIcon
                        icon={calendarClearOutline}
                        style={{
                          color: '#23364B',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                      <span>Enter availability</span>
                    </div>
                    {!availabilitySaved && (
                      <IonIcon
                        icon={arrowForwardOutline}
                        style={{
                          color: '#23364B',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    )}
                    {availabilitySaved && (
                      <IonIcon
                        icon={checkmarkCircle}
                        style={{
                          color: '#EA8231',
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    )}
                  </CardActionContainer>
                </Wrapper>
              </motion.div>
            </AnimatePresence>
          )}

          {isFeatureEnabled(FeatureNames.RequestRecurringInput) ? (
            <Wrapper>
              <CardActionContainer>
                <div>
                  <IonIcon
                    icon={repeatOutline}
                    style={{ width: 24, height: 24 }}
                  />
                  <span>{i18n.t('serviceForm.recurringService')}</span>
                </div>
                <IonToggle
                  checked={isRecurring}
                  onIonChange={({ detail }) => {
                    setIsRecurring(detail.checked);
                    setValue('recurring', detail.checked);
                  }}
                  {...register('recurring')}
                ></IonToggle>
              </CardActionContainer>
            </Wrapper>
          ) : null}
          {isRecurring && (
            <AnimateMotionDiv>
              <Wrapper hasError={errors.frequency && true}>
                <FrequencyTitle>
                  {i18n.t('serviceForm.frequency')}
                </FrequencyTitle>
                <FrequencyInfo>{i18n.t('serviceForm.freqInfo')}</FrequencyInfo>
                <IonSelect
                  placeholder={getValues('frequency') || 'Select'}
                  {...register('frequency')}
                  labelPlacement="floating"
                  interface="popover"
                  shape="round"
                  onIonBlur={() => clearErrors('frequency')}
                  onIonChange={({ detail }) => {
                    handleRecurringChange(detail.value as string);
                  }}
                  style={{ marginTop: 16 }}
                >
                  {frequencyOptions.map(({ name }) => (
                    <IonSelectOption
                      key={name}
                      aria-label={name || 'All'}
                      className="activity-options"
                      value={name}
                    >
                      {name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                {errors.frequency && (
                  <p className="form-error">
                    {errors.frequency?.message as string}
                  </p>
                )}
              </Wrapper>
            </AnimateMotionDiv>
          )}

          <Wrapper className="form-actions">
            <IonButton
              shape="round"
              size="large"
              type="submit"
              disabled={!zipCodeCheck?.getZipCodeMatchesBusinessRules.success}
            >
              Ready!&nbsp; {loading && <IonSpinner name="lines-small" />}
            </IonButton>
            <IonButton
              onClick={clearForm}
              shape="round"
              fill="outline"
              type="reset"
            >
              Clear form
            </IonButton>
          </Wrapper>
        </form>
        <StyledIonAlertGoBack
          isOpen={showGoBackAlert}
          header="You have unsaved changes. Are you sure you want to go back?"
          onDidDismiss={() => setShowGoBackAlert(false)}
          buttons={[
            {
              text: 'Yes, go back',
              cssClass: 'btn alert-button-confirm',
              handler: () => history.goBack(),
            },
            {
              text: 'Cancel',
              cssClass: 'btn alert-button-cancel',
            },
          ]}
        />
        <StyledIonAlert
          isOpen={showAlertError}
          header="Whoops! It looks like you are missing some information."
          message={alertErrorMessage()}
          onDidDismiss={() => setShowAlertError(false)}
          buttons={[
            {
              text: 'OK',
              cssClass: 'alert-button-confirm',
            },
          ]}
        />
        <AvailabilityModal
          setValue={setValue}
          AvailabilityModalIsOpen={showAvailabilityModal}
          setAvailabilityModalIsOpen={setShowAvailabilityModal}
          setAvailabilitySaved={setAvailabilitySaved}
          tentativeSchedule={tentativeScheduleValue}
        />
      </StyledIonContent>

      <StyledIonAlertGoBack
        isOpen={showGoBackAlert}
        header="You have unsaved changes. Are you sure you want to go back?"
        onDidDismiss={() => setShowGoBackAlert(false)}
        buttons={[
          {
            text: 'Yes, go back',
            cssClass: 'btn alert-button-confirm',
            handler: () => history.goBack(),
          },
          {
            text: 'Cancel',
            cssClass: 'btn alert-button-cancel',
          },
        ]}
      />
      <StyledIonAlert
        isOpen={showAlertError}
        header="Whoops! It looks like you are missing some information."
        message={alertErrorMessage()}
        onDidDismiss={() => setShowAlertError(false)}
        buttons={[
          {
            text: 'OK',
            cssClass: 'alert-button-confirm',
          },
        ]}
      />
      <AvailabilityModal
        setValue={setValue}
        AvailabilityModalIsOpen={showAvailabilityModal}
        setAvailabilityModalIsOpen={setShowAvailabilityModal}
        setAvailabilitySaved={setAvailabilitySaved}
        tentativeSchedule={tentativeScheduleValue}
      />
      <ModalAddressForm
        key={`${isOpenAddressModal}`}
        customerId={Number(selectedCustomerId)}
        googlePlacesApiKey={googlePlaceApiKey}
        isOpen={isOpenAddressModal}
        onDidDismiss={() => setOpenAddressModal(false)}
        onSaveAddress={handleOnSaveAddress}
      />
    </IonPage>
  );
};

export default ServiceForm;
