import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import {
  StyledIonModal,
  Title,
  BackLabel,
  StripeLoadingContainer,
} from './ModalAddPayment.style';
import { useTranslation } from 'react-i18next';

import {
  IonAlert,
  IonButtons,
  IonContent,
  IonHeader,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { t } from 'i18next';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SaveCardButton } from '../../pages/payment/payment.style';

import { logger } from '../../logger';
import { BryaLogo } from '../../assets/images/BryaLogo';
import { SetupIntent } from '@stripe/stripe-js';

interface ModalAddPaymentI {
  isOpenModal: boolean;
  onOpenModal: (modalOpen: boolean) => void;
  onNewCardAdded: (card: SetupIntent) => void;
}

const ModalAddPayment: React.FC<ModalAddPaymentI> = ({
  isOpenModal,
  onOpenModal,
  onNewCardAdded,
}) => {
  const i18n = useTranslation();

  const handleModalDismiss = useCallback(() => {
    onOpenModal(false);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showAlertError, setShowAlertError] = useState<boolean>(false);

  const [isStripeLoaded, setIsStripeLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadStripeElements = () => {
      if (stripe && elements) {
        setIsStripeLoaded(true);
      }
    };

    loadStripeElements();
  }, [stripe, elements]);

  const handleSubmit = async (event: MouseEvent) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    event.stopPropagation();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      logger.warn({
        tag: '[MODAL_ADD_PAYMENT_SUBMIT]',
        message: 'User tried to submit before stripe is loaded',
      });
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
    });
    setIsLoading(false);
    if (!error) {
      // onOpenModal(false);
      // refetchPaymentMethods();
      onNewCardAdded(setupIntent);
    }
    if (error) {
      setErrorMessage(error.message as string);
      setShowAlertError(true);
    }
  };

  return (
    <>
      <StyledIonModal
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        isOpen={isOpenModal}
        onDidDismiss={handleModalDismiss}
      >
        <IonHeader className="ion-padding ion-no-border">
          <IonToolbar color="#fff">
            <IonButtons slot="start" onClick={handleModalDismiss}>
              <BackLabel>{t('back')}</BackLabel>
            </IonButtons>
            <IonTitle className="ion-text-center">
              <Title> {i18n.t('payment.addNewCard')}</Title>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {isStripeLoaded && (
            <>
              <PaymentElement />
              <SaveCardButton
                type="submit"
                onClick={handleSubmit}
                disabled={!stripe && isLoading}
              >
                <>
                  {t('payment.saveCard')} &nbsp;
                  {isLoading && <IonSpinner name="lines-small" />}
                </>
              </SaveCardButton>
            </>
          )}
          {!isStripeLoaded && (
            <StripeLoadingContainer>
              <IonSpinner
                name="circles"
                color={'warning'}
                className="brya-spinner"
              />
              <BryaLogo style={{ position: 'absolute' }} />
            </StripeLoadingContainer>
          )}
        </IonContent>
      </StyledIonModal>
      <IonAlert
        isOpen={showAlertError}
        header={errorMessage}
        subHeader="Please verify your card details and try again. If the problem persists, please contact us."
        onDidDismiss={() => setShowAlertError(false)}
        buttons={[
          {
            text: 'OK',
            cssClass: 'alert-button-confirm',
          },
        ]}
      />
    </>
  );
};

export default ModalAddPayment;
