import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryTitle, TertiaryTitle } from '../Typography/Headings/Headings';
import { BodyText } from '../Typography/Body/Body';
import AnimateMotionDiv from '../AnimateMotionDiv/AnimateMotionDiv';
import { Title } from '../ModalPostVisitInfo/ModalPostVisitInfo.style';
import { Button } from '../Button/Button';
import CostBox from '../CostTableCard/CostBox';
import CostLine from '../CostTableCard/CostLine';
import { CostBreakDown } from '../CostBreakDown/CostBreakDown';
import PaymentMethod from '../CostTableCard/PaymentMethod';
import {
  GetServiceRequestQuery,
  GetServiceRequestReviewQuery,
  GraphqlServiceRequestPayment,
  ServicePaymentType,
  ServiceRequestStatus,
  useConfirmStripeTransactionMutation,
  useContactSupportForExtraChargeMutation,
} from '../../graphql/generated';
import { CustomHr, IconContainer } from './ServiceSummary.styles';
import { Steps } from './ServiceSummary';
import IconPaymentCompleted from '../../assets/images/IconPaymentCompleted';
import Badge from '../Badge/Badge';
import { SelectPaymentCard } from '../SelectPaymentCard/SelectPaymentCard';
import { useUpdateCardMutations } from '../../utils/hooks/useUpdateCardMutations';
import { useAuthContext } from '../../providers/authProvider';
import dayjs from 'dayjs';
import { logger } from '../../logger';
import { StripeTransactionStatus } from '../../utils/hooks/useGetOutstandingBalanceStatus';
import { useTalkJsChat } from '../../utils/hooks/useTalkJsChat';
import { FullScreenLoader } from '../FullScreenLoader/FullScreenLoader';
import { calculateTotalValueWithLabel } from '../../utils/helper';
import ModalPaymentFailed from '../ModalPaymentFailed/ModalPaymentFailed';
import { GET_SERVICE_REQUEST } from '../../graphql/service';
import {
  GET_ACTIVITIES_ALERTS,
  GET_ACTIVITIES_GROUPED_BY_DATE,
} from '../../graphql/activities';
import { formatNumber } from '../../utils/formatNumber';

type ProcessPaymentProps = {
  serviceSummaryData?: GetServiceRequestQuery;
  serviceReqReviewData?: GetServiceRequestReviewQuery;
  setNewStep: (Steps: Steps) => void;
  onClose: () => void;
};

export const ServicePaymentTypeLabel = {
  [ServicePaymentType.Fixed]: 'Fixed',
  [ServicePaymentType.Hour]: 'Hourly Rate',
  [ServicePaymentType.Visit]: 'Visit',
};

export const ProcessPayment: FC<ProcessPaymentProps> = ({
  serviceSummaryData,
  serviceReqReviewData,
  setNewStep,
  onClose,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { handleGetBryaTeamChat, getBryaTeamChatQueryLoading } =
    useTalkJsChat();
  const [onConfirmError, setOnConfirmError] = useState<boolean>(false);
  const [lastPaymentMethod, setLastPaymentMethod] = useState<
    string | undefined
  >();

  const serviceRequestPayments =
    serviceSummaryData?.getServiceRequest.ServiceRequestPayments;

  const [
    confirmStripeTransactionMutation,
    { loading: confirmStripeTransactionLoading },
  ] = useConfirmStripeTransactionMutation();

  const [contactSupportForExtraChargeMutation] =
    useContactSupportForExtraChargeMutation();

  const isCardHolder =
    user?.id === serviceSummaryData?.getServiceRequest.requestedBy;

  const hasReview = Boolean(
    serviceReqReviewData?.getServiceRequestReview?.rating
  );

  const {
    handleCardSelect,
    stripeCustomerDefaultCardLoading,
    updateServiceRequestPaymentLoading,
  } = useUpdateCardMutations();

  const initialServiceCost = serviceRequestPayments?.[0];

  const pendingPaymentStatus = [
    StripeTransactionStatus.RequiresAction,
    StripeTransactionStatus.RequiresConfirmation,
    StripeTransactionStatus.RequiresPaymentMethod,
  ];

  const pendingPaymentsTransactions =
    serviceRequestPayments
      ?.filter((payment) =>
        pendingPaymentStatus.includes(
          payment.StripeTransaction?.status as StripeTransactionStatus
        )
      )
      .map((transaction) => transaction.stripeTransactionId) ?? [];

  const hasPendingPayment = pendingPaymentsTransactions?.length > 0;

  const paymentPending = serviceRequestPayments?.find((payment) =>
    pendingPaymentsTransactions.includes(String(payment.stripeTransactionId))
  );

  const lastProcessedPayment = serviceRequestPayments?.filter(
    (payment) =>
      !pendingPaymentStatus.includes(
        payment.StripeTransaction?.status as StripeTransactionStatus
      ) && payment.id !== initialServiceCost?.id
  );

  const hasSecondProcessedPayment =
    lastProcessedPayment && lastProcessedPayment.length > 0;

  const transactionIds = serviceRequestPayments?.reduce((acc, payment) => {
    if (acc.includes(payment.stripeTransactionId)) {
      return acc;
    }
    acc.push(payment.stripeTransactionId);
    return acc;
  }, [] as Array<string | undefined | null>);

  const hasRefund =
    transactionIds?.length === 1 && (serviceRequestPayments || []).length > 1;

  const refundPayment = serviceRequestPayments?.find(
    (payment) => payment.amount < 0
  );

  const finalServiceFee = refundPayment
    ? (initialServiceCost?.fee || 0) + refundPayment.fee
    : initialServiceCost?.fee;

  const finalTax = refundPayment
    ? (initialServiceCost?.tax || 0) + refundPayment.tax
    : initialServiceCost?.tax;

  const handleCardSelectLocal = async (card: string) => {
    setLastPaymentMethod(card);
    await handleCardSelect(
      card,
      paymentPending as GraphqlServiceRequestPayment
    );
    void confirmStripeTransactionMutation({
      variables: {
        stripeTransactionId: paymentPending?.stripeTransactionId as string,
      },
      refetchQueries: [
        GET_SERVICE_REQUEST,
        GET_ACTIVITIES_GROUPED_BY_DATE,
        GET_ACTIVITIES_ALERTS,
      ],
    })
      .then(() => {
        setNewStep(Steps.PaymentCompleteStep);
      })
      .catch((err) => {
        setOnConfirmError(true);
        logger.error({
          tag: '[CONFIRM_STRIPE_TRANSACTION]',
          message:
            'Error on confirmStripeTransactionMutation in Service Summary',
          error: err as Error,
        });
      });
  };

  const handleContactSupport = async () => {
    await contactSupportForExtraChargeMutation({
      variables: {
        stripeTransactionId: paymentPending?.stripeTransactionId as string,
      },
    });
    await handleGetBryaTeamChat();
    onClose();
  };

  const totalCustomerCost = serviceRequestPayments?.reduce((acc, payment) => {
    const totalAmount = payment.amount + payment.fee + payment.tax;
    acc = acc + totalAmount;
    return acc;
  }, 0);

  const totalCustomerCostLabel =
    totalCustomerCost && !isNaN(totalCustomerCost)
      ? `$${totalCustomerCost.toFixed(2)}`
      : '-';

  const handleRateAndReviewProfessional = () =>
    setNewStep(Steps.RateProfessionalStep);

  useEffect(() => {
    if (
      paymentPending?.stripePaymentMethodId &&
      serviceSummaryData?.getServiceRequest.status ===
        ServiceRequestStatus.PaymentFailed &&
      paymentPending?.stripePaymentMethodId !==
        initialServiceCost?.stripePaymentMethodId
    ) {
      setLastPaymentMethod(paymentPending.stripePaymentMethodId);
    }
  }, []);

  return (
    <>
      {(getBryaTeamChatQueryLoading ||
        confirmStripeTransactionLoading ||
        stripeCustomerDefaultCardLoading ||
        updateServiceRequestPaymentLoading) && <FullScreenLoader />}
      {!hasPendingPayment && (
        <IconContainer>
          <IconPaymentCompleted />
        </IconContainer>
      )}

      <Title>
        {hasPendingPayment ? 'Payment' : t('serviceSummary.paymentComplete')}
      </Title>

      {isCardHolder && !hasPendingPayment && (
        <BodyText style={{ textAlign: 'center' }}>
          {t('serviceSummary.noAdditionCharges')}
        </BodyText>
      )}

      {(hasPendingPayment || hasSecondProcessedPayment) && (
        <TertiaryTitle style={{ marginTop: '1rem' }}>
          {t('modalPostVisitCost.initialCosts')}{' '}
          <Badge icon="✓" variant="success">
            {t('modalPostVisitCost.paid')}
          </Badge>
        </TertiaryTitle>
      )}

      <AnimateMotionDiv>
        <CostBox
          borderColor={
            hasPendingPayment || hasSecondProcessedPayment
              ? '#3a868c'
              : 'transparent'
          }
        >
          <CostLine
            label={t('serviceSummary.paymentType')}
            value={
              initialServiceCost?.type
                ? ServicePaymentTypeLabel[initialServiceCost?.type]
                : '-'
            }
          />
          <CostLine
            label={t('serviceSummary.rate')}
            value={
              initialServiceCost?.amount
                ? `$${initialServiceCost?.amount}`
                : '-'
            }
          />
          {initialServiceCost?.type &&
            initialServiceCost.type === ServicePaymentType.Hour && (
              <CostLine
                label="Hours"
                value={initialServiceCost?.estimateHours ?? '-'}
              />
            )}
        </CostBox>
      </AnimateMotionDiv>

      <AnimateMotionDiv>
        <CostBox
          borderColor={
            hasPendingPayment || hasSecondProcessedPayment
              ? '#3a868c'
              : 'transparent'
          }
        >
          <CostLine
            label={t('serviceSummary.initialCost')}
            value={
              initialServiceCost?.amount
                ? `$${initialServiceCost?.amount}`
                : '-'
            }
          />
          {hasRefund && (
            <CostLine
              label={t('serviceSummary.partialRefund')}
              value={
                refundPayment?.amount
                  ? `-$${Math.abs(refundPayment?.amount)}`
                  : '-'
              }
            />
          )}

          <CostLine
            label={t('serviceSummary.safetyFee')}
            value={finalServiceFee ? `$${finalServiceFee}` : '-'}
          />

          {finalTax ? (
            <CostLine
              label={t('serviceSummary.taxFee')}
              value={`$${formatNumber(finalTax)}`}
            />
          ) : null}

          <CostLine
            label={t('serviceSummary.totalCustomerCost')}
            value={
              refundPayment
                ? totalCustomerCostLabel
                : calculateTotalValueWithLabel(
                    initialServiceCost as GraphqlServiceRequestPayment
                  )
            }
            isTotal
          />
        </CostBox>
      </AnimateMotionDiv>

      <CostBreakDown />

      {isCardHolder && (
        <>
          <TertiaryTitle>{t('serviceSummary.paymentMethod')}</TertiaryTitle>
          <CostBox borderColor="#3a868c">
            {initialServiceCost?.stripePaymentMethodId && (
              <PaymentMethod
                stripePaymentMethodId={
                  initialServiceCost?.stripePaymentMethodId
                }
              />
            )}
          </CostBox>
        </>
      )}

      {hasPendingPayment && !hasRefund && (
        <>
          <CustomHr />
          <TertiaryTitle>
            {t('modalPostVisitCost.postVisitCosts')}{' '}
            <Badge icon="!" variant="warning">
              {t('modalPostVisitCost.due')}
            </Badge>
          </TertiaryTitle>
          {!isCardHolder && (
            <BodyText>
              {t('serviceSummary.toBePaidBy', {
                cardHolderName: serviceSummaryData?.getServiceRequest
                  ?.RequestedBy?.fullName as string,
              })}
            </BodyText>
          )}
        </>
      )}

      {hasRefund && (
        <>
          <CustomHr />
          <SecondaryTitle>{t('serviceSummary.refund')} </SecondaryTitle>
        </>
      )}

      {hasPendingPayment && paymentPending?.notes && (
        <CostBox>
          <TertiaryTitle>
            {t('serviceSummary.notesFrom')}{' '}
            {serviceSummaryData?.getServiceRequest?.Agent?.User?.fullName}
          </TertiaryTitle>
          <BodyText>{paymentPending?.notes}</BodyText>
        </CostBox>
      )}

      {hasRefund && refundPayment?.notes && (
        <CostBox>
          <TertiaryTitle>
            {t('serviceSummary.notesFrom')}{' '}
            {serviceSummaryData?.getServiceRequest?.Agent?.User?.fullName}
          </TertiaryTitle>
          <BodyText>{refundPayment?.notes}</BodyText>
        </CostBox>
      )}

      {hasPendingPayment && !hasRefund && (
        <AnimateMotionDiv>
          {initialServiceCost?.type === ServicePaymentType.Hour && (
            <CostBox>
              <CostLine
                label="Hours"
                value={
                  paymentPending?.amount
                    ? paymentPending.amount / initialServiceCost.amount
                    : '-'
                }
              />
            </CostBox>
          )}

          <CostBox borderColor="#FAAD14">
            <CostLine
              label={t('serviceSummary.postVisitServiceCost')}
              value={
                paymentPending?.amount ? `$${paymentPending?.amount}` : '-'
              }
            />
            <CostLine
              label={t('serviceSummary.safetyFee')}
              value={paymentPending?.fee ? `$${paymentPending?.fee}` : '-'}
            />
            {paymentPending?.tax ? (
              <CostLine
                label={t('serviceSummary.taxFee')}
                value={`$${formatNumber(paymentPending?.tax)}`}
              />
            ) : null}
            <CostLine
              label={t('serviceSummary.totalCustomerCost')}
              value={calculateTotalValueWithLabel(
                paymentPending as GraphqlServiceRequestPayment
              )}
              isTotal
            />
          </CostBox>
        </AnimateMotionDiv>
      )}

      {!hasPendingPayment &&
        hasSecondProcessedPayment &&
        !hasRefund &&
        lastProcessedPayment.map((payment) => (
          <AnimateMotionDiv key={payment.id}>
            <CustomHr />
            <TertiaryTitle>
              {t('modalPostVisitCost.postVisitCosts')}{' '}
              <Badge icon="✓" variant="success">
                {t('modalPostVisitCost.paid')}
              </Badge>
            </TertiaryTitle>

            {initialServiceCost?.type === ServicePaymentType.Hour && (
              <CostBox>
                <CostLine
                  label="Hours"
                  value={
                    payment?.amount
                      ? payment.amount / initialServiceCost.amount
                      : '-'
                  }
                />
              </CostBox>
            )}

            <CostBox borderColor="#3a868c">
              <CostLine
                label={t('serviceSummary.postVisitServiceCost')}
                value={payment?.amount ? `$${payment?.amount}` : '-'}
              />
              <CostLine
                label={t('serviceSummary.safetyFee')}
                value={payment?.fee ? `$${payment?.fee}` : '-'}
              />
              {payment?.tax ? (
                <CostLine
                  label={t('serviceSummary.taxFee')}
                  value={`$${formatNumber(payment?.tax)}`}
                />
              ) : null}
              <CostLine
                label={t('serviceSummary.totalCustomerCost')}
                value={calculateTotalValueWithLabel(
                  payment as GraphqlServiceRequestPayment
                )}
                isTotal
              />
            </CostBox>
            <CostBreakDown />
            {isCardHolder && (
              <>
                <TertiaryTitle>
                  {t('serviceSummary.paymentMethod')}
                </TertiaryTitle>
                <CostBox borderColor="#3a868c">
                  {payment?.stripePaymentMethodId && (
                    <PaymentMethod
                      stripePaymentMethodId={payment?.stripePaymentMethodId}
                    />
                  )}
                </CostBox>
              </>
            )}
          </AnimateMotionDiv>
        ))}

      {hasPendingPayment && !hasRefund && isCardHolder && (
        <AnimateMotionDiv>
          <TertiaryTitle style={{ marginTop: '16px' }}>
            {t('serviceSummary.paymentMethod')}
          </TertiaryTitle>
          <CostBox borderColor="#212121">
            {t('serviceSummary.warningBox', {
              date: dayjs(paymentPending?.createdAt)
                .add(3, 'days')
                .format('ddd, MMM D - h:mm A'),
            })}
          </CostBox>
          <SelectPaymentCard
            buttonLabel="Approve"
            onCardSelect={handleCardSelectLocal}
            cardDisabled={false}
            errorPaymentId={
              !confirmStripeTransactionLoading &&
              !updateServiceRequestPaymentLoading &&
              !stripeCustomerDefaultCardLoading
                ? lastPaymentMethod
                : undefined
            }
          />
        </AnimateMotionDiv>
      )}

      {hasPendingPayment && !hasRefund && isCardHolder ? (
        <div style={{ marginLeft: '12px', marginRight: '12px' }}>
          <Button
            color="secondary-orange"
            style={{ marginTop: '16px' }}
            disabled={
              updateServiceRequestPaymentLoading ||
              stripeCustomerDefaultCardLoading
            }
            onClick={handleContactSupport}
          >
            {t('serviceSummary.contactSupport')}
          </Button>
        </div>
      ) : (
        <Button
          color="primary-orange"
          style={{ marginTop: '16px' }}
          onClick={hasReview ? onClose : handleRateAndReviewProfessional}
        >
          {hasReview ? 'OK' : t('serviceSummary.rateAndReview')}
        </Button>
      )}

      {onConfirmError && (
        <ModalPaymentFailed
          isOpenModal={onConfirmError}
          onDidDismiss={() => {
            setOnConfirmError(false);
          }}
          onHandleContactSupport={handleContactSupport}
          errorPaymentMethodId={lastPaymentMethod}
        />
      )}
    </>
  );
};
