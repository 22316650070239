import * as React from 'react';

export const ConnectSelected = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_8950_103864)">
      <path
        d="M12.5416 11C14.81 11 16.814 8.86404 17.0104 6.23822C17.1079 4.91908 16.6941 3.68888 15.8451 2.77494C15.005 1.87216 13.8307 1.37502 12.5416 1.37502C11.2423 1.37502 10.0671 1.86916 9.23304 2.76634C8.38956 3.67341 7.97835 4.90619 8.07288 6.23736C8.26581 8.86361 10.2694 11 12.5416 11Z"
        fill="#EA8231"
      />
      <path
        d="M21.4519 18.8957C21.0892 16.8839 19.957 15.1939 18.1781 14.008C16.5981 12.9548 14.5962 12.3747 12.541 12.3747C10.4858 12.3747 8.48393 12.9548 6.90397 14.0075C5.12506 15.1935 3.99284 16.8834 3.63018 18.8952C3.54725 19.3563 3.65983 19.8122 3.93912 20.1461C4.0658 20.2982 4.22487 20.4201 4.40469 20.5029C4.58452 20.5858 4.78058 20.6274 4.97854 20.6247H20.1035C20.3016 20.6275 20.4978 20.5861 20.6778 20.5033C20.8578 20.4206 21.017 20.2987 21.1438 20.1465C21.4223 19.8126 21.5348 19.3567 21.4519 18.8957Z"
        fill="#EA8231"
      />
      <path
        d="M4.63726 12.3764V10.6574H6.35625C6.53862 10.6574 6.71351 10.585 6.84246 10.456C6.97141 10.3271 7.04385 10.1522 7.04385 9.96982C7.04385 9.78745 6.97141 9.61256 6.84246 9.48361C6.71351 9.35466 6.53862 9.28222 6.35625 9.28222H4.63726V7.56322C4.63726 7.38086 4.56481 7.20597 4.43586 7.07702C4.30692 6.94807 4.13202 6.87563 3.94966 6.87563C3.7673 6.87563 3.5924 6.94807 3.46345 7.07702C3.33451 7.20597 3.26206 7.38086 3.26206 7.56322V9.28222H1.54307C1.3607 9.28222 1.18581 9.35466 1.05686 9.48361C0.927912 9.61256 0.855469 9.78745 0.855469 9.96982C0.855469 10.1522 0.927912 10.3271 1.05686 10.456C1.18581 10.585 1.3607 10.6574 1.54307 10.6574H3.26206V12.3764C3.26206 12.5588 3.33451 12.7337 3.46345 12.8626C3.5924 12.9916 3.7673 13.064 3.94966 13.064C4.13202 13.064 4.30692 12.9916 4.43586 12.8626C4.56481 12.7337 4.63726 12.5588 4.63726 12.3764Z"
        fill="#EA8231"
      />
    </g>
    <defs>
      <clipPath id="clip0_8950_103864">
        <rect
          width="22"
          height="22"
          fill="white"
          transform="translate(0.166016)"
        />
      </clipPath>
    </defs>
  </svg>
);
