import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';

interface IonBackButtonEventDetail {
  register(priority: number, handler: () => void): void;
}

interface IonBackButtonCustomEvent extends Event {
  detail: IonBackButtonEventDetail;
}

const usePreventBackButton = () => {
  useEffect(() => {
    const isAndroid = isPlatform('android');

    const backButtonHandler = (ev: IonBackButtonCustomEvent) => {
      ev.detail.register(100, () => {});
    };

    if (isAndroid) {
      // @ts-expect-error overlap custom event
      document.addEventListener('ionBackButton', backButtonHandler);
    }

    // Clean up
    return () => {
      if (isAndroid) {
        // @ts-expect-error overlap custom event
        document.removeEventListener('ionBackButton', backButtonHandler);
      }
    };
  }, []);
};

export default usePreventBackButton;
