import styled, { CSSProperties } from 'styled-components';
import { GraphqlUser } from '../../graphql/generated';
import { pixelToRem } from '../../utils/helper';
import { useMemo } from 'react';

const NoPictureAvatar = styled.div`
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      0deg,
      var(--colors-primary-orange),
      var(--colors-primary-orange)
    );
  border: 1px solid var(--colors-primary-orange);
  box-shadow: 0px 2px 8px 0px #00000026;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--colors-primary-orange);
  color: var(--colors-primary-orange);
  font-family: Inter;
  font-size: ${() => pixelToRem(14)};
  font-weight: 500;
  line-height: 20px;
`;

interface UserAvatarProps {
  user:
    | Pick<GraphqlUser, 'profilePicture' | 'firstName' | 'lastName'>
    | undefined;
  size?: CSSProperties['width'];
  fontSize?: CSSProperties['fontSize'];
}
export const UserAvatar: React.FC<UserAvatarProps> = ({
  user,
  size,
  fontSize,
}) => {
  const userInitials = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`;
    }
  }, [user?.firstName, user?.lastName]);

  return user?.profilePicture ? (
    <img
      src={String(user?.profilePicture)}
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: '1px solid var(--colors-primary-orange)',
      }}
    />
  ) : (
    <NoPictureAvatar
      style={{
        width: size,
        height: size,
        fontSize,
      }}
    >
      {userInitials}
    </NoPictureAvatar>
  );
};

UserAvatar.defaultProps = {
  size: '40px',
};

interface UserAvatarsProps {
  users: Pick<GraphqlUser, 'profilePicture' | 'firstName' | 'lastName'>[];
  size?: number;
}
/**
 * Renders a list of user avatars overlayed on a 50% over each other
 */
export const UserAvatars: React.FC<UserAvatarsProps> = ({
  users,
  size = 40,
}) => {
  return (
    <div
      style={{
        position: 'relative',
        width: size / 2 + (users.length * size) / 2,
        height: size,
      }}
    >
      {users.map((user, index) => (
        <div
          key={`${user.firstName}-${user.lastName}`}
          style={{ position: 'absolute', left: (index * size) / 2 }}
        >
          <UserAvatar size={size} user={user} />
        </div>
      ))}
    </div>
  );
};
