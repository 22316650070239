import { WelcomeSlide1 } from '../../assets/images/WelcomeSlide1';
import { WelcomeSlide2 } from '../../assets/images/WelcomeSlide2';
import { WelcomeSlide3 } from '../../assets/images/WelcomeSlide3';
import { WelcomeSlide4 } from '../../assets/images/WelcomeSlide4';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SlideOneSubtitle,
  SlideOneTitle,
  SliderContainer,
  TitleContainer,
} from './WelcomeSlider.style';
import useFitText from 'use-fit-text'; // TODO check whats wrong with this import
import { Fragment } from 'react';

const WelcomeSlider: React.FC = () => {
  const i18n = useTranslation();
  const { fontSize, ref } = useFitText();

  const slidesData = [
    {
      SliderImage: WelcomeSlide1,
      title: [
        'welcomeSlide.firstSlide.firstTitle',
        'welcomeSlide.firstSlide.secondTitle',
      ],
      description: 'welcomeSlide.firstSlideSubtitle',
    },
    {
      SliderImage: WelcomeSlide2,
      title: [
        'welcomeSlide.secondSlide.firstTitle',
        'welcomeSlide.secondSlide.secondTitle',
      ],
      description: 'welcomeSlide.secondSlideSubtitle',
    },
    {
      SliderImage: WelcomeSlide3,
      title: [
        'welcomeSlide.thirdSlide.firstTitle',
        'welcomeSlide.thirdSlide.secondTitle',
      ],
      description: 'welcomeSlide.thirdSlideSubtitle',
    },
    {
      SliderImage: WelcomeSlide4,
      title: [
        'welcomeSlide.fourthSlide.firstTitle',
        'welcomeSlide.fourthSlide.secondTitle',
      ],
      description: 'welcomeSlide.fourthSlideSubtitle',
    },
  ];

  return (
    <Container>
      <Swiper
        modules={[Pagination, A11y, Autoplay]}
        pagination={{ clickable: true }}
        slidesPerView={1}
        speed={400}
        loop={false}
        spaceBetween={60}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
      >
        {slidesData.map(({ SliderImage, title, description }, sliderIndex) => (
          <SwiperSlide key={description}>
            <SliderContainer ref={ref}>
              <SliderImage width={'100%'} />
              <TitleContainer>
                {title.map((value, index) => (
                  <Fragment key={value}>
                    {index > 1 && <br />}
                    <SlideOneTitle
                      key={value}
                      color={index === 0 ? '#EA8231' : '#000000'}
                      fontSize={fontSize ? fontSize : '32'}
                    >
                      {i18n.t(value)}{' '}
                      {sliderIndex === 2 && index === 0 && <br />}
                    </SlideOneTitle>
                  </Fragment>
                ))}
              </TitleContainer>
              <SlideOneSubtitle fontSize={fontSize ? fontSize : '16'}>
                {i18n.t(description)}
              </SlideOneSubtitle>
            </SliderContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default WelcomeSlider;
