/* eslint-disable no-console */

import { ApolloError } from '@apollo/client';

/**
 * Logger implementation for client side logging
 */
export type LogParams = {
  tag: string; // Tag to identify the log
  message: string; // Additional message to log
  error?: Error | ApolloError; // Optional Error instance related to the log
};

interface Logger {
  debug: (params: LogParams) => void;
  info: (params: LogParams) => void;
  warn: (params: LogParams) => void;
  error: (params: LogParams) => void;
}

// Here we can setup different loggers depending on environment
const LocalLogger: Logger = {
  debug: ({ tag, message }) => {
    console.debug(`${tag} ${message}`);
  },

  info: ({ tag, message }) => {
    console.info(`${tag} ${message}`);
  },

  warn: ({ tag, message }) => {
    console.warn(`${tag} ${message}`);
  },

  error: ({ tag, message, error }) => {
    console.error(`${tag} ${message}`);
    if (error) {
      console.error(`${tag}`, error);
    }
  },
};

// TODO: Export logger depending on environment
export const logger = LocalLogger;
